import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';

import ClustersChart from '~reactComponents/cards/clustersChart/ClustersChart.react';
import HealthScoreCard from '~reactComponents/cards/healthScore/HealthScoreCard.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';

import { ClusterHealths, IRouter, IStore } from '~globalTypes';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { handlePageChange } from '~reactHelpers';

import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import './Clusters.react.scss';

dayjs.extend(timezone);

type ClustersProps = {
  router: () => IRouter;
  store: () => IStore;
};

const Clusters = ({ router, store }: ClustersProps) => {
  const organization = store().getters.organization;
  const clusters = store().getters.clusters;
  const cluster = store().getters.cluster;
  const user = store().getters.user;

  const [summary, setSummary] = useState<ClusterHealths>();
  const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false);

  const getSummary = async () => {
    try {
      const baseUrl = `/v0/organizations/${organization.Name}`;
      const summary = await sendRequest('GET', `${baseUrl}/health-score/summary`, {}, null);
      setSummary(summary);
      setIsSummaryLoaded(true);
    } catch (e) {
      logger.logError('error_fetching_summary', e);
      toast.error(strings.allClusters.error);
    }
  };

  useEffect(() => {
    setIsSummaryLoaded(false);
    getSummary();
  }, [organization]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: 'last',
      label: strings.general.allClusters,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="org-dashboard">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="org-container mt-4">
        <div className="left-dashboard">
          <ClustersChart
            organization={organization}
            clusters={clusters}
            isSummaryLoaded={isSummaryLoaded}
            retrievedScores={summary}
            router={router}
            user={user}
          />
        </div>
        <div className="right-dashboard">
          <HealthScoreCard
            organization={organization}
            router={router}
            cluster={cluster}
            data={summary}
          />
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Clusters;
