import { mapGetters } from 'vuex';
import { strings } from '~utils/strings';
import AppGroupsReports from './AppGroupsReports.react';

export default {
  metaInfo() {
    return { title: `${strings.metaTitle}${strings.appGroups.AppGroupsReports}` };
  },
  name: 'app-groups-reports-page',
  components: {
    'app-groups-reports': AppGroupsReports,
  },
  computed: {
    ...mapGetters(['isAddAppGroupModalShown']),
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
