import React, { useEffect, useRef, useState } from 'react';
import { BarDatum } from '@nivo/bar';
import { toast } from 'react-hot-toast';
import { Filters } from 'react-table';

import BarChart from '~reactComponents/charts/BarChart/BarChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import { LoadingSpinner } from '@fairwindsops/ui-components';

import { ActionItem, AppGroup, Team } from '../../../ActionItems.types.react';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { convertFiltersToURL } from '~reactHelpers';
import { COLORS } from '~utils/styling';
import { useDebouncedCallback } from 'use-debounce';
import { additionalQueryParams } from '../Charts.react';

type TopIssuesProps = {
  baseURL: string;
  filters: Filters<ActionItem>;
  noData: React.ReactNode;
  selectedTeam?: Team | null;
  selectedAppGroup?: AppGroup | null;
  setFilter: (columnId: string, updater: any) => void;
};

const TopIssues = ({ baseURL, filters, noData, selectedTeam, selectedAppGroup, setFilter }: TopIssuesProps) => {
  const [topIssues, setTopIssues] = useState<BarDatum[]>();
  const chartClicked = useRef<boolean>(false);
  const [loading, setLoading] = useState(true);

  const debounceGetTopIssues = useDebouncedCallback(async () => {
    const params = buildURLSearchParams();
    const URLParams = convertFiltersToURL(filters, params);

    try {
      const data: BarDatum[] = await sendRequest('GET', `${baseURL}/action-items/top?${URLParams}`, {}, null);
      setTopIssues(data.slice(0, 5).reverse());
    } catch (e) {
      console.error('Error retrieving top severities: ', e);
      logger.logError('error_retrieving_top_severities: ', e);
      toast.error(strings.actionItemsPage.errorRetrievingTopSeverities);
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    setLoading(true);
    debounceGetTopIssues();
  }, [filters, selectedTeam, selectedAppGroup]);

  const clearFilter = () => {
    setFilter('Title', []);
    chartClicked.current = false;
  };

  const buildURLSearchParams = () => {
    const currentURLSearchParams = new URLSearchParams(window.location.search);

    let url = 'groupBy=title&page=0&pageSize=5';

    if (selectedTeam?.ID) {
      url = `${url}&TeamID=${selectedTeam.ID}`;
    }

    if (selectedAppGroup?.name) {
      url = `${url}&AppGroup=${selectedAppGroup.name}`;
    }

    additionalQueryParams.forEach((param) => {
      if (currentURLSearchParams.get(param)) {
        url = `${url}&${param}=${currentURLSearchParams.get(param)}`;
      }
    });

    return new URLSearchParams(url);
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    setFilter('Title', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const ClearButtonComponent = () => {
    const filtered = filters.find((filter) => filter.id === 'Title')?.value;
    if (chartClicked.current && filtered.length) {
      return (
        <ClearButton isFiltered={filtered.length} clearFilter={clearFilter} isChartClicked={chartClicked.current} />
      );
    }
    return <></>;
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ClearButtonComponent />
          {topIssues?.length ? (
            <div aria-label={strings.ariaLabels.topActionItemsIssuesChart} className="action-item--charts">
              <BarChart
                data={topIssues}
                keys={['Count']}
                indexBy="Title"
                colors={[
                  COLORS.CHARTS.PURPLE[100],
                  COLORS.CHARTS.PURPLE[200],
                  COLORS.CHARTS.PURPLE[300],
                  COLORS.CHARTS.PURPLE[400],
                  COLORS.CHARTS.PURPLE[500],
                ]}
                layout="horizontal"
                onClick={handleChartClick}
                tooltipLabel={(d) => `${d?.data?.Title}`}
                showLabel={true}
              />
            </div>
          ) : (
            <>{noData}</>
          )}
        </>
      )}
    </>
  );
};

export default TopIssues;
