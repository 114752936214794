import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CardDeck } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import {
  ActionItem,
  ActionItemFilters,
  ActionItemsFilter,
  AppGroup,
  List,
  Member,
  Team,
} from '../ActionItems.types.react';
import { IStore, IRoute, IRouter, IndeterminateCheckboxProps } from '~globalTypes';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';
import { useContextMenu, TriggerEvent } from 'react-contexify';
import Table from '../components/Table/Table.react';
import {
  FILTERS_DEFAULT,
  CONTEXT_MENU_ID,
  EXPORT_ACTION_ITEMS_OPTIONS,
  EXPORT_ACTION_ITEMS_CLUSTER_OPTIONS,
  PAGE_SIZE,
  TABLE_COLUMNS,
  HIDDEN_COLUMNS,
  COLUMNS_ORDER,
  TOP_ROW,
  CONTENT,
} from '../ActionItems.config.react';
import { exportActionItems, exportNSAReport, exportSOC2Report } from '../ActionItems.helpers.react';
import TableCardHeader from '../components/TableCardHeader.react';
import { convertFiltersToURL, handlePageChange } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import {
  Column,
  Row,
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useResizeColumns,
  FilterTypes,
  TableInstance,
  useFlexLayout,
} from 'react-table';
import DeleteModal from '../ActionItemsList/modals/DeleteList.react';
import EditModal from '../ActionItemsList/modals/EditList.react';
import CreateListModal from '../ActionItemsList/modals/CreateList.react';
import useListsReact from '../hooks/useLists.react';
import ConfirmAddToList from '../ActionItemsList/modals/ConfirmAddToList.react';
import DescriptionPanel from '~reactComponents/DescriptionPanel/DescriptionPanel.react';
import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import './ActionItemsPage.react.scss';
import 'react-contexify/dist/ReactContexify.css';
import SelectColumnFilter from '../components/SelectColumnFilter/SelectColumnFilter.react';
import { Card, LayoutReact } from '@fairwindsops/ui-components';
import SeverityChartReact from '../components/Charts/Severity/SeverityChart.react';
import TopIssues from '../components/Charts/TopIssues/TopIssues.react';
import NamespaceChart from '../components/Charts/Namespaces/NamespaceChart.react';
import TopWorkloads from '../components/Charts/Workloads/TopWorkloads.react';

import { getCurrentTimezone } from '~reactHelpers';
import { Breadcrumbs } from '@fairwindsops/ui-components';
import {
  CLUSTER_OVERVIEW,
  ORG_DASHBOARD,
  ACTION_ITEMS_CLUSTER,
  ACTION_ITEMS,
  CLUSTERS,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { sortAscending } from '~utils/helpers';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
dayjs.extend(relativeTime);
dayjs.extend(timezone);

type ActionItemsProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  selectedCluster: string;
};

type TimeRange = { start?: string; end?: string };

type UpdatedList = { data: List; headers: Record<string, string> };

const ActionItemsTable = ({ route, router, store, selectedCluster }: ActionItemsProps): JSX.Element => {
  const org = route?.params?.org;
  const isOwner = store().getters.isOrgOwner;
  const baseURL = `/v0/organizations/${org}`;
  const timeZone = getCurrentTimezone();

  const [actionItems, setActionItems] = useState<ActionItem[]>([]);
  const [selectedFlatRows, setSelectedFlatRows] = useState<Row<ActionItem>[]>([]);
  const [members, setMembers] = useState<Member[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [appGroups, setAppGroups] = useState<AppGroup[]>([]);
  const [selectedAppGroup, setSelectedAppGroup] = useState<AppGroup | null>(null);
  const [selectedActionItem, setSelectedActionItem] = useState<ActionItem | null>(null);
  const [showViewMode, setShowViewMode] = useState<boolean>(false);

  const [selectedTimeRangeFirstSeen, setSelectedTimeRangeFirstSeen] = useState<TimeRange>(() => {
    const [startFirstSeen, endFirstSeen] = route?.query?.FirstSeen?.split(';') || [];
    return { start: startFirstSeen, end: endFirstSeen };
  });

  const [selectedTimeRangeLastReported, setSelectedTimeRangeLastReported] = useState<TimeRange>(() => {
    const [startLastReportedAt, endLastReportedAt] = route?.query?.LastReportedAt?.split(';') || [];
    return { start: startLastReportedAt, end: endLastReportedAt };
  });

  const actionItemFilters = useRef<ActionItemFilters>(FILTERS_DEFAULT);
  const skipTableResetRef = useRef<boolean>();
  const initialFilters = useRef<ActionItemsFilter[]>([]);
  const isHydrated = useRef<boolean>(false);
  const isRefreshed = useRef<boolean>(false);
  const urlParams = useRef<URLSearchParams>(new URLSearchParams(window.location.search));
  const cluster = useRef<string>(route?.params?.cluster);

  const { show: showContextMenu } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  useEffect(() => {
    cluster.current = store().getters?.cluster?.Name;
    isHydrated.current = false;
  }, [store().getters?.cluster?.Name]);

  useEffect(() => {
    urlParams.current = new URLSearchParams(window.location.search);
  }, [window.location.search]);

  useEffect(() => {
    initQueries();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filtersRequestPath = cluster.current
          ? `${baseURL}/clusters/${cluster.current}/action-item-filters`
          : `${baseURL}/action-item-filters`;

        const ignoredKeys = [
          strings.actionItemsPage.filters.page,
          strings.actionItemsPage.filters.pageSize,
          strings.actionItemsPage.filters.from,
          strings.actionItemsPage.filters.listName,
          strings.actionItemsPage.filters.orderBy,
        ];
        const entries = Array.from(urlParams.current.entries());
        const relevantEntries = entries.filter(([key]) => !ignoredKeys.includes(key));

        let allFiltersRequestPath = filtersRequestPath;
        if (relevantEntries.length) {
          const filteredUrlParams = new URLSearchParams(relevantEntries);
          allFiltersRequestPath = `${filtersRequestPath}?${filteredUrlParams.toString()}`;
        }

        const requests = [
          sendRequest('GET', allFiltersRequestPath, { showErrorAlert: false }, null),
          sendRequest('GET', `${baseURL}/memberships`, { showErrorAlert: false }, null),
          sendRequest('GET', `${baseURL}/teams`, { showErrorAlert: false }, null),
          sendRequest('GET', `${baseURL}/app-groups`, { showErrorAlert: false }, null),
        ];

        const [filters, _members, _teams, _appGroups] = await Promise.all(requests);

        actionItemFilters.current = {
          ...actionItemFilters.current,
          ...filters,
          AssigneeEmail: _members?.map((member: Member) => member.Email),
        };
        setMembers(_members.sort((a: Member, b: Member) => sortAscending(a.Email, b.Email)));
        setTeams(_teams);
        setAppGroups(_appGroups || []);
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_action_items: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };

    const filtersArray = Array.from(urlParams.current.entries()).flat();
    if (
      (filtersArray.length === 4 &&
        filtersArray.includes(strings.actionItemsPage.filters.page) &&
        filtersArray.includes(strings.actionItemsPage.filters.pageSize)) ||
      (!filtersArray.includes(strings.actionItemsPage.filters.page) &&
        !filtersArray.includes(strings.actionItemsPage.filters.pageSize))
    ) {
      return;
    }

    if (skipTableResetRef.current !== undefined) {
      fetchData();
      fetchLists();
    }
  }, [org, window.location.search]);

  const fetchLists = async (): Promise<List[] | undefined> => {
    try {
      const lists = await sendRequest(
        'GET',
        `${baseURL}/lists`,
        { showSuccessAlert: false, showErrorAlert: false },
        null,
      );

      setLists(lists);
      return lists;
    } catch (e) {
      console.error(e);
    }
  };

  const initQueries = async () => {
    const searchParams = urlParams.current;
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if (searchParams.has('listName')) {
      const lists = await fetchLists();
      let matchingList: List | null = { Name: searchParams.get('listName') } as List;
      if (lists?.length) {
        matchingList = lists.find((list) => list.Name === searchParams.get('listName')) || null;
      }
      setSelectedSavedList(matchingList);
    }
    if (
      (navigation[0].type === 'reload' || navigation[0].type === 'navigate') &&
      window.location.search &&
      !searchParams.get(strings.noTranslate.from) &&
      !searchParams.get(strings.actionItemsPage.filters.pageSize) &&
      !searchParams.get(strings.actionItemsPage.filters.page)
    ) {
      searchParams.set('redirect', 'true');
      searchParams.set(strings.actionItemsPage.filters.Fixed, 'false');
      searchParams.set(strings.actionItemsPage.filters.Resolution, 'None');
      searchParams.set(strings.actionItemsPage.filters.orderBy, 'Severity.desc');
    } else if (
      searchParams.get(strings.actionItemsPage.filters.pageSize) &&
      searchParams.get(strings.actionItemsPage.filters.page)
    ) {
      isRefreshed.current = true;
    }

    router()
      .replace({ path: `${window.location.pathname}?${searchParams.toString()}` })
      .catch(() => {});
  };

  const {
    checkListName,
    deleteListModal,
    editList,
    deleteList,
    deleteActionItem,
    closeModal,
    submitNewList,
    createFilters,
    importedList,
    listType,
    showModal,
    selectedList,
    selectedSavedList,
    listName,
    listNames,
    modalError,
    lists,
    query,
    setQuery,
    setLists,
    setListType,
    setListName,
    setShowModal,
    setSelectedSavedList,
    setImportedList,
  } = useListsReact(baseURL, fetchLists, selectedFlatRows);

  const handleSavedListSelection = async (list: List | null): Promise<void> => {
    if (list === null) {
      if (selectedSavedList) {
        tableInstance.setAllFilters([]);
      }
      setSelectedSavedList(null);
    } else if (list.Type === 'manual') {
      setSelectedSavedList(list);
      tableInstance.setAllFilters([]);
    } else if (selectedSavedList?.ID !== list.ID) {
      tableInstance.setAllFilters([]);
      setSelectedSavedList(list);
    }
  };

  const ColumnFiltering = (tableInstance: TableInstance): JSX.Element => (
    <SelectColumnFilter
      tableInstance={tableInstance}
      filters={actionItemFilters.current}
      setSelectedSavedList={setSelectedSavedList}
      selectedSavedList={selectedSavedList}
    />
  );

  const DateRangeFilterFirstSeen = (tableInstance: TableInstance): JSX.Element => (
    <>
      <Datepicker
        onDateSelect={filterByDateFirstSeen}
        startDate={selectedTimeRangeFirstSeen.start}
        endDate={selectedTimeRangeFirstSeen.end}
        minDate={120}
      />
    </>
  );

  const DateRangeFilterLastReported = (tableInstance: TableInstance): JSX.Element => (
    <>
      <Datepicker
        onDateSelect={filterByDateLastReported}
        startDate={selectedTimeRangeLastReported.start}
        endDate={selectedTimeRangeLastReported.end}
        minDate={120}
      />
    </>
  );

  const filterByDateFirstSeen = async (start?: Date | Dayjs, end?: Date | Dayjs) => {
    const onDatesSelected = (start: Date | Dayjs, end: Date | Dayjs) => {
      urlParams.current.set('FirstSeen', `${start.toISOString()};${end.toISOString()}`);
      setSelectedTimeRangeFirstSeen({ start: start.toISOString(), end: end.toISOString() });
    };
    const onDateCleared = () => {
      urlParams.current.delete('FirstSeen');
      setSelectedTimeRangeFirstSeen({});
    };
    filterByDate(start, end, onDatesSelected, onDateCleared);
  };

  const filterByDateLastReported = async (start?: Date | Dayjs, end?: Date | Dayjs) => {
    const onDatesSelected = (start: Date | Dayjs, end: Date | Dayjs) => {
      urlParams.current.set('LastReportedAt', `${start.toISOString()};${end.toISOString()}`);
      setSelectedTimeRangeLastReported({ start: start.toISOString(), end: end.toISOString() });
    };
    const onDateCleared = () => {
      urlParams.current.delete('LastReportedAt');
      setSelectedTimeRangeLastReported({});
    };
    filterByDate(start, end, onDatesSelected, onDateCleared);
  };

  const filterByDate = async (
    start?: Date | Dayjs,
    end?: Date | Dayjs,
    onDatesAvailable?: (start: Date | Dayjs, end: Date | Dayjs) => void,
    onEmptyDates?: () => void,
  ) => {
    if (!start && !end) {
      onEmptyDates && onEmptyDates();
      initQueries();
      if (urlParams.current) {
        hydrateColumnFilters(urlParams.current);
      }
      fetchActionItemsDebounced();
      return;
    }
    if (start && end) {
      onDatesAvailable && onDatesAvailable(start, end);
      if (urlParams.current) {
        hydrateColumnFilters(urlParams.current);
      }
      initQueries();
    }
  };

  useEffect(() => {
    if (selectedSavedList?.Type === 'query') {
      setLoaded(false);
      const filterValues: string[][] = [];
      Object.keys(selectedSavedList.Filters).forEach((filter) => {
        selectedSavedList.Filters[filter]?.forEach((option) => filterValues.push([filter, option]));
      });
      const searchParams = new URLSearchParams(filterValues);
      hydrateColumnFilters(searchParams);
      tableInstance.setAllFilters(initialFilters.current);
    } else if (selectedSavedList?.Type === 'manual') {
      const searchParams = new URLSearchParams();
      searchParams.set('manual', `${selectedSavedList.ID}`);
      router()
        .replace({ path: `${window.location.pathname}?${searchParams.toString()}` })
        .catch(() => {});
      setLoaded(false);
    } else {
      setLoaded(false);
      isHydrated.current = false;
    }
  }, [selectedSavedList?.Name]);

  const data = React.useMemo(() => {
    return actionItems;
  }, [actionItems]);

  const columns = React.useMemo<Column<ActionItem>[]>(
    () =>
      TABLE_COLUMNS(
        router,
        ColumnFiltering,
        DateRangeFilterFirstSeen,
        DateRangeFilterLastReported,
        members,
        baseURL,
        setShowModal,
      ),
    [members, actionItems],
  );

  const handleExport = async (action: string) => {
    const { organization, cluster } = store().getters;
    const searchParams = new URLSearchParams(window.location.search);
    const exportOptions = selectedCluster ? EXPORT_ACTION_ITEMS_CLUSTER_OPTIONS : EXPORT_ACTION_ITEMS_OPTIONS;

    let exportPromise;
    if (action === exportOptions[0]) {
      exportPromise = exportActionItems(new URLSearchParams(), baseURL, organization.Name, cluster?.Name);
    } else if (action === exportOptions[1]) {
      searchParams.delete(strings.actionItemsPage.filters.page);
      searchParams.delete(strings.actionItemsPage.filters.pageSize);
      exportPromise = exportActionItems(searchParams, baseURL, organization.Name, cluster?.Name);
    } else if (selectedCluster && action === exportOptions[3]) {
      exportPromise = exportNSAReport(baseURL, selectedCluster);
    } else {
      const {
        organizationTierAccess: { SOC2 },
      } = store().getters;
      exportPromise = exportSOC2Report(baseURL, organization, SOC2);
    }

    await toast.promise(exportPromise, {
      loading: 'Exporting to CSV...',
      success: <b>Export ready!</b>,
      error: <b>An issue happened when exporting the Action Items.</b>,
    });
  };

  const handleGlobalSearch = async (event: React.BaseSyntheticEvent) => {
    setGlobalSearch(event.target.value.trim() || null);
  };

  const queryBuilder = () => {
    let stringifiedQuery = '';
    const { filters } = tableInstance.state;

    filters.forEach((filter): void => {
      if (filter.value.length) {
        const values = filter.value.map((value: Record<string, unknown>) => value.label).join(', ');
        stringifiedQuery = stringifiedQuery + `${filter.id}=${values} AND `;
      }
    });
    return stringifiedQuery.slice(0, stringifiedQuery.length - 4).trimEnd();
  };

  const handleOpenModal = (type: 'query' | 'manual', list?: List) => {
    if (type === 'query') {
      setQuery(queryBuilder());
    }
    if (list) {
      setShowModal({
        show: true,
        modal: 'confirmAddToList',
        meta: { listID: list.ID, listName: list.Name, listType: type },
      });
    } else {
      setShowModal({
        show: true,
        modal: 'create',
      });
    }
    setListType(type);
  };

  const handleAddToQueryList = async (listID: number) => {
    const updatedList = await fetchListById(listID);
    const filters = createFilters(query);

    const data = {
      ActionItems: null,
      Filters: filters,
      Name: updatedList?.data?.Name,
    };

    await sendRequest('PUT', `${baseURL}/lists/${listID}`, { data }, null);
    setShowModal({ show: false, modal: '' });
  };

  const handleAddToManualList = async (listID: number) => {
    let newActionItems = [];
    let filteredRows = [];
    let selectedList: List;
    try {
      const response = await fetchListById(listID);
      selectedList = response?.data;
    } catch (e) {
      logger.logError('error_fetching_list_by_id', e);
      console.error('error_fetching_list_by_id: ', e);
      toast.error('An error occurred while updating the list');
      return;
    }

    if (selectedList.ActionItems?.length) {
      // Remove any duplicates between the manual list and what the user has selected locally
      filteredRows = selectedFlatRows.filter(
        (row) => !selectedList.ActionItems?.some((actionItem: ActionItem) => actionItem.ID === row.original.ID),
      );
    } else {
      filteredRows = selectedFlatRows;
    }

    if (selectedList.ActionItems?.length) {
      newActionItems = [
        ...selectedList.ActionItems,
        ...filteredRows.map((row: Row<ActionItem>) => ({
          Fixed: row.original.Fixed,
          ID: row.original.ID,
          Resolution: row.original.Resolution,
          Severity: row.original.Severity,
          Title: row.original.Title,
        })),
      ];
    } else {
      newActionItems = filteredRows.map((row: Row<ActionItem>) => ({
        Fixed: row.original.Fixed,
        ID: row.original.ID,
        Resolution: row.original.Resolution,
        Severity: row.original.Severity,
        Title: row.original.Title,
      }));
    }

    const data = {
      ActionItems: newActionItems,
      Filters: selectedList.Filters,
      Name: selectedList.Name,
    };

    try {
      await sendRequest('PUT', `${baseURL}/lists/${listID}`, { data }, null);
    } catch (e) {
      console.error(e);
      logger.logError('error_adding_action_items_to_list', e);
      toast.error('An error occurred while updating the list');
      return;
    }
    toast.success('Action items successfully added to your list');
    setShowModal({ show: false, modal: '' });
  };

  const fetchListById = (listID: number): UpdatedList => {
    return sendRequest('GET', `${baseURL}/lists/${listID}`, { showSuccessAlert: false, returnHeaders: true }, null);
  };

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    const columnFilters: ActionItemsFilter[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0])) {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: item[1],
              },
            ],
          });
        }
      } else {
        hydrateSecondaryFilters(item);
      }
    }
    initialFilters.current = columnFilters;
    isHydrated.current = true;
  };

  const hydrateSecondaryFilters = (item: [string, string]) => {
    const key = item[0];
    const value = item[1];
    switch (key) {
      case 'Search':
        setGlobalSearch(value);
        break;
      case 'FirstSeen': {
        const [start, end] = value.split(';');
        setSelectedTimeRangeFirstSeen({
          start: timeZone ? dayjs(start).tz(timeZone).toDate().toISOString() : dayjs(start).toDate().toISOString(),
          end: timeZone ? dayjs(end).tz(timeZone).toDate().toISOString() : dayjs(end).toDate().toISOString(),
        });
        break;
      }
      case 'LastReportedAt': {
        const [start, end] = value.split(';');
        setSelectedTimeRangeLastReported({
          start: timeZone ? dayjs(start).tz(timeZone).toDate().toISOString() : dayjs(start).toDate().toISOString(),
          end: timeZone ? dayjs(end).tz(timeZone).toDate().toISOString() : dayjs(end).toDate().toISOString(),
        });
        break;
      }
      case strings.actionItemsPage.filters.pageSize:
        tableInstance.state.pageSize = Number(value) || PAGE_SIZE;
        break;
      case strings.actionItemsPage.filters.page: {
        tableInstance.gotoPage(Number(value) || 0);
        break;
      }
      case strings.actionItemsPage.filters.orderBy: {
        const sort = value.split('.');
        tableInstance.setSortBy([
          {
            id: sort[0],
            desc: sort[1].toLowerCase() === 'desc',
          },
        ]);
        break;
      }
      case 'TeamID': {
        const _team = teams.find((team) => team.ID === Number(value));
        setSelectedTeam(_team || null);
        break;
      }
      case 'AppGroup': {
        setSelectedAppGroup({ name: value } as AppGroup);
        break;
      }
      default:
        return;
    }
  };

  const fetchActionItems = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;
    let searchParams: URLSearchParams;
    if (
      (selectedSavedList?.Type === 'query' || !selectedSavedList) &&
      !window.location.search.includes('redirect') &&
      !window.location.search.includes(strings.noTranslate.from)
    ) {
      searchParams = new URLSearchParams();
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
    }

    if (
      (![...searchParams].length &&
        !selectedSavedList &&
        searchParams.get(strings.noTranslate.from) !== 'lists-view') ||
      searchParams.get(strings.noTranslate.from) !== 'costs'
    ) {
      searchParams.set(strings.actionItemsPage.filters.page, pageIndex.toString());
      searchParams.set(strings.actionItemsPage.filters.orderBy, 'Severity.desc');
    } else if (!searchParams.has('manual') && selectedSavedList) {
      searchParams.set(strings.actionItemsPage.filters.page, pageIndex.toString());
    }

    if (selectedCluster && !searchParams.has('Cluster')) {
      searchParams.set('Cluster', selectedCluster);
    }

    if (!searchParams.has('manual') && !searchParams.has(strings.actionItemsPage.filters.pageSize)) {
      searchParams.set(strings.actionItemsPage.filters.pageSize, pageSize.toString());
    }
    if (!isHydrated.current && (!selectedSavedList || selectedSavedList?.Filters)) {
      hydrateColumnFilters(searchParams);
    }
    if (globalSearch) {
      searchParams.set('Search', globalSearch);
    } else {
      searchParams.delete('Search');
    }

    searchParams = convertFiltersToURL(filters, searchParams);

    if (!searchParams.has('manual') && sortBy[0]) {
      const sortById = sortBy[0].id === 'LastReportedAt' ? 'LastReportAt' : sortBy[0].id;
      searchParams.set(strings.actionItemsPage.filters.orderBy, `${sortById}.${sortBy[0].desc ? 'desc' : 'asc'}`);
    } else {
      searchParams.delete(strings.actionItemsPage.filters.orderBy);
    }

    if (globalSearch) {
      searchParams.set('Search', globalSearch);
    }

    if (!searchParams.has('manual')) {
      searchParams.set(strings.actionItemsPage.filters.page, pageIndex.toString());
      searchParams.set(strings.actionItemsPage.filters.pageSize, pageSize.toString());
    }

    if (selectedTimeRangeFirstSeen.start && selectedTimeRangeFirstSeen.end) {
      searchParams.set(
        'FirstSeen',
        `${timeZone ? dayjs(selectedTimeRangeFirstSeen.start).tz(timeZone).toISOString() : dayjs(selectedTimeRangeFirstSeen.start).toISOString()};${
          timeZone
            ? dayjs(selectedTimeRangeFirstSeen.end).tz(timeZone).toISOString()
            : dayjs(selectedTimeRangeFirstSeen.end).toISOString()
        }`,
      );
    } else {
      searchParams.delete('FirstSeen');
    }

    if (selectedTimeRangeLastReported.start && selectedTimeRangeLastReported.end) {
      searchParams.set(
        'LastReportedAt',
        `${timeZone ? dayjs(selectedTimeRangeLastReported.start).tz(timeZone).toISOString() : dayjs(selectedTimeRangeLastReported.start).toISOString()};${
          timeZone
            ? dayjs(selectedTimeRangeLastReported.end).tz(timeZone).toISOString()
            : dayjs(selectedTimeRangeLastReported.end).toISOString()
        }`,
      );
    }

    if (selectedTeam) {
      searchParams.set('TeamID', selectedTeam.ID.toString());
    } else {
      searchParams.delete('TeamID');
    }

    if (selectedAppGroup) {
      searchParams.set('AppGroup', selectedAppGroup.name);
    } else {
      searchParams.delete('AppGroup');
    }

    let data, headers;
    try {
      if (selectedSavedList?.Type === 'manual' || searchParams.has('manual')) {
        const response = await fetchListById(selectedSavedList?.ID || parseInt(searchParams.get('manual') || ''));
        data = !response?.data.ActionItems ? [] : response?.data.ActionItems;
        headers = response?.headers;
      } else {
        const response = await sendRequest(
          'GET',
          `${baseURL}/action-items?${searchParams.toString()}`,
          { returnHeaders: true },
          null,
        );
        data = response.data;
        headers = response.headers;
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_action_items: ', e);
      toast.error('Error fetching action items');
      return;
    }

    setActionItems(data);
    setTotalRowCount(parseInt(headers['total-size']));
    tableInstance.pageCount = parseInt(headers['total-size']);
    setLoaded(true);
    // Check if we are on the action-items page to avoid a redirect if the user has switched pages
    // before we get to this replace().
    if (router()?.currentRoute?.name === ACTION_ITEMS || router()?.currentRoute?.name === ACTION_ITEMS_CLUSTER) {
      router()
        .replace({ path: `${window.location.pathname}?${searchParams.toString()}` })
        .catch(() => {});
    }
  }, [
    loaded,
    selectedTimeRangeFirstSeen.start,
    selectedTimeRangeFirstSeen.end,
    globalSearch,
    selectedTeam,
    selectedAppGroup,
    selectedSavedList,
    selectedTimeRangeLastReported.start,
    selectedTimeRangeLastReported.end,
  ]);

  const fetchActionItemsDebounced = useAsyncDebounce(fetchActionItems, 250);

  useEffect(() => {
    if (
      (selectedTimeRangeFirstSeen.start && selectedTimeRangeFirstSeen.end) ||
      (selectedTimeRangeLastReported.start && selectedTimeRangeLastReported.end)
    ) {
      fetchActionItemsDebounced();
    }
  }, [
    fetchActionItemsDebounced,
    selectedTimeRangeFirstSeen.start,
    selectedTimeRangeFirstSeen.end,
    selectedTimeRangeLastReported.start,
    selectedTimeRangeLastReported.end,
  ]);

  useEffect(() => {
    if (!loaded) return;
    fetchActionItemsDebounced();
  }, [fetchActionItemsDebounced, selectedTeam, selectedAppGroup, globalSearch, cluster.current]);

  const filterTypes = React.useMemo<FilterTypes<ActionItem>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
      const defaultRef = React.useRef(null);
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        if (typeof resolvedRef === 'object' && resolvedRef.current) {
          resolvedRef.current.indeterminate = Boolean(indeterminate);
        }
      }, [resolvedRef, indeterminate]);

      return (
        <label>
          <input
            type="checkbox"
            ref={ref}
            {...rest}
            // Need to stop propagation otherwise row event is called again
            onClick={(e) => e.stopPropagation()}
          />
          <span />
        </label>
      );
    },
  );

  // import list for create new list/edit list modals
  const ImportListOptions = ({ name }: { name?: string }) => {
    return (
      <>
        {lists
          .filter((list) => list.Type === 'manual' && list.Name !== name)
          .map((filteredList) => {
            return <option key={filteredList.Name}>{filteredList.Name}</option>;
          })}
      </>
    );
  };

  React.useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  // Restores user selection for hidden/ordered columns
  const tableColumnsStorage: Record<string, string[]> = useMemo(
    () => JSON.parse(localStorage.getItem('action-item-columns') || '{}'),
    [],
  );

  const tableHiddenColumns = useMemo(() => {
    const hiddenColumns = tableColumnsStorage?.hiddenColumns || HIDDEN_COLUMNS;
    if (route.params?.cluster) {
      hiddenColumns.push('Cluster');
    }
    return hiddenColumns;
  }, [route.name]);

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
        columnOrder: tableColumnsStorage?.columnOrder || COLUMNS_ORDER,
        hiddenColumns: tableHiddenColumns,
      },
      manualFilters: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
      autoResetExpanded: !skipTableResetRef.current,
      autoResetGroupBy: !skipTableResetRef.current,
      autoResetSelectedRows: !skipTableResetRef.current,
      autoResetRowState: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          // important to not change
          id: strings.general.selection,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: { row: Row<ActionItem> }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    if (!selectedCluster && tableInstance.state.hiddenColumns?.includes('Cluster') && !route?.params?.cluster) {
      localStorage.setItem('isClusterEnabled', 'false');
    } else if (!selectedCluster && !tableInstance.state.hiddenColumns?.includes('Cluster')) {
      localStorage.setItem('isClusterEnabled', 'true');
    }
    localStorage.setItem(
      'action-item-columns',
      JSON.stringify({
        hiddenColumns:
          localStorage.getItem('isClusterEnabled') === 'true'
            ? tableInstance.state.hiddenColumns?.filter((column) => column !== 'Cluster')
            : tableInstance.state.hiddenColumns,
        columnOrder: tableInstance.state.columnOrder,
      }),
    );
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    if (initialFilters?.current.length > 0 && isHydrated.current) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, isHydrated.current]);

  const ChartEmpty = () => (
    <div className="action--items--charts-empty">
      <span>No Data</span>
    </div>
  );

  const onNotesUpdated = useDebouncedCallback(async (updatedNotes: string) => {
    if (!selectedActionItem?.ID) {
      return;
    }
    let response = null;
    try {
      response = await sendRequest(
        'PATCH',
        `/v0/organizations/${org}/action-items/notes`,
        {
          data: { ID: selectedActionItem?.ID, Notes: updatedNotes },
          showSuccessAlert: false,
          showErrorAlert: false,
        },
        null,
      );
    } catch (e) {
      logger.logError('error_updating-notes_action_items: ', e);
      toast.error('Error updating notes. Please try again!');
    }
    if (response.Success) {
      setActionItems((prevActionItems: ActionItem[]) => updateActionItemNotes(prevActionItems, updatedNotes));
      setSelectedActionItem((prevSelectedActionItem: ActionItem | null) =>
        prevSelectedActionItem ? { ...prevSelectedActionItem, Notes: updatedNotes } : null,
      );
    }
  }, 500);

  const updateActionItemNotes = (actionItems: ActionItem[], updatedNotes: string) => {
    return actionItems.map((actionItem: ActionItem) =>
      actionItem.ID === selectedActionItem?.ID ? { ...actionItem, Notes: updatedNotes } : actionItem,
    );
  };

  const updateActionItemTicketInfo = () => {
    if (!showModal.meta?.actionItem) {
      return;
    }
    return actionItems.map((actionItem: ActionItem) =>
      actionItem.ID === showModal?.meta?.actionItem?.ID
        ? {
            ...actionItem,
            TicketLink: showModal.meta.actionItem.TicketLink,
            TicketProvider: showModal.meta.actionItem.TicketProvider,
            TicketCreatedAt: showModal.meta.actionItem.TicketCreatedAt,
          }
        : actionItem,
    );
  };

  const formattedSelectedActionItem = useMemo(() => {
    if (selectedActionItem) {
      return {
        metadata: TOP_ROW(selectedActionItem),
        content: CONTENT(selectedActionItem, onNotesUpdated),
        created: selectedActionItem.FirstSeen || null,
        updated: selectedActionItem.LastReportedAt || null,
        title: selectedActionItem.Title || null,
        severity: selectedActionItem.Severity || null,
        category: selectedActionItem.Category || null,
        id: selectedActionItem.ID,
        imageName: selectedActionItem.ImageName || null,
        imageTag: selectedActionItem.ImageTag || null,
        imageSHA: selectedActionItem.ImageSHA || null,
      };
    }
    return null;
  }, [selectedActionItem]);

  const openContextMenu = (e: TriggerEvent) => {
    return showContextMenu({ event: e, props: { actionItem: selectedActionItem } });
  };

  const orgBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ACTION_ITEMS,
      label: strings.navigation.ActionItems,
      href: `/orgs/${org}/action-items`,
    },
    {
      id: 'last',
      label: 'All Items',
      href: ``,
      isActive: true,
    },
  ];

  const clusterBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster.current,
      href: `/orgs/${org}/clusters/${cluster.current}`,
    },
    {
      id: 'last',
      label: 'Action Items',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Breadcrumbs
        data={cluster.current ? clusterBreadcrumbsList : orgBreadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <Toaster />
      <div className="action--items--charts" aria-hidden="true">
        <CardDeck>
          <Card>
            <Card.Header style={{ padding: '1rem', borderBottom: COLORS.BORDER.SOLID_TABLE }}>
              <h2>{strings.actionItemsPage.bySeverity}</h2>
            </Card.Header>
            <Card.Body className="action--items--charts__graph" padded>
              <SeverityChartReact
                baseURL={baseURL}
                filters={
                  isRefreshed.current && !tableInstance.state.filters.length
                    ? [strings.noTranslate.blank]
                    : tableInstance.state.filters
                }
                noData={<ChartEmpty />}
                selectedTeam={selectedTeam}
                selectedAppGroup={selectedAppGroup}
                setFilter={tableInstance.setFilter}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header style={{ padding: '1rem', borderBottom: COLORS.BORDER.SOLID_TABLE }}>
              <h2>{strings.actionItemsPage.topIssues}</h2>
            </Card.Header>
            <Card.Body className="action--items--charts__graph" padded>
              <TopIssues
                baseURL={baseURL}
                filters={
                  isRefreshed.current && !tableInstance.state.filters.length
                    ? [strings.noTranslate.blank]
                    : tableInstance.state.filters
                }
                noData={<ChartEmpty />}
                selectedTeam={selectedTeam}
                selectedAppGroup={selectedAppGroup}
                setFilter={tableInstance.setFilter}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header style={{ padding: '1rem', borderBottom: COLORS.BORDER.SOLID_TABLE }}>
              <h2>{strings.actionItemsPage.topNamespaces}</h2>
            </Card.Header>
            <Card.Body className="action--items--charts__graph" padded>
              <NamespaceChart
                baseURL={baseURL}
                filters={
                  isRefreshed.current && !tableInstance.state.filters.length
                    ? [strings.noTranslate.blank]
                    : tableInstance.state.filters
                }
                noData={<ChartEmpty />}
                selectedTeam={selectedTeam}
                selectedAppGroup={selectedAppGroup}
                setFilter={tableInstance.setFilter}
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header style={{ padding: '1rem', borderBottom: COLORS.BORDER.SOLID_TABLE }}>
              <h2>{strings.actionItemsPage.topWorkloads}</h2>
            </Card.Header>
            <Card.Body className="action--items--charts__graph" padded>
              <TopWorkloads
                baseURL={baseURL}
                filters={
                  isRefreshed.current && !tableInstance.state.filters.length
                    ? [strings.noTranslate.blank]
                    : tableInstance.state.filters
                }
                noData={<ChartEmpty />}
                selectedTeam={selectedTeam}
                selectedAppGroup={selectedAppGroup}
                setFilter={tableInstance.setFilter}
              />
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
      <div className="action--items--container">
        <Card body className="action--items" data-cy="action-items-card">
          <TableCardHeader
            members={members}
            totalRowCount={totalRowCount}
            handleExport={handleExport}
            tableInstance={tableInstance}
            lists={lists}
            teams={teams}
            selectedSavedList={selectedSavedList}
            handleSavedListSelection={handleSavedListSelection}
            setSelectedSavedList={setSelectedSavedList}
            handleGlobalSearch={useAsyncDebounce(handleGlobalSearch, 250)}
            handleOpenModal={handleOpenModal}
            setSelectedTeam={setSelectedTeam}
            selectedTeam={selectedTeam}
            appGroups={appGroups}
            selectedAppGroup={selectedAppGroup}
            setSelectedAppGroup={setSelectedAppGroup}
            selectedActionItem={selectedActionItem}
            globalSearch={globalSearch}
            baseURL={baseURL}
            selectedCluster={selectedCluster}
          />
          <Table
            setSelectedFlatRows={setSelectedFlatRows}
            pageSizeCount={tableInstance.state.pageSize}
            totalRowCount={totalRowCount}
            fetchActionItemsDebounced={fetchActionItemsDebounced}
            tableInstance={tableInstance}
            showContextMenu={showContextMenu}
            members={members}
            baseURL={baseURL}
            router={router}
            route={route}
            setSelectedActionItem={setSelectedActionItem}
            setShowModal={setShowModal}
            loaded={loaded}
            caption="Action items table"
            setShowViewMode={setShowViewMode}
            showViewMode={showViewMode}
            isOwner={isOwner}
          />
          {!loaded && <LoadingSpinner />}
        </Card>
        <DescriptionPanel
          selectedItem={formattedSelectedActionItem}
          setSelectedItem={setSelectedActionItem}
          page="action--items"
          openContextMenu={openContextMenu}
        />
      </div>
      {showModal.modal === 'delete' && selectedList && (
        <DeleteModal
          show={showModal.show}
          setShowModal={setShowModal}
          item={selectedList}
          deleteList={deleteList}
          deleteActionItem={deleteActionItem}
        />
      )}
      {showModal.modal === 'edit' && selectedList && (
        <EditModal
          show={showModal.show}
          item={selectedList}
          importedList={importedList}
          setImportedList={setImportedList}
          listName={listName}
          setListName={setListName}
          listNames={listNames}
          ImportListOptions={ImportListOptions}
          query={query}
          setQuery={setQuery}
          deleteListModal={deleteListModal}
          editList={editList}
          router={router}
          org={org ? org : ''}
          getLists={fetchLists}
          closeModal={closeModal}
        />
      )}
      {showModal.modal === 'create' && (
        <CreateListModal
          showModal={showModal}
          closeModal={closeModal}
          listNames={listNames}
          listType={listType}
          ImportListOptions={ImportListOptions}
          query={query}
          setQuery={setQuery}
          submitNewList={submitNewList}
          error={modalError}
        />
      )}
      {showModal.modal === 'confirmAddToList' && (
        <ConfirmAddToList
          showModal={showModal}
          closeModal={closeModal}
          handleAddToManualList={handleAddToManualList}
          handleAddToQueryList={handleAddToQueryList}
          query={query}
        />
      )}
      {showModal.modal === 'ticket' && (
        <CreateTicketModal
          route={route}
          router={router}
          showModal={showModal.show}
          closeModal={closeModal}
          items={[showModal.meta?.actionItem] as ActionItem[] | undefined}
          showViewMode={showViewMode}
          afterCreateTicketCallback={updateActionItemTicketInfo}
          isOwner={isOwner}
        />
      )}
    </LayoutReact>
  );
};

export default ActionItemsTable;
