import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { Card } from '@fairwindsops/ui-components';

import { formatData } from '~views/organization/efficiency/Efficiency.helpers.react';

import { ResourceUsageDataProps } from '../../Costs.types.react';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import { strings } from '~utils/strings.js';

import './ResourceUsageData.react.scss';

dayjs.extend(timezone);

const ResourceUsageData = ({ type, summaries, limits, dates }: ResourceUsageDataProps) => {
  const timeZone = getCurrentTimezone();
  const BottomData = ({ section }: { section: string }) => {
    if (limits && Object.keys(limits).length) {
      const formattedCurrentValue =
        type === strings.noTranslate.cpuRaw
          ? limits.settings[section][type]
          : formatData(limits.settings[section][type], type);

      const formattedRecommendedValue =
        type === strings.noTranslate.cpuRaw
          ? limits.recommendations[section][type]
          : formatData(limits.recommendations[section][type], type);

      const selectedDisplayValue = type === strings.noTranslate.cpuRaw ? 'mCPU' : 'GB';

      return (
        <div className="resource-usage-data__data">
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Current}:`}</strong>
            <span>
              {formattedCurrentValue
                ? `${
                    Number.isInteger(formattedCurrentValue) ? formattedCurrentValue : formattedCurrentValue.toFixed(2)
                  } ${selectedDisplayValue}`
                : 'unset'}
            </span>
          </div>
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Recommended}:`}</strong>
            <span>
              {formattedRecommendedValue
                ? `${
                    Number.isInteger(formattedRecommendedValue)
                      ? formattedRecommendedValue
                      : formattedRecommendedValue.toFixed(2)
                  } ${selectedDisplayValue}`
                : 'unset'}
            </span>
          </div>
          <div className="resource-usage-data__data-container">
            <strong>{`${strings.efficiency.Difference}:`}</strong>
            <span>
              {Number.isNaN(formattedRecommendedValue - formattedCurrentValue)
                ? 'N/A'
                : `${
                    Number.isInteger(formattedRecommendedValue - formattedCurrentValue)
                      ? formattedRecommendedValue - formattedCurrentValue
                      : (formattedRecommendedValue - formattedCurrentValue).toFixed(2)
                  } ${selectedDisplayValue}`}
            </span>
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <Card className="resource-usage-data">
        <Card.Body className="resource-usage-data__container" padded>
          <div>
            <p className="resource-usage-data__title">
              {`${strings.navigation.Costs}: ${
                timeZone
                  ? dayjs(dates.start).tz(timeZone).format('MM/DD/YYYY')
                  : dayjs(dates.start).format('MM/DD/YYYY')
              } - ${
                timeZone ? dayjs(dates.end).tz(timeZone).format('MM/DD/YYYY') : dayjs(dates.end).format('MM/DD/YYYY')
              }`}
            </p>
            <div className="resource-usage-data__data-container">
              <strong>
                {type === strings.noTranslate.cpuRaw
                  ? strings.efficiency.avgCPUPerPod
                  : strings.efficiency.avgMemoryPerPod}
              </strong>
              <span>{`$${(
                summaries[0]?.costs?.actual &&
                summaries[0]?.costs?.actual[type === strings.noTranslate.cpuRaw ? 'cpu' : 'memory'] /
                  summaries[0]?.resources.averagePodCount
              )?.toFixed(2)}`}</span>
            </div>
            <div className="resource-usage-data__data-container">
              <strong>{strings.efficiency.costWithRec}</strong>
              <span>{`$${(
                summaries[0]?.costs?.recommendation &&
                summaries[0]?.costs?.recommendation[type === strings.noTranslate.cpuRaw ? 'cpu' : 'memory'] /
                  summaries[0]?.resources.averagePodCount
              )?.toFixed(2)}`}</span>
            </div>
          </div>
          <div>
            <p className="resource-usage-data__title">{strings.efficiency.Requests}</p>
            <BottomData section="requests" />
          </div>
          <div>
            <p className="resource-usage-data__title">{strings.efficiency.Limits}</p>
            <BottomData section="limits" />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ResourceUsageData;
