import React, { useState, useEffect, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ChartType, Cluster, DateType } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { convertFiltersToURL } from '~reactHelpers';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import '../Charts.react.scss';
import './TopCVEs.react.scss';

type TopCVEsProps = {
  baseURL?: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  noData?: React.ReactNode;
  setFilter: (columnId: string, updater: any) => void;
  firstSeenRange: DateType | null;
};

const TopCVEs = ({ baseURL, noData, filters, cluster, setFilter, firstSeenRange }: TopCVEsProps) => {
  const [topCVEs, setTopCVEs] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getTopCVEs();
    return () => {
      isMounted.current = false;
    };
  }, [cluster]);

  useEffect(() => {
    getTopCVEs();
  }, [filters, firstSeenRange?.start, firstSeenRange?.end]);

  const getTopCVEs = async () => {
    try {
      setLoaded(false);
      const params = new URLSearchParams(cluster ? `groupBy=cveCode&cluster=${cluster.Name}` : 'groupBy=cveCode');
      const URLParams = convertFiltersToURL(filters, params);
      if (firstSeenRange?.start) {
        URLParams.append('firstSeen', firstSeenRange?.start);
      }
      if (firstSeenRange?.end) {
        URLParams.append('firstSeenEnd', firstSeenRange?.end);
      }
      const response = await sendRequest('GET', `${baseURL}/vulnerabilities/top?${URLParams}`, {}, null);
      const topCVEs = response.slice(0, 5).reverse();
      if (isMounted.current) {
        setTopCVEs(topCVEs);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_vuln_top_cves: ', e);
      toast.error(strings.vulnerabilities.errorTopCVEs);
    }
  };

  if (!loaded) return <LoadingSpinner />;

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'cveCode')?.value?.length) return;
    setFilter('cveCode', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('cveCode', []);
    chartClicked.current = false;
  };

  const tooltip = (data: any, color: string) => {
    return (
      <div
        className="top-cves-tooltip"
        style={{
          width: `${
            typeof data.value === 'string' ? (data.value.length + data.count.toString().length) * 0.9 : '20'
          }rem`,
        }}
      >
        <div
          className="top-cves-tooltip__square"
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div>
          {data.value}: <strong>{data.count}</strong>
        </div>
      </div>
    );
  };

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'cveCode')?.value?.length}
      />
      {topCVEs?.length ? (
        <div aria-label={strings.ariaLabels.topCVEsChart} className="vulnerabilities--charts">
          <BarChart
            data={topCVEs}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[
              COLORS.CHARTS.PURPLE[100],
              COLORS.CHARTS.PURPLE[200],
              COLORS.CHARTS.PURPLE[300],
              COLORS.CHARTS.PURPLE[400],
              COLORS.CHARTS.PURPLE[500],
            ]}
            layout="horizontal"
            tooltip={tooltip}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default TopCVEs;
