<template>
  <component :is="component" v-bind:org="org"></component>
</template>

<script>
import Organization from '@/views/organization/index.vue';
import ErrorPage from '@/views/error/error.vue';
import StrictOrg from '@/views/strict-org/strict-org.vue';

export default {
  props: {
    org: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      const isStrictSAMLOrg = this.$store.getters.strictSAMLOrgs
        .find((org) => org === this.$route.params.org);
      if (isStrictSAMLOrg) {
        return 'StrictOrg';
      }

      if (this.$store.getters.organization === null) {
        return 'ErrorPage';
      }
      return 'Organization';
    },
  },
  components: {
    StrictOrg,
    Organization,
    ErrorPage,
  },
};
</script>
