import { AddButton } from '@fairwindsops/ui-components';
import React from 'react';
import { Column, Row } from 'react-table';
import { POLICY_MAPPING } from '~reactComponents/NavigationReact/Navigation.config.react';
import Tag from '~reactComponents/Tags/Tag.react';
import { IRouter } from '~utils/global.types.react';
import { strings } from '~utils/strings';

export const TABLE_COLUMNS = (
  viewAppGroup: any,
  isOrgOwner: boolean,
  router: () => IRouter,
): Column[] => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }: { row: Row }) => {
      return (
        <a className="app-group-name-column" onClick={() => viewAppGroup(row)}>
          {row.original.name}
        </a>
      );
    },
  },
  {
    Header: 'Policy Mappings',
    accessor: 'policyMappings',
    Cell: ({ row }: { row: Row }) => {
      const policyMappings = row.original.policyMappings;

      if (policyMappings?.length) {
        return (
          <div className="app-groups-tag-container">
            {policyMappings.map((policyMapping: string) => (
              <Tag
                hollow={true}
                fontSize={'0.875rem'}
                isRemovable={false}
                key={policyMapping}
                tagClassNames="app-groups-table-tag"
              >
                {policyMapping}
              </Tag>
            ))}
          </div>
        );
      }

      if (isOrgOwner) {
        return (
          <AddButton
            buttonText={strings.policyMapping.addPolicyMapping}
            action={() =>
              router().push({
                name: POLICY_MAPPING,
              })
            }
            testLabel="add-button"
          />
        );
      }

      return <>{strings.general.unavailable}</>;
    },
  },
  {
    Header: 'Open',
    accessor: 'open',
    Cell: ({ row }: { row: Row }) => {
      return (
        <a
          className="app-group-badge__container"
          href={
            row.original.openLink ? `${row.original.openLink}&from='app-groups'&redirect=true` : ''
          }
          tabIndex={!row.original.openLink ? -1 : 0}
        >
          <span className="app-group-badge__text app-group-badge__value">{row.original.open}</span>
          <span className="app-group-badge__text app-group-badge__label">
            {strings.appGroups.seeActionItems}
          </span>
        </a>
      );
    },
  },
  {
    Header: 'Fixed',
    accessor: 'fixed',
    Cell: ({ row }: { row: Row }) => {
      return (
        <a
          className="app-group-badge__container"
          href={
            row.original.fixedLink
              ? `${row.original.fixedLink}&from='app-groups'&redirect=true`
              : ''
          }
          tabIndex={!row.original.openLink ? -1 : 0}
        >
          <span className="app-group-badge__text app-group-badge__value">{row.original.fixed}</span>
          <span className="app-group-badge__text app-group-badge__label">
            {strings.appGroups.seeActionItems}
          </span>
        </a>
      );
    },
  },
  {
    Header: 'Monthly Billed Cost',
    accessor: 'monthlyBilledCost',
    Cell: ({ row }: { row: Row }) => {
      return <>{row.original.monthlyBilledCost}</>;
    },
  },
];
