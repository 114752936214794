import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { strings } from '~utils/strings';
import { v4 as uuidv4 } from 'uuid';
import './InputTag.react.scss';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';

type InputTagProps = {
  field?: string;
  section?: string;
  onTagAdded?: (value: string, field: string, section: string) => void;
  onTagRemoved?: (valus: string, field: string, section: string) => void;
  resetTags?: boolean;
  placeholder?: string;
  tagClasses?: string;
  defaultTagValues?: string[];
  resetDefaultValues?: boolean;
  reloadTags?: boolean;
  inputContainerClassName?: string;
  isDisabled?: boolean;
  dataCy?: string;
  suggestedData?: string[];
  openOptionsOnClick?: boolean;
};

const InputTag = ({
  onTagAdded,
  onTagRemoved,
  resetTags,
  field,
  section,
  placeholder,
  tagClasses,
  defaultTagValues,
  resetDefaultValues,
  reloadTags,
  inputContainerClassName,
  isDisabled,
  dataCy,
  suggestedData,
  openOptionsOnClick,
}: InputTagProps) => {
  const [input, setInput] = useState('');
  const [tags, setTags] = useState<string[]>(defaultTagValues?.length ? defaultTagValues : []);
  const isSuggestionClicked = useRef<boolean>(false);
  const [clickedToOpen, setClickedToOpen] = useState<boolean>(false);

  useEffect(() => {
    if (resetTags) {
      setTags([]);
      return;
    }
    if (resetDefaultValues || reloadTags) {
      setTags(defaultTagValues || []);
      return;
    }
  }, [resetTags, resetDefaultValues]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);
  };

  const isInvalidTagValue = (value: string) => {
    if (!field) {
      return false;
    }

    if ([strings.appGroups.labelsKey, 'namespaceLabels'].includes(field)) {
      const splitValue = value.split('=');

      return splitValue.length !== 2;
    }

    return false;
  };

  const handleAddTag = (e: any, trimmedInput: string) => {
    e.preventDefault();

    // validate the tag value if the field is "labels" or "namespaceLabels".
    if (isInvalidTagValue(trimmedInput)) {
      toast.error(strings.appGroups.tagValueError);
      return;
    }

    setTags((prevState) => [...prevState, trimmedInput]);
    setInput('');
    if (onTagAdded && field && section) {
      onTagAdded(trimmedInput, field, section);
    }
  };

  const onKeyDown = (e: any) => {
    const { key: inputKey } = e;
    const trimmedInput = input.trim();

    if (['Enter', ' ', ','].includes(inputKey) && trimmedInput.length && !tags.includes(trimmedInput)) {
      handleAddTag(e, trimmedInput);
    }
  };

  const deleteTag = (index: number) => {
    if (isDisabled) {
      return;
    }
    if (onTagRemoved && field && section) {
      onTagRemoved(tags[index], field, section);
    }
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const onBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      if (isSuggestionClicked.current) {
        isSuggestionClicked.current = false;
        return;
      } else {
        setClickedToOpen(false);
      }
      const trimmedInput = input.trim();

      if (trimmedInput.length && !tags.includes(trimmedInput)) {
        handleAddTag(e, trimmedInput);
      }
    }, 300);
  };

  const onClick = () => {
    if (openOptionsOnClick) {
      setClickedToOpen(true);
    }
  };

  const filteredSuggestedData = useMemo(
    () =>
      clickedToOpen && !input
        ? suggestedData?.filter((data) => !tags.includes(data))
        : input
          ? suggestedData?.filter((data) => data.includes(input.trim()) && !tags.includes(data))
          : [],
    [suggestedData, input, clickedToOpen, clickedToOpen, tags],
  );

  const onSuggestionClicked = (e: any, data: string) => {
    isSuggestionClicked.current = true;
    setClickedToOpen(false);
    handleAddTag(e, data);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className={`input-container ${inputContainerClassName || ''}`}>
        {tags.map((tag, index) => (
          <div className={`input-tag ${tagClasses || ''}`} key={tag}>
            {tag}
            <button onClick={() => deleteTag(index)}>
              <CloseIcon width="0.5rem" height="0.5rem" fill="#000" />
            </button>
          </div>
        ))}
        <input
          value={input}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          disabled={isDisabled}
          data-cy={dataCy || ''}
        />
      </div>
      {filteredSuggestedData?.length ? (
        <div className="input-tag-suggestion-data">
          {filteredSuggestedData.map((data) => (
            <div
              className="input-tag-suggestion-data-item"
              key={uuidv4()}
              onClick={(e) => onSuggestionClicked(e, data)}
            >
              {data}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InputTag;
