import PasswordReset from './PasswordReset.react';

export default {
  name: 'password-reset',
  components: {
    'password-reset-page': PasswordReset,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
  },
};
