import { strings } from '~utils/strings';
import { Labels } from './Compliance.types.react';

export const standardizedReports = (name: string) => {
  let standardName = '';

  switch (name) {
    case 'soc_2':
      standardName = strings.reportTypes.soc2;
      break;
    case 'iso':
      standardName = strings.reportTypes.iso27001;
      break;
    case 'nsa':
      standardName = strings.reportTypes.nsa;
      break;
    default:
      standardName = strings.reportTypes.hipaa;
      break;
  }
  return standardName;
};

export const formatStatus = (selection: string) => {
  switch (selection) {
    case Labels.notStarted:
    case Labels.notImplemented:
      return strings.checkStatus.notStarted;
    case Labels.needsEvidence:
      return strings.checkStatus.needsEvidence;
    case Labels.passing:
    case Labels.implemented:
      return strings.checkStatus.passing;
    case Labels.notInScope:
      return strings.checkStatus.notInScope;
    case Labels.failing:
      return strings.checkStatus.failing;
    default:
      return selection;
  }
};
