import React from 'react';

import { Item, ItemParams, Menu, Submenu } from 'react-contexify';

import { strings } from '~utils/strings';

import ResolveIcon from '~assetIcons/resolve.svg';
import SnoozeIcon from '~reactComponents/Icons/Snooze.icon.react';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';
import { ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID } from './DescriptionPanel.config.react';
import { ACTION_ITEM_RESOLUTIONS } from '~utils/constants';
import { sendRequest } from '~utils/request';
import { IRoute, IRouter } from '~utils/global.types.react';
import logger from '~utils/logger';
import { getCurrentTimezone } from '~utils/global.helpers.react';
import dayjs from 'dayjs';
import { AdmissionActionItemType } from '../../ReactAdmission.types.react';

type ContextMenuProps = {
  toast: any;
  router: () => IRouter;
  route: IRoute;
  reloadActionItem: (item: AdmissionActionItemType) => void;
  item: AdmissionActionItemType;
};

const snoozedItems = ['Day', 'Week', 'Month'];

const ContextMenu = ({ toast, router, route, reloadActionItem, item }: ContextMenuProps) => {
  const org = route?.params?.org;
  const baseUrl = `/v0/organizations/${org}`;
  const timeZone = getCurrentTimezone();
  const handleResolution = (items: ItemParams<any, any>, resolution: string) => {
    handleResolutionSubmit(items.props?.item.ID, resolution);
  };

  const handleResolutionSubmit = (ID: number, Resolution: string) => {
    const payload = {
      ID,
      Resolution,
    };
    const resp = sendRequest('PATCH', `${baseUrl}/admission-action-items/resolution`, { data: payload }, null);
    resp
      .then(() => {
        toast.success(strings.actionItemsPage.contextMenu.resolutionSuccess);
        reloadActionItem(item);
      })
      .catch((e: { message: any }) => {
        toast.error(`Error on setting resolution: ${e.message}`);
        logger.logError('error_updating_resolution', e);
        return;
      });
  };

  const handleSnoozeClick = (items: ItemParams<any, any>, snoozeOption: string) => {
    handleSnoozeSubmit(items.props?.item.ID, snoozeOption);
  };

  const handleSnoozeSubmit = (ID: number, snoozeOption: any) => {
    const payload = {
      ID,
      SnoozeUntil: '',
    };
    if (snoozeOption !== 'unsnooze') {
      const snoozeUntil = timeZone
        ? dayjs().add(1, snoozeOption).tz(timeZone).format()
        : dayjs().add(1, snoozeOption).format();
      payload.SnoozeUntil = snoozeUntil;
      const resp = sendRequest('PATCH', `${baseUrl}/admission-action-items/snooze`, { data: payload }, null);
      resp
        .then(() => {
          toast.success(
            strings.actionItemsPage.contextMenu.snoozeSuccess.replace(
              strings.actionItemsPage.contextMenu.action,
              strings.actionItemsPage.contextMenu.snoozedFor.replace(
                strings.actionItemsPage.contextMenu.period,
                strings.general[snoozeOption],
              ),
            ),
          );
          reloadActionItem(item);
        })
        .catch((e: { message: any }) => {
          console.error('Error on snooze', e);
          logger.logError('error_snooze', e);
          toast.error(`Error on snoozing: ${e.message}`);
          return;
        });
    } else {
      const payload = {
        ID,
        Resolution: '',
      };
      const resp = sendRequest('PATCH', `${baseUrl}/admission-action-items/resolution`, { data: payload }, null);
      resp
        .then(() => {
          toast.success(
            strings.actionItemsPage.contextMenu.snoozeSuccess.replace(
              strings.actionItemsPage.contextMenu.action,
              strings.actionItemsPage.contextMenu.unsnoozed,
            ),
          );
          reloadActionItem(item);
        })
        .catch((e: { message: any }) => {
          console.error('Error on snooze', e);
          logger.logError('error_snooze', e);
          toast.error(`Error on snoozing: ${e.message}`);
          return;
        });
    }
  };

  return (
    <Menu id={ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Submenu
        label={
          <>
            <img
              className="resolve-icon"
              src={ResolveIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.resolveIcon}
            />
            {strings.general.Resolve}
          </>
        }
      >
        {ACTION_ITEM_RESOLUTIONS.map((resolution, idx) => (
          <Item closeOnClick={true} onClick={(actionItem) => handleResolution(actionItem, resolution.value)} key={idx}>
            {resolution.label}
          </Item>
        ))}
      </Submenu>

      <Submenu
        label={
          <>
            <SnoozeIcon />
            {strings.general.Snooze}
          </>
        }
      >
        {snoozedItems.map((item) => {
          return (
            <Item onClick={(actionItem) => handleSnoozeClick(actionItem, item)} key={item}>
              1 {item}
            </Item>
          );
        })}
        <Item onClick={(actionItem) => handleSnoozeClick(actionItem, 'unsnooze')} key={'unsnooze'}>
          {strings.general.Unsnooze}
        </Item>
      </Submenu>
    </Menu>
  );
};

export default ContextMenu;
