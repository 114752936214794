import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import Select, { StylesConfig } from 'react-select';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner.react';

import { Card } from '@fairwindsops/ui-components';
import TopOrgIssuesChart from './TopOrgIssuesChart.react';

import {
  OrgIssue,
  SearchCriteria,
  SearchValues,
  TimeUnits,
  SelectedReports,
  reportOptions,
  filteringDateOptions,
} from './TopOrgIssues.types.react';
import { Organization, OptionType, IRouter } from '~globalTypes';
import { numericalSortDescending } from '~utils/helpers';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { strings } from '~utils/strings';
import { getCurrentTimezone } from '~reactHelpers';

import './TopOrgIssues.react.scss';

dayjs.extend(timezone);

type TopOrgIssuesProps = {
  organization: Organization;
  router: () => IRouter;
};

const TopOrgIssues = ({ organization, router }: TopOrgIssuesProps) => {
  const [selectedDateFilter, setSelectedDateFilter] = useState<OptionType>(filteringDateOptions[2]);
  const [selectedReport, setSelectedReport] = useState<OptionType>(reportOptions[0]);
  const [issues, setIssues] = useState<OrgIssue[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const firstSeenCriteria = useRef<string | null>();

  const timeZone = getCurrentTimezone();

  useEffect(() => {
    getIssues();
  }, [organization, selectedReport, selectedDateFilter]);

  const getIssues = async () => {
    setLoading(true);
    const query = createTopQuery();
    try {
      const issuesResponse = await sendRequest(
        'GET',
        `/v0/organizations/${organization.Name}/action-items/top?${query}`,
        {},
        null,
      );
      const endIndex = issuesResponse.length > 5 ? 5 : issuesResponse.length;
      setIssues(() => sortIssues(issuesResponse, endIndex));
    } catch (e) {
      logger.logError('error_org_issues', e);
    }
    setLoading(false);
  };

  const createTopQuery = () => {
    const query = new URLSearchParams();
    appendQuery(query, SearchCriteria.Fixed, SearchValues.False);
    appendQuery(query, SearchCriteria.Resolution, SearchValues.None);
    appendQuery(
      query,
      SearchCriteria.GroupBy,
      selectedReport?.value !== SelectedReports.Issues ? selectedReport?.value : null,
    );
    firstSeenCriteria.current = getFirstSeenCriteria();
    appendQuery(query, SearchCriteria.FirstSeen, firstSeenCriteria.current);
    return query.toString();
  };

  const appendQuery = (query: URLSearchParams, criteria: string, value: string | null) => {
    if (!query || !criteria || !value) return;
    query.append(criteria, value);
  };

  const getFirstSeenCriteria = () => {
    switch (selectedDateFilter?.value) {
      case TimeUnits.Daily:
        return timeZone
          ? dayjs()
              .subtract(23, TimeUnits.Hours)
              .subtract(59, TimeUnits.Minutes)
              .utc()
              .tz(timeZone)
              .toISOString()
          : dayjs()
              .subtract(23, TimeUnits.Hours)
              .subtract(59, TimeUnits.Minutes)
              .utc()
              .toISOString();
      case TimeUnits.Weekly:
        return timeZone
          ? dayjs().subtract(1, TimeUnits.Week).utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, TimeUnits.Week).utc().toISOString();
      case TimeUnits.Monthly:
        return timeZone
          ? dayjs().subtract(1, TimeUnits.Month).utc().tz(timeZone).toISOString()
          : dayjs().subtract(1, TimeUnits.Month).utc().toISOString();
    }
    return null;
  };

  const sortIssues = (issuesResponse: OrgIssue[], endIndex: number) => {
    if (!issuesResponse || !issuesResponse.length || !endIndex) return;
    return issuesResponse
      ?.sort((a, b) => numericalSortDescending(a.Count, b.Count))
      .slice(0, endIndex)
      .reverse();
  };

  const customStyles: StylesConfig<OptionType, true> = {
    control: (provided) => ({
      ...provided,
      border: 'none',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: strings.noTranslate.transparent,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: 'none',
    }),
  };

  const TopOrgIssuesHeader = () => (
    <div className="overview-top-issues__issues-header">
      <div className="card-heading overview-top-issues__left" data-cy="issues-report-options">
        <Select
          styles={customStyles}
          options={reportOptions}
          onChange={(e) => onOptionSelected(e, true)}
          aria-label="issue filter"
          value={selectedReport}
          className="overview-top-issues__report-options"
        />
      </div>
      <div className="card-heading overview-top-issues__right" data-cy="issues-date-options">
        <Select
          styles={customStyles}
          options={filteringDateOptions}
          onChange={(e) => onOptionSelected(e, false)}
          aria-label="issue timeframe filter"
          value={selectedDateFilter}
          className="overview-top-issues__filtering-date-options"
        />
      </div>
    </div>
  );

  const onOptionSelected = (e: unknown, isReportOption: boolean) => {
    isReportOption
      ? setSelectedReport(e as OptionType)
      : setSelectedDateFilter(() => e as OptionType);
  };

  const TopOrgIssuesNoData = () => {
    return (
      <div className="overview-top-issues__no-data">Sorry, we have no data for that search.</div>
    );
  };

  return (
    <Card className="overview-top-issues" data-cy="overview-topIssues-card">
      <Card.Header>
        <TopOrgIssuesHeader />
      </Card.Header>
      <Card.Body aria-hidden="true" className="overview-top-issues__body" padded>
        {loading && <LoadingSpinner />}
        {!loading && (!issues || !issues?.length) && <TopOrgIssuesNoData />}
        {!loading && issues && issues?.length > 0 && (
          <TopOrgIssuesChart
            selectedReport={selectedReport}
            issues={issues}
            router={router}
            firstSeenCriteria={firstSeenCriteria.current}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default TopOrgIssues;
