export interface Activity {
  commitHash: string;
  date: string;
  ID: number;
  time: string;
  title: string;
  repoId: number;
}

export const Labels = {
  LatestScans: 'Latest Scans',
} as const;
