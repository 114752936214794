import React from 'react';
import ReactMarkdown from 'react-markdown';

import './ActionItemInfoPanel.react.scss';

interface ContentItem {
  title: string;
  content: string;
}

type ActionItemInfoPanelProps = {
  contents: ContentItem[];
};

const ActionItemInfoPanel = ({ contents }: ActionItemInfoPanelProps): JSX.Element => {
  return (
    <>
      {contents.map((item, index) => {
        return (
          item.content.length > 0 && (
            <div className="admission-item-content" key={index}>
              <h3 className="admission-item-content-title">{item.title}</h3>
              <div className="admission-item-content-item">
                <ReactMarkdown>{item.content}</ReactMarkdown>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};

export default ActionItemInfoPanel;
