import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

type ExportButtonProps = {
  exportActionItemsOptions?: string[];
  size?: 'sm' | 'lg' | undefined;
  title?: string;
  handleExport?: (action: string) => Promise<void>;
};

const ExportButton = ({
  exportActionItemsOptions,
  size,
  title,
  handleExport,
}: ExportButtonProps) => {
  return (
    <DropdownButton title={title} size={size} className="action--items--header--section__dropdown">
      {exportActionItemsOptions?.map((exportType: string, idx: number) => (
        <Dropdown.Item key={idx} onClick={() => handleExport && handleExport(exportType)}>
          {exportType}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default ExportButton;
