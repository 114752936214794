import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { ReportType, SelectedReport } from '../Compliance.types.react';
import { OptionType, IStore } from '~globalTypes';
import { TYPES_OPTIONS } from './Compliance.config.react';
import Multiselect from '~reactComponents/Multiselect/Multiselect.react';
import CloseIcon from '~reactIcons/Close.icon.react';

import { strings } from '~utils/strings';

type ModalProps = {
  show: string;
  closeModal: (newModal?: string) => void;
  selectedReport: SelectedReport | null;
  report: ReportType;
  setReport: React.Dispatch<React.SetStateAction<ReportType>>;
  isOwner: boolean;
  store: () => IStore;
  createReport: (name: string) => Promise<void>;
  submitEdit: (name?: string) => Promise<void>;
};

const CreateEditModal = ({
  show,
  closeModal,
  selectedReport,
  report,
  setReport,
  isOwner,
  store,
  createReport,
  submitEdit,
}: ModalProps): JSX.Element => {
  const clusters: OptionType[] = store().getters.clusters.map((cluster) => ({
    value: cluster.Name,
    label: cluster.Name,
  }));
  const [disabled, setDisabled] = useState<boolean>(true);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [reportName, setReportName] = useState<string>('');

  useEffect(() => {
    disableSubmit(reportName);
  }, [reportName, report.clusters, report.standards]);

  useEffect(() => {
    setReportName(selectedReport?.name || '');
    setInvalid(false);
  }, []);

  const disableSubmit = (name: string) => {
    let currentlyInvalid;
    if (name.length > 0 && name.length < 65) {
      currentlyInvalid = false;
      setInvalid(false);
    } else {
      currentlyInvalid = true;
      setInvalid(true);
    }

    if (isOwner && !currentlyInvalid && report?.standards?.length && report?.clusters?.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const openDeleteModal = () => {
    closeModal('delete');
  };

  const handleChange = (e: React.ChangeEvent) => {
    const nameChange = e.target as HTMLInputElement;
    setReportName(nameChange.value);
    disableSubmit(nameChange.value);
  };

  const handleSubmit = () => {
    setReport({ ...report, name: reportName });
    if (show === 'create') {
      createReport(reportName);
    } else if (show === 'edit') {
      submitEdit(reportName);
    }
  };

  return (
    <Modal show={show !== 'delete'} onHide={closeModal} size="lg">
      <Modal.Header>
        <Modal.Title>
          {selectedReport ? `Edit ${selectedReport.name} Report` : 'New Report'}
        </Modal.Title>
        <div className="custom-close-icon">
          <CloseIcon onClick={() => closeModal()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group style={{ width: '95%' }}>
            <Form.Label>{strings.compliance.reportName}</Form.Label>
            <Form.Control
              type="text"
              placeholder={'Report Name'}
              defaultValue={report.name}
              onChange={(e) => handleChange(e)}
              isInvalid={invalid}
              readOnly={!isOwner}
            />
            <Form.Control.Feedback type="invalid">
              {strings.compliance.nameWrongFeedback}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Form className="multiselect-group">
          <Multiselect
            title={strings.general.Clusters}
            allTags={report}
            setAllTags={setReport}
            options={clusters}
            isAdmin={isOwner}
            tagCollection="clusters"
          />
          <Multiselect
            title={strings.general.Types}
            allTags={report}
            setAllTags={setReport}
            options={TYPES_OPTIONS}
            isAdmin={isOwner}
            tagCollection="standards"
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {show === 'create' && (
          <>
            <Button onClick={() => closeModal('create')} variant="secondary">
              {strings.general.Cancel}
            </Button>
            <Button onClick={handleSubmit} disabled={disabled}>
              {strings.compliance.createReport}
            </Button>
          </>
        )}
        {show === 'edit' && (
          <>
            <Button variant="danger" onClick={openDeleteModal} disabled={!isOwner}>
              {strings.compliance.deleteReport}
            </Button>
            <Button onClick={handleSubmit} disabled={disabled}>
              {strings.compliance.editReport}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEditModal;
