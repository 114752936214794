import React from 'react';
import { TableInstance } from 'react-table';

import Tag from '~reactComponents/Tags/Tag.react';

import { CommonLabels } from '~globalTypes';

import { TableColumnNames, TableAccessors } from './ImpactedRepositoriesTable.types.react';

export const getTableColumns = () => {
  return [
    {
      Header: TableColumnNames.Repositories,
      accessor: TableAccessors.Repositories,
      className: 'no-overflow',
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        const repositoryValue = values?.repository ? values.repository : CommonLabels.Unavailable;
        return <span title={repositoryValue}>{repositoryValue}</span>;
      },
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: TableColumnNames.Namespace,
      accessor: TableAccessors.Namespace,
      className: 'tag',
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        return (
          <Tag tagClassNames="tag-ellipsis">
            {values?.namespace ? values.namespace : CommonLabels.Unavailable}
          </Tag>
        );
      },
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: TableColumnNames.Name,
      accessor: TableAccessors.Name,
      className: 'no-overflow',
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        const nameValue = values?.name ? values.name : CommonLabels.Unavailable;
        return <span title={nameValue}>{nameValue}</span>;
      },
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: TableColumnNames.Container,
      accessor: TableAccessors.Container,
      className: 'no-overflow',
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        const containerValue = values?.container ? values.container : CommonLabels.Unavailable;
        return <span title={containerValue}>{containerValue}</span>;
      },
      minWidth: 150,
      maxWidth: 150,
      width: 150,
    },
    {
      Header: TableColumnNames.Kind,
      accessor: TableAccessors.Kind,
      className: 'tag',
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        return (
          <Tag tagClassNames="tag-ellipsis">
            {values?.kind ? values.kind : CommonLabels.Unavailable}
          </Tag>
        );
      },
      minWidth: 150,
      maxWidth: 200,
      width: 200,
    },
  ];
};
