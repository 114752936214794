import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';
import Select from 'react-select';

import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import { fixID } from '~utils/helpers';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import { OptionType } from '~utils/global.types.react';

import logger from '~logger';

import { IPolicyMapping } from '../../PolicyMapping.types.react';

import './UpdatePolicyMappingModal.react.scss';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';

type UpdatePolicyMappingModalProps = {
  organizationName: string;
  isModalShown?: boolean;
  onConfirmClicked?: () => void;
  onToggleEnabledClicked?: () => void;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  selectedPolicyMapping: IPolicyMapping | null;
  onDeleteModalShown?: (policyMappingName: string) => void;
  isViewing?: boolean;
};

type Context = {
  agent: boolean;
  admission: boolean;
  ci: boolean;
};

const UpdatePolicyMappingModal = ({
  organizationName,
  isModalShown,
  onConfirmClicked,
  onToggleEnabledClicked,
  onModalHidden,
  selectedPolicyMapping,
  onDeleteModalShown,
  isViewing,
}: UpdatePolicyMappingModalProps) => {
  const [policyMappingName, setPolicyMappingName] = useState<string>('');
  const [appGroups, setAppGroups] = useState<OptionType[]>([]);
  const [policies, setPolicies] = useState<OptionType[]>([]);
  const [contexts, setContexts] = useState<Context>({
    agent: true,
    admission: true,
    ci: true,
  });
  const [appGroupOptions, setAppGroupOptions] = useState<OptionType[]>([]);
  const [policyOptions, setPolicyOptions] = useState<OptionType[]>([]);

  const [block, setBlock] = useState<boolean | undefined>();
  const [enabled, setEnabled] = useState<boolean | undefined>();

  const reportsRef = useRef<string[]>([]);
  const policiesMap = useRef<Record<string, string[]>>({});

  const baseURL = `/v0/organizations/${organizationName}`;

  const getAppGroups = async () => {
    try {
      const appGroupRequest = await sendRequest('GET', `${baseURL}/app-groups`, {}, null);
      setAppGroupOptions(
        appGroupRequest?.length
          ? appGroupRequest.map((appGroup: any) => ({ label: appGroup.name, value: appGroup.name }))
          : [],
      );
    } catch (e) {
      logger.logError('error_retrieving_app_groups', e);
      toast.error(<b>{strings.appGroups.errorRetrievingAppGroups}</b>);
    }
  };

  const getPolicies = async (endpoint: string) => {
    let response;

    try {
      response = await sendRequest('GET', `${baseURL}/${endpoint}`, {}, null);
    } catch (e) {
      logger.logError('policies:error_retrieving_policies', e);
    }
    return response;
  };

  const isReportOption = (option: OptionType) => {
    if (!option || !reportsRef?.current?.length) {
      return false;
    }

    for (const report of reportsRef.current) {
      if (report === option.value) {
        return true;
      }
    }

    return false;
  };

  const setAllPolicies = async () => {
    const reportPolicies = await getPolicies('policies');

    if (!reportPolicies) {
      return;
    }

    const reports = Object.keys(reportPolicies.Checks);

    for (const report of reports) {
      policiesMap.current[report] = policiesMap.current[report] || [];

      if (reportsRef.current) {
        reportsRef.current.push(report);
      }

      const policyKeys = Object.keys(reportPolicies?.Checks[report]);

      for (const policy of policyKeys) {
        policiesMap.current[report].push(`${report}.${policy}`);
      }
    }

    let policyOptions: OptionType[] = [];

    for (const key in policiesMap.current) {
      policyOptions.push({ label: key, value: key });
      policyOptions = [...policyOptions, ...policiesMap.current[key].map((value) => ({ label: value, value }))];
    }

    const selectedPolicies = selectedPolicyMapping?.spec?.policies;

    if (selectedPolicies?.length) {
      for (const policy of selectedPolicies) {
        if (isReportOption({ label: policy, value: policy })) {
          policyOptions = policyOptions.filter((policyOption) => !policyOption.value.includes(`${policy}.`));
        }
      }
    }

    setPolicyOptions(policyOptions);
  };

  useEffect(() => {
    if (isModalShown && selectedPolicyMapping) {
      // get app groups and policies.
      (async function () {
        await getAppGroups();
        await setAllPolicies();
      })();
    }
  }, [isModalShown, selectedPolicyMapping]);

  useEffect(() => {
    if (!selectedPolicyMapping) {
      return;
    }

    setPolicyMappingName(selectedPolicyMapping?.name || '');

    setAppGroups(
      selectedPolicyMapping?.spec?.appGroups?.length
        ? selectedPolicyMapping.spec.appGroups.map((value: string) => ({ label: value, value }))
        : [],
    );

    setPolicies(
      selectedPolicyMapping?.spec?.policies?.length
        ? selectedPolicyMapping.spec.policies.map((value: string) => ({ label: value, value }))
        : [],
    );

    const contexts = selectedPolicyMapping?.spec?.contexts?.length ? selectedPolicyMapping.spec.contexts : [];

    setContexts({
      agent: contexts.includes('Agent'),
      admission: contexts.includes('Admission'),
      ci: contexts.includes('CI'),
    });

    setBlock(selectedPolicyMapping?.spec?.block);
    setEnabled(selectedPolicyMapping?.spec?.enabled);
  }, [selectedPolicyMapping]);

  const closeModal = () => {
    onModalHidden && onModalHidden(false);
    setPolicyMappingName('');
    setPolicies([]);
    setAppGroups([]);
    setContexts({
      agent: true,
      admission: true,
      ci: true,
    });
    setBlock(undefined);
    setEnabled(undefined);
  };

  const onRuleNameKeyUp = (e: SyntheticEvent) => {
    setPolicyMappingName(fixID((e.target as HTMLInputElement).value));
  };

  const validateForm = () => {
    if (!policyMappingName) {
      toast.error(strings.policyMapping.policyMappingNameRequired);
      return false;
    }

    if (!appGroups?.length) {
      toast.error(strings.policyMapping.appGroupsRequired);
      return false;
    }

    return true;
  };

  const buildSavePolicyMappingPayload = () => {
    const payload: Record<string, any> = {
      type: 'PolicyMapping',
      name: policyMappingName,
    };

    payload.spec = {
      block: block,
      enabled: enabled,
      policies: policies?.length ? policies.map((policy) => policy.value) : null,
    };

    if (appGroups?.length || contexts.agent || contexts.admission || contexts.ci) {
      if (appGroups.length) {
        payload.spec.appGroups = appGroups.map((appGroup) => appGroup.value);
      }

      payload.spec.contexts = [
        ...(contexts.agent ? ['Agent'] : []),
        ...(contexts.admission ? ['Admission'] : []),
        ...(contexts.ci ? ['CI'] : []),
      ];
    }

    return payload;
  };

  const updatePolicyMapping = async () => {
    if (!validateForm()) {
      return false;
    }

    try {
      await sendRequest(
        'POST',
        `${baseURL}/policy-mappings`,
        {
          data: buildSavePolicyMappingPayload(),
        },
        null,
      );

      toast.success(
        strings.policyMapping.updatePolicyMappingSuccess.replace('$POLICY_MAPPING_NAME', policyMappingName),
      );

      onConfirmClicked && onConfirmClicked();
      closeModal();
    } catch (e) {
      logger.logError('error_updating_policy_mapping', e);
      toast.error(
        <b>{strings.policyMapping.cannotUpdateThePolicyMapping.replace('$POLICY_MAPPING_NAME', policyMappingName)}</b>,
      );
    }
  };

  const handleAddingReportOption = (option: OptionType) => {
    if (!option) {
      return;
    }

    const updatedOptions = policies.filter((policy) => !policy.value.includes(`${option.value}.`));
    updatedOptions.push(option);

    setPolicies([...updatedOptions]);

    setPolicyOptions((prevPolicyOptions) =>
      prevPolicyOptions.filter((policyOption) => !policyOption.value.includes(`${option.value}.`)),
    );
  };

  const handleRemovingReportOption = (option: OptionType) => {
    if (!option) {
      return;
    }

    const updatedPolicyOptions = policyOptions.filter((policy) => policy.value !== option.value);

    setPolicyOptions([
      ...updatedPolicyOptions,
      option,
      ...policiesMap.current[option.value].map((value: string) => ({ label: value, value })),
    ]);
  };

  const onPoliciesChanged = (action: string, removedValue: OptionType, option: OptionType) => {
    if (action === 'clear') {
      setPolicies([]);
      return;
    }

    if (option) {
      if (isReportOption(option)) {
        handleAddingReportOption(option);
        return;
      }

      setPolicies((prevPolicies) => [...prevPolicies, option]);
      return;
    }

    if (removedValue) {
      if (isReportOption(removedValue)) {
        handleRemovingReportOption(removedValue);
      }

      setPolicies((prevPolicies) => prevPolicies.filter((appGroup) => appGroup.value !== removedValue.value));
    }
  };

  const handleToggleEnabled = async () => {
    if (isViewing) {
      return;
    }

    try {
      const body = (await sendRequest(
        'PATCH',
        `${baseURL}/policy-mappings/${policyMappingName}/toggle-enabled`,
        {},
        null,
      )) as IPolicyMapping;

      setEnabled(body?.spec?.enabled);

      const enabledText = body?.spec?.enabled ? 'enabled' : 'disabled';
      toast.success(
        strings.policyMapping.toggleEnabledPolicyMappingSuccess
          .replace('$POLICY_MAPPING_NAME', policyMappingName)
          .replace('$ENABLED', enabledText),
      );
      onToggleEnabledClicked && onToggleEnabledClicked();
    } catch (e) {
      logger.logError('error_toggling_enabled_policy_mapping', e);
      toast.error(
        <b>
          {strings.policyMapping.cannotToggleEnabledPolicyMapping.replace('$POLICY_MAPPING_NAME', policyMappingName)}
        </b>,
      );
    }
  };

  return (
    <Modal show={isModalShown} onHide={closeModal} className="add-policy-mapping-modal">
      <Modal.Body className="pt-0">
        <div className="add-policy-mapping-modal-title-container">
          <h3 className="modal-title">
            {isViewing ? strings.policyMapping.viewPolicyMapping : strings.policyMapping.updatePolicyMapping}
          </h3>
          <CloseIcon
            width="0.75rem"
            height="0.75rem"
            onClick={closeModal}
            className="add-policy-mapping-modal-close-icon"
          />
        </div>
        {/* Policy Mapping Name */}
        <div className="policy-mapping-name">
          <div className="policy-mapping-name-title-row">
            <div className="policy-mapping-name-title">
              <span>{strings.policyMapping.policyMappingName}</span>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id="tooltip-app-group-name">
                    <Popover.Content>{strings.policyMapping.policyMappingNameTooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
            <div
              data-cy="enable-toggle-button"
              className="add-policy-mapping-enabled__action"
              onClick={handleToggleEnabled}
            >
              <Form.Check
                aria-label="Toggle policy-mapping enabled"
                checked={enabled === undefined ? true : enabled}
                disabled={isViewing}
                className="add-policy-mapping-enabled__toggle-policy-mapping-enabled-btn"
                type="switch"
              />
              <span>{strings.Enable}</span>
            </div>
          </div>
          <Form.Control
            onChange={(e) => setPolicyMappingName(e.target.value)}
            onKeyUp={onRuleNameKeyUp}
            placeholder={strings.policyMapping.nameOfPolicyMapping}
            type="text"
            value={policyMappingName}
            disabled={true}
            data-cy="add-policy-mapping-name-input"
          />
        </div>
        {/* End Policy Mapping Name */}
        {/* App Groups */}
        <div>
          <div className="policy-mapping-form-label">
            <span>{strings.policyMapping.appGroupsLabel}</span>
          </div>
          <Select
            closeMenuOnSelect={false}
            isMulti
            options={appGroupOptions}
            onChange={(newOption, { action, removedValue, option }) => {
              if (action === 'clear') {
                setAppGroups([]);
              } else if (option) {
                setAppGroups((prevAppGroups) => [...prevAppGroups, option]);
              } else if (removedValue) {
                setAppGroups((prevAppGroups) =>
                  prevAppGroups.filter((appGroup) => appGroup.value !== removedValue.value),
                );
              }
            }}
            value={appGroups}
            isDisabled={isViewing}
          />
        </div>
        {/* End App Groups */}
        {/* Policies */}
        <div>
          <div className="policy-mapping-form-label">
            <span>{strings.policyMapping.policies}</span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id="tooltip-policies">
                  <Popover.Content>{strings.policyMapping.policyTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </div>
          <Select
            closeMenuOnSelect={false}
            isMulti
            options={policyOptions}
            onChange={(newOption, { action, removedValue, option }) => {
              onPoliciesChanged(action, removedValue, option);
            }}
            value={policies}
            isDisabled={isViewing}
            classNamePrefix="policy-mapping-policies-select"
          />
        </div>
        {/* End Policies */}
        {/* Contexts */}
        <div>
          <div className="policy-mapping-form-label">
            <span>{strings.policyMapping.contexts}</span>
          </div>
          <div className="policy-mapping-checkbox-container">
            <div>
              <input
                checked={contexts.agent}
                id="agent-checkbox"
                onChange={() => setContexts((prevContexts) => ({ ...prevContexts, agent: !prevContexts.agent }))}
                type="checkbox"
                disabled={isViewing}
              />
              <label className="checkbox-label" htmlFor="agent-checkbox">
                {strings.policyMapping.agent}
              </label>
            </div>
            <div>
              <input
                checked={contexts.admission}
                id="admission-checkbox"
                onChange={() =>
                  setContexts((prevContexts) => ({
                    ...prevContexts,
                    admission: !prevContexts.admission,
                  }))
                }
                type="checkbox"
                disabled={isViewing}
              />
              <label className="checkbox-label" htmlFor="admission-checkbox">
                {strings.policyMapping.admission}
              </label>
            </div>
            <div>
              <input
                checked={contexts.ci}
                id="ci-checkbox"
                onChange={() => setContexts((prevContexts) => ({ ...prevContexts, ci: !prevContexts.ci }))}
                type="checkbox"
                disabled={isViewing}
              />
              <label className="checkbox-label" htmlFor="ci-checkbox">
                {strings.policyMapping.ci}
              </label>
            </div>
          </div>
          <div className="policy-mapping-form-label">
            <span>{strings.policyMapping.block}</span>
          </div>
          <div className="policy-mapping-radio-button-container">
            <div>
              <input
                name="policy-mapping-radio"
                checked={block === true}
                id="always-block"
                onChange={() => setBlock(true)}
                disabled={isViewing}
                type="radio"
              />
              <label className="policy-mapping-radio-label" htmlFor="always-block">
                {strings.policyMapping.alwaysBlock}
              </label>
            </div>
            <div>
              <input
                name="policy-mapping-radio"
                checked={block === undefined}
                id="block-on-high-and-critical"
                onChange={() => setBlock(undefined)}
                disabled={isViewing}
                type="radio"
              />
              <label className="policy-mapping-radio-label" htmlFor="block-on-high-and-critical">
                {strings.policyMapping.blockBasedOnPolicySettings}
              </label>
              <OverlayTrigger
                placement="right"
                delay={{ show: 0, hide: 1000 }}
                overlay={
                  <Popover id="tooltip-app-group-name">
                    <Popover.Content>{strings.policyMapping.blockOnPolicyConfigurationTooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
            <div>
              <input
                name="policy-mapping-radio"
                checked={block === false}
                id="never-block"
                onChange={() => setBlock(false)}
                disabled={isViewing}
                type="radio"
              />
              <label className="policy-mapping-radio-label" htmlFor="never-block">
                {strings.policyMapping.neverBlock}
              </label>
            </div>
          </div>
        </div>
        {/* End Contexts */}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn add-policy-mapping-modal-cancel-button" type="button" onClick={closeModal}>
          {strings.policyMapping.cancel}
        </Button>
        <Button
          className="btn"
          type="button"
          onClick={updatePolicyMapping}
          disabled={!policyMappingName || isViewing}
          data-cy="save-policy-mapping-button"
        >
          {strings.policyMapping.updatePolicyMapping}
        </Button>
        <Button
          className="btn btn-danger"
          type="button"
          onClick={() => {
            onDeleteModalShown && onDeleteModalShown(policyMappingName);
            closeModal();
          }}
          disabled={!policyMappingName || isViewing}
          data-cy="delete-policy-mapping-button"
        >
          {strings.policyMapping.deletePolicyMapping}
        </Button>
      </Modal.Footer>
      <Toaster />
    </Modal>
  );
};

export default UpdatePolicyMappingModal;
