import React, { useMemo, useState } from 'react';
import { Item, ItemParams, Menu, Submenu } from 'react-contexify';
import { Form, InputGroup } from 'react-bootstrap';
import Fuse from 'fuse.js';

import Icon from '~reactIcons/Icon.react';

import { Member } from '../ImageDetailItemsTable/ImageDetailItemsTable.types.react';

import { ACTION_ITEM_RESOLUTIONS } from '~utils/constants';

import ExportIcon from '~assetIcons/export.svg';
import AssignIcon from '~assetIcons/assign.svg';
import ResolveIcon from '~assetIcons/resolve.svg';

import { VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID } from '~reactHelpers';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';
import { strings } from '~utils/strings';

type ContextMenuProps = {
  members: Member[];
  onCreateTicketClick: () => void;
  onViewTicketClick?: () => void;
  onUnlinkTicketClick?: () => void;
  fetchVulnActionItemsDebounced?: () => void;
  onResolutionHandled: (resolution: { value: string; label: string }) => void;
  onAssigneeHandled: (email: string) => void;
  isOwner: boolean;
};

interface ItemProps {
  id: number;
}

const ContextMenu = ({
  members,
  onCreateTicketClick,
  onResolutionHandled,
  onAssigneeHandled,
  onViewTicketClick,
  onUnlinkTicketClick,
  isOwner,
}: ContextMenuProps) => {
  const [search, setSearch] = useState<string>('');
  const [filteredMembers, setFilteredMembers] = useState<Member[]>([]);

  const searchInstance = useMemo(() => {
    return new Fuse((members as Member[]) || [], {
      keys: ['Email'],
      threshold: 0.2,
    });
  }, [members]);

  const handleMembersSearch = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const _members = searchInstance.search(target.value).map((res) => res.item);
    setSearch(target.value);
    setFilteredMembers(_members);
  };

  const handleResolution = async (resolution: { value: string; label: string }) => {
    onResolutionHandled(resolution);
  };

  const handleAssignee = async (email: string) => {
    onAssigneeHandled(email);
  };

  // FIXME: Menu should close upon selection in description panel
  const keepMenuOpen = ({ event }: ItemParams<ItemProps, any>) => event.stopPropagation();

  return (
    <Menu id={VULN_ITEMS_TABLE_IMAGE_DETAIL_CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>Additional Actions</span>
      </Item>
      <Item onClick={onCreateTicketClick}>
        <img
          className="ticket-icon"
          src={ExportIcon}
          style={{ paddingRight: '1rem' }}
          alt={strings.actionItemsPage.contextMenu.ticketIcon}
        />
        Create Ticket
      </Item>
      {onViewTicketClick && (
        <>
          <Item onClick={onViewTicketClick}>
            <img
              className="ticket-icon"
              src={ExportIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.ticketIcon}
            />
            View Image Ticket
          </Item>
          {isOwner && (
            <Item onClick={onUnlinkTicketClick}>
              <img
                className="ticket-icon"
                src={ExportIcon}
                style={{ paddingRight: '1rem' }}
                alt={strings.actionItemsPage.contextMenu.ticketIcon}
              />
              Unlink Image Ticket
            </Item>
          )}
        </>
      )}
      <Submenu
        label={
          <>
            <img className="resolve-icon" src={ResolveIcon} style={{ paddingRight: '1rem' }} alt="resolve icon" />
            Resolve
          </>
        }
      >
        {ACTION_ITEM_RESOLUTIONS.map((resolution, idx) => (
          <Item onClick={() => handleResolution(resolution)} key={idx}>
            {resolution.label}
          </Item>
        ))}
      </Submenu>
      <Submenu
        label={
          <>
            <img className="assign-icon" src={AssignIcon} style={{ paddingRight: '1rem' }} alt="assign icon" />
            Assign
          </>
        }
      >
        <Item onClick={keepMenuOpen} className="context--menu__no-hover">
          <Form>
            <InputGroup>
              <InputGroup.Text>
                <Icon name="search" width="1rem" height="1rem" />
              </InputGroup.Text>
              <Form.Control type="search" onKeyUp={handleMembersSearch} placeholder="Search members" />
            </InputGroup>
          </Form>
        </Item>
        <Item
          onClick={() => {
            handleAssignee('');
          }}
        >
          Unassign
        </Item>
        {(filteredMembers.length ? filteredMembers : members)?.map((member, idx) => (
          <Item
            onClick={() => {
              handleAssignee(member.Email);
            }}
            key={idx}
          >
            {member.Email}
          </Item>
        ))}
      </Submenu>
    </Menu>
  );
};

export default ContextMenu;
