import React from 'react';
import { ResponsiveWaffle } from '@nivo/waffle';

const HexbinChart = ({ nodes, chartSettings, CustomCell, CustomToolTip }) => {
  let marginTop;
  if (nodes.length > 20 && nodes.length < 90) {
    marginTop = 40;
  } else if (nodes.length > 90) {
    marginTop = 30;
  } else if (nodes.length > 6 && nodes.length < 20) {
    marginTop = 75;
  } else {
    marginTop = 110;
  }
  const marginBottom = nodes.length > 5 ? 10 : -20;

  return (
    <ResponsiveWaffle
      data={nodes}
      total={nodes.length * 1.25} // increasing total prevents nodes from duplicating in chart, do not remove
      rows={Math.ceil(nodes.length / chartSettings.numCols)}
      columns={nodes.length > 1 ? chartSettings.numCols : 1}
      cellComponent={CustomCell}
      margin={{ top: marginTop, right: 0, bottom: marginBottom, left: 0 }}
      motionStiffness={90}
      motionDamping={11}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tooltip={CustomToolTip}
    />
  );
};

export default HexbinChart;
