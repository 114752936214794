import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import clsx from 'clsx';

import { NotificationPreference } from '~views/organization/settings/notifications/Notifications.types.react';

import { strings } from '~utils/strings';
import { CardTitleDefault, BasicText } from '~utils/texts.react';

import './Notification.react.scss';

type NotificationProps = {
  title?: string;
  context: NotificationPreference;
  preferences: NotificationPreference[];
  updateNotifications: (event: React.MouseEvent<HTMLElement>, id: NotificationPreference, selected: string[]) => void;
};

const Notification = ({ title = 'N/A', context, preferences, updateNotifications }: NotificationProps) => {
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    selectCheckboxes();
  }, []);

  const selectCheckboxes = () => {
    setSelected([]);
    let preferenceIndex = -1;

    preferences.forEach((preference, index) => {
      if (preference.Cluster === context.Cluster) {
        if (preference.ChannelID === context.ChannelID) {
          preferenceIndex = index;
        }
      }
    });

    if (preferenceIndex > -1 && preferences[preferenceIndex].Digest) {
      setSelected((prevState) => [...prevState, 'daily']);
    }
    if (preferenceIndex > -1 && preferences[preferenceIndex].Realtime) {
      setSelected((prevState) => [...prevState, 'real']);
    }
  };

  const handleChecking = (selection: string) => {
    if (selected.includes(selection)) {
      setSelected(selected.filter((selections) => selections !== selection));
    } else {
      setSelected((prevState) => [...prevState, selection]);
    }
  };

  return (
    <div className="notification-component" data-cy="notification-component">
      <div className="notification-component__card">
        <h2 className={clsx('notification-component-title', CardTitleDefault())}>{title}</h2>
        <Form>
          <Form.Group data-cy="checkboxes">
            <Form.Label>{strings.notificationPreferences}</Form.Label>
            <label
              className={clsx(
                'notification-checkbox-label',
                BasicText({
                  size: strings.textStyling.md,
                  bottomMargin: strings.textStyling.smBottom,
                }),
              )}
            >
              {strings.realTime}
              <input type="checkbox" onChange={() => handleChecking('real')} checked={selected.includes('real')} />
              <span className="checkmark"></span>
            </label>
            <label
              className={clsx(
                'notification-checkbox-label',
                BasicText({
                  size: strings.textStyling.md,
                  bottomMargin: strings.textStyling.smBottom,
                }),
              )}
            >
              {strings.dailyDigest}
              <input type="checkbox" onChange={() => handleChecking('daily')} checked={selected.includes('daily')} />
              <span className="checkmark"></span>
            </label>
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            className="notification-component__button"
            onClick={(e) => updateNotifications(e, context, selected)}
          >
            {strings.Update}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Notification;
