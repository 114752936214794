import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClusterBulletChart from '../components/ClusterBulletChart/ClusterBulletChart.react';
import CostChart from '../components/CostChart/CostChart.react';
import LineChart from '../components/LineChart/LineChart.react';
import NodeTable from '../components/NodeTable/NodeTable.react';
import NodeHexbinChart from '../components/NodeHexbinChart/NodeHexbinChart.react';

import { Cluster, IStore, IRoute, IRouter } from '~globalTypes';
import { NodeCostsType } from '../../Efficiency.types.react';
import { getClusterData } from '../../Efficiency.helpers.react';
import { sendRequest } from '~utils/request';
import { getCurrentTimezone, handlePageChange } from '~reactHelpers';
import logger from '~logger';
import { strings } from '~utils/strings';

import './Capacity.react.scss';
import {
  ORG_EFFICIENCY,
  NODE_EFFICIENCY,
  ORG_DASHBOARD,
  CLUSTER_OVERVIEW,
} from '~reactComponents/NavigationReact/Navigation.config.react';

dayjs.extend(timezone);

type EfficiencyMainProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const CapacityMain = ({ route, router, store }: EfficiencyMainProps) => {
  const timeZone = getCurrentTimezone();
  const org = route?.params?.org;
  const cluster = store().getters.cluster?.Name;
  const { name } = route;

  const orgBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ORG_EFFICIENCY,
      label: strings.navigation.Efficiency,
      href: `/orgs/${org}/efficiency`,
    },
    {
      id: 'last',
      label:
        name === ORG_EFFICIENCY || name === NODE_EFFICIENCY
          ? strings.navigation.Capacity
          : strings.navigation.Workloads,
      href: `/orgs/${org}/efficiency`,
      isActive: true,
    },
  ];

  const clusterBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ORG_EFFICIENCY,
      label: strings.navigation.Efficiency,
      href: `/orgs/${org}/efficiency`,
    },
    {
      id: 'org-efficiency-same',
      label: strings.navigation.Capacity,
      href: `/orgs/${org}/clusters/${cluster}/efficiency-clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}/efficiency-clusters`,
      isActive: true,
    },
  ];

  const baseURL = `/v0/organizations/${org}`;
  const clusters = store().getters.clusters.map((cluster: Cluster) => cluster.Name);
  const end = timeZone ? dayjs().tz(timeZone) : dayjs();
  const start = end.clone().subtract(1, 'month').toDate();
  const [data, setData] = useState<NodeCostsType>({});
  const [nodesData, setNodesData] = useState([]);

  const getData = async () => {
    const response = await getClusterData(baseURL, start, end.toDate(), 'daily', cluster);

    if (!cluster) {
      const updatedClusters = {};

      clusters.forEach((cluster) => {
        if (!response[cluster]) {
          updatedClusters[cluster] = [];
        }
      });

      setData({ ...response, ...updatedClusters });
    } else {
      setData(response);
    }
  };

  const clusterAndDataCheck = useMemo(() => {
    if (!cluster && !Object.keys(data).length) {
      return false;
    } else if (cluster && cluster !== Object.keys(data)[0]) {
      return false;
    }
    return true;
  }, [cluster, data]);

  useEffect(() => {
    getData();
  }, [cluster]);

  useEffect(() => {
    if (cluster) {
      const getNodesData = async () => {
        let response;
        try {
          response = await sendRequest(
            'GET',
            `${baseURL}/clusters/${cluster}/reports/workloads/detail`,
            {},
            null,
          );
        } catch (e) {
          console.error('error', e);
          logger.logError('error_retrieving_workloads', e);
        }

        setNodesData(response ? response.Report.Nodes : []);
      };

      getNodesData();
    }
  }, [cluster]);

  return (
    <LayoutReact className="efficiency-page">
      <Breadcrumbs
        data={cluster ? clusterBreadcrumbsList : orgBreadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {clusterAndDataCheck && Object.keys(data).length > 0 && (
        <>
          <ClusterBulletChart
            data={data}
            router={router}
            route={route}
            baseURL={`${baseURL}/clusters/${cluster}`}
          />
          <CostChart baseURL={baseURL} router={router} route={route} />
          <div className="efficiency-page-two-charts">
            <LineChart
              data={data}
              title={strings.navigation.Capacity}
              router={router}
              route={route}
            />
            <LineChart
              data={data}
              title={strings.efficiency.Utilization}
              router={router}
              route={route}
            />
          </div>
          {cluster && (
            <>
              <NodeHexbinChart data={nodesData} />
              <NodeTable cluster={cluster} data={nodesData} />
            </>
          )}
        </>
      )}
      {!clusterAndDataCheck && (
        <div className="efficiency-page__loading-spinner">
          <LoadingSpinner />
        </div>
      )}
    </LayoutReact>
  );
};

export default CapacityMain;
