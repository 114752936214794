import { FormattedAndRawData, LimitsAndRequests, LimitsType, Period } from '../CumulativeCosts/Costs.types.react';
import { Point } from './LineCardChart.react';
import { getWeekNumber } from '~utils/global.helpers.react';
import { TIME_PRECISION } from '@nivo/scales';
import dayjs from 'dayjs';

// aggregate data for weekly and monthly periods
export const aggregateData = (data: Point[], period: Period): Point[] => {
  if (period === 'daily' || period === 'hourly') {
    return data;
  }

  const aggregatedData: { [key: string]: { date: Date; value: number | null; count: number | null } } = {};

  for (let index = 0; index < data.length; index++) {
    const point = data[index];
    const date = point.x;
    let key = '';

    if (period === 'weekly') {
      const weekNumber = getWeekNumber(date);
      key = `${date.getFullYear()}-W${weekNumber}`;
    } else if (period === 'monthly') {
      key = `${date.getFullYear()}-${date.getMonth() + 1}`;
    }

    if (!key) {
      continue;
    }

    if (!aggregatedData[key]) {
      if (point.y) {
        aggregatedData[key] = { date: point.x, value: 0, count: 0 };
      } else {
        aggregatedData[key] = { date: point.x, value: null, count: null };
      }
    }

    if (point.y) {
      // at this point we are sure that value and count were initialized with 0
      aggregatedData[key]!.value! += point.y;
      aggregatedData[key]!.count! += 1;
    }
  }

  const values = Object.values(aggregatedData).map((item) => ({
    x: item.date,
    y: item.value && item.count ? item.value / item.count : null,
  }));

  return values;
};

// format data for the line chart, it supports memory and cpu data
export const formatData = (
  valueField: 'memoryRaw' | 'cpuRaw',
  timeseries: {
    time: string;
    resources: {
      averageUsage: FormattedAndRawData;
      minUsage: FormattedAndRawData;
      maxUsage: FormattedAndRawData;
      recommendation: LimitsAndRequests;
      settings: LimitsAndRequests;
    };
  }[],
  formatFunction: (bytes: number | undefined) => number | null,
  setHighestUsage: React.Dispatch<React.SetStateAction<number>>,
  limits: LimitsType | undefined,
  recSettings: 'current' | 'historical',
  containers: string | null,
  period: Period,
) => {
  // initialize 7 arrays
  const [minUsageBucket, maxUsageBucket, avgUsageBucket, recsLimits, recsRequests, curLimits, curRequests]: Point[][] =
    Array(7)
      .fill(null)
      .map(() => []);

  let highestAmount = 0;
  for (let index = 0; index < timeseries.length; index++) {
    const {
      time,
      resources: { averageUsage, minUsage, maxUsage, recommendation, settings },
    } = timeseries[index];

    const date = new Date(time);

    minUsageBucket.push({ x: date, y: formatFunction(minUsage[valueField]) });
    maxUsageBucket.push({ x: date, y: formatFunction(maxUsage[valueField]) });
    avgUsageBucket.push({ x: date, y: formatFunction(averageUsage[valueField]) });

    let recLimitsAndRequests: LimitsAndRequests = recommendation;
    let curLimitsAndRequests: LimitsAndRequests = settings;
    if (recSettings === 'current' && limits) {
      // on current config, use up-to-date limits and requests instead of historical
      if (containers) {
        const ct = limits.containers.find((c) => c.container === containers);
        if (ct) {
          recLimitsAndRequests = ct.recommendations;
          curLimitsAndRequests = ct.settings;
        }
      } else {
        recLimitsAndRequests = limits.recommendations;
        curLimitsAndRequests = limits.settings;
      }
    }

    recsLimits.push({ x: date, y: formatFunction(recLimitsAndRequests.limits[valueField]) });
    recsRequests.push({ x: date, y: formatFunction(recLimitsAndRequests.requests[valueField]) });

    curLimits.push({ x: date, y: formatFunction(curLimitsAndRequests.limits[valueField]) });
    curRequests.push({ x: date, y: formatFunction(curLimitsAndRequests.requests[valueField]) });

    highestAmount = Math.max(
      highestAmount,
      formatFunction(maxUsage[valueField]) || 0,
      formatFunction(curLimitsAndRequests.limits[valueField]) || 0,
      formatFunction(recLimitsAndRequests.limits[valueField]) || 0,
    );
  }
  setHighestUsage(highestAmount);

  return [
    {
      id: 'minUsage',
      data: aggregateData(minUsageBucket, period),
    },
    {
      id: 'maxUsage',
      data: aggregateData(maxUsageBucket, period),
    },
    {
      id: 'averageUsage',
      data: aggregateData(avgUsageBucket, period),
    },
    {
      id: 'limits',
      data: aggregateData(recsLimits, period),
    },
    {
      id: 'requests',
      data: aggregateData(recsRequests, period),
    },
    {
      id: 'settings-limits',
      data: aggregateData(curLimits, period),
    },
    {
      id: 'settings-requests',
      data: aggregateData(curRequests, period),
    },
  ];
};

export const resolveXScaleFormat = (period: Period): string | undefined => {
  switch (period) {
    case 'daily':
      return '%Y-%m-%d';
    case 'weekly':
      return '%Y-%W';
    case 'monthly':
      return '%Y-%m';
    case 'hourly':
    default:
      return '%Y-%m-%d %H:%M';
  }
};

export const resolveXScalePrecision = (period: Period): TIME_PRECISION | undefined => {
  switch (period) {
    case 'daily':
      return 'day';
    case 'weekly':
      return 'day';
    case 'monthly':
      return 'month';
    case 'hourly':
    default:
      return 'hour';
  }
};

export const resolveXFormat = (period: Period): string => {
  switch (period) {
    case 'daily':
      return 'time:%Y-%m-%d';
    case 'weekly':
      return 'time:%Y-%W';
    case 'monthly':
      return 'time:%Y-%m';
    case 'hourly':
    default:
      return 'time:%H';
  }
};

export const resolveAxisBottomFormat = (value: Date, timeZone: string, period: Period): string => {
  const formatter = timeZone ? dayjs(value).tz(timeZone) : dayjs(value);
  switch (period) {
    case 'daily':
      return formatter.format('MM/D');
    case 'weekly':
      return formatter.format('MMM D');
    case 'monthly':
      return formatter.format('MMM YYYY');
    case 'hourly':
    default:
      return formatter.format('MM/DD: hA');
  }
};

export const resolveAxisBottomTickValues = (period: Period): string => {
  switch (period) {
    case 'daily':
      return 'every 1 days';
    case 'weekly':
      return 'every 1 week';
    case 'monthly':
      return 'every 1 month';
    case 'hourly':
    default:
      return 'every 4 hours';
  }
};
