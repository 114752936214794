import { Breadcrumbs } from '@fairwindsops/ui-components';
import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Select, { SingleValue } from 'react-select';
import { OptionType } from '~globalTypes';
import { handlePageChange } from '~utils/global.helpers.react';
import { NotificationsProps } from './Notifications.types.react';

import { ORG_DASHBOARD, TEAM_MANAGEMENT } from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';

import './Notifications.react.scss';
import SlackNotifications from './SlackNotification.react';
import MSTeamsNotifications from './MSTeamsNotification.react';
import { vexillaShould } from '~utils/feature-flags';

const options = vexillaShould(strings.featureFlags.msTeamsIntegration)
  ? [
      { value: 'slack', label: 'Slack' },
      { value: 'msteams', label: 'MS Teams' },
    ]
  : [{ value: 'slack', label: 'Slack' }];

const Notifications = ({ route, store, router }: NotificationsProps) => {
  const org = route?.params?.org;
  const isOrgOwner = store().getters.isOrgOwner;

  const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(options[0]);

  const handleChange = (newValue: SingleValue<OptionType>) => {
    setSelectedOption(newValue);
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${org}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.notifications,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="notifications-page">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner && (
        <div className="notifications-page-message">
          <span>You are only allowed to change these settings if you are an owner.</span>
          <span>Please contact your organization's owner to make changes</span>
        </div>
      )}

      <Select
        className="integration-select"
        value={selectedOption}
        onChange={handleChange}
        options={options}
        aria-label="integrations options select"
      />

      {selectedOption?.value === 'slack' && <SlackNotifications store={store} route={route} />}
      {selectedOption?.value === 'msteams' && <MSTeamsNotifications store={store} route={route} />}
      <Toaster />
    </div>
  );
};

export default Notifications;
