import React, { useState, useEffect, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ChartType, Cluster, DateType } from '~globalTypes';

import { getChartURLSearchParams } from '../../../../ReactVulnerabilities.helpers.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import '../Charts.react.scss';
import { convertFiltersToURLForAllImages } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.helpers.react';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';

type TopImpactedPackagesProps = {
  baseURL?: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  noData?: React.ReactNode;
  isResolvedShown?: boolean;
  selectedAppGroup: AppGroup | null;
  firstSeenRange: DateType;
  setFilter: (columnId: string, updater: any) => void;
};

const TopImpactedPackages = ({
  baseURL,
  cluster,
  filters,
  isResolvedShown,
  selectedAppGroup,
  firstSeenRange,
  noData,
  setFilter,
}: TopImpactedPackagesProps) => {
  const [topImpactedPackages, setTopImpactedPackages] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getTopImpactedPackages();
    return () => {
      isMounted.current = false;
    };
  }, [cluster, isResolvedShown, firstSeenRange?.start, firstSeenRange?.end, selectedAppGroup]);

  useEffect(() => {
    if (filters?.length) {
      getTopImpactedPackages();
    }
  }, [filters]);

  const getTopImpactedPackages = async () => {
    try {
      setLoaded(false);
      const params = getChartURLSearchParams({
        groupBy: 'package',
        cluster,
        isResolvedShown,
        selectedAppGroup,
        firstSeen: firstSeenRange?.start,
        firstSeenEnd: firstSeenRange?.end,
      });
      const URLParams = convertFiltersToURLForAllImages(filters, params);
      const response = await sendRequest('GET', `${baseURL}/images/vulnerabilities/top?${URLParams}`, {}, null);
      const topImpactedPackages = response.slice(0, 5);
      if (isMounted.current) {
        setTopImpactedPackages(topImpactedPackages);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_vuln_top_impacted_packages: ', e);
      toast.error(strings.vulnerabilities.errorTopImpactedPackages);
    }
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'package')?.value?.length) return;
    setFilter('package', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('package', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'package')?.value?.length}
      />
      {topImpactedPackages?.length ? (
        <div aria-label={strings.ariaLabels.topImpactedPackagesChart} className="vulnerabilities--charts">
          <BarChart
            data={topImpactedPackages}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[COLORS.CHARTS.PACKAGES[100], COLORS.CHARTS.PACKAGES[200]]}
            colorBy="indexValue"
            layout="vertical"
            tooltipLabel={(d) => `${d.data.value}`}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default TopImpactedPackages;
