export default [
  {
    Name: 'development-namespace',
    Title: 'Do not scan development namespace for Admission Controller',
    Rule: 'development-namespace.yaml',
  },
  {
    Name: 'mark-as-danger',
    Title: 'Mark Trivy vulnerabilities as danger',
    Rule: 'mark-as-danger.yaml',
  },
  {
    Name: 'polaris-severities',
    Title: 'For in-cluster Polaris checks, set a severity of 0.1',
    Rule: 'polaris-severities.yaml',
  },
  {
    Name: 'reduce-severities',
    Title: 'Reduce all severities in the staging cluster by 50%',
    Rule: 'reduce-severities.yaml',
  },
  {
    Name: 'root-not-in-kube-system',
    Title: 'Check for containers running as root',
    Rule: 'root-not-in-kube-system.yaml',
  },
  {
    Name: 'send-notification',
    Title: 'Send alerts for trivy in production cluster',
    Rule: 'send-notification.yaml',
  },
  {
    Name: 'update-notes',
    Title: 'Add Notes for vulnerabilities for images with known CVEs',
    Rule: 'update-notes.yaml',
  },
  {
    Name: 'working-as-intended-resolution',
    Title: 'Don’t scan containers in the kube-system namespace',
    Rule: 'working-as-intended-resolution.yaml',
  },
  {
    Name: 'enforce-specific-policies',
    Title: 'Enforce specific policies and manage exceptions for Admission Controller',
    Rule: 'enforce-specific-policies.yaml',
  },
];
