import { OptionType } from '~utils/global.types.react';

export type AutomatedCheck = {
  failing: number;
  failingActionItemsLink: string;
  manuallyResolved: number;
  manuallyResolvedActionItemsLink: string;
  passing: number;
  type: string;
};

export const Labels = {
  Automated: 'automated',
  FailingResources: 'Failing Resources',
  ManuallyResolved: 'Manually Resolved',
  Manual: 'manual',
  NotStarted: 'not_started',
} as const;

export const defaultPolicyStatusOptions: OptionType[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'not_in_scope', label: 'Not in Scope' },
  { value: 'automated', label: 'Automated' },
];

export const statusMap: Record<string, string[]> = {
  yes: ['passing', 'needs_evidence'],
  not_in_scope: ['not_in_scope'],
  no: ['failing'],
};

export const payloadMap: Record<string, string> = {
  yes: 'passing',
  no: 'failing',
  not_in_scope: 'not_in_scope',
};

export enum RESOURCE_TYPES {
  CLUSTERS = 'clusters',
  REPOSITORIES = 'repositories',
  RESOURCES = 'resources',
}

export const NON_RESOURCE_TYPES = [`${RESOURCE_TYPES.CLUSTERS}`, `${RESOURCE_TYPES.REPOSITORIES}`];
