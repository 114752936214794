import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';

import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import ExpandIcon from '~reactComponents/Icons/ExpandIcon.icon.react';
import InputTag from '~reactComponents/InputTag/InputTag.react';

import { fixID } from '~utils/helpers';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import './AddAppGroupModal.react.scss';
import { APP_GROUP_SPECS, IAppGroupSpec, IStore } from '~utils/global.types.react';

type ExpadableSections = {
  matchResources: boolean;
  excludeResources: boolean;
};

type AddAppGroupModalProps = {
  organizationName: string;
  isModalShown?: boolean;
  onConfirmClicked?: () => void;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  appGroupNames: string[];
  onOverrideModalShown?: (payload: Record<string, any>) => void;
  store: () => IStore;
};

const AddAppGroupModal = ({
  organizationName,
  isModalShown,
  onConfirmClicked,
  onModalHidden,
  appGroupNames,
  onOverrideModalShown,
  store,
}: AddAppGroupModalProps) => {
  const [appGroupName, setAppGroupName] = useState<string>('');
  const [expandableSections, setExpandableSections] = useState<ExpadableSections>({
    matchResources: true,
    excludeResources: false,
  });
  const [matchResources, setMatchResources] = useState<Record<string, string[]>>({});
  const [excludeResources, setExcludeResources] = useState<Record<string, string[]>>({});
  const [names, setNames] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [namespaceLabels, setNamespaceLabels] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [kinds, setKinds] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);

  const baseURL = `/v0/organizations/${organizationName}`;

  const clusters = store().getters.clusters;
  const repositories = store().getters.repositories;

  const getActionItemFilters = async () => {
    try {
      const actionItemFilters = await sendRequest(
        'GET',
        `${baseURL}/action-item-filters?Field=ResourceName&Field=ResourceNamespace&Field=ResourceKind&Field=ResourceLabel&Field=ResourceContainer&Field=NamespaceLabel`,
        {},
        null,
      );

      setNames(actionItemFilters?.ResourceName?.length ? actionItemFilters.ResourceName : []);
      setNamespaces(
        actionItemFilters?.ResourceNamespace?.length ? actionItemFilters.ResourceNamespace : [],
      );
      setNamespaceLabels(
        actionItemFilters?.NamespaceLabel?.length ? actionItemFilters.NamespaceLabel : [],
      );
      setLabels(actionItemFilters?.ResourceLabel?.length ? actionItemFilters.ResourceLabel : []);
      setKinds(actionItemFilters?.ResourceKind?.length ? actionItemFilters.ResourceKind : []);
      setContainers(
        actionItemFilters?.ResourceContainer?.length ? actionItemFilters.ResourceContainer : [],
      );
    } catch (e: any) {
      logger.logError('error_retrieving_action_item_filters', e);
    }
  };

  useEffect(() => {
    if (!isModalShown) {
      return;
    }

    (async function () {
      await getActionItemFilters();
    })();
  }, [isModalShown]);

  const closeModal = () => {
    onModalHidden && onModalHidden(false);
    setAppGroupName('');
    setExpandableSections({
      matchResources: true,
      excludeResources: false,
    });
    setMatchResources({});
    setExcludeResources({});
  };

  const onRuleNameKeyUp = (e: SyntheticEvent) => {
    setAppGroupName(fixID((e.target as HTMLInputElement).value));
  };

  const toggleMatchResourcesSection = () =>
    setExpandableSections((prev) => ({
      ...prev,
      matchResources: !prev.matchResources,
    }));

  const toggleExcludeResourcesSection = () =>
    setExpandableSections((prev) => ({
      ...prev,
      excludeResources: !prev.excludeResources,
    }));

  const onTagAdded = (value: string, field: string, section: string) => {
    if (!value || !field || !section) {
      return;
    }

    if (section === 'match') {
      setMatchResources((prev) => ({ ...prev, [field]: [...(prev[field] || []), value] }));
    } else {
      setExcludeResources((prev) => ({ ...prev, [field]: [...(prev[field] || []), value] }));
    }
  };

  const onTagRemoved = (value: string, field: string, section: string) => {
    if (!value || !field || !section) {
      return;
    }

    if (section === 'match') {
      setMatchResources((prev) => ({
        ...prev,
        [field]: prev[field].filter((prevValue) => prevValue !== value),
      }));
    } else {
      setExcludeResources((prev) => ({
        ...prev,
        [field]: prev[field].filter((prevValue) => prevValue !== value),
      }));
    }
  };

  const transformExpressionData = (data: string[]) => {
    if (!data?.length) {
      return [];
    }

    const transformedData = [];

    for (const value of data) {
      const splitValue = value.split('=');
      transformedData.push({ [splitValue[0]]: splitValue[1] });
    }

    return transformedData;
  };

  const buildAppGroupPayload = () => {
    // convert x=y to an object
    const matchLabels = transformExpressionData(matchResources[strings.appGroups.labelsKey]);
    const matchNamespaceLabels = transformExpressionData(matchResources['namespaceLabels']);
    const excludeLabels = transformExpressionData(excludeResources[strings.appGroups.labelsKey]);
    const excludeNamespaceLabels = transformExpressionData(excludeResources['namespaceLabels']);

    const hasMatchResources = !!(matchResources && Object.keys(matchResources)?.length);
    const hasExcludeResources = !!(excludeResources && Object.keys(excludeResources)?.length);

    delete matchResources[strings.appGroups.labelsKey];
    delete matchResources['namespaceLabels'];
    delete excludeResources[strings.appGroups.labelsKey];
    delete excludeResources['namespaceLabels'];

    const spec = {
      match: hasMatchResources
        ? [
            {
              ...matchResources,
              ...(matchLabels?.length ? { labels: matchLabels } : {}),
              ...(matchNamespaceLabels?.length ? { namespaceLabels: matchNamespaceLabels } : {}),
            },
          ]
        : [],
      exclude: hasExcludeResources
        ? [
            {
              ...excludeResources,
              ...(excludeLabels?.length ? { labels: excludeLabels } : {}),
              ...(excludeNamespaceLabels?.length
                ? { namespaceLabels: excludeNamespaceLabels }
                : {}),
            },
          ]
        : [],
    };

    return {
      type: 'AppGroup',
      name: appGroupName,
      ...(spec.match || spec.exclude ? { spec } : { spec: {} }),
    };
  };

  const saveAppGroup = async () => {
    try {
      const payload = buildAppGroupPayload();

      if (appGroupNames.includes(payload.name)) {
        closeModal();
        onOverrideModalShown && onOverrideModalShown(payload);
        return;
      }

      await sendRequest('POST', `${baseURL}/app-groups`, { data: payload }, null);

      toast.success(
        strings.appGroups.createAppGroupSuccess.replace('$APP_GROUP_NAME', appGroupName),
      );

      onConfirmClicked && onConfirmClicked();
      closeModal();
    } catch (e) {
      logger.logError('error_creating_an_app_group', e);
      toast.error(
        <b>{strings.appGroups.cannotCreateTheAppGroup.replace('$APP_GROUP_NAME', appGroupName)}</b>,
      );
    }
  };

  const appGroupSpecs = useMemo(() => Object.keys(APP_GROUP_SPECS), []);

  const suggestedData = useMemo(
    () => ({
      names,
      namespaces,
      clusters: clusters?.length ? clusters.map((cluster) => cluster.Name) : [],
      repositories: repositories?.length ? repositories.map((repository) => repository.Name) : [],
      namespaceLabels,
      labels,
      kinds,
      containers,
    }),
    [clusters, names, namespaces, namespaceLabels, labels, kinds, containers],
  );

  return (
    <Modal show={isModalShown} onHide={closeModal} className="add-app-group-modal">
      <Modal.Body className="pt-0">
        <div className="add-app-group-modal-title-container">
          <h3 className="modal-title">{strings.appGroups.newAppGroup}</h3>
          <CloseIcon
            width="0.75rem"
            height="0.75rem"
            onClick={closeModal}
            className="add-app-group-modal-close-icon"
          />
        </div>
        {/* App Group Name */}
        <div className="app-group-name">
          <div className="app-group-name-title">
            <span>{strings.appGroups.appGroupName}</span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id={`tooltip-app-group-name`}>
                  <Popover.Content>{strings.appGroups.appGroupNameTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </div>
          <Form.Control
            onChange={(e) => setAppGroupName(e.target.value)}
            onKeyUp={onRuleNameKeyUp}
            placeholder={strings.appGroups.nameOfAppGroup}
            type="text"
            value={appGroupName}
            data-cy="add-app-group-name-input"
          />
        </div>
        {/* End App Group Name */}
        {/* Match Resources */}
        <div className="match-resources">
          <button className="match-resources-title" onClick={toggleMatchResourcesSection}>
            <span>{strings.appGroups.matchResources}</span>
            <div
              className={`add-app-group-modal-expand-icon ${
                expandableSections.matchResources ? '' : 'add-app-group-modal-expand-icon--rotate'
              }`}
            >
              <ExpandIcon />
            </div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id={`tooltip-match-resources`}>
                  <Popover.Content>{strings.appGroups.matchResourcesTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </button>
          {expandableSections.matchResources ? (
            <div className="match-resources-body">
              {appGroupSpecs.map((key) => (
                <div className="match-resources-item">
                  <div className="match-resources-item-title">
                    <span>{APP_GROUP_SPECS[key as keyof IAppGroupSpec]}</span>
                  </div>
                  <InputTag
                    onTagAdded={onTagAdded}
                    onTagRemoved={onTagRemoved}
                    field={key}
                    section="match"
                    dataCy={`add-app-group-input-tag-${key}`}
                    suggestedData={suggestedData[key as keyof typeof suggestedData]}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {/* End Match Resources */}
        {/* Exclude Resources */}
        <div className="exclude-resources">
          <button className="exclude-resources-title" onClick={toggleExcludeResourcesSection}>
            <span>{strings.appGroups.excludeResources}</span>
            <div
              className={`add-app-group-modal-expand-icon ${
                expandableSections.excludeResources ? '' : 'add-app-group-modal-expand-icon--rotate'
              }`}
            >
              <ExpandIcon />
            </div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id={`tooltip-exclude-resources`}>
                  <Popover.Content>{strings.appGroups.excludeResourcesTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </button>
          {expandableSections.excludeResources ? (
            <div className="exclude-resources-body">
              {appGroupSpecs.map((key) => (
                <div className="exclude-resources-item">
                  <div className="exclude-resources-item-title">
                    <span>{APP_GROUP_SPECS[key as keyof IAppGroupSpec]}</span>
                  </div>
                  <InputTag
                    onTagAdded={onTagAdded}
                    onTagRemoved={onTagRemoved}
                    field={key}
                    section="exclude"
                    tagClasses="exclude-tag"
                    suggestedData={suggestedData[key as keyof typeof suggestedData]}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {/* End Exclude Resources */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn add-app-group-modal-cancel-button"
          type="button"
          onClick={closeModal}
        >
          {strings.appGroups.cancel}
        </Button>
        <Button
          className="btn"
          type="button"
          onClick={saveAppGroup}
          disabled={!appGroupName}
          data-cy="save-app-group-button"
        >
          {strings.appGroups.saveAppGroup}
        </Button>
      </Modal.Footer>
      <Toaster />
    </Modal>
  );
};

export default AddAppGroupModal;
