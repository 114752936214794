export enum Severities {
  Critical = 'C',
  High = 'H',
  Medium = 'M',
}

export const Labels = {
  ActionItem: 'Action Item',
  ActionItems: 'Action Items',
  Critical: 'Critical',
  High: 'High',
  HoverContent: 'on the main branch',
  Medium: 'Medium',
} as const;
