import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BarDatum } from '@nivo/bar';
import { Column, Row } from 'react-table';

import { OptionType, SelectedFilters } from '~utils/global.types.react';
import { strings } from '~utils/strings';
import SeverityBadge from '../../../../reactComponents/SeverityBadge/SeverityBadge.react';

export const DEFAULT_FILTERS = {
  Category: [strings.general.efficiency, strings.general.reliability, strings.general.security],
  Severity: [
    strings.general.critical,
    strings.general.high,
    strings.general.medium,
    strings.general.low,
    strings.general.none.toLowerCase(),
  ],
};

export enum ActionItemAggregator {
  title = 'title',
  eventType = 'eventType',
  cluster = 'cluster',
  namespace = 'namespace',
  workload = 'workload',
  kind = 'kind',
  reportType = 'reportType',
  severity = 'severity',
  category = 'category',
  assigneeEmail = 'assigneeEmail',
  container = 'container',
  status = 'status',
}

export interface ActionItemAggregatorOption extends OptionType {
  value: ActionItemAggregator;
  label: string;
}

export interface ActionItemAggregation extends BarDatum {
  title: string;
  fixedCount: number;
  manuallyResolvedCount: number;
  openCount: number;
  reportType: string;
  aggregator: string;
  openCriticalCount: number;
  openHighCount: number;
  openLowCount: number;
  openMediumCount: number;
  openNoneCount: number;
}

export const titleAggregatorOption = {
  value: ActionItemAggregator.title,
  label: strings.aggregatedActionItems.aggregatorLabels.title,
};
export const eventTypeAggregatorOption = {
  value: ActionItemAggregator.eventType,
  label: strings.aggregatedActionItems.aggregatorLabels.eventType,
};
export const clusterAggregatorOption = {
  value: ActionItemAggregator.cluster,
  label: strings.aggregatedActionItems.aggregatorLabels.cluster,
};

export const aggregatorOptions: ActionItemAggregatorOption[] = [
  {
    value: ActionItemAggregator.cluster,
    label: strings.aggregatedActionItems.aggregatorLabels.cluster,
  },
  {
    value: ActionItemAggregator.namespace,
    label: strings.aggregatedActionItems.aggregatorLabels.namespace,
  },
  {
    value: ActionItemAggregator.workload,
    label: strings.aggregatedActionItems.aggregatorLabels.workload,
  },
  { value: ActionItemAggregator.kind, label: strings.aggregatedActionItems.aggregatorLabels.kind },
  eventTypeAggregatorOption,
  {
    value: ActionItemAggregator.reportType,
    label: strings.aggregatedActionItems.aggregatorLabels.reportType,
  },
  {
    value: ActionItemAggregator.severity,
    label: strings.aggregatedActionItems.aggregatorLabels.severity,
  },
  {
    value: ActionItemAggregator.category,
    label: strings.aggregatedActionItems.aggregatorLabels.category,
  },
  {
    value: ActionItemAggregator.assigneeEmail,
    label: strings.aggregatedActionItems.aggregatorLabels.assigneeEmail,
  },
  {
    value: ActionItemAggregator.container,
    label: strings.aggregatedActionItems.aggregatorLabels.container,
  },
  // invalid aggregator error
  // (API bug being fixed in: https://github.com/FairwindsOps/Insights/pull/4261)
  {
    value: ActionItemAggregator.status,
    label: strings.aggregatedActionItems.aggregatorLabels.status,
  },
];

export const defaultAggregators = [eventTypeAggregatorOption];

export enum SelectAction {
  Select = 'select-option',
  Deselect = 'deselect-option',
}

export interface IAggregatedActionItems {
  title: string;
  openCount: number;
  manuallyResolvedCount: number;
  fixedCount: number;
  openCriticalCount: number;
  openHighCount: number;
  openLowCount: number;
  openMediumCount: number;
  openNoneCount: number;
}

const aggregatorColumnMapper = (onSelectedFiltersChanged: any) => ({
  cluster: {
    Header: 'Cluster',
    accessor: 'cluster',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['cluster' as keyof typeof row.original];

      return (
        <span
          title={value}
          className="aggregator-column-value"
          onClick={() => onSelectedFiltersChanged('cluster', value)}
        >
          {value}
        </span>
      );
    },
  },
  eventType: {
    Header: 'Title/Event Type',
    accessor: 'title',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['title' as keyof typeof row.original]
        ? row.original['title' as keyof typeof row.original]
        : row.original['eventType' as keyof typeof row.original];

      const isTitle = !!row.original['title' as keyof typeof row.original];

      return (
        <span
          title={value}
          className={'aggregator-column-value'}
          onClick={() => {
            if (isTitle) {
              onSelectedFiltersChanged('title', value);
            } else {
              onSelectedFiltersChanged('eventType', value);
            }
          }}
        >
          {value}
        </span>
      );
    },
  },
  namespace: {
    Header: 'Namespace',
    accessor: 'resourceNamespace',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['resourceNamespace' as keyof typeof row.original];

      return (
        <span
          title={value}
          className="aggregator-column-value"
          onClick={() => onSelectedFiltersChanged('namespace', value)}
        >
          {value}
        </span>
      );
    },
  },
  workload: {
    Header: 'Workload',
    accessor: 'resourceName',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['resourceName' as keyof typeof row.original];
      return <span title={value}>{value}</span>;
    },
  },
  kind: {
    Header: 'Kind',
    accessor: 'resourceKind',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['resourceKind' as keyof typeof row.original];
      return (
        <span title={value} className="aggregator-column-value" onClick={() => onSelectedFiltersChanged('kind', value)}>
          {value}
        </span>
      );
    },
  },
  reportType: {
    Header: strings.columnTitles.reportType,
    accessor: 'reportType',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['reportType' as keyof typeof row.original];
      return <span title={value}>{value}</span>;
    },
  },
  severity: {
    Header: 'Severity',
    accessor: strings.vulnerabilities.severity_lowercase,
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original[strings.vulnerabilities.severity_lowercase as keyof typeof row.original];
      return (
        <span
          title={value}
          className="aggregator-column-value"
          onClick={() => onSelectedFiltersChanged('severity', value)}
        >
          {value}
        </span>
      );
    },
  },
  category: {
    Header: 'Category',
    accessor: 'category',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['category' as keyof typeof row.original];
      return (
        <span
          title={value}
          className="aggregator-column-value"
          onClick={() => onSelectedFiltersChanged('category', value)}
        >
          {value}
        </span>
      );
    },
  },
  assigneeEmail: {
    Header: 'Assignee Email',
    accessor: 'assigneeEmail',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['assigneeEmail' as keyof typeof row.original];
      return <span title={value}>{value}</span>;
    },
  },
  container: {
    Header: 'Container',
    accessor: 'container',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['resourceContainer' as keyof typeof row.original];
      return (
        <span
          title={value}
          className="aggregator-column-value"
          onClick={() => onSelectedFiltersChanged('container', value)}
        >
          {value}
        </span>
      );
    },
  },
  status: {
    Header: strings.columnTitles.Status,
    accessor: 'status',
    className: 'no-overflow',
    minWidth: 400,
    Cell: ({ row }: { row: Row }) => {
      const value = row.original['status' as keyof typeof row.original];
      return <span title={value}>{value}</span>;
    },
  },
});

const renderColumnsByAggregators = (aggregators: OptionType[], onSelectedFiltersChanged: any) => {
  const columns: any = [];

  const mapper = aggregatorColumnMapper(onSelectedFiltersChanged);

  for (const aggregator of aggregators) {
    columns.push(mapper[aggregator.value as keyof typeof mapper]);
  }

  return columns;
};

const queryParamsAggregatorsMapper = {
  cluster: {
    queryName: 'Cluster',
    apiFieldName: 'cluster',
  },
  namespace: {
    queryName: 'ResourceNamespace',
    apiFieldName: 'resourceNamespace',
  },
  kind: {
    queryName: 'ResourceKind',
    apiFieldName: 'resourceKind',
  },
  eventType: {
    queryName: 'EventType',
    apiFieldName: 'eventType',
  },
  title: {
    queryName: 'Title',
    apiFieldName: 'title',
  },
  reportType: {
    queryName: 'ReportType',
    apiFieldName: 'reportType',
  },
  severity: {
    queryName: 'Severity',
    apiFieldName: strings.vulnerabilities.severity_lowercase,
  },
  category: {
    queryName: 'Category',
    apiFieldName: 'category',
  },
  assigneeEmail: {
    queryName: 'AssigneeEmail',
    apiFieldName: 'assigneeEmail',
  },
  container: {
    queryName: 'ResourceContainer',
    apiFieldName: 'resourceContainer',
  },
  workload: {
    queryName: 'ResourceName',
    apiFieldName: 'resourceName',
  },
};

const getQueryParamsByAggregators = (aggregators: OptionType[], row: Row) => {
  let queryParams = '';

  for (const aggregator of aggregators) {
    if (!aggregator?.value || !(aggregator.value in queryParamsAggregatorsMapper)) {
      continue;
    }

    const mapKey = aggregator.value as keyof typeof queryParamsAggregatorsMapper;

    const queryName = queryParamsAggregatorsMapper[mapKey].queryName;
    const apiFieldName = queryParamsAggregatorsMapper[mapKey].apiFieldName;

    if (!queryName || !apiFieldName) {
      continue;
    }

    const rowValue = row.original[apiFieldName as keyof typeof row.original];

    if (!rowValue) {
      continue;
    }

    const newQuery = `${queryName}=${rowValue}`;

    queryParams += `&${newQuery}`;
  }

  return queryParams;
};

export const TABLE_COLUMNS = (
  baseActionItemLink: string,
  aggregators: OptionType[],
  selectedFilters: SelectedFilters,
  onSelectedFiltersChanged: any,
): Column[] => {
  // mapper for action items links.
  const actionItemsParamsMapper = {
    Fixed: 'Fixed',
    Category: 'Category',
    Severity: 'Severity',
    Cluster: 'Cluster',
    PodLabel: 'ResourceLabel',
    Container: 'ResourceContainer',
    Kind: 'ResourceKind',
    Namespace: 'ResourceNamespace',
    Title: 'Title',
    ReportType: 'ReportType',
  };

  return [
    ...renderColumnsByAggregators(aggregators, onSelectedFiltersChanged),
    {
      Header: 'Severity (Open)',
      accessor: 'openCriticalCount',
      className: 'no-overflow',
      minWidth: 250,
      disableSortBy: true,
      Cell: ({ row }: { row: Row }) => {
        return (
          <SeverityBadge
            id={uuidv4()}
            severityCount={{
              nCritical: row.original.openCriticalCount,
              nHigh: row.original.openHighCount,
              nMedium: row.original.openMediumCount,
              nLow: row.original.openLowCount,
            }}
          />
        );
      },
    },
    {
      Header: 'Open',
      accessor: 'openCount',
      className: 'no-overflow',
      minWidth: 100,
      Cell: ({ row }: { row: Row }) => {
        const openActionItemLink = `${baseActionItemLink}&Fixed=false&Resolution=None&redirect=true${getQueryParamsByAggregators(
          aggregators,
          row,
        )}`;

        return (
          <a className="aggregated-action-item-badge__container" href={openActionItemLink}>
            <span className="aggregated-action-item-badge__text aggregated-action-item-badge__value">
              {row.original.openCount}
            </span>
            {/* <span className="aggregated-action-item-badge__text aggregated-action-item-badge__label">
              {strings.appGroups.seeActionItems}
            </span> */}
          </a>
        );
      },
    },
    {
      Header: 'Manually Resolved',
      accessor: 'manuallyResolvedCount',
      className: 'no-overflow',
      minWidth: 200,
      Cell: ({ row }: { row: Row }) => {
        const manuallyResolvedActionItemLink = `${baseActionItemLink}&Fixed=false&Resolution=working_as_intended&Resolution=wont_fix&redirect=true${getQueryParamsByAggregators(
          aggregators,
          row,
        )}`;

        return (
          <a className="aggregated-action-item-badge__container" href={manuallyResolvedActionItemLink}>
            <span className="aggregated-action-item-badge__text aggregated-action-item-badge__value">
              {row.original.manuallyResolvedCount}
            </span>
            {/* <span className="aggregated-action-item-badge__text aggregated-action-item-badge__label">
              {strings.appGroups.seeActionItems}
            </span> */}
          </a>
        );
      },
    },
    {
      Header: 'Fixed',
      accessor: 'fixedCount',
      className: 'no-overflow',
      minWidth: 100,
      Cell: ({ row }: { row: Row }) => {
        const fixedActionItemLink = `${baseActionItemLink}&Fixed=true&redirect=true${getQueryParamsByAggregators(
          aggregators,
          row,
        )}`;

        return (
          <a className="aggregated-action-item-badge__container" href={fixedActionItemLink}>
            <span className="aggregated-action-item-badge__text aggregated-action-item-badge__value">
              {row.original.fixedCount}
            </span>
            {/* <span className="aggregated-action-item-badge__text aggregated-action-item-badge__label">
              {strings.appGroups.seeActionItems}
            </span> */}
          </a>
        );
      },
    },
  ];
};

export interface Team {
  Clusters: string[] | null;
  ID: number;
  Memberships: Record<string, unknown>[];
  Name: string;
  Namespaces: string | null;
  Organization: string;
  Repositories: string[] | null;
}

export const ADVANCED_FILTERS_MAPPER: Record<string, string> = {
  Cluster: 'Cluster',
  ResourceKind: 'Kind',
  ResourceNamespace: 'Namespace',
  Team: 'Team',
  Severity: 'Severity',
  Category: 'Category',
  Title: 'Title',
  ResourceContainer: 'Container',
  PodLabel: 'Pod Labels',
  EventType: 'Event Type',
  ReportType: strings.columnTitles.reportType,
  AppGroup: strings.general.AppGroup,
};

export const SEARCH_PARAMS_MAPPER: Record<string, string> = {
  Cluster: strings.aggregators.cluster,
  Namespace: strings.aggregators.namespace,
  Team: 'teams',
  Severity: strings.aggregators.severity,
  Category: strings.aggregators.category,
  Kind: 'kind',
  Title: 'title',
  Container: 'container',
  Workload: 'workload',
  Fixed: 'fixed',
  'Event Type': 'eventType',
  'Report Type': 'reportType',
  'App Group': 'appGroup',
};

export const REVERSED_SEARCH_PARAMS_MAPPER: Record<string, string> = {
  cluster: 'Cluster',
  namespace: 'Namespace',
  teams: 'Team',
  severity: 'Severity',
  category: 'Category',
  kind: 'Kind',
  title: 'Title',
  container: 'Container',
  workload: 'Workload',
  fixed: 'Fixed',
  eventType: 'Event Type',
  reportType: strings.columnTitles.reportType,
  appGroup: strings.general.AppGroup,
};

export const TEAM = 'Team';

export const TEAMS = 'teams';
