import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Card, Spinner } from 'react-bootstrap';
import { AddButton, Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { TABLE_COLUMNS } from './Compliance.config.react';
import { Column, useTable } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';
import { sendRequest } from '~utils/request';
import { handlePageChange } from '~reactHelpers';
import { Membership, IStore, IRoute, IRouter } from '~globalTypes';
import { ReportType, ReportsSummary, SelectedReport } from '../Compliance.types.react';
import logger from '~logger';
import CreateEditModal from './CreateEditModal.react';
import DeleteModal from './DeleteModal.react';
import { standardizedReports } from '../Compliance.helpers.react';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { strings } from '~utils/strings';

import './Compliance.react.scss';

type ComplianceProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const Compliance = ({ route, router, store }: ComplianceProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const isOrgOwner = store().getters.isOrgOwner;

  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<string>('');
  const [report, setReport] = useState<ReportType>({ name: '', clusters: [], standards: [] });
  const [reports, setReports] = useState<ReportsSummary[]>([]);
  const [selectedReport, setSelectedReport] = useState<SelectedReport | null>(null);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'last',
      label: 'Compliance Reports',
      href: ``,
      isActive: true,
    },
  ];

  useEffect(() => {
    if (isOrgOwner) {
      setLoading(true);
      getAllReports();
    }
  }, [isOrgOwner]);

  const getAllReports = async () => {
    try {
      const allReports = await sendRequest(
        'GET',
        `${baseURL}/compliance/reports/summaries`,
        {},
        null,
      );
      setReports(allReports.reportsSummaries);
    } catch (e) {
      logger.logError('error_retrieving_compliance_reports', e);
      toast.error(<b>Error retrieving reports</b>);
    }
    setLoading(false);
  };

  const isOwner = useMemo(() => {
    const matchingOrg =
      org === strings.noTranslate.centaurus
        ? { IsOwner: true }
        : store().getters.memberships.find(
            (membership: Membership) => membership.Organization === org,
          );

    return matchingOrg?.IsOwner || store().getters.user?.IsSuperAdmin;
  }, [org]);

  const handleModalClose = (deleteModal?: string) => {
    setReport({ name: '', clusters: [], standards: [] });
    if (deleteModal === 'delete') {
      setShowModal('delete');
    } else {
      setSelectedReport(null);
      setShowModal('');
    }
  };

  const createReport = async (name: string) => {
    const data = {
      name: name,
      clusters: report.clusters.map((cluster) => cluster.value),
      standards: report.standards.map((type) => type.value),
    };

    try {
      await sendRequest(
        'POST',
        `${baseURL}/compliance/reports`,
        { data, returnHeaders: true },
        null,
      );
      toast.success(<b>Report created</b>);
    } catch (e) {
      logger.logError('error_creating_compliance_report', e);
      if (e.status === 400) {
        toast.error(<b>{`${e.message}. Please use a different name`}</b>);
      } else {
        toast.error(<b>We had an issue creating your report. Please try again</b>);
      }
    }
    handleModalClose();
    getAllReports();
  };

  const handleEditModal = (editableReport: ReportsSummary) => {
    const updatedReport = {
      name: editableReport.name,
      id: editableReport.id,
      clusters: editableReport.clusters.map((cluster) => ({ value: cluster, label: cluster })),
      standards: editableReport.standards.map((type) => {
        const formattedType = standardizedReports(type);
        return { value: type, label: formattedType };
      }),
    };
    setReport({
      name: updatedReport.name,
      clusters: updatedReport.clusters,
      standards: updatedReport.standards,
    });
    setSelectedReport(updatedReport);
    setShowModal('edit');
  };

  const submitEditedReport = async (name?: string) => {
    const data = {
      name: name || selectedReport?.name,
      clusters: report.clusters.map((cluster) => cluster.value),
      standards: report.standards.map((type) => type.value),
    };
    try {
      await sendRequest(
        'PUT',
        `${baseURL}/compliance/reports/${selectedReport?.id}`,
        { data },
        null,
      );
      toast.success(<b>Updated report!</b>);
    } catch (e) {
      logger.logError('error_editing_compliance_report', e);
      toast.error(<b>error editing report</b>);
    }
    handleModalClose();
    getAllReports();
  };

  const data = useMemo(() => reports, [reports]);
  const columns = useMemo<Column[]>(() => TABLE_COLUMNS(handleEditModal, router), [reports]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    hiddenColumns: !isOwner ? ['update'] : [],
  });

  const handleCreateModal = () => {
    setShowModal('create');
  };

  const deleteReport = async () => {
    try {
      await sendRequest('DELETE', `${baseURL}/compliance/reports/${selectedReport?.id}`, {}, null);
      toast.success(<b>Report deleted!</b>);
    } catch (e) {
      logger.logError('error_deleting_report', e);
      toast.error(<b>Sorry, we were unable to delete your report, please try again</b>);
    }
    getAllReports();
    handleModalClose();
  };

  if (!isOrgOwner) {
    return (
      <Alert variant={strings.noTranslate.info} className="owner-role-required">
        <div>{strings.compliance.ownerRoleRequired}</div>
      </Alert>
    );
  }

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <Card className="reports-card">
        <Card.Body>
          <Card.Title className="mb-3">All Compliance Reports</Card.Title>
          <Card.Subtitle className="mb-4 text-muted">
            All of these reports can be exported into a PDF report.
          </Card.Subtitle>
          <AddButton
            buttonText="Create New Report"
            action={handleCreateModal}
            disabled={!isOwner}
            testLabel={strings.testLabels.addButton}
          />
          {loading && (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" className="reports-spinner">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {!loading && reports.length > 0 && (
            <table
              {...getTableProps()}
              className="reports-table"
              aria-label="Compliance reports table"
            >
              <thead className="reports-header">
                {headerGroups.map((headerGroup) => (
                  <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {!loading && !reports.length && isOwner && (
            <div className="error-message">
              <h6>
                Sorry, it looks like you don't have any reports. Add a report above to track your
                compliance reports
              </h6>
            </div>
          )}
          {!loading && !reports.length && !isOwner && (
            <div className="error-message">
              <h6>
                Sorry, it looks like your organization doesn't have any reports. Speak to your
                organization owner to create a report.
              </h6>
            </div>
          )}
        </Card.Body>
      </Card>
      {showModal === 'delete' && (
        <DeleteModal
          show={showModal}
          closeModal={handleModalClose}
          selectedReport={selectedReport}
          isOwner={isOwner}
          deleteReport={deleteReport}
        />
      )}
      {showModal === 'edit' && (
        <CreateEditModal
          show={showModal}
          closeModal={handleModalClose}
          selectedReport={selectedReport}
          isOwner={isOwner}
          report={report}
          setReport={setReport}
          store={store}
          createReport={createReport}
          submitEdit={submitEditedReport}
        />
      )}
      {showModal === 'create' && (
        <CreateEditModal
          show={showModal}
          closeModal={handleModalClose}
          selectedReport={selectedReport}
          isOwner={isOwner}
          report={report}
          setReport={setReport}
          store={store}
          createReport={createReport}
          submitEdit={submitEditedReport}
        />
      )}
      <Toaster />
    </LayoutReact>
  );
};

export default Compliance;
