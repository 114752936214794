import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import AgentStatus from '~reactComponents/cards/agentStatus/AgentStatus.react';
import TopOrgIssues from '~reactComponents/cards/topIssues/TopOrgIssues.react';
import OrgActivities from '~reactComponents/cards/activities/Activities.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';

import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IStore, IRouter, Report } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { handlePageChange } from '~reactHelpers';
import { strings } from '~utils/strings';

import './Dashboard.react.scss';

type DashboardProps = {
  router: () => IRouter;
  store: () => IStore;
};

const Dashboard = ({ router, store }: DashboardProps) => {
  const organization = store().getters.organization;
  const repositories = store().getters.repositories;
  const clusters = store().getters.clusters;

  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    const getReports = async () => {
      if (!organization?.Name) {
        return;
      }

      try {
        const reports = await sendRequest(
          'GET',
          `/v0/organizations/${organization.Name}/reports/latest`,
          {},
          null,
        );

        setReports(reports);
      } catch (e) {
        if (e) {
          logger.logError('error_fetching_latest_reports', e);
          toast.error(strings.general.fetchingError);
        }
      }
    };

    getReports();
  }, [organization]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: 'last',
      label: strings.general.home,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="org-dashboard">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="org-container mt-4">
        <div className="left-dashboard">
          <TopOrgIssues organization={organization} router={router} />
        </div>
        <div className="right-dashboard">
          <AgentStatus
            clusters={clusters}
            organization={organization}
            reports={reports}
            repos={repositories}
          />
          <OrgActivities organization={organization} />
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Dashboard;
