import React, { SyntheticEvent, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import { TableInstance } from 'react-table';
import useRootClose from 'react-overlays/useRootClose';

import Icon from '~reactIcons/Icon.react';
import MoveIcon from '~reactIcons/Move.icon.react';

import { Cluster } from '~globalTypes';

import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import './ToggleColumnsVisibility.react.scss';

type ToggleColumnsVisibilityProps = {
  cluster?: Cluster;
  tableInstance: TableInstance<any>;
};

const ToggleColumnsVisibility = ({
  cluster,
  tableInstance,
}: ToggleColumnsVisibilityProps): JSX.Element => {
  let { allColumns } = tableInstance;

  allColumns = allColumns.filter((column) => (cluster ? column.Header !== 'Clusters' : true));

  const ref = useRef();
  const [show, setShow] = useState(false);

  const handleRootClose = () => setShow(false);

  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="action--items--custom-toggle"
    >
      {children}
    </div>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');

      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <Droppable droppableId="action-items-columns">
            {(provided) => (
              <ul className="list-unstyled" {...provided.droppableProps} ref={provided.innerRef}>
                {React.Children.toArray(children).filter((child) => {
                  return !value || child.props.draggableId.toLowerCase().startsWith(value);
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      );
    },
  );

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const columns = Array.from(allColumns);
    const [reorderedItem] = columns.splice(result.source.index, 1);
    columns.splice(result.destination.index, 0, reorderedItem);
    tableInstance.setColumnOrder(columns.map((item) => item.id));
    return columns;
  };

  const handleDropdownToggle = (
    isOpen: boolean,
    event: SyntheticEvent<Dropdown>,
    metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' },
  ) => {
    setShow(metadata.source === 'click' ? !show : true);
  };

  const hiddenColumnsCount = allColumns.filter((col) => !col.isVisible).length;

  const Toggle = React.memo(({ hiddenColumnsCounts }: { hiddenColumnsCounts: number }) => {
    return (
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Icon name="eye-slash" height="1rem" width="1rem" fill={COLORS.CORE.WHITE} />
        <span>{hiddenColumnsCounts} hidden columns</span>
      </Dropdown.Toggle>
    );
  });

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Dropdown focusFirstItemOnShow show={show} onToggle={handleDropdownToggle}>
        <Toggle hiddenColumnsCounts={hiddenColumnsCount} />

        <Dropdown.Menu as={CustomMenu} ref={ref}>
          {allColumns.map((column, index) => {
            if (column.id !== strings.general.selection) {
              return (
                <Draggable key={column.id} draggableId={column.Header as string} index={index}>
                  {(provided) => (
                    <Dropdown.Item
                      as="li"
                      eventKey={column.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <Form>
                            <Form.Check
                              id={column.id}
                              type="switch"
                              {...column.getToggleHiddenProps()}
                            />
                          </Form>
                          <span>{column.Header}</span>
                        </div>
                        <MoveIcon />
                      </div>
                    </Dropdown.Item>
                  )}
                </Draggable>
              );
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </DragDropContext>
  );
};

export default ToggleColumnsVisibility;
