import React from 'react';
import { Cluster, LatestReport } from '~globalTypes';
import { toast } from 'react-hot-toast';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import {
  EXPORT_FIELDS_MAP as ALL_IMAGES_EXPORT_FIELDS_MAP,
  EXPORT_FORMAT as ALL_IMAGES_EXPORT_FORMAT,
  VulnerabilitiesActionItems as AllImagesVulnerabilitiesActionItems,
  VulnerabilitiesActionItemsResponse as AllImagesVulnerabilitiesActionItemsResponse,
} from './AllImages/components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import {
  EXPORT_FIELDS_MAP as ALL_VULNERABILITIES_EXPORT_FIELDS_MAP,
  VulnerabilitiesActionItems as AllVulnerabilitiesActionItems,
} from './AllVulnerabilities/components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import {
  EXPORT_FIELDS_MAP as IMAGE_DETAIL_EXPORT_FIELDS_MAP,
  ImageDetailItem,
} from './ImageDetail/components/ImageDetailItemsTable/ImageDetailItemsTable.types.react';

import {
  ActionItemsResponse,
  ExportCSVParams,
  ExportParams,
  GetChartURLSearchParams,
  VulnerabilitiesItemsType,
  VulnerabilitiesResponseItemsType,
} from './ReactVulnerabilities.types.react';

import { convertJsonToCsv, downloadCsv, sortDescending } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { getCurrentTimezone } from '~reactHelpers';

dayjs.extend(timezone);

export const getTrivyReports = async (
  baseURL: string,
  cluster: Cluster,
  callback: (lastSyncedDate: string) => void,
) => {
  const timeZone = getCurrentTimezone();
  const latestReports = await getLatestReports(baseURL);
  const lastSyncedDate = getLastSyncedDate(cluster!, latestReports);
  if (lastSyncedDate) {
    const day = timeZone
      ? dayjs(lastSyncedDate).tz(timeZone).format('MM/DD/YYYY')
      : dayjs(lastSyncedDate).format('MM/DD/YYYY');
    const time = timeZone
      ? dayjs(lastSyncedDate).tz(timeZone).format('h:mm A')
      : dayjs(lastSyncedDate).format('h:mm A');
    callback(`${day} at ${time}`);
  } else {
    callback('');
  }
};

const getLatestReports = async (baseURL: string) => {
  try {
    return await sendRequest('GET', `${baseURL}/reports/latest`, { showErrorAlert: false }, null);
  } catch (e) {
    logger.logError('error_get_last_synced_date_vulnerabilities_items_table', e);
  }
  return null;
};

const Labels = {
  trivy: 'trivy',
  exportAll: 'export-all-items',
  exportFilteredItems: 'export-filtered-items',
  cluster: 'cluster',
};

const getLastSyncedDate = (cluster: Cluster, latestReports: LatestReport[]) => {
  if (!latestReports?.length) return '';
  const trivyReports = latestReports
    .filter((report: LatestReport) => report.ReportType === Labels.trivy)
    .sort((a, b) => sortDescending(a.UpdateTime, b.UpdateTime));
  if (cluster) {
    const clusterTrivyReports = trivyReports.filter((report: LatestReport) => report.Cluster === cluster.Name);
    return clusterTrivyReports?.length ? clusterTrivyReports[0].UpdateTime : '';
  }
  return trivyReports?.length ? trivyReports[0].UpdateTime : '';
};

export const PAGES = {
  ALL_IMAGES: 'vulnerabilities_all_images',
  ALL_VULNERABILITIES: 'vulnerabilities_all_vulnerabilities',
  IMAGE_DETAIL: 'vulnerabilities_image_detail',
};

export const EXPORT_STATUS_MESSAGES = {
  LOADING: 'Exporting to CSV...',
  SUCCESS: 'Export Ready!',
  FAILED: 'An issue happened when exporting the Action Items.',
};

export const EXPORT_IMAGES_OPTIONS = ['Export all images to CSV', 'Export filtered images to CSV'];

export const EXPORT_VULNERABILITIES_OPTIONS = [
  'Export all vulnerabilities to CSV',
  'Export filtered vulnerabilities to CSV',
  'Export CVEs by App Group to CSV',
];

export const exportActionItems = async ({ from, apiEndpoint, orgName }: ExportParams) => {
  const response = await fetchActionItems({ from, apiEndpoint });
  const data = getData(from!, response);
  if (!data) {
    throw new Error();
  }
  await createCSVFile(from!, transformResponse(from!, data), orgName!);
};

export const fetchActionItems = async ({ from, apiEndpoint }: ExportParams) => {
  if (!from || !apiEndpoint) return {};
  try {
    const { data, headers } = await sendRequest('GET', apiEndpoint, { returnHeaders: true }, null);
    return { data, headers };
  } catch (e) {
    toast.error('Unable to fetch vulnerabilities items. Try again.');
    logger.logError('error_fetching_vulnerabilities_items_export', e);
    return { data: { summaries: [], vulnerabilities: [], total: 0 }, headers: {} };
  }
};

const getData = (from: string, response: ActionItemsResponse) => {
  if (!from || !response) return [];
  switch (from) {
    case PAGES.ALL_IMAGES:
    case PAGES.ALL_VULNERABILITIES:
      return response.data?.summaries ? response.data.summaries : [];
    case PAGES.IMAGE_DETAIL:
      return response.data?.vulnerabilities ? response.data.vulnerabilities : [];
    default:
      return [];
  }
};

export const transformResponse = (from: string, data: VulnerabilitiesResponseItemsType) => {
  if (!data?.length) return [];
  switch (from) {
    case PAGES.ALL_IMAGES:
      return transformAllImagesVulnActionItems(data as AllImagesVulnerabilitiesActionItemsResponse[]);
    case PAGES.ALL_VULNERABILITIES:
      return data as AllVulnerabilitiesActionItems[];
    case PAGES.IMAGE_DETAIL:
      return data as ImageDetailItem[];
    default:
      return [];
  }
};

export const transformAllImagesVulnActionItems = (data: AllImagesVulnerabilitiesActionItemsResponse[]) => {
  if (!data?.length) return [];
  const transformedVulnActionItems = [];
  for (const vulnActionItem of data) {
    transformedVulnActionItems.push(transformAllImagesVulnActionItem(vulnActionItem));
  }
  return transformedVulnActionItems;
};

const transformAllImagesVulnActionItem = (vulnActionItem: AllImagesVulnerabilitiesActionItemsResponse) => ({
  id: vulnActionItem?.id,
  title: vulnActionItem?.title,
  sha: vulnActionItem?.sha,
  severityLabel: {
    nCritical: vulnActionItem?.severitiesSummary?.criticalCount,
    nHigh: vulnActionItem?.severitiesSummary?.highCount,
    nMedium: vulnActionItem?.severitiesSummary?.mediumCount,
  },
  vulnerabilitiesCount: vulnActionItem?.vulnerabilitiesCount,
  cluster: vulnActionItem?.clusters,
  repositoriesCount: vulnActionItem?.repositoriesCount,
  workloadsCount: vulnActionItem?.workloadsCount,
  recommended: vulnActionItem?.recommended,
  package: vulnActionItem?.packages,
  tag: vulnActionItem?.tag,
  lastScanned: vulnActionItem?.lastScanned,
  riskReduction: vulnActionItem?.riskReduction,
});

export const createCSVFile = async (
  from: string,
  data: AllImagesVulnerabilitiesActionItems[] | AllVulnerabilitiesActionItems[] | ImageDetailItem[],
  org: string,
) => {
  const exportFieldsMap = getExportFieldsMap(from);
  const formattedActionItems = formatAIsForExport(from, data, exportFieldsMap);
  const csvData = await convertJsonToCsv(formattedActionItems, Object.values(exportFieldsMap));
  const filename = actionItemFileName(from, org);
  downloadCsv(csvData, filename);
};

const getExportFieldsMap = (from: string) => {
  if (!from) return {};
  switch (from) {
    case PAGES.ALL_IMAGES:
      return ALL_IMAGES_EXPORT_FIELDS_MAP;
    case PAGES.ALL_VULNERABILITIES:
      return ALL_VULNERABILITIES_EXPORT_FIELDS_MAP;
    case PAGES.IMAGE_DETAIL:
      return IMAGE_DETAIL_EXPORT_FIELDS_MAP;
    default:
      return {};
  }
};

export const formatAIsForExport = (
  from: string,
  actionItems: VulnerabilitiesItemsType,
  exportFieldsMap: Record<string, string>,
) =>
  actionItems.map((actionItem) => {
    const formattedAI: Record<string, unknown> = {};

    switch (from) {
      case PAGES.ALL_IMAGES:
        formatAllImagesAI(actionItem as AllImagesVulnerabilitiesActionItems, formattedAI, exportFieldsMap);
        break;
      case PAGES.ALL_VULNERABILITIES:
      case PAGES.IMAGE_DETAIL:
        formatAI(actionItem as AllVulnerabilitiesActionItems | ImageDetailItem, formattedAI, exportFieldsMap);
        break;
      default:
        break;
    }
    return formattedAI;
  });

const formatAllImagesAI = (
  actionItem: AllImagesVulnerabilitiesActionItems,
  formattedAI: Record<string, unknown>,
  exportFieldsMap: Record<string, string>,
) => {
  for (const field in exportFieldsMap) {
    const formattedField = exportFieldsMap[field];
    if (ALL_IMAGES_EXPORT_FORMAT[field as keyof typeof ALL_IMAGES_EXPORT_FORMAT]) {
      formattedAI[formattedField] = ALL_IMAGES_EXPORT_FORMAT[field](actionItem);
    } else {
      formattedAI[formattedField] = actionItem[field as keyof AllImagesVulnerabilitiesActionItems];
    }
  }
};

const formatAI = (
  actionItem: AllVulnerabilitiesActionItems | ImageDetailItem,
  formattedAI: Record<string, unknown>,
  exportFieldsMap: Record<string, string>,
) => {
  for (const field in exportFieldsMap) {
    const formattedField = exportFieldsMap[field];
    formattedAI[formattedField] = actionItem[field as keyof (AllVulnerabilitiesActionItems | ImageDetailItem)];
  }
};

export const actionItemFileName = (from: string, orgName: string): string => `${from}_action-items_${orgName}`;

export const exportCSV = async ({
  action,
  exportAllCallback,
  exportFilteredCallback,
  exportCvesByAppGroupCallback,
  totalExportFilteredItems,
  searchParams,
  logEventKey,
  cluster,
}: ExportCSVParams) => {
  const exportOptions = action.includes('images') ? EXPORT_IMAGES_OPTIONS : EXPORT_VULNERABILITIES_OPTIONS;

  let exportPromise;
  if (action === exportOptions[0]) {
    logger.logEvent(`${logEventKey}:${getExportLogEvent('all')}`, { cluster });
    exportPromise = exportAllCallback();
  } else if (action === exportOptions[1]) {
    searchParams?.delete('page');
    searchParams?.set('pageSize', String(totalExportFilteredItems));
    logger.logEvent(`${logEventKey}:${getExportLogEvent('filtered')}`, {
      searchParams: searchParams?.toString(),
    });
    exportPromise = exportFilteredCallback();
  } else if (action === exportOptions[2]) {
    exportPromise = exportCvesByAppGroupCallback();
  }

  if (exportPromise) {
    await toast.promise(exportPromise, {
      loading: EXPORT_STATUS_MESSAGES.LOADING,
      success: <b>{EXPORT_STATUS_MESSAGES.SUCCESS}</b>,
      error: <b>{EXPORT_STATUS_MESSAGES.FAILED}</b>,
    });
  }
};

export const getExportURLSearchParams = (cluster: Cluster, totalRowCount: number) =>
  cluster?.Name
    ? new URLSearchParams(`pageSize=${totalRowCount}&cluster=${cluster.Name}`)
    : new URLSearchParams(`pageSize=${totalRowCount}`);

export const getChartURLSearchParams = ({
  groupBy,
  cluster,
  isResolvedShown = undefined,
  selectedAppGroup,
  firstSeen = null,
  firstSeenEnd = null,
}: GetChartURLSearchParams) => {
  const timeZone = getCurrentTimezone();
  let searchParams = `groupBy=${groupBy}`;
  if (cluster) {
    searchParams = `${searchParams}&cluster=${cluster.Name}`;
  }
  if (isResolvedShown !== undefined) {
    searchParams = `${searchParams}&resolved=${isResolvedShown}`;
  }
  if (selectedAppGroup) {
    searchParams = `${searchParams}&appGroups=${selectedAppGroup.name}`;
  }
  if (firstSeen) {
    searchParams = `${searchParams}&firstSeen=${
      timeZone ? dayjs(firstSeen).tz(timeZone).toISOString() : dayjs(firstSeen).toISOString()
    }`;
  }
  if (firstSeenEnd) {
    searchParams = `${searchParams}&firstSeenEnd=${
      timeZone ? dayjs(firstSeenEnd).tz(timeZone).toISOString() : dayjs(firstSeenEnd).toISOString()
    }`;
  }
  return new URLSearchParams(searchParams);
};

export const getExportLogEvent = (action: string) => {
  switch (action) {
    case 'all':
      return Labels.exportAll;
    case 'filtered':
      return Labels.exportFilteredItems;
    default:
      return null;
  }
};

export const formatChartLabel = (value: string, postfix: string) => {
  if (!value || !postfix) return value;
  return value?.length > 8 ? `${value.substring(0, 9)}${postfix}` : value;
};
