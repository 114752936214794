import { sendRequest } from '~utils/request';
import logger from '~logger';

export const handleAssigneeSelection = async (
  email: string,
  sha: string,
  baseUrl: string,
  dryRun = false
) => {
  try {
    const assigneeEmail = email.length ? email : null;
    const payload = {
      imageSHAs: [sha],
      assigneeEmail: assigneeEmail,
    };
    return await sendRequest(
      'PATCH',
      `${baseUrl}/images/vulnerabilities/assignee/bulk?dryRun=${dryRun}`,
      { data: payload },
      null
    );
  } catch (e) {
    console.error('Error updating assignee', e);
    logger.logError('error_updating_assignee', e);
    throw new Error();
  }
};

export const handleResolutionSelection = async (
  resolution: string,
  sha: string,
  baseUrl: string,
  dryRun = false
) => {
  try {
    const payload = {
      imageSHAs: [sha],
      resolution,
    };
    return await sendRequest(
      'PATCH',
      `${baseUrl}/images/vulnerabilities/resolution/bulk?dryRun=${dryRun}`,
      { data: payload },
      null
    );
  } catch (e) {
    console.error('Error updating resolution', e);
    logger.logError('error_updating_resolution', e);
    throw new Error();
  }
};
