import React from 'react';
import { Card } from 'react-bootstrap';
import { LoadingSpinner } from '@fairwindsops/ui-components';
import { strings } from '~utils/strings';

import './LineCardChart.react.scss';
import LineChart, { LineChartProps } from '~reactComponents/charts/LineChart/LineChart.react';

export type Data = {
  id: string;
  data: Point[];
};

export type Point = {
  x: Date;
  y: number | null;
};

export type LineCardChartProps = {
  classNamePrefix?: string;
  cardTitle: string;
  graphLabel?: string;
  isLoading: boolean;
  lineChartProps: LineChartProps;
  footerComponent?: React.ComponentType<any>;
  headerComponent?: React.ComponentType<any>;
};

const LineCardChart = ({
  classNamePrefix = 'line-card-chart',
  cardTitle,
  graphLabel,
  isLoading,
  lineChartProps,
  footerComponent: FooterComponent,
  headerComponent: HeaderComponent,
}: LineCardChartProps) => {
  const hasData = lineChartProps.data.some((d) => d.data.length > 0);
  return (
    <Card className={classNamePrefix}>
      <Card.Title className={`${classNamePrefix}__title`}>{cardTitle}</Card.Title>
      {HeaderComponent && <Card.Header className={`${classNamePrefix}__header`}>{<HeaderComponent />}</Card.Header>}
      <Card.Body>
        <div aria-label={graphLabel} role="img" className={`${classNamePrefix}__chart`}>
          {isLoading && <LoadingSpinner containerClassNames={`${classNamePrefix}__spinner`} />}
          {!isLoading && hasData && <LineChart {...lineChartProps} />}
          {!isLoading && !hasData && (
            <div className={`${classNamePrefix}__no-data`}>{strings.general.noDataToDisplay}</div>
          )}
        </div>
        {FooterComponent && <FooterComponent />}
      </Card.Body>
    </Card>
  );
};

export default LineCardChart;
