import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Card, Dropdown, DropdownButton } from 'react-bootstrap';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Select, { StylesConfig } from 'react-select';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';
import AdmissionChart from './components/AdmissionChart/AdmissionChart.react';
import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import DescriptionPanel from './components/DescriptionPanel/DescriptionPanel.react';
import PaginationComponent from '~reactComponents/pagination/pagination.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ExpandIcon from '~reactComponents/Icons/Expand.icon.react';

import {
  AdmissionRequestType,
  FilterOption,
  FilterOptions,
  OptionType,
  RequestCountObjectType,
  RequestCountType,
} from './ReactAdmission.types.react';
import { IStore, IRouter, IRoute } from '~globalTypes';

import { DropdownOptions, handlePageChange } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import { PAGE_SIZE_OPTIONS } from '~utils/constants';
import { strings } from '~utils/strings';
import logger from '~logger';
import { getCurrentTimezone, setURL } from '~reactHelpers';
import { COLORS } from '~utils/styling';

import './ReactAdmission.react.scss';
import { Toaster } from 'react-hot-toast';
import { UPDATE_CLUSTER } from '~store/action.types';

dayjs.extend(utc);
dayjs.extend(timezone);

const todayTz = getCurrentTimezone();
const today = todayTz ? dayjs().tz(todayTz) : dayjs();
const dayAgo = today.clone().subtract(1, 'day');
const options: OptionType[] = [
  { value: 'number', label: strings.admissionController.numberChart },
  { value: 'percentage', label: strings.admissionController.percentChart },
];

const formatDatetime = (m: Dayjs): string => {
  return m.toISOString();
};

const filterOptions: FilterOptions = {
  any: { label: 'Any', success: null, passiveMode: null, passiveModeSuccess: null },
  pass: { label: 'Pass', success: true, passiveMode: null, passiveModeSuccess: null },
  fail: { label: 'Fail', success: false, passiveMode: null, passiveModeSuccess: null },
  passiveFail: { label: 'Passive Fail', success: true, passiveMode: true, passiveModeSuccess: false },
};

const fromLabelToFilterOption = (label: string): FilterOption => {
  return Object.values(filterOptions).find((option) => option.label === label) || filterOptions.any;
};

const fromQueryToFilterOption = (urlSearchParams: URLSearchParams): FilterOption => {
  const success = urlSearchParams.get('success');
  const passiveMode = urlSearchParams.get('passiveMode');
  const passiveModeSuccess = urlSearchParams.get('passiveModeSuccess');

  if (success === 'false') {
    return filterOptions.fail;
  }

  if (success === 'true' && passiveMode === null && passiveModeSuccess === null) {
    return filterOptions.pass;
  }

  if (success === 'true' && passiveMode === 'true' && passiveModeSuccess === 'false') {
    return filterOptions.passiveFail;
  }

  return filterOptions.any;
};

type ReportProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const defaultRequestCount: RequestCountType = { requestCount: [], interval: 'hour' };

const ReactAdmission = ({ route, router, store }: ReportProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const startTime = route?.query?.startTime || formatDatetime(dayAgo);
  const endTime = route?.query?.endTime || formatDatetime(today);
  const searchQuery = route?.query?.search || '';
  const currentURL = new URLSearchParams(window.location.search);

  const [filterOption, setFilterOption] = useState<FilterOption>(() => fromQueryToFilterOption(currentURL));
  const [searchValue, setSearchValue] = useState<string | undefined>(searchQuery);
  const [resultsToDisplay, setResultsToDisplay] = useState<AdmissionRequestType[]>([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<{
    start: string;
    end: string;
  }>({ start: startTime, end: endTime });
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [selectedRequest, setSelectedRequest] = useState<AdmissionRequestType | null>(null);
  const [selectedChart, setSelectedChart] = useState<'number' | 'percentage'>('number');
  const [requestCount, setRequestCount] = useState<RequestCountType>(defaultRequestCount);
  const [admissionRequestExists, setAdmissionRequestExists] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(false);
  const isFirstTimeLoaded = useRef<boolean>(true);

  const timeZone = getCurrentTimezone();

  useEffect(() => {
    setFilterOption(fromQueryToFilterOption(new URLSearchParams(window.location.search)));
  }, [window.location.search]);

  useEffect(() => {
    logger.logEvent('admission:page-load');
    if (!isMounted.current && isFirstTimeLoaded) {
      init();
      isMounted.current = true;
      isFirstTimeLoaded.current = false;
    }
    return () => {
      isMounted.current = false;
      isFirstTimeLoaded.current = true;
    };
  }, [route?.params?.cluster]);

  const searchedOrFiltered = useMemo(() => {
    return !!(
      searchValue ||
      filterOption !== filterOptions.any ||
      (selectedTimeRange.start !== startTime && selectedTimeRange.end !== endTime)
    );
  }, [filterOption, searchValue, selectedTimeRange.start, selectedTimeRange.end]);

  const checkAnyAdmissionRequestExists = async (cluster: string | null): Promise<boolean> => {
    let response = false;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/exists?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}${cluster && cluster !== '' ? '&cluster=' + cluster : ''}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsExistsErrorMsg, e);
    }
    return response;
  };

  const init = async (pageNumber = pageIndex, size = pageSize) => {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    let cluster = searchParams.get('cluster');
    if (!cluster) {
      cluster = route?.params?.cluster;
    }
    if (route.params?.operation === strings.selectCluster) {
      setLoading(true);
      setResultsToDisplay([]);
      route.params.operation = '';
      const searchParams: URLSearchParams = new URLSearchParams();
      searchParams.set('startTime', selectedTimeRange.start);
      searchParams.set('endTime', selectedTimeRange.end);
      filterOption.success != null && searchParams.set('success', String(filterOption.success));
      filterOption.passiveMode != null && searchParams.set('passiveMode', String(filterOption.passiveMode));
      filterOption.passiveModeSuccess != null &&
        searchParams.set('passiveModeSuccess', String(filterOption.passiveModeSuccess));
      searchParams.delete('page');
      searchParams.delete('pageSize');
      setPageIndex(0);
      setURL({
        route,
        nameToMatch: route.name || '',
        searchParams: searchParams?.toString(),
        router,
      });
      store().dispatch(UPDATE_CLUSTER, cluster);
    }
    const admissionRequestExists = await checkAnyAdmissionRequestExists(cluster);
    setAdmissionRequestExists(admissionRequestExists);

    if (!admissionRequestExists) {
      setLoading(false);
      setTotalSize(0);
      setResultsToDisplay([]);
      setRequestCount(defaultRequestCount);
      return;
    }

    const { data, headers } = await getAdmissionRequests(
      selectedTimeRange.start,
      selectedTimeRange.end,
      pageNumber,
      size,
      cluster,
      filterOption,
    );

    if (headers['total-size'] === '0' || !data.length) {
      setLoading(false);
      setResultsToDisplay([]);
      setTotalSize(0);
      setRequestCount(defaultRequestCount);
      return;
    }

    const { requestCount, interval } = await getAdmissionRequestsCount(
      cluster,
      selectedTimeRange.start,
      selectedTimeRange.end,
    );

    setResultsToDisplay(data);
    setRequestCount({ requestCount, interval });
    setTotalSize(headers['total-size']);
    await getAllActionItems(data[0]);
  };

  const getAdmissionRequestsCount = async (
    cluster: string | null,
    start = startTime,
    end = endTime,
  ): Promise<{ requestCount: RequestCountObjectType[]; interval: 'day' | 'hour' }> => {
    const difference = timeZone ? dayjs(end).tz(timeZone).diff(start, 'day') : dayjs(end).diff(start, 'day');
    const interval = difference > 2 ? 'day' : 'hour';
    let response;
    try {
      response = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/count?startTime=${start}&endTime=${end}&interval=${interval}${cluster && cluster !== '' ? '&cluster=' + cluster : ''}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError(strings.clusterOverview.admissionRequestsCountErrorMsg, e);
    }
    return { requestCount: response, interval };
  };

  const getAllActionItems = async (response: AdmissionRequestType) => {
    const retrievedActionItems = await getAdmissionRequestByID(response);
    setSelectedRequest(null);
    setSelectedRequest(retrievedActionItems || null);
    setLoading(false);
  };

  const getAdmissionRequests = async (
    startTime: string,
    endTime: string,
    pageNumber: number,
    size: number,
    cluster: string | null,
    filterOption: FilterOption,
    search?: string,
  ) => {
    const queryURL = `${baseURL}/admission-requests?`;
    const searchParams: URLSearchParams = new URLSearchParams();

    searchParams.set('startTime', startTime);
    searchParams.set('endTime', endTime);
    searchParams.set('page', String(pageNumber));
    searchParams.set('pageSize', String(size));
    if (cluster !== null && cluster !== undefined && cluster !== '') {
      searchParams.set('cluster', cluster);
    } else {
      searchParams.delete('cluster');
    }

    filterOption.success != null && searchParams.set('success', String(filterOption.success));
    filterOption.passiveMode != null && searchParams.set('passiveMode', String(filterOption.passiveMode));
    filterOption.passiveModeSuccess != null &&
      searchParams.set('passiveModeSuccess', String(filterOption.passiveModeSuccess));

    if (search) searchParams.set(`search`, search || '');

    setURL({
      route,
      nameToMatch: route.name || '',
      searchParams: searchParams.toString(),
      router,
    });
    try {
      return await sendRequest('GET', `${queryURL}${searchParams.toString()}`, { returnHeaders: true }, null);
    } catch (e) {
      logger.logError('error_fetching_admissions', e);
    }
  };

  const getAdmissionRequestByID = async (request: AdmissionRequestType) => {
    try {
      request.Data = await sendRequest(
        'GET',
        `${baseURL}/admission-requests/${request.ID}`,
        { showSuccessAlert: false, cache: false },
        null,
      );
      return request;
    } catch (e) {
      logger.logError('error_retrieving_admission_request_by_id', e);
    }
  };

  const allTimelineItems = React.useMemo(() => {
    const handleTimelineSelection = (request: AdmissionRequestType) => {
      getAllActionItems(request);
      logger.logEvent('admission:select-timeline-event');
    };

    const timelineItems = resultsToDisplay.map((request) => {
      let requestClassName;
      if (request.ID === selectedRequest?.ID) {
        requestClassName = request?.Status + ' active';
      } else {
        requestClassName = request?.Status;
      }
      let status = '';
      if (request?.Status === undefined) {
        // requests from old tables
        status = request.Success ? strings.general.Pass : strings.general.Fail;
      } else {
        // request from new tables
        if (request?.Status === 'pass') {
          status = strings.general.Pass;
        } else if (request?.Status === 'passive_fail') {
          status = strings.general.PassiveFail;
        } else if (request?.Status === 'block') {
          status = strings.general.Blocked;
        } else {
          status = strings.general.Fail;
        }
      }
      return (
        <li className={requestClassName} key={request.ID} onClick={() => handleTimelineSelection(request)}>
          <div className="admission-timeline-item">
            <div className="admission-timeline-content">
              <strong>{status}:</strong>
              {` ${request.Cluster} ${request.ResourceKind} ${request.ResourceName} ${strings.admissionController.inNamespace} ${request.ResourceNamespace}`}
              <ExpandIcon />
            </div>
            <div className="admission-timeline-date">
              {timeZone
                ? dayjs(request.CreatedAt).utc().tz(timeZone).format('MM/DD/YYYY')
                : dayjs(request.CreatedAt).utc().format('MM/DD/YYYY')}{' '}
              {strings.general.at}{' '}
              {timeZone
                ? dayjs(request.CreatedAt).utc().local().tz(timeZone).format('hh:mm A')
                : dayjs(request.CreatedAt).utc().local().format('hh:mm A')}
            </div>
            {request.ActionItemsCount > 0 && (
              <div className="admission-action-items">
                <hr className="admission-action-items-line" />
                <div className="admission-action-items-content">
                  <div className="admission-action-items-icon">
                    <div className="admission-action-items-icon__number">{request.ActionItemsCount}</div>
                  </div>
                  <div className="admission-action-items-link">
                    {strings.admissionController.seeActionItem.replace('itemsCount', String(request.ActionItemsCount))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </li>
      );
    });

    return (
      <ul className="admission-ul" id="admission-list" tabIndex={0} data-cy="admission-requests-list">
        {timelineItems}
      </ul>
    );
  }, [resultsToDisplay, selectedRequest]);

  const searchForItems = async (event: unknown) => {
    const typedEvent = event as Event | null;
    if (typedEvent) {
      typedEvent.preventDefault();
      setLoading(true);
      setPageIndex(0);
      const inputSearchValue = typedEvent?.target as HTMLInputElement;
      setSearchValue(inputSearchValue.value || undefined);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const getData = async () => {
        try {
          const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
          const cluster = searchParams.get('cluster');
          const { data, headers } = await getAdmissionRequests(
            selectedTimeRange.start,
            selectedTimeRange.end,
            pageIndex,
            pageSize,
            cluster,
            filterOption,
            searchValue,
          );
          store().dispatch(UPDATE_CLUSTER, cluster);
          setTotalSize(headers['total-size']);
          if (data.length) {
            await getAllActionItems(data[0]);
            setResultsToDisplay(data);
          } else {
            setResultsToDisplay([]);
            setLoading(false);
            setRequestCount(defaultRequestCount);
          }
        } catch (e) {
          logger.logError('error_searching_for_admission_requests', e);
        }
      };
      if (route.name === strings.AdmissionRequestsCluster) {
        return;
      }
      getData();
    }, 1000);

    return () => clearTimeout(handler);
  }, [searchValue]);

  const handleFilterOptionSelect = async (selection: string | null) => {
    if (!selection) return;

    const newFilterOption = fromLabelToFilterOption(selection);
    setLoading(true);
    try {
      const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
      const cluster = searchParams.get('cluster');
      const { data, headers } = await getAdmissionRequests(
        selectedTimeRange.start,
        selectedTimeRange.end,
        pageIndex,
        pageSize,
        cluster,
        newFilterOption,
        searchValue,
      );
      setTotalSize(headers['total-size']);
      if (!data.length) {
        setResultsToDisplay([]);
        setLoading(false);
        setTotalSize(0);
        setRequestCount(defaultRequestCount);
        return;
      }

      const { requestCount, interval } = await getAdmissionRequestsCount(
        cluster,
        selectedTimeRange.start,
        selectedTimeRange.end,
      );

      setResultsToDisplay(data);
      setRequestCount({ requestCount, interval });
      setTotalSize(headers['total-size']);
      await getAllActionItems(data[0]);
      setLoading(false);

      logger.logEvent('admission:filter-by-result-type', selection);
    } catch (e) {
      logger.logError('error_filtering_admission_requests', e);
    }
  };

  const formatStartAndEndTimes = (start: Date | Dayjs, end: Date | Dayjs) => {
    const newStart = formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start));
    const newEnd = formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end));
    return [newStart, newEnd];
  };

  const filterByDate = async (start?: Date | Dayjs, end?: Date | Dayjs) => {
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    setPageIndex(0);
    const cluster = searchParams.get('cluster');
    if (!start || !end) {
      return;
    }
    setLoading(true);
    setResultsToDisplay([]);
    const [newStart, newEnd] = formatStartAndEndTimes(start, end);
    setSelectedTimeRange({ start: newStart, end: newEnd });
    const admissionRequests = await getAdmissionRequests(newStart, newEnd, pageIndex, pageSize, cluster, filterOption);
    setTotalSize(admissionRequests.headers['total-size']);
    if (admissionRequests.data.length) {
      getAllActionItems(admissionRequests.data[0]);
      const { requestCount, interval } = await getAdmissionRequestsCount(cluster, newStart, newEnd);
      setRequestCount({ requestCount, interval });
      setResultsToDisplay(admissionRequests.data);
    } else {
      setRequestCount(defaultRequestCount);
      setResultsToDisplay([]);
      setLoading(false);
    }
    logger.logEvent('admission:filter-by-date');
  };

  const changePageSize = (sizeOption: number) => {
    setPageSize(sizeOption);
    init(pageIndex, sizeOption);
    logger.logEvent('admission:change-results-per-page', sizeOption);
  };

  const handlePaginationChange = (page: number): void => {
    setPageIndex(page);
    init(page);
    setResultsToDisplay([]);
    setLoading(true);
    const searchParams: URLSearchParams = new URLSearchParams(window.location.search);
    let cluster = searchParams.get('cluster');
    if (!cluster) {
      cluster = route?.params?.cluster;
    }
    store().dispatch(UPDATE_CLUSTER, cluster);
    logger.logEvent('admission:change-timeline-page', page);
  };

  const handleChartTypeChange = (value: unknown) => {
    const typedValue = value as OptionType;
    if (typedValue) setSelectedChart(typedValue.value as 'number' | 'percentage');
    logger.logEvent(`admission:change-chart`, typedValue.value);
  };

  const customSelectStyles: StylesConfig<unknown, true> = {
    control: (styles) => ({ ...styles, borderStyle: 'none' }),
    singleValue: (styles) => ({ ...styles, color: COLORS.CORE.PRIMARY }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (styles) => ({ ...styles, color: `${COLORS.CORE.PRIMARY} !important` }),
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'last',
      label: strings.navigation.admissionController,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="admission-parent-div">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="admission-top-div">
        <Card className="admission-chart-card">
          <Card.Header className="admission-chart-header">
            <Select
              className="admission-chart-dropdown"
              defaultValue={options[0]}
              options={options}
              onChange={handleChartTypeChange}
              name="chart-dropdown"
              styles={customSelectStyles}
              aria-label="admission chart dropdown"
            />
          </Card.Header>
          <Card.Body className={loading ? 'admission-chart-card-spinner' : ''}>
            <AdmissionChart
              interval={requestCount.interval}
              data={requestCount.requestCount}
              chart={selectedChart}
              admissionRequestExists={admissionRequestExists}
              router={router}
              loading={loading}
            />
          </Card.Body>
        </Card>
      </div>
      <div className="admission-bottom-div">
        <SearchFilterBar searchFunction={searchForItems}>
          <Datepicker
            onDateSelect={filterByDate}
            startDate={selectedTimeRange.start}
            endDate={selectedTimeRange.end}
            isClearable={false}
          />
          <DropdownButton
            className="dropdown-btn"
            title={filterOption.label}
            onSelect={handleFilterOptionSelect}
            data-cy="result-dropdown"
          >
            <DropdownOptions options={Object.values(filterOptions).map((option) => option.label)} />
          </DropdownButton>
        </SearchFilterBar>
        <div className="admission-bottom-div-card-container">
          <Card className="admission-timeline">
            <Card.Body className="admission-timeline-body">
              <Card.Title className="admission-timeline-title">{strings.admissionController.Timeline}</Card.Title>
              {resultsToDisplay && resultsToDisplay.length > 0 && (
                <Card.Subtitle className="admission-timeline-subtitle">
                  {`${strings.general.showingNumber.replace(
                    '#',
                    `${Math.max(pageSize * pageIndex + 1)}-${pageSize * pageIndex + pageSize}`,
                  )} ${totalSize} ${strings.general.results}`}
                  <div className="admission-timeline-subtitle-page-size">
                    <span>{strings.resultsPerPage}</span>
                    <DropdownButton
                      title={pageSize}
                      size="sm"
                      className="admission-timeline-subtitle-page-size__dropdown__page-size"
                    >
                      {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
                        <Dropdown.Item
                          key={idx}
                          onClick={() => {
                            changePageSize(pageSizeOption);
                          }}
                        >
                          {pageSizeOption}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                </Card.Subtitle>
              )}
              {loading && <LoadingSpinner />}
              {!loading && !admissionRequestExists && (
                <div>
                  <span>{strings.admissionController.noAdmissionController}</span>
                </div>
              )}
              {admissionRequestExists && !loading && !searchedOrFiltered && !resultsToDisplay.length && (
                <div>
                  <span>{strings.admissionController.noData}</span>
                </div>
              )}
              {admissionRequestExists && !loading && searchedOrFiltered && !resultsToDisplay.length && (
                <div>
                  <span>{strings.admissionController.noResults}</span>
                </div>
              )}
              {!loading && resultsToDisplay && <>{allTimelineItems}</>}
              {!loading && totalSize > pageSize && (
                <div className="admission-timeline-pagination">
                  <PaginationComponent
                    currentPage={pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={Math.ceil(totalSize / pageSize)}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
          {resultsToDisplay.length > 0 && (
            <DescriptionPanel
              request={selectedRequest}
              router={router}
              route={route}
              store={store}
              caption="Action Items of Admission Requests"
              reloadAdmissionRequest={getAdmissionRequestByID}
            />
          )}
        </div>
        <Toaster />
      </div>
    </LayoutReact>
  );
};

export default ReactAdmission;
