import { OptionType } from '~globalTypes';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

export enum SearchCriteria {
  Fixed = 'Fixed',
  Resolution = 'Resolution',
  GroupBy = 'groupBy',
  FirstSeen = 'FirstSeen',
}

export enum SearchValues {
  False = 'false',
  None = 'none',
}

export enum TimeUnits {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
  Month = 'month',
  Week = 'week',
  Hours = 'hours',
  Minutes = 'minutes',
}

export enum SelectedReports {
  Issues = 'issues',
  Severity = 'severity',
  Cluster = 'cluster',
}

export interface OrgIssue {
  Title: string;
  Count: number;
}

export interface ActionItemQueriesMap {
  [option: string]: string;
}

export const reportOptions: OptionType[] = [
  {
    label: strings.reportOptions.topIssues,
    value: 'issues',
  },
  {
    label: strings.reportOptions.issuesSeverity,
    value: strings.vulnerabilities.severity_lowercase,
  },
  {
    label: strings.reportOptions.issuesCluster,
    value: 'cluster',
  },
];

export const filteringDateOptions: OptionType[] = [
  {
    label: strings.dateOptions.lastDay,
    value: 'daily',
  },
  {
    label: strings.dateOptions.lastWeek,
    value: 'weekly',
  },
  {
    label: strings.dateOptions.lastMonth,
    value: 'monthly',
  },
];

export const actionItemQueriesMap: ActionItemQueriesMap = {
  issues: 'Title',
  severity: 'Severity',
  cluster: 'Cluster',
};

export const chartColors: string[] = [
  COLORS.CHARTS.PURPLE[100],
  COLORS.CHARTS.PURPLE[200],
  COLORS.CHARTS.PURPLE[300],
  COLORS.CHARTS.PURPLE[400],
  COLORS.CHARTS.PURPLE[500],
];
