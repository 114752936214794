import React from 'react';

const HexagonOutlineIcon = ({ fill }: { fill: string }) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33975 5.57735L9 1.1547L16.6603 5.57735V14.4227L9 18.8453L1.33975 14.4227V5.57735Z"
        stroke={fill}
        stroke-width="2"
      />
    </svg>
  );
};

export default HexagonOutlineIcon;
