import React, { ChangeEventHandler } from 'react';
import { qosOptions } from './CostsSettings.config.react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import { UseFormRegister } from 'react-hook-form';
import { strings } from '~utils/strings';
import { Placement } from 'react-bootstrap/esm/Overlay';

import './QoSFormGroup.react.scss';

type QoSFormGroupProps = {
  classNamePrefix?: string;
  overlayPlacement?: Placement;
  register: UseFormRegister<any>;
  onChange?: ChangeEventHandler<HTMLInputElement>; // overrides the default register onChange
};

function QoSFormGroup({
  classNamePrefix = 'costs-settings',
  overlayPlacement = 'left',
  register,
  onChange,
}: QoSFormGroupProps) {
  const toDisplay = Object.entries(qosOptions).map(([key, label]) => (
    <div key={`${classNamePrefix}__${key}`}>
      {onChange ? (
        <Form.Check
          inline
          key={key}
          id={key}
          type="radio"
          label={label}
          value={key}
          {...register('QoS')}
          onChange={onChange}
        />
      ) : (
        <Form.Check inline key={key} id={key} type="radio" label={label} value={key} {...register('QoS')} />
      )}
      <OverlayTrigger
        placement={overlayPlacement}
        delay={{ show: 0, hide: 1000 }}
        overlay={
          <Popover id={`tooltip-${key}`}>
            <Popover.Content>
              {strings.efficiency[key]}{' '}
              {key == 'burstable_plus' && (
                <a
                  href="https://insights.docs.fairwinds.com/first-steps/cost-efficiency/#quality-of-service-qos-recommendations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See more in docs
                </a>
              )}
            </Popover.Content>
          </Popover>
        }
      >
        <InfoIcon style={{ marginBottom: '0.75rem', marginRight: '1rem' }} />
      </OverlayTrigger>
    </div>
  ));

  return (
    <Form.Group className="radio-group">
      <div className={`${classNamePrefix}__qos-options`}>{toDisplay}</div>
    </Form.Group>
  );
}

export default QoSFormGroup;
