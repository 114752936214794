import React from 'react';
import HexagonIcon from '~reactIcons/Hexagon.icon.react';
import HexagonOutlineIcon from '~reactIcons/HexagonOutline.icon.react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import './Legend.react.scss';

const Legend = (): JSX.Element => {
  return (
    <div className="node-hexbin-container__legend">
      <div className="node-hexbin-legend-sections">
        <div className="node-hexbin-legend-section">
          <h3 className="section-header">Fills</h3>
          <div className="section-items">
            <span className="legend-item-span">
              <HexagonIcon fill={COLORS.CHARTS.HEXBIN.FILL_DANGER} />
              <h4 className="legend-item">{`${strings.general.Requests} > 85%`}</h4>
            </span>
            <span className="legend-item-span">
              <HexagonIcon fill={COLORS.CHARTS.HEXBIN.FILL_GOOD} />
              <h4 className="legend-item">{`${strings.general.Requests} 75% - 85%`}</h4>
            </span>
            <span className="legend-item-span">
              <HexagonIcon fill={COLORS.CHARTS.HEXBIN.FILL_FINE} />
              <h4 className="legend-item">{`${strings.general.Requests} < 75%`}</h4>
            </span>
          </div>
        </div>
        <div className="node-hexbin-legend-section node-hexbin-legend-borders">
          <h3 className="section-header">Borders</h3>
          <div className="section-items">
            <span className="legend-item-span">
              <HexagonOutlineIcon fill={COLORS.CHARTS.HEXBIN.BORDER_DANGER} />
              <h4 className="legend-item">{`${strings.general.Limits} > 120%`}</h4>
            </span>
            <span className="legend-item-span">
              <HexagonOutlineIcon fill={COLORS.CHARTS.HEXBIN.BORDER_GOOD} />
              <h4 className="legend-item">{`${strings.general.Limits} 100% - 120%`}</h4>
            </span>
            <span className="legend-item-span">
              <HexagonOutlineIcon fill={COLORS.CHARTS.HEXBIN.BORDER_FINE} />
              <h4 className="legend-item">{`${strings.general.Limits} < 100%`}</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
