import { mapGetters } from 'vuex';
import YAML from 'js-yaml';
import store from '@/store';
import { TOGGLE_TOAST } from '@/store/action.types';
import AutomationTemplatesList from './AutomationTemplatesList.react';

export default {
  name: 'automationTemplates',
  components: {
    'automation-templates-list': AutomationTemplatesList,
  },
  async beforeRouteLeave(to, _, next) {
    if (to.name !== 'edit-rule-template') {
      next();
      return;
    }
    let template;
    // eslint-disable-next-line
    const rule = to.params.rule;
    try {
      template = YAML.load(
        await this.sendRequest(
          'GET',
          `https://raw.githubusercontent.com/FairwindsOps/insights-plugins/main/plugins/automation/examples/${rule.Rule}`,
        ),
      );
    } catch (e) {
      const error = {
        message:
          'An error occurred while retrieving the automation rule template. Please try again.',
        position: 'b-toaster-top-center',
        type: 'error',
      };
      store.dispatch(TOGGLE_TOAST, error);
    }
    // Capitalizing template's properties
    const templateEntries = Object.entries(template).map((item) => [
      item[0][0].toUpperCase() + item[0].slice(1),
      item[1],
    ]);
    template = Object.fromEntries(templateEntries);
    to.params.rule = template;
    to.params.isTemplate = 'true';
    next();
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
  },
  computed: {
    ...mapGetters(['isOrgOwner', 'organization']),
  },
};
