import React, { Children } from 'react';
import Select, { components, ValueContainerProps } from 'react-select';
import { TableInstance, Row } from 'react-table';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { OptionType } from '~globalTypes';

import { SELECT_STYLES } from '../Table.config.react';
import { sortAscending } from '~utils/helpers';
import { COLORS } from '~utils/styling';

import './SelectFilter.react.scss';

type SelectColumnFilterType = {
  tableInstance: TableInstance;
  filters: Record<string, string[]>;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    const typedChild = child as Record<string, any>;
    return child && allowedTypes.includes(typedChild?.type?.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const SelectFilter = ({ tableInstance, filters }: SelectColumnFilterType) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = tableInstance;

  let options: OptionType[];

  if (filters?.[id] && Array.isArray(filters[id])) {
    options = (filters[id] || []).map((option: any) => {
      if (typeof option === 'object') {
        return option;
      }
      return {
        label: option,
        value: option,
      };
    });
  } else {
    options = React.useMemo(() => {
      const columnOptions = new Set();
      preFilteredRows?.forEach((row: Row) => {
        if (Array.isArray(row.values[id])) {
          row.values[id].forEach((item: string) => columnOptions.add(item));
        } else {
          columnOptions.add(row.values[id]?.toString());
        }
      });
      return Array.from(columnOptions)
        .map(
          (option: any): OptionType => ({
            label: option,
            value: option,
          }),
        )
        .sort((a, b) => sortAscending(a.value, b.value));
    }, [id, preFilteredRows]);
  }

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={filterValue}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          // eslint-disable-next-line
          // @ts-ignore
          ValueContainer,
        }}
        onChange={(selected) => setFilter(selected)}
      />
    </div>
  );
};

export default React.memo(SelectFilter);
