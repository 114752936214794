import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import Select from 'react-select';
import clsx from 'clsx';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import { ORG_DASHBOARD, PROFILE } from '~reactComponents/NavigationReact/Navigation.config.react';

import { CurrentUser, IRouter, IStore, OptionType } from '~globalTypes';
import { UPDATE_CURRENT_USER } from '~store/action.types';

import { strings } from '~utils/strings';
import { handlePageChange } from '~utils/global.helpers.react';
import { getCurrentUser, sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { JOB_TITLES } from '~utils/constants';
import { MediumBasicText } from '~utils/texts.react';

import './Profile.react.scss';

type ProfileProps = {
  store: () => IStore;
  router: () => IRouter;
};

type FormData = { FirstName: string; LastName: string; Email: string; JobTitle: string | null };
type SubmitFormData = { FirstName: string; LastName: string; CountryAlpha2?: string };

const validationSchema = Joi.object({
  FirstName: Joi.string().trim().required().prefs({ convert: true }),
  LastName: Joi.string().trim().required().prefs({ convert: true }),
  Email: Joi.string().trim().required().prefs({ convert: true }),
  JobTitle: Joi.optional().prefs({ convert: true }),
});

const Profile = ({ store, router }: ProfileProps) => {
  const organization = localStorage.getItem('organization') || '';
  const [jobTitles, setJobTitles] = useState<OptionType[]>([]);
  const [jobTitle, setJobTitle] = useState<OptionType | null>(null);
  const [user, setUser] = useState<CurrentUser>();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<FormData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      JobTitle: '',
    },
    mode: 'onChange',
  });

  const transformJobTitles = () => {
    if (!JOB_TITLES) {
      return;
    }

    const jobTitleValues = Object.values(JOB_TITLES);

    if (!jobTitleValues?.length) {
      return;
    }

    setJobTitles(
      jobTitleValues.map((option) => ({ value: option.value || '', label: option.text })),
    );
  };

  const getUser = async () => {
    try {
      const user = await getCurrentUser();
      store().dispatch(UPDATE_CURRENT_USER, user.user);
      setUser(user.user);
    } catch (e) {
      logger.logError('event_get_user_info', e);
    }
  };

  useEffect(() => {
    transformJobTitles();
    getUser();
  }, []);

  const setProfile = () => {
    if (!user) {
      return;
    }

    reset({
      FirstName: user.FirstName || '',
      LastName: user.LastName || '',
      Email: user.Email || '',
      JobTitle: user.JobTitle || '',
    });
  };

  useEffect(() => {
    if (user) {
      setProfile();
    }
  }, [user]);

  useEffect(() => {
    if (user && jobTitles?.length) {
      setJobTitle(jobTitles.find((title) => title.value === user.JobTitle) || null);
    }
  }, [user, jobTitles]);

  const updateProfile = async (formData: FormData) => {
    if (!formData) {
      return;
    }

    try {
      const data = {
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        JobTitle: formData.JobTitle,
        CountryAlpha2: '',
      } as SubmitFormData;

      if (user?.Country?.Alpha2) {
        data.CountryAlpha2 = user?.Country?.Alpha2;
      } else {
        delete data.CountryAlpha2;
      }

      const response = await sendRequest('PUT', '/v0/user', { data }, null);

      if (response.Success) {
        getUser();
      }
    } catch (e) {
      logger.logError('event_change_user_info', e);
    }
  };

  const submit = (data: FormData) => {
    if (!data) {
      return;
    }

    updateProfile(data);
  };

  const breadcrumbsRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: organization,
      href: `/orgs/${organization}/dashboard`,
    },
    {
      id: PROFILE,
      label: strings.navigation.userSettings,
      href: `/user-settings/details`,
    },
    {
      id: 'last',
      label: 'User Information',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="user-details">
        <Breadcrumbs
          data={breadcrumbsRoutesList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <div className="title">
          <h1 className="sr-only">{strings.userProfile.userDetails}</h1>
          <p className="sr-only">{strings.userProfile.updateYourProfileInformation}</p>
          <Form
            className="mt-4 details-form"
            onSubmit={handleSubmit((data: FormData) => submit(data))}
          >
            <div className="input-row">
              <FormGroup
                id="first-name-group"
                controlId="FirstName"
                className={clsx(
                  'profile-input',
                  MediumBasicText({ bottomMargin: strings.textStyling.lgBottom }),
                )}
              >
                <Form.Label>{strings.userProfile.firstName}</Form.Label>
                <Form.Control
                  {...register('FirstName')}
                  className={`${errors.FirstName ? 'is-invalid' : ''}`}
                  placeholder={strings.userProfile.firstName}
                  type="text"
                  data-cy="first-name-text-input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.FirstName?.message ? strings.userProfile.firstNameRequired : ''}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup
                id="last-name-group"
                controlId="LastName"
                className={clsx(
                  'profile-input',
                  MediumBasicText({ bottomMargin: strings.textStyling.lgBottom }),
                )}
              >
                <Form.Label>{strings.userProfile.lastName}</Form.Label>
                <Form.Control
                  {...register('LastName')}
                  className={`${errors.LastName ? 'is-invalid' : ''}`}
                  placeholder={strings.userProfile.lastName}
                  type="text"
                  data-cy="last-name-text-input"
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.FirstName?.message ? strings.userProfile.lastNameRequired : ''}
                </Form.Control.Feedback>
              </FormGroup>
            </div>
            <div className="input-row">
              <FormGroup
                id="email-group"
                controlId="email"
                className={clsx(
                  'profile-input',
                  MediumBasicText({ bottomMargin: strings.textStyling.lgBottom }),
                )}
              >
                <Form.Label>{strings.userProfile.email}</Form.Label>
                <Form.Control
                  {...register('Email')}
                  className={`${errors.Email ? 'is-invalid' : ''}`}
                  placeholder={strings.userProfile.email}
                  type="text"
                  disabled
                />
              </FormGroup>
              <FormGroup
                id="job-title-group"
                controlId="jobTitle"
                className={clsx(
                  'profile-input',
                  MediumBasicText({ bottomMargin: strings.textStyling.lgBottom }),
                )}
              >
                <Form.Label>{strings.userProfile.jobTitle}</Form.Label>
                <Controller
                  name="JobTitle"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        onChange={(option) => {
                          if (option) {
                            setValue('JobTitle', option.value);
                            setJobTitle(option);
                          }
                        }}
                        options={jobTitles}
                        value={jobTitle}
                        className="user-profile-job-title"
                        classNamePrefix="user-profile-job-title"
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>
            <Button
              className="profile-update-btn"
              type="submit"
              variant="primary"
              data-cy="submit-info-button"
              disabled={!isValid}
            >
              {strings.userProfile.updateProfile}
            </Button>
          </Form>
        </div>
      </div>
    </LayoutReact>
  );
};

export default Profile;
