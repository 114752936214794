import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import YAML from 'js-yaml';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import { useContextMenu, TriggerEvent } from 'react-contexify';
import ContextMenu from '../allPolicies/ContextMenu/ContextMenu.react';
import { sendRequest } from '~utils/request';
import PolicyEditor from '../PolicyEditor.react';
import { IInstances, IPolicies, IPolicyFormData, ITemplate } from '../policy.types.react';
import { IStore, IRoute, IRouter } from '~globalTypes';
import { displayToast, handlePageChange } from '~reactHelpers';
import { CONTEXT_MENU_ID } from '../allPolicies/Table/Table.config.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import logger from '~logger';
import { strings } from '~utils/strings';

import './EditPolicy.react.scss';
import { ORG_DASHBOARD, ALL_POLICIES } from '~reactComponents/NavigationReact/Navigation.config.react';

type EditPolicyProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const EditPolicy = ({ route, router, store }: EditPolicyProps): JSX.Element => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [policies, setPolicies] = useState<IPolicies[] | ITemplate[]>([]);
  const [instances, setInstances] = useState<IInstances[]>([]);
  const [policy, setPolicy] = useState<IPolicies | ITemplate | Record<string, unknown>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const isTemplate = route?.meta?.isTemplate;
  const baseUrl = `/v0/organizations/${store().getters.organization.Name}/opa/customChecks`;

  const { show: showContextMenu } = useContextMenu({
    id: CONTEXT_MENU_ID,
  });

  useEffect(() => {
    onLoad();
  }, [route.name]);

  const onLoad = async () => {
    const policyName = route?.query?.name;
    let policyItem: IPolicies | ITemplate;
    let _instances: IInstances[] = [];

    let _policies: IPolicies[];

    if (route?.params?.policies) {
      _policies = route.params.policies;
    } else {
      try {
        _policies = await sendRequest('GET', `${baseUrl}`, {}, null);
      } catch (e) {
        console.error('error fetching policy: ', e);
        logger.logError('error_fetching_policy', e);
        displayToast("Couldn't retrieve your policy, please try again", true, store);
        setLoading(false);
        return;
      }
    }

    if (isTemplate) {
      let files: { [key: string]: any }[];

      // Get all github files in template folder
      try {
        files = await sendRequest(
          'GET',
          `https://api.github.com/repos/FairwindsOps/insights-plugins/contents/plugins/opa/examples-v2/${policyName}`,
          {},
          null,
        );
      } catch (e) {
        console.error('error fetching sample policies: ', e);
        logger.logError('error_fetching_sample_policies', e);
        displayToast("Couldn't retrieve policies, please try again", true, store);
        setLoading(false);
        return;
      }

      if (files) {
        const YAMLFilesReqs: Promise<string>[] = [];
        const regoFilesReqs: Promise<string>[] = [];

        files.forEach((file) => {
          if (file.name.includes('.yaml')) {
            YAMLFilesReqs.push(sendRequest('GET', file.download_url, {}, null));
          } else if (file.name.includes('.rego')) {
            regoFilesReqs.push(sendRequest('GET', file.download_url, {}, null));
          }
        });

        // Get all YAML and Rego files in template folder
        let YAMLRes, RegoRes;
        try {
          [YAMLRes, RegoRes] = await Promise.all([Promise.all(YAMLFilesReqs), Promise.all(regoFilesReqs)]);
        } catch (e) {
          console.error('error fetching sample policies from github: ', e);
          logger.logError('error_fetching_sample_policies_from_github', e);
          displayToast("Couldn't retrieve policies, please try again", true, store);
          setLoading(false);
          return;
        }

        if (!isTemplate && YAMLRes) {
          _instances = YAMLRes?.map((yaml, idx, arr) => ({
            code: yaml,
            Name: `workloads${arr.length > 1 ? `-${idx}.yaml` : ''}`,
          }));
        }

        policyItem = {
          Name: '',
          Description: '',
          Rego: RegoRes[0],
        } as ITemplate;
        setPolicy(policyItem);
      } else {
        displayToast('Something went wrong...', true, store);
        logger.logError('error_fetching_github_templates');
        setLoading(false);
      }
    } else {
      policyItem = _policies.find((policy) => policy.Name === policyName) as IPolicies;
      if (!policyItem) {
        displayToast('This policy does not exist', true, store);
        setTimeout(() => {
          router().push({ name: 'policy' });
        }, 2000);
        setLoading(false);
        return;
      }
      setPolicy(policyItem);

      let instancesRes;

      if (policyItem.Version === 1) {
        try {
          instancesRes = await sendRequest(
            'GET',
            `${baseUrl}/${policyName}/instances?fillObject=true`,
            { headers: { Accept: 'text/yaml' } },
            null,
          );
        } catch (e) {
          logger.logError('error_fetching_policy_instances', e);
          displayToast("Couldn't retrieve policy instances. Please try again.", true, store);
          setLoading(false);
          return;
        }
      }

      if (instancesRes && instancesRes.length) {
        const parsedInstances = YAML.load(instancesRes);
        if (Array.isArray(parsedInstances)) {
          _instances = parsedInstances.map((instance: { [key: string]: unknown }) => {
            const { name, ...yaml } = instance;
            return {
              code: YAML.dump(yaml),
              Name: name as string,
            };
          });
        }
      } else {
        setLoading(false);
        return;
      }
    }
    setInstances(_instances);
    setPolicies(_policies);
    setLoading(false);
  };

  const openContextMenu = (e: TriggerEvent) => {
    return showContextMenu({ event: e, props: { policy } });
  };

  const ConfirmDeleteModal = (): JSX.Element => {
    const closeModal = () => setShowDeleteModal(false);
    return (
      <Modal show={showDeleteModal} onHide={closeModal} aria-labelledby="modal-title" centered>
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">
            Confirm deletion of policy <strong>{(policy as IPolicies).Name}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleOnDelete}>
            Delete Policy
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const deletePolicy = async () => {
    try {
      await sendRequest(
        'DELETE',
        `${baseUrl}/${policy.Name}?includeInstances=true`,
        { showSuccessAlert: false, showErrorAlert: false },
        null,
      );
    } catch (e) {
      displayToast(`Policy ${policy.Name} was not deleted. Please try again`, true, store);
      logger.logError('error_deleting_policy', e);
    }
  };

  const handleOnSubmit = async (data: IPolicyFormData, removableInstances?: string[]): Promise<unknown> => {
    try {
      if (data.Name !== policy?.Name && policies.some((policy) => policy?.Name === data.Name)) {
        displayToast(`Policy ${data.Name} already exists!`, true, store);
        return;
      }

      // Upsert the rego policy
      const _policy = {
        Rego: data.Rego,
        Description: data.Description,
      };
      const createPolicy = await sendRequest(
        'PUT',
        `${baseUrl}/${data.Name}${isTemplate || policy.Version === 2 ? '?version=2.0' : ''}`,
        {
          data: _policy,
          headers: {
            'content-type': 'application/json',
          },
        },
        null,
      );

      if (policy?.Disabled !== undefined) {
        await sendRequest(
          'POST',
          `${baseUrl}/${data.Name}/disable`,
          {
            data: { disabled: policy.Disabled },
            headers: { 'content-type': 'text/plain' },
          },
          null,
        );

        if (!isTemplate || policy.Version === 1) {
          // Figure out which instances were removed
          const instancesToRemove = instances.filter((instance) => {
            return !data.Yaml.some((newInstance) => newInstance.Name === instance.Name);
          });

          // Remove the instances that are not needed anymore
          await Promise.all(
            instancesToRemove.map((instance) =>
              sendRequest('DELETE', `${baseUrl}/${data.Name}/instances/${instance.Name}`, {}, null),
            ),
          );
        }
      }

      // Upsert the instances
      if (policy.Version === 1) {
        const _instances = data.Yaml.map((instance) =>
          sendRequest(
            'PUT',
            `${baseUrl}/${data.Name}/instances/${instance.Name}`,
            {
              data: instance.code,
              headers: {
                'content-type': 'application/x-yaml',
              },
            },
            null,
          ),
        );

        // Delete any instances that the user might have removed
        const removables = (removableInstances || []).map((removable) => {
          sendRequest('DELETE', `${baseUrl}/${data.Name}/instances/${removable}`, {}, null);
        });

        await Promise.all([..._instances, ...removables]);
      }

      if (data.Name !== policy?.Name && !isTemplate) {
        await deletePolicy();
        displayToast(`Policy ${policy?.Name} successfully renamed to ${data.Name}!`, false, store);
      } else if (data.Name === policy?.Name) {
        displayToast(`Policy ${data.Name} updated successfully!`, false, store);
      } else {
        createPolicy.Success
          ? displayToast(`Policy ${data.Name} created successfully!`, false, store)
          : displayToast(`Policy ${data.Name} not created!`, true, store);
      }
      logger.logEvent('policy-wizard-edit: OPA policy edited');
      router().push({
        name: 'policy-wizard-edit',
        query: {
          name: data?.Name,
        },
      });
    } catch (e) {
      console.error('here error', e);
      logger.logError('error_editing_policy', e);
      displayToast(e.message ? e.message : `Error editing policy ${policy?.Name}`, true, store);
    }
  };

  const handleOnDelete = async () => {
    try {
      await sendRequest('DELETE', `${baseUrl}/${policy.Name}?includeInstances=true`, {}, null);
      displayToast(`Policy ${policy.Name} removed`, false, store);
      router().push({ name: ALL_POLICIES });
    } catch (e) {
      displayToast(`Error removing policy ${policy.Name}`, true, store);
      logger.logError('error_fetching_policies', e);
    }
  };

  const handleTogglePolicy = async () => {
    try {
      await sendRequest(
        'POST',
        `${baseUrl}/${policy.Name}/disable`,
        {
          data: { disabled: !policy.Disabled },
          headers: { 'content-type': 'text/plain' },
        },
        null,
      );

      setPolicy({
        ...policy,
        Disabled: !policy.Disabled,
      });
      displayToast(`Policy ${policy.Name} has been ${!policy.Disabled ? 'disabled' : 'enabled'}`, false, store);
    } catch (e) {
      displayToast(`Error toggling policy ${policy.Name}`, true, store);
      logger.logError('error_toggling_policy', e);
    }
  };

  const { org } = route.params || { org: '' };
  const breadcrumbRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ALL_POLICIES,
      label: strings.navigation.Policy,
      href: `/orgs/${org}/policy`,
    },
    {
      id: route.name === 'policy-wizard-edit' ? 'policy-wizard-edit' : 'policy-templates',
      label: route.name === 'policy-wizard-edit' ? 'OPA Policy Wizard' : 'OPA Policy Templates',
      href: `/orgs/${org}/policy`,
    },
    {
      id: 'last',
      label: 'OPA Policy Wizard',
      href: `/orgs/${org}/policy`,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isTemplate && !policy.IsLibrary && (
        <div className="d-flex justify-content-between">
          <Form className="policy--toggle">
            <Form.Label className="policy--toggle__label">Enable</Form.Label>
            <Form.Check
              type="switch"
              id="policy--toggle__switch"
              onChange={handleTogglePolicy}
              checked={!policy.Disabled}
              data-cy="enable-toggle-button"
            />
          </Form>
          <button
            className="additional-actions-btn"
            onClick={(e) => openContextMenu(e)}
            data-cy="additional-actions-button"
          >
            <img src={HorizontalEllipsis} alt="ellipsis icon" />
          </button>
        </div>
      )}
      {!loading && policy ? (
        <PolicyEditor
          route={route}
          router={router}
          store={store}
          handleOnSubmit={handleOnSubmit}
          edit={!isTemplate}
          isTemplate={isTemplate}
          policy={policy}
          instances={instances}
        />
      ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" className="announcement-spinner">
            <span className="sr-only">Loading</span>
          </Spinner>
        </div>
      )}
      <ConfirmDeleteModal />
      <ContextMenu router={router} setModal={setShowDeleteModal} editorPage={true} />
    </LayoutReact>
  );
};

export default EditPolicy;
