import React from 'react';
import { StylesConfig } from 'react-select';
import { Column, TableInstance } from 'react-table';

import Tag from '~reactComponents/Tags/Tag.react';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import {
  VulnerabilitiesActionItems,
  Labels,
  AffectedImagesInfo,
} from './VulnerabilitiesItemsTable.types.react';

import { strings } from '~utils/strings';
import { IRouter } from '~utils/global.types.react';
import { RESIZED_WIDTH_STORAGE_KEYS } from '~reactHelpers';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export const SELECT_STYLES: StylesConfig<unknown, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: (tableInstance: TableInstance) => JSX.Element,
): Column<VulnerabilitiesActionItems>[] => {
  const resizedWith = JSON.parse(
    localStorage.getItem(RESIZED_WIDTH_STORAGE_KEYS.vulnerabilitiesAllVulnerabilitiesPage) || '{}',
  );

  return [
    {
      Header: 'CVE ID',
      accessor: 'cveCode',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.cveCode}>{instance.row.original.cveCode}</span>
      ),
      width: resizedWith['cveCode'] || 350,
      minWidth: 150,
      maxWidth: 500,
    },
    {
      Header: 'Title',
      accessor: 'cveTitle',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.cveTitle}>{instance.row.original.cveTitle}</span>
      ),
      width: resizedWith['cveTitle'] || 250,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: 'Severity',
      accessor: 'cveSeverity',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => {
        if (!value) return <></>;
        const formattedValue = `${value.charAt(0)}${value.slice(1).toLowerCase()}`;
        return <PillBadge text={formattedValue} />;
      },
      width: resizedWith['cveSeverity'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Images',
      accessor: 'imagesCount',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.imagesCount}>{instance.row.original.imagesCount}</span>
      ),
      width: resizedWith['imagesCount'] || 150,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      Header: 'Package',
      accessor: 'packageName',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.packageName}>{instance.row.original.packageName}</span>
      ),
      width: resizedWith['packageName'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Fixed Version',
      accessor: 'hasFixedVersion',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => {
        const fixedVersion = instance.row.original.fixedVersion
          ? instance.row.original.fixedVersion
          : Labels.Unavailable;
        return <span title={fixedVersion}>{fixedVersion}</span>;
      },
      width: resizedWith['hasFixedVersion'] || 250,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: 'Installed',
      accessor: 'installedVersion',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.installedVersion}>
          {instance.row.original.installedVersion}
        </span>
      ),
      width: resizedWith['installedVersion'] || 150,
      minWidth: 130,
      maxWidth: 250,
    },
  ];
};

export const COLUMNS_ORDER = [
  'CVE Code',
  'CVE Title',
  'Severity',
  'Images',
  'Package',
  'Fixed',
  'Installed',
  'Recommended',
];

export const TOP_ROW = (selectedVulnActionItem: VulnerabilitiesActionItems) => [
  {
    title: 'ID',
    content: getCVEIdContent(selectedVulnActionItem?.cveCode),
  },
  { title: 'Package', content: selectedVulnActionItem.packageName },
  {
    title: 'Installed Version',
    content: selectedVulnActionItem.installedVersion,
  },
  { title: 'Fixed Version', content: selectedVulnActionItem.fixedVersion },
];

const getCVEIdContent = (cveCode: string) => {
  if (!cveCode) return null;
  if (cveCode.startsWith('CVE-') || cveCode.startsWith('DLA-')) {
    const cveLink = cveCode.startsWith('CVE-')
      ? `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cveCode}`
      : `https://security-tracker.debian.org/tracker/${cveCode}`;
    return (
      <a href={cveLink} target="_blank">
        {cveCode}
      </a>
    );
  }
  return cveCode;
};

export const CONTENT = (
  selectedVulnActionItem: VulnerabilitiesActionItems,
  affectedImages: string[] | undefined,
  affectedImagesInfo: AffectedImagesInfo[],
  organizationName: string,
  router: () => IRouter,
) => [
  { title: 'Title', content: selectedVulnActionItem.cveTitle },
  { title: 'Description', content: selectedVulnActionItem.cveDescription },
  {
    title: 'Affected Images',
    content: renderAffectedImages(affectedImages, affectedImagesInfo, organizationName, router),
    isJSX: true,
  },
];

const renderAffectedImages = (
  affectedImages: string[] | undefined,
  affectedImagesInfo: AffectedImagesInfo[],
  organizationName: string,
  router: () => IRouter,
) => {
  if (!affectedImages?.length) return <></>;

  const goToImageDetail = (affectedImageInfo: AffectedImagesInfo) => () => {
    router().push({
      name: `react-vulnerabilities-image-detail`,
      params: {
        org: organizationName,
        selectedImage: affectedImageInfo.SHA,
      },
      query: {
        resolved: false,
        tag: affectedImageInfo.Tag,
      },
    });
  };

  return (
    <div className="affected-images-container">
      {affectedImages.map((image: string) => {
        const info = affectedImagesInfo.find(
          (imageInfo: AffectedImagesInfo) => imageInfo.Name === image,
        );
        const nClusters = info?.ClustersCount || 0;
        return info ? (
          <div onClick={goToImageDetail(info)}>
            <Tag tagClassNames="tag-ellipsis vulnerabilities-tag">{`${image} (${nClusters} ${
              nClusters <= 1 ? strings.vulnerabilities.cluster : strings.vulnerabilities.clusters
            })`}</Tag>
          </div>
        ) : (
          <Tag tagClassNames="tag-ellipsis vulnerabilities-tag">{image}</Tag>
        );
      })}
    </div>
  );
};
