import React from 'react';

import { DataItem } from './ComplianceBadge.types.react';

import './ComplianceBadge.react.scss';

type ComplianceBadgeProps = {
  data: DataItem[] | null;
};

const ComplianceBadge = ({ data }: ComplianceBadgeProps) => {
  if (!data) {
    return <></>;
  }

  return (
    <>
      {data.map((dataItem: DataItem) => {
        return (
          <span className="compliance-badge__container">
            <span className="compliance-badge__text compliance-badge__value">{dataItem.value}</span>
            <span className="compliance-badge__text compliance-badge__label">{dataItem.label}</span>
          </span>
        );
      })}
    </>
  );
};

export default ComplianceBadge;
