import React, { useState, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Select, { OptionProps, StylesConfig, MultiValue } from 'react-select';

import { AddButton } from '@fairwindsops/ui-components';
import DisplayTags from '../DisplayTags/DisplayTags.react';
import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { ReportType } from '~views/organization/compliance/Compliance.types.react';
import { OptionType } from '~globalTypes';

import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';

import './Multiselect.react.scss';

type MultiselectProps = {
  title: string;
  allTags: ReportType;
  setAllTags: React.Dispatch<React.SetStateAction<ReportType>>;
  options: OptionType[];
  isAdmin: boolean;
  tagCollection: string;
};

const Multiselect = ({
  title,
  allTags,
  setAllTags,
  options,
  isAdmin,
  tagCollection,
}: MultiselectProps): JSX.Element => {
  const [showDropdown, setDropdown] = useState(false);

  const tagKey = useMemo(() => {
    return hasKey(allTags, tagCollection) ? tagCollection : '';
  }, [allTags, tagCollection]);

  const currentTags = useMemo(() => {
    if (tagKey && tagKey !== 'name' && allTags[tagKey].length) {
      return (
        <DisplayTags options={allTags[tagKey]} admin={isAdmin} removeTag={handleRemoveTag} isGray />
      );
    }
  }, [allTags, tagCollection]);

  const currentValue = useMemo(() => {
    if (tagKey && tagKey !== 'name') {
      return allTags[tagKey].map((tag: OptionType) => tag);
    }
  }, [allTags, tagCollection]);

  const customStyles: StylesConfig<OptionType, true> = {
    container: (provided) => ({
      ...provided,
      width: '90%',
      marginTop: '1rem',
    }),
  };

  const handleRemoveTag = (selection: OptionType) => {
    if (tagKey && tagKey !== 'name') {
      const filteredTags = allTags[tagKey].filter(
        (tag: OptionType) => tag.value !== selection.value,
      );
      setAllTags({ ...allTags, [tagKey]: filteredTags });
    }
  };

  const handleSettingTags = (option: MultiValue<OptionType>) => {
    if (tagKey) {
      setAllTags({ ...allTags, [tagKey]: option });
    }
  };

  return (
    <Form.Group>
      <Form.Label>{title}</Form.Label>
      {isAdmin && <AddButton buttonText={`${strings.general.Add} ${title}`} action={setDropdown} />}
      {currentTags}
      {showDropdown && isAdmin && (
        <Select
          defaultMenuIsOpen={true}
          options={options}
          components={{ Option }}
          closeMenuOnSelect={false}
          isMulti
          hideSelectedOptions={false}
          isClearable={false}
          styles={customStyles}
          onChange={(option: MultiValue<OptionType>) => handleSettingTags(option)}
          controlShouldRenderValue={false}
          value={currentValue}
        />
      )}
    </Form.Group>
  );
};

export default Multiselect;
