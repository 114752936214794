import React, { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';

import { Breadcrumbs } from '@fairwindsops/ui-components';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import TeamAccess from './components/team-access/TeamAccess.react';

import {
  AdvancedFilterOption,
  DESELECT_OPTION,
  IRoute,
  IRouter,
  IStore,
  Team,
  TeamAccessAllSelected,
} from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import { handlePageChange } from '~reactHelpers';

import './CreateTeam.react.scss';
import { TeamAccessElement } from './CreateTeam.react.types';

type CreateTeamProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

type SelectedOptions = Record<string, Record<string, string[]>>;

const CreateTeam = ({ router, store }: CreateTeamProps) => {
  const organization = store().getters.organization;
  const isOrgOwner = store().getters.isOrgOwner;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const [teamName, setTeamName] = useState<{ value: string; error: string }>({
    value: '',
    error: '',
  });

  const [clusters, setClusters] = useState<string[]>([]);
  const [repositories, setRepositories] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    clusters: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
    repositories: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
    namespaces: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
  });
  const [allowTeamAccessAllSelection, setAllowTeamAccessAllSelection] =
    useState<TeamAccessAllSelected>({
      isAllClusterSelected: false,
      isAllNamespaceSelected: false,
      isAllRepoSelected: false,
    });

  const getClusters = async () => {
    try {
      setClusters((await sendRequest('GET', `${baseUrl}/all-clusters`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_all_clusters_team_management', e);
    }
  };

  const getRepositories = async () => {
    try {
      setRepositories((await sendRequest('GET', `${baseUrl}/all-repositories`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_all_repositories_team_management', e);
    }
  };

  const getNamespaces = async () => {
    try {
      setNamespaces((await sendRequest('GET', `${baseUrl}/all-namespaces`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_all_namespaces_team_management', e);
    }
  };

  const getTeams = async () => {
    try {
      setTeams((await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_all_namespaces_team_management', e);
    }
  };

  useEffect(() => {
    getClusters();
    getRepositories();
    getNamespaces();
    getTeams();
  }, []);

  useEffect(() => {
    if (!isOrgOwner) {
      router().push({ name: TEAM_MANAGEMENT });
    }
  }, [isOrgOwner]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.teamManagement.settings,
      href: `/orgs/${organization.Name}/settings/team-management`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.teamManagement.teamManagement,
      href: `/orgs/${organization.Name}/settings/team-management`,
    },
    {
      id: 'last',
      label: strings.teamManagement.newTeam,
      href: '',
      isActive: true,
    },
  ];

  const allowedTeamAccess = useMemo(
    () =>
      [
        {
          id: 1,
          addButtonTitle: strings.teamManagement.addAdditionalClusters,
          title: strings.teamManagement.clusters,
          options: [
            { label: strings.teamManagement.allClusters, value: null },
            ...clusters.map((cluster) =>
              allowTeamAccessAllSelection.isAllClusterSelected
                ? { label: cluster, value: cluster, isDisabled: true }
                : { label: cluster, value: cluster },
            ),
          ],
          sectionKey: strings.teamManagement.clusters.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-clusters-section',
        },
        {
          id: 2,
          addButtonTitle: strings.teamManagement.addAdditionalRepositories,
          title: strings.teamManagement.repositories,
          options: [
            { label: strings.teamManagement.allRepositories, value: null },
            ...repositories.map((repository) =>
              allowTeamAccessAllSelection.isAllRepoSelected
                ? { label: repository, value: repository, isDisabled: true }
                : { label: repository, value: repository },
            ),
          ],
          sectionKey: strings.teamManagement.repositories.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-repositories-section',
        },
        {
          id: 3,
          addButtonTitle: strings.teamManagement.addAdditionalNamespaces,
          title: strings.teamManagement.namespaces,
          options: [
            { label: strings.teamManagement.allNamespaces, value: null },
            ...namespaces.map((namespace) =>
              allowTeamAccessAllSelection.isAllNamespaceSelected
                ? { label: namespace, value: namespace, isDisabled: true }
                : { label: namespace, value: namespace },
            ),
          ],
          sectionKey: strings.teamManagement.namespaces.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-namespaces-section',
        },
      ] as TeamAccessElement[],
    [clusters, repositories, namespaces, allowTeamAccessAllSelection],
  );

  const blockedTeamAccess = useMemo(
    () =>
      [
        {
          id: 1,
          addButtonTitle: strings.teamManagement.addAdditionalClusters,
          title: strings.teamManagement.blockedClusters,
          options: clusters.map((cluster) => ({ label: cluster, value: cluster })),
          sectionKey: strings.teamManagement.clusters.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-clusters-section',
        },
        {
          id: 2,
          addButtonTitle: strings.teamManagement.addAdditionalRepositories,
          title: strings.teamManagement.blockedRepositories,
          options: repositories.map((repository) => ({ label: repository, value: repository })),
          sectionKey: strings.teamManagement.repositories.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-repositories-section',
        },
        {
          id: 3,
          addButtonTitle: strings.teamManagement.addAdditionalNamespaces,
          title: strings.teamManagement.blockedNamespaces,
          options: namespaces.map((namespace) => ({ label: namespace, value: namespace })),
          sectionKey: strings.teamManagement.namespaces.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-namespaces-section',
        },
      ] as TeamAccessElement[],
    [clusters, repositories, namespaces],
  );

  const validateTeamName = (inputTeamName: string) => {
    if (!inputTeamName) {
      return strings.teamManagement.teamNameRequired;
    }

    if (teams.find((team) => team.Name.toLowerCase() === inputTeamName.toLowerCase())) {
      return strings.teamManagement.teamAlreadyExists;
    }

    return '';
  };

  const onTeamNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setTeamName({ value: e.target.value, error: validateTeamName(e.target.value) });
  };

  const updateAllowTeamAccess = (value: string, action: string, sectionKey: string) => {
    if (action === DESELECT_OPTION) {
      selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[
        sectionKey
      ].allowedTeamAccess.filter((element) => element !== value);
    } else {
      if (value === null) {
        selectedOptions[sectionKey].allowedTeamAccess = [value];
      } else {
        selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[sectionKey]
          .allowedTeamAccess?.length
          ? [...selectedOptions[sectionKey].allowedTeamAccess, value]
          : [value];
      }
    }

    setSelectedOptions({ ...selectedOptions });
  };

  const updateBlockedTeamAccess = (value: string, action: string, sectionKey: string) => {
    if (action === DESELECT_OPTION) {
      selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[
        sectionKey
      ].blockedTeamAccess.filter((element) => element !== value);
    } else {
      selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[sectionKey].blockedTeamAccess
        ?.length
        ? [...selectedOptions[sectionKey].blockedTeamAccess, value]
        : [value];
    }

    setSelectedOptions({ ...selectedOptions });
  };

  const onOptionChanged = (
    option: AdvancedFilterOption,
    action: string,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => {
    const { value } = option;

    if (isAllowingTeamAccess) {
      updateAllowTeamAccess(value, action, sectionKey);
    } else {
      updateBlockedTeamAccess(value, action, sectionKey);
    }
  };

  const removeAllowTeamAccess = (value: string, sectionKey: string) => {
    selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[
      sectionKey
    ].allowedTeamAccess.filter((element) => element !== value);

    setSelectedOptions({ ...selectedOptions });
  };

  const removeBlockedTeamAccess = (value: string, sectionKey: string) => {
    selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[
      sectionKey
    ].blockedTeamAccess.filter((element) => element !== value);

    setSelectedOptions({ ...selectedOptions });
  };

  const onOptionRemoved = (
    removedValue: AdvancedFilterOption,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => {
    const { value } = removedValue;

    if (!selectedOptions[sectionKey]) {
      return;
    }

    if (isAllowingTeamAccess) {
      removeAllowTeamAccess(value, sectionKey);
    } else {
      removeBlockedTeamAccess(value, sectionKey);
    }
  };

  const updateAllSelection = (label: string, isSelected = true) => {
    if (label === strings.teamManagement.allClusters) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllClusterSelected: isSelected }));
    } else if (label === strings.teamManagement.allRepositories) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllRepoSelected: isSelected }));
    } else if (label === strings.teamManagement.allNamespaces) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllNamespaceSelected: isSelected }));
    }
  };

  const onSelectChanged = (
    option: AdvancedFilterOption,
    removedValue: AdvancedFilterOption,
    action: string,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => {
    if (option) {
      updateAllSelection(option.label, action === 'DESELECT_OPTION' ? false : true);
      onOptionChanged(option, action, sectionKey, isAllowingTeamAccess);
    } else if (removedValue) {
      updateAllSelection(removedValue.label, false);
      onOptionRemoved(removedValue, sectionKey, isAllowingTeamAccess);
    }
  };

  const buildCreateTeamPayload = () => ({
    Clusters:
      selectedOptions.clusters.allowedTeamAccess?.length &&
      selectedOptions.clusters.allowedTeamAccess[0] === null
        ? null
        : selectedOptions.clusters.allowedTeamAccess,
    Repositories:
      selectedOptions.repositories.allowedTeamAccess?.length &&
      selectedOptions.repositories.allowedTeamAccess[0] === null
        ? null
        : selectedOptions.repositories.allowedTeamAccess,
    Namespaces:
      selectedOptions.namespaces.allowedTeamAccess?.length &&
      selectedOptions.namespaces.allowedTeamAccess[0] === null
        ? null
        : selectedOptions.namespaces.allowedTeamAccess,
    DisallowedClusters: selectedOptions.clusters.blockedTeamAccess,
    DisallowedRepositories: selectedOptions.repositories.blockedTeamAccess,
    DisallowedNamespaces: selectedOptions.namespaces.blockedTeamAccess,
    Name: teamName.value,
    Organization: organization.Name,
  });

  const createTeam = async () => {
    try {
      await sendRequest('POST', `${baseUrl}/teams`, { data: buildCreateTeamPayload() }, null);
      await router().push({ name: TEAM_MANAGEMENT });
    } catch (e) {
      logger.logError('error_create_team_team_management', e);
    }
  };

  const getAllValue = (sectionKey: string) => {
    switch (sectionKey) {
      case 'clusters':
        return strings.teamManagement.allClusters;
      case 'repositories':
        return strings.teamManagement.allRepositories;
      case 'namespaces':
        return strings.teamManagement.allNamespaces;
      default:
        break;
    }
  };

  return (
    <div className="create-team">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <h3 className="create-team__title">{strings.teamManagement.createANewTeam}</h3>
      <p className="create-team__sub-title">{strings.teamManagement.subTitleAddNewTeam}</p>

      <FormGroup id="team-name-group" controlId="TeamName" className="profile-input">
        <Form.Label>{strings.teamManagement.teamName}</Form.Label>
        <Form.Control
          className={`${teamName.error ? 'is-invalid' : ''}`}
          placeholder={strings.teamManagement.name}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>) => onTeamNameChanged(e)}
          data-cy="team-name-text-input"
        />
        <Form.Control.Feedback type="invalid">{teamName.error || ''}</Form.Control.Feedback>
      </FormGroup>

      <div className="create-team__team-access">
        <h3 className="create-team__title">{strings.teamManagement.teamAccess}</h3>
        <div className="create-team__team-access-container">
          {allowedTeamAccess?.length
            ? allowedTeamAccess.map((teamAccess: TeamAccessElement) => (
                <TeamAccess
                  addButtonTitle={teamAccess.addButtonTitle}
                  isAllowingTeamAccess={teamAccess.isAllowingTeamAccess}
                  key={teamAccess.id}
                  onOptionChanged={onSelectChanged}
                  options={teamAccess.options}
                  sectionKey={teamAccess.sectionKey}
                  title={teamAccess.title}
                  dataCy={teamAccess.dataCy}
                  defaultValue={selectedOptions[teamAccess.sectionKey].allowedTeamAccess.map(
                    (value) => ({
                      label: value === null ? getAllValue(teamAccess.sectionKey) : value,
                      value,
                    }),
                  )}
                />
              ))
            : null}
        </div>
      </div>

      <div className="create-team__team-access">
        <h3 className="create-team__title">{strings.teamManagement.blockedAccess}</h3>
        <div className="create-team__team-access-container">
          {blockedTeamAccess?.length
            ? blockedTeamAccess.map((teamAccess: TeamAccessElement) => (
                <TeamAccess
                  addButtonTitle={teamAccess.addButtonTitle}
                  isAllowingTeamAccess={teamAccess.isAllowingTeamAccess}
                  key={teamAccess.id}
                  onOptionChanged={onSelectChanged}
                  options={teamAccess.options}
                  sectionKey={teamAccess.sectionKey}
                  title={teamAccess.title}
                  dataCy={teamAccess.dataCy}
                />
              ))
            : null}
        </div>
      </div>

      <div className="create-team__footer">
        <Button
          className="create-team__submit-btn"
          type="button"
          onClick={createTeam}
          disabled={!!teamName.error || !teamName.value}
          data-cy="submit-create-team-button"
        >
          {strings.teamManagement.createTeam}
        </Button>
      </div>
      <Toaster />
    </div>
  );
};

export default CreateTeam;
