import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import clsx from 'clsx';

import { RepoSeverity } from '~globalTypes';
import { Severities, Labels } from './RepoSeverities.types.react';

import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './RepoSeverities.react.scss';

type RepoSeveritiesProps = {
  id: number;
  isFromRepo?: boolean;
  repoSeverity: RepoSeverity;
  nonePluralsLabel?: string;
  pluralsLabel?: string;
};

const RepoSeverities = ({
  id,
  repoSeverity,
  isFromRepo,
  nonePluralsLabel = Labels.ActionItem,
  pluralsLabel = Labels.ActionItems,
}: RepoSeveritiesProps) => {
  if (!repoSeverity) return <></>;

  const renderTooltip = (value: number, label: string) => {
    const actionItemLabel = value > 0 ? pluralsLabel : nonePluralsLabel;
    return (
      <Popover id={`repo-severities-tooltip-${label}-${id}`}>
        <Popover.Content>
          {value} {label} {actionItemLabel} {isFromRepo ? Labels.HoverContent : ''}
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div className={clsx('repo-severities', XSText({ weight: strings.textStyling.medium }))}>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(repoSeverity.nCritical, Labels.Critical)}
      >
        <span
          className={`repo-severities__critical repo-severities__badge ${
            repoSeverity.nCritical === 0 ? 'repo-severities__gray' : ''
          }`}
        >
          {repoSeverity.nCritical} {Severities.Critical}
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(repoSeverity.nHigh, Labels.High)}
      >
        <span
          className={`repo-severities__high repo-severities__badge ${
            repoSeverity.nHigh === 0 ? 'repo-severities__gray' : ''
          }`}
        >
          {repoSeverity.nHigh} {Severities.High}
        </span>
      </OverlayTrigger>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(repoSeverity.nMedium, Labels.Medium)}
      >
        <span
          className={`repo-severities__medium repo-severities__badge ${
            repoSeverity.nMedium === 0 ? 'repo-severities__gray' : ''
          }`}
        >
          {repoSeverity.nMedium} {Severities.Medium}
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default RepoSeverities;
