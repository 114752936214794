export interface TrendsData {
  name: string;
  branch: string;
  summary: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
}

export interface RepositoryData {
  ID: number;
  Favorite: boolean;
  Name: string;
  Severities: {
    nCritical: number | undefined;
    nHigh: number | undefined;
    nMedium: number | undefined;
  };
  Branches: number | undefined;
  ActionItems: number;
  EligibleAutoFixCount?: number;
  isTrendsGoingUp?: boolean;
  Trends: Record<string, number> | undefined | null;
}

export interface RepositoryTableData {
  ID: number;
  Favorite: boolean;
  Name: string;
  Severities: {
    nCritical: number | undefined;
    nHigh: number | undefined;
    nMedium: number | undefined;
  };
  Branches: number | undefined;
  ActionItems: number;
  Trends: TrendsTableData[];
}

interface TrendsTableData {
  id: string;
  dotColor: string;
  color: string;
  data: TrendsChartData[];
}

interface TrendsChartData {
  x: string;
  y: number;
}

export interface TableColumnsParams {
  loadingTrends: boolean | undefined;
  onFavoriteChanged: (item: RepositoryTableData) => void;
  onRepositorySelected: (item: RepositoryTableData) => void;
}

export enum TableAccessors {
  Favorite = 'Favorite',
  Name = 'Name',
  Branches = 'Branches',
  Severities = 'Severities',
  ActionItems = 'ActionItems',
  EligibleAutoFixCount = 'EligibleAutoFixCount',
  Trends = 'Trends',
}

export enum TimeUnits {
  Month = 'month',
  MMDD = 'MM/DD',
}

export const Labels = {
  NoNameAvailable: 'No Name Available',
} as const;
