import React from 'react';

import { IRouter, IStore } from '~globalTypes';

import { strings } from '~utils/strings';

import NotificationsComponent from '~reactComponents/Notifications/UserNotificationsComponent.react';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { ORG_DASHBOARD, PROFILE } from '~reactComponents/NavigationReact/Navigation.config.react';
import { handlePageChange } from '~utils/global.helpers.react';

type NotificationsProps = {
  store: () => IStore;
  router: () => IRouter;
};

const Notifications = ({ store, router }: NotificationsProps) => {
  const organization = localStorage.getItem('organization') || '';

  const breadcrumbsRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: organization,
      href: `/orgs/${organization}/dashboard`,
    },
    {
      id: PROFILE,
      label: strings.navigation.userSettings,
      href: `/user-settings/details`,
    },
    {
      id: 'last',
      label: 'Notifications',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbsRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <h1 className="sr-only">{strings.userProfile.notifications}</h1>
      <NotificationsComponent store={store} />
    </LayoutReact>
  );
};

export default Notifications;
