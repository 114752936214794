import { FiltersMapType } from '~globalTypes';
import { strings } from '~utils/strings';
export interface ExecutionRuleFilters {
  [key: string]: string | string[] | Record<string, string>[] | null;
}

export interface Rule {
  ID: number;
  Organization: string;
  Cluster: string;
  Name: string;
  Description: string;
  Context: string;
  ReportType: string;
  EventType: string;
  Repository: string;
  Action: string;
  IsActive: boolean;
  RuleOrder: number;
  CreatedAt: string;
  LastUpdatedAt: string;
  CreatedBy: string;
}

export interface Executions {
  executions: Execution[];
  total: number;
}

export interface Execution {
  ruleId: number;
  executionId: number;
  status: string;
  name: string;
  cluster: string;
  context: string;
  namespace: string;
  reportType: string;
  kind: string;
  eventType: string;
  startDate: string;
  endDate: string;
}

export interface DateRange {
  from?: Date | null | undefined;
  to?: Date | null | undefined;
}

export interface ExecutionRulesFilter {
  id: string;
  value: {
    value: string | number;
    label: string | number;
  }[];
}

export interface Description {
  lastReportedAt?: string;
  actionItem?: {
    cluster: string;
    context: string;
    eventType: string;
    id: number;
    reportType: string;
    resource: {
      container: string;
      kind: string;
      name: string;
      namespace: string;
    };
  };
  modifiedFields: string;
  events: string;
  logs: string[];
}

export const filtersMap: FiltersMapType = {
  names: 'name',
  dates: 'startDate',
  clusters: 'cluster',
  contexts: 'context',
  kinds: strings.aggregators.kind,
  namespaces: strings.aggregators.namespace,
  eventTypes: strings.aggregators.eventType,
  statuses: 'status',
  reportTypes: strings.aggregators.reportType,
};

export const sortingFields = [
  'names',
  'clusters',
  'contexts',
  'kinds',
  'namespaces',
  'eventTypes',
  'statuses',
  'reportTypes',
];

export enum ExecutionStatus {
  Error = 'error',
  Warning = 'warning',
  Modified = 'modified',
  NoChange = 'no_change',
}
