import React from 'react';
import { Row, TableInstance } from 'react-table';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner.react';
import Icon from '../../Icons/Icon.react';

import { TableColumnsParams, TableAccessors } from './ClustersChart.types.react';

import { CLUSTER_OVERVIEW } from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { ReportStatus } from '~utils/global.types.react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTableColumns = ({
  organization,
  loadingSummary,
  router,
  changeFavorite,
}: TableColumnsParams) => {
  return [
    {
      Header: (
        <span style={{ paddingTop: '0.25rem' }}>
          <Icon name="star-fill" width="16" height="16" fill={COLORS.CORE.LINK} />
        </span>
      ),
      accessor: TableAccessors.Favorite,
      Cell: (data: TableInstance) => {
        const values = data.row.original;
        return (
          <div className="overview-clusters-card__star-icon" onClick={() => changeFavorite(values)}>
            <Icon
              name={values.favorite ? 'star-fill' : 'star'}
              width="16"
              height="16"
              fill={values.favorite ? COLORS.CORE.LINK : ''}
            />
          </div>
        );
      },
      width: 25,
      minWidth: 25,
      maxWidth: 50,
      defaultCanSort: true,
      sortType: 'basic',
    },
    {
      Header: strings.general.Cluster,
      accessor: TableAccessors.Cluster,
      width: 100,
      minWidth: 100,
      maxWidth: 200,
      defaultCanSort: true,
      Cell: ({ value }: { value: boolean }) => {
        const resolvedRoute = router().resolve({
          name: CLUSTER_OVERVIEW,
          params: { org: organization.Name, cluster: value },
        });
        return (
          <a className="overview-clusters-card__cluster-link" href={resolvedRoute?.href}>
            {value}
          </a>
        );
      },
    },
    {
      Header: strings.general.Version,
      accessor: TableAccessors.Version,
      width: 50,
      minWidth: 30,
      maxWidth: 50,
      defaultCanSort: true,
      Cell: (data: TableInstance) => {
        if (loadingSummary) {
          return (
            <LoadingSpinner
              containerClassNames="overview-clusters-card__loading-container"
              spinnerClassNames="overview-clusters-card__loading"
            />
          );
        }
        const { version }: { version: string | undefined } = data.row.original;
        return <span>{version ? version : strings.general.na}</span>;
      },
    },
    {
      Header: strings.agent,
      accessor: TableAccessors.Workloads,
      width: 100,
      minWidth: 50,
      maxWidth: 150,
      defaultCanSort: true,
      sortType: sortReportByLastSeen(strings.workloads),
      Cell: (data: TableInstance) => {
        const {
          workloads,
          nodesCount,
        }: { workloads: ReportStatus | undefined; nodesCount: number } = data.row.original;
        const formattedNodes = nodesCount <= 1 ? `${nodesCount} node` : `${nodesCount} nodes`;
        const suffix = `(${formattedNodes})`;
        return (
          <ReportStatusTextIndicator
            loadingSummary={loadingSummary}
            offlineThreshold={3}
            description={suffix}
            reportStatus={workloads}
          />
        );
      },
    },
    {
      Header: strings.admission,
      accessor: TableAccessors.Admission,
      width: 100,
      minWidth: 50,
      maxWidth: 150,
      defaultCanSort: true,
      sortType: sortReportByLastSeen(strings.admission),
      Cell: (data: TableInstance) => {
        const {
          admission,
          passiveMode,
        }: { admission: ReportStatus | undefined; passiveMode: boolean } = data.row.original;
        const mode = passiveMode ? 'Passive' : 'Blocking';
        const suffix = `(${mode} Mode)`;
        return (
          <ReportStatusTextIndicator
            loadingSummary={loadingSummary}
            description={suffix}
            reportStatus={admission}
          />
        );
      },
    },
    {
      Header: strings.prometheus,
      accessor: TableAccessors.Prometheus,
      width: 100,
      minWidth: 50,
      maxWidth: 150,
      defaultCanSort: true,
      sortType: sortReportByLastSeen(strings.prometheus),
      Cell: (data: TableInstance) => {
        const { prometheus }: { prometheus: ReportStatus | undefined } = data.row.original;
        return (
          <ReportStatusTextIndicator loadingSummary={loadingSummary} reportStatus={prometheus} />
        );
      },
    },
  ];
};

const sortReportByLastSeen = function (accessor: string) {
  return function (rowA: Row, rowB: Row) {
    const rsA = rowA.original[accessor];
    const rsB = rowB.original[accessor];

    if (rsA.Enabled && !rsB.Enabled) {
      return 1;
    }

    if (rsA.Enabled && !rsB.Enabled) {
      return -1;
    }

    const now = new Date();

    const timeDiffA = now.getTime() - new Date(rsA.LastSeen).getTime();
    const timeDiffB = now.getTime() - new Date(rsB.LastSeen).getTime();

    if (timeDiffA > timeDiffB) {
      return 1;
    }
    return timeDiffA < timeDiffB ? -1 : 0;
  };
};

const ReportStatusTextIndicator = (props: {
  reportStatus: ReportStatus | undefined;
  description: string;
  offlineThreshold: number;
  loadingSummary: boolean | undefined;
}) => {
  if (props.loadingSummary) {
    return (
      <LoadingSpinner
        containerClassNames="overview-clusters-card__loading-container"
        spinnerClassNames="overview-clusters-card__loading"
      />
    );
  }
  const now = new Date();
  if (props.reportStatus?.Enabled && props.reportStatus.LastSeen) {
    const lastSeen = new Date(props.reportStatus.LastSeen);
    const timeDiff = now.getTime() - lastSeen.getTime();
    const hoursMinutes = timeDiff / (1000 * 60);
    const online = hoursMinutes < props.offlineThreshold * 60;
    const formattedDate = formatDifference(now, lastSeen);
    const value = online ? `${formattedDate} ago` : `Offline ${formattedDate}`;
    const indicator = online ? 'synced-green-circle' : 'synced-red-circle';
    return (
      <>
        <div
          className={clsx(
            'custom-last-synced-date__synced-circle',
            'custom-last-synced-date__' + indicator,
          )}
        ></div>
        <span>
          {value}
          {props.description ? (
            <span className="overview-clusters-card__cell-container__description">
              {' '}
              {props.description}
            </span>
          ) : null}
        </span>
      </>
    );
  }
  return <span> {strings.clustersOverview.notInstalled}</span>;
};

ReportStatusTextIndicator.defaultProps = {
  offlineThreshold: 24,
  description: undefined,
};

// calculates the difference between two dates in terms of y, mo, d, h and s -- depending on the magnitude of the difference
const formatDifference = (date1: Date, date2: Date) => {
  const difference = Math.abs(date1.getTime() - date2.getTime());
  const secondsDifference = Math.floor(difference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(monthsDifference / 12);

  if (yearsDifference > 0) {
    return yearsDifference + 'y';
  } else if (monthsDifference > 0) {
    return monthsDifference + 'mo';
  } else if (daysDifference > 0) {
    return daysDifference + 'd';
  } else if (hoursDifference > 0) {
    return hoursDifference + 'h';
  } else if (minutesDifference > 0) {
    return minutesDifference + 'm';
  } else {
    return secondsDifference + 's';
  }
};
