import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { getCurrentTimezone } from '~reactHelpers';

dayjs.extend(timezone);

export const formatLog = (event: any) => {
  if (!event) return '';
  const timeZone = getCurrentTimezone();
  return `${
    timeZone
      ? dayjs(event.createdAt).tz(timeZone).format('MMM DD YYYY HH:mm:ss')
      : dayjs(event.createdAt).format('MMM DD YYYY HH:mm:ss')
  } ${event.level} ${event.details}`;
};

export const showLogs = (logs: string[] | undefined) => {
  if (!logs?.length) return;
  return logs.join('\r\n');
};
