import React from 'react';

import { TabsData } from '~utils/global.types.react';

import './Tabs.react.scss';

type TabsProps = {
  data: TabsData[];
};

const Tabs = ({ data, ...props }: TabsProps) => {
  if (!data?.length) {
    return <></>;
  }

  const onTabClicked = (item: TabsData) => () => {
    if (item.onClick) {
      item.onClick(item);
    }
  };

  return (
    <div className="tabs" {...props}>
      {data.map((item: TabsData) => (
        <div
          className={`tabs__item ${item.isActive && 'tabs__item--active'}`}
          key={item.id}
          onClick={onTabClicked(item)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
