import React from 'react';
import { Alert, Button } from 'react-bootstrap';

import { Breadcrumbs } from '@fairwindsops/ui-components';
import ClusterSettings from '../clusterSettings/ClusterSettings.react';
import SettingsRepos from '~reactComponents/Settings/repositories/SettingsRepos.react';
import DeleteOrg from '~reactComponents/Settings/deleteOrg/DeleteOrg.react';

import { IStore, IRouter } from '~globalTypes';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';
import { handlePageChange } from '~utils/global.helpers.react';

import './DangerZone.react.scss';

type DangerZoneProps = {
  store: () => IStore;
  router: () => IRouter;
};

const DangerZone = ({ store, router }: DangerZoneProps) => {
  const { organization, clusters, repositories, isOrgOwner } = store().getters;

  if (!organization) return <></>;

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.dangerZone,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="danger-container">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {organization.Tier === 0 && (
        <Alert className="danger-container__alert">
          <div>
            The free tier of Fairwinds Insights includes scanning for two clusters and one
            repository. You can add additional clusters and repositories now and we'll be in touch
            to discuss unlimited access
          </div>
          <a href="https://fairwinds.com/insights-pricing" target="_blank">
            <Button className="btn plg-upgrade-btn" variant="primary">
              {strings.freeTier.Upgrade}
            </Button>
          </a>
        </Alert>
      )}
      {isOrgOwner ? (
        <div>
          <ClusterSettings
            organization={organization}
            clusters={clusters}
            isOrgOwner={isOrgOwner}
            store={store}
          />
          <SettingsRepos repositories={repositories} isOrgOwner={isOrgOwner} store={store} />
          <DeleteOrg
            organization={organization}
            isOrgOwner={isOrgOwner}
            store={store}
            router={router}
          />
        </div>
      ) : (
        <>
          <span>{strings.settings.dangerZone.notOrgOwnerMessage}</span>
          <div>
            <ClusterSettings
              organization={organization}
              clusters={clusters}
              isOrgOwner={isOrgOwner}
              store={store}
            />
            <SettingsRepos repositories={repositories} isOrgOwner={isOrgOwner} store={store} />
          </div>
        </>
      )}
    </div>
  );
};

export default DangerZone;
