import React from 'react';
import { Item, ItemParams, Menu } from 'react-contexify';
import { CONTEXT_MENU_ID } from '../Table/Table.config.react';
import ExportIcon from '~assetIcons/export.svg';
import SimilarIcon from '~assetIcons/similar.svg';
import 'react-contexify/dist/ReactContexify.css';
import { IRouter } from '~globalTypes';
import './ContextMenu.react.scss';
import { handlePageChange } from '~reactHelpers';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

type ContextMenuProps = {
  router: () => IRouter;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  editorPage?: boolean;
};

interface ItemProps {
  id: number;
}

const ContextMenu = ({ router, setModal, editorPage }: ContextMenuProps) => {
  const editPolicy = ({ props: { policy } }: ItemParams) => {
    // creating new policy goes to edit policy page - keep this loop; make sure it's clear the save actually happened; figure out how to keep the error around longer - react-toast
    logger.logEvent('all-policies: select edit OPA from description of policy');
    handlePageChange(router, 'policy-wizard-edit', { name: policy.title });
  };

  const deletePolicy = () => {
    const mixpanelText = editorPage
      ? 'policy-wizard-edit: delete button clicked'
      : 'all-policies: delete button clicked from policy description card';
    logger.logEvent(mixpanelText);
    setModal(true);
  };

  return (
    <Menu id={CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>Additional Actions</span>
      </Item>
      {!editorPage && (
        <Item onClick={editPolicy}>
          <img
            className="ticket-icon"
            src={ExportIcon}
            style={{ paddingRight: '1rem' }}
            alt={strings.actionItemsPage.contextMenu.ticketIcon}
          />
          Edit OPA Policy
        </Item>
      )}
      <Item onClick={deletePolicy}>
        <img
          className="similar-icon"
          src={SimilarIcon}
          style={{ paddingRight: '1rem' }}
          alt="similar icon"
        />{' '}
        Delete OPA Policy
      </Item>
    </Menu>
  );
};

export default ContextMenu;
