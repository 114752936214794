import React from 'react';
import { TableInstance } from 'react-table';
import { Button } from 'react-bootstrap';

import Icon from '~reactIcons/Icon.react';

import {
  TableColumnsParams,
  TableColumnNames,
  TableAccessors,
} from './ClusterSettings.types.react';

export const getTableColumns = ({ onClusterSelected, isOrgOwner }: TableColumnsParams) => {
  return isOrgOwner
    ? [
        {
          Header: TableColumnNames.Cluster,
          accessor: TableAccessors.Cluster,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            return <span>{values.cluster}</span>;
          },
          minWidth: 524,
          maxWidth: 524,
          width: 524,
        },
        {
          Header: TableColumnNames.Delete,
          minWidth: 130,
          maxWidth: 130,
          width: 130,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            const isDeleting = values.status !== 'active';
            return (
              <Button
                variant="danger"
                onClick={() => onClusterSelected(values)}
                disabled={isDeleting}
                className={`${isDeleting ? 'deleting-button' : ''}`}
              >
                <Icon
                  name="trash"
                  className={`delete-icon cluster-delete-icon ${isDeleting ? 'deleting-icon' : ''}`}
                />
                <span>{isDeleting ? 'Deleting...' : 'Delete'}</span>
              </Button>
            );
          },
        },
      ]
    : [
        {
          Header: TableColumnNames.Cluster,
          accessor: TableAccessors.Cluster,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            return <span>{values.cluster}</span>;
          },
          minWidth: 524,
          maxWidth: 524,
          width: 524,
        },
      ];
};
