import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Controlled as CodeMirror } from '@leifandersen/react-codemirror2';
import { joiResolver } from '@hookform/resolvers/joi';
import { Toaster } from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import Joi from 'joi';
import Select from 'react-select';

import { EDITRULE } from '~reactComponents/NavigationReact/Navigation.config.react';

import {
  IRule,
  IRulesFormData,
  OptionType,
  Cluster,
  IStore,
  Repository,
  IRoute,
} from '~utils/global.types.react';

import { fixID, sortAscending } from '~utils/helpers';
import { RuleContexts, ReportTypes } from '~utils/constants';
import { strings } from '~utils/strings';

import 'codemirror/mode/javascript/javascript';
import './RulesEditor.react.scss';

type RulesEditorProps = {
  handleOnSubmit: (data: IRulesFormData) => void;
  isEdit?: boolean;
  route: IRoute;
  rule: IRulesFormData;
  rules: IRule[];
  store: () => IStore;
};

const validationSchema = Joi.object({
  Name: Joi.string().trim().replace(/\s/g, '-').required().prefs({ convert: true }),
  Description: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  Context: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  ReportType: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  Cluster: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  Repository: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  Action: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  LogsEnabled: Joi.boolean().optional(),
});

const RulesEditor = ({ handleOnSubmit, isEdit, route, rule, rules, store }: RulesEditorProps) => {
  const [ruleName, setRuleName] = useState<string>('');
  const [context, setContext] = useState<OptionType | null>(null);
  const [report, setReport] = useState<OptionType | null>(null);
  const [cluster, setCluster] = useState<OptionType | null>(null);
  const [repository, setRepository] = useState<OptionType | null>(null);
  const [action, setAction] = useState<string | null>(rule?.Action || null);
  const [isLogEnabled, setIsLogEnabled] = useState<boolean>(false);

  const isOrgOwner = store().getters.isOrgOwner;

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm<IRulesFormData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      Name: rule?.Name ? fixID(rule.Name) : '',
      Description: rule?.Description || '',
      Context: rule?.Context || '',
      ReportType: rule?.ReportType || '',
      Cluster: rule?.Cluster || '',
      Repository: rule?.Repository || '',
      Action: rule?.Action || '',
    },
  });

  const initAction = (action: string | undefined) => {
    if (!action) {
      return;
    }

    setAction(action);
  };

  const initContext = (context: string | undefined) => {
    if (!context) {
      return;
    }

    const selectedContext = contextOptions.find((option) => option.value === context);

    setContext(selectedContext || null);
  };

  const initReportType = (reportType: string | undefined) => {
    if (!reportType) {
      return;
    }

    const selectedReportType = reportOptions.find((option) => option.value === reportType);

    setReport(selectedReportType || null);
  };

  const initCluster = (cluster: string | undefined) => {
    if (!cluster) {
      return;
    }

    const selectedCluster = clusterOptions.find((option) => option.value === cluster);

    setCluster(selectedCluster || null);
  };

  const initRepository = (repository: string | undefined) => {
    if (!repository) {
      return;
    }

    const selectedRepo = repositoryOptions.find((option) => option.value === repository);

    setRepository(selectedRepo || null);
  };

  useEffect(() => {
    if (isEdit && rule) {
      reset(rule);
      setRuleName(rule.Name || '');
      setIsLogEnabled(rule.LogsEnabled || false);
      initAction(rule.Action);
      initContext(rule.Context);
      initReportType(rule.ReportType);
      initCluster(rule.Cluster);
      initRepository(rule.Repository);
    }
  }, [isEdit, rule]);

  const contextOptions = useMemo(() => {
    const contexts = RuleContexts.sort(sortAscending).map((context: string) => ({
      value: context,
      label:
        context === 'AdmissionController' ? strings.automationRule.admissionController : context,
    }));
    return [{ value: '', label: strings.automationRule.all }, ...contexts];
  }, []);

  const reportOptions = useMemo(() => {
    const reports = ReportTypes.sort(sortAscending).map((report: string) => ({
      value: report,
      label: report,
    }));
    return [{ value: '', label: strings.automationRule.all }, ...reports];
  }, []);

  const clusterOptions = useMemo(() => {
    const clusters = store().getters.clusters.map((cluster: Cluster) => ({
      value: cluster.Name,
      label: cluster.Name,
    }));
    return [{ value: '', label: strings.automationRule.all }, ...clusters];
  }, [store]);

  const repositoryOptions = useMemo(() => {
    const repositories = store().getters.repositories.map((repository: Repository) => ({
      value: repository.Name,
      label: repository.Name,
    }));
    return [{ value: '', label: strings.automationRule.all }, ...repositories];
  }, [store]);

  const isRuleNameUnique = (name: string) => {
    if (!name) {
      return false;
    }

    let filteredRules;

    if (route?.name === EDITRULE) {
      rules = rules?.filter((item) => item.Name !== name);
    }

    return (filteredRules || rules)?.every((item) => item.Name !== name);
  };

  const onRuleNameKeyUp = (e: SyntheticEvent) => {
    setValue('Name', fixID((e.target as HTMLInputElement).value));
  };

  const submit = (data: IRulesFormData) => {
    if (!isRuleNameUnique(data.Name || '')) {
      setError('Name', {
        type: 'custom',
        message: strings.automationRule.ruleAlreadyExists.replace('$ruleName', data.Name),
      });
      return;
    }

    data.LogsEnabled = isLogEnabled;

    handleOnSubmit(data);
  };

  const isSubmitButtonDisabled = useMemo(
    () => !isRuleNameUnique(ruleName) || (context?.value === 'CI/CD' && repository === null),
    [ruleName, context, repository],
  );

  const submitButtonText = useMemo(
    () =>
      route.name === EDITRULE && !route.params?.isTemplate
        ? strings.automationRule.updateRule
        : strings.automationRule.saveRule,
    [route],
  );

  const onLogsEnabled = () => {
    setIsLogEnabled((prevState) => !prevState);
  };

  return (
    <div className="rule--editor">
      <Form onSubmit={handleSubmit((data: IRulesFormData) => submit(data))}>
        <fieldset disabled={!isOrgOwner}>
          <div className="rule--editor--details">
            <FormGroup controlId="Name" className="rule--editor--details__Name">
              <Form.Label>{strings.automationRule.ruleName}</Form.Label>
              <Form.Control
                {...register('Name')}
                className={`${errors.Name ? 'is-invalid' : ''}`}
                data-cy="rule-name-text-input"
                onChange={(e) => setRuleName(e.target.value)}
                onKeyUp={onRuleNameKeyUp}
                placeholder="Name"
                type="text"
                value={ruleName}
              />
              <Form.Control.Feedback type="invalid">{errors?.Name?.message}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="Description" className="rule--editor--details__Description">
              <Form.Label>{strings.automationRule.ruleDescription}</Form.Label>
              <Form.Control
                {...register('Description')}
                className={`${errors.Description ? 'is-invalid' : ''}`}
                data-cy="rule-description-text-input"
                placeholder="Description"
                type="text"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.Description?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </div>
          <div className="rule--editor--options">
            <FormGroup controlId="Context" className="dropdown-form-group">
              <Form.Label>{strings.automationRule.context}</Form.Label>
              <Controller
                name="Context"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      className="rule--editor__select"
                      id="rule-context-dropdown"
                      onChange={(option) => {
                        setValue('Context', option.value);
                        setContext(option);
                        if (option.value !== 'CI/CD' && option.value !== 'All') {
                          setRepository(null);
                          setValue('Repository', '');
                        } else if (option.value === 'CI/CD') {
                          setCluster(null);
                          setValue('Cluster', '');
                        }
                      }}
                      options={contextOptions}
                      value={context || contextOptions[0]}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.Context?.message}
              </Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="ReportType" className="dropdown-form-group">
              <Form.Label>{strings.automationRule.report}</Form.Label>
              <Controller
                name="ReportType"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      className="rule--editor__select"
                      id="rule-report-dropdown"
                      onChange={(option) => {
                        setValue('ReportType', option.value);
                        setReport(option);
                      }}
                      options={reportOptions}
                      value={report || reportOptions[0]}
                    />
                  );
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.ReportType?.message}
              </Form.Control.Feedback>
            </FormGroup>
            {context?.value !== 'CI/CD' && (
              <FormGroup controlId="Cluster" className="dropdown-form-group">
                <Form.Label>{strings.automationRule.cluster}</Form.Label>
                <Controller
                  name="Cluster"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        className="rule--editor__select"
                        id="rule-cluster-dropdown"
                        onChange={(option) => {
                          setValue('Cluster', option.value);
                          setCluster(option);
                        }}
                        options={clusterOptions}
                        value={cluster || clusterOptions[0]}
                      />
                    );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.Cluster?.message}
                </Form.Control.Feedback>
              </FormGroup>
            )}
            {['CI/CD', ''].includes(context?.value || '') && (
              <FormGroup controlId="Cluster" className="dropdown-form-group">
                <Form.Label>{strings.automationRule.repository}</Form.Label>
                <Controller
                  name="Repository"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        className="rule--editor__select"
                        id="rule-repository-dropdown"
                        onChange={(option) => {
                          setValue('Repository', option.value);
                          setRepository(option);
                        }}
                        options={repositoryOptions}
                        value={repository || repositoryOptions[0]}
                      />
                    );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors?.Repository?.message}
                </Form.Control.Feedback>
              </FormGroup>
            )}
            <FormGroup controlId="Action">
              <Form.Label data-cy="action">{strings.automationRule.action}</Form.Label>
              <Controller
                control={control}
                name="Action"
                defaultValue=""
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CodeMirror
                    value={action}
                    options={{
                      tabSize: 2,
                      mode: 'text/javascript',
                      theme: 'material',
                      lineNumbers: true,
                      line: true,
                      readOnly: !isOrgOwner,
                    }}
                    onBeforeChange={(editor, data, value) => {
                      setAction(value);
                      setValue('Action', value);
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.Action?.message}
              </Form.Control.Feedback>
            </FormGroup>
          </div>
          <div className="rule--editor__action" onClick={onLogsEnabled}>
            <Form.Check
              aria-label={strings.automationRule.enableExecutionLogsToggle}
              checked={isLogEnabled}
              className="rule--editor__toggle-rule-status-btn"
              type="switch"
              data-cy="ruleLogsToggle"
            />
            <span>{strings.Logs}</span>
          </div>
          <Button
            type="submit"
            variant="primary"
            data-cy="submit-rule-button"
            disabled={isSubmitButtonDisabled}
          >
            {submitButtonText}
          </Button>
        </fieldset>
        <Toaster />
      </Form>
    </div>
  );
};

export default RulesEditor;
