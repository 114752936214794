import NewCluster from './NewCluster.react';

export default {
  name: 'newcluster',
  components: {
    'new-cluster-page': NewCluster,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
