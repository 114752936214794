export const Labels = {
  notImplemented: 'notImplemented',
  implemented: 'implemented',
  passing: 'passing',
  needsEvidence: 'needsEvidence',
  notStarted: 'notStarted',
  notInScope: 'notInScope',
  failing: 'failing',
  passingText: 'Passing',
  needsEvidenceText: 'Needs Evidence',
  notStartedText: 'Not Started',
  notInScopeText: 'Not in Scope',
  failingText: 'Failing',
} as const;

export const KeyPositions: Record<string, number> = {
  passing: 0,
  notStarted: 1,
  needsEvidence: 2,
  notInScope: 3,
  failing: 4,
};
