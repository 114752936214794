import dayjs, { Dayjs } from 'dayjs';

export type DateRangeLabel =
  | 'Last 7 days'
  | 'Last 30 days'
  | 'Last 365 days'
  | 'Last month'
  | 'Month to date'
  | 'Last quarter'
  | 'Quarter to date'
  | 'Last year'
  | 'Year to date';

function getQuarterStart(month: number): number {
  if (month < 3) return 0; // Q1
  if (month < 6) return 3; // Q2
  if (month < 9) return 6; // Q3
  return 9; // Q4
}

export const getDateRangeLabel = (today: Dayjs, startDate: Dayjs, endDate: Dayjs): DateRangeLabel | undefined => {
  const diffInDays = endDate.diff(startDate, 'day');
  const isSameMonth = today.isSame(startDate, 'month');

  if (diffInDays === 7 && endDate.isSame(today, 'day')) {
    return 'Last 7 days';
  }

  if ((diffInDays === 30 || diffInDays === 31) && endDate.isSame(today, 'day')) {
    return 'Last 30 days';
  }

  if ((diffInDays === 365 || diffInDays === 366) && endDate.isSame(today, 'day')) {
    return 'Last 365 days';
  }

  const lastMonth = today.subtract(1, 'month');
  if (startDate.isSame(lastMonth, 'month') && endDate.isSame(lastMonth, 'month')) {
    return 'Last month';
  }

  if (isSameMonth && endDate.isSame(today, 'day') && startDate.isSame(today.startOf('month'), 'day')) {
    return 'Month to date';
  }

  const currentMonth = today.month();

  let lastQuarterStartMonth;
  let lastQuarterEndMonth;

  if (currentMonth >= 0 && currentMonth <= 2) {
    lastQuarterStartMonth = 9;
    lastQuarterEndMonth = 11;
  } else if (currentMonth >= 3 && currentMonth <= 5) {
    lastQuarterStartMonth = 0;
    lastQuarterEndMonth = 2;
  } else if (currentMonth >= 6 && currentMonth <= 8) {
    lastQuarterStartMonth = 3;
    lastQuarterEndMonth = 5;
  } else {
    lastQuarterStartMonth = 6;
    lastQuarterEndMonth = 8;
  }

  const lastQuarterStart = today.set('month', lastQuarterStartMonth).startOf('month');
  const lastQuarterEnd = today.set('month', lastQuarterEndMonth).endOf('month');

  if (startDate.isSame(lastQuarterStart, 'day') && endDate.isSame(lastQuarterEnd, 'day')) {
    return 'Last quarter';
  }

  const currentQuarterStartMonth = getQuarterStart(today.month());
  const currentQuarterStart = today.set('month', currentQuarterStartMonth).startOf('month');

  if (startDate.isSame(currentQuarterStart, 'day') && endDate.isSame(today, 'day')) {
    return 'Quarter to date';
  }

  const lastYear = today.subtract(1, 'year').startOf('year');
  const lastYearEnd = today.subtract(1, 'year').endOf('year');
  if (startDate.isSame(lastYear, 'day') && endDate.isSame(lastYearEnd, 'day')) {
    return 'Last year';
  }

  const currentYearStart = dayjs().startOf('year');
  if (startDate.isSame(currentYearStart, 'day') && endDate.isSame(today, 'day')) {
    return 'Year to date';
  }

  return undefined;
};
