import Clusters from './Clusters.react';

export default {
  name: 'clusters',
  components: {
    'clusters-page': Clusters,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
