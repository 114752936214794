import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';

import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import ExpandIcon from '~reactComponents/Icons/ExpandIcon.icon.react';
import InputTag from '~reactComponents/InputTag/InputTag.react';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import { APP_GROUP_SPECS, IAppGroup, IAppGroupSpec, IStore } from '~utils/global.types.react';

import logger from '~logger';

import './UpdateAppGroupModal.react.scss';

type ExpadableSections = {
  matchResources: boolean;
  excludeResources: boolean;
};

type UpdateAppGroupModalProps = {
  organizationName: string;
  isModalShown?: boolean;
  onConfirmClicked?: () => void;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  selectedAppGroup: IAppGroup | null;
  onDeleteModalShown?: (appGroupName: string) => void;
  store: () => IStore;
};

const UpdateAppGroupModal = ({
  organizationName,
  isModalShown,
  onConfirmClicked,
  onModalHidden,
  selectedAppGroup,
  onDeleteModalShown,
  store,
}: UpdateAppGroupModalProps) => {
  const [appGroupName, setAppGroupName] = useState<string>('');
  const [expandableSections, setExpandableSections] = useState<ExpadableSections>({
    matchResources: true,
    excludeResources: false,
  });
  const [matchResources, setMatchResources] = useState<Record<string, string[]>>({});
  const [excludeResources, setExcludeResources] = useState<Record<string, string[]>>({});
  const [names, setNames] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [namespaceLabels, setNamespaceLabels] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [kinds, setKinds] = useState<string[]>([]);
  const [containers, setContainers] = useState<string[]>([]);

  const baseURL = `/v0/organizations/${organizationName}`;

  const clusters = store().getters.clusters;
  const repositories = store().getters.repositories;

  const getActionItemFilters = async () => {
    try {
      const actionItemFilters = await sendRequest(
        'GET',
        `${baseURL}/action-item-filters?Field=ResourceName&Field=ResourceNamespace&Field=ResourceKind&Field=ResourceLabel&Field=ResourceContainer&Field=NamespaceLabel`,
        {},
        null,
      );

      setNames(actionItemFilters?.ResourceName?.length ? actionItemFilters.ResourceName : []);
      setNamespaces(
        actionItemFilters?.ResourceNamespace?.length ? actionItemFilters.ResourceNamespace : [],
      );
      setNamespaceLabels(
        actionItemFilters?.NamespaceLabel?.length ? actionItemFilters.NamespaceLabel : [],
      );
      setLabels(actionItemFilters?.ResourceLabel?.length ? actionItemFilters.ResourceLabel : []);
      setKinds(actionItemFilters?.ResourceKind?.length ? actionItemFilters.ResourceKind : []);
      setContainers(
        actionItemFilters?.ResourceContainer?.length ? actionItemFilters.ResourceContainer : [],
      );
    } catch (e: any) {
      logger.logError('error_retrieving_action_item_filters', e);
    }
  };

  useEffect(() => {
    if (!isModalShown) {
      return;
    }

    (async function () {
      await getActionItemFilters();
    })();
  }, [isModalShown]);

  const transformResources = (resources: IAppGroupSpec) => {
    if (!resources) {
      return resources;
    }

    const keys = Object.keys(resources);

    for (const key of keys) {
      const appGroupKey = key as keyof IAppGroupSpec;

      if (['namespaceLabels', strings.appGroups.labelsKey].includes(appGroupKey)) {
        resources[key as keyof IAppGroupSpec] = resources[key as keyof IAppGroupSpec]?.map(
          (data: any) => {
            const dataKeys = Object.keys(data);
            return `${dataKeys[0]}=${data[dataKeys[0]]}`;
          },
        );
      } else {
        resources[key as keyof IAppGroupSpec] = resources[key as keyof IAppGroupSpec] || [];
      }
    }

    return resources as Record<string, string[]>;
  };

  const transformSelectedAppGroup = () => {
    if (!selectedAppGroup) {
      return;
    }

    if (selectedAppGroup.spec) {
      if (selectedAppGroup.spec?.match?.length) {
        setMatchResources(transformResources(selectedAppGroup.spec.match[0]));
      }
      if (selectedAppGroup.spec?.exclude?.length) {
        setExcludeResources(transformResources(selectedAppGroup.spec.exclude[0]));
      }
    }
  };

  useEffect(() => {
    if (selectedAppGroup) {
      transformSelectedAppGroup();
      setAppGroupName(selectedAppGroup.name);
    }
  }, [selectedAppGroup]);

  const closeModal = () => {
    onModalHidden && onModalHidden(false);
    setAppGroupName('');
    setExpandableSections({
      matchResources: true,
      excludeResources: false,
    });
    setMatchResources({});
    setExcludeResources({});
  };

  const toggleMatchResourcesSection = () =>
    setExpandableSections((prev) => ({
      ...prev,
      matchResources: !prev.matchResources,
    }));

  const toggleExcludeResourcesSection = () =>
    setExpandableSections((prev) => ({
      ...prev,
      excludeResources: !prev.excludeResources,
    }));

  const onTagAdded = (value: string, field: string, section: string) => {
    if (!value || !field || !section) {
      return;
    }

    if (section === 'match') {
      setMatchResources((prev) => ({ ...prev, [field]: [...(prev[field] || []), value] }));
    } else {
      setExcludeResources((prev) => ({ ...prev, [field]: [...(prev[field] || []), value] }));
    }
  };

  const onTagRemoved = (value: string, field: string, section: string) => {
    if (!value || !field || !section) {
      return;
    }

    if (section === 'match') {
      setMatchResources((prev) => ({
        ...prev,
        [field]: prev[field].filter((prevValue) => prevValue !== value),
      }));
    } else {
      setExcludeResources((prev) => ({
        ...prev,
        [field]: prev[field].filter((prevValue) => prevValue !== value),
      }));
    }
  };

  const transformExpressionData = (data: string[]) => {
    if (!data?.length) {
      return [];
    }

    const transformedData = [];

    for (const value of data) {
      const splitValue = value.split('=');
      transformedData.push({ [splitValue[0]]: splitValue[1] });
    }

    return transformedData;
  };

  const buildAppGroupPayload = () => {
    // convert x=y to an object
    const matchLabels = transformExpressionData(matchResources[strings.appGroups.labelsKey]);
    const matchNamespaceLabels = transformExpressionData(matchResources['namespaceLabels']);
    const excludeLabels = transformExpressionData(excludeResources[strings.appGroups.labelsKey]);
    const excludeNamespaceLabels = transformExpressionData(excludeResources['namespaceLabels']);

    delete matchResources[strings.appGroups.labelsKey];
    delete matchResources['namespaceLabels'];
    delete excludeResources[strings.appGroups.labelsKey];
    delete excludeResources['namespaceLabels'];

    return {
      type: 'AppGroup',
      name: appGroupName,
      spec: {
        match: [{ ...matchResources, labels: matchLabels, namespaceLabels: matchNamespaceLabels }],
        exclude: [
          { ...excludeResources, labels: excludeLabels, namespaceLabels: excludeNamespaceLabels },
        ],
      },
    };
  };

  const saveAppGroup = async () => {
    try {
      await sendRequest('POST', `${baseURL}/app-groups`, { data: buildAppGroupPayload() }, null);

      onConfirmClicked && onConfirmClicked();
      onModalHidden && onModalHidden(false);

      toast.success(
        strings.appGroups.updateAppGroupSuccess.replace('$APP_GROUP_NAME', appGroupName),
      );
    } catch (e) {
      logger.logError('error_updating_an_app_group', e);
      toast.error(<b>{strings.appGroups.cannotUpdateTheAppGroup}</b>);
    }
  };

  const appGroupSpecs = useMemo(() => Object.keys(APP_GROUP_SPECS), []);

  const suggestedData = useMemo(
    () => ({
      names,
      namespaces,
      clusters: clusters?.length ? clusters.map((cluster) => cluster.Name) : [],
      repositories: repositories?.length ? repositories.map((repository) => repository.Name) : [],
      namespaceLabels,
      labels,
      kinds,
      containers,
    }),
    [clusters, names, namespaces, namespaceLabels, labels, kinds, containers],
  );

  return (
    <Modal show={isModalShown} onHide={closeModal} className="update-app-group-modal">
      <Modal.Body className="pt-0">
        <div className="update-app-group-modal-title-container">
          <h3 className="modal-title">{appGroupName}</h3>
        </div>
        {/* Match Resources */}
        <div className="match-resources">
          <button className="match-resources-title" onClick={toggleMatchResourcesSection}>
            <span>{strings.appGroups.matchResources}</span>
            <div
              className={`update-app-group-modal-expand-icon ${
                expandableSections.matchResources
                  ? ''
                  : 'update-app-group-modal-expand-icon--rotate'
              }`}
            >
              <ExpandIcon />
            </div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id={`tooltip-match-resources`}>
                  <Popover.Content>{strings.appGroups.matchResourcesTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </button>
          {expandableSections.matchResources ? (
            <div className="match-resources-body">
              {appGroupSpecs.map((key) => (
                <div className="match-resources-item">
                  <div className="match-resources-item-title">
                    <span>{APP_GROUP_SPECS[key as keyof IAppGroupSpec]}</span>
                  </div>
                  <InputTag
                    onTagAdded={onTagAdded}
                    onTagRemoved={onTagRemoved}
                    field={key}
                    section="match"
                    defaultTagValues={matchResources[key]}
                    dataCy={`update-app-group-input-tag-${key}`}
                    suggestedData={suggestedData[key as keyof typeof suggestedData]}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {/* End Match Resources */}
        {/* Exclude Resources */}
        <div className="exclude-resources">
          <button className="exclude-resources-title" onClick={toggleExcludeResourcesSection}>
            <span>{strings.appGroups.excludeResources}</span>
            <div
              className={`update-app-group-modal-expand-icon ${
                expandableSections.excludeResources
                  ? ''
                  : 'update-app-group-modal-expand-icon--rotate'
              }`}
            >
              <ExpandIcon />
            </div>
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id={`tooltip-exclude-resources`}>
                  <Popover.Content>{strings.appGroups.excludeResourcesTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon />
            </OverlayTrigger>
          </button>
          {expandableSections.excludeResources ? (
            <div className="exclude-resources-body">
              {appGroupSpecs.map((key) => (
                <div className="exclude-resources-item">
                  <div className="exclude-resources-item-title">
                    <span>{APP_GROUP_SPECS[key as keyof IAppGroupSpec]}</span>
                  </div>
                  <InputTag
                    onTagAdded={onTagAdded}
                    onTagRemoved={onTagRemoved}
                    field={key}
                    section="exclude"
                    defaultTagValues={excludeResources[key]}
                    tagClasses="exclude-tag"
                    suggestedData={suggestedData[key as keyof typeof suggestedData]}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {/* End Exclude Resources */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn update-app-group-modal-cancel-button"
          type="button"
          onClick={closeModal}
        >
          {strings.appGroups.cancel}
        </Button>
        <Button
          className="btn"
          type="button"
          onClick={saveAppGroup}
          disabled={!appGroupName}
          data-cy="update-app-group-button"
        >
          {strings.appGroups.updateAppGroup}
        </Button>
        <Button
          className="btn"
          type="button"
          variant="danger"
          onClick={() => {
            onDeleteModalShown && onDeleteModalShown(appGroupName);
            closeModal();
          }}
          disabled={!appGroupName}
          data-cy="delete-app-group-button"
        >
          {strings.appGroups.deleteAppGroup}
        </Button>
      </Modal.Footer>
      <Toaster />
    </Modal>
  );
};

export default UpdateAppGroupModal;
