import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Card, Row, Col, Badge, Form } from 'react-bootstrap';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import Fuse from 'fuse.js';
import { sendRequest } from '~utils/request';
import Icon from '~reactIcons/Icon.react';
import { IPolicies, ITemplateGitHub } from '../policy.types.react';
import { IStore, IRoute, IRouter } from '~globalTypes';
import logger from '~logger';
import { getCurrentTimezone, handlePageChange } from '~reactHelpers';

import {
  ORG_DASHBOARD,
  ALL_POLICIES,
  POLICY_WIZARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import './PoliciesList.react.scss';
import './PoliciesList.override.scss';

dayjs.extend(timezone);

type PoliciesListProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  isOrgOwner: boolean;
};

const PoliciesList = ({ route, router, store }: PoliciesListProps): React.ReactNode => {
  const { org } = route.params || {};
  const { isOrgOwner } = store().getters;

  const breadcrumbRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ALL_POLICIES,
      label: strings.navigation.Policy,
      href: `/orgs/${org}/policy`,
    },
    {
      id: 'last',
      label: 'OPA Policy Templates',
      href: `/orgs/${org}/opa-lists`,
      isActive: true,
    },
  ];

  const timeZone = getCurrentTimezone();

  const [currentPage, setCurrentPage] = useState<'custom' | 'templates'>('custom');
  const [policies, setPolicies] = useState<IPolicies[] | ITemplateGitHub[]>([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredPolicies, setFilteredPolicies] = useState<IPolicies[] | ITemplateGitHub[]>([]);

  const searchInstance = useMemo(() => {
    return new Fuse(
      policies as IPolicies[],
      {
        keys: ['Name'],
        findAllMatches: true,
        threshold: 0,
        ignoreLocation: true,
      } as Fuse.IFuseOptions<IPolicies>,
    );
  }, [policies]);

  useEffect(() => {
    setFilteredPolicies([]);
    setSearch('');
  }, [currentPage]);

  useEffect(() => {
    getTemplates();
    setLoading(false);
  }, []);

  const formatDate = (policy: IPolicies) => {
    const { CreatedAt, UpdatedAt } = policy;
    if (UpdatedAt > CreatedAt) {
      return `Updated on ${
        timeZone
          ? dayjs(UpdatedAt).tz(timeZone).format('MM/DD/YY')
          : dayjs(UpdatedAt).format('MM/DD/YY')
      }`;
    }
    return `Created on ${
      timeZone
        ? dayjs(CreatedAt).tz(timeZone).format('MM/DD/YY')
        : dayjs(CreatedAt).format('MM/DD/YY')
    }`;
  };

  const handleCreateCustomPolicy = () => {
    router().push({ name: POLICY_WIZARD, params: { policies } });
  };

  const handleEditPolicy = (policy: IPolicies | ITemplateGitHub) => {
    logger.logEvent(`policy-templates: selected ${policy.Name} to edit`);
    router().push({
      name: 'edit-opa-template',
      query: {
        name: policy.Name,
      },
      params: {
        policy,
      },
    });
  };

  const getTemplates = async () => {
    router().push({ path: `${route.path}?filter=templates` });

    try {
      const templates: { [key: string]: never }[] = await sendRequest(
        'GET',
        'https://api.github.com/repos/FairwindsOps/insights-plugins/contents/plugins/opa/examples-v2',
        {
          cache: true,
        },
        null,
      );

      // Get template names
      const templateNames: ITemplateGitHub[] = [];
      templates.forEach((template) => {
        // check for folders only
        if (!template.size) {
          templateNames.push({
            Name: template.name,
            Url: template.url,
          });
        }
      });

      setPolicies(templateNames);
      setCurrentPage('templates');
    } catch (e: unknown) {
      logger.logError('error_fetching_policies_templates_github', e);
    }
  };

  const handlePolicySearch = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const policies = searchInstance.search(target.value).map((res) => res.item);
    setSearch(target.value);
    setFilteredPolicies(policies);
  };

  return (
    <LayoutReact className="policies--list">
      <Breadcrumbs
        data={breadcrumbRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="d-flex justify-content-between mb-3">
        {filteredPolicies.length || policies.length ? (
          <Form className="policies--search">
            <Form.Group controlId="policies-search">
              <Form.Control
                type="search"
                value={search}
                placeholder={`Search ${currentPage === 'templates' ? 'Templates' : 'Policies'}`}
                onChange={handlePolicySearch}
              />
            </Form.Group>
          </Form>
        ) : null}
        {!isOrgOwner && (
          <Alert variant="warning" className="warning-alert">
            <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
            <h4 className="warning-title">
              Only owners are allowed to modify policies. <strong>This page is read-only.</strong>
            </h4>
          </Alert>
        )}
      </div>
      {!filteredPolicies.length && !policies.length && !loading ? (
        <div className="policies--zero">
          <p>
            <strong className="align-center">
              No policies found!
              <br />
            </strong>
            <a href="#" onClick={handleCreateCustomPolicy}>
              Create one now
            </a>{' '}
            or{' '}
            <a href="https://insights.docs.fairwinds.com/reports/opa/" target="_blank">
              read the documentation
            </a>
          </p>
        </div>
      ) : null}
      <Row xs={1} lg={1}>
        {search.length || policies.length || !loading ? (
          (search.length ? filteredPolicies : policies).map((policy, idx) => (
            <Col key={idx}>
              <Card
                onClick={() => handleEditPolicy(policy)}
                role="button"
                data-cy="policies--list--card"
                className="policies--list--card"
              >
                <Card.Body>
                  <div className="policies--list--card">
                    <span role="heading" aria-level={1} className="policies--list--card__title">
                      {policy.Name}
                    </span>
                    {currentPage === 'custom' && (
                      <span>{`${formatDate(policy as IPolicies)} by ${
                        (policy as IPolicies).UpdatedBy
                      }`}</span>
                    )}
                  </div>
                  {currentPage === 'custom' && !(policy as IPolicies)?.Disabled && (
                    <Badge variant="lighter-grey" pill>
                      Enabled
                    </Badge>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <ThinCardLoader />
        )}
      </Row>
    </LayoutReact>
  );
};

const ThinCardLoader = () => {
  return (
    <>
      {Array.from({ length: 7 }).map((_, idx) => (
        <Col key={idx}>
          <Card>
            <Card.Body>
              <div className="policies--list--card">
                <span className="skeleton" style={{ width: '4rem' }} />
                <span
                  className="skeleton"
                  style={{ width: `${Math.random() * (23 - 13) + 11}rem` }}
                />
              </div>
              <span className="skeleton rounded" style={{ width: '4rem' }} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default PoliciesList;
