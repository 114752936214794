import NewAutomation from './NewAutomation.react';

export default {
  name: 'newRule',
  components: {
    'new-automation': NewAutomation,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
