import { VulnerabilitiesActionItems } from './VulnerabilitiesItemsTable.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { Filters } from 'react-table';
import { convertFiltersToURL } from '~utils/global.helpers.react';

export const handleAssigneeSelection = async (
  email: string,
  selectedRows: VulnerabilitiesActionItems[],
  baseUrl: string,
  dryRun = false,
) => {
  try {
    const assigneeEmail = email.length ? email : null;
    const payload = {
      imageSHAs: selectedRows.map((row) => row.sha),
      assigneeEmail: assigneeEmail,
    };
    return await sendRequest(
      'PATCH',
      `${baseUrl}/images/vulnerabilities/assignee/bulk?dryRun=${dryRun}`,
      { data: payload },
      null,
    );
  } catch (e) {
    console.error('Error updating vuln assignee', e);
    logger.logError('error_updating_vuln_action_item_assignee', e);
    throw new Error();
  }
};

export const handleResolutionSelection = async (
  resolution: string,
  selectedRows: VulnerabilitiesActionItems[],
  baseUrl: string,
  dryRun = false,
) => {
  try {
    const payload = {
      imageSHAs: selectedRows.map((row) => row.sha),
      resolution,
    };
    return await sendRequest(
      'PATCH',
      `${baseUrl}/images/vulnerabilities/resolution/bulk?dryRun=${dryRun}`,
      { data: payload },
      null,
    );
  } catch (e) {
    console.error('Error updating vuln resolution', e);
    logger.logError('error_updating_vuln_resolution', e);
    throw new Error();
  }
};

export const convertFiltersToURLForAllImages = (filters: Filters<Record<string, unknown>>, params: URLSearchParams) => {
  const searchParams = convertFiltersToURL(filters, params);

  const vulnerabilitiesCountFilter = filters.find((filter) => filter.id === 'vulnerabilitiesCount');
  if (vulnerabilitiesCountFilter) {
    const values = vulnerabilitiesCountFilter.value as [{ label: string; value: string }];
    if (values && values.length == 1) {
      if (values[0].label.startsWith('<')) {
        searchParams.set('vulnerabilitiesOperator', 'lte');
      } else {
        searchParams.set('vulnerabilitiesOperator', 'gte');
      }
    }
  }

  const repositoriesCount = filters.find((filter) => filter.id === 'repositoriesCount');
  if (repositoriesCount) {
    const values = repositoriesCount.value as [{ label: string; value: string }];
    if (values && values.length > 0) {
      searchParams.set('hasRepositories', values[0].value);
    }
    searchParams.delete('repositoriesCount');
  }

  const workloadsCount = filters.find((filter) => filter.id === 'workloadsCount');
  if (workloadsCount) {
    const values = workloadsCount.value as [{ label: string; value: string }];
    if (values && values.length > 0) {
      searchParams.set('hasWorkloads', values[0].value);
    }
    searchParams.delete('workloadsCount');
  }

  return searchParams;
};
