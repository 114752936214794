import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import Icon from '~reactIcons/Icon.react';
import trash from '@/assets/icons/trash.svg';
import { Breadcrumbs, LayoutReact, DeleteModal } from '@fairwindsops/ui-components';
import RulesEditor from '../components/rulesEditor/RulesEditor.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import {
  AUTOMATION,
  ORG_DASHBOARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { IRoute, IRouter, IRule, IRulesFormData, IStore } from '~utils/global.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { handlePageChange } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './EditAutomation.react.scss';

type EditAutomationProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const sampleAction = `if (ActionItem.Severity <= 0.25) {
  ActionItem.Description = 'Low Risk';
}`;

const EditAutomation = ({ route, router, store }: EditAutomationProps): JSX.Element => {
  const [rules, setRules] = useState<IRule[]>([]);
  const [rule, setRule] = useState<IRule>();
  const [isRuleActive, setIsRuleActive] = useState<boolean>(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const organization = store().getters.organization;
  const isOrgOwner = store().getters.isOrgOwner;

  const baseURL = `/v0/organizations/${organization.Name}`;

  const getRules = async () => {
    setIsLoading(true);

    try {
      const rules = await sendRequest('GET', `${baseURL}/rules`, {}, null);

      setIsLoading(false);

      return rules;
    } catch (e: unknown) {
      logger.logError('error_fetching_automation_rules', e);
      setIsLoading(false);

      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setRules(route?.params?.rules || (await getRules()));
    })();
  }, [route]);

  useEffect(() => {
    let rule;

    if (rules && route?.query?.name) {
      rule = rules.find((rule) => rule.Name === route?.query?.name);
    } else if (route?.params?.rule) {
      rule = route.params.rule;
    }

    if (rule) {
      setRule(rule);
      setIsRuleActive(rule.IsActive);
    }
  }, [rules, route]);

  const editRule = async (data: IRulesFormData) => {
    const isTemplate = route?.params?.isTemplate;

    try {
      if (isTemplate) {
        await sendRequest('POST', `${baseURL}/rules/create`, { data }, null);
      } else {
        await sendRequest('POST', `${baseURL}/rules/${rule?.ID}`, { data }, null);
      }
    } catch (e: unknown) {
      logger.logError(
        isTemplate
          ? 'error_creating_new_automation_rule_template'
          : 'error_updating_automation_rule',
        e,
      );
      toast.error(e.message);
      throw e;
    }
  };

  const handleOnSubmit = async (data: IRulesFormData) => {
    if (!data) {
      return;
    }

    await editRule(data);

    router().push({ name: AUTOMATION });
  };

  const selectedRule = useMemo(
    () => ({
      Action: rule?.Action || sampleAction,
      Cluster: rule?.Cluster,
      Context: rule?.Context,
      Description: rule?.Description,
      LogsEnabled: rule?.LogsEnabled,
      Name: rule?.Name,
      ReportType: rule?.ReportType,
      Repository: rule?.Repository,
    }),
    [rule],
  );

  const getSuccessMessage = () => {
    if (!rule) {
      return '';
    }

    return strings.automationRule.toggleRuleSuccess
      .replace('$ruleName', rule.Name)
      .replace(
        '$ruleStatus',
        !isRuleActive
          ? strings.automationRule.enabled.toLowerCase()
          : strings.automationRule.disabled.toLowerCase(),
      );
  };

  const onRuleStatusChanged = async () => {
    if (!rule?.ID) {
      return;
    }

    try {
      await sendRequest('PATCH', `${baseURL}/rules/${rule.ID}/toggle`, {}, null);

      toast.success(getSuccessMessage());

      setIsRuleActive((prevState) => !prevState);
    } catch (e: unknown) {
      logger.logError('error_toggling_automation_rule_status', e);
      toast.error(strings.automationRule.toggleRuleFailed);
    }
  };

  const deleteRule = async () => {
    if (!rule?.ID) {
      return;
    }

    try {
      await sendRequest('DELETE', `${baseURL}/rules/${rule.ID}`, {}, null);

      setIsDeleteModalShown(false);

      toast.success(strings.automationRule.deleteRuleSuccess.replace('$ruleName', rule.Name));

      setTimeout(() => {
        router().push({ name: AUTOMATION });
      }, 2000);
    } catch (e: unknown) {
      logger.logError('error_deleting_automation_rule', e);
      toast.error(strings.automationRule.deleteRuleFailed);
    }
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: AUTOMATION,
      label: strings.navigation.Automation,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: strings.automationRule.rulesWizard,
      href: `${window.location.pathname}`,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="edit-automation__container">
        <div className="edit-automation__header-container">
          <Breadcrumbs
            data={breadcrumbsList}
            onClick={(route: string) => {
              handlePageChange(router, route);
            }}
          />
          {(!route?.params?.isTemplate || isOrgOwner) && (
            <div className="edit-automation__action-container">
              <div
                className="edit-automation__action edit-automation__delete"
                data-cy="delete-rule-button"
                onClick={() => setIsDeleteModalShown(true)}
              >
                <img src={trash} />
                <span>{strings.Delete}</span>
              </div>
              <div
                className="edit-automation__action"
                data-cy="enable-toggle-button"
                onClick={onRuleStatusChanged}
              >
                <Form.Check
                  aria-label="Toggle Rule Status"
                  checked={isRuleActive}
                  className="edit-automation__toggle-rule-status-btn"
                  type="switch"
                />
                <span>{strings.Enable}</span>
              </div>
            </div>
          )}
        </div>
        {!isOrgOwner && (
          <Alert variant="warning" className="warning-alert">
            <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
            <h4 className="warning-title">
              {strings.automationRule.onlyOwner} <strong>{strings.automationRule.readOnly}</strong>
            </h4>
          </Alert>
        )}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <RulesEditor
            handleOnSubmit={handleOnSubmit}
            isEdit
            route={route}
            rule={selectedRule}
            rules={rules}
            store={store}
          />
        )}
        <Toaster />
        <DeleteModal
          deleteFunction={deleteRule}
          name={strings.automationRule.rule}
          page={strings.automationRule.rule}
          setShowModal={(value: boolean) => setIsDeleteModalShown(value)}
          showModal={isDeleteModalShown}
          text={`${strings.automationRule.confirmDeletionOfRule} ${rule?.Name}`}
        />
      </div>
    </LayoutReact>
  );
};

export default EditAutomation;
