import React, { useState, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useTable, useSortBy, useFlexLayout } from 'react-table';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Card } from '@fairwindsops/ui-components';
import SortIcon from '../../Icons/SortIcon.icon.react';

import {
  Cluster,
  ClusterHealths,
  CurrentUser,
  Organization,
  SortStatus,
  UserFavorite,
  IRouter,
  ReportStatus,
} from '~globalTypes';

import {
  ClustersChartScore,
  HistoricalCluster,
  HistoricalClusterScore,
  ClusterHealthScoreSummary,
  TableAccessors,
} from './ClustersChart.types.react';

import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~logger';
import { CardTitlePrimary } from '~utils/texts.react';
import { getTableColumns } from './ClustersChartTable.config.react';

import './ClustersChart.react.scss';

dayjs.extend(timezone);

type ClustersChartProps = {
  organization: Organization;
  user: CurrentUser;
  retrievedScores?: ClusterHealths;
  historicalScores: HistoricalCluster[];
  clusters: Cluster[];
  isSummaryLoaded: boolean;
  router: () => IRouter;
};

const ClustersChart = ({
  organization,
  user,
  retrievedScores,
  clusters,
  isSummaryLoaded,
  router,
}: ClustersChartProps) => {
  const [userFavorites, setUserFavorites] = useState<UserFavorite[]>([]);
  const [scores, setScores] = useState<ClustersChartScore[]>();
  const [filteredScores, setFilteredScores] = useState<ClustersChartScore[]>();
  const [loadingSummary, setLoadingSummary] = useState<boolean>();

  const baseUrl = `/v0/organizations/${organization.Name}`;

  useEffect(() => {
    setLoadingSummary(true);
    if (isSummaryLoaded) {
      updateData();
    }
  }, [organization, retrievedScores, clusters, isSummaryLoaded]);

  useEffect(() => {
    const initializedScores = initScores();
    setScores(() => initializedScores);
    setFilteredScores(() => initializedScores);
  }, [clusters]);

  const initScores = () =>
    clusters.map((cluster: Cluster) => ({ Cluster: cluster.Name }) as ClustersChartScore);

  const updateData = async () => {
    let favorites: UserFavorite[];
    let updatedScores: ClustersChartScore[];
    try {
      favorites = await updateUserFavorites();
      updatedScores = await updateScores();
      updatedScores = updateScoreFavorites(updatedScores, favorites);
    } catch (e) {
      logger.logError('error_fetching_org_clusters_info', e);
      toast.error('Error retrieving clusters');
    }
    setUserFavorites(() => favorites);
    setScores(() => updatedScores);
    setFilteredScores(() => updatedScores);
    setLoadingSummary(false);
  };

  const updateUserFavorites = async () => {
    if (!user) return [];
    return await getUserFavorites();
  };

  const getUserFavorites = async () =>
    await sendRequest('GET', `${baseUrl}/user-favorites`, {}, null);

  const updateScores = async () => {
    const scores = getFromRetrievedScores();
    return transformScores(scores);
  };

  const getFromRetrievedScores = () =>
    (retrievedScores?.Clusters || []).map((clusterHealthScoreSummary: ClusterHealthScoreSummary) =>
      buildScoreObj(clusters, clusterHealthScoreSummary),
    );

  const buildScoreObj = (
    clusters: Cluster[],
    cluster: ClusterHealthScoreSummary,
  ): ClustersChartScore => ({
    favorite: false,
    Cluster: cluster.Name,
    version: clusters.find((c: Cluster) => c.Name === cluster.Name)?.Stats?.Version,
    passiveMode:
      clusters.find((c: Cluster) => c.Name === cluster.Name)?.AdmissionSettings?.passiveMode ||
      false,
    workloads: getReportStatusForReportType(clusters, cluster.Name, strings.workloads),
    admission: getReportStatusForReportType(clusters, cluster.Name, strings.admission),
    prometheus: getReportStatusForReportType(clusters, cluster.Name, strings.prometheusMetrics),
    nodesCount: clusters.find((c: Cluster) => c.Name === cluster.Name)?.Stats?.NodesCount || 0,
  });

  const getReportStatusForReportType = (
    clusterSummaries: Cluster[],
    clusterName: string,
    reportTypes: string,
  ): ReportStatus | undefined => {
    const clusterSummary = clusterSummaries.find(
      (cluster: Cluster) => cluster.Name === clusterName,
    );
    return clusterSummary
      ? clusterSummary.Reports.find((report: ReportStatus) => reportTypes === report.Name)
      : undefined;
  };

  const transformScores = (scores: ClustersChartScore[]): ClustersChartScore[] => {
    return scores.map((score: ClustersChartScore) => {
      return {
        ...score,
      };
    });
  };

  const calculateScoreDifference = (scores: HistoricalClusterScore | undefined): number => {
    if (!scores) return 0;
    const transformedScores = Object.entries(scores);
    const lastScore = transformedScores[transformedScores.length - 1][1] as unknown as number;
    const firstScore = transformedScores[0][1] as unknown as number;
    return Math.round(lastScore - firstScore);
  };

  const updateScoreFavorites = (
    scores: ClustersChartScore[],
    favorites: UserFavorite[],
  ): ClustersChartScore[] => {
    return scores.map((score: ClustersChartScore) => ({
      ...score,
      favorite: hasFavorite(score, favorites),
    }));
  };

  const hasFavorite = (cluster: ClustersChartScore, favorites: UserFavorite[]) => {
    return favorites.findIndex((favorite) => favorite.ResourceID === cluster.Cluster) > -1;
  };

  const onFilterUpdated = (e: React.BaseSyntheticEvent) => {
    const search = e.target as HTMLInputElement;
    setFilteredScores(() =>
      scores?.filter(
        (score: ClustersChartScore) => score.Cluster && score.Cluster.includes(search.value),
      ),
    );
  };

  const changeFavorite = async (item: ClustersChartScore) => {
    if (!item) return;
    const selectedFavorite = findSelectedFavorite(item);
    try {
      if (selectedFavorite) {
        await removeFavorite(selectedFavorite, item);
      } else {
        await addFavorite(item);
      }
    } catch (e) {
      logger.logError('error_updating_favorites', e);
    }
  };

  const findSelectedFavorite = (item: ClustersChartScore) => {
    if (!item) return;
    return userFavorites.find((favorite: UserFavorite) => favorite.ResourceID === item.Cluster);
  };

  const removeFavorite = async (selectedFavorite: UserFavorite, item: ClustersChartScore) => {
    item.favorite = false;
    await sendRequest('DELETE', `${baseUrl}/user-favorites/${selectedFavorite.ID}`, {}, null);
    updateUserFavoritesState(selectedFavorite, true);
    updateScoresState(item);
  };

  const addFavorite = async (item: ClustersChartScore) => {
    item.favorite = true;
    const insertedUserFavorite = await sendRequest(
      'POST',
      `${baseUrl}/user-favorites`,
      {
        data: {
          ResourceType: 'cluster',
          ResourceID: item.Cluster,
        },
      },
      null,
    );
    updateUserFavoritesState(insertedUserFavorite, false);
    updateScoresState(item);
  };

  const updateUserFavoritesState = (selectedFavorite: UserFavorite, isRemoved: boolean) => {
    setUserFavorites((prevUserFavorites) =>
      isRemoved
        ? prevUserFavorites.filter((favorite) => favorite.ID !== selectedFavorite.ID)
        : [...prevUserFavorites, selectedFavorite],
    );
  };

  const updateScoresState = (item: ClustersChartScore) => {
    setScores((prevScores) =>
      prevScores?.map((score) => {
        if (score.Cluster === item.Cluster) {
          return { ...score, favorite: item.favorite };
        }
        return score;
      }),
    );
    setFilteredScores((prevScores) =>
      prevScores?.map((score) => {
        if (score.Cluster === item.Cluster) {
          return { ...score, favorite: item.favorite };
        }
        return score;
      }),
    );
  };

  const columns = useMemo(
    () => getTableColumns({ organization, loadingSummary, router, changeFavorite }),
    [filteredScores, loadingSummary],
  );

  const getTableData = () => {
    if (!filteredScores || !filteredScores.length) return [];
    return filteredScores.map((score) => {
      const {
        favorite,
        Cluster,
        version,
        passiveMode,
        nodesCount,
        workloads,
        admission,
        prometheus,
      } = score;
      return {
        favorite,
        Cluster,
        version,
        passiveMode,
        nodesCount,
        workloads,
        admission,
        prometheus,
      };
    });
  };

  const data = useMemo(() => getTableData(), [filteredScores]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        sortBy: [
          {
            id: TableAccessors.Favorite,
            desc: true,
          },
        ],
      },
    },
    useFlexLayout,
    useSortBy,
  );

  return (
    <Card className="overview-clusters-card" data-cy="clusters-card">
      <Card.Title>
        <div className="overview-clusters-card__title">
          <div className="overview-clusters-card__left-title">
            <span className={CardTitlePrimary({ textTransform: strings.textStyling.capitalize })}>
              {strings.general.Clusters}
            </span>
          </div>
          <Form.Group>
            <Form.Control
              id="filter-input"
              onChange={onFilterUpdated}
              placeholder="Search for cluster"
              type="search"
              aria-label="filter input"
            />
          </Form.Group>
        </div>
      </Card.Title>
      <Card.Body className="overview-clusters-card__body">
        <div className="overview-clusters-card__body">
          <table
            {...getTableProps()}
            className="overview-clusters-card__table"
            aria-label="Clusters summary table"
            data-cy="clusters-info-table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`overview-clusters-card__header-container__${column.id}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="overview-clusters-card__header-container">
                        <span className="overview-clusters-card__sort-icon">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortIcon
                                sortStatus={SortStatus.Descending}
                                width="1rem"
                                height="1rem"
                              />
                            ) : (
                              <SortIcon
                                sortStatus={SortStatus.Ascending}
                                width="1rem"
                                height="1rem"
                              />
                            )
                          ) : (
                            <SortIcon sortStatus={SortStatus.None} width="1rem" height="1rem" />
                          )}
                        </span>
                        <span>{column.render('Header')}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="overview-clusters-card__body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className={`overview-clusters-card__cell-container__${cell.column.id}`}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ClustersChart;
