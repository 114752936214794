import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  Column,
  useAsyncDebounce,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  useResizeColumns,
  FilterTypes,
  TableInstance,
  useFlexLayout,
} from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import toast, { Toaster } from 'react-hot-toast';

import DescriptionPanel from '../components/DescriptionPanel/DescriptionPanel.react';
import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import SelectColumnFilter from '../components/SelectColumnFilter/SelectColumnFilter.react';
import Table from '~reactComponents/Table/Table.react';
import TableCardHeader from '../components/TableCardHeader/TableCardHeader.react';

import { ExecutionRuleFilters, ExecutionRulesFilter, DateRange, Execution } from './AutomationLogs.types.react';

import { IRoute, IRouter, IStore, DateType } from '~globalTypes';

import { PAGE_SIZE, TABLE_COLUMNS } from './AutomationLogs.config.react';

import { convertFiltersToURL, getCurrentTimezone, handlePageChange } from '~reactHelpers';

import { sendRequest } from '~utils/request';
import logger from '~logger';

import { strings } from '~utils/strings';

import 'react-datepicker/dist/react-datepicker.css';
import './AutomationLogs.react.scss';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { ORG_DASHBOARD, AUTOMATION } from '~reactComponents/NavigationReact/Navigation.config.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';

const timeZone = getCurrentTimezone();
const formatDatetime = (m: Dayjs): string => m.toISOString();

type AutomationLogsProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const AutomationLogs = ({ route, router, store }: AutomationLogsProps): JSX.Element => {
  const { organization } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const timeZone = getCurrentTimezone();

  const [executions, setExecutions] = useState<Execution[]>([]);
  const [selectedExecution, setSelectedExecution] = useState<Execution | null>(null);
  const executionFilters = useRef<ExecutionRuleFilters>();
  const initialFilters = useRef<ExecutionRulesFilter[]>([]);
  const [dateRange, setDateRange] = useState<DateType | null>(
    (() => {
      const searchParams = new URLSearchParams(window.location.search);
      const startDate = searchParams.get('startDate');
      const endDate = searchParams.get('endDate');
      return startDate && endDate ? { start: startDate, end: endDate } : null;
    })(),
  );
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [isLogEnabled, setIsLogEnabled] = useState<boolean>(false);

  const skipTableResetRef = useRef<boolean>();
  const sortBy = useRef<{ orderBy: string; desc: boolean }>({ orderBy: '', desc: false });

  useEffect(() => {
    initQueries();
  }, []);

  useEffect(() => {
    fetchExecutionsDebounced();
  }, [dateRange?.start, dateRange?.end]);

  useEffect(() => {
    fetchExecutionsDebounced();
  }, [globalSearch]);

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  useEffect(() => {
    if (initialFilters?.current.length > 0 && loaded) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, loaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filters = await sendRequest(
          'GET',
          `${baseURL}/rules/executions-filters`,
          { showErrorAlert: false },
          null,
        );
        executionFilters.current = {
          ...executionFilters.current,
          ...filters,
        };
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_automation_logs: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };
    fetchData();
  }, [organization]);

  const initQueries = () => {
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if ((navigation[0].type === 'reload' || navigation[0].type === 'navigate') && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('redirect', 'true');
      router()
        .replace({ path: `${route.path}?${searchParams.toString()}` })
        .catch(() => {});
    }
  };

  const filterByDate = (start?: Date | Dayjs, end?: Date | Dayjs) => {
    if (!start && !end) {
      setDateRange(null);
      return;
    }
    if (start && end) {
      const changedDates = {
        start: formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start)),
        end: formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end)),
      };
      setDateRange(changedDates);
    }
  };

  const ExecutionDatePicker = () => {
    return (
      <div>
        <Datepicker
          onDateSelect={filterByDate}
          minDate={120}
          emptyValueLabel="Filter"
          startDate={dateRange?.start}
          endDate={dateRange?.end}
          className="action-items-reports"
        />
      </div>
    );
  };

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter tableInstance={tableInstance} filters={executionFilters.current} />
  );

  const data = React.useMemo(() => {
    return executions;
  }, [executions]);

  const columns = React.useMemo<Column<Execution>[]>(
    () => TABLE_COLUMNS(ColumnFiltering, ExecutionDatePicker),
    [dateRange],
  );

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    const columnFilters: ExecutionRulesFilter[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0]) && item[0] !== 'startDate') {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: item[1],
              },
            ],
          });
        }
      } else {
        hydrateSecondaryFilters(item);
      }
    }
    initialFilters.current = columnFilters;
  };

  const hydrateSecondaryFilters = (item: [string, string]) => {
    switch (item[0]) {
      case 'search':
        setGlobalSearch(item[1]);
        break;
      case 'pageSize':
        tableInstance.state.pageSize = Number(item[1]) || PAGE_SIZE;
        break;
      case 'page': {
        tableInstance.state.pageIndex = Number(item[1]) || 0;
        tableInstance.gotoPage(Number(item[1]) || 0);
        break;
      }
      case 'orderBy': {
        sortBy.current = {
          ...sortBy.current,
          orderBy: item[0] === 'orderBy' ? item[1] : '',
        };
        break;
      }
      case 'desc': {
        sortBy.current = {
          ...sortBy.current,
          desc: item[1] === 'true',
        };
        if (sortBy.current.orderBy) {
          tableInstance.setSortBy([
            {
              id: sortBy.current.orderBy,
              desc: sortBy.current.desc,
            },
          ]);
        }
        break;
      }
      default:
        return;
    }
  };

  const fetchExecutions = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;

    let searchParams: URLSearchParams;

    if (!window.location.search.includes('redirect')) {
      searchParams = new URLSearchParams();
      searchParams.set('page', pageIndex.toString());
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
      searchParams.set('page', searchParams.get('page') ? (searchParams.get('page') as string) : pageIndex.toString());
    }

    if (!searchParams.has('pageSize')) {
      searchParams.set('pageSize', pageSize.toString());
    }

    if (!loaded) {
      hydrateColumnFilters(searchParams);
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    } else {
      searchParams.delete('search');
    }

    searchParams = convertFiltersToURL(filters, searchParams);

    if (sortBy[0]) {
      searchParams.set('orderBy', `${sortBy[0].id}`);
      searchParams.set('desc', `${sortBy[0].desc}`);
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('desc');
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    }

    if (dateRange?.start) {
      searchParams.set(
        'startDate',
        formatDatetime(timeZone ? dayjs(dateRange.start).tz(timeZone) : dayjs(dateRange.start)),
      );
    } else {
      searchParams.delete('startDate');
    }

    if (dateRange?.end) {
      searchParams.set('endDate', formatDatetime(timeZone ? dayjs(dateRange.end).tz(timeZone) : dayjs(dateRange.end)));
    } else {
      searchParams.delete('endDate');
    }

    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/rules/executions?${searchParams.toString()}`,
        { returnHeaders: true },
        null,
      );
      const data = response.data;
      setIsLogEnabled(data.enabled);
      setExecutions(data.executions);
      setTotalRowCount(data.total);
      tableInstance.pageCount = data.total;
      setLoaded(true);
      // Check if we are on the automation-logs page to avoid a redirect if the user has switched pages
      // before we get to this replace().
      if (router()?.currentRoute?.name === 'automation-logs') {
        router()
          .replace({ path: `${route.path}?${searchParams.toString()}` })
          .catch(() => {});
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_execution: ', e);
      toast.error('Error fetching automation logs');
    }
  }, [loaded, dateRange?.start, dateRange?.end, globalSearch]);

  const fetchExecutionsDebounced = useAsyncDebounce(fetchExecutions, 250);

  const filterTypes = React.useMemo<FilterTypes<Execution>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
      },
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
  );

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageIndex],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
    }
  };

  const LoggingInfo = () => (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id={`tooltip-action-items-reports-line-chart`}>
          <Popover.Content>{strings.automationRule.loggingInfo}</Popover.Content>
        </Popover>
      }
    >
      <InfoIcon />
    </OverlayTrigger>
  );

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: AUTOMATION,
      label: strings.navigation.Automation,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: 'Logs',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <Toaster />
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="automation-logs--container">
        <Card body className="automation-logs" data-cy="automation-logs-card">
          <TableCardHeader
            totalRowCount={totalRowCount}
            tableInstance={tableInstance}
            selectedExecution={selectedExecution}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
          />
          <LastSyncedDate
            className="automation-logs__logging-status"
            hasData={isLogEnabled}
            node={
              <span
                className={`automation-logs__logging-status-info ${
                  !isLogEnabled && 'automation-logs__logging-status-info--disabled'
                }`}
              >
                <LoggingInfo />
              </span>
            }
            noneSyncedDateTitle={strings.automationRule.loggingIsOff}
            syncedDateTitle={strings.automationRule.loggingIsOn}
          />
          {loaded && (
            <Table
              tableInstance={tableInstance}
              pagination={
                <Pagination
                  currentPage={tableInstance.state.pageIndex}
                  pageChange={handlePaginationChange}
                  totalCount={totalPage}
                />
              }
              onDataFetched={fetchExecutionsDebounced}
              onItemSelected={setSelectedExecution}
              caption="Automation logs table"
            />
          )}
          {!loaded && <LoadingSpinner />}
        </Card>
        {/* Description panel should be here */}
        {selectedExecution && (
          <DescriptionPanel
            organization={organization}
            selectedExecution={selectedExecution}
            setSelectedExecution={setSelectedExecution}
          />
        )}
      </div>
    </LayoutReact>
  );
};

export default AutomationLogs;
