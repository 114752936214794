import PoliciesList from './PoliciesList.react';

export default {
  name: 'opaPolicies',
  components: {
    'policies-list': PoliciesList,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
