import { UnControlled as CodeMirror } from '@leifandersen/react-codemirror2';
import React from 'react';
import { strings } from '~utils/strings';

import './ResourceRecommendation.react.scss';
import { LimitsType } from '../../Costs.types.react';
import toast from 'react-hot-toast';
import CopyIcon from '~reactComponents/Icons/CopyIcon.react';

type ResourceRecommendationProps = {
  limits?: LimitsType;
};

type section = 'settings' | 'recommendations';

function ResourceRecommendation({ limits }: ResourceRecommendationProps) {
  const codeSample = (section: section) => {
    if (limits && Object.keys(limits).length) {
      let sample = '';
      ['requests', 'limits'].forEach((codeSection) => {
        sample += codeSection === 'requests' ? `${codeSection}:` : `\n${codeSection}:`;
        ['cpu', 'memory'].forEach((type) => {
          if (limits[section][codeSection][type]) {
            sample += `\n  ${type}: ${limits[section][codeSection][type]}`;
          }
        });
      });
      return sample;
    }
    return '';
  };

  const copyCode = async () => {
    await navigator.clipboard.writeText(codeSample('recommendations'));
    toast.success(strings.efficiency.copiedValues);
  };

  const CodeSampleDisplay = ({ section }: { section: section }) => (
    <CodeMirror
      value={codeSample(section)}
      className="costs-code-mirror"
      options={{
        tabSize: 4,
        theme: 'material',
        lineNumbers: true,
        readOnly: true,
      }}
    />
  );

  return (
    <div className="resource-usage-code">
      <div className="resource-usage-code__container">
        <h2 className="resource-usage-code__title">{strings.efficiency.Current}</h2>
        <CodeSampleDisplay section="settings" />
      </div>
      <div className="resource-usage-code__container">
        <div className="resource-usage-code__header">
          <h2 className="resource-usage-code__title">{strings.efficiency.Recommended}</h2>
          <div className="resource-usage-code__copy-icon" onClick={copyCode}>
            <CopyIcon />
          </div>
        </div>
        <CodeSampleDisplay section="recommendations" />
      </div>
    </div>
  );
}

export default ResourceRecommendation;
