import React, { useEffect, useMemo, useState } from 'react';
import { NotificationPreference, Item } from './Notifications.types.react';
import Notification from '~reactComponents/Settings/Notifications/Notification.react';
import { LoadingSpinner } from '@fairwindsops/ui-components';
import toast from 'react-hot-toast';
import { OptionProps } from 'react-select';
import Select from 'react-select';
import { Cluster, IRoute, IStore, OptionType } from '~utils/global.types.react';
import logger from '~utils/logger';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import {
  MSTeamsIntegration,
  MSTeamsIntegrationPreferences,
  MSTeamsTeam,
} from '../integrations/Integrations.types.react';

const statuses = {
  idle: 'idle',
  pending: 'pending',
  rejected: 'rejected',
  resolved: 'resolved',
  tokenRejected: 'tokenRejected',
};

const allClusterOption = { value: 'All Clusters', label: 'All Clusters' };

type MSTeamsNotificationsProps = {
  route: IRoute;
  store: () => IStore;
};

export default function MSTeamsNotifications({ route, store }: MSTeamsNotificationsProps) {
  const org = route?.params?.org;
  const isOrgOwner = store().getters.isOrgOwner;
  const [isIntegrated, setIsIntegrated] = useState<boolean>(true);
  const [status, setStatus] = useState<string>(statuses.idle);

  const [notificationPreferences, setNotificationPreferences] = useState<NotificationPreference[]>([]);
  const [teams, setTeams] = useState<MSTeamsTeam[]>([]);

  const [clusterOptions, setClusterOptions] = useState<OptionType[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<OptionType[]>([]);
  const [channelsOptions, setChannelsOptions] = useState<OptionType[]>([]);

  const [selectedChannel, setSelectedChannel] = useState<OptionType | null>();
  const [selectedTeam, setSelectedTeam] = useState<OptionType | null>();
  const [selectedCluster, setSelectedCluster] = useState<OptionType | null>();

  const [stale, setStale] = useState<boolean>(false);

  useEffect(() => {
    refreshMSTeams();
  }, [route.path]);

  useEffect(() => {
    refreshPreferences();
  }, [isIntegrated]);

  useEffect(() => {
    if (!stale) return;
    const refreshAsync = async () => {
      await refreshPreferences();
      setStale(false);
    };
    refreshAsync();
  }, [stale]);

  useEffect(() => {
    if (!selectedTeam) {
      return;
    }

    const teamChannels = teams
      .find((team) => team.id === selectedTeam.value)
      ?.channels.map((channel: Item) => ({
        value: channel.id,
        label: channel.name,
      }));

    setChannelsOptions(teamChannels || []);
    setSelectedChannel(null);
  }, [selectedTeam]);

  useEffect(() => {
    setSelectedTeam(null);
    setSelectedChannel(null);
  }, [selectedCluster]);

  const allClusterSelected = selectedCluster === allClusterOption;
  const noFilterSelected = !selectedCluster && !selectedTeam && !selectedChannel;

  const refreshMSTeams = async () => {
    try {
      const data = (await sendRequest('GET', `${baseURL}/msteams/integration`, {}, null)) as MSTeamsIntegration;
      setIsIntegrated(data.IsAccessTokenSet);
      if (!data.IsAccessTokenSet) {
        setStatus(statuses.tokenRejected);
      }
      const mappedClusters = store().getters.clusters.map((cluster: Cluster) => ({
        value: cluster.Name,
        label: cluster.Name,
      }));
      setClusterOptions([allClusterOption].concat(mappedClusters));
    } catch (e) {
      logger.logError('error_get_msteams_integration', { message: e.message });
    }
  };

  const refreshPreferences = async () => {
    if (!isIntegrated) {
      setNotificationPreferences([]);
      setStatus(statuses.tokenRejected);
      return;
    }

    setStatus(statuses.pending);
    setSelectedChannel(null);
    setSelectedTeam(null);
    setNotificationPreferences([]);
    try {
      const data = (await sendRequest(
        'GET',
        `${baseURL}/msteams/integration/preferences`,
        {},
        null,
      )) as MSTeamsIntegrationPreferences;
      setTeams(data.Teams);
      setTeamsOptions(
        (data.Teams || []).map((team: MSTeamsTeam) => ({ value: team.id, label: team.name })).sort(sortTeams),
      );
      setNotificationPreferences(data.NotificationPreferences);
      setStatus(statuses.resolved);
    } catch (e) {
      logger.logError('error_get_msteams_settings', { message: e.message });
      setStatus(statuses.rejected);
    }
  };

  const updateNotifications = async (
    event: React.MouseEvent<HTMLElement>,
    context: NotificationPreference,
    selected: string[],
  ) => {
    event.preventDefault();
    const data = {
      ChannelID: context.ChannelID,
      TeamID: context.TeamID,
      Digest: selected.includes('daily'),
      Realtime: selected.includes('real'),
    };
    const url =
      context.Cluster && !baseURL.includes(`/clusters/${context.Cluster}`)
        ? `${baseURL}/clusters/${context.Cluster}`
        : baseURL;
    const teamName = teams.find((team) => team.id === context.TeamID)?.name;
    const channelName = teams
      .find((team) => team.id === context.TeamID)
      ?.channels.find((channel) => channel.id === context.ChannelID)?.name;
    const title = `${teamName} > ${channelName}`;
    try {
      await sendRequest('POST', `${url}/msteams/integration/preferences`, { data }, null);
      toast.success(
        <b>{`Success updating notifications for ${title} in ${context.Cluster ? `the ${context.Cluster} cluster` : 'All Clusters'}`}</b>,
      );
      setSelectedChannel(null);
      setSelectedTeam(null);
      setSelectedCluster(null);
    } catch (e) {
      logger.logError('error_updating_msteams_notification', e);
      toast.error(
        <b>{`Error adding notifications for ${title} in ${context.Cluster ? `the ${context.Cluster} cluster` : 'All Clusters'}`}</b>,
      );
    }
    setStale(true);
  };

  const handleClusterSelection = (selected: unknown) => {
    if (!selected) {
      setSelectedCluster(null);
    } else {
      setSelectedCluster(selected as OptionType);
    }
  };

  const handleChannelSelection = (selected: unknown) => {
    if (!selected) {
      setSelectedChannel(null);
    } else {
      setSelectedChannel(selected as OptionType);
    }
  };

  const handleTeamSelection = (selected: unknown) => {
    if (!selected) {
      setSelectedTeam(null);
    } else {
      setSelectedTeam(selected as OptionType);
    }
  };

  const Option = (props: OptionProps<OptionType, true>) => {
    return (
      <div className="select-checkbox" onClick={() => props.selectOption(props.data)}>
        <label>
          <input type="checkbox" id={`${props?.label}-checkbox`} defaultChecked={props.isSelected} />
          <span />
        </label>
        <span>{props.label}</span>
      </div>
    );
  };

  const baseURL = useMemo(() => {
    let base = '';
    if (!selectedCluster?.value || selectedCluster === allClusterOption) {
      base = `/v0/organizations/${org}`;
    } else {
      base = `/v0/organizations/${org}/clusters/${selectedCluster.value}`;
    }
    return base;
  }, [selectedCluster?.value]);

  const allChannels: NotificationPreference[] = useMemo(() => {
    if (!notificationPreferences) {
      return [];
    }
    if (selectedCluster && selectedTeam && selectedChannel) {
      const item = notificationPreferences
        .filter((notificationPreference) => !notificationPreference.Cluster)
        .filter((notificationPreference) => notificationPreference.TeamID === selectedTeam.value)
        .find((notificationPreference) => notificationPreference.ChannelID === selectedChannel.value);

      if (item) {
        return [item];
      }
      return [
        {
          ID: 0,
          NotificationIntegration: 0,
          Organization: org,
          TeamID: selectedTeam.value,
          ChannelID: selectedChannel.value,
          Cluster: '', // all clusters
          Digest: false,
          Realtime: false,
          DatadogCostMetrics: false,
        },
      ];
    }

    if (selectedCluster && selectedTeam) {
      return notificationPreferences
        .filter((notificationPreference) => !notificationPreference.Cluster)
        .filter((notificationPreference) => notificationPreference.TeamID === selectedTeam.value);
    }

    if (selectedCluster) {
      return notificationPreferences.filter((notificationPreference) => !notificationPreference.Cluster);
    }

    return notificationPreferences;
  }, [selectedCluster?.value, selectedTeam?.value, selectedChannel?.value, notificationPreferences]);

  const clusterChannels: NotificationPreference[] = useMemo(() => {
    if (!notificationPreferences) {
      return [];
    }
    if (selectedCluster && selectedTeam && selectedChannel) {
      const item = notificationPreferences
        .filter((notificationPreference) => notificationPreference.Cluster === selectedCluster.value)
        .filter((notificationPreference) => notificationPreference.TeamID === selectedTeam.value)
        .find((notificationPreference) => notificationPreference.ChannelID === selectedChannel.value);

      if (item) {
        return [item];
      }
      return [
        {
          ID: 0,
          NotificationIntegration: 0,
          Organization: org,
          TeamID: selectedTeam.value,
          ChannelID: selectedChannel.value,
          Cluster: selectedCluster.value,
          Digest: false,
          Realtime: false,
          DatadogCostMetrics: false,
        },
      ];
    }

    if (selectedCluster && selectedTeam) {
      return notificationPreferences
        .filter((notificationPreference) => notificationPreference.Cluster === selectedCluster.value)
        .filter((notificationPreference) => notificationPreference.TeamID === selectedTeam.value);
    }

    if (selectedCluster) {
      return notificationPreferences.filter(
        (notificationPreference) => notificationPreference.Cluster === selectedCluster.value,
      );
    }

    return notificationPreferences;
  }, [selectedCluster?.value, selectedTeam?.value, selectedChannel?.value, notificationPreferences]);

  const sortTeams = (a: OptionType, b: OptionType) => {
    if (a.label) {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    }
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  };

  const sortPreferences = (a: NotificationPreference, b: NotificationPreference) => {
    if (!a.Cluster && b.Cluster) return -1;
    if (a.Cluster && !b.Cluster) return 1;

    if (a.name && b.name) {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
    }
    if (a.ID < b.ID) return -1;
    if (a.ID > b.ID) return 1;
    return 0;
  };

  const formatTitle = (context: NotificationPreference, teams: MSTeamsTeam[]) => {
    const teamName = teams.find((team) => team.id === context.TeamID)?.name;
    const channelName = teams
      .find((team) => team.id === context.TeamID)
      ?.channels.find((channel) => channel.id === context.ChannelID)?.name;
    const clusterName = context?.Cluster ? context.Cluster : strings.navigation.allClusters;
    return `${clusterName} > ${teamName} > ${channelName}`;
  };

  return (
    <>
      {!isIntegrated && (
        <div className="notifications-page-message" data-cy="no-integration">
          <span>You do not have MS Teams integrated</span>
          <span>Please go to the integrations tab to add MS Teams to allow Action Item notifications.</span>
        </div>
      )}
      {isIntegrated && isOrgOwner && (
        <>
          {status === strings.noTranslate.resolved && (
            <div className="notifications-page-dropdowns">
              <div className="notifications-page-dropdown-container">
                <h2 className="notifications-page-dropdown-container__title" data-cy="clusters-title">
                  Select the cluster where you would like to receive notifications
                </h2>
                <Select
                  className="clusters-dropdown-input"
                  classNamePrefix="custom_select"
                  components={{ Option }}
                  aria-label="cluster-select-dropdown"
                  isSearchable
                  isClearable
                  options={clusterOptions}
                  value={selectedCluster}
                  onChange={handleClusterSelection}
                />
              </div>
              <div className="notifications-page-dropdown-container">
                <h2 className="notifications-page-dropdown-container__title">
                  Select the team where you would like to receive notifications
                </h2>
                <Select
                  components={{ Option }}
                  isSearchable
                  isClearable
                  isDisabled={!selectedCluster}
                  aria-label="msteams-team-select-dropdown"
                  onChange={handleTeamSelection}
                  options={teamsOptions}
                  value={selectedTeam}
                />
              </div>
              <div className="notifications-page-dropdown-container">
                <h2 className="notifications-page-dropdown-container__title">
                  Select the channel where you would like to receive notifications
                </h2>
                <Select
                  components={{ Option }}
                  isSearchable
                  isClearable
                  isDisabled={!selectedTeam}
                  aria-label="msteams-channel-select-dropdown"
                  onChange={handleChannelSelection}
                  options={channelsOptions}
                  value={selectedChannel}
                />
              </div>
            </div>
          )}
          <div className="notifications-page-display">
            {(status === 'idle' || status === 'pending') && (
              <div className="notifications-page-display__loading">
                <LoadingSpinner />
              </div>
            )}
            {status === strings.noTranslate.resolved && (
              <>
                {(noFilterSelected ? notificationPreferences : allClusterSelected ? allChannels : clusterChannels)
                  .sort(sortPreferences)
                  .map((preference) => (
                    <Notification
                      title={formatTitle(preference, teams)}
                      context={preference}
                      preferences={notificationPreferences}
                      updateNotifications={updateNotifications}
                      key={`${preference.Cluster ? preference.Cluster : 'all'}-${preference.ChannelID}`}
                    />
                  ))}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
