import { OptionType } from '~utils/global.types.react';
import { strings } from '~utils/strings';

export const Labels = {
  notStarted: 'not_started',
  manual: 'manual',
  automated: 'automated',
} as const;

export const defaultAnswerOptions: OptionType[] = [
  { value: 'yes', label: strings.general.Yes },
  { value: 'no', label: strings.general.No },
  { value: 'not_in_scope', label: strings.checkStatus.notInScope },
  { value: 'automated', label: 'Automated' },
];

export const statusMap: Record<string, string[]> = {
  yes: ['passing', 'needs_evidence'],
  not_in_scope: ['not_in_scope'],
  no: ['failing'],
};

export const payloadMap: Record<string, string> = {
  yes: 'passing',
  no: 'failing',
  not_in_scope: 'not_in_scope',
};
