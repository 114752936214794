export interface RepositoryItem {
  repository: string;
}

export interface TableColumnsParams {
  onRepoSelected: (repository: RepositoryItem) => any;
  isOrgOwner: boolean;
}

export enum TableColumnNames {
  Repository = 'Repository',
  Delete = 'Delete',
}

export enum TableAccessors {
  Repository = 'repository',
}
