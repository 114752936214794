import { strings } from '~utils/strings';

import OrganizationsList from './OrganizationsList.react';

export default {
  name: 'organizations',
  components: {
    'organizations-list': OrganizationsList,
  },
  metaInfo() {
    return {
      title: strings.general.appTitle,
      meta: [
        {
          name: 'description',
          content: strings.general.appDescriptionContent,
        },
      ],
    };
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
