import React from 'react';
import { StylesConfig } from 'react-select';
import { Column, TableInstance } from 'react-table';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import Tag from '~reactComponents/Tags/Tag.react';

import { AffectedImage, ImageDetailItem, Labels } from './ImageDetailItemsTable.types.react';
import { IRouter } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { RESIZED_WIDTH_STORAGE_KEYS } from '~reactHelpers';

export const PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250];

export const SELECT_STYLES: StylesConfig<unknown, true> = {
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

export const TABLE_COLUMNS = (
  ColumnFiltering: (tableInstance: TableInstance) => JSX.Element,
): Column<ImageDetailItem>[] => {
  const resizedWith = JSON.parse(
    localStorage.getItem(RESIZED_WIDTH_STORAGE_KEYS.imageDetailPage) || '{}',
  );

  return [
    {
      Header: 'CVE ID',
      accessor: 'code',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.code}>{instance.row.original.code}</span>
      ),
      width: resizedWith['code'] || 350,
      minWidth: 150,
      maxWidth: 500,
    },
    {
      Header: 'Title',
      accessor: 'title',
      Filter: ColumnFiltering,
      className: 'no-overflow',
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.title}>{instance.row.original.title}</span>
      ),
      width: resizedWith['title'] || 250,
      minWidth: 130,
      maxWidth: 250,
    },
    {
      Header: 'Severity',
      accessor: 'severityLabel',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => {
        let severityData = instance.row.original.severityLabel;
        if (severityData?.length) {
          severityData = severityData.toLowerCase();
          const severityLabel = severityData?.length
            ? `${severityData.charAt(0).toUpperCase()}${severityData.slice(1)}`
            : severityData;
          return <PillBadge text={severityLabel} />;
        }
        return <></>;
      },
      width: resizedWith['severityLabel'] || 150,
      minWidth: 150,
      maxWidth: 200,
    },
    {
      Header: 'Package',
      accessor: 'package',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.package}>{instance.row.original.package}</span>
      ),
      width: resizedWith['package'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Installed',
      accessor: 'installed',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => (
        <span title={instance.row.original.installed}>{instance.row.original.installed}</span>
      ),
      width: resizedWith['installed'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
    {
      Header: 'Fixed',
      accessor: 'hasFixedVersion',
      className: 'no-overflow',
      Filter: ColumnFiltering,
      Cell: (instance: TableInstance) => {
        const fixed = instance.row.original.fixed
          ? instance.row.original.fixed
          : Labels.Unavailable;
        return <span title={fixed}>{fixed}</span>;
      },
      width: resizedWith['hasFixedVersion'] || 150,
      minWidth: 130,
      maxWidth: 200,
    },
  ];
};

export const COLUMNS_ORDER = ['CVE ID', 'Title', 'Severity', 'Package', 'Installed', 'Fixed'];

export const TOP_ROW = (selectedImageDetailItem: ImageDetailItem | null) => [
  {
    title: 'ID',
    content: getIDContent(selectedImageDetailItem?.code),
  },
  { title: 'Package', content: selectedImageDetailItem?.package },
  {
    title: 'Installed Version',
    content: selectedImageDetailItem?.installed,
  },
  { title: 'Fixed Version', content: selectedImageDetailItem?.fixed },
];

const getIDContent = (code: string | undefined) => {
  if (!code) return null;
  if (code.startsWith('CVE-') || code.startsWith('DLA-')) {
    const cveLink = code.startsWith('CVE-')
      ? `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${code}`
      : `https://security-tracker.debian.org/tracker/${code}`;
    return (
      <a href={cveLink} target="_blank">
        {code}
      </a>
    );
  }
  return code;
};

export const CONTENT = (
  selectedImageDetailItem: ImageDetailItem | null,
  affectedImages: AffectedImage[],
  organizationName: string,
  router: () => IRouter,
) => [
  { title: 'Title', content: selectedImageDetailItem?.title },
  { title: 'Description', content: selectedImageDetailItem?.description },
  {
    title: 'Affected Images',
    content: renderAffectedImages(affectedImages, organizationName, router),
    isJSX: true,
  },
];

const renderAffectedImages = (
  affectedImages: AffectedImage[],
  organizationName: string,
  router: () => IRouter,
) => {
  if (!affectedImages?.length) return <></>;

  const goToImageDetail = (image: AffectedImage) => () => {
    router().push({
      name: `react-vulnerabilities-image-detail`,
      params: {
        org: organizationName,
        selectedImage: image.SHA,
      },
      query: {
        resolved: false,
        tag: image.Tag,
      },
    });
    window.location.reload();
  };

  return (
    <div className="affected-images-container">
      {affectedImages.map((image: AffectedImage) => {
        const nClusters = image?.ClustersCount || 0;
        return (
          <div onClick={goToImageDetail(image)}>
            <Tag tagClassNames="tag-ellipsis vulnerabilities-tag">{`${image.Name} (${nClusters} ${
              nClusters <= 1 ? strings.vulnerabilities.cluster : strings.vulnerabilities.clusters
            })`}</Tag>
          </div>
        );
      })}
    </div>
  );
};
