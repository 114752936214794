import React from 'react';
import { Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import DOMPurify from 'dompurify';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import { useContextMenu, TriggerEvent } from 'react-contexify';

import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';

import { AdmissionActionItemType, AdmissionRequestType } from '../../ReactAdmission.types.react';
import { escapeEvent } from '~reactHelpers';
import { strings } from '~utils/strings';
import { useWindowEvents } from '~hooks/useWindowEvents.react';
import { getCurrentTimezone } from '~reactHelpers';

import './ActionItem.react.scss';
import { ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID } from '../DescriptionPanel/DescriptionPanel.config.react';
import ContextMenu from '../DescriptionPanel/ContextMenu.react';
import { IRoute, IRouter, IStore } from '~utils/global.types.react';
import toast from 'react-hot-toast';
import { ADMISSION_ACTION_ITEM_RESOLUTIONS } from '~utils/constants';
import ActionItemInfoPanel from './ActionItemInfoPanel.react';

type ActionItemProps = {
  item: AdmissionActionItemType;
  date: string;
  closePanel: () => void;
  router: () => IRouter;
  route: IRoute;
  store: () => IStore;
  request: AdmissionRequestType;
  reloadActionItem: (item: AdmissionActionItemType) => void;
};

dayjs.extend(utc);
dayjs.extend(timezone);

const ActionItem = ({
  item,
  date,
  closePanel,
  router,
  route,
  store,
  request,
  reloadActionItem,
}: ActionItemProps): JSX.Element => {
  useWindowEvents([
    escapeEvent(() => {
      closePanel();
    }),
  ]);

  const contents = [
    { title: 'Description', content: DOMPurify.sanitize(item.Description || '') },
    {
      title: 'Result Details',
      content:
        DOMPurify.sanitize(item.ResultDetails || '') +
        DOMPurify.sanitize(
          item.MatchedAppGroupPolicyMapping
            ? `\n\nMatched AppGroup/PolicyMapping: \`${item.MatchedAppGroupPolicyMapping}\``
            : '',
        ),
    },
    { title: 'Remediation', content: DOMPurify.sanitize(item.Remediation || '') },
  ];

  const openContextMenu = (e: TriggerEvent) => {
    return showContextMenu({ event: e, props: { item } });
  };

  const { show: showContextMenu } = useContextMenu({
    id: ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID,
  });

  const timeZone = getCurrentTimezone();

  const itemTitle = () => {
    return item.Title.length <= 64 ? item.Title : item.Title.slice(0, 64).concat('...');
  };

  return (
    <>
      <span className="admission-item-close-panel" onClick={closePanel} data-cy="admission-action-item-close-button">
        <CloseIcon width="1rem" height="1rem" />
      </span>
      <Card.Title className="admission-item-card-title">
        <h1 className="admission-item-title">{itemTitle()}</h1>
        <div className="admission-item-tags">
          <PillBadge severity={item.Severity} />
          <PillBadge color="low" text={item?.Category} />
        </div>
        {!store().getters.isOrgOwner || !request.CanBeResolved ? null : (
          <button
            className="additional-actions-btn"
            onClick={(e) => openContextMenu(e)}
            data-cy="additional-actions-button"
          >
            <img src={HorizontalEllipsis} alt="ellipsis icon" />
          </button>
        )}
      </Card.Title>
      <div className="admission-item-meta">
        <div>
          <h2 className="admission-item-meta-title">{strings.general.Reported}</h2>
          <h3 className="admission-item-meta-content">
            {timeZone
              ? dayjs(date).utc().local().tz(timeZone).format('MM/DD/YYYY')
              : dayjs(date).utc().local().format('MM/DD/YYYY')}{' '}
            {strings.general.at}{' '}
            {timeZone
              ? dayjs(date).utc().local().tz(timeZone).format('hh:mm A')
              : dayjs(date).utc().local().format('hh:mm A')}
          </h3>
        </div>
        <div>
          <h2 className="admission-item-meta-title">{strings.general.reportedBy}</h2>
          <h3 className="admission-item-meta-content">{strings.admissionController.serviceAccount}</h3>
        </div>
        <div>
          <h2 className="admission-item-meta-title">{strings.columnTitles.reportType}</h2>
          <h3 className="admission-item-meta-content">{item.ReportType}</h3>
        </div>
        <div>
          <h2 className="admission-item-meta-title">{strings.columnTitles.Resolution}</h2>
          <h3 className="admission-item-meta-content">
            {
              ADMISSION_ACTION_ITEM_RESOLUTIONS.find((element) => {
                return element.value === item.HistoricalResolution;
              })?.label
            }
          </h3>
        </div>
        {item?.Status !== undefined && item?.Status !== '' ? (
          <div>
            <h2 className="admission-item-meta-title">{strings.columnTitles.Status}</h2>
            <h3 className="admission-item-meta-content">
              {item.Status === 'success'
                ? strings.general.Pass
                : item.Status === strings.general.PassiveFailure
                  ? strings.general.PassiveFail
                  : strings.general.Blocked}
            </h3>
          </div>
        ) : null}
        {item.Resolution !== '' ? (
          <div>
            <h2 className="admission-item-meta-title">{strings.columnTitles.Message}</h2>
            <h3 className="admission-item-meta-content">
              {' '}
              {strings.columnTitles.ResolvedAdmissionMessage}
              {' ' +
                ADMISSION_ACTION_ITEM_RESOLUTIONS.find((element) => {
                  return element.value === item.Resolution;
                })?.label}
            </h3>
          </div>
        ) : null}
        {item.SnoozeUntil ? (
          <div>
            <h2 className="admission-item-meta-title">{strings.columnTitles.SnoozedUntil}</h2>
            <h3 className="admission-item-meta-content">
              {timeZone
                ? dayjs(item.SnoozeUntil).utc().local().tz(timeZone).format('MM/DD/YYYY')
                : dayjs(item.SnoozeUntil).utc().local().format('MM/DD/YYYY')}
            </h3>
          </div>
        ) : null}
      </div>
      {contents.length > 0 && <ActionItemInfoPanel contents={contents} />}
      <ContextMenu route={route} router={router} toast={toast} reloadActionItem={reloadActionItem} item={item} />
    </>
  );
};

export default ActionItem;
