import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Button, Form, InputGroup, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import toast, { Toaster } from 'react-hot-toast';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import Option from '~reactComponents/OptionComponent/OptionComponent.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import { DropdownIndicator } from '~reactComponents/DropdownIndicator/DropdownIndicator.react';

import {
  defaultInstance,
  validationSchema,
  customizingNumbersOptions,
  customStyles,
} from './CostsSettings.config.react';
import { settingsBreadcrumbsList } from '../Costs.config.react';
import { getSyncDate } from '../Costs.helpers.react';

import { CostsProps, InstanceType, WorkloadsSettingsType, SettingsInputData } from '../Costs.types.react';
import { OptionType } from '~globalTypes';
import { handlePageChange, hasKey } from '~utils/global.helpers.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import './CostsSettings.react.scss';
import QoSFormGroup from './QoSFormGroup.react';

const CostsSettings = ({ route, router, store }: CostsProps) => {
  const org = route?.params?.org;
  const cluster = store().getters.cluster?.Name || route?.query?.Cluster;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<SettingsInputData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      instanceType: {},
      cloudProvider: {},
      QoS: '',
      costs: {
        CPUPerHour: 0,
        GBPerHour: 0,
        DiskPerMonth: 0,
        GBPerTransmit: 0,
        GBPerReceived: 0,
      },
    },
  });

  const [cloudProviders, setCloudProviders] = useState<OptionType[]>([]);
  const [cloudProvider, setCloudProvider] = useState<string>('');
  const [instanceTypes, setInstanceTypes] = useState<InstanceType[]>([]);
  const [syncedDates, setSyncedDates] = useState<Record<string, string>>({});
  const [selectedInstance, setSelectedInstance] = useState<InstanceType | null>(null);
  const [settings, setSettings] = useState<WorkloadsSettingsType | Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getDataOnLoad = async () => {
      setIsLoading(true);
      let retrievedInstanceTypes;
      let retrievedSettings: WorkloadsSettingsType;

      getSyncDate(cluster, org, setSyncedDates);

      try {
        retrievedInstanceTypes = await sendRequest('GET', '/v0/instance-types', {}, null);
        retrievedSettings = await sendRequest(
          'GET',
          `/v0/organizations/${org}/clusters/${cluster}/workload-settings`,
          {},
          null,
        );
      } catch (e) {
        logger.logError('error_retrieving_costs_settings', e);
        toast.error(strings.efficiency.unableRetrieveSettings);
      }

      if (retrievedInstanceTypes) {
        setInstanceTypes(retrievedInstanceTypes);
        setCloudProviders(
          [
            ...new Set(
              retrievedInstanceTypes
                .sort((i1: InstanceType, i2: InstanceType) => (i1.CloudProvider < i2.CloudProvider ? -1 : 1))
                .map((provider: InstanceType) => provider.CloudProvider),
            ),
          ].map((value: string) => ({ value: value, label: value })),
        );
      }
      if (retrievedInstanceTypes && retrievedSettings) {
        findMatchingInstance(retrievedInstanceTypes, retrievedSettings);
      } else {
        setSelectedInstance(defaultInstance);
        setValue('cloudProvider', {
          label: strings.efficiency.cloudProvider,
          value: strings.efficiency.cloudProvider,
        });
        setValue('instanceType', {
          provider: strings.efficiency.cloudProvider,
          label: '',
          value: 0,
        });
        setValue('QoS', 'guaranteed');
        setValue('costs', {
          CPUPerHour: 0,
          GBPerHour: 0,
          GBPerTransmit: 0,
          GBPerReceived: 0,
          DiskPerMonth: 0,
        });
      }
      setIsLoading(false);
    };

    if (cluster) getDataOnLoad();
  }, [cluster]);

  const instanceOptions = useMemo(() => {
    return [...instanceTypes]
      .filter((instance) => instance.CloudProvider === cloudProvider)
      .map((type) => ({
        provider: type.CloudProvider,
        value: type.ID,
        label: `${type.Name} ${type.PlanType ? `(${type.PlanType})` : ''}`,
      }));
  }, [cloudProvider]);

  const findMatchingInstance = (
    instances: InstanceType[],
    retrievedSettings: WorkloadsSettingsType | Record<string, any>,
  ) => {
    const sortedInstances = instances.sort((i1: InstanceType, i2: InstanceType) => (i1.Name < i2.Name ? -1 : 1));

    const matchingInstance = sortedInstances.find(
      (instance: InstanceType) => instance.ID === retrievedSettings.InstanceTypeID,
    );

    setSettings(retrievedSettings);

    if (matchingInstance) {
      setCloudProvider(matchingInstance.CloudProvider);
      setSelectedInstance(matchingInstance);
      setValue('instanceType', {
        provider: matchingInstance.CloudProvider,
        value: matchingInstance.ID,
        label: `${matchingInstance.Name} ${matchingInstance.PlanType ? `(${matchingInstance.PlanType})` : ''}`,
      });
      setValue('cloudProvider', {
        label: matchingInstance.CloudProvider,
        value: matchingInstance.CloudProvider,
      });
    } else {
      setSelectedInstance(defaultInstance);
      setValue('cloudProvider', {
        label: strings.efficiency.cloudProvider,
        value: strings.efficiency.cloudProvider,
      });
      setValue('instanceType', {
        provider: strings.efficiency.cloudProvider,
        label: '',
        value: 0,
      });
    }

    setValue('QoS', retrievedSettings.Qos || strings.efficiency.guaranteedQoS);
    setValue('costs', {
      CPUPerHour: parseFloat(retrievedSettings.CPUCostPerCorePerHour.toFixed(10)),
      GBPerHour: parseFloat(retrievedSettings.MemoryCostPerGigabytePerHour.toFixed(10)),
      GBPerTransmit: parseFloat(retrievedSettings.NetworkTransmitCostPerGigabyte.toFixed(10)),
      GBPerReceived: parseFloat(retrievedSettings.NetworkReceiveCostPerGigabyte.toFixed(10)),
      DiskPerMonth: parseFloat(retrievedSettings.StorageCostPerGigabytePerMonth.toFixed(10)),
    });
  };

  const handleOnSubmit = handleSubmit(async (data) => {
    if (data.instanceType.provider !== data.cloudProvider.value) {
      return toast.error(strings.efficiency.needInstanceType);
    }

    const dataToSend = {
      InstanceTypeID: data.instanceType.value === 0 ? null : data.instanceType.value,
      Qos: data.QoS,
      CPUCostPerCorePerHour: data.costs.CPUPerHour || settings?.CPUCostPerCorePerHour,
      MemoryCostPerGigabytePerHour: data.costs.GBPerHour || settings?.MemoryCostPerGigabytePerHour,
      NetworkTransmitCostPerGigabyte: data.costs.GBPerTransmit || settings?.NetworkTransmitCostPerGigabyte,
      NetworkReceiveCostPerGigabyte: data.costs.GBPerReceived || settings?.NetworkReceiveCostPerGigabyte,
      StorageCostPerGigabytePerMonth: data.costs.DiskPerMonth || settings?.StorageCostPerGigabytePerMonth,
      instance: selectedInstance,
    };

    try {
      await sendRequest(
        'POST',
        `/v0/organizations/${org}/clusters/${cluster}/workload-settings`,
        { data: dataToSend },
        null,
      );
      toast.success(strings.efficiency.settingsChanged);
    } catch (e) {
      logger.logError('error_changing_costs_settings', e);
      toast.error(strings.efficiency.unableProcessChanges);
    }
  });

  const resetToDefault = async () => {
    let response;
    try {
      response = await sendRequest(
        'DELETE',
        `/v0/organizations/${org}/clusters/${cluster}/workload-settings`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_deleting_workload_settings', e);
      toast.error(strings.efficiency.unableResetDefaults);
    }

    if (response.Success) {
      let retrievedSettings;
      try {
        retrievedSettings = await sendRequest(
          'GET',
          `/v0/organizations/${org}/clusters/${cluster}/workload-settings`,
          {},
          null,
        );
      } catch (e) {
        logger.logError('error_retrieving_workload_settings', e);
        toast.error(strings.efficiency.unableResetDefaults);
      } finally {
        findMatchingInstance(instanceTypes, retrievedSettings);
      }
    }
  };

  const CustomizedNumbersOptions = (): JSX.Element => {
    const toDisplay = customizingNumbersOptions.map((option) => {
      return (
        <div className="customize-numbers-section" key={`${option}-section`}>
          <Form.Label className="costs-settings__labels" id={`${option} input`}>
            {strings.efficiency[option]}
          </Form.Label>
          <>
            {hasKey(strings.efficiency, `${option}Tooltip`) && (
              <OverlayTrigger
                key={option}
                placement="right"
                overlay={
                  <Popover id={`tooltip-${option}`}>
                    <Popover.Content>{strings.efficiency[`${option}Tooltip`]}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon style={{ margin: '0 1rem 1rem 0.5rem' }} />
              </OverlayTrigger>
            )}
          </>
          <>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>$</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                autoFocus
                className="column-placement"
                type="number"
                min="0"
                step="0.0001"
                id={option}
                aria-label={`${option} input`}
                aria-labelledby={`${option} input`}
                title={`${option} input`}
                {...register(`costs.${option}`)}
                disabled={syncedDates?.syncedAWSDate?.length > 0 || syncedDates?.syncedCloudCostsDate?.length > 0}
              />
            </InputGroup>
            {errors?.costs && (
              <Form.Control.Feedback type="invalid">{errors?.costs[option]?.message}</Form.Control.Feedback>
            )}
          </>
        </div>
      );
    });

    return <>{toDisplay}</>;
  };

  return (
    <LayoutReact className="costs-settings">
      <Breadcrumbs
        data={settingsBreadcrumbsList(org, cluster)}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!cluster && (
        <div className="costs-settings__no-cluster">
          <h2 className="costs-settings__no-cluster-title">{strings.efficiency.selectACluster}</h2>
        </div>
      )}
      {cluster && (
        <>
          {isLoading && (
            <LastSyncedDate loading={true} noneSyncedDateTitle="Loading..." className="costs-settings__last-synced" />
          )}
          {!isLoading && syncedDates?.syncedAWSDate && (
            <LastSyncedDate
              syncedDate={syncedDates?.syncedAWSDate}
              syncedDateTitle={strings.efficiency.lastSyncedWithAWS}
              className="costs-settings__last-synced"
            />
          )}
          {!isLoading &&
            !syncedDates?.syncedAWSDate &&
            syncedDates?.syncedCloudCostsDate?.includes(strings.general.at) && (
              <LastSyncedDate
                syncedDate={syncedDates?.syncedCloudCostsDate}
                syncedDateTitle={strings.efficiency.lastSyncedWithCloudCosts}
                className="costs-settings__last-synced"
              />
            )}

          {!isLoading && !syncedDates?.syncedAWSDate && !syncedDates?.syncedCloudCostsDate?.length && (
            <LastSyncedDate
              noneSyncedDateTitle={strings.efficiency.cloudCostsNotInstalled}
              node={
                <div className="costs-settings__aws-info-icon">
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Popover id="costs-settings_no-aws">
                        <PopoverContent>{strings.efficiency.cloudCostsTooltip}</PopoverContent>
                      </Popover>
                    }
                  >
                    <InfoIcon />
                  </OverlayTrigger>
                </div>
              }
              className="costs-settings__last-synced"
            />
          )}

          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <Form className="costs-settings__form" onSubmit={handleOnSubmit} id="costs-settings-form" noValidate>
              <h1 className="costs-settings__title">{strings.efficiency.costsDetails}</h1>
              <div className="costs-settings__qos">
                <h3 className="costs-settings__form-labels">{strings.efficiency.defaultQoS}</h3>
                <QoSFormGroup register={register} />
              </div>
              <h1 className="costs-settings__title">{strings.efficiency.customizingNumbers}</h1>
              {syncedDates?.syncedCloudCostsDate?.length > 0 && (
                <h2 className="costs-settings__subtitle">{strings.efficiency.costCustomizingSubtitle}</h2>
              )}
              <div>
                <Form.Group>
                  <div className="costs-settings__instance-type">
                    <Form.Label className="costs-settings__labels" htmlFor={strings.efficiency.instanceDropdown}>
                      {strings.efficiency.instanceType}
                    </Form.Label>
                    <Controller
                      name="cloudProvider"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            styles={customStyles}
                            aria-label={strings.efficiency.cloudDropdown}
                            aria-labelledby={strings.efficiency.cloudDropdown}
                            components={{
                              Option,
                              DropdownIndicator,
                            }}
                            value={
                              Object.keys(field.value).length
                                ? field.value
                                : {
                                    label: strings.efficiency.cloudProvider,
                                    value: strings.efficiency.cloudProvider,
                                  }
                            }
                            isSearchable
                            isDisabled={
                              syncedDates?.syncedAWSDate?.length > 0 || syncedDates?.syncedCloudCostsDate?.length > 0
                            }
                            options={cloudProviders}
                            onChange={(option) => {
                              setSelectedInstance({
                                ...defaultInstance,
                                CloudProvider: option.value,
                              });
                              setValue('instanceType', {
                                provider: option.value,
                                value: strings.efficiency.selectInstance,
                                label: strings.efficiency.selectInstance,
                              });
                              setValue('cloudProvider', option);
                              setCloudProvider(option.value);
                              setValue('costs', {
                                CPUPerHour: 0,
                                GBPerHour: 0,
                                GBPerTransmit: 0,
                                GBPerReceived: 0,
                                DiskPerMonth: 0,
                              });
                            }}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="instanceType"
                      control={control}
                      aria-label={strings.efficiency.instanceDropdown}
                      aria-labelledby={strings.efficiency.instanceDropdown}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            className="instance-type-select"
                            classNamePrefix="instance-type-select"
                            aria-label={strings.efficiency.instanceDropdown}
                            aria-labelledby={strings.efficiency.instanceDropdown}
                            title={strings.efficiency.instanceDropdown}
                            styles={customStyles}
                            components={{
                              Option,
                              DropdownIndicator,
                            }}
                            value={
                              field.value.provider !== ''
                                ? field.value
                                : {
                                    label: strings.efficiency.instanceType,
                                    value: strings.efficiency.instanceType,
                                  }
                            }
                            isDisabled={
                              instanceOptions.length < 1 ||
                              syncedDates?.syncedAWSDate?.length > 0 ||
                              syncedDates?.syncedCloudCostsDate?.length > 0
                            }
                            isSearchable
                            options={instanceOptions || []}
                            onChange={(option) => {
                              const newInstance = instanceTypes.find((instance) => instance.ID === option.value);
                              if (newInstance) {
                                setValue('instanceType', {
                                  provider: newInstance?.CloudProvider,
                                  value: newInstance?.ID,
                                  label: `${newInstance?.Name} ${
                                    newInstance?.PlanType ? `(${newInstance?.PlanType})` : ''
                                  }`,
                                });
                                setSelectedInstance(newInstance);
                                setValue('costs', {
                                  CPUPerHour: parseFloat(newInstance?.CPUCostPerCorePerHour.toFixed(10)),
                                  GBPerHour: parseFloat(newInstance?.MemoryCostPerGigabytePerHour.toFixed(10)),
                                  DiskPerMonth: 0,
                                  GBPerReceived: 0,
                                  GBPerTransmit: 0,
                                });
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <CustomizedNumbersOptions />
                  <div className="costs-settings__reset-defaults" onClick={resetToDefault}>
                    {strings.efficiency.resetToDefaults}
                  </div>
                </Form.Group>
              </div>
              <div className="costs-settings__bottom-buttons">
                <Button
                  variant="primary"
                  type="submit"
                  form="costs-settings-form"
                  disabled={syncedDates?.syncedAWSDate?.length > 0 || syncedDates?.syncedCloudCostsDate?.length > 0}
                >
                  {strings.efficiency.saveSettings}
                </Button>
              </div>
            </Form>
          )}
        </>
      )}
      <Toaster />
    </LayoutReact>
  );
};

export default CostsSettings;
