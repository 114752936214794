import React from 'react';
import { COLORS } from '~utils/styling';

const ExpandIcon = ({ ...props }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    height={16}
    width={20}
    className="expand-icon"
    aria-label="Expand icon"
    {...props}
  >
    <path
      fill={COLORS.CORE.PRIMARY}
      d="M12.15 2.29a.5.5 0 0 0-.52-.48l-4.5.24a.5.5 0 0 0 .06 1l3.99-.21.2 4a.5.5 0 0 0 1-.06l-.23-4.5Zm-4.21 4.9 4.08-4.54-.74-.67L7.2 6.52l.75.67Zm-5.13 4.34a.5.5 0 0 0 .53.48l4.5-.22a.5.5 0 1 0-.06-1l-4 .2-.18-4a.5.5 0 0 0-1 .05l.21 4.5Zm4.24-4.88-4.1 4.52.73.67L7.8 7.33l-.74-.68Z"
      opacity=".9"
    />
  </svg>
);

export default ExpandIcon;
