import React, { useState } from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableInstance } from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useContextMenu } from 'react-contexify';

import ExportButton from '~reactComponents/ExportButton/ExportButton.react';
import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import ToggleColumnsVisibility from '../ToggleColumnsVisibility/ToggleColumnsVisibility.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';

import { PAGE_SIZE_OPTIONS } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.config.react';

import { VulnerabilitiesActionItems } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { Cluster, DateType } from '~globalTypes';

import { EXPORT_IMAGES_OPTIONS } from '../../../ReactVulnerabilities.helpers.react';
import { getCurrentTimezone, VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID } from '~reactHelpers';

import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import './TableCardHeader.react.scss';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';

dayjs.extend(LocalizedFormat);
const timeZone = getCurrentTimezone();
const formatDatetime = (m: Dayjs): string =>
  m.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toISOString();

type TableCardHeader = {
  cluster?: Cluster;
  firstSeenRange: DateType | null;
  setFirstSeenRange: React.Dispatch<React.SetStateAction<DateType | null>>;
  globalSearch: string | null;
  isHidingColsSupported?: boolean;
  isResolvedShown: boolean;
  selectedAppGroup: AppGroup | null;
  setSelectedAppGroup: React.Dispatch<React.SetStateAction<AppGroup | null>>;
  appGroups: AppGroup[];
  loaded: boolean;
  selectedVulnActionItem?: VulnerabilitiesActionItems | null;
  syncedDate?: string;
  tableInstance: TableInstance<VulnerabilitiesActionItems>;
  totalRowCount: number;
  handleExport: (action: string) => Promise<void>;
  onShowResolvedClicked: () => void;
  setGlobalSearch: (keywords: string | null) => void;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableCardHeader = ({
  cluster,
  firstSeenRange,
  setFirstSeenRange,
  globalSearch,
  isHidingColsSupported,
  isResolvedShown,
  appGroups,
  selectedAppGroup,
  setSelectedAppGroup,
  loaded,
  selectedVulnActionItem,
  syncedDate,
  tableInstance,
  totalRowCount,
  handleExport,
  onShowResolvedClicked,
  setGlobalSearch,
  setLoaded,
}: TableCardHeader) => {
  const {
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const { show: showContextMenu } = useContextMenu({
    id: VULN_ITEMS_TABLE_ALL_IMAGES_CONTEXT_MENU_ID,
  });

  const filterByDate = (start?: Date | Dayjs, end?: Date | Dayjs) => {
    if (!start || !end) {
      setFirstSeenRange(null);
      return;
    }
    const changedDates = {
      start: formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start)),
      end: formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end)),
    };
    setFirstSeenRange(changedDates);
  };

  return (
    <div className="vulnerabilities-action--items--header">
      <Row>
        <Col className="vulnerabilities-action--items--header--section vulnerabilities-action--items--header--section--top-all-images">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="vulnerabilities-action--items--header--section__search"
              value={globalSearch || ''}
              onChange={(e) => setGlobalSearch(e.target.value.trim() || null)}
            />
          </Form>
          <div className="vulnerabilities-action--items--header--section__date-picker-container">
            <div className="vulnerabilities-action--items--header--section__separator"></div>
            <Datepicker
              onDateSelect={filterByDate}
              minDate={120}
              emptyValueLabel="First Seen"
              startDate={firstSeenRange?.start}
              endDate={firstSeenRange?.end}
              className="action-items-reports"
            />
          </div>
          <DropdownButton
            title={selectedAppGroup?.name || 'App Group: Name'}
            size="sm"
            className="action--items--header--section__dropdown"
          >
            <Dropdown.Item onSelect={() => setSelectedAppGroup(null)}>All App Groups</Dropdown.Item>
            {appGroups.map((appGroup, idx) => (
              <Dropdown.Item key={idx} onSelect={() => setSelectedAppGroup(appGroup)}>
                {appGroup.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <div className="vulnerabilities-action--items--header--section__actions-container">
            <ExportButton
              exportActionItemsOptions={EXPORT_IMAGES_OPTIONS}
              size="sm"
              title="Export"
              handleExport={handleExport}
            />
            <button
              className={`additional-actions-btn ${selectedVulnActionItem ? 'd-none' : ''}`}
              onClick={(e) => showContextMenu({ event: e, props: { actionItem: selectedVulnActionItem } })}
              data-cy="additional-actions-button"
            >
              <img src={HorizontalEllipsis} alt="ellipsis icon" />
            </button>
          </div>
        </Col>
      </Row>
      <Row className="vulnerabilities-action--items--header--section-container">
        <Col className="vulnerabilities-action--items--header--section">
          <span className="vulnerabilities-action--items--header--section__showing">
            {`Showing ${Math.max(pageSize * pageIndex + 1)} -
              ${
                pageSize * pageIndex + pageSize > totalRowCount ? totalRowCount : pageSize * pageIndex + pageSize
              } of ${totalRowCount} results`}
          </span>
          {isHidingColsSupported && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <ToggleColumnsVisibility tableInstance={tableInstance} cluster={cluster} />
            </DndProvider>
          )}
          {loaded && (
            <label className="vulnerabilities-action--items__show-resolved" data-cy="show-resolved-only-checkbox">
              <input
                type="checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                  setLoaded && setLoaded(false);
                  onShowResolvedClicked();
                }}
                checked={isResolvedShown}
              />
              <div className="vulnerabilities-action--items__show-resolved-label">
                <span>{strings.vulnerabilities.ShowResolvedOnly}</span>
              </div>
            </label>
          )}
        </Col>
        <Col className={`vulnerabilities-action--items--header--section end ${selectedVulnActionItem ? 'd-none' : ''}`}>
          <span className="vulnerabilities-action--items--header--section__results">Results per page</span>
          <DropdownButton
            title={pageSize}
            size="sm"
            className="vulnerabilities-action--items--header--section__dropdown vulnerabilities-action--items--header--section__dropdown__page-size"
          >
            {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  if (pageSize === pageSizeOption) return;
                  setLoaded && setLoaded(false);
                  setPageSize(pageSizeOption);
                }}
              >
                {pageSizeOption}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <LastSyncedDate
          noneSyncedDateTitle={strings.vulnerabilities.NoneSyncedDateTitle}
          syncedDate={syncedDate}
          syncedDateTitle={strings.vulnerabilities.SyncedDateTitle}
        />
      </Row>
    </div>
  );
};

export default TableCardHeader;
