export const Labels = {
  ImpactedWorkloads: 'Impacted Workloads',
  NoImpactedWorkloads: 'There are no impacted workloads',
} as const;

export interface ImpactedWorkloads {
  cluster: string;
  namespace: string;
  name: string;
  container: string;
  kind: string;
}

export enum TableColumnNames {
  Cluster = 'Cluster',
  Namespace = 'Namespace',
  Name = 'Name',
  Container = 'Container',
  Kind = 'Kind',
}

export enum TableAccessors {
  Cluster = 'cluster',
  Namespace = 'namespace',
  Name = 'name',
  Container = 'container',
  Kind = 'kind',
}
