import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';

import { AddButton, Breadcrumbs } from '@fairwindsops/ui-components';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import {
  IRoute,
  IRouter,
  IStore,
  OptionType,
  Team,
  TeamMembership,
} from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { validateEmail } from '~utils/helpers';

import { handlePageChange } from '~reactHelpers';

import { FormElement } from './InviteAccount.react.types';

import './InviteAccount.react.scss';

type InviteAccountProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const InviteAccount = ({ router, store }: InviteAccountProps) => {
  const organization = store().getters.organization;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const [teams, setTeams] = useState<OptionType[]>([]);
  const [memberships, setMemberships] = useState<TeamMembership[]>([]);

  const [formElements, setFormElements] = useState<FormElement[]>([]);

  const roles = useRef<OptionType[]>([
    { label: strings.general.admin, value: strings.general.admin },
    { label: strings.general.editor, value: strings.general.editor },
    { label: strings.general.viewer, value: strings.general.viewer },
  ]);

  const getTeams = async () => {
    try {
      const teams = (await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || [];

      if (teams?.length) {
        setTeams(teams.map((team: Team) => ({ label: team.Name, value: team.ID })));
      }
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  const getMemberShips = async () => {
    try {
      setMemberships((await sendRequest('GET', `${baseUrl}/memberships`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  useEffect(() => {
    getTeams();
    getMemberShips();
  }, []);

  const initNewFormElement = () => ({
    ID: uuidv4(),
    Email: '',
    TeamID: teams[0].value,
    Role: roles.current[0].value,
    IsOwner: false,
    error: null,
  });

  useEffect(() => {
    if (teams?.length) {
      setFormElements([initNewFormElement()]);
    }
  }, [teams]);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.teamManagement.settings,
      href: `/orgs/${organization.Name}/settings/team-management`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.teamManagement.teamManagement,
      href: `/orgs/${organization.Name}/settings/team-management`,
    },
    {
      id: 'last',
      label: strings.teamManagement.inviteUsers,
      href: '',
      isActive: true,
    },
  ];

  const isEmailExisted = (email: string) => {
    return !!memberships.find((membership: TeamMembership) => membership.Email === email);
  };

  const isEmailValid = (email: string) => {
    if (!email) {
      return '';
    }

    if (!validateEmail(email)) {
      return strings.teamManagement.enterAValidEmail;
    }

    return isEmailExisted(email) ? strings.teamManagement.existingUserErrorMessage : '';
  };

  const onEmailChanged = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setFormElements((prevFormElements) =>
      prevFormElements.map((element: FormElement) =>
        element.ID === id
          ? {
              ...element,
              Email: e.target.value,
              error: isEmailValid(e.target.value),
            }
          : element,
      ),
    );
  };

  const onTeamChanged = (option: OptionType, id: string) => {
    setFormElements((prevFormElements) =>
      prevFormElements.map((element: FormElement) =>
        element.ID === id
          ? {
              ...element,
              TeamID: option.value,
            }
          : element,
      ),
    );
  };

  const onRoleChanged = (option: OptionType, id: string) => {
    setFormElements((prevFormElements) =>
      prevFormElements.map((element: FormElement) =>
        element.ID === id
          ? {
              ...element,
              Role: option.value,
            }
          : element,
      ),
    );
  };

  const onOwnerAccessChanged = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setFormElements((prevFormElements) =>
      prevFormElements.map((element: FormElement) =>
        element.ID === id
          ? {
              ...element,
              IsOwner: e.target.checked,
            }
          : element,
      ),
    );
  };

  const onFormElementAdded = () => {
    setFormElements((prevFormElements) => [...prevFormElements, initNewFormElement()]);
  };

  const onFormElementRemoved = (id: string) => {
    setFormElements((prevFormElements) =>
      prevFormElements.filter((element: FormElement) => element.ID !== id),
    );
  };

  const hasEmptyEmails = () => !!formElements.find((element) => !element.Email);

  const hasErrors = () => !!formElements.find((element) => !!element.error);

  const shouldSendInviteButtonDisabled = useMemo(
    () => hasEmptyEmails() || hasErrors(),
    [formElements],
  );

  const transformFormElements = () =>
    formElements.map(({ Email, TeamID, Role, IsOwner }) => ({ Email, TeamID, Role, IsOwner }));

  const buildRequests = () =>
    transformFormElements().map((data) =>
      sendRequest(
        'POST',
        `${baseUrl}/memberships`,
        {
          data,
          showSuccessAlert: false,
        },
        null,
      ),
    );

  const sendInvites = async () => {
    try {
      await Promise.all(buildRequests());
      await router().push({ name: TEAM_MANAGEMENT });
    } catch (e) {
      logger.logError('error_inviting_member', e);
      toast.error(strings.teamManagement.inviteUsersFailed);
    }
  };

  return (
    <div className="invite-account">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <h3 className="invite-account__title">{strings.teamManagement.newUsers}</h3>
      <p className="invite-account__sub-title">{strings.teamManagement.subTitle}</p>
      <div className="invite-account__body">
        {formElements.map((element: FormElement, index: number) => (
          <div className="invite-account__row" key={element.ID}>
            <FormGroup id={`email-group-${element.ID}`} controlId="Email" className="profile-input">
              <Form.Label>{strings.teamManagement.email}</Form.Label>
              <Form.Control
                className={`${element.error ? 'is-invalid' : ''}`}
                placeholder={strings.teamManagement.email}
                type="text"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onEmailChanged(e, element.ID)}
                data-cy="email-text-input"
              />
              <Form.Control.Feedback type="invalid">{element.error || ''}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup id={`team-group-${element.ID}`} controlId="Team" className="profile-input">
              <Form.Label>{strings.teamManagement.team}</Form.Label>
              <Select
                onChange={(option) => {
                  onTeamChanged(option, element.ID);
                }}
                options={teams}
                value={teams.find((team) => team.value === element.TeamID)}
                className="invite-account__team-select"
                classNamePrefix="invite-account__team-select"
              />
            </FormGroup>
            <FormGroup id={`role-group-${element.ID}`} controlId="Role" className="profile-input">
              <Form.Label>{strings.teamManagement.role}</Form.Label>
              <Select
                onChange={(option) => {
                  onRoleChanged(option, element.ID);
                }}
                options={roles.current}
                value={roles.current.find((role) => role.value === element.Role)}
                className="invite-account__role-select"
                classNamePrefix="invite-account__role-select"
                data-cy="team-dropdown"
              />
            </FormGroup>
            <FormGroup id={`owner-access-group-${element.ID}`} controlId="IsOwner">
              <Form.Label className="owner-access-label">
                <span>{strings.teamManagement.ownerAccess}</span>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={`tooltip-owner-access-${element.ID}`}>
                      {strings.teamManagement.ownerAccessTooltip}
                    </Tooltip>
                  }
                >
                  <InfoIcon />
                </OverlayTrigger>
              </Form.Label>
              <Form.Check type="checkbox" onChange={(e) => onOwnerAccessChanged(e, element.ID)} />
            </FormGroup>
            {index !== 0 ? (
              <div className="invite-account__close-icon-container">
                <CloseIcon
                  width="1rem"
                  height="1rem"
                  onClick={() => onFormElementRemoved(element.ID)}
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <div className="invite-account__footer">
        <AddButton
          action={onFormElementAdded}
          buttonText={strings.teamManagement.inviteAdditionalUsers}
          class="invite-account__add-button"
          testLabel={strings.testLabels.addButton}
        />
        <Button
          className="invite-account__send-invite-btn"
          type="button"
          onClick={() => sendInvites()}
          disabled={shouldSendInviteButtonDisabled}
          data-cy="submit-send-invites-button"
        >
          {strings.teamManagement.sendInvites}
        </Button>
      </div>
      <Toaster />
    </div>
  );
};

export default InviteAccount;
