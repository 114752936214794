import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { ImpactedRepository, Labels } from './ImpactedRepositoriesTable.types.react';
import { getTableColumns } from './ImpactedRepositoriesTable.config.react';

import { ImageSummary } from '~globalTypes';

import './ImpactedRepositoriesTable.react.scss';

type ImpactedRepositoriesProps = {
  orgName: string;
  imageSummary: ImageSummary | undefined;
  loaded: boolean;
};

const ImpactedRepositories = ({ orgName, imageSummary, loaded }: ImpactedRepositoriesProps) => {
  const [impactedRepositories, setImpactedRepositories] = useState<ImpactedRepository[]>([]);

  useEffect(() => {
    if (imageSummary?.impactedRepositories?.length) {
      setImpactedRepositories(imageSummary.impactedRepositories);
    }
  }, [imageSummary]);

  // imageSummary.name has format "imageName:imageTag"
  const [imageName, imageTag, imageSHA] = [imageSummary?.name.split(':')[0], imageSummary?.tag, imageSummary?.sha];

  const columns = useMemo(() => getTableColumns(), []);

  const data = useMemo(() => impactedRepositories, [impactedRepositories]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  return (
    <Card className="impacted-repositories" data-cy="impacted-repositories">
      <Card.Title>
        <div className="impacted-repositories__title">
          <div className="impacted-repositories__left-title">
            {data?.length ? (
              <a
                className="impacted-workloads__workloads-title"
                href={`/orgs/${orgName}/repositories?imageName=${imageName}&imageTag=${imageTag}&imageSHA=${imageSHA}`}
              >
                {Labels.ImpactedRepositories}
              </a>
            ) : (
              <span className="impacted-repositories__repositories-title">{Labels.ImpactedRepositories}</span>
            )}
          </div>
        </div>
      </Card.Title>
      <Card.Body className={`impacted-repositories__body ${loaded ? '' : 'impacted-repositories__scroll--hidden'}`}>
        {loaded ? (
          <div>
            {data?.length ? (
              <>
                <table className="impacted-repositories__table" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            <div>{column.render('Header')}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="impacted-repositories__table-body">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="impacted-repositories__empty-body">
                <span>{Labels.NoImpactedRepositories}</span>
              </div>
            )}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
};

export default ImpactedRepositories;
