import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select, { GroupBase, MultiValueProps, StylesConfig } from 'react-select';
import toaster, { Toaster } from 'react-hot-toast';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import {
  ACTION_ITEMS,
  CLUSTER_OVERVIEW,
  CLUSTERS,
  ORG_DASHBOARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { DropdownIndicator } from '~reactComponents/DropdownIndicator/DropdownIndicator.react';
import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import AggregatedActionItemsTable from './AggregatedActionItemsTable/AggregatedActionItemsTable.react';
import AggregatorBarChart from './AggregatorBarChart/AggregatorBarChart.react';

import { handlePageChange } from '~utils/global.helpers.react';
import {
  AdvancedFilterOption,
  IRoute,
  IRouter,
  IStore,
  OptionType,
  SelectedFilters,
} from '~utils/global.types.react';
import { strings } from '~utils/strings';

import {
  ActionItemAggregation,
  ADVANCED_FILTERS_MAPPER,
  aggregatorOptions,
  DEFAULT_FILTERS,
  defaultAggregators,
  REVERSED_SEARCH_PARAMS_MAPPER,
  SEARCH_PARAMS_MAPPER,
  TEAM,
  Team,
} from './ActionItemsSummary.config.react';

import { getAggregatedActionItems, getAggregatorString } from './ActionItemsSummary.helpers.react';

import './ActionItemsSummary.react.scss';
import logger from '~utils/logger';
import { sendRequest } from '~utils/request';
import { COLORS } from '~utils/styling';
import AdvancedFilters from '~reactComponents/AdvancedFilters/AdvancedFilters.react';

interface ActionItemsSummaryProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

const MultiValue = ({
  children,
  getValue,
  index,
}: MultiValueProps<OptionType, true, GroupBase<OptionType>>) => {
  if (getValue().length && !index) {
    if (getValue().length <= 1) {
      return (
        <div>{`${children?.slice(0, 20)}${
          children?.length > 20 ? strings.punctuation.ellipsis : ''
        }`}</div>
      );
    } else {
      const length = getValue().length - 1;
      return (
        <>
          <div>{children?.slice(0, 20)}</div>
          <div>{`(+${length} ${strings.general.others})`}</div>
        </>
      );
    }
  }
  return null;
};

export function ActionItemsSummary({ router, route, store }: ActionItemsSummaryProps) {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const baseURL = `/v0/organizations/${org}`;
  const [aggregatedActionItems, setAggregatedActionItems] = useState<ActionItemAggregation[]>([]);
  const [aggregatedActionItemsTableData, setAggregatedActionItemsTableData] = useState<
    Record<string, any>[]
  >([]);
  const [filteredActionItems, setFilteredActionItems] = useState<ActionItemAggregation[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAggregators, setSelectedAggregators] = useState<OptionType[]>(defaultAggregators);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [advancedFilters, setAdvancedFilters] = useState<Record<string, string[]>>(DEFAULT_FILTERS);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [teams, setTeams] = useState<Team[]>([]);
  const [sortBy, setSortBy] = useState<any>([
    {
      id: 'openCount',
      desc: true,
    },
  ]);
  const [isFetchingTeams, setIsFetchingTeams] = useState<boolean>(true);

  const currentURLSearchParams = useRef<URLSearchParams>(
    new URLSearchParams(window.location.search),
  );
  const isNotFirstLoaded = useRef<boolean>(false);
  const hasNotInitializedSelectedFilters = useRef<boolean>(true);
  const isFetchingActionItemFilters = useRef<boolean>(false);
  const isFetchingAggregatedActionItems = useRef<boolean>(false);

  const getTeams = async () => {
    setIsFetchingTeams(true);
    try {
      setTeams((await sendRequest('GET', `${baseURL}/teams`, {}, null)) || []);
    } catch (e: any) {
      logger.logError('error_retrieving_teams_action_item_summary', e);
    } finally {
      setIsFetchingTeams(false);
    }
  };

  const transformActionItemFilters = (
    actionItemFilters: Record<string, string[]>,
    teams: Team[],
  ) => {
    if (!actionItemFilters) {
      return;
    }

    const actionItemFilterKeys = Object.keys(actionItemFilters);
    const transformedActionItemFilters = {} as Record<string, string[]>;

    for (const key of actionItemFilterKeys) {
      if (ADVANCED_FILTERS_MAPPER[key as keyof typeof ADVANCED_FILTERS_MAPPER]) {
        transformedActionItemFilters[ADVANCED_FILTERS_MAPPER[key]] = actionItemFilters[key] || [];
      }
    }

    if (teams?.length) {
      transformedActionItemFilters[TEAM] = teams.map((team) => team.Name);
    }

    return transformedActionItemFilters;
  };

  const initSelectedFilters = (searchParams: URLSearchParams) => {
    const selectedFilters = {} as SelectedFilters;

    const paramEntries = Array.from(searchParams);

    for (const [key, value] of paramEntries) {
      const section = REVERSED_SEARCH_PARAMS_MAPPER[key];

      if (!section) {
        continue;
      }

      const option = {
        label: value,
        value,
        section,
        isDisabled: !!(section === 'Cluster' && cluster),
      };

      selectedFilters[section] = selectedFilters[section]
        ? [...selectedFilters[section], option]
        : [option];
    }

    hasNotInitializedSelectedFilters.current = false;

    setSelectedFilters({ ...selectedFilters });
  };

  const getActionItemFilters = async () => {
    try {
      if (isFetchingActionItemFilters.current) {
        return;
      }

      isFetchingActionItemFilters.current = true;
      const searchParams = new URLSearchParams();

      const filterKeys = Object.keys(selectedFilters);

      if (filterKeys?.length) {
        for (const key of filterKeys) {
          const apiSearchKey = SEARCH_PARAMS_MAPPER[key];

          if (!apiSearchKey) {
            continue;
          }

          selectedFilters[key].forEach((data: any) =>
            searchParams.append(apiSearchKey, data.value),
          );
        }
      }

      const actionItemFilters = await sendRequest(
        'GET',
        cluster
          ? `${baseURL}/clusters/${cluster}/action-item-filters?${searchParams.toString()}`
          : `${baseURL}/action-item-filters?${searchParams.toString()}`,
        {},
        null,
      );

      setAdvancedFilters({
        ...DEFAULT_FILTERS,
        ...transformActionItemFilters(actionItemFilters, teams),
      });

      if (hasNotInitializedSelectedFilters.current) {
        initSelectedFilters(currentURLSearchParams.current);
      }
    } catch (e: any) {
      logger.logError('error_retrieving_action_item_filters_summary', e);
    } finally {
      isFetchingActionItemFilters.current = false;
    }
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (isFetchingTeams) {
      return;
    }

    getActionItemFilters();
  }, [cluster, selectedFilters, isFetchingTeams]);

  useEffect(() => {
    const searchParams = new URLSearchParams(route.query);

    const aggregatorsFromParams = searchParams.get('aggregator')?.split(',') || [];
    const newPage = parseInt(searchParams.get('page') || '1');
    const newItemsPerPage = parseInt(searchParams.get('itemsPerPage') || '50');

    if (Object.keys(route.query || {}).length === 0) {
      setQueryParams(defaultAggregators, [], 1, 10, sortBy);
    } else {
      const newSelectedAggregators: OptionType[] = [];

      aggregatorsFromParams.forEach((aggregator) => {
        const aggregatorOption = aggregatorOptions.find((option) => option.value === aggregator);
        if (aggregatorOption) {
          newSelectedAggregators.push(aggregatorOption);
        }
      });

      setSelectedAggregators(newSelectedAggregators);
      initSelectedFilters(searchParams);
    }

    setPage(newPage);
    setItemsPerPage(newItemsPerPage);
  }, [route.query]);

  useEffect(() => {
    setQueryParams(selectedAggregators, selectedFilters, page, itemsPerPage, sortBy);
  }, [page, itemsPerPage]);

  async function fetchAggregatedActionItems() {
    if (isFetchingAggregatedActionItems.current) {
      return;
    }

    if (!selectedAggregators?.length) {
      setAggregatedActionItems([]);
      setFilteredActionItems([]);
      setAggregatedActionItemsTableData([]);
      return;
    }

    setIsLoading(true);
    isFetchingAggregatedActionItems.current = true;

    try {
      // TODO: this logic should be changed when developing the advanced filters.
      const fetchedAggregatedActionItems = await getAggregatedActionItems(
        selectedAggregators,
        selectedFilters,
        teams,
        org,
        cluster,
        sortBy,
      );
      setAggregatedActionItems(
        fetchedAggregatedActionItems.map((aggregatedActionItem) => {
          return {
            ...aggregatedActionItem,
            aggregator: getAggregatorString(aggregatedActionItem, selectedAggregators),
          };
        }),
      );
      setAggregatedActionItemsTableData(fetchedAggregatedActionItems);
    } catch (e: any) {
      toaster.error(e.message ? e.message : 'Failure fetching aggregated action items.');
      setAggregatedActionItems([]);
      setFilteredActionItems([]);
      setAggregatedActionItemsTableData([]);
    } finally {
      setIsLoading(false);
      isFetchingAggregatedActionItems.current = false;
    }
  }

  useEffect(() => {
    if (hasNotInitializedSelectedFilters.current || isFetchingTeams) {
      return;
    }
    setQueryParams(selectedAggregators, selectedFilters, page, itemsPerPage, sortBy);
    fetchAggregatedActionItems();
  }, [org, cluster, selectedAggregators, selectedFilters, sortBy, isFetchingTeams]);

  const getSortCriteria = (sortBy: any) => {
    if (!sortBy) {
      return null;
    }

    const sortCriteriaMapper = {
      resourceKind: 'kind',
      workloadLabels: 'workload',
      resourceNamespace: 'namespace',
    };

    return [
      {
        ...sortBy[0],
        id: sortCriteriaMapper[sortBy[0].id as keyof typeof sortCriteriaMapper] || sortBy[0].id,
      },
    ];
  };

  useEffect(() => {
    setFilteredActionItems(
      aggregatedActionItems.slice((page - 1) * itemsPerPage, page * itemsPerPage),
    );
  }, [aggregatedActionItems, page, itemsPerPage]);

  function setQueryParams(
    newSelectedAggregators: typeof selectedAggregators,
    newFilters: any,
    newPage: number,
    newItemsPerPage: number,
    sortBy: any,
  ) {
    const query: Record<string, string | number | boolean | string[] | number[]> = {};
    const aggregators = newSelectedAggregators.map(
      (selectedAggregator) => selectedAggregator.value,
    );

    query.aggregator = aggregators;
    query.page = newPage;
    query.itemsPerPage = newItemsPerPage;

    const filterKeys = Object.keys(newFilters);

    if (sortBy?.length) {
      const sortCriteria = sortBy[0];
      query.orderBy = sortCriteria.id;
      query.orderByDesc = sortCriteria.desc;
    }

    router().push({
      name: router().currentRoute?.name,
      query,
    });
  }

  const orgBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ACTION_ITEMS,
      label: strings.navigation.ActionItems,
      href: `/orgs/${org}/action-items`,
    },
    {
      id: 'last',
      label: strings.navigation.summary,
      href: ``,
      isActive: true,
    },
  ];

  const clusterBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}`,
    },
    {
      id: 'last',
      label: strings.navigation.summary,
      href: ``,
      isActive: true,
    },
  ];

  const customFilterStyles: StylesConfig<OptionType, true> = {
    control: (provided) => ({
      ...provided,
      minHeight: '1.5rem',
      maxHeight: '4.5rem',
    }),
    valueContainer: (provided) => ({
      ...provided,
      alignItems: 'flex-start',
      maxHeight: '4.25rem',
      overflow: 'auto',
      padding: '4px 8px 0px 8px',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#EBEEF7',
      color: COLORS.CORE.PRIMARY,
      alignContent: 'center',
    }),
  };

  const onOptionChanged = (option: AdvancedFilterOption, action: string) => {
    const { value, section } = option;

    if (selectedFilters[section]) {
      selectedFilters[section] =
        action === 'deselect-option'
          ? selectedFilters[section].filter((filterValue) => filterValue.value !== value)
          : [...selectedFilters[section], option];
    } else {
      selectedFilters[section] = [option];
    }

    if (!selectedFilters[section]?.length) {
      delete selectedFilters[section];
    }
  };

  const onOptionRemoved = (removedValue: AdvancedFilterOption) => {
    const { value, section } = removedValue;

    if (!selectedFilters[section]) {
      return;
    }

    selectedFilters[section] = selectedFilters[section].filter(
      (filterValue) => filterValue.value !== value,
    );

    if (!selectedFilters[section]?.length) {
      delete selectedFilters[section];
    }
  };

  const selectFilters = (
    option: AdvancedFilterOption,
    removedValue: AdvancedFilterOption,
    action: string,
  ) => {
    if (option?.value && option?.section) {
      onOptionChanged(option, action);
    } else if (removedValue?.value && removedValue?.section) {
      onOptionRemoved(removedValue);
    }

    setSelectedFilters({ ...selectedFilters });
  };

  const currentFilterCount = useMemo(() => {
    if (!selectedFilters) {
      return 0;
    }

    const filterKeys = Object.keys(selectedFilters);

    let count = 0;

    if (filterKeys) {
      filterKeys.forEach((key) => {
        count += selectedFilters[key].length;
      });
    }

    return count;
  }, [selectedFilters]);

  return (
    <LayoutReact className="action-items-summary">
      <Breadcrumbs
        data={cluster ? clusterBreadcrumbsList : orgBreadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />

      <div className="action-bar action-items-summary-action-bar">
        <Select
          className="aggregator-select"
          classNamePrefix="aggregator-select"
          aria-label="Aggregator Select Dropdown"
          isDisabled={isLoading}
          styles={{
            group: () => ({
              height: 'auto',
              overflow: strings.noTranslate.default,
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: strings.noTranslate.costsPadding,
              textTransform: strings.noTranslate.capitalize,
            }),
          }}
          isMulti
          value={selectedAggregators}
          options={aggregatorOptions}
          placeholder="Aggregator"
          isSearchable
          isClearable
          backspaceRemovesValue
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            Option,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            MultiValue,
            DropdownIndicator,
          }}
          onChange={(option, meta) => {
            const aggregatorOptions = option?.length ? [...option] : [...defaultAggregators];

            if (
              sortBy?.length &&
              option?.length &&
              !['openCount', 'manuallyResolvedCount', 'fixedCount'].includes(sortBy[0].id)
            ) {
              setSortBy([
                ...sortBy.filter((element: any) =>
                  aggregatorOptions.find((option) => option.value === element.id),
                ),
              ]);
            }

            setSelectedAggregators(aggregatorOptions);
          }}
        />

        <AdvancedFilters
          customFilterStyles={customFilterStyles}
          customMenuType={{ filters: advancedFilters, selectedFilters, selectFilters }}
          nCurrentFilters={currentFilterCount}
        />
      </div>

      <AggregatorBarChart
        selectedAggregators={selectedAggregators}
        data={filteredActionItems}
        isLoading={isLoading}
        title={
          selectedAggregators.length === 0
            ? 'Action Items'
            : `Action Items by ${selectedAggregators
                .map((aggregatorOption) => aggregatorOption.label)
                .join(', ')}`
        }
      />

      {aggregatedActionItemsTableData?.length ? (
        <AggregatedActionItemsTable
          organizationName={org}
          aggregatedActionItems={aggregatedActionItemsTableData}
          page={page}
          itemsPerPage={itemsPerPage}
          onPageChanged={(page: number, pageSize: number) => {
            setPage(page + 1);
            setItemsPerPage(pageSize);
          }}
          onSortChanged={(sortBy: any) => {
            setSortBy(sortBy?.length ? getSortCriteria(sortBy) : null);
          }}
          router={router}
          aggregators={selectedAggregators}
          selectedFilters={selectedFilters}
          onSelectedFiltersChanged={(key: string, value: string) => {
            const section = REVERSED_SEARCH_PARAMS_MAPPER[key];

            const option = {
              label: value,
              value,
              section,
              isDisabled: !!(section === 'Cluster' && cluster),
            };

            if (
              selectedFilters[section]?.length &&
              selectedFilters[section].find((element) => element.value === option.value)
            ) {
              return;
            }

            selectedFilters[section] = selectedFilters[section]
              ? [...selectedFilters[section], option]
              : [option];

            setSelectedFilters({ ...selectedFilters });
          }}
        />
      ) : null}
      <Toaster />
    </LayoutReact>
  );
}
