import CreateTeam from './CreateTeam.react';

export default {
  name: 'create-team',
  components: {
    'create-team-page': CreateTeam,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
