import React from 'react';

import { LayoutReact } from '@fairwindsops/ui-components';

import {
  ACTION_ITEMS,
  ACTION_ITEMS_CLUSTER,
  ACTION_ITEMS_LISTS,
  ACTION_ITEMS_CLUSTER_LISTS,
  ACTION_ITEMS_CLUSTER_REPORTS,
  ACTION_ITEMS_CLUSTER_REPORTS_PRINT_PREVIEW,
  ACTION_ITEMS_REPORTS,
  ACTION_ITEMS_REPORTS_PRINT_PREVIEW,
} from '../../../reactComponents/NavigationReact/Navigation.config.react';

import ActionItemsTable from './ActionItemsPage/ActionItemsPage.react';
import ActionItemsList from './ActionItemsList/ActionItemsList.react';
import ActionItemsReports from './ActionItemsReports/ActionItemsReports.react';

import { IStore, IRoute, IRouter } from '~globalTypes';

type ActionItemsPageProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const ActionItemsPage = ({ route, router, store }: ActionItemsPageProps) => {
  const cluster = route?.params?.cluster;
  return (
    <LayoutReact>
      {route.name === ACTION_ITEMS || route.name === ACTION_ITEMS_CLUSTER ? (
        <ActionItemsTable router={router} route={route} store={store} selectedCluster={cluster ? cluster : ''} />
      ) : null}
      {route.name === ACTION_ITEMS_LISTS || route.name === ACTION_ITEMS_CLUSTER_LISTS ? (
        <ActionItemsList route={route} router={router} store={store} selectedCluster={cluster || 'All Clusters'} />
      ) : null}
      {[
        ACTION_ITEMS_REPORTS,
        ACTION_ITEMS_REPORTS_PRINT_PREVIEW,
        ACTION_ITEMS_CLUSTER_REPORTS,
        ACTION_ITEMS_CLUSTER_REPORTS_PRINT_PREVIEW,
      ].includes(route.name || '') ? (
        <ActionItemsReports route={route} router={router} selectedCluster={cluster || ''} store={store} />
      ) : null}
    </LayoutReact>
  );
};

export default ActionItemsPage;
