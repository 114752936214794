import TopImpactedImages from '../Charts/TopImpactedImages/TopImpactedImages.react';
import SeverityBreakdown from '../Charts/SeverityBreakdown/SeverityBreakdown.react';
import TopImpactedPackages from '../Charts/TopImpactedPackages/TopImpactedPackages.react';
import { FiltersMapType } from '~globalTypes';

export const Labels = {
  RecommendationsUnavailable: 'Unavailable',
  RiskReductionUnavailable: 'Unavailable',
  severityLabels: 'severityLabels',
  vulnerabilityCounts: 'vulnerabilityCounts',
  repositoryCounts: 'repositoryCounts',
  workloadCounts: 'workloadCounts',
  AllImagesVulnerabilitiesItems: 'All Images Page - Vulnerabilities Items',
  trivy: 'trivy',
  C: 'C',
  H: 'H',
  M: 'M',
  NoImagesSelectedErrMessage: 'Please select images',
  Vulnerabilities: 'Vulnerabilities',
  Vulnerability: 'Vulnerability',
  Cluster: 'cluster',
  Clusters: 'clusters',
  Package: 'package',
  Packages: 'packages',
} as const;

export const ChartLabels = {
  TopImpactedImages: {
    title: 'Top Impacted Images',
    component: TopImpactedImages,
  },
  SeverityBreakdown: {
    title: 'Severity Breakdown',
    component: SeverityBreakdown,
  },
  TopImpactedPackages: {
    title: 'Top Impacted Packages',
    component: TopImpactedPackages,
  },
} as const;

export interface VulnerabilitiesActionItems {
  id: number;
  title: string;
  sha: string;
  severityLabel: {
    nCritical: number;
    nHigh: number;
    nMedium: number;
  };
  vulnerabilitiesCount: number;
  cluster: string[];
  repositoriesCount: number;
  workloadsCount: number;
  recommended: string;
  package: string[];
  tag: string;
  lastScanned?: string;
}

export interface VulnerabilitiesActionItemsResponse {
  id: number;
  sha: string;
  title: string;
  severitiesSummary: {
    criticalCount: number;
    highCount: number;
    mediumCount: number;
    lowCount: number;
    noneCount: number;
    unknownCount: number;
  };
  vulnerabilitiesCount: number;
  clusters: string[];
  clustersCount: number;
  workloads: string[];
  workloadsCount: number;
  repositories: string[];
  repositoriesCount: number;
  recommended: string;
  packages: string[];
  tag: string;
  lastScanned?: string;
  riskReduction?: number;
}

export const sortingFields = [
  'titles',
  'severityLabel',
  'clusters',
  'repositoryCounts',
  'workloadCounts',
  'vulnerabilityCounts',
  'hasRecommendedTag',
  'riskReduction',
];

export const filtersMap: FiltersMapType = {
  titles: 'title',
  severityLabels: 'severityLabel',
  clusters: 'cluster',
  repositoryCounts: 'repositoriesCount',
  workloadCounts: 'workloadsCount',
  vulnerabilityCounts: 'vulnerabilitiesCount',
  hasRecommendedTags: 'hasRecommendedTag',
  riskReduction: 'riskReduction',
  packages: 'package',
};

export interface Member {
  CreatedOn: string;
  Email: string;
  FirstName: string;
  IsOwner: boolean;
  LastName: string;
  Organization: string;
  Teams: number[];
}

export const EXPORT_FORMAT: Record<string, (...args: any) => unknown> = {
  severityLabel: (item: VulnerabilitiesActionItems): string | undefined =>
    `${item.severityLabel.nCritical}${Labels.C}, ${item.severityLabel.nHigh}${Labels.H}, ${item.severityLabel.nMedium}${Labels.M}`,
  clusters: (item: VulnerabilitiesActionItems): string | undefined =>
    item.cluster?.length ? item.cluster.join(', ') : '',
};

export const EXPORT_FIELDS_MAP: Record<string, string> = {
  title: 'Title',
  severityLabel: 'Severity',
  vulnerabilitiesCount: 'Vulnerabilities',
  clusters: 'Clusters',
  repositoriesCount: 'Repositories',
  workloadsCount: 'Workloads',
  recommended: 'Recommended Tag',
  riskReduction: 'Risk Reduction',
  lastScanned: 'Last Scanned',
};
