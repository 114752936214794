import React, { useState, useEffect } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import dayjs from 'dayjs';

import { AutomatedStandard, OptionType } from '~utils/global.types.react';

import { formatStatus, standardizedReports } from '../Compliance.helpers.react';
import { Check, Standards } from '../Compliance.types.react';

import AccordionComponent from '~reactComponents/accordion/AccordionComponent.react';
import Tag from '~reactComponents/Tags/Tag.react';
import CloseIcon from '~reactIcons/Close.icon.react';

import { statusMap, defaultAnswerOptions, payloadMap, Labels } from './AssessmentModal.types.react';

import { strings } from '~utils/strings';

import './AssessmentModal.react.scss';
import { IGNORE_STANDARDS } from '~reactComponents/cards/ComplianceCard/ComplianceCard.types.react';

type ModalProps = {
  show: boolean;
  setModal: (showModal: boolean) => void;
  isOwner: boolean;
  check: Check | null;
  addEvidence: (evidence: { evidence: string; status: string; mode: string }, id: number) => void;
};

const SelfAssessmentModal = ({
  show,
  setModal,
  isOwner,
  check,
  addEvidence,
}: ModalProps): JSX.Element => {
  const [standards, setStandards] = useState<AutomatedStandard[]>([]);
  const [answerOptions, setAnswerOptions] = useState<OptionType[]>([]);
  const [answer, setAnswer] = useState<OptionType | null>(null);
  const [evidenceText, setEvidenceText] = useState<string>(check?.evidence || '');

  useEffect(() => {
    if (check) {
      const filteredDefaultAnswerOptions = check.canBeAutomated
        ? defaultAnswerOptions
        : defaultAnswerOptions.filter((option) => option.value !== Labels.automated);
      setAnswerOptions(filteredDefaultAnswerOptions);
      setAnswer(findAnswer(filteredDefaultAnswerOptions));
      getStandards(check.standards);
    }
  }, [check]);

  const getStandards = (standards: Standards[]) => {
    if (!standards?.length) {
      return;
    }
    const transformedStandards = [];
    for (const standard of standards) {
      if (IGNORE_STANDARDS.includes(standard?.name)) continue;
      transformedStandards.push({
        label: standardizedReports(standard?.name),
        codes: standard?.codes,
      });
    }
    setStandards(transformedStandards);
  };

  const findAnswer = (filteredDefaultAnswerOptions: OptionType[]) => {
    const criteria = getCriteria(check);
    if (!criteria) return null;
    return (
      filteredDefaultAnswerOptions.find((option: OptionType) => option.value === criteria) || null
    );
  };

  const getCriteria = (check: Check | null) => {
    if (!check) {
      return null;
    }
    if (check.mode === Labels.automated) {
      return check.mode;
    }
    const keys = Object.keys(statusMap);
    for (const key of keys) {
      if (statusMap[key].includes(check.status)) {
        return key;
      }
    }
    return null;
  };

  const submitEvidence = () => {
    const status = findStatus();
    if (check)
      addEvidence(
        {
          evidence: evidenceText || '',
          status,
          mode: answer?.value === Labels.automated ? Labels.automated : Labels.manual,
        },
        check?.id,
      );
    setModal(false);
  };

  const findStatus = () => {
    if (!answer) return Labels.notStarted;
    const keys = Object.keys(payloadMap);
    for (const key of keys) if (key === answer.value) return payloadMap[key];
    return Labels.notStarted;
  };

  const onAnswerSelected = (selected: OptionType) => {
    setAnswer(selected);
  };

  const getUpdatedBy = () => {
    if (!check) {
      return '';
    }

    if (check.selfAssessedBy?.firstName && check.selfAssessedBy.lastName) {
      `${check.selfAssessedBy.firstName} ${check.selfAssessedBy.lastName}`;
    }

    return check.selfAssessedBy?.email ? check.selfAssessedBy?.email : '';
  };

  const evidence = (node: React.FormEvent) => {
    const target = node.target as HTMLInputElement;
    if (node !== null) setEvidenceText(target.value);
  };

  return (
    <Modal show={show} onHide={() => setModal(false)} className="self-assessment-modal">
      <Modal.Header>
        <Modal.Title>{check?.title}</Modal.Title>
        <div className="self-assessment-modal__header-right">
          {check?.status ? (
            <Badge
              pill
              className={`compliance-status-badge compliance-status-badge--${check.status}`}
            >
              {formatStatus(check.status)}
            </Badge>
          ) : null}
          <div className="custom-close-icon">
            <CloseIcon onClick={() => setModal(false)} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="self-assessment-modal__modal-body">
        <div className="self-assessment-modal__header-section">
          <div>
            <div className="self-assessment-modal__last-reported">
              <div>{strings.automatedCheck.lastReported}</div>
              <div>
                {dayjs(check?.selfAssessedAt).utc().format('LL')} at{' '}
                {dayjs(check?.selfAssessedAt).utc().format('LT')}
              </div>
            </div>
            <div className="self-assessment-modal__updated-by">
              <div>{strings.automatedCheck.updatedBy}</div>
              <div>{getUpdatedBy()}</div>
            </div>
          </div>
          <div className="self-assessment-modal__standards">
            {standards?.length &&
              standards.map((standard: AutomatedStandard, index: number) => (
                <div key={index} className="self-assessment-modal__standard-item">
                  <div>{standard.label}</div>
                  <div>
                    {standard.codes?.length &&
                      standard.codes.map((code: string, index: number) => (
                        <div key={index}>
                          <Tag tagClassNames="tag-ellipsis">{code}</Tag>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="self-assessment-modal__policy-status">
          <div className="self-assessment-modal__policy-evaluated">
            <div>{strings.automatedCheck.policy}</div>
            <p className="self-assessment-modal__check-text">{check?.text}</p>
            {check?.reportType ? (
              <p className="self-assessment-modal__check-text">{`${strings.automatedCheck.automationDetails1.replace(
                '#report_type',
                check.reportType,
              )} ${
                check.eventTypes?.length
                  ? strings.automatedCheck.automationDetails2.replace(
                      '#event_types',
                      check.eventTypes.join(', '),
                    )
                  : ''
              }`}</p>
            ) : null}
          </div>
          <div className="assessment-btns">
            <Select
              className="assessment-select"
              classNamePrefix="assessment-select"
              isSearchable={false}
              options={answerOptions}
              value={answer}
              onChange={onAnswerSelected}
            />
          </div>
        </div>
        <div>
          <AccordionComponent
            title={strings.automatedCheck.additionalEvidence}
            containerClassNames="automated-check-modal__accordion-container"
            headerClassNames="automated-check-modal__accordion-header"
            bodyClassNames="automated-check-modal__accordion-body"
          >
            <Form.Control
              as="textarea"
              defaultValue={check?.evidence}
              placeholder={strings.selfAssessment.evidencePlaceHolder}
              onChange={evidence}
              rows={4}
            />
          </AccordionComponent>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setModal(false)} className="outline-btn cancel-btn">
          {strings.selfAssessment.cancel}
        </Button>
        <Button type="submit" onClick={submitEvidence} disabled={!isOwner}>
          {strings.selfAssessment.completeAssessment}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelfAssessmentModal;
