import React, { useState, useEffect, useMemo } from 'react';
import { Alert, Button, Form, FormGroup } from 'react-bootstrap';
import Joi from 'joi';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controlled as CodeMirror } from '@leifandersen/react-codemirror2';
import sampleYamlInstance from './new/sampleYamlInstance';
import sampleRegoPolicy from './new/sampleRegoPolicy';
import PlusIcon from '~assetIcons/plus.svg';
import MinusIcon from '~assetIcons/minus.svg';
import { IStore, IRoute, IRouter } from '~globalTypes';
import { IInstances, IPolicies, IPolicyFormData, ITemplate } from './policy.types.react';
import 'codemirror-rego/mode';
import 'codemirror/mode/yaml/yaml';
import './PolicyEditor.react.scss';

type PolicyEditorProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  edit: boolean;
  isTemplate: boolean;
  handleOnSubmit: (data: IPolicyFormData, removableInstances?: string[]) => Promise<any>;
  policy?: IPolicies | ITemplate | Record<string, any>;
  instances?: IInstances[];
};

const validationSchema = Joi.object({
  Name: Joi.string().trim().replace(/\s/g, '-').required().prefs({ convert: true }),
  Description: Joi.string().trim().prefs({ convert: true }).optional().allow(null, ''),
  Rego: Joi.string().required(),
  Yaml: Joi.array()
    .items({
      Name: Joi.string().required().trim().replace(/\s/g, '-').prefs({ convert: true }).messages({
        'string.empty': `"instance" is not allowed to be empty`,
      }),
      code: Joi.string().required(),
    })
    .unique((a, b) => a.Name === b.Name)
    .min(0),
});

const isCheckRE = /^package\s+fairwinds\s*(#.*)?$/;

function isOPACustomLibrary(rego: string): boolean {
  const lines = rego.trim().split('\n');
  for (const line of lines) {
    if (line.trim().startsWith('package')) {
      const isCheck = isCheckRE.test(line.trim());
      return !isCheck;
    }
  }
  return false;
}

const PolicyEditor = (props: PolicyEditorProps): JSX.Element => {
  const [regoPolicy, setRegoPolicy] = useState<string>(sampleRegoPolicy);
  // TODO: what do we do with removableInstances?
  const [removableInstances, setRemovableInstances] = useState<string[]>([]);
  const [instances, setInstances] = useState<IInstances[]>([
    {
      Name: 'helm-deployed.yaml',
      code: sampleYamlInstance,
    },
  ]);

  useEffect(() => {
    if (props?.policy?.Rego) {
      setRegoPolicy(props.policy.Rego);
    }
    if (props?.instances?.length) {
      setInstances(props.instances);
    }
  }, [props.route]);

  const isLibrary = useMemo(() => isOPACustomLibrary(regoPolicy), [regoPolicy]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IPolicyFormData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      Name: props?.policy?.Name || '',
      Description: props?.policy?.Description || '',
      Rego: props?.policy?.Rego || sampleRegoPolicy,
      Yaml: props?.instances?.length ? props.instances : [{ Name: 'helm-deployed.yaml', code: sampleYamlInstance }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Yaml',
  });

  const formatName = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    target.value = target.value.replace(/\s/g, '-').toLowerCase();
  };

  const handleAppendInstance = () => {
    append({ Name: '', code: sampleYamlInstance });
    setInstances((instances) => [...instances, { Name: '', code: sampleYamlInstance }]);
  };

  const handleRemoveInstance = (idx: number) => {
    remove(idx);
    const _instances = [...instances];
    setRemovableInstances((removables) => [...removables, instances[idx].Name]);
    _instances.splice(idx, 1);
    setInstances((instances) => [...instances, ..._instances]);
  };

  return (
    <div className="policy--editor">
      <Form
        onSubmit={handleSubmit(
          (data) => props.handleOnSubmit(data),
          (e) => console.error(e),
        )}
      >
        <fieldset disabled={!props.store().getters.isOrgOwner}>
          <div className="policy--editor--details">
            <FormGroup controlId="PolicyName" className="policy--editor--details__Name">
              <Form.Label>Policy Name</Form.Label>
              <Form.Control
                type="text"
                className={`${errors.Name ? 'is-invalid' : ''}`}
                placeholder="Name"
                onKeyUp={formatName}
                {...register('Name')}
                data-cy="policy-name-text-input"
              />
              <Form.Control.Feedback type="invalid">{errors?.Name?.message}</Form.Control.Feedback>
            </FormGroup>
            <FormGroup controlId="PolicyDescription" className="policy--editor--details__Description">
              <Form.Label>Policy Description</Form.Label>
              <Form.Control
                type="text"
                className={`${errors.Description ? 'is-invalid' : ''}`}
                placeholder="Description"
                {...register('Description')}
                data-cy="policy-description-text-input"
              />
              <Form.Control.Feedback type="invalid">{errors?.Description?.message}</Form.Control.Feedback>
            </FormGroup>
          </div>
          {isLibrary && (
            <Alert variant="info">
              This code will be an OPA library -{' '}
              <a href="https://insights.docs.fairwinds.com/features/policies/#about">link to docs for more info</a>.
            </Alert>
          )}
          <div className="policies-codemirror-rego">
            <h4 className="mt-3 policies-codemirror-rego__title">Rego</h4>
            <CodeMirror
              value={regoPolicy}
              options={{
                tabSize: 4,
                mode: 'rego',
                theme: 'material',
                lineNumbers: true,
                line: true,
                readOnly: !props.store().getters.isOrgOwner,
              }}
              onBeforeChange={(editor, data, value) => {
                setRegoPolicy(value);
                setValue('Rego', value, { shouldDirty: true, shouldTouch: true });
              }}
            />
          </div>
          <>
            {props?.policy?.Version === 1 && (
              <>
                {fields.map((instance, idx) => (
                  <div key={instance.id} className="policy--editor--instance">
                    <div className="d-flex mt-3 align-items-center">
                      <h4 className="m-0 mb-1">YAML</h4>
                      <Button
                        variant="white"
                        onClick={handleAppendInstance}
                        className="p-0 pl-2 policy--editor--instance__button"
                        aria-label="add instance"
                        title="Add a new instance"
                      >
                        <div className="instance--button">
                          <img className="add-icon" src={PlusIcon} alt="add icon" />
                          <span className={idx > 0 ? 'd-none' : 'ml-1'}>Add Instance</span>
                        </div>
                      </Button>
                      {idx > 0 && (
                        <Button
                          variant="white"
                          onClick={() => handleRemoveInstance(idx)}
                          className="p-0 pl-1 policy--editor--instance__button"
                          aria-label="remove instance"
                          title="Remove instance"
                        >
                          <div className="instance--button">
                            <img className="add-icon" src={MinusIcon} alt="remove icon" />
                          </div>
                        </Button>
                      )}
                    </div>
                    <FormGroup controlId="instanceName" className="policy--editor--instance__Name">
                      <Form.Label className="sr-only">Instance Name</Form.Label>
                      <Form.Control
                        type="text"
                        className={errors.Yaml?.[idx] ? 'is-invalid' : ''}
                        placeholder="Instance Name"
                        onKeyUp={formatName}
                        {...register(`Yaml.${idx}.Name` as const)}
                      />
                      <Form.Control.Feedback type="invalid">{errors?.Yaml?.[idx]?.Name?.message}</Form.Control.Feedback>
                    </FormGroup>
                    {instances[idx]?.code && (
                      <Controller
                        control={control}
                        name={`Yaml.${idx}.Name` as const}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <CodeMirror
                            value={instances[idx] && instances[idx].code}
                            options={{
                              tabSize: 4,
                              mode: 'yaml',
                              theme: 'material',
                              lineNumbers: true,
                              line: true,
                              readOnly: !props.store().getters.isOrgOwner,
                            }}
                            onBeforeChange={(editor, data, value) => {
                              const _instances = [...instances];
                              _instances[idx].code = value;
                              setInstances(_instances);
                              setValue(
                                `Yaml.${idx}`,
                                { Name: instances[idx].Name, code: value },
                                { shouldDirty: true, shouldTouch: true },
                              );
                            }}
                          />
                        )}
                      />
                    )}
                  </div>
                ))}
              </>
            )}
          </>
          <Button type="submit" variant="primary" className="mt-4" data-cy="submit-policy-button">
            {props.edit ? 'Update Policy' : 'Save Policy'}
          </Button>
        </fieldset>
      </Form>
    </div>
  );
};

export default PolicyEditor;
