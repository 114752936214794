import React from 'react';
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableInstance } from 'react-table';
import dayjs, { Dayjs } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useContextMenu } from 'react-contexify';

import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';
import ExportButton from '~reactComponents/ExportButton/ExportButton.react';
import ToggleColumnsVisibility from '../ToggleColumnsVisibility/ToggleColumnsVisibility.react';

import { PAGE_SIZE_OPTIONS } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.config.react';

import { VulnerabilitiesActionItems } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';

import { EXPORT_VULNERABILITIES_OPTIONS } from '../../../ReactVulnerabilities.helpers.react';
import { getCurrentTimezone, VULN_ITEMS_TABLE_ALL_VULNERABILITIES_CONTEXT_MENU_ID } from '~reactHelpers';
import { strings } from '~utils/strings';

import './TableCardHeader.react.scss';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import { DateType } from '~utils/global.types.react';

dayjs.extend(LocalizedFormat);
const timeZone = getCurrentTimezone();
const formatDatetime = (m: Dayjs): string =>
  m.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toISOString();

type TableCardHeader = {
  firstSeenRange: DateType | null;
  setFirstSeenRange: React.Dispatch<React.SetStateAction<DateType | null>>;
  globalSearch: string | null;
  isHidingColsSupported?: boolean;
  selectedVulnActionItem?: VulnerabilitiesActionItems | null;
  syncedDate?: string;
  tableInstance: TableInstance<VulnerabilitiesActionItems>;
  totalRowCount: number;
  handleExport: (action: string) => Promise<void>;
  setFirstSeen?: React.Dispatch<React.SetStateAction<Date | null>>;
  setGlobalSearch: (keywords: string | null) => void;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableCardHeader = ({
  firstSeenRange,
  setFirstSeenRange,
  globalSearch,
  isHidingColsSupported,
  selectedVulnActionItem,
  syncedDate,
  tableInstance,
  totalRowCount,
  handleExport,
  setFirstSeen,
  setGlobalSearch,
  setLoaded,
}: TableCardHeader) => {
  const {
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  const { show: showContextMenu } = useContextMenu({
    id: VULN_ITEMS_TABLE_ALL_VULNERABILITIES_CONTEXT_MENU_ID,
  });

  const filterByDate = (start: Date | Dayjs, end: Date | Dayjs) => {
    if (!start && !end) {
      setFirstSeenRange(null);
      return;
    }
    if (start && end) {
      const changedDates = {
        start: formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start)),
        end: formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end)),
      };
      setFirstSeenRange(changedDates);
    }
  };

  return (
    <div className="vulnerabilities-action--items--header">
      <Row>
        <Col className="vulnerabilities-action--items--header--section vulnerabilities-action--items--header--section--top">
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Control
              type="search"
              placeholder="Search"
              className="vulnerabilities-action--items--header--section__search"
              value={globalSearch || ''}
              onChange={(e) => setGlobalSearch(e.target.value.trim() || null)}
            />
          </Form>
          <div className="vulnerabilities-action--items--header--section__date-picker-container">
            <div className="vulnerabilities-action--items--header--section__separator"></div>
            <Datepicker
              onDateSelect={filterByDate}
              minDate={120}
              emptyValueLabel="First Seen"
              startDate={firstSeenRange?.start}
              endDate={firstSeenRange?.end}
              className="action-items-reports"
            />
            <div className="vulnerabilities-action--items--header--section__arrow-down">
              <div className="accordion-arrow"></div>
            </div>
          </div>
          <div
            className={`vulnerabilities-action--items--header--section__actions-container ${
              selectedVulnActionItem ? 'd-none' : ''
            }`}
          >
            <ExportButton
              exportActionItemsOptions={EXPORT_VULNERABILITIES_OPTIONS}
              size="sm"
              title="Export"
              handleExport={handleExport}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="vulnerabilities-action--items--header--section">
          <span className="vulnerabilities-action--items--header--section__showing">
            {`Showing ${Math.max(pageSize * pageIndex + 1)} -
              ${
                pageSize * pageIndex + pageSize > totalRowCount ? totalRowCount : pageSize * pageIndex + pageSize
              } of ${totalRowCount} results`}
          </span>
          {isHidingColsSupported && (
            <DndProvider backend={HTML5Backend} debugMode={true}>
              <ToggleColumnsVisibility tableInstance={tableInstance} />
            </DndProvider>
          )}
        </Col>
        <Col className="vulnerabilities-action--items--header--section end">
          <span className="vulnerabilities-action--items--header--section__results">Results per page</span>
          <DropdownButton
            title={pageSize}
            size="sm"
            className="vulnerabilities-action--items--header--section__dropdown vulnerabilities-action--items--header--section__dropdown__page-size"
          >
            {PAGE_SIZE_OPTIONS?.map((pageSizeOption, idx) => (
              <Dropdown.Item
                key={idx}
                onClick={() => {
                  if (pageSize === pageSizeOption) return;
                  setLoaded && setLoaded(false);
                  setPageSize(pageSizeOption);
                }}
              >
                {pageSizeOption}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </Col>
      </Row>
      <Row>
        <LastSyncedDate
          noneSyncedDateTitle={strings.vulnerabilities.NoneSyncedDateTitle}
          syncedDate={syncedDate}
          syncedDateTitle={strings.vulnerabilities.SyncedDateTitle}
        />
      </Row>
    </div>
  );
};

export default TableCardHeader;
