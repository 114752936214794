import React, { useState, useEffect, useMemo, useRef } from 'react';

import { Form } from 'react-bootstrap';
import { useTable, useSortBy, useFlexLayout, useResizeColumns, usePagination } from 'react-table';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { Card } from '@fairwindsops/ui-components';

import PaginationComponent from '~reactComponents/pagination/pagination.react';
import SortIcon from '~reactIcons/SortIcon.icon.react';

import {
  RepositoryData,
  RepositoryTableData,
  TableAccessors,
  TimeUnits,
  TrendsData,
} from './RepoChart.types.react';

import {
  IRouter,
  Organization,
  Repository,
  RepositoryLastScan,
  SortStatus,
  UserFavorite,
} from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { SET_REPOSITORY } from '~store/action.types';

import { REACT_REPOSITORY } from '~reactComponents/NavigationReact/Navigation.config.react';

import store from '~store/index';

import {
  CALCULATED_ACTION_ITEMS,
  HIDDEN_SORT_ICONS,
  PAGE_SIZE,
  TABLE_COLUMNS,
} from './RepoChart.config.react';

import { getCurrentTimezone } from '~reactHelpers';
import { CardTitlePrimary } from '~utils/texts.react';

import './RepoChart.react.scss';

dayjs.extend(timezone);

type RepoChartProps = {
  organization: Organization;
  repositories: Repository[];
  router: () => IRouter;
};

const RepoChart = ({ organization, repositories, router }: RepoChartProps) => {
  const [repositoriesData, setRepositoriesData] = useState<RepositoryData[]>();
  const [filteredRepositoriesData, setFilteredRepositoriesData] = useState<RepositoryData[]>();
  const [userFavorites, setUserFavorites] = useState<UserFavorite[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);

  const [loadingTrends, setLoadingTrends] = useState<boolean>();

  const isMounted = useRef<boolean>(false);

  const baseURL = `/v0/organizations/${organization.Name}`;
  const timeZone = getCurrentTimezone();

  useEffect(() => {
    if (organization && repositories?.length) {
      isMounted.current = true;
      initData();
      setLoadingTrends(true);
      updateData();
    }
    return () => {
      isMounted.current = false;
    };
  }, [organization, repositories]);

  const initData = () => {
    const initializedData = repositories?.map(
      (repository: Repository) => ({ Name: repository.Name }) as RepositoryData,
    );
    setRepositoriesData(initializedData);
    setFilteredRepositoriesData(initializedData);
  };

  const updateData = async () => {
    const favorites = await getUserFavorites();
    const trends = await getTrends();
    const transformedRepositories = transformRepositories(
      repositories,
      trends.summaries,
      favorites,
    );
    if (isMounted.current) {
      if (favorites?.length) {
        setUserFavorites(favorites);
      }
      setRepositoriesData(transformedRepositories);
      setFilteredRepositoriesData(transformedRepositories);
      setTotalRowCount(transformedRepositories?.length || 0);
      setLoadingTrends(false);
    }
  };

  const getUserFavorites = async () => {
    try {
      return await sendRequest(
        'GET',
        `${baseURL}/user-favorites?resourceType=repository`,
        {},
        null,
      );
    } catch (e: any) {
      logger.logError('event_get_repositories_user_favorites', { message: e.message });
    }
  };

  const getTrends = async () => {
    const startDate = timeZone
      ? dayjs().subtract(2, TimeUnits.Month).utc().tz(timeZone).toISOString()
      : dayjs().subtract(2, TimeUnits.Month).utc().toISOString();
    const endDate = new Date().toISOString();
    try {
      return await sendRequest(
        'GET',
        `${baseURL}/ci/action-items/historical?window=weekly&startTime=${startDate}&endTime=${endDate}`,
        {},
        null,
      );
    } catch (e: any) {
      logger.logError('event_get_ci_action_items_historical', { message: e.message });
    }
  };

  const transformRepositories = (
    repositories: Repository[],
    trendsData: TrendsData[],
    favorites: UserFavorite[],
  ) => {
    return repositories?.map((repository) => {
      const repositoryTrends = getRepositoryTrends(repository, trendsData);
      const repositoryFavorited = getRepositoryFavoriteStatus(repository, favorites);
      return {
        ID: repository.ID,
        Favorite: repositoryFavorited,
        Name: repository.Name,
        Severities: getRepoSeverities(repository),
        Branches: repository.Branches?.length,
        ActionItems: getNumberOfActionItems(repository),
        EligibleAutoFixCount: repository.LastScan?.EligibleAutoFixCount,
        isTrendsGoingUp: isTrendsGoingUp(repositoryTrends),
        Trends: repositoryTrends,
      };
    });
  };

  const getRepositoryFavoriteStatus = (repository: Repository, favorites: UserFavorite[]) => {
    if (!favorites?.length) return false;
    return favorites.find((favorite) => +favorite.ResourceID === repository.ID) ? true : false;
  };

  const getRepositoryTrends = (repository: Repository, trendsData: TrendsData[]) => {
    if (!repository || !trendsData?.length) return null;
    return trendsData.find((trend) => trend.name === repository.Name)?.summary;
  };

  const getRepoSeverities = (repository: Repository) => ({
    nCritical: repository?.LastScan?.CriticalActionItems,
    nHigh: repository?.LastScan?.HighActionItems,
    nMedium: repository?.LastScan?.MediumActionItems,
  });

  const getNumberOfActionItems = (repository: Repository): number => {
    if (!repository?.LastScan) return 0;
    let nActionItems = 0;
    for (const key in repository.LastScan) {
      if (CALCULATED_ACTION_ITEMS.includes(key)) {
        nActionItems = nActionItems + +repository.LastScan[key as RepositoryLastScan];
      }
    }
    return nActionItems;
  };

  const isTrendsGoingUp = (trends: Record<string, number> | undefined | null): boolean => {
    if (!trends) return false;
    const transformedTrends = Object.values(trends);
    const nTrends = transformedTrends.length;
    let idx = nTrends - 1;
    while (idx > 0) {
      const currentValue = transformedTrends[idx];
      const nextValue = transformedTrends[idx - 1];
      if (currentValue > nextValue) return true;
      if (currentValue < nextValue) return false;
      idx--;
    }
    return false;
  };

  const onFilterUpdated = (e: React.BaseSyntheticEvent) => {
    const search = e.target as HTMLInputElement;
    setFilteredRepositoriesData(() =>
      repositoriesData?.filter(
        (repository: RepositoryData) => repository.Name && repository.Name.includes(search.value),
      ),
    );
  };

  const onFavoriteChanged = async (item: RepositoryTableData) => {
    if (!item) return;
    const selectedFavorite = findSelectedFavorite(item);
    try {
      if (selectedFavorite) {
        await removeFavorite(selectedFavorite, item);
      } else {
        await addFavorite(item);
      }
    } catch (e) {
      logger.logError('error_updating_favorites', e);
    }
  };

  const findSelectedFavorite = (item: RepositoryTableData) => {
    if (!item) return;
    return userFavorites?.find((favorite) => +favorite.ResourceID === item.ID);
  };

  const removeFavorite = async (selectedFavorite: UserFavorite, item: RepositoryTableData) => {
    item.Favorite = false;
    await sendRequest('DELETE', `${baseURL}/user-favorites/${selectedFavorite.ID}`, {}, null);
    updateUserFavorites(selectedFavorite, true);
    updateRepositoriesData(item);
  };

  const addFavorite = async (item: RepositoryTableData) => {
    item.Favorite = true;
    const insertedUserFavorite = await sendRequest(
      'POST',
      `${baseURL}/user-favorites`,
      {
        data: {
          ResourceType: 'repository',
          ResourceID: `${item.ID}`,
        },
      },
      null,
    );
    updateUserFavorites(insertedUserFavorite, false);
    updateRepositoriesData(item);
  };

  const updateUserFavorites = (selectedFavorite: UserFavorite, isRemoved: boolean) => {
    setUserFavorites((prevUserFavorites) =>
      isRemoved
        ? prevUserFavorites?.filter((favorite) => favorite.ID !== selectedFavorite.ID)
        : [...(prevUserFavorites || []), selectedFavorite],
    );
  };

  const updateRepositoriesData = (item: RepositoryTableData) => {
    setRepositoriesData((prevRepositoriesData) =>
      getUpdatedRepositoriesData(prevRepositoriesData, item),
    );
    setFilteredRepositoriesData((prevFilteredRepositoriesData) =>
      getUpdatedRepositoriesData(prevFilteredRepositoriesData, item),
    );
  };

  const getUpdatedRepositoriesData = (
    prevRepositoriesData: RepositoryData[] | undefined,
    item: RepositoryTableData,
  ) =>
    prevRepositoriesData?.map((repository) =>
      repository.Name === item.Name ? { ...repository, Favorite: item.Favorite } : repository,
    );

  const onRepositorySelected = (item: RepositoryTableData) => {
    if (!item) return;
    const selectedRepository = repositories?.find((repository) => repository.ID === item.ID);
    if (selectedRepository) {
      store.commit(SET_REPOSITORY, selectedRepository);
      router().push({
        name: REACT_REPOSITORY,
        params: {
          org: organization.Name,
          repo: selectedRepository.Name,
          from: 'repositories',
        },
      });
    }
  };

  const columns = useMemo(
    () => TABLE_COLUMNS({ loadingTrends, onFavoriteChanged, onRepositorySelected }),
    [filteredRepositoriesData, loadingTrends],
  );

  const getTableData = () => {
    if (!filteredRepositoriesData?.length) return [];
    return filteredRepositoriesData.map((repository) => {
      const { ID, Favorite, Name, Severities, Branches, ActionItems, EligibleAutoFixCount } =
        repository;
      return {
        ID,
        Favorite,
        Name,
        Severities,
        Branches,
        ActionItems,
        EligibleAutoFixCount,
        Trends: transformTrends(repository),
      };
    });
  };

  const transformTrends = (repository: RepositoryData) => {
    if (!repository?.Trends) return [];
    const data = getChartData(repository.Trends);
    return [
      {
        id: repository.Name,
        data,
      },
    ];
  };

  const getChartData = (trends: Record<string, number> | undefined | null) => {
    if (!trends) return null;
    const data = [];
    for (const [key, value] of Object.entries(trends)) {
      const date = timeZone
        ? dayjs(key).tz(timeZone).format(TimeUnits.MMDD)
        : dayjs(key).format(TimeUnits.MMDD);
      data.push({ x: date, y: value });
    }
    return data;
  };

  const data = useMemo(() => getTableData(), [filteredRepositoriesData]);

  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      initialState: {
        pageSize: PAGE_SIZE,
        sortBy: [
          {
            id: TableAccessors.Favorite,
            desc: true,
          },
        ],
      },
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
    usePagination,
  );

  const handlePaginationChange = (page: number): void => {
    gotoPage(page);
  };

  const renderSortIcon = (column: any) => {
    if (column.isSorted) {
      if (column.isSortedDesc)
        return <SortIcon sortStatus={SortStatus.Descending} width="1rem" height="1rem" />;
      return <SortIcon sortStatus={SortStatus.Ascending} width="1rem" height="1rem" />;
    }
    if (HIDDEN_SORT_ICONS.includes(column.Header)) return <></>;
    return <SortIcon sortStatus={SortStatus.None} width="1rem" height="1rem" />;
  };

  return (
    <Card className="repositories-chart-card" data-cy="repositories-chart-card">
      <Card.Title>
        <div className="repositories-chart-card__title">
          <div className="repositories-chart-card__left-title">
            <span className={CardTitlePrimary()}>All Repositories</span>
          </div>
          <Form.Group>
            <Form.Control
              id="filter-input"
              onChange={onFilterUpdated}
              placeholder="Search"
              type="search"
              aria-label="filter input"
            />
          </Form.Group>
        </div>
      </Card.Title>
      <Card.Body className="repositories-chart-card__body">
        <div>
          <table
            {...getTableProps()}
            className="repositories-chart-card__table"
            aria-label="Summary of repositories containing the number of action items and their severities"
            data-cy="repositories-table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <div className="repositories-chart-card__header-container">
                        <span className="repositories-chart-card__sort-icon">
                          {renderSortIcon(column)}
                        </span>
                        <span>{column.render('Header')}</span>
                      </div>
                      {column.canResize && (
                        <div {...column.getResizerProps()} className="draggable-column" />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="repositories-chart-card__body">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PaginationComponent
            currentPage={pageIndex}
            pageChange={handlePaginationChange}
            totalCount={Math.ceil(totalRowCount / pageSize)}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default RepoChart;
