import React from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import toast, { Toaster } from 'react-hot-toast';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import './PasswordReset.react.scss';
import { IRoute, IRouter } from '~utils/global.types.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { ORG_DASHBOARD, PROFILE } from '~reactComponents/NavigationReact/Navigation.config.react';
import { handlePageChange } from '~utils/global.helpers.react';

type FormData = { newPassword: string; confirmPassword: string };

const validationSchema = Joi.object({
  newPassword: Joi.string().trim().required().prefs({ convert: true }),
  confirmPassword: Joi.string().trim().required().prefs({ convert: true }),
});

type PasswordResetProps = {
  route: IRoute;
  router: () => IRouter;
};

const PasswordReset = ({ route, router }: PasswordResetProps) => {
  const organization = localStorage.getItem('organization') || '';
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<FormData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const updatePassword = async (data: FormData) => {
    const token = route.query?.token;

    const payload = {
      password: data.newPassword,
      confirm_password: data.confirmPassword,
      token,
    };

    const url = '/v0/auth/recover/end';

    try {
      const result = await sendRequest(
        'POST',
        url,
        {
          data: payload,
        },
        null,
      );

      reset({ newPassword: '', confirmPassword: '' });

      if (result && result.status === strings.noTranslate.failure) {
        let responseMessage = '';

        if (!result.errors.password && !result.errors.confirmPassword) {
          responseMessage = strings.userProfile.resetPasswordErrorMessage;
        } else {
          responseMessage = result.errors.password[0] || result.errors.confirmPassword[0];
        }

        toast.error(responseMessage);
      } else if (result && result.Failure === '') {
        toast.success(strings.userProfile.resetPasswordSuccessMessage);
      }
    } catch (e) {
      logger.logEvent('event_change_password', e);
    }
  };

  const submit = (data: FormData) => {
    if (!data) {
      return;
    }

    const { newPassword, confirmPassword } = data;

    if (newPassword !== confirmPassword) {
      setError('confirmPassword', {
        type: 'custon',
        message: strings.userProfile.confirmPasswordError,
      });
    }

    updatePassword(data);
  };

  // TODO: https://fairwinds.myjetbrains.com/youtrack/issue/FWI-4593/Password-Reset-Flow
  const breadcrumbsRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: organization,
      href: `/orgs/${organization}/dashboard`,
    },
    {
      id: PROFILE,
      label: strings.navigation.userSettings,
      href: `/user-settings/details`,
    },
    {
      id: 'last',
      label: 'Notifications',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      {/* We are just commenting this for now because we plan on adding these back in. */}
      {/* <Breadcrumbs
        data={breadcrumbsRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      /> */}
      <a href="/user-settings/details">Back to User Settings</a>
      <h1 className="title">{strings.userProfile.password}</h1>
      <Form onSubmit={handleSubmit((data: FormData) => submit(data))}>
        <FormGroup id="new-password-group" controlId="newPassword" className="password-input">
          <Form.Label>{strings.userProfile.newPassword}</Form.Label>
          <Form.Control
            {...register('newPassword')}
            className={`${errors.newPassword ? 'is-invalid' : ''}`}
            type="password"
            data-cy="new-password-text-input"
            maxLength={128}
            id="new-password"
          />
        </FormGroup>
        <FormGroup
          id="confirm-password-group"
          controlId="confirmPassword"
          className="password-input"
        >
          <Form.Label>{strings.userProfile.confirmPassword}</Form.Label>
          <Form.Control
            {...register(strings.noTranslate.confirmPassword)}
            className={`${errors.confirmPassword ? 'is-invalid' : ''}`}
            type="password"
            data-cy="confirm-password-text-input"
            maxLength={128}
            id="confirm-password"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.confirmPassword?.message}
          </Form.Control.Feedback>
        </FormGroup>
        <Button
          className="password-btn"
          type="submit"
          variant="primary"
          data-cy="submit-password-button"
          disabled={!isValid}
        >
          {strings.userProfile.updatePassword}
        </Button>
      </Form>
      <Toaster />
    </LayoutReact>
  );
};

export default PasswordReset;
