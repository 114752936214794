<template lang="pug">
datadog(:store="store" :router="router")
</template>

<script>
import Datadog from './Datadog.react';

export default {
  components: {
    Datadog,
  },
  methods: {
    store() {
      return this.$store;
    },
    router() {
      return this.$router;
    },
  },
  name: 'datadog-integration',
};
</script>
