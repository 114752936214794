import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

import CloseIcon from '~reactIcons/Close.icon.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Execution, Description, ExecutionStatus } from '../../logs/AutomationLogs.types.react';
import { Organization, Severity } from '~globalTypes';
import { escapeEvent, formatStatus } from '~reactHelpers';
import { sendRequest } from '~utils/request';

import { dateFromToday } from '~utils/helpers';

import { formatLog } from '../../logs/AutomationLogs.helpers.react';
import { TOP_ROW, CONTENT, LOG_EVENT_TYPES } from '../../logs/AutomationLogs.config.react';

import { useWindowEvents } from '~hooks/useWindowEvents.react';

import './DescriptionPanel.react.scss';

type DescriptionPanelProps = {
  organization: Organization;
  selectedExecution: Execution | null;
  setSelectedExecution: React.Dispatch<React.SetStateAction<Execution | null>>;
};

const DescriptionPanel = ({
  organization,
  selectedExecution,
  setSelectedExecution,
}: DescriptionPanelProps) => {
  const [description, setDescription] = useState<Description | null>();
  const [isSlided, setIsSlided] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const baseURL = `/v0/organizations/${organization.Name}`;

  useWindowEvents([
    escapeEvent(() => {
      closePanel();
    }),
  ]);

  useEffect(() => {
    if (organization && selectedExecution) {
      setIsSlided(true);
      getDescription();
    }
  }, [organization, selectedExecution]);

  const getDescription = async () => {
    setIsLoading(true);
    const response = await sendRequest(
      'GET',
      `${baseURL}/rules/${selectedExecution?.ruleId}/executions/${selectedExecution?.executionId}`,
      { showErrorAlert: false },
      null
    );
    const transformedDescription = transformDescription(response);
    setDescription(transformedDescription);
    setIsLoading(false);
  };

  const transformDescription = (response: any) => {
    const description = {} as Description;
    const { modifiedFields, events, logs } = getEventsAndModifiedFields(response.events);
    description.actionItem = response.actionItem;
    description.lastReportedAt = response.endedAt;
    description.modifiedFields = modifiedFields;
    description.events = events;
    description.logs = logs;
    return description;
  };

  const getEventsAndModifiedFields = (events: any): any => {
    if (!events?.length) return '';
    const modifiedFields = {};
    const combinedEvents = {};
    const logs = [] as string[];
    const sortedEvents = sortEvents(events);
    for (const event of sortedEvents) {
      getCombinedEvents(combinedEvents, event.details, event.type);
      getModifiedFields(modifiedFields, event.changelog);
      getLogs(logs, event);
    }
    const modifiedFieldsKeys = Object.keys(modifiedFields);
    const combinedEventsKeys = Object.keys(combinedEvents);
    return {
      modifiedFields: modifiedFieldsKeys?.length ? modifiedFieldsKeys.join(',') : '',
      events: combinedEventsKeys?.length ? combinedEventsKeys.join(',') : '',
      logs,
    };
  };

  const sortEvents = (events: any) =>
    events.sort((firstEvent: any, secondEvent: any) => firstEvent.id - secondEvent.id);

  const getCombinedEvents = (combinedEvents: any, details: string, eventType: string) => {
    if (!details || !eventType || LOG_EVENT_TYPES.includes(eventType) || combinedEvents[details])
      return;
    combinedEvents[details] = true;
  };

  const getModifiedFields = (modifiedFields: any, changelogs: any) => {
    if (!changelogs?.length) return;
    for (const changelog of changelogs) {
      const paths = changelog.path;
      updateModifiedFields(modifiedFields, paths);
    }
  };

  const updateModifiedFields = (modifiedFields: any, paths: any) => {
    for (const path of paths) {
      if (!modifiedFields[path]) {
        modifiedFields[path] = true;
      }
    }
  };

  const getLogs = (logs: string[], event: any) => {
    if (LOG_EVENT_TYPES.includes(event.type)) {
      const formattedLog = formatLog(event);
      if (formattedLog) {
        logs.push(formattedLog);
      }
    }
  };

  const getBadgeColor = (status: string) => {
    switch (status) {
      case ExecutionStatus.Error:
        return Severity.Critical;
      case ExecutionStatus.Warning:
        return Severity.High;
      case ExecutionStatus.Modified:
        return Severity.Medium;
      case ExecutionStatus.NoChange:
        return Severity.None;
      default:
        return undefined;
    }
  };

  const closePanel = () => {
    setIsSlided(false);
    setTimeout(() => {
      setSelectedExecution(null);
    }, 550);
  };

  const MetaData = (fn: any) => {
    if (selectedExecution && description) {
      return (
        <>
          {fn(selectedExecution, description).map((headerItem: any, key: any) => (
            <div key={key}>
              <h2 className="body-title">{headerItem.title}</h2>
              <span className="body-title__content">{headerItem.content}</span>
            </div>
          ))}
        </>
      );
    }
    return <></>;
  };

  const Description = () => {
    if (selectedExecution) {
      return (
        <>
          {CONTENT(description).map((contentItem, key) => {
            return contentItem.shouldShow ? (
              <div key={key}>
                <h2 className="automation-logs--description--content__title">
                  {contentItem.title}
                </h2>
                {contentItem.content}
              </div>
            ) : (
              <React.Fragment key={key}></React.Fragment>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  return (
    <Card
      className={`automation-logs--description ${
        isSlided ? '' : 'automation-logs--description__hidden'
      }`}
    >
      {isLoading && (
        <LoadingSpinner containerClassNames="automation-logs--description__loading-container" />
      )}
      {selectedExecution && !isLoading ? (
        <>
          <div className="automation-logs--description--header">
            <div className="automation-logs--description--header--container">
              <h2 className="automation-logs--description--header--container__title">
                {selectedExecution.name}
              </h2>
              <div className="badges" style={{ height: '3rem', alignItems: 'center' }}>
                <Badge pill variant={getBadgeColor(selectedExecution.status)}>
                  {selectedExecution.status ? formatStatus(selectedExecution.status) : ''}
                </Badge>
              </div>
            </div>
            <CloseIcon onClick={closePanel} className="automation-logs--description__close" />
            <div className="lower-title">
              <div className="event-time">
                <h2 className="event-time-title">Event Time</h2>
                <span className="event-time-diff">
                  {dateFromToday(selectedExecution.startDate)}
                </span>
              </div>
            </div>
          </div>
          <div className="body-content">
            <div className="body-row">{MetaData(TOP_ROW)}</div>
            <Description />
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default DescriptionPanel;
