import React from 'react';
import { Row } from 'react-table';

import Tag from '~reactComponents/Tags/Tag.react';
import UserAvatar from '~reactComponents/UserAvatar/UserAvatar.react';

import { AVATAR_COLORS } from '~utils/constants';
import { randomAvatarColor } from '~utils/helpers';
import { strings } from '~utils/strings';
import { AllTeamsTableConfiguration, AllTeamsTableMembership } from './AllTeamsTable.types.react';
import ExpandIcon from '~reactComponents/Icons/Expand.icon.react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

const chooseAvatarColor = (content: string) => randomAvatarColor(content, AVATAR_COLORS);

type getAllTeamsTableColumnsParams = {
  onTeamDeleted: (teamId: number) => void;
  onClusterChanged: (teamId: number, cluster: AllTeamsTableConfiguration) => void;
  onNamespaceChanged: (teamId: number, namespace: AllTeamsTableConfiguration) => void;
  onRepositoryChanged: (teamId: number, repository: AllTeamsTableConfiguration) => void;
  onExpandIconClicked: (teamId: number) => void;
  isOrgOwner: boolean;
};

export const getAllTeamsTableColumns = ({
  onTeamDeleted,
  onClusterChanged,
  onNamespaceChanged,
  onRepositoryChanged,
  onExpandIconClicked,
  isOrgOwner,
}: getAllTeamsTableColumnsParams) => [
  {
    Header: strings.teamManagement.team,
    accessor: 'Team',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      const teamName = row.original.Team;

      return (
        <div className="all-teams-table__team-container">
          {row.original.IsTeamAdmin ? (
            <ExpandIcon onClick={() => onExpandIconClicked(row.original.ID)} />
          ) : null}
          <span title={teamName}>{teamName}</span>
        </div>
      );
    },
    minWidth: 100,
  },
  {
    Header: strings.teamManagement.myRole,
    accessor: 'MyRole',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      return row.original.MyRole ? (
        <div className="all-teams-table__my-role-container">
          <PillBadge color="none" text={row.original.MyRole} />
        </div>
      ) : null;
    },
    minWidth: 50,
  },
  {
    Header: strings.teamManagement.name,
    accessor: 'Memberships',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      const memberships = row.original.Memberships;

      const transformMemberships = memberships.map((membership: AllTeamsTableMembership) =>
        membership?.firstName
          ? {
              firstName: membership.firstName.charAt(0).toUpperCase(),
              lastName: membership.lastName.charAt(0).toUpperCase(),
            }
          : membership,
      );

      return (
        <div className="all-teams-table__memberships-container">
          {transformMemberships.map((membership: AllTeamsTableMembership) => (
            <UserAvatar
              firstName={membership.firstName}
              lastName={membership.lastName}
              backgroundColor={chooseAvatarColor(`${membership.firstName} ${membership.lastName}`)}
            />
          ))}
        </div>
      );
    },
    minWidth: 150,
  },
  {
    Header: strings.teamManagement.clusters,
    accessor: 'Clusters',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      const clusters = row.original.Clusters;

      return (
        <div className="all-teams-table__tag-container">
          {clusters?.length
            ? clusters.map((cluster: AllTeamsTableConfiguration) => (
                <Tag
                  hollow={true}
                  fontSize={'0.675rem'}
                  isRemovable={isOrgOwner}
                  removeTag={() => onClusterChanged(row.original.ID, cluster)}
                  key={cluster.value}
                  tagClassNames={`all-teams-table__tag ${
                    !cluster.isAllowed ? 'all-teams-table__disallowed-tag' : ''
                  }`}
                >
                  {cluster.value === null ? strings.teamManagement.allClusters : cluster.value}
                </Tag>
              ))
            : null}
        </div>
      );
    },
    minWidth: 300,
  },
  {
    Header: strings.teamManagement.namespaces,
    accessor: 'Namespaces',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      const namespaces = row.original.Namespaces;

      return (
        <div className="all-teams-table__tag-container">
          {namespaces?.length
            ? namespaces.map((namespace: AllTeamsTableConfiguration) => (
                <Tag
                  hollow={true}
                  fontSize={'0.675rem'}
                  isRemovable={isOrgOwner}
                  removeTag={() => onNamespaceChanged(row.original.ID, namespace)}
                  key={namespace.value}
                  tagClassNames={`all-teams-table__tag ${
                    !namespace.isAllowed ? 'all-teams-table__disallowed-tag' : ''
                  }`}
                >
                  {namespace.value === null
                    ? strings.teamManagement.allNamespaces
                    : namespace.value}
                </Tag>
              ))
            : null}
        </div>
      );
    },
    minWidth: 300,
  },
  {
    Header: strings.teamManagement.repositories,
    accessor: 'Repositories',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      const repositories = row.original.Repositories;

      return (
        <div className="all-teams-table__tag-container">
          {repositories?.length
            ? repositories.map((repository: AllTeamsTableConfiguration) => (
                <Tag
                  hollow={true}
                  fontSize={'0.675rem'}
                  isRemovable={isOrgOwner}
                  removeTag={() => onRepositoryChanged(row.original.ID, repository)}
                  key={repository.value}
                  tagClassNames={`all-teams-table__tag ${
                    !repository.isAllowed ? 'all-teams-table__disallowed-tag' : ''
                  }`}
                >
                  {repository.value === null
                    ? strings.teamManagement.allRepositories
                    : repository.value}
                </Tag>
              ))
            : null}
        </div>
      );
    },
    minWidth: 300,
  },
  {
    Header: strings.teamManagement.action,
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      return !isOrgOwner ? null : (
        <span className="all-teams-table__remove" onClick={() => onTeamDeleted(row.original.ID)}>
          {strings.teamManagement.delete}
        </span>
      );
    },
    minWidth: 175,
  },
];
