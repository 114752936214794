import React, { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { AddButton } from '@fairwindsops/ui-components';
import Select, {
  MultiValueGenericProps,
  StylesConfig,
  components,
  createFilter,
} from 'react-select';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { AdvancedFilterOption, MenuListType, OptionType } from '~utils/global.types.react';

import './TeamAccess.react.scss';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

const MenuList = ({ children, maxHeight }: MenuListType) => (
  <Virtuoso
    style={{ height: maxHeight }}
    totalCount={children.length}
    itemContent={(index) => <div>{children[index]}</div>}
  />
);

const MultiValueContainer = (props: MultiValueGenericProps<any>) => (
  <components.MultiValueContainer {...props} />
);

const customFilterStyles: StylesConfig<OptionType, true> = {
  control: (provided) => ({
    ...provided,
    minHeight: '1.5rem',
    maxHeight: '4.5rem',
  }),
  valueContainer: (provided) => ({
    ...provided,
    alignItems: 'flex-start',
    maxHeight: '4.25rem',
    overflow: 'auto',
    padding: '4px 8px 0px 8px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#EBEEF7',
    color: COLORS.CORE.PRIMARY,
    alignContent: 'center',
  }),
};

type TeamAccessProps = {
  addButtonTitle?: string;
  options?: OptionType[];
  title?: string;
  onOptionChanged: (
    option: AdvancedFilterOption,
    removedValue: AdvancedFilterOption,
    action: string,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => void;
  sectionKey?: string;
  isAllowingTeamAccess?: boolean;
  dataCy?: string;
  defaultValue?: OptionType[];
  isDropDownShown?: boolean;
};

const TeamAccess = ({
  addButtonTitle = '',
  options,
  title,
  onOptionChanged,
  sectionKey,
  isAllowingTeamAccess,
  dataCy,
  defaultValue,
  isDropDownShown,
}: TeamAccessProps) => {
  const [isDropdownShown, setIsDropdownShown] = useState<boolean>(!!isDropDownShown);

  const onAddButtonClicked = () => {
    setIsDropdownShown(true);
  };

  return (
    <div className="team-access" data-cy={dataCy}>
      <div className="team-access__title">{title}</div>
      <div className="team-access__add-button-container">
        <AddButton
          action={onAddButtonClicked}
          buttonText={addButtonTitle}
          testLabel={strings.testLabels.addButton}
        />
      </div>
      {isDropdownShown ? (
        <Select
          className={`team-access__dropdown ${dataCy}`}
          classNamePrefix={`team-access__dropdown`}
          closeMenuOnSelect={false}
          components={{ Option, MenuList, MultiValueContainer }}
          controlShouldRenderValue={true}
          filterOption={createFilter({ ignoreAccents: false })}
          hideSelectedOptions={false}
          isClearable={false}
          isMulti
          onChange={(newOption, { action, removedValue, option }) => {
            if (onOptionChanged) {
              onOptionChanged(option, removedValue, action, sectionKey, isAllowingTeamAccess);
            }
          }}
          options={options}
          styles={customFilterStyles}
          value={defaultValue}
          isOptionDisabled={(option) => option.isDisabled}
        />
      ) : null}
    </div>
  );
};

export default TeamAccess;
