import React, { useMemo, useEffect, useState } from 'react';
import { Organization, Cluster, Repository, Report } from '~globalTypes';
import { Card } from '@fairwindsops/ui-components';
import './AgentStatus.react.scss';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { OrgSummary } from './AgentStatus.types.react';
import { strings } from '~utils/strings';
import { CardTitlePrimary, XSText, BasicText } from '~utils/texts.react';

type AgentProps = {
  organization: Organization;
  reports: Report[];
  repos: Repository[];
  clusters: Cluster[];
};

const AgentStatus = ({ organization, reports, repos, clusters }: AgentProps) => {
  const [orgSummary, setOrgSummary] = useState<OrgSummary>();
  const baseURL = `/v0/organizations/${organization.Name}`;

  useEffect(() => {
    getSummary();
  }, []);

  const calculateReportsInstalled = useMemo(() => {
    return reports.filter((report) => !report.Offline).length;
  }, [reports, organization]);

  const getSummary = async () => {
    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/summary`,
        { showErrorAlert: false },
        null,
      );
      setOrgSummary(response);
    } catch (e) {
      logger.logError('error_getting_summary_org_home_page: ', e);
    }
  };

  return (
    <Card>
      <Card.Body padded>
        <h2
          className={CardTitlePrimary({
            topMargin: 'sm',
            bottomMargin: strings.textStyling.medBottom,
          })}
        >
          Agent Status
        </h2>
        <div className="org-dash-agent-card__body">
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >{`${orgSummary?.numberOfClusters || 0} / ${clusters.length}`}</h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >{`${strings.installed} ${strings.general.Clusters}`}</h4>
          </div>
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >{`${orgSummary?.numberOfRepositories || 0} / ${repos.length}`}</h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >{`${strings.installed} ${strings.navigation.Repositories}`}</h4>
          </div>
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >{`${calculateReportsInstalled} / ${reports.length}`}</h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >
              {strings.runningReports}
            </h4>
          </div>
        </div>
        <div className="org-dash-agent-card__body">
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >
              {orgSummary?.averageNodesLast30Days || 0}
            </h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >{`${strings.average} ${strings.nodesL30D}`}</h4>
          </div>
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >
              {orgSummary?.numberOfOPAPolicies || 0}
            </h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >{`${strings.custom} ${strings.policiesSet}`}</h4>
          </div>
          <div>
            <h3
              className={BasicText({
                weight: strings.textStyling.medium,
                bottomMargin: strings.textStyling.medBottom,
              })}
            >
              {orgSummary?.numberOfAutomationRules || 0}
            </h3>
            <h4
              className={XSText({
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >{`${strings.custom} ${strings.rulesSet}`}</h4>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AgentStatus;
