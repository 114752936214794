import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Alert, Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { useTable, useFlexLayout } from 'react-table';
import ReactMarkdown from 'react-markdown';
import toast, { Toaster } from 'react-hot-toast';
import clsx from 'clsx';

import store from '~store/index';

import CloseIcon from '~reactIcons/Close.icon.react';
import CopyIcon from '~reactIcons/CopyIcon.react';

import { CISystem, ciSystems } from './AddRepository.types.react';
import {
  getTableColumns,
  POLLING_REPOSITORIES_INTERVAL_TIME,
  POLLING_REPOSITORIES_TIMEOUT,
} from './AddRepository.config.react';

import useVuexStore from '~hooks/useVuexStore';

import { Bot, CommonLabels, Repository } from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { copyToClipboard } from '~utils/helpers';
import { strings } from '~utils/strings';
import { CI_VERSION, CI_SHA, CI_PLUGIN_IMAGE_VERSION } from '~utils/constants';
import { ThinTitle, BasicText } from '~utils/texts.react';

import {
  TOGGLE_ADD_REPO_MODAL,
  SET_ORG_REPOSITORIES,
  TOGGLE_REPO_SETTINGS_MODAL,
} from '~store/action.types';

import './AddRepository.react.scss';

const AddRepository = () => {
  const { organization, repositories, isAddRepoModalShown, isRepoSettingsModalShown } =
    useVuexStore();

  const isOrgOwner = store.getters.isOrgOwner;

  const [isConnectManuallyModalShown, setIsConnectManuallyModalShown] = useState<boolean>(false);
  const [isTeamManagementModalShown, setIsTeamManagementModalShown] = useState<boolean>(false);
  const [selectedCiProvider, setSelectedCiProvider] = useState<CISystem>(ciSystems[0]);
  const [secondStepContent, setSecondStepContent] = useState<string>('');
  const [thirdStepContent, setThirdStepContent] = useState<string>('');

  const firstStepContent = useRef<string>(strings.repository.ConnectGithubManuallyFirstStepContent);
  const ciUrl = useRef<string>(`${window.location.origin}/v0/insights-ci-${CI_VERSION}.sh`);

  const baseURL = `/v0/organizations/${organization.Name}`;
  const pollingRepositoriesInterval = useRef<NodeJS.Timeout>();
  const isLimitMessageShown = repositories.length >= 1 && organization.Tier === 0;

  const hideAddRepoModal = () => {
    store.commit(TOGGLE_ADD_REPO_MODAL, false);
    store.commit(TOGGLE_REPO_SETTINGS_MODAL, false);
  };

  useEffect(() => {
    getFirstStepContent();
    getSecondStepContent();
    return () => {
      clearPollingRepositoriesInterval();
      store.commit(TOGGLE_ADD_REPO_MODAL, false);
      store.commit(TOGGLE_REPO_SETTINGS_MODAL, false);
    };
  }, []);

  useEffect(() => {
    if (selectedCiProvider) {
      const thirdStepContent = selectedCiProvider.instructions
        .replace('~ciUrl~', ciUrl.current)
        .replace('~ciSha~', CI_SHA)
        .replace('~ciPluginImageVersion~', CI_PLUGIN_IMAGE_VERSION);
      setThirdStepContent(thirdStepContent);
    }
  }, [selectedCiProvider]);

  useEffect(() => {
    setIsTeamManagementModalShown(isRepoSettingsModalShown);
  }, [isRepoSettingsModalShown]);

  const getFirstStepContent = () => {
    const firstStepContentParts = firstStepContent.current.split('\n');
    firstStepContentParts.splice(3, 0, `      hostname: ${window.location.origin}`);
    firstStepContent.current = firstStepContentParts
      .join('\n')
      .replace(/~orgName~/g, organization.Name);
  };

  const getSecondStepContent = async () => {
    if (isOrgOwner) {
      try {
        const bots = await sendRequest('GET', `${baseURL}/bots`, {}, null);
        const ciToken = bots.find((b: Bot) => b.Name === 'ci').AuthToken;
        const secondStepContent = '```\n' + ciToken + '\n```';
        setSecondStepContent(secondStepContent || '');
      } catch (e: unknown) {
        logger.logError('event_get_ci_token_add_repository', e);
      }
    }
  };

  const connectGithub = async () => {
    try {
      const githubLink = await getGithubLink();
      window.open(githubLink, '_blank');
      hideAddRepoModal();
      setIsTeamManagementModalShown(true);
      pollingRepositories();
    } catch (e: unknown) {
      logger.logError('event_connect_github_add_repository', e);
    }
  };

  const getGithubLink = async () => {
    try {
      const { authURL } = await sendRequest('GET', `${baseURL}/github`, {}, null);
      return `${authURL}?state=${organization.Name}`;
    } catch (e: unknown) {
      logger.logError('event_get_github_link_add_repository', e);
    }
  };

  const pollingRepositories = () => {
    let timeCounter = 0;
    pollingRepositoriesInterval.current = setInterval(async () => {
      timeCounter = timeCounter + POLLING_REPOSITORIES_INTERVAL_TIME;
      if (timeCounter > POLLING_REPOSITORIES_TIMEOUT) {
        clearPollingRepositoriesInterval();
        return;
      }
      const updatedRepositories = await getRepositories();
      if (updatedRepositories?.length !== repositories.length) {
        store.commit(SET_ORG_REPOSITORIES, updatedRepositories);
        clearPollingRepositoriesInterval();
        return;
      }
    }, POLLING_REPOSITORIES_INTERVAL_TIME);
  };

  const clearPollingRepositoriesInterval = () => {
    if (pollingRepositoriesInterval.current) {
      clearInterval(pollingRepositoriesInterval.current);
    }
  };

  const connectGithubManually = () => {
    hideAddRepoModal();
    setIsConnectManuallyModalShown(true);
  };

  const copy = (content: string) => async () => {
    content = content.replaceAll(CommonLabels.TripleBackticks, CommonLabels.Empty).trim();
    await copyToClipboard(content);
    toast.success(<b>{strings.general.CopiedToClipboard}</b>);
  };

  const hideConnectGithubManually = () => {
    setIsConnectManuallyModalShown(false);
    store.commit(TOGGLE_ADD_REPO_MODAL, false);
    store.commit(TOGGLE_REPO_SETTINGS_MODAL, false);
  };

  const onAutoScanChanged = async (repository: Repository) => {
    repository.AutoScan = !repository.AutoScan;
    await updateRepository(repository);
    await reloadRepositories();
  };

  const onIssueCreationChanged = async (repository: Repository) => {
    repository.Tickets = !repository.Tickets;
    await updateRepository(repository);
    await reloadRepositories();
  };

  const updateRepository = async (repository: Repository) => {
    try {
      await sendRequest(
        'PUT',
        `${baseURL}/ci/repositories/${repository.ID}`,
        {
          data: {
            AutoScan: repository.AutoScan,
            Tickets: repository.Tickets,
          },
        },
        null,
      );
    } catch (e: unknown) {
      logger.logError('event_update_repository', e);
      toast.error(strings.repository.CannotUpdateRepository);
    }
  };

  const reloadRepositories = async () => {
    const repositories = await getRepositories();
    store.commit(SET_ORG_REPOSITORIES, repositories);
  };

  const getRepositories = async () => {
    try {
      return await sendRequest('GET', `${baseURL}/ci/repositories`, {}, null);
    } catch (e: unknown) {
      logger.logError('event_get_repositories', e);
      toast.error(strings.repository.CannotLoadRepositories);
    }
  };

  const sortRepositories = (repositories: Repository[]) => {
    return repositories?.sort((firstRepo, secondRepo) => firstRepo.ID - secondRepo.ID);
  };

  const columns = useMemo(
    () => getTableColumns({ onAutoScanChanged, onIssueCreationChanged }),
    [repositories],
  );

  const data = useMemo(
    () => (repositories?.length ? sortRepositories(repositories) : []),
    [repositories],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  const hideTeamManagementModal = () => {
    setIsTeamManagementModalShown(false);
    clearPollingRepositoriesInterval();
    store.commit(TOGGLE_ADD_REPO_MODAL, false);
    store.commit(TOGGLE_REPO_SETTINGS_MODAL, false);
  };

  return (
    <>
      <Modal onHide={hideAddRepoModal} show={isAddRepoModalShown}>
        <Modal.Body className="custom-modal-body">
          <div className="custom-modal-title">
            <h3 className={ThinTitle({ size: 'sm' })}>{strings.repository.AddRepoModalTitle}</h3>
            <div className="custom-close-icon">
              <CloseIcon onClick={hideAddRepoModal} />
            </div>
          </div>
          <p className="add-repository__modal-content">
            {isLimitMessageShown && (
              <Alert className="add-repo-free-tier">
                You've reached the Insights free tier repository limit. You can add more
                repositories now and we'll be in touch to discuss unlimited access.
              </Alert>
            )}
            <p>{strings.repository.AddRepoModalContent}</p>
            <ul>
              <li>{strings.repository.AddRepoModalContent1}</li>
              <li>{strings.repository.AddRepoModalContent2}</li>
            </ul>
          </p>
        </Modal.Body>
        <Modal.Footer className="add-repository__modal-footer">
          <Button className="btn" variant="primary" onClick={connectGithub}>
            {strings.repository.ConnectGithub}
          </Button>
          <Button
            className="add-repository__connect-manually"
            variant="secondary"
            type="button"
            onClick={connectGithubManually}
          >
            {strings.repository.ConnectGithubManually}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={isConnectManuallyModalShown}
        onHide={hideConnectGithubManually}
        dialogClassName="connect-manually"
      >
        <Modal.Body className="custom-modal-body connect-manually__modal-body">
          <div className="custom-modal-title">
            <h3 className={ThinTitle({ size: 'sm' })}>{strings.repository.AddRepoModalTitle}</h3>
            <div className="custom-close-icon">
              <CloseIcon onClick={hideConnectGithubManually} />
            </div>
          </div>
          <div className="connect-manually__modal-content">
            <p>{strings.repository.ConnectGithubManuallyFirstStep}</p>
            <div className="connect-manually__copy-section">
              <div className="connect-manually__copy-icon" onClick={copy(firstStepContent.current)}>
                <CopyIcon />
              </div>
              <div className="connect-manually__copy-content">
                <ReactMarkdown>{firstStepContent.current}</ReactMarkdown>
              </div>
            </div>
            <div className="connect-manually__ci-providers">
              <h3 className={ThinTitle({ size: 'sm' })}>{strings.repository.CIProviders}</h3>
              <DropdownButton title={selectedCiProvider.name} size="sm">
                {ciSystems.map((provider, idx) => (
                  <Dropdown.Item key={idx} onClick={() => setSelectedCiProvider(provider)}>
                    {provider.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>
            <p>{strings.repository.ConnectGithubManuallySecondStep}</p>
            {isOrgOwner ? (
              <div className="connect-manually__copy-section">
                <div className="connect-manually__copy-icon" onClick={copy(secondStepContent)}>
                  <CopyIcon />
                </div>
                <div className="connect-manually__copy-content">
                  <ReactMarkdown>{secondStepContent}</ReactMarkdown>
                </div>
              </div>
            ) : null}
            <p>{strings.repository.ConnectGithubManuallyThirdStep}</p>
            <div className="connect-manually__copy-section">
              <div className="connect-manually__copy-icon" onClick={copy(thirdStepContent)}>
                <CopyIcon />
              </div>
              <div className="connect-manually__copy-content">
                <ReactMarkdown>{thirdStepContent}</ReactMarkdown>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal onHide={hideTeamManagementModal} show={isTeamManagementModalShown}>
        <Modal.Body className="custom-modal-body team-management__modal-body">
          <div className="custom-modal-title">
            <div>
              <h3 className={clsx('team-management__title', ThinTitle({ size: 'sm' }))}>
                {strings.repository.TeamManagementModalTitle}
              </h3>
              {isLimitMessageShown && (
                <Alert className="team-management__alert add-repo-free-tier">
                  You've reached the Insights free tier repository limit. You can add more
                  repositories now and we'll be in touch to discuss unlimited access.
                </Alert>
              )}
              <h4
                className={clsx(
                  'team-management__sub-title',
                  BasicText({ size: strings.textStyling.md }),
                )}
              >
                <p>{strings.repository.TeamManagementModalSubTitle}</p>
                <ul>
                  <li>{strings.repository.TeamManagementModalSubTitle1}</li>
                  <li>{strings.repository.TeamManagementModalSubTitle2}</li>
                </ul>
              </h4>
            </div>
            <div className="custom-close-icon team-management__close-icon-container">
              <CloseIcon onClick={hideTeamManagementModal} />
            </div>
          </div>
          <div className="team-management__modal-content">
            {repositories?.length ? (
              <>
                <table className="repo-table-container repo-table" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            <div>{column.render('Header')}</div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <div className="table-body">
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </div>
                </table>
              </>
            ) : (
              <div className="repos-empty-container">
                <span>{strings.repository.NoRepositories}</span>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Toaster />
    </>
  );
};

export default AddRepository;
