import React from 'react';

import './UserAvatar.react.scss';

type UserAvatarProps = {
  backgroundColor: string;
  firstName: string;
  lastName: string;
};

const UserAvatar = ({ firstName, lastName, backgroundColor }: UserAvatarProps) => {
  return (
    <div className={`user-avatar ${backgroundColor}`}>
      <span>{firstName.toUpperCase()}</span>
      <span>{lastName.toUpperCase()}</span>
    </div>
  );
};

export default UserAvatar;
