export interface ClusterItem {
  cluster: string;
  status: string;
}

export interface TableColumnsParams {
  onClusterSelected: (cluster: ClusterItem) => any;
  isOrgOwner: boolean;
}

export enum TableColumnNames {
  Cluster = 'Cluster',
  Delete = 'Delete',
}

export enum TableAccessors {
  Cluster = 'cluster',
}
