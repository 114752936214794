import React from 'react';

import { Button, Modal } from 'react-bootstrap';

import CloseIcon from '../Icons/Close.icon.react';

import './MultipleConfirmationDialog.react.scss';

type ConfirmButton = {
  classes?: string;
  text?: string;
  dataCyConfirmButton?: string;
  onClick?: () => void;
};

type MultipleConfirmationDialogProps = {
  modelSize?: 'sm' | 'lg' | 'xl';
  cancelButtonClasses?: string;
  cancelButtonText?: string;
  isModalShown?: boolean;
  modalBodyClasses?: string;
  modalContent?: string;
  modalContentClasses?: string;
  modalTitle?: string;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  modalClassName?: string;
  confirmationButtons?: ConfirmButton[];
};

const MultipleConfirmationDialog = ({
  modelSize,
  cancelButtonClasses,
  cancelButtonText,
  isModalShown,
  modalBodyClasses,
  modalContent,
  modalContentClasses,
  modalTitle,
  onModalHidden,
  confirmationButtons,
  modalClassName,
}: MultipleConfirmationDialogProps) => {
  const closeModal = () => {
    onModalHidden && onModalHidden(false);
  };

  return (
    <Modal show={isModalShown} size={modelSize} onHide={closeModal} className={modalClassName ? modalClassName : ''}>
      <Modal.Body className={`pt-0 ${modalBodyClasses ? modalBodyClasses : ''}`}>
        <div className="confirm-modal-title-container">
          <h3 className="modal-title">{modalTitle}</h3>
          <CloseIcon width="0.75rem" height="0.75rem" onClick={closeModal} className="confirm-modal-close-icon" />
        </div>
        <p className={`${modalContentClasses || ''}`}>{modalContent}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className={`btn ${cancelButtonClasses || ''}`} type="button" onClick={closeModal}>
          {cancelButtonText}
        </Button>
        {(confirmationButtons || []).map((button, index) => (
          <Button
            key={index}
            className={`btn ${button.classes || ''}`}
            type="button"
            onClick={() => button.onClick && button.onClick()}
            data-cy={button.dataCyConfirmButton || ''}
          >
            {button.text}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default MultipleConfirmationDialog;
