import React from 'react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import './Legend.react.scss';

const Legend = ({ display }: { display: string }) => {
  return (
    <div className="node-bullet-legend">
      <div className="node-bullet-legend__item">
        <div
          style={{ backgroundColor: COLORS.CHARTS.BULLET.MEASURE }}
          className="node-bullet-legend__icon"
        ></div>
        <strong style={{ paddingRight: '0.5rem' }}>
          {strings.general.Requests}
          {strings.punctuation.colon}
        </strong>
        {` ${strings.general.Total} ${display === 'cpu' ? strings.general.CPU : display} ${
          strings.efficiency.requestsAllWorkloads
        }`}
      </div>
      <div className="node-bullet-legend__item">
        <div
          style={{ backgroundColor: COLORS.CORE.DANGER }}
          className="node-bullet-legend__icon"
        ></div>
        <strong style={{ paddingRight: '0.5rem' }}>
          {strings.general.Limits}
          {strings.punctuation.colon}
        </strong>
        {` ${strings.general.Total} ${display === 'cpu' ? strings.general.CPU : display} ${
          strings.efficiency.limitsAllWorkloads
        }`}
      </div>
      <div className="node-bullet-legend__item">
        <div
          style={{ backgroundColor: COLORS.CHARTS.BULLET.RANGE_2 }}
          className="node-bullet-legend__icon"
        ></div>
        <strong style={{ paddingRight: '0.5rem' }}>
          {strings.navigation.Capacity}
          {strings.punctuation.colon}
        </strong>
        {` ${strings.general.Total} ${strings.general.available} ${
          display === 'cpu' ? strings.general.CPU : display
        } ${strings.efficiency.acrossAllNodes}`}
      </div>
      <div className="node-bullet-legend__item">
        <div
          style={{ backgroundColor: COLORS.CHARTS.BULLET.RANGE_1 }}
          className="node-bullet-legend__icon"
        ></div>
        <strong style={{ paddingRight: '0.5rem' }}>
          {strings.efficiency.Usage}
          {strings.punctuation.colon}
        </strong>
        {` ${strings.general.Total} ${display === 'cpu' ? strings.general.CPU : display} ${
          strings.efficiency.usedByAllWorkloads
        }`}
      </div>
    </div>
  );
};

export default Legend;
