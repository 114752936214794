import React, { useState, useEffect, useRef } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import Joi from 'joi';

import Icon from '~reactIcons/Icon.react';

import { Bot, TokenInput, invalidTokenNameMessage } from './AuthTokens.types.react';

import { IRouter, IStore } from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { Breadcrumbs } from '@fairwindsops/ui-components';
import { handlePageChange } from '~utils/global.helpers.react';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';

import './AuthTokens.react.scss';

type AuthTokensProps = {
  store: () => IStore;
  router: () => IRouter;
};

const validationSchema = Joi.object({
  name: Joi.string().required(),
  role: Joi.string().required(),
});

const AuthTokens = ({ store, router }: AuthTokensProps) => {
  const { organization, organizationTierAccess, isOrgOwner } = store().getters;

  const [bots, setBots] = useState<Bot[]>();
  const [isAuthCodeShown, setIsAuthCodeShown] = useState<boolean>(false);
  const [isSaveTokenModalShown, setIsSaveTokenModalShown] = useState<boolean>(false);
  const [isDeleteTokenModalShown, setIsDeleteTokenModalShown] = useState<boolean>(false);
  const [roles, setRoles] = useState<string[]>();

  const baseUrl = `/v0/organizations/${organization.Name}`;
  const deleteToken = useRef<Bot>();

  useEffect(() => {
    getTokenRoles();
    initBots();
  }, []);

  useEffect(() => {
    if (!isSaveTokenModalShown) {
      reset();
    }
  }, [isSaveTokenModalShown]);

  const getTokenRoles = async () => {
    try {
      const roles = await sendRequest('GET', '/v0/bots/roles', {}, null);
      setRoles(roles);
    } catch (e: any) {
      logger.logEvent('event_get_token_roles', { message: e.message });
    }
  };

  const initBots = async () => {
    if (organizationTierAccess.APIAuth) {
      await getBots();
      return true;
    }
    return false;
  };

  const saveToken = async (tokenInput: TokenInput) => {
    const matched = bots?.find((bot) => bot.Name === tokenInput?.name);
    if (matched) {
      setError('name', {
        type: 'manual',
        message: invalidTokenNameMessage,
      });
      return;
    }
    try {
      await sendRequest('POST', `${baseUrl}/bots`, { data: tokenInput }, null);
      await getBots();
      await displayAuthCodeOrModal();
      setIsSaveTokenModalShown(false);
    } catch (e: any) {
      logger.logEvent('event_get_bots', { message: e.message });
    }
    return null;
  };

  const getBots = async () => {
    try {
      const bots = await sendRequest('GET', `${baseUrl}/bots`, {}, null);
      setBots(bots);
    } catch (e: any) {
      logger.logEvent('event_get_bots', { message: e.message });
    }
  };

  const displayAuthCodeOrModal = async () => {
    const retrieved = await initBots();
    if (retrieved) {
      setIsAuthCodeShown(true);
    }
  };

  const openSaveTokenModal = () => {
    setIsSaveTokenModalShown(true);
  };

  const confirmDeleteTokenModal = (token: Bot) => () => {
    if (!token) return;
    deleteToken.current = token;
    setIsDeleteTokenModalShown(true);
  };

  const deleteSelectedToken = async () => {
    try {
      await sendRequest('DELETE', `${baseUrl}/bots/${deleteToken.current?.ID}`, {}, null);
      await getBots();
    } catch (e: any) {
      logger.logEvent('event_delete_token', { message: e.message });
    }
    setIsDeleteTokenModalShown(false);
    deleteToken.current = {} as Bot;
  };

  const { register, handleSubmit, setError, reset, formState } = useForm<TokenInput>({
    resolver: joiResolver(validationSchema),
    mode: 'onChange',
  });

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: 'last',
      label: strings.navigation.tokens,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="auth-div">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner && (
        <div>
          <h1 className="auth-title">Authentication Tokens</h1>
          <span>
            You are only allowed to change these settings if you are an owner. Please contact your
            organization's owner to make changes.
          </span>
        </div>
      )}
      {isOrgOwner && (
        <div>
          <h1 className="auth-title">
            <span className="float-right">
              <Button variant="primary" onClick={openSaveTokenModal} data-cy="add-token-button">
                <i className="fa fa-plus plus-icon"></i>
                Add Token
              </Button>
            </span>
          </h1>
          <div>
            <p>Use the following tokens to make calls to the Insights API.</p>
            {!isAuthCodeShown && (
              <button
                className="btn btn-primary btn-min-width"
                onClick={displayAuthCodeOrModal}
                data-cy="display-tokens-button"
              >
                Show Tokens
              </button>
            )}
            {isAuthCodeShown && organizationTierAccess.APIAuth && (
              <>
                <div className="bot-list">
                  {bots
                    ?.sort((token1, token2) => (token1.ID < token2.ID ? -1 : 1))
                    .map((bot: Bot) => (
                      <div key={bot.Name} data-cy="token">
                        <div>
                          {bot.Name} ({bot.Role})
                          <div className="token">
                            <span>
                              <pre className="pre-code">
                                <code>{bot.AuthToken}</code>
                              </pre>
                            </span>
                            <div className="token-button" data-cy="token-action-button">
                              <Button
                                variant="danger"
                                type="button"
                                onClick={confirmDeleteTokenModal(bot)}
                              >
                                {bot.Name != 'ci' && bot.Name != 'admin' && (
                                  <Icon name="trash" className="delete-icon token-delete-icon" />
                                )}
                                <span>
                                  {bot.Name == 'ci' || bot.Name == 'admin'
                                    ? 'Regenerate'
                                    : 'Remove'}
                                </span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  className="btn btn-primary btn-min-width btn-token"
                  data-cy="display-tokens-button"
                  onClick={() => setIsAuthCodeShown(false)}
                >
                  Hide Tokens
                </button>
              </>
            )}
          </div>
          <Modal show={isSaveTokenModalShown} onHide={() => setIsSaveTokenModalShown(false)}>
            <Modal.Body className="pt-0">
              <Form
                onSubmit={handleSubmit(
                  (data) => saveToken(data),
                  (e) => console.error(e),
                )}
              >
                <h3 className="modal-title">Add New Token</h3>
                <Form.Group controlId="tokenName" className="name-container">
                  <Form.Label className="fw-normal">Name</Form.Label>
                  <Form.Control
                    className="form-element"
                    type="text"
                    isInvalid={formState.errors.name ? true : false}
                    {...register('name')}
                    data-cy="token-name-text-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {invalidTokenNameMessage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="tokenRole" className="role-container">
                  <Form.Label className="fw-normal">Role</Form.Label>
                  <Form.Control
                    as="select"
                    aria-label="Roles Select"
                    className="custom-select form-element"
                    {...register('role')}
                    data-cy="token-role-dropdown"
                  >
                    <option value={''} key={'empty-role'} hidden></option>
                    {roles?.map((role: string) => (
                      <option value={role} key={role}>
                        {role}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="token-modal-footer">
                  <Button
                    className="btn saved-view-button"
                    variant="primary"
                    onClick={() => setIsSaveTokenModalShown(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-danger saved-view-button"
                    variant="secondary"
                    type="submit"
                    disabled={!formState.isValid}
                    data-cy="submit-token-button"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal show={isDeleteTokenModalShown} onHide={() => setIsDeleteTokenModalShown(false)}>
            <Modal.Body className="pt-0">
              <h3 className="modal-title">
                {deleteToken.current?.Name === 'ci' || deleteToken.current?.Name === 'admin'
                  ? 'Regenerate Token'
                  : 'Delete Token'}
              </h3>
              {deleteToken.current?.Name === 'ci' || deleteToken.current?.Name === 'admin' ? (
                <span>
                  Are you sure you want to regenerate {deleteToken.current?.Name}? The previous
                  token will be deleted and a new token will be created.
                </span>
              ) : (
                <span>Are you sure you want to delete {deleteToken.current?.Name}?</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn"
                variant="primary"
                onClick={() => setIsDeleteTokenModalShown(false)}
              >
                Cancel
              </Button>
              <Button className="btn btn-danger" type="button" onClick={deleteSelectedToken}>
                {deleteToken.current?.Name === 'ci' || deleteToken.current?.Name === 'admin'
                  ? 'Regenerate'
                  : 'Delete'}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AuthTokens;
