// eslint-disable-next-line
import TrialExpired from './TrialExpired.react';

export default {
  components: {
    'trial-expired': TrialExpired,
  },
  methods: {
    store() {
      return this.$store;
    },
  },
};
