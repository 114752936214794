import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { CurrentUser, IStore } from '~utils/global.types.react';
import { UPDATE_CURRENT_USER } from '~store/action.types';

import { strings } from '~utils/strings';
import { getCurrentUser, sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { ThinTitle } from '~utils/texts.react';

import './UserNotificationsComponent.react.scss';

type NotificationsComponentProps = {
  store: () => IStore;
};

const NotificationsComponent = ({ store }: NotificationsComponentProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const [user, setUser] = useState<CurrentUser>();

  const getUser = async () => {
    const user = await getCurrentUser();
    store().dispatch(UPDATE_CURRENT_USER, user.user);
    setUser(user.user);
  };

  useEffect(() => {
    getUser();
  }, []);

  const updateEmail = async () => {
    const data = {
      EmailDigest: !user?.EmailDigest,
    } as { EmailDigest: boolean; Token: string };

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      data.Token = token;
    }

    try {
      await sendRequest('POST', '/v0/email-digest', { data }, null);
      await getUser();
    } catch (e) {
      logger.logError('event_update_email_digest', e);
    }

    setSelected(false);
  };

  if (!user) {
    return <></>;
  }

  return (
    <div className="user-notification-component">
      <div>
        <h2
          className={ThinTitle({
            textTransform: strings.textStyling.capitalize,
            bottomMargin: strings.textStyling.medBottom,
            topMargin: strings.textStyling.md,
          })}
        >
          {strings.userProfile.email}
        </h2>
        {user.EmailDigest ? (
          <div className="component-sub-title">{strings.userProfile.unSubscribeEmailContent}</div>
        ) : (
          <div className="component-sub-title">{strings.userProfile.subscribeEmailContent}</div>
        )}
        <div>
          <input
            checked={selected}
            data-cy="notification-checkbox"
            id="notifications-checkbox"
            name="notifications-checkbox"
            onChange={() => setSelected((prevState) => !prevState)}
            type="checkbox"
          />
          <label className="checkbox-label" htmlFor="notifications-checkbox">
            {user.EmailDigest ? strings.userProfile.unsubscribe : strings.userProfile.subscribe}
          </label>
        </div>
        <Button
          className="update-btn"
          data-cy="submit-notification-button"
          disabled={!selected}
          onClick={updateEmail}
        >
          {strings.userProfile.update}
        </Button>
      </div>
    </div>
  );
};

export default NotificationsComponent;
