import React, { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTable, useSortBy, useResizeColumns, useFlexLayout } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';

import { AddButton } from '@fairwindsops/ui-components';

import Table from '~reactComponents/Table/Table.react';

import { IRoute, IRouter, IStore, OptionType, Team, TeamMembership } from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import { TeamAccountTableData, getAccountTableColumns } from './TeamAccountsTable.config.react';

import './TeamAccountsTable.react.scss';

type TeamAccountsTableProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  teamId: string;
};

const TeamAccountsTable = ({ route, router, store, teamId }: TeamAccountsTableProps) => {
  const [memberships, setMemberships] = useState<TeamMembership[]>();
  const [filteredMemberships, setFilteredMemberships] = useState<TeamAccountTableData[]>([]);
  // the list of emails that does not belong to the selected team.
  const [nonMembershipEmails, setNonMembershipEmails] = useState<string[]>([]);

  const organization = store().getters.organization;
  const user = store().getters.user;
  const isOrgOwner = store().getters.isOrgOwner;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const roles = useRef<OptionType[]>([
    { label: strings.general.admin, value: strings.general.admin },
    { label: strings.general.editor, value: strings.general.editor },
    { label: strings.general.viewer, value: strings.general.viewer },
  ]);

  const findRoleForEachMembership = (selectedTeam: Team, memberships: TeamMembership[]) => {
    if (!selectedTeam?.Memberships?.length || !memberships?.length || !selectedTeam) {
      return;
    }

    setFilteredMemberships([
      ...memberships.map((membership: TeamMembership) => {
        const teamAccountTableData = { ...membership } as TeamAccountTableData;

        teamAccountTableData.Role = selectedTeam.Memberships.find((mem) => mem.UserID === membership.Email)?.Role;

        return teamAccountTableData;
      }),
    ]);
  };

  const filterTeamMemberships = (selectedTeam: Team, teams: Team[], teamMemberships: TeamMembership[]) => {
    if (teams?.length && teamMemberships?.length) {
      findRoleForEachMembership(selectedTeam, teamMemberships);
    } else {
      setFilteredMemberships(teamMemberships);
    }
  };

  const getData = async () => {
    try {
      const teams = (await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || [];
      const selectedTeam = teams.find((team: Team) => team.ID === +teamId);
      const memberships = await sendRequest('GET', `${baseUrl}/memberships`, {}, null);

      setMemberships(memberships);
      setNonMembershipEmails(
        memberships
          .filter((membership: TeamMembership) => !membership?.Teams?.includes(+teamId))
          .map((mem: TeamMembership) => mem.Email),
      );

      filterTeamMemberships(
        selectedTeam,
        teams,
        memberships.filter((membership: TeamMembership) => membership?.Teams?.includes(+teamId)),
      );
    } catch (e) {
      logger.logError('error_getting_data_team-management', e);
    }
  };

  useEffect(() => {
    if (teamId) {
      getData();
    }
  }, [teamId]);

  const removeExistingUser = async (email: string) => {
    if (!email) {
      return;
    }

    try {
      await sendRequest(
        'DELETE',
        `${baseUrl}/memberships`,
        {
          data: { Email: email },
        },
        null,
      );

      await getData();

      toast.success(strings.teamManagement.deleteMembershipSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.deleteMembershipFailed);
      logger.logError('error_updating_membership_role', e);
    }
  };

  const removeNewRow = (id?: string) => {
    if (!id) {
      return;
    }

    setFilteredMemberships((prevData) => prevData.filter((data) => data.ID !== id));
  };

  const onMembershipRemoved = async (email: string, id?: string) => {
    if (id) {
      removeNewRow(id);
    } else {
      await removeExistingUser(email);
    }
  };

  const addNewMember = async (newMember: TeamAccountTableData) => {
    if (!newMember?.Email || !newMember?.Role) {
      return;
    }

    try {
      await sendRequest(
        'PATCH',
        `${baseUrl}/teams/${teamId}/memberships`,
        {
          data: {
            UserID: newMember.Email,
            Role: newMember.Role,
            TeamID: +teamId,
          },
        },
        null,
      );

      toast.success(strings.teamManagement.addNewMemberSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.addNewMemberFailed);
      logger.logError('error_adding_member_to_team', e);
    }
  };

  const onNewRoleAdded = async (selectedRole: string, id: string) => {
    if (!selectedRole || !id) {
      return;
    }

    const selectedTableRow = filteredMemberships.find((membership: TeamAccountTableData) => membership.ID === id);

    if (!selectedTableRow) {
      return;
    }

    selectedTableRow.Role = selectedRole;

    if (selectedTableRow.Email) {
      await addNewMember(selectedTableRow);
      await getData();
      return;
    }

    setFilteredMemberships((prevData) => prevData.map((data) => (data.ID === id ? selectedTableRow : data)));
  };

  const onRoleChanged = async (email: string, selectedRole: string) => {
    if (!email || !selectedRole) {
      return;
    }

    try {
      await sendRequest(
        'PATCH',
        `${baseUrl}/teams/${teamId}/memberships`,
        {
          data: {
            UserID: email,
            Role: selectedRole,
            TeamID: +teamId,
          },
        },
        null,
      );

      await getData();

      toast.success(strings.teamManagement.changeRoleSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.changeRoleFailed);
      logger.logError('error_updating_membership_role', e);
    }
  };

  const onEmailSelected = async (email: string, id: string) => {
    if (!email || !id) {
      return;
    }

    const selectedMembership = memberships?.find((membership: TeamMembership) => membership.Email === email);

    if (!selectedMembership) {
      return;
    }

    const selectedTableRow = filteredMemberships.find((membership: TeamAccountTableData) => membership.ID === id);

    if (!selectedTableRow) {
      return;
    }

    selectedTableRow.Email = email;
    selectedTableRow.FirstName = selectedMembership.FirstName;
    selectedTableRow.LastName = selectedMembership.LastName;

    if (selectedTableRow.Role) {
      await addNewMember(selectedTableRow);
      await getData();
      return;
    }

    setFilteredMemberships((prevData) => prevData.map((data) => (data.ID === id ? selectedTableRow : data)));
  };

  const columns = useMemo(
    () =>
      getAccountTableColumns({
        onMembershipRemoved,
        onRoleChanged,
        onEmailSelected,
        onNewRoleAdded,
        user,
        isOrgOwner,
        roles: roles.current,
        nonMembershipEmails: nonMembershipEmails.map((email) => ({ label: email, value: email })),
      }),
    [filteredMemberships],
  );

  const buildTableRowData = (membership: TeamAccountTableData) =>
    membership
      ? {
          IsAddingNewMember: !!membership.isAddingNewMember,
          ID: membership.ID,
          Email: membership.Email,
          Name:
            membership.FirstName && membership.LastName
              ? `${membership.FirstName} ${membership.LastName}`
              : strings.teamManagement.pendingUser,
          Role: membership.Role,
        }
      : {};

  const transformMembershipsForTable = () => {
    if (!filteredMemberships?.length) {
      return [];
    }

    return filteredMemberships.map((membership: TeamAccountTableData) => buildTableRowData(membership));
  };

  const data = useMemo(() => transformMembershipsForTable(), [filteredMemberships]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
  );

  const onNewMemberRowAdded = () => {
    setFilteredMemberships((prevData) => [
      {
        ID: uuidv4(),
        CreatedOn: '',
        Email: '',
        FirstName: '',
        IsOwner: false,
        LastName: '',
        Organization: '',
        Teams: [],
        Role: '',
        isAddingNewMember: true,
      },
      ...prevData,
    ]);
  };

  return (
    <>
      <div className="team-accounts-table__body">
        {isOrgOwner && (
          <div className="team-accounts-table__add-member-container">
            <span>{strings.teamManagement.allMembers}</span>
            <AddButton
              action={onNewMemberRowAdded}
              buttonText={strings.teamManagement.addMember}
              class="team-accounts-table__add-button"
              testLabel="add-button-team-account"
            />
          </div>
        )}
        <div className="team-accounts-table__body-table">
          <Table aria-hidden="true" tableInstance={tableInstance} caption={strings.teamManagement.teamAccountsTable} />
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default TeamAccountsTable;
