import TopCVEs from '../Charts/TopCVEs/TopCVEs.react';
import SeverityBreakdown from '../Charts/SeverityBreakdown/SeverityBreakdown.react';
import TopImpactedPackages from '../Charts/TopImpactedPackages/TopImpactedPackages.react';
import { FiltersMapType } from '~globalTypes';

export const Labels = {
  cveSeverities: 'cveSeverities',
  Unavailable: 'Unavailable',
  AllVulnerabilitiesVulnerabilitiesItems: 'All Vulnerabilities Page - Vulnerabilities Items',
  trivy: 'trivy',
  NoVulnerabilitiesSelectedErrMessage: 'Please select vulnerabilities',
  HasFixedVersion: 'hasFixedVersion',
  FixedVersion: 'fixedVersion',
} as const;

export const ChartLabels = {
  TopImpactedImages: {
    title: 'Top CVEs',
    component: TopCVEs,
  },
  SeverityBreakdown: {
    title: 'Severity Breakdown',
    component: SeverityBreakdown,
  },
  TopImpactedPackages: {
    title: 'Top Impacted Packages',
    component: TopImpactedPackages,
  },
} as const;

export interface VulnerabilitiesActionItems {
  cveCode: string;
  cveTitle: string;
  cveDescription: string;
  cveSeverity: string;
  imagesCount: number;
  packageName: string;
  fixedVersion: string;
  installedVersion: string;
  recommendedTag: string;
  createdAt: string;
  imageNames: string[];
}

export const sortingFields = [
  'cveCodes',
  'cveTitles',
  'cveSeverities',
  'imagesCount',
  'packageNames',
  'fixedVersions',
  'installedVersions',
  'recommendedTags',
];

export const postfixFields = {
  imagesCount: '+',
};

export const filtersMap: FiltersMapType = {
  cveCodes: 'cveCode',
  cveTitles: 'cveTitle',
  cveSeverities: 'cveSeverity',
  imagesCount: 'imagesCount',
  packageNames: 'packageName',
  hasFixedVersions: 'hasFixedVersion',
  installedVersions: 'installedVersion',
  recommendedTags: 'recommendedTag',
};

export interface DescriptionTopRow {
  title: string;
  content: string | boolean;
  isHidden?: boolean;
}

export interface Member {
  CreatedOn: string;
  Email: string;
  FirstName: string;
  IsOwner: boolean;
  LastName: string;
  Organization: string;
  Teams: number[];
}

export const EXPORT_FIELDS_MAP: Record<string, string> = {
  cveCode: 'CVE ID',
  cveTitle: 'Title',
  cveSeverity: 'Severity',
  imagesCount: 'Images',
  packageName: 'Package',
  fixedVersion: 'Fixed Version',
  installedVersion: 'Installed',
};

export interface AffectedImagesInfo {
  ID: number;
  SHA: string;
  Name: string;
  BaseImage: string;
  Tag: string;
  ClustersCount: number;
}
