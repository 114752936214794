import React, { Children } from 'react';
import { TableInstance, Row } from 'react-table';
import Select, { components, ValueContainerProps } from 'react-select';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { filtersMap, sortingFields } from '../../logs/AutomationLogs.types.react';
import { mapFilters, formatStatus } from '~reactHelpers';
import { FiltersMapType, OptionType } from '~globalTypes';

import { SELECT_STYLES } from '../../logs/AutomationLogs.config.react';

import { COLORS } from '~utils/styling';

import './SelectColumnFilter.react.scss';

type SelectColumnFilterType = {
  tableInstance: TableInstance;
  filters: FiltersMapType;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    const typedChild = child as Record<string, any>;
    return child && allowedTypes.includes(typedChild.type.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const SelectColumnFilter = ({ tableInstance, filters }: SelectColumnFilterType) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = tableInstance;

  const mappedFilters = mapFilters(filters, filtersMap, sortingFields);

  let options: OptionType[];

  if (mappedFilters && mappedFilters[id] && Array.isArray(mappedFilters[id])) {
    const toBeMapped = mappedFilters[id] || [];
    options = Array.isArray(toBeMapped)
      ? toBeMapped.map((option: any) => {
          if (typeof option === 'object') {
            return option;
          }
          return {
            label: id === 'status' ? formatStatus(option as string) : option,
            value: option,
          };
        })
      : [];
  } else {
    options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows?.forEach((row: Row) => {
        options.add(row.values[id]?.toString());
      });
      return Array.from(options).map((option: any) => ({
        label: option,
        value: option,
      }));
    }, [id, preFilteredRows]);
  }

  const handleChange = (selected: any) => {
    setFilter(selected);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={filterValue}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          // eslint-disable-next-line
          // @ts-ignore
          ValueContainer,
        }}
        onChange={(selected) => handleChange(selected)}
      />
    </div>
  );
};

export default React.memo(SelectColumnFilter);
