import React, { useEffect, useMemo, useState } from 'react';

import { Modal } from 'react-bootstrap';

import toast, { Toaster } from 'react-hot-toast';

import {
  AdvancedFilterOption,
  DESELECT_OPTION,
  IRoute,
  IRouter,
  IStore,
  Team,
  TeamAccessAllSelected,
} from '~utils/global.types.react';

import CloseIcon from '~reactComponents/Icons/Close.icon.react';

import TeamAccountsTable from '../teamAccountsTable/TeamAccountsTable.react';
import TeamAccess from '~views/organization/settings/createTeam/components/team-access/TeamAccess.react';
import { TeamAccessElement } from '~views/organization/settings/createTeam/CreateTeam.react.types';

import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

import './TeamModal.react.scss';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

type TeamModalProps = {
  isModalShown?: boolean;
  modalTitle?: string;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  teamId: string;
};

type SelectedOptions = Record<string, Record<string, string[]>>;

const TeamModal = ({ isModalShown, modalTitle, onModalHidden, route, router, store, teamId }: TeamModalProps) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    clusters: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
    repositories: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
    namespaces: {
      allowedTeamAccess: [],
      blockedTeamAccess: [],
    },
  });
  const [clusters, setClusters] = useState<string[]>([]);
  const [repositories, setRepositories] = useState<string[]>([]);
  const [namespaces, setNamespaces] = useState<string[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allowTeamAccessAllSelection, setAllowTeamAccessAllSelection] = useState<TeamAccessAllSelected>({
    isAllClusterSelected: false,
    isAllNamespaceSelected: false,
    isAllRepoSelected: false,
  });

  const organization = store().getters.organization;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const getClusters = async () => {
    try {
      const clusters = (await sendRequest('GET', `${baseUrl}/all-clusters`, {}, null)) || [];

      setClusters(clusters);

      return clusters;
    } catch (e) {
      logger.logError('error_getting_all_clusters_team_management', e);
    }
  };

  const getRepositories = async () => {
    try {
      const repositories = (await sendRequest('GET', `${baseUrl}/all-repositories`, {}, null)) || [];

      setRepositories(repositories);

      return repositories;
    } catch (e) {
      logger.logError('error_getting_all_repositories_team_management', e);
    }
  };

  const getNamespaces = async () => {
    try {
      const namespaces = (await sendRequest('GET', `${baseUrl}/all-namespaces`, {}, null)) || [];

      setNamespaces(namespaces);

      return namespaces;
    } catch (e) {
      logger.logError('error_getting_all_namespaces_team_management', e);
    }
  };

  const initSelectedOptions = (team: Team | null) => {
    if (!team) {
      return;
    }

    selectedOptions.clusters.allowedTeamAccess =
      team?.Clusters === null ? [team.Clusters] : !team?.Clusters?.length ? [] : team.Clusters;

    selectedOptions.repositories.allowedTeamAccess =
      team?.Repositories === null ? [team.Repositories] : !team?.Repositories?.length ? [] : team.Repositories;

    selectedOptions.namespaces.allowedTeamAccess =
      team?.Namespaces === null ? [team.Namespaces] : !team?.Namespaces?.length ? [] : team.Namespaces;

    selectedOptions.clusters.blockedTeamAccess =
      team?.DisallowedClusters === null || !team?.DisallowedClusters?.length ? [] : team.DisallowedClusters;

    selectedOptions.repositories.blockedTeamAccess =
      team?.DisallowedRepositories === null || !team?.DisallowedRepositories?.length ? [] : team.DisallowedRepositories;

    selectedOptions.namespaces.blockedTeamAccess =
      team?.DisallowedNamespaces === null || !team?.DisallowedNamespaces?.length ? [] : team.DisallowedNamespaces;

    setSelectedOptions({ ...selectedOptions });
  };

  const initAllowTeamAccessAllSelection = (team: Team | null) => {
    if (!team) {
      return;
    }

    setAllowTeamAccessAllSelection((prev) => ({
      ...prev,
      isAllClusterSelected: team.Clusters === null,
      isAllNamespaceSelected: team.Namespaces === null,
      isAllRepoSelected: team.Namespaces === null,
    }));
  };

  const getTeams = async (clusters: string[], repositories: string[], namespaces: string[]) => {
    try {
      const teams = (await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || [];
      const team = teams.find((team: Team) => team.ID === +teamId) || null;

      setTeams(teams);
      setSelectedTeam(team);
      initSelectedOptions(team);
      initAllowTeamAccessAllSelection(team);
    } catch (e) {
      logger.logError('error_getting_all_teams_team_management', e);
    }
  };

  useEffect(() => {
    if (teamId) {
      (async function () {
        setIsLoading(true);
        const clusters = await getClusters();
        const repositories = await getRepositories();
        const namespaces = await getNamespaces();
        await getTeams(clusters, repositories, namespaces);
        setIsLoading(false);
      })();
    }
  }, [teamId]);

  const closeModal = () => {
    onModalHidden && onModalHidden(false);
  };

  const updateAllowTeamAccess = (value: string, action: string, sectionKey: string) => {
    if (action === DESELECT_OPTION) {
      selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[sectionKey].allowedTeamAccess.filter(
        (element) => element !== value,
      );
    } else {
      if (value === null) {
        selectedOptions[sectionKey].allowedTeamAccess = [value];
      } else {
        selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[sectionKey].allowedTeamAccess?.length
          ? [...selectedOptions[sectionKey].allowedTeamAccess, value]
          : [value];
      }
    }
  };

  const updateBlockedTeamAccess = (value: string, action: string, sectionKey: string) => {
    if (action === DESELECT_OPTION) {
      selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[sectionKey].blockedTeamAccess.filter(
        (element) => element !== value,
      );
    } else {
      selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[sectionKey].blockedTeamAccess?.length
        ? [...selectedOptions[sectionKey].blockedTeamAccess, value]
        : [value];
    }
  };

  const updateSelectedTeamAccess = () => {
    if (!selectedTeam) {
      return selectedTeam;
    }

    selectedTeam.Clusters = selectedOptions.clusters.allowedTeamAccess;
    selectedTeam.Repositories = selectedOptions.repositories.allowedTeamAccess;
    selectedTeam.Namespaces = selectedOptions.namespaces.allowedTeamAccess;
    selectedTeam.DisallowedClusters = selectedOptions.clusters.blockedTeamAccess;
    selectedTeam.DisallowedRepositories = selectedOptions.repositories.blockedTeamAccess;
    selectedTeam.DisallowedNamespaces = selectedOptions.namespaces.blockedTeamAccess;

    return selectedTeam;
  };

  const updateTeamAccess = async (updatedSelectedTeam: Team | null) => {
    if (!updatedSelectedTeam) {
      return;
    }

    try {
      await sendRequest(
        'POST',
        `${baseUrl}/teams`,
        {
          data: {
            Repositories:
              updatedSelectedTeam.Repositories?.length && updatedSelectedTeam.Repositories[0] === null
                ? null
                : updatedSelectedTeam.Repositories,
            Clusters:
              updatedSelectedTeam.Clusters?.length && updatedSelectedTeam.Clusters[0] === null
                ? null
                : updatedSelectedTeam.Clusters,
            Namespaces:
              updatedSelectedTeam.Namespaces?.length && updatedSelectedTeam.Namespaces[0] === null
                ? null
                : updatedSelectedTeam.Namespaces,
            DisallowedClusters: updatedSelectedTeam.DisallowedClusters,
            DisallowedRepositories: updatedSelectedTeam.DisallowedRepositories,
            DisallowedNamespaces: updatedSelectedTeam.DisallowedNamespaces,
            Name: updatedSelectedTeam.Name,
            Organization: organization.Name,
          },
        },
        null,
      );

      toast.success(strings.teamManagement.updateTeamAccessSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.updateTeamAccessFailed);
      logger.logError('error_updating_tags', e);
    }
  };

  const onOptionChanged = (
    option: AdvancedFilterOption,
    action: string,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => {
    const { value } = option;

    if (isAllowingTeamAccess) {
      updateAllowTeamAccess(value, action, sectionKey);
    } else {
      updateBlockedTeamAccess(value, action, sectionKey);
    }

    const updatedSelectedTeam = updateSelectedTeamAccess();
    updateTeamAccess(updatedSelectedTeam);
    setSelectedOptions({ ...selectedOptions });
    setSelectedTeam(updatedSelectedTeam ? { ...updatedSelectedTeam } : null);
  };

  const removeAllowTeamAccess = (value: string, sectionKey: string) => {
    selectedOptions[sectionKey].allowedTeamAccess = selectedOptions[sectionKey].allowedTeamAccess.filter(
      (element) => element !== value,
    );
  };

  const removeBlockedTeamAccess = (value: string, sectionKey: string) => {
    selectedOptions[sectionKey].blockedTeamAccess = selectedOptions[sectionKey].blockedTeamAccess.filter(
      (element) => element !== value,
    );
  };

  const onOptionRemoved = (removedValue: AdvancedFilterOption, sectionKey: string, isAllowingTeamAccess: boolean) => {
    const { value } = removedValue;

    if (!selectedOptions[sectionKey]) {
      return;
    }

    if (isAllowingTeamAccess) {
      removeAllowTeamAccess(value, sectionKey);
    } else {
      removeBlockedTeamAccess(value, sectionKey);
    }

    const updatedSelectedTeam = updateSelectedTeamAccess();
    updateTeamAccess(updatedSelectedTeam);
    setSelectedOptions({ ...selectedOptions });
    setSelectedTeam(updatedSelectedTeam ? { ...updatedSelectedTeam } : null);
  };

  const updateAllSelection = (label: string, isSelected = true) => {
    if (label === strings.teamManagement.allClusters) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllClusterSelected: isSelected }));
    } else if (label === strings.teamManagement.allRepositories) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllRepoSelected: isSelected }));
    } else if (label === strings.teamManagement.allNamespaces) {
      setAllowTeamAccessAllSelection((prev) => ({ ...prev, isAllNamespaceSelected: isSelected }));
    }
  };

  const onSelectChanged = (
    option: AdvancedFilterOption,
    removedValue: AdvancedFilterOption,
    action: string,
    sectionKey: string,
    isAllowingTeamAccess: boolean,
  ) => {
    if (option) {
      updateAllSelection(option.label, action === 'DESELECT_OPTION' ? false : true);
      onOptionChanged(option, action, sectionKey, isAllowingTeamAccess);
    } else if (removedValue) {
      updateAllSelection(removedValue.label, false);
      onOptionRemoved(removedValue, sectionKey, isAllowingTeamAccess);
    }
  };

  const allowedTeamAccess = useMemo(
    () =>
      [
        {
          id: 1,
          addButtonTitle: strings.teamManagement.addAdditionalClusters,
          title: strings.teamManagement.clusters,
          options: [
            { label: strings.teamManagement.allClusters, value: null },
            ...clusters.map((cluster) =>
              allowTeamAccessAllSelection.isAllClusterSelected
                ? { label: cluster, value: cluster, isDisabled: true }
                : { label: cluster, value: cluster },
            ),
          ],
          sectionKey: strings.teamManagement.clusters.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-clusters-section',
        },
        {
          id: 2,
          addButtonTitle: strings.teamManagement.addAdditionalRepositories,
          title: strings.teamManagement.repositories,
          options: [
            { label: strings.teamManagement.allRepositories, value: null },
            ...repositories.map((repository) =>
              allowTeamAccessAllSelection.isAllRepoSelected
                ? { label: repository, value: repository, isDisabled: true }
                : { label: repository, value: repository },
            ),
          ],
          sectionKey: strings.teamManagement.repositories.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-repositories-section',
        },
        {
          id: 3,
          addButtonTitle: strings.teamManagement.addAdditionalNamespaces,
          title: strings.teamManagement.namespaces,
          options: [
            { label: strings.teamManagement.allNamespaces, value: null },
            ...namespaces.map((namespace) =>
              allowTeamAccessAllSelection.isAllNamespaceSelected
                ? { label: namespace, value: namespace, isDisabled: true }
                : { label: namespace, value: namespace },
            ),
          ],
          sectionKey: strings.teamManagement.namespaces.toLowerCase(),
          isAllowingTeamAccess: true,
          dataCy: 'team-namespaces-section',
        },
      ] as TeamAccessElement[],
    [clusters, repositories, namespaces, selectedTeam, allowTeamAccessAllSelection],
  );

  const blockedTeamAccess = useMemo(
    () =>
      [
        {
          id: 1,
          addButtonTitle: strings.teamManagement.addAdditionalClusters,
          title: strings.teamManagement.blockedClusters,
          options: clusters.map((cluster) => ({ label: cluster, value: cluster })),
          sectionKey: strings.teamManagement.clusters.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-clusters-section',
        },
        {
          id: 2,
          addButtonTitle: strings.teamManagement.addAdditionalRepositories,
          title: strings.teamManagement.blockedRepositories,
          options: repositories.map((repository) => ({ label: repository, value: repository })),
          sectionKey: strings.teamManagement.repositories.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-repositories-section',
        },
        {
          id: 3,
          addButtonTitle: strings.teamManagement.addAdditionalNamespaces,
          title: strings.teamManagement.blockedNamespaces,
          options: namespaces.map((namespace) => ({ label: namespace, value: namespace })),
          sectionKey: strings.teamManagement.namespaces.toLowerCase(),
          isAllowingTeamAccess: false,
          dataCy: 'team-blocked-namespaces-section',
        },
      ] as TeamAccessElement[],
    [clusters, repositories, namespaces, selectedTeam],
  );

  const getAllValue = (sectionKey: string) => {
    switch (sectionKey) {
      case 'clusters':
        return strings.teamManagement.allClusters;
      case 'repositories':
        return strings.teamManagement.allRepositories;
      case 'namespaces':
        return strings.teamManagement.allNamespaces;
      default:
        break;
    }
  };

  return (
    <Modal show={isModalShown} onHide={closeModal} className="team-modal-body">
      <Modal.Body className="pt-0">
        <div className="team-modal-title-container">
          <h1 className="modal-title">{`${modalTitle} ${strings.teamManagement.team}`}</h1>
          <div className="team-modal-close-icon-container">
            <CloseIcon width="0.75rem" height="0.75rem" onClick={closeModal} className="team-modal-close-icon" />
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <TeamAccountsTable route={route} router={router} store={store} teamId={teamId} />
            <div className="team-modal__team-access">
              <h2 className="team-modal__title">{strings.teamManagement.teamAccess}</h2>
              <div className="team-modal__team-access-container">
                {allowedTeamAccess?.length
                  ? allowedTeamAccess.map((teamAccess: TeamAccessElement) => (
                      <TeamAccess
                        addButtonTitle={teamAccess.addButtonTitle}
                        isAllowingTeamAccess={teamAccess.isAllowingTeamAccess}
                        key={teamAccess.id}
                        onOptionChanged={onSelectChanged}
                        options={teamAccess.options}
                        sectionKey={teamAccess.sectionKey}
                        title={teamAccess.title}
                        dataCy={teamAccess.dataCy}
                        defaultValue={selectedOptions[teamAccess.sectionKey].allowedTeamAccess.map((value) => ({
                          label: value === null ? getAllValue(teamAccess.sectionKey) : value,
                          value,
                        }))}
                        isDropDownShown
                      />
                    ))
                  : null}
              </div>
            </div>

            <div className="team-modal__team-access">
              <h2 className="team-modal__title">{strings.teamManagement.blockedAccess}</h2>
              <div className="team-modal__team-access-container">
                {blockedTeamAccess?.length
                  ? blockedTeamAccess.map((teamAccess: TeamAccessElement) => (
                      <TeamAccess
                        addButtonTitle={teamAccess.addButtonTitle}
                        isAllowingTeamAccess={teamAccess.isAllowingTeamAccess}
                        key={teamAccess.id}
                        onOptionChanged={onSelectChanged}
                        options={teamAccess.options}
                        sectionKey={teamAccess.sectionKey}
                        title={teamAccess.title}
                        dataCy={teamAccess.dataCy}
                        defaultValue={selectedOptions[teamAccess.sectionKey].blockedTeamAccess.map((value) => ({
                          label: value,
                          value,
                        }))}
                        isDropDownShown
                      />
                    ))
                  : null}
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Toaster />
    </Modal>
  );
};

export default TeamModal;
