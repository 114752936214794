import React from 'react';
import { TableInstance } from 'react-table';
import { Button } from 'react-bootstrap';

import Icon from '../../Icons/Icon.react';

import { TableColumnsParams, TableColumnNames, TableAccessors } from './SettingsRepos.types.react';

export const getTableColumns = ({ onRepoSelected, isOrgOwner }: TableColumnsParams) => {
  return isOrgOwner
    ? [
        {
          Header: TableColumnNames.Repository,
          accessor: TableAccessors.Repository,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            return <span>{values.repository}</span>;
          },
          minWidth: 524,
          maxWidth: 524,
          width: 524,
        },
        {
          Header: TableColumnNames.Delete,
          minWidth: 130,
          maxWidth: 130,
          width: 130,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            return (
              <Button variant="danger" onClick={() => onRepoSelected(values)}>
                <Icon name="trash" className="delete-icon repo-delete-icon" />
                <span>Delete</span>
              </Button>
            );
          },
        },
      ]
    : [
        {
          Header: TableColumnNames.Repository,
          accessor: TableAccessors.Repository,
          Cell: (data: TableInstance) => {
            const values = data.row.original;
            return <span>{values.repository}</span>;
          },
          minWidth: 524,
          maxWidth: 524,
          width: 524,
        },
      ];
};
