import * as React from 'react';
import { COLORS } from '~utils/styling';

const ArrowDownIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6" {...props}>
      <path d="M4.8 6L.6 0H9L4.8 6z" fill={COLORS.CORE.WHITE} />
    </svg>
  );
};

export default ArrowDownIcon;
