import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import toast, { Toaster } from 'react-hot-toast';

import FairwindsLogo from './Icons/FairwindsLogo.icon.react';
import CheckFill from './Icons/CheckFill.icon.react';
import CheckCircle from './Icons/CheckCircle.icon.react';

import { Check, Labels } from '../Compliance.types.react';
import { OptionType, IStore, IRoute, IRouter } from '~globalTypes';

import { standardizedReports } from '../Compliance.helpers.react';

import { sendRequest } from '~utils/request';
import { sortDescending, sortAscending } from '~utils/helpers';
import logger from '~logger';
import { getCurrentTimezone } from '~reactHelpers';
import { COLORS } from '~utils/styling';

import './DownloadReport.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type DownloadProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const DownloadReport = ({ route }: DownloadProps): JSX.Element => {
  const org = route?.params?.org;
  const [checks, setChecks] = useState<Check[]>([]);
  const [reportStandards, setReportStandards] = useState<string[]>([]);
  const [title, setTitle] = useState<string>('');

  const timeZone = getCurrentTimezone();

  useEffect(() => {
    getReport();
  }, []);

  const styles = StyleSheet.create({
    page: {
      width: '100%',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
      border: '1px solid black',
    },
    logo: {
      paddingLeft: 16,
      paddingTop: 10,
    },
    pageTitle: {
      paddingLeft: 16,
      fontSize: 16,
      fontWeight: 'light',
    },
    body: {
      border: '1px solid black',
    },
    downloadCard: {
      border: '1px solid black',
      margin: 16,
      padding: '0px 16px 16px 16px',
    },
    downloadCardTitle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 0,
    },
    leftTitle: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
    },
    checkTitle: {
      fontSize: 14,
      fontWeight: 400,
      color: COLORS.CORE.BLACK,
    },
    downloadAssessmentDate: {
      fontSize: 12,
      color: COLORS.CORE.GRAY,
      paddingTop: 6,
      paddingLeft: 12,
    },
    rightTitle: {
      justifyContent: 'flex-end',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    standardsDiv: {
      display: 'flex',
      flexDirection: 'row',
    },
    singleStandard: {
      marginLeft: 8,
      fontSize: 8,
      padding: '4px 6px 2px 8px',
      border: '1px solid black',
      borderRadius: '50px',
    },
    cardSubtitle: {
      marginTop: 0,
      marginLeft: 12,
      fontSize: 10,
      fontWeight: 300,
    },
    downloadCodes: {
      marginBottom: 16,
      marginTop: 0,
    },
    standardCodes: {
      padding: '0 16px 4px 0',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'baseline',
    },
    standardTitle: {
      fontSize: 10,
      fontWeight: 'bold',
      paddingRight: 8,
    },
    allStandardCodes: {
      fontSize: 8,
    },
    assessmentTitle: {
      fontSize: 14,
      fontWeight: 500,
    },
    assessmentSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '0 0 8px 0',
    },
    evidenceTitle: {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 10,
    },
    text: {
      fontSize: 10,
      fontWeight: 300,
    },
    filled: {
      marginTop: 12,
    },
    notFilled: {
      marginTop: 12,
    },
  });

  const getReport = async () => {
    const baseURL = `/v0/organizations/${org}`;
    const reportID = route?.query?.reportID;

    try {
      const response = await sendRequest('GET', `${baseURL}/compliance/reports/${reportID}`, {}, null);
      const sortedChecks = response.checks.sort((a: Check, b: Check) => sortDescending(a.status, b.status));
      setTitle(response.name);
      setChecks(sortedChecks);
      setReportStandards(response.standards);
      toast.success(<b>Please wait while we compile your compliance report</b>, { duration: 16000 });
    } catch (e) {
      logger.logError('error_retrieving_compliance_report', e);
      toast.error(<b>Unable to retrieve your report. Please try again.</b>);
    }
  };

  const formatStandards = (check: Check): OptionType[] => {
    const allStandards: OptionType[] = [];
    check.standards.forEach((standard) => {
      if (standard.name !== 'kintent') {
        const formattedType = standardizedReports(standard.name);
        if (standard.name === 'iso' && reportStandards.includes(standard.name)) {
          allStandards.push({ value: standard.name, label: formattedType });
        } else if (reportStandards.includes(standard.name)) {
          allStandards.push({ value: standard.name, label: formattedType });
        }
      }
    });
    return allStandards;
  };

  return (
    <>
      <PDFViewer className="pdf-viewer">
        <Document title={`${title}-compliance-report`}>
          <Page size="LETTER">
            <FairwindsLogo style={styles.logo} />
            <View>
              <Text style={styles.pageTitle}>All Compliance Checks</Text>
            </View>
            {checks ? (
              checks.map((check) => {
                const filteredStandards = check.standards.filter((standard) => standard.name !== 'kintent');
                const standards = formatStandards(check);
                return (
                  <View key={check.id} style={styles.downloadCard} wrap={false}>
                    <View style={styles.downloadCardTitle}>
                      <View style={styles.leftTitle}>
                        {check.status === Labels.implemented ? (
                          <CheckFill style={styles.filled} />
                        ) : (
                          <CheckCircle style={styles.notFilled} />
                        )}
                        <Text style={styles.checkTitle}>{check.title}</Text>
                        {check.status === Labels.implemented && (
                          <Text style={styles.downloadAssessmentDate}>
                            {timeZone
                              ? dayjs(check.selfAssessedAt).utc().tz(timeZone).format('MM/DD/YYYY')
                              : dayjs(check.selfAssessedAt).utc().format('MM/DD/YYYY')}{' '}
                            at{' '}
                            {timeZone
                              ? dayjs(check.selfAssessedAt).utc().tz(timeZone).format('hh:mm A')
                              : dayjs(check.selfAssessedAt).utc().format('hh:mm A')}
                          </Text>
                        )}
                      </View>
                      <View style={styles.rightTitle}>
                        <View style={styles.standardsDiv}>
                          {standards.map((standard) => {
                            return <Text style={styles.singleStandard}>{standard.label}</Text>;
                          })}
                        </View>
                        <Text style={styles.cardSubtitle}>{check.category}</Text>
                      </View>
                    </View>
                    <View style={styles.downloadCodes}>
                      {filteredStandards.map((standard) => {
                        const standardName = standardizedReports(standard.name);
                        return (
                          <View style={styles.standardCodes} key={`${check.id}-${standardName}`}>
                            <Text style={styles.standardTitle}>{standardName}:</Text>
                            <Text style={styles.allStandardCodes}>
                              {standard.codes.sort((a, b) => sortAscending(a, b)).join(', ')}
                            </Text>
                          </View>
                        );
                      })}
                    </View>
                    <View>
                      <Text style={styles.assessmentTitle}>Self Assessment</Text>
                      <View style={styles.assessmentSection}>
                        <View>
                          {check.status !== Labels.notImplemented ? (
                            <CheckFill style={styles.filled} />
                          ) : (
                            <CheckCircle style={styles.notFilled} />
                          )}
                        </View>
                        <View>
                          <Text style={styles.text}>{check.text}</Text>
                        </View>
                      </View>
                      <Text style={styles.evidenceTitle}>Evidence</Text>
                      {check.evidence.length > 0 && <Text style={styles.text}>{check.evidence}</Text>}
                      {!check.evidence.length && <Text style={styles.text}>There is no evidence at this time.</Text>}
                    </View>
                  </View>
                );
              })
            ) : (
              <View>
                <Text>Sorry, there are no checks to display. Please try again.</Text>
              </View>
            )}
          </Page>
        </Document>
      </PDFViewer>
      <Toaster />
    </>
  );
};

export default DownloadReport;
