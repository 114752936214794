import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Alert, Button, DropdownButton } from 'react-bootstrap';
import Select, { MenuListProps, MultiValue as MultiValueType, OptionProps, components } from 'react-select';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';
import { useDebouncedCallback } from 'use-debounce';

import { AddButton, Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import SearchFilterBar from '~reactComponents/SearchFilterBar/SearchFilterBar.react';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import FilterIcon from '~reactIcons/Filter.react';
import Option from '~reactComponents/OptionComponent/OptionComponent.react';
import AggregatorBarChart from './Components/AggregatorBarChart/AggregatorBarChart.react';
import AggregatorTable from './Components/AggregatorTable/AggregatorTable.react';
import CostsOverTimeChart from './Components/CostsOverTimeChart/CostsOverTimeChart.react';
import ResourceUsageChart from './Components/ResourceUsageChart/ResourceUsageChart.react';
import CostsDonut from './Components/CostsDonut/CostsDonut.react';

import useCostsData from './hooks/useCostsData.react';

import {
  CostsProps,
  SelectedFilters,
  DateType,
  ResourceSummary,
  CostsOptions,
  WorkloadOption,
} from './Costs.types.react';

import {
  aggregatorOptions,
  resourcesPerPodRequired,
  customStyles,
  CustomMenu,
  clusterBreadcrumbsList,
  orgBreadcrumbsList,
  topWorkloadsAggregators,
} from './Costs.config.react';

import {
  CUMULATIVE_COSTS,
  CUMULATIVE_COSTS_CLUSTER,
  REPORT_HUB,
  RIGHT_SIZING,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import {
  baseRequest,
  getSyncDate,
  getFiltersFromQuery,
  formatFiltersForState,
  formatChangedDates,
  buildFilterParams,
  buildColumnsQuery,
  getSelectedAggregators,
  getSelectedTimeRange,
  findLabel,
} from './Costs.helpers.react';

import logger from '~logger';
import { OptionType } from '~globalTypes';
import { getCurrentTimezone, handlePageChange, hasKey, pushURL } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';

import './Costs.react.scss';
import { DropdownIndicator } from '~reactComponents/DropdownIndicator/DropdownIndicator.react';
import { MultiValue } from '~reactComponents/MultiValue/MultiValue.react';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import trash from '@/assets/icons/trash.svg';
import NewSavedViewDialog from './Components/NewSavedViewDialog/NewSavedViewDialog.react';

dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

const namespaceAggregator = {
  value: strings.efficiency.namespace,
  label: strings.efficiency.namespace,
};
const workloadAggregator = {
  value: strings.efficiency.workload,
  label: strings.efficiency.workload,
};
const kindAggregator = {
  value: strings.efficiency.kind,
  label: strings.efficiency.kind,
};
const containerAggregator = {
  value: strings.efficiency.container,
  label: strings.efficiency.container,
};

const workloadLevel = [
  strings.efficiency.cluster,
  strings.efficiency.namespace,
  strings.efficiency.kind,
  strings.efficiency.workload,
];
const namespaceLevel = [strings.efficiency.cluster, strings.efficiency.namespace];

const clusterLevel = [strings.efficiency.cluster];

const CumulativeCosts = ({ store, route, router }: CostsProps) => {
  const org = route?.params?.org;
  const organization = store().getters.organization;
  const cluster = store().getters.cluster?.Name;
  const { name } = route;
  const timeZone = getCurrentTimezone();

  const [areFiltersLoading, setAreFiltersLoading] = useState<boolean>(true);
  const [areTopWorkloadsLoading, setAreTopWorkloadsLoading] = useState<boolean>(true);
  const [isSavingsAvailableLoading, setIsSavingsAvailableLoading] = useState<boolean>(true);
  const [isTotalCostsLoading, setIsTotalCostsLoading] = useState<boolean>(true);
  const [isSummaryLoading, setIsSummaryLoading] = useState<boolean>(true);
  const [isTimeseriesLoading, setIsTimeseriesLoading] = useState<boolean>(true);
  const [syncedDatesLoading, setSyncedDatesLoading] = useState(true);
  const [savedViewsChanged, setSavedViewsChanged] = useState<boolean>(false);
  const [shouldRefetchSavedViews, setShouldRefetchSavedViews] = useState<boolean>(false);
  const [isNewSavedViewDialogShown, setIsNewSavedViewDialogShown] = useState<boolean>(false);

  // these refs will be used to reset the state.
  const initialAggregators = useRef<(OptionType | CostsOptions)[]>([{ label: 'cluster', value: 'cluster' }]);
  const initialFilters = useRef<Record<string, any>>({});

  const initializingState = useRef<Record<string, any>>({
    isFirstTimeInit: true,
    isInitRunning: false,
  });

  const {
    selectedTimeRange,
    setSelectedTimeRange,
    selectedTimeRangeChanged,
    setSelectedTimeRangeChanged,
    selectedAggregators,
    setSelectedAggregators,
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
    setTopWorkloads,
    savedViews,
    setSavedViews,
    syncedPrometheusDate,
    setSyncedDates,
    pageSize,
    orderBy,
    currentPage,
    hiddenColumnsChanged,
    setTableState,
    summaries,
    totals,
    resources,
    totalCosts,
    setTotalCosts,
    savingsAvailable,
    setSavingsAvailable,
    selectedSavedView,
    setSelectedSavedView,
    workloadLabels,
    namespaceLabels,
    podLabels,
    currentAction,
    setCurrentAction,
    period,
    showUsageChart,
    currentFilters,
    filterByDate,
    selectFilters,
    selectAggregators,
    updateSelectedFilters,
    updateSelectedTopWorkload,
    SyncedDates,
    createBaseSearchParams,
    paramsWithAggregators,
    setResources,
    setLabels,
    dateRangeOption,
    setDateRangeOption,
  } = useCostsData(cluster, route);

  const disableApplyButton = useMemo(() => {
    // conditional specifically targeting changing filters while api calls are still happening on page load
    if (
      currentAction === 'mounting' &&
      !areFiltersLoading &&
      (selectedAggregators.length > 1 || Object.keys(selectedFilters).length > 0)
    ) {
      return false;
    }

    if (currentAction === 'mounted' || currentAction === 'clearing' || currentAction === 'cleared') {
      return true;
    }

    if (
      currentAction === 'mounting' &&
      selectedAggregators.length === 1 &&
      selectedAggregators[0].value === 'cluster'
    ) {
      return true;
    }

    if (selectedSavedView && currentAction !== 'updating') {
      return true;
    }

    if (selectedAggregators.length > 0) {
      return false;
    }
    if (Object.keys(selectedFilters).length > 0) {
      return false;
    }
    return true;
  }, [selectedTimeRange, selectedAggregators, selectedFilters, currentAction, areFiltersLoading]);

  const isLoading = useMemo(
    () => isTotalCostsLoading && isTimeseriesLoading && isSummaryLoading,
    [isTotalCostsLoading, isTimeseriesLoading, isSummaryLoading],
  );

  useEffect(() => {
    const onLoad = async () => {
      const dates = await getInterval();
      dates ? initFilters([], dates) : initFilters();
      dates ? init([], dates) : init();
    };
    if (currentAction !== 'mounting') {
      return;
    }
    setAreFiltersLoading(true);
    onLoad();
    setCurrentAction('loaded');
  }, [selectedFilters, currentAction]);

  const resetState = async () => {
    setAreFiltersLoading(true);
    setIsSummaryLoading(true);
    setIsTimeseriesLoading(true);
    setIsTotalCostsLoading(true);

    const params = new URLSearchParams(window.location.search);

    const newSelectedAggregators = getSelectedAggregators(params);
    setSelectedAggregators(newSelectedAggregators);

    const newDates = getSelectedTimeRange(timeZone, params);
    const possibleFilters = await getFilters(newDates);
    const keys = Object.keys(possibleFilters);
    keys.push('billedCostGreaterThan');
    keys.push('networkingLessThanInMB');
    keys.push('dateRangeOption');
    const filtersFromQuery = getFiltersFromQuery(keys, params);
    const formattedFilters = formatFiltersForState(filtersFromQuery);
    updateSelectedFilters(cluster, 'pop', formattedFilters);

    setSelectedSavedView(null);
    setSelectedTimeRange(newDates);

    await initFilters(newSelectedAggregators, newDates, formattedFilters);
    await init(newSelectedAggregators, newDates, formattedFilters);
  };

  useEffect(() => {
    window.addEventListener('popstate', resetState); // listen for back/forward button clicks
    return () => {
      window.removeEventListener('popstate', resetState);
    };
  }, []);

  useEffect(() => {
    // we'll want a savedViewsOptions array for the dropdown menu;
    // array of label, options; options is an array of value, label; do we need a section key/value?

    const getSavedViews = async () => {
      let response;
      try {
        response = await sendRequest('GET', `/v0/organizations/${org}/resources/saved-views`, {}, null);
      } catch (e) {
        logger.logError('error_retrieving_resources_saved-views', e);
      }

      const formattedSavedViews = (response || []).map((view) => {
        const viewKeys = Object.keys(view);
        const viewValue = {};

        viewKeys.forEach((key) => {
          if (view[key] !== null) {
            viewValue[key] = view[key];
          }
        });

        return {
          label: view.name,
          value: viewValue,
        };
      });

      const updatedSavedViews = { label: 'saved views', options: formattedSavedViews };
      setSavedViews((prevState) => {
        if (Array.isArray(prevState)) {
          prevState[0] = updatedSavedViews;
          return [...prevState];
        } else {
          return [updatedSavedViews];
        }
      });
    };

    if (
      (currentAction === 'mounting' && !Object.keys(selectedFilters).length) ||
      currentAction === 'clicked' ||
      shouldRefetchSavedViews
    ) {
      getSavedViews();
      setShouldRefetchSavedViews(false);
    }
  }, [selectedFilters, shouldRefetchSavedViews]);

  useEffect(() => {
    const getTopWorkloads = async () => {
      const searchParams = paramsWithAggregators(topWorkloadsAggregators, {}, selectedTimeRange, undefined);
      const searchParamsWithColumns = buildColumnsQuery(searchParams);

      const response = await baseRequest('resources-summary', org, searchParamsWithColumns);

      const formattedTopWorkloadsResponse = (response?.resources || []).map((resource: ResourceSummary) => ({
        label: `${resource.workload} ${resource.kind} in ${resource.namespace}/${resource.cluster}`,
        value: {
          workloads: resource.workload,
          kinds: resource.kind,
          namespaces: resource.namespace,
          clusters: resource.cluster,
          aggregators: ['cluster', 'kind', 'namespace', 'workload'],
        },
      }));

      if (
        formattedTopWorkloadsResponse.length === 1 &&
        Object.keys(selectedFilters).filter((filter) => resourcesPerPodRequired.includes(filter)).length === 4
      ) {
        setSelectedSavedView(formattedTopWorkloadsResponse[0]);
      }

      const updatedTopWorkloads = {
        label: strings.workloads,
        options: formattedTopWorkloadsResponse,
      };
      setAreTopWorkloadsLoading(false);
      setSavedViews((prevState) => {
        prevState[1] = updatedTopWorkloads;
        return prevState;
      });
      setTopWorkloads(formattedTopWorkloadsResponse);
    };

    if ((currentAction === 'mounting' && !Object.keys(selectedFilters).length) || currentAction === 'clicked') {
      getDebouncedData(getTopWorkloads);
    }
  }, [selectedFilters]);

  const getDebouncedData = useDebouncedCallback((getData) => getData(), 500);

  useEffect(() => {
    setTableState({
      orderBy: {
        orderBy: 'totalCost',
        orderByDesc: 'true',
      },
    });
    setSyncedDatesLoading(true);
    getSyncDate(cluster, org, setSyncedDates).then(() => {
      setSyncedDatesLoading(false);
    });
  }, [org, cluster]);

  useEffect(() => {
    updateSelectedFilters(cluster);
  }, [cluster]);

  useEffect(() => {
    if (
      !initializingState.current.isFirstTimeInit &&
      !initializingState.current.isInitRunning &&
      !isSummaryLoading &&
      !isTotalCostsLoading &&
      !isTimeseriesLoading &&
      currentAction !== 'fetching' &&
      currentAction !== 'mounting' &&
      (currentAction === 'mounted' || currentAction === 'updated' || hiddenColumnsChanged === true)
    ) {
      setAreFiltersLoading(true);
      initFilters();
      init();
    }
  }, [pageSize, currentPage, orderBy, hiddenColumnsChanged, currentAction]);

  useEffect(() => {
    if (
      currentAction === 'clicked' ||
      currentAction === 'clearing' ||
      (currentAction === 'updating' && (savedViewsChanged || selectedTimeRangeChanged))
    ) {
      setAreFiltersLoading(true);
      initFilters(undefined, undefined, selectedFilters, dateRangeOption);
      init(undefined, undefined, selectedFilters, dateRangeOption);
      if (currentAction !== 'clearing') {
        setCurrentAction('updated');
      } else {
        setCurrentAction('cleared');
      }
      setSavedViewsChanged(false);
    }
  }, [selectedFilters, currentAction, dateRangeOption]);

  useEffect(() => {
    const workloads = aggregatorOptions.find((option) => option.label === 'workload labels');
    const namespace = aggregatorOptions.find((option) => option.label === 'namespace labels');
    const pod = aggregatorOptions.find((option) => option.label === 'pod labels');
    if (workloads) workloads.options = workloadLabels;
    if (namespace) namespace.options = namespaceLabels;
    if (pod) pod.options = podLabels;
  }, [workloadLabels, namespaceLabels, podLabels]);

  const initFilters = async (
    aggregators?: (OptionType | CostsOptions)[],
    dates?: DateType,
    updatedFilters?: SelectedFilters,
    dateRangeOption?: string,
  ) => {
    const formattedFilters = await getFiltersAndURL(aggregators, dates, updatedFilters, dateRangeOption);

    if (selectedAggregators && formattedFilters) {
      if (selectedSavedView) {
        return;
      } else {
        setSelectedSavedView(null);
      }
    }
  };

  const init = async (
    aggregators?: (OptionType | CostsOptions)[],
    dates?: DateType,
    updatedFilters?: SelectedFilters,
    dateRangeOption?: string,
  ) => {
    initializingState.current.isInitRunning = true;

    setIsTotalCostsLoading(true);
    setIsSummaryLoading(true);
    setIsTimeseriesLoading(true);
    setTableState({ hiddenColumnsChanged: false });

    if (!currentAction) return;

    const searchParams = paramsWithAggregators(
      aggregators || [],
      updatedFilters || {},
      dates || selectedTimeRange,
      dateRangeOption,
    );

    getLabels();
    getSummaryData(searchParams);
    getSummaryTimeseriesData(searchParams);
    const totalCosts = await getCostsData(aggregators, dates, updatedFilters, 'skipSavingsAvailable');
    setTotalCosts(totalCosts);
    const savingsAvailable = await getCostsData(aggregators, dates, updatedFilters, 'savingsAvailableOnly');
    setSavingsAvailable(savingsAvailable);
    setIsSavingsAvailableLoading(false);
    setIsTotalCostsLoading(false);

    if (currentAction !== 'clearing') {
      setCurrentAction(currentAction === 'updating' ? 'updated' : 'mounted');
    }

    if (initializingState.current.isFirstTimeInit) {
      initializingState.current.isFirstTimeInit = false;
    }

    if (initializingState.current.isInitRunning) {
      initializingState.current.isInitRunning = false;
    }
  };

  const getLabels = async () => {
    let response: Record<string, string[]>;
    try {
      response = await sendRequest('GET', `/v0/organizations/${org}/resources/labels`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_labels_aggregators', e);
    }

    const mapLabels = (label: string): CostsOptions[] => {
      return response[label] && response[label].length > 0
        ? response[label].map((item: string) => ({
            value: `${item}-${label}`,
            label: item,
            section: label,
          }))
        : [];
    };

    setLabels({
      workloadLabels: mapLabels('workload'),
      namespaceLabels: mapLabels('namespace'),
      podLabels: mapLabels('pod'),
    });
  };

  const getFiltersAndURL = async (
    aggregators?: (OptionType | CostsOptions)[],
    dates?: DateType,
    updatedFilters?: SelectedFilters,
    dateRangeOption?: string | undefined,
  ) => {
    let finalSearchParams = new URLSearchParams(window.location.search);
    const dateRangeOptionFromURL = finalSearchParams.get('dateRangeOption');
    let formattedFilters = updatedFilters;

    const filterParams = buildFilterParams(
      finalSearchParams,
      cluster,
      Object.values(updatedFilters || {}).flat(),
      Object.values(selectedFilters).flat(),
      false,
      filters,
      currentAction,
      false,
    );

    if (!updatedFilters || selectedTimeRangeChanged) {
      const possibleFilters = await getFilters(dates);
      const keys = Object.keys(possibleFilters);
      keys.push('billedCostGreaterThan');
      keys.push('networkingLessThanInMB');
      keys.push('dateRangeOption');
      if (selectedTimeRangeChanged && !dateRangeOption) {
        finalSearchParams.delete('dateRangeOption');
      }
      const filtersFromQuery = getFiltersFromQuery(keys, filterParams);
      formattedFilters = formatFiltersForState(filtersFromQuery);
      selectedTimeRangeChanged && setSelectedTimeRangeChanged(false);
    }

    if (
      (updatedFilters && Object.values(updatedFilters).flat().length) ||
      (formattedFilters && Object.values(formattedFilters).flat().length)
    ) {
      setSelectedFilters({ type: null, payload: updatedFilters || formattedFilters || {} });
    }

    if (!finalSearchParams.toString().length || selectedSavedView || currentAction === 'clicked') {
      finalSearchParams = paramsWithAggregators(
        aggregators || [],
        updatedFilters || formattedFilters || {},
        dates || selectedTimeRange,
        undefined,
      );
    }
    finalSearchParams.set('redirect', 'true');
    if (dateRangeOption) {
      finalSearchParams.set('dateRangeOption', dateRangeOption);
    } else if (dateRangeOptionFromURL && !selectedTimeRangeChanged) {
      finalSearchParams.set('dateRangeOption', dateRangeOptionFromURL);
      setDateRangeOption(dateRangeOptionFromURL);
    } else if (selectedSavedView && selectedSavedView.value?.dateRangeOption) {
      finalSearchParams.set('dateRangeOption', selectedSavedView.value.dateRangeOption);
      setDateRangeOption(selectedSavedView.value.dateRangeOption);
    }
    pushURL({
      route,
      nameToMatch: cluster ? CUMULATIVE_COSTS_CLUSTER : CUMULATIVE_COSTS,
      searchParams: finalSearchParams.toString(),
      router,
    });

    return formattedFilters;
  };

  const getFilters = async (dates?: DateType) => {
    const searchParams = new URLSearchParams();

    searchParams.set('endDate', dates ? dates.end : selectedTimeRange.end);
    searchParams.set('startDate', dates ? dates.start : selectedTimeRange.start);

    const data = await baseRequest('resources-filters', org, searchParams);
    const updatedData = Object.keys(data).reduce((updatedFilters, filter) => {
      if (!data[filter]) {
        updatedFilters[filter] = [];
      } else {
        updatedFilters[filter] = data[filter];
      }
      return updatedFilters;
    }, {});

    setFilters(updatedData);
    setAreFiltersLoading(false);
    return updatedData;
  };

  const getInterval = async () => {
    const searchParams = createBaseSearchParams([], {}, false, true);

    const response = await baseRequest('resources/interval', org, searchParams);

    const currentURL = new URLSearchParams(window.location.search);
    const urlDate =
      currentURL.get('startDate') === selectedTimeRange.start && currentURL.get('endDate') === selectedTimeRange.end;
    const retrievedDate = dayjs(response?.initialDateTime);
    const setDate = dayjs(selectedTimeRange.start);

    if (response.initialDateTime.includes('0001')) {
      return;
    }
    if (response && currentAction === 'mounting' && (!urlDate || !retrievedDate.isSameOrBefore(setDate, 'day'))) {
      const changedDates = formatChangedDates(response);
      setSelectedTimeRange(changedDates);

      return changedDates;
    }
  };

  const getCostsData = async (
    aggregators?: MultiValueType<OptionType>,
    dates?: DateType,
    filters?: SelectedFilters,
    additionalParam?: string,
  ) => {
    const searchParams = createBaseSearchParams(aggregators || [], filters || {}, false, true, dates);

    if (additionalParam) searchParams.set(additionalParam, 'true');

    return await baseRequest('resources-total-costs', org, searchParams);
  };

  const hasHourInAnyTime = (resources: any) => {
    return resources.some((resource: any) => {
      return resource.timeseries.some((time: any) => {
        return time.time.includes('T') && time.time.split('T')[1].split(':')[0] !== '00';
      });
    });
  };

  const getSummaryData = async (searchParams: URLSearchParams) => {
    const searchParamsWithColumns = buildColumnsQuery(new URLSearchParams(searchParams));
    let response;

    try {
      response = await baseRequest('resources-summary', org, searchParamsWithColumns);
    } catch (e) {
      logger.logError('error_retrieving_costs_summary', e);
    }

    setResources({
      summaries: response.resources || [],
      totals: response.totals || {
        totalCost: 0,
        costRecommendationTotal: 0,
        numberOfRows: 0,
      },
    });
    setIsSummaryLoading(false);
  };

  const getSummaryTimeseriesData = async (searchParams: URLSearchParams) => {
    let response;

    try {
      response = await baseRequest('resources-summary-timeseries', org, searchParams);
    } catch (e) {
      logger.logError('error_retrieving_costs_summary_timeseries', e);
    }

    setResources({
      resources: response.resources || [],
    });
    setIsTimeseriesLoading(false);
  };

  const isAggregatorUpdated = () =>
    selectedAggregators?.length > 1 || (selectedAggregators?.length > 0 && selectedAggregators[0].label !== 'cluster');

  const isFilterUpdated = () => {
    const filterKeys = Object.keys(selectedFilters);
    filterKeys.splice(filterKeys.indexOf('dateRangeOption'), 1);

    if (!filterKeys?.length) {
      return false;
    }

    for (const key of filterKeys) {
      const filterValues = selectedFilters[key as keyof typeof selectedFilters] as any;
      if (filterValues && filterValues.length) {
        return true;
      }
    }

    return false;
  };

  const isClearButtonShown = useMemo(
    () => isAggregatorUpdated() || isFilterUpdated(),
    [selectedAggregators, selectedFilters],
  );

  const onClearButtonClicked = () => {
    setSelectedAggregators(initialAggregators.current);
    updateSelectedFilters(cluster, 'pop', initialFilters.current);
    setSelectedSavedView(null);
    setCurrentAction('clearing');
  };

  const onSavedViewDeleted = async (savedViewName: string) => {
    if (!savedViewName) {
      return;
    }

    try {
      await sendRequest(
        'DELETE',
        `/v0/organizations/${organization.Name}/resources/saved-views/${savedViewName}`,
        {},
        null,
      );

      toast.success(strings.efficiency.savedViewDeleted);

      setShouldRefetchSavedViews(true);
    } catch (e) {
      logger.logError('error_delete_saved_view', e);
    }
  };

  const CustomOption = (props: OptionProps) => {
    const savedViewOptions = savedViews[0]?.options || [];

    const isSavedViewOption = !!savedViewOptions.find((option) => option?.label === props?.label);

    return (
      <div>
        <components.Option {...props}>
          {props?.label || strings.general.unavailable}
          {isSavedViewOption ? (
            <img
              className="delete-saved-view-icon"
              src={trash}
              alt={strings.efficiency.deleteSavedViews}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (props?.label) {
                  onSavedViewDeleted(props.label);
                }
              }}
            />
          ) : null}
        </components.Option>
      </div>
    );
  };

  const onSaveViewClicked = () => {
    setIsNewSavedViewDialogShown(true);
  };

  const MenuList = (props: MenuListProps) => {
    return (
      <components.MenuList {...props}>
        <div className="save-view-btn-container">
          <AddButton buttonText="Save View" testLabel="save-view-btn" action={onSaveViewClicked} />
        </div>
        {props.children}
      </components.MenuList>
    );
  };

  const handleItemSelected = (row: ResourceSummary) => {
    const handlePaginationChange = (page: number) =>
      page > -1 && page < Math.ceil(totals?.numberOfRows || 0 / pageSize) ? setTableState({ currentPage: page }) : null;
    const checker = (aggregators: MultiValueType<OptionType>, validation: string[]) =>
      validation.every((v) => aggregators.length == validation.length && aggregators.some((a) => a.value === v));
    if (checker(selectedAggregators, workloadLevel)) {
      selectAggregators([...selectedAggregators, containerAggregator]);
    } else if (checker(selectedAggregators, namespaceLevel)) {
      selectAggregators([...selectedAggregators, kindAggregator, workloadAggregator]);
    } else if (checker(selectedAggregators, clusterLevel)) {
      selectAggregators([...selectedAggregators, namespaceAggregator]);
    }
    const selectedFilters = {};
    const currentAggregators =
      selectedAggregators.length > 0 ? selectedAggregators : aggregatorOptions.concat([{ value: 'pod', label: 'pod' }]);
    handlePaginationChange(0);

    currentAggregators.forEach((aggregator) => {
      const formattedSection = aggregator.section ? findLabel(aggregator.section) : `${aggregator.value}s`;
      if (hasKey(selectedFilters, formattedSection) && hasKey(row, aggregator.value)) {
        selectedFilters[formattedSection].push({
          section: formattedSection,
          value: row[aggregator.value],
          label: row[aggregator.value],
        });
      } else if (aggregator?.section) {
        selectedFilters[formattedSection] = row[formattedSection].map((filter) => ({
          section: formattedSection,
          value: `${filter.name}=${filter.value}`,
          label: `${filter.name}=${filter.value}`,
        }));
      } else {
        selectedFilters[formattedSection] = [
          { section: formattedSection, value: row[aggregator.value], label: row[aggregator.value] },
        ];
      }
    });
    selectFilters(true, selectedFilters, false);
  };

  const newPageURL = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return router().resolve({
      name: RIGHT_SIZING,
      params: { org: org, cluster: cluster },
      query: {
        from: 'costs',
        ...(params.get('startDate') && { startDate: params.get('startDate') }),
        ...(params.get('endDate') && { endDate: params.get('endDate') }),
        ...(params.get('clusters') && { clusters: params.get('clusters') }),
        ...(params.get('namespaces') && { namespaces: params.get('namespaces') }),
        ...(params.get('kinds') && { kinds: params.get('kinds') }),
        ...(params.get('workloads') && { workloads: params.get('workloads') }),
        ...(params.get('containers') && { containers: params.get('containers') }),
      },
    }).href;
  }, [window.location.search]);

  return (
    <LayoutReact className="costs">
      {organization?.Tier === 0 && (
        <Alert variant={strings.noTranslate.info} className="beta-alert">
          <div>{strings.freeTier.FreeTierBannerMessage}</div>
          <a href="https://fairwinds.com/insights-pricing" target="_blank">
            <Button className="btn plg-upgrade-btn" variant="primary">
              {strings.freeTier.Upgrade}
            </Button>
          </a>
        </Alert>
      )}
      <Breadcrumbs
        data={cluster ? clusterBreadcrumbsList(org, name, cluster) : orgBreadcrumbsList(org, name)}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <SearchFilterBar className="costs__dropdown-bar">
        <Datepicker
          disabled={isLoading}
          isClearable={false}
          onDateSelect={filterByDate}
          startDate={selectedTimeRange.start}
          endDate={selectedTimeRange.end}
          minDate={12}
          className="costs"
          utc={true}
          dateOptionSelected={dateRangeOption}
          onDateOptionSelect={setDateRangeOption}
        />
        <Select
          className="custom--select_costs"
          classNamePrefix="custom--select_costs"
          aria-label="costs-workloads-dropdown"
          isDisabled={isLoading}
          styles={{
            ...customStyles,
            menu: (provided) => ({
              ...provided,
              width: '140%',
              maxWidth: '26rem',
            }),
          }}
          value={selectedSavedView}
          options={savedViews}
          placeholder="Saved Views"
          isSearchable
          isClearable
          isLoading={areTopWorkloadsLoading}
          backspaceRemovesValue
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            DropdownIndicator,
            Option: CustomOption,
            MenuList,
          }}
          onChange={(option: WorkloadOption, actionMeta) => {
            setSavedViewsChanged(true);
            updateSelectedTopWorkload(option, actionMeta, cluster);
            if (setDateRangeOption) {
              setDateRangeOption(undefined);
              setSelectedTimeRangeChanged(true);
            }
          }}
        />
        <Select
          className="custom--select_costs"
          classNamePrefix="custom--select_costs"
          aria-label="costs-aggregator-dropdown"
          isDisabled={isLoading}
          styles={{
            ...customStyles,
            group: () => ({
              height: 'auto',
              overflow: strings.noTranslate.default,
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: strings.noTranslate.costsPadding,
              textTransform: strings.noTranslate.capitalize,
            }),
          }}
          isMulti
          value={selectedAggregators}
          options={aggregatorOptions}
          placeholder="Aggregator"
          isSearchable
          isClearable
          backspaceRemovesValue
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          components={{
            Option,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            MultiValue,
            DropdownIndicator,
          }}
          onChange={(option) => selectAggregators(option)}
        />
        {filters && Object.keys(filters).length > 0 && (
          <DropdownButton
            disabled={isLoading}
            title={
              <span className="filter-title">
                <FilterIcon />
                {strings.efficiency.Filtering}
                {currentFilters > 0 && (
                  <div className="costs__filter-number">{currentFilters > 99 ? '99+' : currentFilters}</div>
                )}
              </span>
            }
            id="filter-options"
            data-cy="filter-button"
          >
            <CustomMenu
              filters={
                filters || {
                  clusters: [],
                  containers: [],
                  kinds: [],
                  workloads: [],
                  namespaces: [],
                }
              }
              cluster={cluster}
              selectedFilters={selectedFilters}
              setTableState={setTableState}
              selectFilters={selectFilters}
              setSelectedSavedView={setSelectedSavedView}
              filtersOrder={[
                'clusters',
                'namespaces',
                'kinds',
                'workloads',
                'containers',
                'efficiencies',
                'resourceTypes',
                'workloadLabels',
                'namespaceLabels',
                'podLabels',
                'appGroups',
              ]}
            />
          </DropdownButton>
        )}
        {!disableApplyButton && (
          <div>
            <Button
              variant="primary"
              onClick={() => setCurrentAction('clicked')}
              disabled={disableApplyButton}
              size="sm"
              data-cy="apply-changes-button"
              className="apply-changes-button"
            >
              {strings.efficiency.applyChanges}
            </Button>
          </div>
        )}
        {disableApplyButton && isClearButtonShown ? (
          <div onClick={onClearButtonClicked} className="clear-changes-button">
            <CloseIcon />
            <span>{strings.efficiency.clear}</span>
          </div>
        ) : null}
      </SearchFilterBar>
      <SyncedDates loading={syncedDatesLoading} />
      {((currentAction !== 'updating' && resources.length > 0) ||
        (resources.length === 0 && Object.keys(selectedFilters).length > 0)) && (
        <div className="costs__charts">
          <div className="costs__top-div costs__chart-section">
            <CostsOverTimeChart
              data={resources}
              aggregators={selectedAggregators}
              route={route}
              isLoading={isTimeseriesLoading}
              period={hasHourInAnyTime(resources) ? strings.noTranslate.hourly : strings.noTranslate.daily}
            />
            <CostsDonut data={totalCosts} dates={selectedTimeRange} isLoading={isTotalCostsLoading} />
          </div>
          <div className="costs__chart-section">
            <AggregatorBarChart
              data={summaries}
              aggregators={selectedAggregators}
              setFilters={selectFilters}
              totals={totals}
              savingsAvailable={savingsAvailable}
              selectedFilters={selectedFilters}
              isLoading={isSummaryLoading && isTotalCostsLoading}
              isSavingsAvailableLoading={isSavingsAvailableLoading}
            />
          </div>
          <div className="costs__chart-section">
            <AggregatorTable
              aggregators={selectedAggregators}
              summaries={summaries}
              pageSize={pageSize}
              setFilters={selectFilters}
              route={route}
              router={router}
              totalResults={totals?.numberOfRows || 0}
              pageIndex={currentPage}
              isLoading={isSummaryLoading}
              setTableState={setTableState}
              onItemSelected={handleItemSelected}
            />
          </div>
          <div className="costs__chart-section">
            {showUsageChart && summaries?.length > 0 && (
              <>
                <Alert className="right-sizing-page-alert" variant="success">
                  <Alert.Heading>Check out our new and improved Right-Sizing Page</Alert.Heading>
                  <p>
                    We've made updates to improve features and added new visualization options.{' '}
                    <Alert.Link
                      style={{ backgroundColor: '#d9e2da', fontWeight: 600 }}
                      href={newPageURL}
                      target="_blank"
                    >
                      Visit the new page
                    </Alert.Link>{' '}
                    to see the changes!
                  </p>
                </Alert>
                <ResourceUsageChart
                  timeRange={selectedTimeRange}
                  route={route}
                  period={period}
                  selectedFilters={selectedFilters}
                  org={org}
                  summaries={summaries}
                  router={router}
                />
              </>
            )}
          </div>
        </div>
      )}
      {!isLoading && !syncedPrometheusDate && currentAction !== 'updating' && !resources.length && (
        <div className="costs__no-data">
          <h1>{strings.efficiency.installPrometheusForData}</h1>
          {cluster && (
            <Button
              variant="primary"
              onClick={() =>
                router().push({
                  name: REPORT_HUB,
                  params: { cluster, org, report: 'prometheus-metrics' },
                })
              }
            >
              {strings.efficiency.installPrometheus}
            </Button>
          )}
        </div>
      )}
      <NewSavedViewDialog
        isModalShown={isNewSavedViewDialogShown}
        onModalHidden={() => setIsNewSavedViewDialogShown(false)}
        onSaveViewCreated={() => setShouldRefetchSavedViews(true)}
        selectedAggregators={selectedAggregators}
        selectedFilters={selectedFilters}
        organizationName={organization.Name}
        dateRangeOption={dateRangeOption}
      />
      <Toaster />
    </LayoutReact>
  );
};

export default CumulativeCosts;
