// eslint-disable-next-line
import Integrations from './Integrations.react';

export default {
  components: {
    // eslint-disable-next-line quote-props
    integrations: Integrations,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
