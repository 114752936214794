import React, { useState } from 'react';
import { sendRequest } from '~utils/request';
import toast from 'react-hot-toast';
import logger from '~logger';
import { ActionItem, ActionItemFilters, List, ModalInfo, NewList } from '../ActionItems.types.react';
import { findMatchingListById, findMatchingListByName, formattingQuery } from '../ActionItems.helpers.react';
import { Row } from 'react-table';
import { strings } from '~utils/strings';

const useListsReact = (
  baseURL: string,
  fetchLists: () => Promise<List[] | undefined>,
  selectedFlatRows: Row<ActionItem>[] = [],
  loadActionItems?: (list: List) => void,
) => {
  const [listNames, setListNames] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<ModalInfo>({ show: false, modal: '' });
  const [selectedList, setSelectedList] = useState<List | null>(null);
  const [selectedSavedList, setSelectedSavedList] = useState<List | null>(null);
  const [importedList, setImportedList] = useState<string>('');
  const [listName, setListName] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [lists, setLists] = useState<List[]>([]);
  const [modalError, setModalError] = useState<string>('');
  const [listType, setListType] = useState<string>('');

  const submitNewList = async (listName: string, importedList: string): Promise<void> => {
    if (!listName) {
      setModalError(strings.useListsHook.noNameError);
      return;
    }

    const data: NewList = {
      Name: listName,
    };
    let matchedList;

    if (listType === 'query' && query) {
      const filters = createFilters(query);
      data.Filters = filters;
    } else if (listType === 'query' && !query) {
      setModalError(strings.useListsHook.noQueryError);
      return;
    } else if (listType === 'manual' && importedList && importedList !== 'List') {
      matchedList = findMatchingListByName(importedList, lists);
      loadActionItems && (await loadActionItems(matchedList as List));
      data.Filters = null;
      data.ActionItems = matchedList?.ActionItems;
      if (!data.ActionItems) {
        toast.error(strings.actionItemsList.noActionItemsForList);
        return;
      }
    } else if (listType === 'manual' && !importedList) {
      data.Filters = null;
      data.ActionItems = selectedFlatRows.map((item) => ({ ID: item.original.ID }));
    }

    try {
      if (data.Filters || data.ActionItems) {
        await sendRequest('POST', `${baseURL}/lists`, { data }, null);
        fetchLists();
        closeModal();
        toast.success(strings.useListsHook.newListCreated.replace('$listName', listName));
      }
    } catch (e) {
      if (e.status === 403) {
        logger.logError('error_creating_new_list_unauthorized');
        toast.error(strings.useListsHook.notAuthorized);
      } else {
        logger.logError('error_creating_new_list');
        toast.error(strings.useListsHook.addingNewListError);
      }
    }
  };

  const createFilters = (query: string): ActionItemFilters | null => {
    // TODO: create more robust error handling;
    // create an array of all possible filters; check to see if all of the query parts match the possible filters?
    // check if each query part is properly capitalized?
    const errMessage = strings.useListsHook.wrongQueryFormat;
    if (!query.includes('=')) {
      setModalError(errMessage);
      return null;
    }
    const filters: ActionItemFilters = {};
    const queryParts = query.split(/\sand\s/i);
    for (const queryPart of queryParts) {
      const [key, values] = queryPart.split('=').map((s) => s.trim());
      if (!key || key[0] !== key[0].toUpperCase()) {
        setModalError(errMessage);
        return null;
      }
      filters[key] = values?.split(/,\s*/);
    }
    return filters;
  };

  const editListModal = (list: List) => {
    if (list) {
      setSelectedList(list);
      if (list?.Filters) {
        const newQuery = formattingQuery(list?.Filters);
        setQuery(newQuery);
      }
      setShowModal({ show: true, modal: 'edit' });
    } else {
      toast.error(strings.useListsHook.listNotFound);
    }
  };

  const deleteList = async (): Promise<void> => {
    try {
      if (selectedList) {
        await sendRequest('DELETE', `${baseURL}/lists/${selectedList.ID}`, {}, null);

        setShowModal({ show: false, modal: '' });
        await fetchLists();

        const filteredList = listNames.filter((name: string) => name !== selectedList.Name);

        setListNames(filteredList);
        toast.success(strings.useListsHook.listDeleted);
      }
    } catch (e) {
      if (e.status === 403) {
        logger.logError('error_deleting_list_unauthorized');
        toast.error(strings.useListsHook.unauthorizedListDelete);
      } else {
        logger.logError('error_deleting_list', e);
        toast.error(strings.useListsHook.errorDeletingList);
      }
    }
  };

  const deleteListModal = (id: number) => {
    const matchingList = findMatchingListById(id, lists);
    delete matchingList?.ActionItemIndex;
    if (matchingList) setSelectedList(matchingList);
    setShowModal({ show: true, modal: 'delete' });
  };

  const editList = async (): Promise<void> => {
    const data: NewList = {
      Name: listName || selectedList?.Name,
      Filters: null,
      ActionItems: selectedList?.Type === 'manual' ? selectedList?.ActionItems : null,
    };

    if (selectedList && query) {
      const filters = createFilters(query);
      data.Filters = filters as ActionItemFilters;
    } else if (selectedList && importedList !== '' && importedList !== 'List') {
      const matchedList = findMatchingListByName(importedList, lists);
      loadActionItems && (await loadActionItems(matchedList as List));
      if (matchedList?.ActionItems?.length && matchedList?.ActionItems?.length > 0) {
        data.ActionItems = data.ActionItems?.concat(matchedList?.ActionItems as ActionItem[]);
      }
    }

    try {
      await sendRequest('PUT', `${baseURL}/lists/${selectedList?.ID}`, { data }, null);
    } catch (e) {
      if (e.status === 403) {
        logger.logError('error_editing_list_unauthorized', e);
        toast.error(strings.useListsHook.unauthorizedEditingList);
      } else {
        toast.error(strings.useListsHook.notEditableList);
        logger.logError('error_editing_list', e);
      }

      if (query) {
        toast.error(strings.useListsHook.unableEditList);
      } else {
        toast.error(strings.useListsHook.unableUpdateList);
      }
      return;
    }
    await fetchLists();
    setSelectedList(null);
    closeModal();
    toast.success(strings.useListsHook.listUpdated);
  };

  const closeModal = () => {
    setShowModal({ show: false, modal: '' });
    setModalError('');
    setListName('');
    setQuery('');
    setImportedList('');
  };

  const deleteActionItemModal = (updatedList: List): void => {
    setSelectedList(updatedList);
    setShowModal({ show: true, modal: 'delete' });
  };

  const deleteActionItem = async () => {
    let response;
    // FIXME: can't mutate here..
    if (selectedList && selectedList.ActionItemIndex !== undefined && selectedList?.ActionItemIndex >= 0) {
      selectedList?.ActionItems?.splice(selectedList?.ActionItemIndex, 1);
    }
    const data = {
      Name: selectedList?.Name,
      Filters: null,
      Order: selectedList?.Order,
      ActionItems: selectedList?.ActionItems,
    };
    try {
      response = await sendRequest('PUT', `${baseURL}/lists/${selectedList?.ID}`, { data }, null);
    } catch (e) {
      toast.error(strings.useListsHook.unableDeleteActionItem);
      logger.logError('error_removing_action_item', e);
    }

    if (response.Success) {
      toast.success(<b>{strings.useListsHook.deletedActionItem}</b>);
      setShowModal({ show: false, modal: '' });
      await fetchLists();
    }
  };

  const checkListName = (name: string): void => {
    if (name.length > 64) {
      setListName('');
      setModalError(strings.useListsHook.listNameTooLong);
      return;
    } else if (name.length < 1) {
      setListName('');
      setModalError(strings.useListsHook.noListName);
      return;
    }
    setListName(name);
    setModalError('');
  };

  return {
    checkListName,
    deleteActionItem,
    deleteActionItemModal,
    deleteList,
    deleteListModal,
    editList,
    editListModal,
    closeModal,
    submitNewList,
    createFilters,
    selectedSavedList,
    showModal,
    listType,
    query,
    setQuery,
    listName,
    modalError,
    listNames,
    setListType,
    setListName,
    setShowModal,
    setModalError,
    importedList,
    setImportedList,
    setSelectedSavedList,
    setSelectedList,
    selectedList,
    lists,
    setLists,
    setListNames,
    loadActionItems,
  };
};

export default useListsReact;
