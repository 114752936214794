import React from 'react';

import { Breadcrumbs } from '@fairwindsops/ui-components';

import {
  APP_GROUPS_SUMMARY,
  ORG_DASHBOARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import AppGroupSummaryTable from './components/AppGroupsSummaryTable/AppGroupsSummaryTable.react';

import { IStore, IRoute, IRouter } from '~globalTypes';

import { handlePageChange } from '~reactHelpers';
import { strings } from '~utils/strings';

import './AppGroupsSummary.react.scss';

type AppGroupsSummaryProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  isAddAppGroupModalShown?: boolean;
};

const AppGroupsSummary = ({
  route,
  router,
  store,
  isAddAppGroupModalShown,
}: AppGroupsSummaryProps) => {
  const organization = store().getters.organization;

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: APP_GROUPS_SUMMARY,
      label: strings.appGroups.AppGroups,
      href: `/orgs/${organization.Name}/app-groups/summary`,
    },
    {
      id: 'last',
      label: strings.appGroups.summary,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="app-groups-summary">
      <div className="app-groups-summary__container">
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <div>
          <AppGroupSummaryTable
            store={store}
            route={route}
            router={router}
            isAddAppGroupModalShown={isAddAppGroupModalShown}
          />
        </div>
      </div>
    </div>
  );
};

export default AppGroupsSummary;
