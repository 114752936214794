import React, { useMemo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import clsx from 'clsx';

import { SeverityCount } from '~globalTypes';

import { strings } from '~utils/strings';
import { XSText } from '~utils/texts.react';

import './SeverityBadge.react.scss';

type SeverityBadgeProps = {
  id: string;
  severityCount: SeverityCount;
};

const SeverityBadge = ({ id, severityCount }: SeverityBadgeProps) => {
  const actionItem = 'Action Item';

  if (!severityCount) return <></>;

  const renderTooltip = (value: number, label: string) => {
    const actionItemLabel = value > 0 ? `${actionItem}s` : actionItem;

    return (
      <Popover id={`severity-badge-tooltip-${label}-${id}`}>
        <Popover.Content>
          {value} {label} {actionItemLabel}
        </Popover.Content>
      </Popover>
    );
  };

  const fieldsMapper = useMemo(
    () => [
      {
        fieldName: 'nCritical',
        label: 'Critical',
        shorthand: 'C',
        className: 'severity-badge__critical',
      },
      {
        fieldName: 'nHigh',
        label: 'High',
        shorthand: 'H',
        className: 'severity-badge__high',
      },
      {
        fieldName: 'nMedium',
        label: 'Medium',
        shorthand: 'M',
        className: 'severity-badge__medium',
      },
      {
        fieldName: 'nLow',
        label: 'Low',
        shorthand: 'L',
        className: 'severity-badge__low',
      },
    ],
    [],
  );

  return (
    <div className={clsx('severity-badge', XSText({ weight: strings.textStyling.medium }))}>
      {fieldsMapper.map((element) => {
        const field = element.fieldName as keyof typeof severityCount;
        return (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(severityCount[field], element.label)}
          >
            <span
              className={`${element.className} severity-badge__badge ${
                severityCount[field] === 0 ? 'severity-badge__gray' : ''
              }`}
            >
              {severityCount[field]} {element.shorthand}
            </span>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default SeverityBadge;
