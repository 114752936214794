import React, { Children } from 'react';
import { TableInstance } from 'react-table';
import Select, { components, ValueContainerProps, MultiValue } from 'react-select';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { SELECT_STYLES } from '../ImageDetailItemsTable/ImageDetailItemsTable.config.react';
import {
  filtersMap,
  sortingFields,
} from '../ImageDetailItemsTable/ImageDetailItemsTable.types.react';

import {
  mapFilters,
  formatStatus,
  saveResizedWidth,
  RESIZED_WIDTH_STORAGE_KEYS,
} from '~reactHelpers';
import { FiltersMapType } from '~globalTypes';

import { Options } from './SelectColumnFilter.config.react';
import { COLORS } from '~utils/styling';

import './SelectColumnFilter.react.scss';

type OptionType = {
  value: string;
  label: string;
};

type SelectColumnFilterType = {
  tableInstance: TableInstance;
  filters: FiltersMapType;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    return child && allowedTypes.includes(child.type.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const SelectColumnFilter = ({ tableInstance, filters, setLoaded }: SelectColumnFilterType) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = tableInstance;

  const mappedFilters = mapFilters(filters, filtersMap, sortingFields);

  let options: OptionType[];

  const formatOption = (id: string, option: unknown) => {
    switch (id) {
      case Options.Status:
        return formatStatus(option as string);
      case Options.HasFixedVersion:
        return formatHasFixedVersions(option);
      default:
        return String(option);
    }
  };

  const formatValue = (id: string, option: unknown) => {
    return id === Options.HasFixedVersion ? formatHasFixedVersions(option) : String(option);
  };

  const formatHasFixedVersions = (option: unknown) => {
    return option === Options.TrueInt ? Options.True : Options.False;
  };

  if (mappedFilters?.[id] && Array.isArray(mappedFilters[id])) {
    options = (mappedFilters[id] || []).map((option: unknown) => {
      if (typeof option === 'object') {
        return option;
      }
      return {
        label: formatOption(id, option),
        value: formatValue(id, option),
      };
    });
  } else {
    options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows?.forEach((row) => {
        options.add(row.values[id]?.toString());
      });
      return Array.from(options).map((option) => ({
        label: option,
        value: option,
      }));
    }, [id, preFilteredRows]);
  }

  const handleChange = (selected: MultiValue<OptionType>) => {
    if (!selected?.length && !filterValue?.length) return;

    saveResizedWidth(RESIZED_WIDTH_STORAGE_KEYS.imageDetailPage, tableInstance);

    setFilter(selected);
    setLoaded && setLoaded(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={filterValue}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          ValueContainer,
        }}
        onChange={(selected) => handleChange(selected)}
      />
    </div>
  );
};

export default React.memo(SelectColumnFilter);
