import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import PieChart from '~reactComponents/charts/PieChart/PieChart.react';

import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ChartType, Cluster, DateType } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { colorScheme } from '~config/main.config';
import { convertFiltersToURL } from '~reactHelpers';
import { strings } from '~utils/strings';

import '../Charts.react.scss';

type SeverityBreakdownProps = {
  baseURL: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  noData?: React.ReactNode;
  setFilter: (columnId: string, updater: any) => void;
  firstSeenRange: DateType | null;
};

const SeverityBreakdown = ({
  baseURL,
  cluster,
  filters,
  noData,
  setFilter,
  firstSeenRange,
}: SeverityBreakdownProps) => {
  const [severities, setSeverities] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getSeverities();
    return () => {
      isMounted.current = false;
    };
  }, [cluster]);

  useEffect(() => {
    getSeverities();
  }, [filters, firstSeenRange?.start, firstSeenRange?.end]);

  const getSeverities = async () => {
    try {
      setLoaded(false);
      const params = new URLSearchParams(
        cluster ? `groupBy=cveSeverity&cluster=${cluster.Name}` : 'groupBy=cveSeverity',
      );
      const URLParams = convertFiltersToURL(filters, params);
      if (firstSeenRange?.start) {
        URLParams.append('firstSeen', firstSeenRange?.start);
      }
      if (firstSeenRange?.end) {
        URLParams.append('firstSeenEnd', firstSeenRange?.end);
      }
      const response = await sendRequest('GET', `${baseURL}/vulnerabilities/top?${URLParams}`, {}, null);
      if (isMounted.current) {
        setSeverities(transformResponse(response));
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_vuln_severities: ', e);
      toast.error(strings.vulnerabilities.errorVulnSeverities);
    }
  };

  const transformResponse = (response: ChartType[]) =>
    response?.length ? response.map((res) => ({ ...res, value: res.value?.toLowerCase() })) : [];

  const data = useMemo(() => {
    return severities?.map((severity) => ({
      label: severity.value.charAt(0).toUpperCase() + severity.value.slice(1),
      id: severity.value,
      color: (colorScheme as Record<string, string>)[severity.value],
      value: severity.count,
    }));
  }, [severities]);

  const handleChartClick = (data) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'cveSeverity')?.value?.length) return;
    setFilter('cveSeverity', [{ value: data.id, label: data.id }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('cveSeverity', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'cveSeverity')?.value?.length}
      />
      {data?.length ? (
        <div aria-label={strings.ariaLabels.severityPieChart} className="vulnerabilities--charts">
          <PieChart data={data || []} handleChartClick={handleChartClick} />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default SeverityBreakdown;
