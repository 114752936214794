import React, { useMemo } from 'react';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { ImageSummary } from '~globalTypes';
import { Labels } from './RiskReduction.types.react';

import './RiskReduction.react.scss';

type RiskReductionProps = {
  imageSummary: ImageSummary | undefined;
  loaded: boolean;
};

const RiskReduction = ({ imageSummary, loaded }: RiskReductionProps) => {
  const currentTag = useMemo(
    () => (imageSummary?.tag ? imageSummary.tag : Labels.NA),
    [imageSummary],
  );

  const recommendedImage = useMemo(
    () => (imageSummary?.recommendedImage ? imageSummary.recommendedImage : Labels.NA),
    [imageSummary],
  );

  const imageVulnCount = useMemo(
    () =>
      imageSummary?.imageVulnCount || imageSummary?.imageVulnCount === 0
        ? imageSummary.imageVulnCount
        : Labels.NA,
    [imageSummary],
  );

  const riskReductionPercent = useMemo(() => {
    if (!imageSummary?.imageVulnCount || !imageSummary?.recommendedImage) return 0;
    if (!imageSummary?.recommendedImageVulnCount) return 100;
    const result =
      ((imageSummary?.imageVulnCount - imageSummary?.recommendedImageVulnCount) * 100) /
      imageSummary?.imageVulnCount;
    return Number.isInteger(result) ? result : result.toFixed(2);
  }, [imageSummary]);

  const recommendedImageVulnCount = useMemo(() => {
    if (!imageSummary?.recommendedImage?.length) {
      return Labels.NA;
    } else if (
      imageSummary?.recommendedImageVulnCount ||
      imageSummary?.recommendedImageVulnCount === 0
    ) {
      return imageSummary.recommendedImageVulnCount;
    } else {
      return Labels.NA;
    }
  }, [imageSummary]);

  return (
    <Card>
      <Card.Body padded>
        <h2 className="vulnerabilities-right-card__title">
          {Labels.RiskReduction} {riskReductionPercent}
          {Labels.Percent}
        </h2>
        {loaded ? (
          <div className="vulnerabilities-right-card__risk-reduction">
            <div className="vulnerabilities-right-card__column">
              <div>
                <h3 title={currentTag}>{currentTag}</h3>
                <h4>{Labels.CurrentTag}</h4>
              </div>
              <div>
                <h3 title={recommendedImage}>{recommendedImage}</h3>
                <h4>{Labels.RecommendedTag}</h4>
              </div>
            </div>
            <div className="vulnerabilities-right-card__column">
              <div>
                <h3 title={String(imageVulnCount)}>{imageVulnCount}</h3>
                <h4>{Labels.Vulnerabilities}</h4>
              </div>
              <div>
                <h3 title={String(recommendedImageVulnCount)}>{recommendedImageVulnCount}</h3>
                <h4>{Labels.Vulnerabilities}</h4>
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
};

export default RiskReduction;
