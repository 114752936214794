import React from 'react';

import { Button, Modal } from 'react-bootstrap';

import CloseIcon from '../Icons/Close.icon.react';

import './ConfirmationDialog.react.scss';

type ConfirmationDialogProps = {
  cancelButtonClasses?: string;
  cancelButtonText?: string;
  confirmButtonClasses?: string;
  confirmButtonText?: string;
  isModalShown?: boolean;
  modalBodyClasses?: string;
  modalContent?: string;
  modalContentClasses?: string;
  modalTitle?: string;
  onConfirmClicked?: () => void;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  modalClassName?: string;
  dataCyConfirmButton?: string;
};

const ConfirmationDialog = ({
  cancelButtonClasses,
  cancelButtonText,
  confirmButtonClasses,
  confirmButtonText,
  isModalShown,
  modalBodyClasses,
  modalContent,
  modalContentClasses,
  modalTitle,
  onConfirmClicked,
  onModalHidden,
  modalClassName,
  dataCyConfirmButton,
}: ConfirmationDialogProps) => {
  const closeModal = () => {
    onModalHidden && onModalHidden(false);
  };

  return (
    <Modal show={isModalShown} onHide={closeModal} className={modalClassName ? modalClassName : ''}>
      <Modal.Body className={`pt-0 ${modalBodyClasses ? modalBodyClasses : ''}`}>
        <div className="confirm-modal-title-container">
          <h3 className="modal-title">{modalTitle}</h3>
          <CloseIcon
            width="0.75rem"
            height="0.75rem"
            onClick={closeModal}
            className="confirm-modal-close-icon"
          />
        </div>
        <p className={`${modalContentClasses ? modalContentClasses : ''}`}>{modalContent}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={`btn ${cancelButtonClasses ? cancelButtonClasses : ''}`}
          type="button"
          onClick={closeModal}
        >
          {cancelButtonText}
        </Button>
        <Button
          className={`btn ${confirmButtonClasses ? confirmButtonClasses : ''}`}
          type="button"
          onClick={() => onConfirmClicked && onConfirmClicked()}
          data-cy={dataCyConfirmButton || ''}
        >
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationDialog;
