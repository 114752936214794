import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Select, {
  StylesConfig,
  components,
  MultiValueGenericProps,
  createFilter,
  OptionProps,
  MultiValue,
  ActionMeta,
} from 'react-select';
import { Virtuoso } from 'react-virtuoso';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { OptionType } from '~globalTypes';
import { MenuListType, CustomMenuType, CostsOptions } from './Costs.types.react';

import { hasKey } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import {
  ORG_EFFICIENCY,
  ORG_DASHBOARD,
  NODE_EFFICIENCY,
  CUMULATIVE_COSTS,
  CUMULATIVE_COSTS_CLUSTER,
  CLUSTER_OVERVIEW,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { namespaceLabels, podLabels, workloadLabels } from './Costs.helpers.react';
import InputTag from '~reactComponents/InputTag/InputTag.react';
import { all } from 'axios';

export const costsRoutes = [CUMULATIVE_COSTS, CUMULATIVE_COSTS_CLUSTER];

export const totalColumnsOptions = {
  CPU: strings.noTranslate.cpuCost,
  Memory: strings.noTranslate.memoryCost,
  'Network Receive': strings.noTranslate.networkReceiveCost,
  'Network Transmit': strings.noTranslate.networkTransmitCost,
  Disk: strings.noTranslate.storageCapacityCost,
};

// reducers
export function genericReducer<T>(state: T, action: Partial<T>) {
  return {
    ...state,
    ...action,
  };
}

export function filterReducer<T>(state: T, action: { type: string | null; payload: T }) {
  if (action.type === 'pop') {
    return { ...action.payload };
  }
  if (Object.keys(action.payload).length) {
    return { ...state, ...action.payload };
  } else {
    return {};
  }
}

// resources per pod configs
// used for showing/hiding resources per pod chart
// aggregators required
export const usageChartRequired = [
  strings.noTranslate.workload,
  strings.noTranslate.namespace,
  strings.noTranslate.kind,
];

// filters required
export const resourcesPerPodRequired = [
  strings.noTranslate.clusters,
  strings.noTranslate.workloads,
  strings.noTranslate.kinds,
  strings.noTranslate.namespaces,
];

// table columns hidden defaults
export const defaultHiddenColumns = [
  strings.noTranslate.receiveBytes,
  strings.noTranslate.costReceiveBytes,
  strings.noTranslate.transmitBytes,
  strings.noTranslate.costTransmitBytes,
  strings.noTranslate.diskBytes,
  strings.noTranslate.costDiskBytes,
  'resources.settings.limits.memory',
  'resources.recommendation.limits.memory',
  'resources.settings.requests.memory',
  'resources.recommendation.requests.memory',
  'resources.averageUsage.memory',
  'costs.actual.memory',
  'costs.recommendation.memory',
  'costs.difference.memory',
  'resources.settings.limits.cpu',
  'resources.recommendation.limits.cpu',
  'resources.settings.requests.cpu',
  'resources.recommendation.requests.cpu',
  'resources.averageUsage.cpu',
  'costs.actual.cpu',
  'costs.recommendation.cpu',
  'costs.difference.cpu',
];

// react-select dropdown options
export const aggregatorOptions = [
  { value: strings.noTranslate.cluster, label: strings.general.Cluster },
  { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
  { value: strings.noTranslate.kind, label: strings.efficiency.kind },
  { value: strings.noTranslate.workload, label: strings.efficiency.workload },
  { value: strings.noTranslate.container, label: strings.efficiency.container },
  { label: 'workload labels', section: 'workloadLabels' },
  { label: 'namespace labels', section: 'namespaceLabels' },
  { label: 'pod labels', section: 'podLabels' },
];

export const qosLabels = [
  { value: 'critical', label: strings.qosRecs.Critical },
  { value: 'burstable_plus', label: strings.qosRecs.BurstablePlus },
  { value: 'guaranteed', label: strings.qosRecs.Guaranteed },
  { value: 'burstable', label: strings.qosRecs.Burstable },
  { value: 'limited', label: strings.qosRecs.Limited },
];

export const quickviewOptionsOrg = [
  {
    label: strings.efficiency.quickViews.topClusters,
    value: 'top-clusters',
  },
  {
    label: strings.efficiency.quickViews.topNamespacesGrouped,
    value: 'namespaces-grouped',
  },
  {
    label: strings.efficiency.quickViews.topNamespacesUngrouped,
    value: 'namespaces-ungrouped',
  },
  {
    label: strings.efficiency.quickViews.topWorkloads,
    value: 'top-workloads',
  },
  {
    label: strings.efficiency.quickViews.kubeSystemByCluster,
    value: 'kube-systems',
  },
];

export const quickviewOptionsCluster = [
  {
    label: strings.efficiency.quickViews.topWorkloads,
    value: 'top-workloads-cluster',
  },
  {
    label: strings.efficiency.quickViews.topNamespaces,
    value: 'top-namespaces',
  },
];

export const allowWildcard = ['workloads'];

// quickviews aggregators & filters configs
const emptyFilters = {
  clusters: [],
  workloads: [],
  containers: [],
  kinds: [],
  namespaces: [],
  workloadLabels: [],
  namespaceLabels: [],
  podLabels: [],
};

export const topWorkloadsAggregators = [
  { value: strings.noTranslate.cluster, label: strings.general.cluster },
  { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
  { value: strings.noTranslate.kind, label: strings.efficiency.kind },
  { value: strings.noTranslate.workload, label: strings.efficiency.workload },
];

export const quickViews = {
  clear: {
    aggregators: [{ value: strings.noTranslate.cluster, label: strings.general.cluster }],
    filters: emptyFilters,
  },
  [strings.noTranslate.topClusters]: {
    aggregators: [{ value: strings.noTranslate.cluster, label: strings.general.cluster }],
    filters: emptyFilters,
  },
  [strings.noTranslate.namespacesGrouped]: {
    aggregators: [{ value: strings.noTranslate.namespace, label: strings.efficiency.namespace }],
    filters: emptyFilters,
  },
  [strings.noTranslate.namespacesUngrouped]: {
    aggregators: [
      { value: strings.noTranslate.cluster, label: strings.general.cluster },
      { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
    ],
    filters: emptyFilters,
  },
  [strings.noTranslate.topWorkloads]: {
    aggregators: topWorkloadsAggregators,
    filters: emptyFilters,
  },
  [strings.noTranslate.kubeSystems]: {
    aggregators: [
      { value: strings.noTranslate.cluster, label: strings.general.cluster },
      { value: strings.noTranslate.namespace, label: strings.efficiency.namespace },
    ],
    filters: {
      clusters: [],
      workloads: [],
      containers: [],
      kinds: [],
      workloadLabels: [],
      namespaceLabels: [],
      podLabels: [],
      namespaces: [
        {
          section: strings.noTranslate.namespaces,
          value: 'kube-system',
          label: strings.efficiency.kubeSystem,
        },
      ],
    },
  },
  [strings.noTranslate.topWorkloadsCluster]: {
    aggregators: topWorkloadsAggregators,
  },
  [strings.noTranslate.topNamespaces]: {
    aggregators: [{ value: strings.noTranslate.namespace, label: strings.efficiency.namespace }],
  },
};

// Breadcrumbs
export const orgBreadcrumbsList = (org: string, name = '') => [
  {
    id: ORG_DASHBOARD,
    label: org,
    href: `/orgs/${org}/dashboard`,
  },
  {
    id: ORG_EFFICIENCY,
    label: strings.navigation.Efficiency,
    href: `/orgs/${org}/efficiency`,
  },
  {
    id: 'last',
    label: name === ORG_EFFICIENCY || name === NODE_EFFICIENCY ? strings.navigation.Capacity : strings.navigation.Costs,
    href: `/orgs/${org}/dashboard`,
    isActive: true,
  },
];

export const clusterBreadcrumbsList = (org: string, name = '', cluster: string) => [
  {
    id: ORG_DASHBOARD,
    label: org,
    href: `/orgs/${org}/dashboard`,
  },
  {
    id: ORG_EFFICIENCY,
    label: strings.navigation.Efficiency,
    href: `/orgs/${org}/efficiency`,
  },
  {
    id: CLUSTER_OVERVIEW,
    label: cluster,
    href: `/orgs/${org}/clusters/${cluster}/overview`,
  },
  {
    id: 'last',
    label: name === ORG_EFFICIENCY || name === NODE_EFFICIENCY ? strings.navigation.Capacity : strings.navigation.Costs,
    href: `/orgs/${org}/dashboard`,
    isActive: true,
  },
];

export const settingsBreadcrumbsList = (org: string, cluster: string) => [
  {
    id: ORG_DASHBOARD,
    label: org,
    href: `/orgs/${org}/dashboard`,
  },
  {
    id: ORG_EFFICIENCY,
    label: strings.navigation.Efficiency,
    href: `/orgs/${org}/efficiency`,
  },
  {
    id: NODE_EFFICIENCY,
    label: cluster || 'All Clusters',
    href: `/orgs/${org}/clusters/${cluster}/efficiency-clusters`,
  },
  {
    id: 'last',
    label: strings.navigation.Settings,
    href: `/orgs/${org}/clusters/${cluster}/efficiency-clusters/settings`,
    isActive: true,
  },
];

export const WorkloadsOption = (props: OptionProps<OptionType, true>) => {
  const { data, label, isSelected, selectOption } = props;

  return (
    <div
      title={label}
      className="select-checkbox"
      onClick={(event) => {
        event.preventDefault();
        selectOption(data);
      }}
    >
      <label>
        <input
          type="checkbox"
          id={`${label}-checkbox`}
          checked={isSelected}
          style={{ pointerEvents: 'none' }}
          onClick={(event) => event.preventDefault()}
          onChange={() => {}}
        />
        <span />
      </label>
      <span className="select-checkbox__label">{label}</span>
    </div>
  );
};

const MenuList = ({ children, maxHeight }: MenuListType) => {
  const content = (index: number) => (Array.isArray(children) ? children[index] : children);
  let totalCount = 1;

  if (Array.isArray(children) && children.length < 1000) {
    totalCount = children.length;
  } else if (Array.isArray(children) && children.length > 1000) {
    totalCount = 1000;
  }

  return (
    <Virtuoso
      style={{ height: totalCount === 1 ? 40 : maxHeight }}
      totalCount={totalCount}
      itemContent={(index) => <div>{content(index)}</div>}
    />
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<ColourOption>) => {
  if (props.data.value === strings.punctuation.asterisk) {
    return <></>;
  }
  return <components.MultiValueContainer {...props} />;
};

const sortByOrder = (orderArray: string[], dataArray: string[]) => {
  const getIndex = (e: string) => orderArray.indexOf(e);
  dataArray.sort((a, b) => {
    const indexA = getIndex(a);
    const indexB = getIndex(b);
    if (indexA === -1 && indexB === -1) return 0; // both elements are unknown
    if (indexA === -1) return 1; // element a is unknown, so a should come after b
    if (indexB === -1) return -1; // element b is unknown, so b should come after a
    return indexA - indexB; // compare the indices of the elements
  });
  return dataArray;
};

type BilledCostItemProps = {
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
};

const BilledCostItem = ({ onBlur, onChange, value }: BilledCostItemProps) => {
  return (
    <div key="billedCostGreaterThan" className="filter-section__section">
      <h1 className="filter-section__title">{strings.general.billedCostGreaterThanLabel}:</h1>
      <div className="custom--select_filtering__control custom--text_filtering__value-container billed_cost_greater_than">
        <input
          className="custom--text_filtering"
          type="number"
          min="0"
          step="0.0001"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

type NetworkingLessThanItemProps = {
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: any;
};

const NetworkingLessThanItem = ({ onBlur, onChange, value }: NetworkingLessThanItemProps) => {
  return (
    <div key="networkingLessThan" className="filter-section__section">
      <h1 className="filter-section__title">{strings.general.networkingLessThanInMBLabel}:</h1>
      <div className="custom--select_filtering__control custom--text_filtering__value-container billed_cost_greater_than">
        <input
          className="custom--text_filtering"
          type="number"
          min="0"
          step="0.0001"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export const CustomMenu = ({
  filters,
  cluster,
  selectedFilters,
  setTableState,
  selectFilters,
  setSelectedSavedView,
  filtersOrder,
}: CustomMenuType): JSX.Element => {
  const [billedCostGreaterThan, setBilledCostGreaterThan] = useState<number | undefined>(undefined);
  const [networkingLessThanInMB, setNetworkingLessThanInMB] = useState<number | undefined>(undefined);

  const [topLevelCluster, setTopLevelCluster] = useState<string>('');
  const [changedCluster, setChangedCluster] = useState<boolean>();

  useEffect(() => {
    if (selectedFilters['billedCostGreaterThan']) {
      setBilledCostGreaterThan(+selectedFilters['billedCostGreaterThan'][0]?.value);
    } else {
      setBilledCostGreaterThan(undefined);
    }
    if (selectedFilters['networkingLessThanInMB']) {
      setNetworkingLessThanInMB(+selectedFilters['networkingLessThanInMB'][0]?.value);
    } else {
      setNetworkingLessThanInMB(undefined);
    }
    if (topLevelCluster !== cluster) {
      setTopLevelCluster(cluster);
      setChangedCluster(true);
    } else {
      setChangedCluster(false);
    }
    setTopLevelCluster(cluster);
  }, [selectedFilters, cluster, topLevelCluster, changedCluster]);

  const handleBilledCostBlur = () => {
    setSelectedSavedView(null);
    setTableState({ currentPage: 0 });
    if (!billedCostGreaterThan && selectedFilters['billedCostGreaterThan']) {
      delete selectedFilters['billedCostGreaterThan'];
      selectFilters(false, selectedFilters, true);
      return;
    }
    if (!billedCostGreaterThan) {
      return;
    }
    selectFilters(
      false,
      {
        billedCostGreaterThan: [
          {
            section: 'billedCostGreaterThan',
            value: String(billedCostGreaterThan),
            label: String(billedCostGreaterThan),
          },
        ],
      },
      false,
    );
  };

  const handleNetworkingLessThanBlur = () => {
    setSelectedSavedView(null);
    setTableState({ currentPage: 0 });
    if (!networkingLessThanInMB && selectedFilters['networkingLessThanInMB']) {
      delete selectedFilters['networkingLessThanInMB'];
      selectFilters(false, selectedFilters, true);
      return;
    }
    if (!networkingLessThanInMB) {
      return;
    }
    selectFilters(
      false,
      {
        networkingLessThanInMB: [
          {
            section: 'networkingLessThanInMB',
            value: String(networkingLessThanInMB),
            label: String(networkingLessThanInMB),
          },
        ],
      },
      false,
    );
  };

  const labelFilters = [workloadLabels, namespaceLabels, podLabels];
  const filtersToDisplay = Object.keys(filters)
    .filter((filter) => !filter.includes('Label'))
    .concat(labelFilters);

  const filtersSortedToDisplay = sortByOrder(filtersOrder, filtersToDisplay);
  const menu = filtersSortedToDisplay.map((filterSection) => {
    const allFilters =
      filters && hasKey(filters, filterSection)
        ? filters[filterSection].map((filter) => ({
            label: filter,
            value: filter,
            section: filterSection,
          }))
        : [];
    if (['cluster', 'namespace'].includes(filterSection) && allFilters.length > 0) {
      allFilters.unshift({
        value: strings.punctuation.asterisk,
        label: strings.general.selectAll,
        section: filterSection,
      });
    }

    const handleSelectOnChange = (
      newOption: MultiValue<OptionType>,
      { action, removedValue, option }: ActionMeta<OptionType>,
    ) => {
      setSelectedSavedView(null);
      setTableState({ currentPage: 0 });
      const typedRemovedValue = removedValue as CostsOptions;
      let finalFilters;
      if (removedValue && (action === 'remove-value' || action === 'pop-value')) {
        const index = selectedFilters[typedRemovedValue.section].findIndex(
          (filter) => filter.label === typedRemovedValue?.label && filter.section === typedRemovedValue.section,
        );
        (selectedFilters[typedRemovedValue.section] as CostsOptions[]).splice(index, 1);
        if (!selectedFilters[typedRemovedValue.section].length) {
          delete selectedFilters[typedRemovedValue.section];
        }
        finalFilters = selectedFilters;
      } else if (newOption.length && action === 'deselect-option') {
        const typedOption = newOption as CostsOptions[];
        if (option?.value === strings.punctuation.asterisk) {
          finalFilters = { [typedOption[0].section]: [] };
        } else {
          finalFilters = { [typedOption[0].section]: newOption };
        }
      } else if (!newOption.length && !removedValue && action === 'deselect-option') {
        selectedFilters[filterSection].pop();
        finalFilters = selectedFilters;
      } else if (newOption[0].value === strings.punctuation.asterisk) {
        const typedOption = newOption as CostsOptions[];
        finalFilters = {
          [typedOption[0].section]: allFilters,
        };
      } else {
        const typedOption = newOption as CostsOptions[];
        finalFilters = { [typedOption[0].section]: typedOption };
      }
      selectFilters(false, finalFilters, Boolean(removedValue));
    };

    let wildcardOptions: any[] | undefined = [];
    if (allowWildcard.includes(filterSection)) {
      wildcardOptions = allFilters.filter((filter) => filter.section === filterSection).map((filter) => filter.value);
    }

    const onTagAdded = (value: string, field: string, section: string) => {
      if (!value || !field || !section) {
        return;
      }
      selectedFilters[field] = [...(selectedFilters[field] || []), { section: field, value: value, label: section }];
      selectFilters(false, selectedFilters, false);
    };

    const onTagRemoved = (value: string, field: string, section: string) => {
      if (!value || !field || !section) {
        return;
      }
      const index = selectedFilters[field].findIndex((filter) => filter.section === field);
      (selectedFilters[field] as CostsOptions[]).splice(index, 1);
      if (!selectedFilters[field].length) delete selectedFilters[field];
      const finalFilters = selectedFilters;
      selectFilters(false, finalFilters, true);
    };

    return (
      <div className="filter-section__section" key={filterSection}>
        <h1 className="filter-section__title">{filterSection}</h1>
        {!allowWildcard.includes(filterSection) ? (
          <Select
            key={filterSection}
            id={filterSection}
            className={`custom--select_filtering custom--select_filtering-${filterSection}`}
            classNamePrefix="custom--select_filtering"
            options={allFilters}
            components={{ Option, MenuList, MultiValueContainer }}
            closeMenuOnSelect={false}
            isMulti
            hideSelectedOptions={false}
            isClearable={false}
            filterOption={createFilter({ ignoreAccents: false })}
            controlShouldRenderValue={true}
            value={selectedFilters[filterSection] || []}
            onChange={handleSelectOnChange}
            placeholder={strings.general.Search}
            styles={customFilterStyles}
          />
        ) : (
          <InputTag
            inputContainerClassName={
              filterSection === 'clusters' && !!cluster
                ? 'custom--text_filtering input_disabled'
                : 'custom--text_filtering'
            }
            onTagAdded={onTagAdded}
            onTagRemoved={onTagRemoved}
            field={filterSection}
            section={filterSection}
            dataCy={`${filterSection}-input-tag-${filterSection}`}
            suggestedData={wildcardOptions}
            defaultTagValues={
              filterSection === 'clusters' && !!cluster
                ? [cluster]
                : (selectedFilters[filterSection] || []).map((filter) => filter?.value)
            }
            resetDefaultValues={!!changedCluster}
            reloadTags={true}
            openOptionsOnClick={true}
            resetTags={selectedFilters[filterSection] ? selectedFilters[filterSection].length === 0 : true}
            isDisabled={filterSection === 'clusters' && !!cluster}
          />
        )}
      </div>
    );
  });

  menu.push(
    <BilledCostItem
      onBlur={handleBilledCostBlur}
      onChange={(e) => {
        setBilledCostGreaterThan(e.target.value);
      }}
      value={billedCostGreaterThan ? billedCostGreaterThan : ''}
    />,
  );

  menu.push(
    <NetworkingLessThanItem
      onBlur={handleNetworkingLessThanBlur}
      onChange={(e) => {
        setNetworkingLessThanInMB(e.target.value);
      }}
      value={networkingLessThanInMB ? networkingLessThanInMB : ''}
    />,
  );

  return (
    <>
      <div className="filters-title">{strings.efficiency.advancedFilters}</div>
      <Dropdown.Divider />
      <div className="filter-section">{menu}</div>
    </>
  );
};

// react-select styles
export const customStyles: StylesConfig<OptionType, true> = {
  control: (provided) => ({
    ...provided,
    width: strings.noTranslate.eighteenREM,
    minHeight: '2rem',
    maxHeight: '4rem',
    height: '2rem',
    backgroundColor: strings.noTranslate.transparent,
    border: `${COLORS.BORDER.SOLID_TABLE}`,
    color: COLORS.CORE.DARK_GRAY,
    overflow: 'hidden',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    marginBottom: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.costsPadding,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: strings.noTranslate.smallBottomPadding,
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: strings.noTranslate.eighteenREM,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
  group: (provided) => ({
    ...provided,
    height: '15rem',
    overflow: 'scroll',
    marginRight: '0.25rem',
  }),
};

export const customFilterStyles: StylesConfig<OptionType, true> = {
  control: (provided) => ({
    ...provided,
    minHeight: '1.5rem',
    maxHeight: '2.5rem',
  }),
  valueContainer: (provided) => ({
    ...provided,
    alignItems: 'flex-start',
    maxHeight: '2.25rem',
    overflow: 'auto',
    padding: '4px 8px 0px 8px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: COLORS.CORE.MULTIVALUE_BACKGROUND,
    color: COLORS.CORE.PRIMARY,
    alignContent: 'center',
  }),
};
