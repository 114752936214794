import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, usePagination } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';

import { AddButton, Card } from '@fairwindsops/ui-components';

import Tabs from '~reactComponents/Tabs/Tabs.react';
import Table from '~reactComponents/Table/Table.react';
import Pagination from '~reactComponents/pagination/pagination.react';

import {
  IRouter,
  IStore,
  OptionType,
  TabsData,
  Team,
  TeamMembership,
} from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import { getAccountTableColumns } from './AccountsTable.config.react';

import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';

import './AccountsTable.react.scss';

type AccountsTableProps = {
  router: () => IRouter;
  store: () => IStore;
};

const AccountsTable = ({ router, store }: AccountsTableProps) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [memberships, setMemberships] = useState<TeamMembership[]>([]);
  const [filteredMemberships, setFilteredMemberships] = useState<TeamMembership[]>([]);
  const [tabsData, setTabsData] = useState<TabsData[]>([
    {
      id: '1',
      isActive: true,
      label: strings.teamManagement.all,
      onClick: (item: TabsData) => onTabClicked(item, 'all'),
    },
    {
      id: '2',
      isActive: false,
      label: strings.teamManagement.owners,
      onClick: (item: TabsData) => onTabClicked(item, 'isOwner'),
    },
    {
      id: '3',
      isActive: false,
      label: strings.teamManagement.standard,
      onClick: (item: TabsData) => onTabClicked(item, 'standard'),
    },
    {
      id: '4',
      isActive: false,
      label: strings.teamManagement.pending,
      onClick: (item: TabsData) => onTabClicked(item, 'pending'),
    },
  ]);
  const [filterCriteria, setFilterCriteria] = useState<string>('all');
  const [searchKeywords, setSearchKeywords] = useState<string>('');
  const [isConfirmDeleteModalShown, setIsConfirmDeleteModalShown] = useState<boolean>(false);
  const [emailToDelete, setEmailToDelete] = useState<string>('');
  const [isForcedRefresh, setIsForcedRefresh] = useState<boolean>(false);
  const [isConfirmOwnerModalShown, setIsConfirmOwnerModalShown] = useState<boolean>(false);
  const [membershipForChangingOwner, setMembershipForChangingOwner] =
    useState<TeamMembership | null>(null);
  const [page, setPage] = useState<number>(1);

  const organization = store().getters.organization;
  const user = store().getters.user;
  const isOrgOwner = store().getters.isOrgOwner;

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const getTeams = async () => {
    try {
      setTeams((await sendRequest('GET', `${baseUrl}/teams`, {}, null)) || []);
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  const getMemberShips = async () => {
    try {
      const memberships = await sendRequest('GET', `${baseUrl}/memberships`, {}, null);
      setMemberships(memberships || []);
      setFilteredMemberships(memberships || []);
    } catch (e) {
      logger.logError('error_getting_teams_team-management', e);
    }
  };

  useEffect(() => {
    getTeams();
    getMemberShips();
  }, []);

  const countNumberOfAccounts = () => memberships?.length || 0;

  const countNumberOfOwnerAccounts = () =>
    memberships?.filter((membership) => membership.IsOwner)?.length || 0;

  const countNumberOfStandardAccounts = () =>
    memberships?.filter(
      (membership) => !membership.IsOwner && membership.FirstName && membership.LastName,
    )?.length || 0;

  const countNumberOfPendingAccounts = () =>
    memberships?.filter((membership) => !membership.FirstName && !membership.LastName)?.length || 0;

  const updateTabsDataLabels = () => {
    tabsData[0].label = `${strings.teamManagement.all} (${countNumberOfAccounts()})`;
    tabsData[1].label = `${strings.teamManagement.owners} (${countNumberOfOwnerAccounts()})`;
    tabsData[2].label = `${strings.teamManagement.standard} (${countNumberOfStandardAccounts()})`;
    tabsData[3].label = `${strings.teamManagement.pending} (${countNumberOfPendingAccounts()})`;

    setTabsData([...tabsData]);
  };

  useEffect(() => {
    updateTabsDataLabels();
  }, [memberships]);

  const onInviteUserClicked = () => {
    router().push({
      name: 'invite-users',
      params: {
        teams: teams.map((team) => ({
          value: team.ID,
          text: team.Name,
        })),
        memberEmails: memberships.map((membership) => membership.Email),
      },
    });
  };

  const hasMatchedKeywords = (membership: TeamMembership) => {
    if (!searchKeywords) {
      return true;
    }

    if (!membership) {
      return false;
    }

    const lowercaseSearchKeywords = searchKeywords.toLowerCase();

    return (
      membership.FirstName.toLowerCase().includes(lowercaseSearchKeywords) ||
      membership.LastName.toLowerCase().includes(lowercaseSearchKeywords) ||
      membership.Email.toLowerCase().includes(lowercaseSearchKeywords)
    );
  };

  const filterMemberships = () => {
    let finalFilteredMemberships = [];

    switch (filterCriteria) {
      case 'all':
        finalFilteredMemberships = memberships;
        break;
      case 'isOwner':
        finalFilteredMemberships = memberships.filter((membership) => !!membership.IsOwner);
        break;
      case 'standard':
        finalFilteredMemberships = memberships.filter(
          (membership) => !membership.IsOwner && membership.FirstName && membership.LastName,
        );
        break;
      case 'pending':
        finalFilteredMemberships = memberships.filter(
          (membership) => !membership.FirstName && !membership.LastName,
        );
        break;
      default:
        finalFilteredMemberships = memberships;
        break;
    }

    if (searchKeywords) {
      finalFilteredMemberships = finalFilteredMemberships.filter((membership: TeamMembership) =>
        hasMatchedKeywords(membership),
      );
    }

    setFilteredMemberships(finalFilteredMemberships);
  };

  useEffect(() => {
    filterMemberships();
  }, [filterCriteria, searchKeywords]);

  useEffect(() => {
    if (isForcedRefresh) {
      filterMemberships();
      setIsForcedRefresh(false);
    }
  }, [isForcedRefresh]);

  const onTabClicked = (item: TabsData, filterCriteria: string) => {
    if (!item) {
      return;
    }

    setFilterCriteria(filterCriteria);

    setTabsData((prevTabsData) =>
      prevTabsData.map((tab) =>
        tab.id === item.id ? { ...tab, isActive: true } : { ...tab, isActive: false },
      ),
    );
  };

  const onMembersSearched = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeywords(e.target.value);
  };

  const onMembershipRemoved = (email: string) => {
    if (!email) {
      return;
    }

    setIsConfirmDeleteModalShown(true);
    setEmailToDelete(email);
  };

  const onMembershipOwnerChanged = (membership: TeamMembership) => {
    if (!membership) {
      return;
    }

    setMembershipForChangingOwner(membership);
    setIsConfirmOwnerModalShown(true);
  };

  const onAccountTeamRemoved = async (email: string, teamId: string) => {
    if (!email || !teamId) {
      return;
    }

    try {
      await sendRequest(
        'DELETE',
        `${baseUrl}/teams/${teamId}/memberships`,
        {
          data: {
            UserID: email,
            TeamID: +teamId,
          },
        },
        null,
      );

      await getTeams();
      await getMemberShips();

      setIsForcedRefresh(true);

      toast.success(strings.teamManagement.removeAccountTeamSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.removeAccountTeamFailed);
      logger.logError('error_removing_team_from_an_account', e);
    }
  };

  const onAccountTeamAdded = async (team: Team, email: string, role = 'viewer') => {
    if (!team || !email) {
      return;
    }

    try {
      await sendRequest(
        'PATCH',
        `${baseUrl}/teams/${team.ID}/memberships`,
        {
          data: {
            UserID: email,
            Role: role,
            TeamID: team.ID,
          },
        },
        null,
      );

      await getTeams();
      await getMemberShips();

      setIsForcedRefresh(true);

      toast.success(strings.teamManagement.addAccountTeamSuccess);
    } catch (e) {
      toast.error(strings.teamManagement.addAccountTeamFailed);
      logger.logError('error_adding_a_team_to_an_account', e);
    }
  };

  const columns = useMemo(
    () =>
      getAccountTableColumns({
        onMembershipRemoved,
        onMembershipOwnerChanged,
        onAccountTeamRemoved,
        onAccountTeamAdded,
        user,
        isOrgOwner,
        teams,
      }),
    [teams, filteredMemberships],
  );

  const getTeamById = (id: number) => (id ? teams?.find((team) => team.ID === id) : null);

  const transformTeamsDataForData = (teamIds: number[]) => {
    if (!teamIds?.length) {
      return [];
    }

    const transformedTeamsData = [] as OptionType[];

    for (const id of teamIds) {
      const selectedTeam = getTeamById(id);

      if (!selectedTeam) {
        continue;
      }

      transformedTeamsData.push({
        label: selectedTeam.Name,
        value: String(selectedTeam.ID),
      });
    }

    return transformedTeamsData;
  };

  const buildTableRowData = (membership: TeamMembership) =>
    membership
      ? {
          IsOwner: membership.IsOwner,
          Email: membership.Email,
          Name:
            membership.FirstName && membership.LastName
              ? `${membership.FirstName} ${membership.LastName}`
              : strings.teamManagement.pendingUser,
          Teams: membership.Teams?.length ? transformTeamsDataForData(membership.Teams) : [],
          LastLogin: membership.LastLogin,
        }
      : {};

  const transformMembershipsForTable = () => {
    if (!filteredMemberships?.length) {
      return [];
    }

    return filteredMemberships.map((membership: TeamMembership) => buildTableRowData(membership));
  };

  const data = useMemo(() => transformMembershipsForTable(), [teams, filteredMemberships]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: page ? page - 1 : 0,
        pageSize: 5,
      },
    },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
    usePagination,
  );

  const totalPage = useMemo(
    () => Math.ceil(data.length / tableInstance.state.pageSize),
    [data, tableInstance.state.pageIndex],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
      setPage(page + 1);
    }
  };

  const onRemoveAccountConfirmed = async () => {
    if (!emailToDelete) {
      return;
    }

    try {
      await sendRequest(
        'DELETE',
        `${baseUrl}/memberships`,
        {
          data: { Email: emailToDelete },
        },
        null,
      );

      setEmailToDelete('');
      setIsConfirmDeleteModalShown(false);

      await getTeams();
      await getMemberShips();

      setIsForcedRefresh(true);

      toast.success(strings.teamManagement.removeAccountSuccess);
    } catch (e) {
      logger.logError('error_remove_membership', e);
      toast.error(strings.teamManagement.removeAccountFailed);
    }
  };

  const getConfirmModalContent = () => {
    if (isConfirmDeleteModalShown) {
      return strings.teamManagement.confirmAccountRemovalContent
        .replace('$organization', organization.Name)
        .replace('$email', emailToDelete);
    }

    if (isConfirmOwnerModalShown && membershipForChangingOwner) {
      return membershipForChangingOwner.IsOwner
        ? strings.teamManagement.confirmOwnershipChangeContent2
            .replace('$email', membershipForChangingOwner.Email)
            .replace('$organization', organization.Name)
        : strings.teamManagement.confirmOwnershipChangeContent1
            .replace('$email', membershipForChangingOwner.Email)
            .replace('$organization', organization.Name);
    }

    return '';
  };

  const onOwnershipChangeConfirmed = async () => {
    if (!membershipForChangingOwner) {
      return;
    }

    const selectedMembership = memberships.find(
      (membership) => membership.Email === membershipForChangingOwner.Email,
    );

    if (!selectedMembership) {
      return;
    }

    selectedMembership.IsOwner = !selectedMembership.IsOwner;
    try {
      await sendRequest(
        'PATCH',
        `${baseUrl}/memberships`,
        {
          data: {
            ...selectedMembership,
          },
        },
        null,
      );

      setIsConfirmOwnerModalShown(false);
      setMembershipForChangingOwner(null);

      await getTeams();
      await getMemberShips();

      setIsForcedRefresh(true);
    } catch (e) {
      logger.logError('error_change_ownership_membership', e);
      toast.error(strings.teamManagement.removeAccountFailed);
    }
  };

  return (
    <>
      <Card className="accounts-table">
        <Card.Title className="accounts-table__title">
          {strings.teamManagement.allAccounts}
        </Card.Title>
        <Card.Body>
          <div className="accounts-table__body">
            {isOrgOwner ? (
              <AddButton
                action={onInviteUserClicked}
                buttonText={`${strings.teamManagement.inviteUsers}`}
                class="accounts-table__add-button"
                testLabel="invite-button"
              />
            ) : null}
            <div className="accounts-table__body-header">
              <Tabs data={tabsData} data-cy="all-accounts-tabs" />
              <div className="accounts-table__search-container">
                <Form.Control
                  className="accounts-table__search"
                  data-cy="all-accounts-search-input"
                  id="team-management-account-search"
                  onChange={(e) => onMembersSearched(e as ChangeEvent<HTMLInputElement>)}
                  placeholder={strings.general.Search}
                  type="search"
                />
              </div>
            </div>
            <div className="accounts-table__body-table">
              <Table
                aria-hidden="true"
                dataCy="all-accounts-table"
                tableInstance={tableInstance}
                caption={strings.teamManagement.accountsTable}
                pagination={
                  <Pagination
                    currentPage={tableInstance.state.pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={totalPage}
                  />
                }
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.general.Cancel}
        confirmButtonText={strings.general.confirm}
        isModalShown={isConfirmDeleteModalShown || isConfirmOwnerModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={getConfirmModalContent()}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={
          isConfirmDeleteModalShown
            ? strings.teamManagement.confirmAccountRemovalTitle
            : strings.teamManagement.confirmOwnershipChangeTitle
        }
        onConfirmClicked={
          isConfirmDeleteModalShown ? onRemoveAccountConfirmed : onOwnershipChangeConfirmed
        }
        onModalHidden={(isModalShown: boolean | undefined) => {
          if (isConfirmDeleteModalShown) {
            setIsConfirmDeleteModalShown(!!isModalShown);
            setEmailToDelete('');
            return;
          }
          if (isConfirmOwnerModalShown) {
            setIsConfirmOwnerModalShown(!!isModalShown);
            setMembershipForChangingOwner(null);
            return;
          }
        }}
      />
      <Toaster />
    </>
  );
};

export default AccountsTable;
