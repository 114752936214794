import React from 'react';
import Tag from '~reactComponents/Tags/Tag.react';
import { formatCost, formatPercentage } from '../../Costs.helpers.react';
import { strings } from '~utils/strings';
import prettyBytes from 'pretty-bytes';

export const TABLE_COLUMNS = (currentAggregators: string[]) => [
  {
    Header: strings.general.Cluster,
    accessor: 'cluster',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    isVisible: currentAggregators.includes('cluster'),
    minWidth: 150,
  },
  {
    Header: strings.efficiency.Workload,
    accessor: 'workload',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 150,
    isVisible: currentAggregators.includes('workload'),
  },
  {
    Header: strings.efficiency.Kind,
    accessor: 'kind',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 125,
    isVisible: currentAggregators.includes('kind'),
  },
  {
    Header: strings.efficiency.Pod,
    accessor: 'pod',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 150,
    isVisible: currentAggregators.includes('pod'),
  },
  {
    Header: strings.efficiency.Container,
    accessor: 'container',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 150,
    isVisible: currentAggregators.includes('container'),
  },
  {
    Header: strings.efficiency.Namespace,
    accessor: 'namespace',
    className: 'aggregator-column',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 150,
    isVisible: currentAggregators.includes('namespace'),
  },
  {
    Header: strings.efficiency.workloadLabels,
    accessor: 'workloadLabels',
    className: 'no-overflow',
    disableSortBy: true,
    Cell: ({ value }: { value: Record<string, string>[] }) => {
      const title = (value || []).map((label) => `${label.name}=${label.value}`).join(', ');
      const display = (value || []).map((label) => (
        <span className="aggregator-labels-spacing">
          <Tag isGray={true}>{`${label.name}=${label.value}`}</Tag>
        </span>
      ));
      return <span title={title}>{display}</span>;
    },
    minWidth: 200,
    isVisible: currentAggregators.includes('workloadLabels'),
  },
  {
    Header: strings.efficiency.namespaceLabels,
    accessor: 'namespaceLabels',
    className: 'no-overflow',
    disableSortBy: true,
    Cell: ({ value }: { value: Record<string, string>[] }) => {
      const title = (value || []).map((label) => `${label.name}=${label.value}`).join(', ');
      const display = (value || []).map((label) => (
        <span className="aggregator-labels-spacing">
          <Tag isGray={true}>{`${label.name}=${label.value}`}</Tag>
        </span>
      ));
      return <span title={title}>{display}</span>;
    },
    minWidth: 200,
    isVisible: currentAggregators.includes('namespaceLabels'),
  },
  {
    Header: strings.efficiency.podLabels,
    accessor: 'podLabels',
    className: 'no-overflow',
    disableSortBy: true,
    Cell: ({ value }: { value: Record<string, string>[] }) => {
      const title = (value || []).map((label) => `${label.name}=${label.value}`).join(', ');
      const display = (value || []).map((label) => (
        <span className="aggregator-labels-spacing">
          <Tag isGray={true}>{`${label.name}=${label.value}`}</Tag>
        </span>
      ));
      return <span title={title}>{display}</span>;
    },
    minWidth: 200,
    isVisible: currentAggregators.includes('podLabels'),
  },
  {
    Header: strings.efficiency.avgPods,
    accessor: 'resources.averagePodCount',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => (
      <span title={value > 0 ? value.toFixed(2) : '0'}>{value > 0 ? value.toFixed(2) : '0'}</span>
    ),
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.memoryBilled,
    accessor: 'resources.billed.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.cpuBilled,
    accessor: 'resources.billed.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.networkReceived,
    accessor: 'resources.networkReceiveBytes',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>
        {value ? prettyBytes(parseInt(value), { space: false }) : 'Unavailable'}
      </span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.networkReceivedCost,
    accessor: 'costs.actual.networkReceive',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },

  {
    Header: strings.efficiency.networkSent,
    accessor: 'resources.networkTransmitBytes',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>
        {value ? prettyBytes(parseInt(value), { space: false }) : 'Unavailable'}
      </span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.networkSentCost,
    accessor: 'costs.actual.networkTransmit',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },

  {
    Header: 'Disk',
    accessor: 'resources.storageCapacityBytes',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>
        {value ? prettyBytes(parseInt(value), { space: false }) : 'Unavailable'}
      </span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: 'Disk Cost',
    accessor: 'costs.actual.storageCapacity',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.billedCost,
    accessor: 'costs.actual.total',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.recommendedCost,
    accessor: 'costs.recommendation.total',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.costDifference,
    accessor: 'costs.difference.total',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.zero$;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.costEfficiency,
    accessor: 'costs.costScore',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedValue = value ? formatPercentage(value) : strings.noCostData;
      return <span title={formattedValue}>{formattedValue}</span>;
    },
    minWidth: 125,
    isVisible: true,
  },
  {
    Header: strings.efficiency.memoryLimits,
    accessor: 'resources.settings.limits.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryRecommendedLimits,
    accessor: 'resources.recommendation.limits.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 175,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryRequests,
    accessor: 'resources.settings.requests.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 135,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryRecommendedRequests,
    accessor: 'resources.recommendation.requests.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 150,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryAvgUsage,
    accessor: 'resources.averageUsage.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 135,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryBilledCost,
    accessor: 'costs.actual.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryRecommendedCost,
    accessor: 'costs.recommendation.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.memoryCostDifference,
    accessor: 'costs.difference.memory',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuLimits,
    accessor: 'resources.settings.limits.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuRecommendedLimits,
    accessor: 'resources.recommendation.limits.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 135,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuRequests,
    accessor: 'resources.settings.requests.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuRecommendedRequests,
    accessor: 'resources.recommendation.requests.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 150,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuAvgUsage,
    accessor: 'resources.averageUsage.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: string }) => (
      <span title={value ? value : 'Unavailable'}>{value ? value : 'Unavailable'}</span>
    ),
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuBilledCost,
    accessor: 'costs.actual.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuRecommendedCost,
    accessor: 'costs.recommendation.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
  {
    Header: strings.efficiency.cpuCostDifference,
    accessor: 'costs.difference.cpu',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      const formattedCost = value ? formatCost(value) : strings.noCostData;
      return <span title={formattedCost}>{formattedCost}</span>;
    },
    minWidth: 125,
    isVisible: false,
  },
];

export const COLUMNS_ORDER = [
  'cluster',
  'namespace',
  'kind',
  'workload',
  'pod',
  'container',
  'workloadLabels',
  'namespaceLabels',
  'podLabels',
  'resources.averagePodCount',
  'resources.billed.memory',
  'resources.billed.cpu',

  'resources.networkReceiveBytes',
  'costs.actual.networkReceive',
  'resources.networkTransmitBytes',
  'costs.actual.networkTransmit',
  'resources.storageCapacityBytes',
  'costs.actual.storageCapacity',

  'costs.actual.total',
  'costs.recommendation.total',
  'costs.difference.total',
  'resources.settings.limits.memory',
  'resources.recommendation.limits.memory',
  'resources.settings.requests.memory',
  'resources.recommendation.requests.memory',
  'resources.averageUsage.memory',
  'costs.actual.memory',
  'costs.recommendation.memory',
  'costs.difference.memory',
  'resources.settings.limits.cpu',
  'resources.recommendation.limits.cpu',
  'resources.settings.requests.cpu',
  'resources.recommendation.requests.cpu',
  'resources.averageUsage.cpu',
  'costs.actual.cpu',
  'costs.recommendation.cpu',
  'costs.difference.cpu',
  'costs.costScore',
];

export const neverHideColumns = [
  'cluster',
  'workload',
  'kind',
  'pod',
  'container',
  'namespace',
  'workloadLabels',
  'namespaceLabels',
  'podLabels',
];

export const timeSeriesColumns = [
  'totalCost',
  'memoryRecommendedLimits',
  'memoryRecommendedRequests',
  'cpuRecommendedLimits',
  'cpuRecommendedRequests',
  'memoryUsage',
  'cpuUsage',
  'minMemoryUsage',
  'maxMemoryUsage',
  'minCPUUsage',
  'maxCPUUsage',
];

export const sortByOptions = {
  cluster: 'cluster',
  pod: 'pod',
  workload: 'workload',
  kind: 'kind',
  namespace: 'namespace',
  container: 'container',
  resources_averagePodCount: 'podsCountAverage',
  costs_recommendation_total: 'recommendedCost',
  costs_actual_total: 'totalCost',
  costs_actual_cpu: strings.noTranslate.cpuCost,
  costs_recommendation_cpu: 'cpuRecommendedCost',
  costs_actual_memory: strings.noTranslate.memoryCost,
  costs_recommendation_memory: 'memoryRecommendedCost',
  resources_billed_memory: 'memoryBilled',
  resources_billed_cpu: 'cpuBilled',
  resources_settings_limits_memory: 'memoryLimit',
  resources_recommendation_limits_memory: 'memoryRecommendedLimits',
  resources_settings_requests_memory: 'memoryRequest',
  resources_recommendation_requests_memory: 'memoryRecommendedRequests',
  resources_averageUsage_memory: 'memoryUsage',
  resources_settings_limits_cpu: 'cpuLimit',
  resources_recommendation_limits_cpu: 'cpuRecommendedLimits',
  resources_settings_requests_cpu: 'cpuRequest',
  resources_recommendation_requests_cpu: 'cpuRecommendedRequests',
  resources_averageUsage_cpu: 'cpuUsage',
  costs_difference_total: 'totalCostDifference',
  costs_difference_cpu: 'cpuCostDifference',
  costs_difference_memory: 'memoryCostDifference',
  resources_networkReceiveBytes: 'networkReceiveBytes',
  costs_actual_networkReceive: strings.noTranslate.networkReceiveCost,
  resources_networkTransmitBytes: 'networkTransmitBytes',
  costs_actual_networkTransmit: strings.noTranslate.networkTransmitCost,
  resources_storageCapacityBytes: 'storageCapacityBytes',
  costs_actual_storageCapacity: strings.noTranslate.storageCapacityCost,
  costs_costScore: 'costScore',
};
