import { OptionType } from 'dayjs';
import React from 'react';
import { MultiValueProps, GroupBase } from 'react-select';
import { strings } from '~utils/strings';

export const MultiValue = ({
  children,
  getValue,
  index,
}: MultiValueProps<OptionType, true, GroupBase<OptionType>>) => {
  if (getValue().length && !index) {
    if (getValue().length <= 1) {
      return (
        <div>{`${children?.slice(0, 20)}${
          children?.length > 20 ? strings.punctuation.ellipsis : ''
        }`}</div>
      );
    } else {
      const length = getValue().length - 1;
      return (
        <>
          <div>{children?.slice(0, 9)}</div>
          <div>{`(+${length} ${strings.general.others})`}</div>
        </>
      );
    }
  }
  return null;
};
