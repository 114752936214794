// eslint-disable-next-line
import AutomationLogs from './AutomationLogs.react.tsx';

export default {
  components: {
    'automation-logs': AutomationLogs,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
