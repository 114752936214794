import React, { useState, useEffect, useRef } from 'react';
import { Card, Table } from 'react-bootstrap';
import ActionItem from '../ActionItemInfo/ActionItem.react';
import {
  Column,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  useTable,
  FilterTypes,
  TableInstance,
  useFlexLayout,
} from 'react-table';
import SelectColumnFilter from '../../../../organization/actionItems/components/SelectColumnFilter/SelectColumnFilter.react';
import { getSeverityLabel } from '~utils/helpers';
import './DescriptionPanel.react.scss';
import { AdmissionRequestType, AdmissionActionItemType } from '../../ReactAdmission.types.react';
import { IRoute, IRouter, IStore, OptionType } from '~globalTypes';
import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';
import { TABLE_COLUMNS } from './DescriptionPanel.config.react';
import logger from '~logger';
import { ADMISSION_ACTION_ITEM_RESOLUTIONS } from '~utils/constants';

type DescriptionPanelProps = {
  request: AdmissionRequestType | null;
  caption: string;
  router: () => IRouter;
  route: IRoute;
  store: () => IStore;
  reloadAdmissionRequest: (request: AdmissionRequestType) => Promise<AdmissionRequestType | undefined>;
};

type Filter = {
  id: string;
  value: OptionType[];
};

const DescriptionPanel = ({
  request,
  caption,
  router,
  route,
  store,
  reloadAdmissionRequest,
}: DescriptionPanelProps): JSX.Element => {
  const FILTERS_DEFAULT = {
    Severity: ['critical', 'high', 'medium', 'low', 'none'],
    HistoricalResolution: ADMISSION_ACTION_ITEM_RESOLUTIONS.map((resolution) => resolution.label),
    Category: [strings.Efficiency, strings.Reliability, strings.Security],
    ReportType: [''],
    Title: [''],
  };

  const [actionItem, setActionItem] = useState<AdmissionActionItemType | null>(null);
  const [columnFilterOpen, setColumnFilterOpen] = useState<string>('');
  const [resultsToDisplay, setResultsToDisplay] = useState<AdmissionActionItemType[]>(() => request?.Data || []);
  const actionItemFilters = useRef(FILTERS_DEFAULT);

  useEffect(() => {
    if (request?.Data?.length === 1 && !actionItem) {
      setActionItem(request.Data[0]);
    } else if (!request?.Data?.length || (request?.Data?.length === 1 && actionItem)) {
      setActionItem(null);
    } else if (request?.Data?.length > 1) {
      const { filters } = tableInstance.state;
      setActionItem(null);
      if (!filters.length) {
        setResultsToDisplay(request.Data);
      }
      const reportType: string[] = [];
      const titles: string[] = [];
      const resolutions: string[] = [];
      request.Data.forEach((actionItem) => {
        if (!reportType.includes(actionItem.ReportType)) {
          reportType.push(actionItem.ReportType);
        }
        if (!titles.includes(actionItem.Title)) {
          titles.push(actionItem.Title);
        }
        const resolution = ADMISSION_ACTION_ITEM_RESOLUTIONS.find(
          (element) => element.value === actionItem.HistoricalResolution,
        );
        if (!resolutions.includes(resolution?.label)) {
          resolutions.push(resolution?.label);
        }
      });
      actionItemFilters.current = {
        ...actionItemFilters.current,
        ReportType: reportType,
        Title: titles,
        HistoricalResolution: resolutions,
      };
    }
  }, [request?.ID, resultsToDisplay]);

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter
      tableInstance={tableInstance}
      filters={actionItemFilters.current}
      isColumnFilterOpen={columnFilterOpen}
      setIsColumnFilterOpen={setColumnFilterOpen}
    />
  );

  const filterTypes = React.useMemo<FilterTypes<AdmissionActionItemType>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const filterActionItems = () => {
    const { filters } = tableInstance.state;
    const activeFilters: Filter[] = filters.filter((filter) => filter.value.length);
    if (activeFilters.length > 0 && request?.Data) {
      const results: AdmissionActionItemType[] = [];
      request?.Data.forEach((actionItem) => {
        let resolutionAlreadyMatched = false;
        let matchesAllFilters = true;
        activeFilters.forEach((filter) => {
          let matchesSomeValue = false;
          filter?.value.forEach((filterValue: { label: string; value: string }) => {
            const filterKey: string = hasKey(actionItem, filter?.id) ? filter?.id : '';
            const checkResults = results.find((result) => result.EventType === actionItem?.EventType);
            if (hasKey(actionItem, filterKey)) {
              if (!checkResults && filter?.id === 'Severity') {
                const severity = getSeverityLabel(actionItem?.Severity);
                if (severity === filterValue.value) {
                  matchesSomeValue = true;
                }
              } else if (filter?.id === strings.HistoricalResolution) {
                if (resolutionAlreadyMatched) {
                  matchesSomeValue = true;
                } else {
                  const filterLabel = ADMISSION_ACTION_ITEM_RESOLUTIONS.find(
                    (element) => element.label.toLowerCase() == filterValue.value.toLowerCase(),
                  );
                  matchesSomeValue = filterLabel?.value === actionItem?.HistoricalResolution;
                  if (matchesSomeValue) {
                    resolutionAlreadyMatched = true;
                  }
                }
              } else if (actionItem && !checkResults && filterKey && filterValue.value === actionItem[filterKey]) {
                matchesSomeValue = true;
              }
              logger.logEvent(`admission:table-column-filter`, {
                dataFromFilter: matchesSomeValue,
                filterName: filterValue,
              });
            }
          });
          if (!matchesSomeValue) {
            matchesAllFilters = false;
          }
        });
        if (matchesAllFilters) {
          results.push(actionItem);
        }
      });
      if (results.length > 0) {
        setResultsToDisplay(results);
      } else if (!activeFilters.length && request?.Data) {
        setResultsToDisplay(request?.Data);
      } else {
        setResultsToDisplay([]);
      }
    } else {
      request?.Data ? setResultsToDisplay(request?.Data) : [];
    }
  };

  const fetchActionItemsDebounced = useAsyncDebounce(filterActionItems, 250);

  const data = React.useMemo(() => resultsToDisplay, [resultsToDisplay]);
  const columns = React.useMemo<Column<AdmissionActionItemType>[]>(
    () => TABLE_COLUMNS(ColumnFiltering),
    [request, actionItemFilters.current, columnFilterOpen],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        filters: [],
      },
      manualFilters: true,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { filters },
  } = tableInstance;

  useEffect(() => {
    fetchActionItemsDebounced();
  }, [filters]);

  const resetActionItem = () => {
    if (request?.Data?.length === 1) {
      setResultsToDisplay(request.Data);
      if (resultsToDisplay.length === 1) {
        setActionItem(null);
      }
    } else {
      setActionItem(null);
    }
    setResultsToDisplay(request?.Data || []);
  };

  const handleSelectActionItem = (original: AdmissionActionItemType) => {
    setActionItem(original);
    logger.logEvent(`admission-table:selected ${original?.Category} category item`);
  };

  const reloadActionItem = (original: AdmissionActionItemType) => {
    if (request) {
      const response = reloadAdmissionRequest(request);
      response.then((resp) => {
        if (resp?.Data) {
          const requestItem = resp?.Data.find((item) => item.ID === original.ID);
          if (requestItem) {
            setActionItem(requestItem);
          }
        }
      });
    }
  };

  return (
    <Card
      className={!actionItem && request?.Data?.length ? 'admission-right-panel__table' : 'admission-right-panel'}
      data-cy="admission-action-items-card"
    >
      {request && (
        <>
          {request?.Data?.length === 0 && (
            <Card.Body>
              <Card.Title className="admission-right-no-info">{strings.admissionController.noActionItems}</Card.Title>
            </Card.Body>
          )}
          {actionItem && (
            <ActionItem
              item={actionItem}
              date={request.CreatedAt}
              closePanel={resetActionItem}
              router={router}
              route={route}
              store={store}
              request={request}
              reloadActionItem={reloadActionItem}
            />
          )}
          {request.Data && request?.Data?.length >= 1 && !actionItem && (
            <Table
              {...getTableProps()}
              className="admission-table"
              aria-label={caption}
              data-cy="admission-action-items-table"
            >
              <thead className="admission-table-head" role={strings.ariaRoles.rowgroup}>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()} role="row">
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} role="presentation">
                        <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <span>{column.render('Header')}</span>
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="fas fa-arrow-down" />
                              ) : (
                                <i className="fas fa-arrow-up" />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                        <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="admission-table-body">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => handleSelectActionItem(row.original)}
                      className="admission-table-row"
                    >
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </>
      )}
    </Card>
  );
};

export default DescriptionPanel;
