export interface Bot {
  AuthToken: string;
  CreatedAt: string;
  ID: number;
  Name: string;
  Organization: string;
  Role: string;
}

export interface TokenInput {
  name?: string;
  role?: string;
}

export const apiAuthFeature = 'apiauth';
export const invalidTokenNameMessage = 'This name already exists, please choose another name.';
