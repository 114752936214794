import React, { useEffect, useRef, useState } from 'react';
import { BarDatum, ComputedDatum } from '@nivo/bar';
import { toast } from 'react-hot-toast';
import { Filters } from 'react-table';

import BarChart from '~reactComponents/charts/BarChart/BarChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import { LoadingSpinner } from '@fairwindsops/ui-components';

import { ActionItem, AppGroup, Team } from '../../../ActionItems.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { convertFiltersToURL } from '~reactHelpers';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import { useDebouncedCallback } from 'use-debounce';
import { additionalQueryParams } from '../Charts.react';

type TopWorkloadsProps = {
  filters: Filters<ActionItem>;
  baseURL: string;
  noData: React.ReactNode;
  selectedTeam?: Team | null;
  selectedAppGroup?: AppGroup | null;
  setFilter: (columnId: string, updater: any) => void;
};

const TopWorkloads = ({ baseURL, filters, noData, selectedTeam, selectedAppGroup, setFilter }: TopWorkloadsProps) => {
  const [topWorkloadsData, setTopWorkloadsData] = useState<BarDatum[]>();
  const chartClicked = useRef<boolean>(false);
  const [loading, setLoading] = useState(true);

  const debounceGetTopWorkloads = useDebouncedCallback(async () => {
    const params = buildURLSearchParams();
    const URLParams = convertFiltersToURL(filters, params);

    try {
      const data: BarDatum[] = await sendRequest(
        'GET',
        `${baseURL}/action-items/top?${URLParams}&page=0&pageSize=5`,
        {},
        null,
      );
      const workloads = data
        .slice(0, 5)
        .reverse()
        .map((workload, idx) => {
          workload.FQN = workload.Resource;
          const name = typeof workload.Resource === 'string' ? workload.Resource.split('/') : workload.Resource;
          workload.Resource = Array.isArray(name) ? name[name.length - 1] : name;
          workload.Index = idx;
          return workload;
        });
      setTopWorkloadsData(workloads);
    } catch (e) {
      logger.logError('error_retrieving_top_workloads: ', e);
      toast.error(strings.actionItemsPage.errorRetrievingTopWorkloads);
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    setLoading(true);
    debounceGetTopWorkloads();
  }, [filters, selectedTeam, selectedAppGroup]);

  const clearFilter = () => {
    setFilter('ResourceName', []);
    chartClicked.current = false;
  };

  const buildURLSearchParams = () => {
    const currentURLSearchParams = new URLSearchParams(window.location.search);

    let url = 'groupBy=resource';

    if (selectedTeam?.ID) {
      url = `${url}&TeamID=${selectedTeam.ID}`;
    }

    if (selectedAppGroup?.name) {
      url = `${url}&AppGroup=${selectedAppGroup.name}`;
    }

    additionalQueryParams.forEach((param) => {
      if (currentURLSearchParams.get(param)) {
        url = `${url}&${param}=${currentURLSearchParams.get(param)}`;
      }
    });

    return new URLSearchParams(url);
  };

  const handleChartClick = (data: ComputedDatum<BarDatum>) => {
    setFilter('ResourceName', [{ value: data.data.Resource, label: data.data.Resource }]);
    chartClicked.current = true;
  };

  const ClearButtonComponent = () => {
    const filtered = filters.find((filter) => filter.id === 'ResourceName')?.value;
    if (chartClicked.current && filtered.length) {
      return (
        <ClearButton isFiltered={filtered.length} isChartClicked={chartClicked.current} clearFilter={clearFilter} />
      );
    }
    return <></>;
  };

  const tooltip = (data: any, color: string) => {
    return (
      <div
        className="top-workloads-tooltip"
        style={{
          width: `${typeof data.FQN === 'string' ? (data.FQN.length + data.Count.toString().length) * 0.55 : 10}rem`,
        }}
      >
        <div
          className="top-workloads-tooltip__square"
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div>
          {data.FQN}: <strong>{data.Count}</strong>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ClearButtonComponent />
          {topWorkloadsData?.length ? (
            <div aria-label={strings.ariaLabels.topImpactedWorkloadsChart} className="action-item--charts">
              <BarChart
                data={topWorkloadsData}
                keys={['Count']}
                indexBy="FQN"
                colors={[
                  COLORS.CHARTS.WORKLOADS[500],
                  COLORS.CHARTS.WORKLOADS[400],
                  COLORS.CHARTS.WORKLOADS[300],
                  COLORS.CHARTS.WORKLOADS[200],
                  COLORS.CHARTS.WORKLOADS[100],
                ]}
                layout="horizontal"
                onClick={handleChartClick}
                tooltip={tooltip}
                showLabel={true}
              />
            </div>
          ) : (
            <>{noData}</>
          )}
        </>
      )}
    </>
  );
};

export default TopWorkloads;
