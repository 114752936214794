import React from 'react';
import { Column, Renderer, FilterProps } from 'react-table';

import PillBadge from '~reactComponents/PillBadge/PillBadge.react';

import { AdmissionActionItemType } from '../../ReactAdmission.types.react';

import { strings } from '~utils/strings';
import { ADMISSION_ACTION_ITEM_RESOLUTIONS } from '~utils/constants';

export const TABLE_COLUMNS = (
  ColumnFiltering: Renderer<FilterProps<AdmissionActionItemType>>,
): Column<AdmissionActionItemType>[] => {
  return [
    {
      Header: strings.columnTitles.Status,
      accessor: 'Status',
      Filter: undefined,
      Cell: ({ value }: { value: string }) => {
        if (value === 'failure') {
          return (
            <span>
              <div className="admission-item-blocked__red-circle"></div>
            </span>
          );
        } else if (value === strings.general.PassiveFailure) {
          return (
            <span>
              <div className="admission-item-blocked__orange-circle"></div>
            </span>
          );
        } else if (value === 'success') {
          return (
            <span>
              <div className="admission-item-blocked__green-circle"></div>
            </span>
          );
        }
        return null;
      },
      width: 10,
      minWidth: 10,
      maxWidth: 10,
    },
    {
      Header: strings.columnTitles.Title,
      accessor: 'Title',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => {
        const title = value.length <= 75 ? value : value.slice(0, 75).concat('...');
        return <span>{title}</span>;
      },
      width: 150,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      Header: strings.columnTitles.Resolution,
      accessor: 'HistoricalResolution',
      Filter: ColumnFiltering,
      Cell: ({ value }: { value: string }) => {
        const resolution = ADMISSION_ACTION_ITEM_RESOLUTIONS.find((element) => element.value === value);
        return <span>{resolution?.label}</span>;
      },
      width: 150,
      minWidth: 100,
      maxWidth: 200,
    },
    {
      Header: strings.columnTitles.Category,
      accessor: 'Category',
      Cell: ({ value }: { value: string }): JSX.Element => <PillBadge color="low" text={value} />,
      Filter: ColumnFiltering,
      width: 100,
      minWidth: 50,
      maxWidth: 150,
    },
    {
      Header: strings.columnTitles.Severity,
      accessor: 'Severity',
      Cell: ({ value }: { value: number }): JSX.Element => {
        return <PillBadge severity={value} />;
      },
      Filter: ColumnFiltering,
      width: 100,
      minWidth: 75,
      maxWidth: 150,
    },
  ];
};

export const ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID = 'ADMISSION_ACTION_ITEMS_CONTEXT_MENU_ID';
