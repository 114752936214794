import React, { useEffect, useState } from 'react';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Card } from '@fairwindsops/ui-components';
import { ActionItemsReport } from '../../ActionItemsReports.config.react';

import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';

type BarChartProps = {
  data: ActionItemsReport[];
  isLoading: boolean | undefined;
  title: string;
  colors: Record<string, string>;
};

const ActionItemsReportsBarChart = ({ data, isLoading, title, colors }: BarChartProps): JSX.Element => {
  const getKeys = (): string[] => {
    const allKeys: string[] = [];
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]).filter((key) => key !== 'label');
      allKeys.push(...keys);
    }
    return Array.from(new Set(allKeys));
  };
  return (
    <div>
      <Card className="action-item-aggregator-summary-bar-chart">
        <Card.Title className="chart-title">
          <span className="aggregators">{title}</span>
        </Card.Title>
        <Card.Body>
          {isLoading && <LoadingSpinner containerClassNames="spinner" />}
          {!isLoading && data.length > 0 && (
            <div className="app-groups-chart">
              <LargeBarChart
                data={data}
                enableLabel={false}
                keys={getKeys()}
                indexBy={'label'}
                margin={{ top: 10, right: 10, bottom: 50, left: 85 }}
                colors={({ id }) => {
                  return colors[id];
                }}
                groupMode="grouped"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 2,
                  tickRotation: 0,
                }}
                axisBottom={{
                  tickSize: 3,
                  tickPadding: 2,
                }}
                tooltip={(d: any, label?: string) => (
                  <div className="action-items-reports-line-chart__tooltip">
                    <span>
                      {label?.split(' - ')[0]}: {d[label?.split(' - ')[0] ? label?.split(' - ')[0] : '']}
                    </span>
                  </div>
                )}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ActionItemsReportsBarChart;
