import { mapGetters } from 'vuex';
// eslint-disable-next-line
import ReactRepositories from './ReactRepositories.react';
import AddRepository from '../components/AddRepository/AddRepository.react';

export default {
  components: {
    'react-repositories': ReactRepositories,
    'add-repository': AddRepository,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
  computed: {
    ...mapGetters([
      'repository',
      'organization',
      'isAddRepoModalShown',
      'isRepoSettingsModalShown',
      'repositories',
      'isOrgOwner',
    ]),
  },
};
