import React from 'react';

import { Breadcrumbs } from '@fairwindsops/ui-components';

import { IRoute, IRouter, IStore } from '~utils/global.types.react';

import AccountsTable from './components/accountsTable/AccountsTable.react';
import AllTeamsTable from './components/allTeamsTable/AllTeamsTable.react';

import {
  ORG_DASHBOARD,
  TEAM_MANAGEMENT,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { handlePageChange } from '~reactHelpers';
import { strings } from '~utils/strings';

import './TeamManagement.react.scss';

type TeamManagementProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const TeamManagement = ({ route, router, store }: TeamManagementProps) => {
  const organization = store().getters.organization;

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.teamManagement.settings,
      href: `/orgs/${organization.Name}/settings/team-management`,
    },
    {
      id: 'last',
      label: strings.teamManagement.teamManagement,
      href: '',
      isActive: true,
    },
  ];

  return (
    <div className="team-management">
      <div className="team-management__breadcrumbs">
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
      </div>
      <div className="team-management__accounts-table-container" data-cy="all-accounts-card">
        <AccountsTable route={route} store={store} router={router} />
      </div>
      <div className="team-management__all-teams-table-container" data-cy="all-teams-card">
        <AllTeamsTable route={route} store={store} router={router} />
      </div>
    </div>
  );
};

export default TeamManagement;
