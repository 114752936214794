import { mapGetters } from 'vuex';
// eslint-disable-next-line
import ReactRepository from './ReactRepository.react';
import AddRepository from '../components/AddRepository/AddRepository.react';

export default {
  components: {
    'react-repository': ReactRepository,
    'add-repository': AddRepository,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
  computed: {
    ...mapGetters([
      'repository',
      'organization',
      'isAddRepoModalShown',
      'isRepoSettingsModalShown',
      'repositories',
      'isOrgOwner',
    ]),
  },
};
