import React, { useEffect, useMemo, useState } from 'react';
import {
  Column,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import Fuse from 'fuse.js';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';

import PaginationComponent from '~reactComponents/pagination/pagination.react';

import TableCardHeader from '../TableCardHeader/TableCardHeader.react';

import { strings } from '~utils/strings';

import { TABLE_COLUMNS } from '../ActionItemsSummary.config.react';
import { IRouter, OptionType, SelectedFilters } from '~utils/global.types.react';

type AggregatedActionItemsProps = {
  aggregatedActionItems: Record<string, any>[];
  page: number;
  itemsPerPage: number;
  organizationName: string;
  onPageChanged: (page: number, pageSize: number) => void;
  onSortChanged: (sortBy: any) => void;
  router: () => IRouter;
  aggregators: OptionType[];
  selectedFilters: SelectedFilters;
  onSelectedFiltersChanged: (key: string, value: string) => void;
};

const AggregatedActionItemsTable = ({
  aggregatedActionItems,
  page,
  itemsPerPage,
  organizationName,
  onPageChanged,
  router,
  onSortChanged,
  aggregators,
  selectedFilters,
  onSelectedFiltersChanged,
}: AggregatedActionItemsProps) => {
  const baseURL = `/v0/organizations/${organizationName}`;
  const baseActionItemLink = `/orgs/${organizationName}/action-items?page=0&orderBy=Severity.desc&pageSize=25`;

  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [totalRowCount, setTotalRowCount] = useState<number>(aggregatedActionItems?.length || 0);
  const [inputAggregatedActionItems, setInputAggregatedActionItems] = useState<
    Record<string, any>[]
  >([]);
  const [filteredAggregatedActionItems, setFilteredAggregatedActionItems] = useState<
    Record<string, any>[]
  >([]);

  useEffect(() => {
    const initAggreatedActionItems = aggregatedActionItems?.length ? aggregatedActionItems : [];
    setInputAggregatedActionItems(initAggreatedActionItems);
    setFilteredAggregatedActionItems(initAggreatedActionItems);
    setTotalRowCount(initAggreatedActionItems?.length || 0);
  }, [aggregatedActionItems]);

  const searchInstance = useMemo(() => {
    return new Fuse(inputAggregatedActionItems, {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['title'],
    });
  }, [inputAggregatedActionItems.length]);

  useEffect(() => {
    if (!globalSearch?.length) {
      setFilteredAggregatedActionItems(inputAggregatedActionItems);
      setTotalRowCount(inputAggregatedActionItems?.length || 0);
    } else {
      const results = searchInstance.search(globalSearch).map((res) => res.item);
      setFilteredAggregatedActionItems([...results]);
      setTotalRowCount(results?.length || 0);
    }
  }, [globalSearch, inputAggregatedActionItems]);

  useEffect(() => {
    // reset the pagination.
    onPageChanged(0, itemsPerPage);
  }, [globalSearch]);

  const data = useMemo(() => filteredAggregatedActionItems, [filteredAggregatedActionItems]);

  const columns = useMemo<Column[]>(
    () => TABLE_COLUMNS(baseActionItemLink, aggregators, selectedFilters, onSelectedFiltersChanged),
    [filteredAggregatedActionItems, aggregators, selectedFilters],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: page ? page - 1 : 0,
        pageSize: itemsPerPage || 0,
        sortBy: [
          {
            id: 'openCount',
            desc: true,
          },
        ],
      },
      manualFilters: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
  );

  useEffect(() => {
    onPageChanged(tableInstance.state.pageIndex, tableInstance.state.pageSize);
  }, [tableInstance.state.pageIndex, tableInstance.state.pageSize]);

  useEffect(() => {
    onSortChanged(tableInstance.state.sortBy);
  }, [tableInstance.state.sortBy]);

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage) {
      tableInstance.gotoPage(page);
    }
  };

  return (
    <div className="aggregated-action-items-table--container">
      <Card className="aggregated-action-items" data-cy="aggregated-action-items-table-card">
        <TableCardHeader
          globalSearch={globalSearch}
          setGlobalSearch={setGlobalSearch}
          tableInstance={tableInstance}
          totalRowCount={totalRowCount}
        />
        <table
          {...tableInstance.getTableProps()}
          className="aggregated-action-items-table"
          aria-label="aggregated-action-items-table"
          data-cy="aggregated-action-items-table"
        >
          <thead className="aggregated-action-items-header">
            {tableInstance.headerGroups.map((headerGroup) => (
              <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className={clsx({ 'select-header': column.id === strings.general.selection })}
                    {...column.getHeaderProps()}
                  >
                    <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <span>{column.render('Header')}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fas fa-arrow-down" />
                          ) : (
                            <i className="fas fa-arrow-up" />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div {...column.getResizerProps()} className="draggable-column" />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...tableInstance.getTableBodyProps()}>
            {tableInstance.page?.length
              ? tableInstance.page.map((row) => {
                  tableInstance.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="aggregated-action-items-table-row">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              {
                                className: `${cell.column?.className} ${
                                  cell.column?.id === strings.general.selection
                                    ? 'select-column'
                                    : ''
                                }`,
                                style: cell.column?.style,
                              },
                            ])}
                          >
                            <div className="cell-container">{cell.render('Cell')}</div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        <PaginationComponent
          currentPage={tableInstance.state.pageIndex}
          pageChange={handlePaginationChange}
          totalCount={totalPage}
        />
      </Card>
    </div>
  );
};

export default AggregatedActionItemsTable;
