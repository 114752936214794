import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { Modal, Button, Form } from 'react-bootstrap';
import { Card } from '@fairwindsops/ui-components';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Cluster, Organization, IStore } from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { ClusterItem } from './ClusterSettings.types.react';

import { getTableColumns } from './ClusterSettings.config.react';

import { UPDATE_ORG } from '~store/action.types';

import './ClusterSettings.react.scss';

type ClusterSettingsProps = {
  organization: Organization;
  clusters: Cluster[];
  isOrgOwner: boolean;
  store: () => IStore;
};

const ClusterSettings = ({ organization, clusters, isOrgOwner, store }: ClusterSettingsProps) => {
  const [clusterItems, setClusterItems] = useState<ClusterItem[]>([]);
  const [isDeleteClusterModalShown, setIsDeleteClusterModalShown] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const selectedCluster = useRef<Cluster>();
  const pollingUpdatedClustersInterval = useRef<NodeJS.Timeout>();

  const baseUrl = `/v0/organizations/${organization.Name}`;
  const POLLING_UPDATED_CLUSTERS_INTERVAL_TIME = 30000;

  useEffect(() => {
    pollingUpdatedClusters();
    return () => {
      clearPollingUpdatedClustersInterval();
    };
  }, []);

  useEffect(() => {
    setClusterItems(() =>
      clusters?.map((cluster) => ({ cluster: cluster.Name, status: cluster.Status })),
    );
  }, [clusters]);

  const deleteCluster = async () => {
    if (!selectedCluster.current?.Name) return;
    try {
      await sendRequest(
        'DELETE',
        `${baseUrl}/clusters/${selectedCluster.current.Name}?async=true`,
        {},
        null,
      );
      setIsDeleteClusterModalShown(false);
      await store().dispatch(UPDATE_ORG, organization.Name);
    } catch (e: unknown) {
      if (e instanceof SyntaxError) {
        logger.logEvent('event_delete_cluster', { message: e.message });
      }
    }
    setShowSpinner(false);
  };

  const pollingUpdatedClusters = () => {
    pollingUpdatedClustersInterval.current = setInterval(async () => {
      await store().dispatch(UPDATE_ORG, organization.Name);
    }, POLLING_UPDATED_CLUSTERS_INTERVAL_TIME);
  };

  const clearPollingUpdatedClustersInterval = () => {
    if (pollingUpdatedClustersInterval.current) {
      clearInterval(pollingUpdatedClustersInterval.current);
    }
  };

  const onClusterSelected = (item: ClusterItem) => {
    selectedCluster.current = clusters.find((cluster: Cluster) => item.cluster === cluster.Name);
    setIsDeleteClusterModalShown(true);
  };

  const columns = useMemo(
    () => getTableColumns({ onClusterSelected, isOrgOwner }) as any,
    [clusterItems],
  );

  const data = useMemo(() => clusterItems, [clusterItems]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  const confirmDeletion = (e: React.ChangeEvent) => {
    e.preventDefault();
    const typedInput = e.target as HTMLInputElement;
    const input = typedInput.value;

    setIsInvalid(input.length > 0 && input.length !== selectedCluster?.current?.Name.length);

    setIsDisabled(input !== selectedCluster.current?.Name);
  };

  return (
    <Card className={isOrgOwner ? 'cluster-container' : 'delete-cluster'}>
      <Card.Title>
        <h2
          className={`clusters-delete-title ${isOrgOwner ? '' : 'clusters-delete-non-owner-title'}`}
        >
          Clusters
        </h2>
      </Card.Title>
      {clusterItems?.length ? (
        <Card.Body>
          <table
            className="table-container cluster-table"
            {...getTableProps()}
            aria-label="Table enabling cluster deletion"
            data-cy="dangerzone-cluster-table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="table-body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Modal
            show={isDeleteClusterModalShown}
            onHide={() => setIsDeleteClusterModalShown(false)}
          >
            <Modal.Header>
              <h3 className="modal-title">
                Confirm Deletion of Cluster {selectedCluster.current?.Name}
              </h3>
            </Modal.Header>
            <Modal.Body className="pt-0 cluster-delete-modal">
              {!showSpinner && (
                <Form>
                  <Form.Group controlId="formClusterDeletion">
                    <Form.Label>Type in cluster name to confirm deletion</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => confirmDeletion(e)}
                      id="deleteCluster"
                      isInvalid={isInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cluster name must match that of cluster being deleted.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              )}
              {showSpinner && !isInvalid && !isDisabled && (
                <div>
                  <LoadingSpinner />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn"
                variant="primary"
                onClick={() => setIsDeleteClusterModalShown(false)}
              >
                Cancel
              </Button>
              <Button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  setShowSpinner(true);
                  deleteCluster();
                }}
                disabled={isDisabled}
              >
                {/* on click and if isInvalid & isDisabled are false, show loading spinner */}
                Delete Cluster {selectedCluster.current?.Name}
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      ) : (
        <span>There are no clusters.</span>
      )}
    </Card>
  );
};

export default ClusterSettings;
