import React, { useEffect, useRef, useState } from 'react';
import { BarDatum } from '@nivo/bar';
import { toast } from 'react-hot-toast';
import { Filters } from 'react-table';

import BarChart from '~reactComponents/charts/BarChart/BarChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import { LoadingSpinner } from '@fairwindsops/ui-components';

import { ActionItem, AppGroup, Team } from '../../../ActionItems.types.react';

import { convertFiltersToURL } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { useDebouncedCallback } from 'use-debounce';
import { additionalQueryParams } from '../Charts.react';

type NamespaceChartProps = {
  baseURL: string;
  filters: Filters<ActionItem>;
  noData: React.ReactNode;
  selectedTeam?: Team | null;
  selectedAppGroup?: AppGroup | null;
  setFilter: (columnId: string, updater: any) => void;
};

const NamespaceChart = ({
  baseURL,
  filters,
  noData,
  selectedTeam,
  selectedAppGroup,
  setFilter,
}: NamespaceChartProps) => {
  const [topNamespaces, setTopNamespaces] = useState<BarDatum[]>();
  const chartClicked = useRef<boolean>(false);
  const [loading, setLoading] = useState(true);

  const debouncedGetTopNamespace = useDebouncedCallback(async () => {
    const params = buildURLSearchParams();
    const URLParams = convertFiltersToURL(filters, params);

    try {
      const data: BarDatum[] = await sendRequest('GET', `${baseURL}/action-items/top?${URLParams}`, {}, null);

      const namespaces = data.slice(0, 5).map((namespace) => {
        if (!namespace.Namespace) {
          namespace.Namespace = 'No namespace';
        }
        return namespace;
      });
      setTopNamespaces(namespaces);
    } catch (e) {
      console.error('Error retrieving top severities: ', e);
      logger.logError('error_retrieving_top_severities: ', e);
      toast.error('Could not retrieve top severities');
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    setLoading(true);
    debouncedGetTopNamespace();
  }, [filters, selectedTeam, selectedAppGroup]);

  const clearFilter = () => {
    setFilter('ResourceNamespace', []);
    chartClicked.current = false;
  };

  const buildURLSearchParams = () => {
    const currentURLSearchParams = new URLSearchParams(window.location.search);

    let url = 'groupBy=namespace&page=0&pageSize=5';

    if (selectedTeam?.ID) {
      url = `${url}&TeamID=${selectedTeam.ID}`;
    }

    if (selectedAppGroup?.name) {
      url = `${url}&AppGroup=${selectedAppGroup.name}`;
    }

    additionalQueryParams.forEach((param) => {
      if (currentURLSearchParams.get(param)) {
        url = `${url}&${param}=${currentURLSearchParams.get(param)}`;
      }
    });

    return new URLSearchParams(url);
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    const value = indexValue === 'No namespace' ? '' : indexValue;
    setFilter('ResourceNamespace', [{ value, label: value }]);
    chartClicked.current = true;
  };

  const ClearButtonComponent = () => {
    const filtered = filters.find((filter) => filter.id === 'ResourceNamespace')?.value;
    if (chartClicked.current && filtered.length) {
      return (
        <ClearButton clearFilter={clearFilter} isFiltered={filtered.length} isChartClicked={chartClicked.current} />
      );
    }
    return <></>;
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ClearButtonComponent />
          {topNamespaces?.length ? (
            <div aria-label="Chart displaying top namespaces" className="action-item--charts">
              <BarChart
                data={topNamespaces}
                keys={['Count']}
                indexBy="Namespace"
                colors={[COLORS.CHARTS.NAMESPACE[100], COLORS.CHARTS.NAMESPACE[200]]}
                layout="vertical"
                onClick={handleChartClick}
                tooltipLabel={(d) => `${d?.data?.Namespace}`}
                showLabel={true}
              />
            </div>
          ) : (
            <>{noData}</>
          )}
        </>
      )}
    </>
  );
};

export default NamespaceChart;
