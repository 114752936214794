import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { strings } from '~utils/strings';

import { CvesReportModalProps } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.react';

import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import { formatDatetime } from '~utils/global.helpers.react';
import { DateType, OptionType } from '~utils/global.types.react';
import dayjs, { Dayjs } from 'dayjs';

import Select from 'react-select';
import { LoadingSpinner } from '@fairwindsops/ui-components';

const CvesReportModal = ({ showModal, closeModal, exportCves }: CvesReportModalProps) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState<DateType>({
    start: formatDatetime(dayjs().subtract(1, 'month')),
    end: formatDatetime(dayjs()),
  });

  const [selectedStatus, setSelectedStatus] = useState<OptionType[]>();
  const [selectedSeverity, setSelectedSeverity] = useState<OptionType[]>();
  const [processing, setProcessing] = useState<boolean>(false);

  const handleSubmit = async () => {
    setProcessing(true);
    await exportCves(
      selectedTimeRange,
      selectedStatus?.map((status) => status.value) || [],
      selectedSeverity?.map((severity) => severity.value) || [],
    );
    setProcessing(false);
  };

  const filterByDate = (start?: Dayjs | Date, end?: Date | Dayjs) => {
    if (!start || !end) {
      return;
    }

    const changedDates = {
      start: formatDatetime(dayjs(start)),
      end: formatDatetime(dayjs(end)),
    };
    setSelectedTimeRange(changedDates);
  };

  return (
    <Modal show={showModal.show} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{strings.general.exportCSV}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="action-items-reports__filter-container">
          <Datepicker
            isClearable={true}
            onDateSelect={filterByDate}
            startDate={selectedTimeRange.start}
            endDate={selectedTimeRange.end}
            minDate={2}
            className="action-items-reports"
          />
          <Select
            isMulti
            options={[
              {
                label: 'Introduced',
                value: 'introduced',
              },
              {
                label: 'Fixed',
                value: 'fixed',
              },
              {
                label: 'Resolved',
                value: 'resolved',
              },
            ]}
            value={selectedStatus}
            placeholder="Filter by status"
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={setSelectedStatus}
          />
          <Select
            isMulti
            options={[
              {
                label: 'Critical',
                value: 'CRITICAL',
              },
              {
                label: 'High',
                value: 'HIGH',
              },
              {
                label: 'Medium',
                value: 'MEDIUM',
              },
              {
                label: 'Low',
                value: 'LOW',
              },
            ]}
            value={selectedSeverity}
            placeholder="Filter by severity"
            isSearchable
            isClearable
            backspaceRemovesValue
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            onChange={setSelectedSeverity}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="create-list-modal__actions">
          <Button variant="secondary" onClick={closeModal}>
            {strings.general.Cancel}
          </Button>
          <div>
            <Button
              disabled={processing}
              onClick={() => {
                handleSubmit();
              }}
              data-cy="submit-export_cve-button"
            >
              {strings.general.Export}
            </Button>
          </div>
        </div>
      </Modal.Footer>
      <div className="loading-spinner-container">{processing && <LoadingSpinner />}</div>
    </Modal>
  );
};

export default CvesReportModal;
