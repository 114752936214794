import React, { useEffect, useState } from 'react';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Card } from '@fairwindsops/ui-components';
import { ActionItemAggregation, ActionItemAggregator } from '../ActionItemsSummary.config.react';

import { strings } from '~utils/strings';

import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';
import { COLORS } from '~utils/styling';

import './AggregatorBarChart.react.scss';
import { OptionType } from '~utils/global.types.react';
import Case from 'case';

const colors: Record<string, string> = {
  fixedCount: COLORS.CHARTS.ACTION_ITEMS_SUMMARY.FIXED,
  manuallyResolvedCount: COLORS.CHARTS.ACTION_ITEMS_SUMMARY.RESOLVED,
  openCount: COLORS.CHARTS.ACTION_ITEMS_SUMMARY.OPEN,
};

type BarChartProps = {
  data: ActionItemAggregation[];
  isLoading: boolean;
  title: string;
  selectedAggregators: OptionType[];
};

const AggregatorBarChart = ({
  data,
  isLoading,
  title,
  selectedAggregators,
}: BarChartProps): JSX.Element => {
  const [maxValue, setMaxValue] = useState(0);

  useEffect(() => {
    setMaxValue(data.reduce((currentMax, item) => Math.max(currentMax, item.openCount), 0));
  }, [data]);

  return (
    <div>
      <Card className="action-item-aggregator-summary-bar-chart">
        <Card.Title className="chart-title">
          <span className="aggregators">{title}</span>
        </Card.Title>
        <Card.Body>
          {isLoading && <LoadingSpinner containerClassNames="spinner" />}
          {!isLoading && data.length > 0 && selectedAggregators.length > 0 && (
            <div className="chart">
              <LargeBarChart
                data={data}
                keys={['openCount']}
                indexBy={'aggregator'}
                margin={{ top: 20, right: 0, bottom: 30, left: 85 }}
                maxValue={maxValue}
                colors={({ id }) => {
                  return colors[id];
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }}
                tooltip={(data, label) => {
                  return (
                    <div className="action-item-aggregator-summary-bar-chart__tooltip">
                      {selectedAggregators.map((aggregator) => {
                        let label = aggregator.label;
                        let labelValue =
                          data[aggregator.value] ||
                          data[`resource${Case.pascal(aggregator.value)}`];

                        if (aggregator.value === ActionItemAggregator.workload) {
                          labelValue = data.resourceName;
                        }

                        if (aggregator.value === ActionItemAggregator.eventType) {
                          label = data.title ? 'Title' : 'Event Type';
                          labelValue = data.title || data.eventType;
                        }

                        return (
                          <div
                            className="row"
                            key={data.aggregator + data.title + aggregator.value}
                          >
                            <div className="ellipsis">
                              <strong>{label}:</strong>
                              <span>{labelValue}</span>
                            </div>
                          </div>
                        );
                      })}
                      <div className="row">
                        <div
                          className="color-box"
                          style={{ backgroundColor: colors.openCount }}
                        ></div>
                        <div>
                          <strong>Open Action Items:</strong>
                          <span>{data.openCount || 0}</span>
                        </div>
                      </div>
                    </div>
                  );
                }}
              />
            </div>
          )}
          {!isLoading && !data?.length && selectedAggregators.length > 0 && (
            <div className="no-aggregator">{strings.efficiency.noDataToDisplay}</div>
          )}

          {!isLoading && selectedAggregators.length === 0 && (
            <div className="no-aggregator">
              No aggregators selected. Please select an aggregator to see aggregated action items.
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AggregatorBarChart;
