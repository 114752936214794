import * as React from 'react';

import { SortStatus } from '~globalTypes';

type IconProps = {
  fill?: string;
  height?: string;
  sortStatus?: string;
  width?: string;
};

const SortIcon = ({ sortStatus, width, height, fill, ...props }: IconProps): JSX.Element => {
  if (sortStatus === SortStatus.None) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fill}
        viewBox="0 0 148 100"
        preserveAspectRatio="none"
        {...props}
      >
        <path opacity=".3" d="m51 1 25 23 24 22H1l25-22zm0 100 25-23 24-22H1l25 22z" />
      </svg>
    );
  }
  if (sortStatus === SortStatus.Ascending) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fill}
        viewBox="0 0 148 100"
        preserveAspectRatio="none"
        {...props}
      >
        <path d="m51 1 25 23 24 22H1l25-22z" />
        <path opacity=".3" d="m51 101 25-23 24-22H1l25 22z" />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 148 100"
      preserveAspectRatio="none"
      {...props}
    >
      <path opacity=".3" d="m51 1 25 23 24 22H1l25-22z" />
      <path d="m51 101 25-23 24-22H1l25 22z" />
    </svg>
  );
};

export default SortIcon;
