<template>
  <router-view />
</template>

<script>
export default {
  name: 'opaRules',
};
</script>

<style lang="scss" scoped>
.policies {
  padding: 1rem;
  height: 100%;
}
</style>

<style lang="scss">
.opa-title {
  font-size: 1.75rem;
}
</style>
