import { Repository } from '~utils/global.types.react';
import { strings } from '~utils/strings';

export interface TableColumnsParams {
  onAutoScanChanged: (repository: Repository) => void;
  onIssueCreationChanged: (repository: Repository) => void;
}

export enum TableAccessors {
  Repository = 'Name',
}

const other = `\`\`\`
# Execute these commands.
curl -L ~ciUrl~ > insights-ci.sh
echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
chmod +x insights-ci.sh
image_version=~ciPluginImageVersion~ ./insights-ci.sh
\`\`\``;

const circle = `\`\`\`
jobs:
  build:
    docker:
      - image: <builder-image>
    steps:
      - checkout
      - setup_remote_docker
      - <Build images needed>
      - run:
          name: Insights CI
          command: |
            curl -L ~ciUrl~ > insights-ci.sh
            echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
            chmod +x insights-ci.sh
            image_version=~ciPluginImageVersion~ ./insights-ci.sh
\`\`\``;

const ado = `\`\`\`
variables:
  Agent.Source.Git.ShallowFetchDepth: 0

# Run on an Ubuntu agent so it has access to docker
steps:
- task: Bash@3
  inputs:
    targetType: 'inline'
    script: |
      curl -L ~ciUrl~ > insights-ci.sh
      echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
      chmod +x insights-ci.sh
      image_version=~ciPluginImageVersion~ ./insights-ci.sh
  env:
    FAIRWINDS_TOKEN: $(FAIRWINDS_TOKEN)
\`\`\``;

const github = `\`\`\`
name: Fairwinds Insights
on:
  push:
    branches: [ main ]
  pull_request:
    branches: [ main ]
  workflow_dispatch:

jobs:
  build:
    runs-on: ubuntu-latest
    env:
      FAIRWINDS_TOKEN: \${{ secrets.FAIRWINDS_TOKEN }}
    steps:
      - uses: actions/checkout@v3
        with:
          fetch-depth: 0
      - name: Fairwinds Insights
        run: |
          curl -L ~ciUrl~ > insights-ci.sh
          echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
          chmod +x insights-ci.sh
          image_version=~ciPluginImageVersion~ ./insights-ci.sh
\`\`\``;

const gitlab = `\`\`\`
docker-build:
  image: docker:latest
  stage: build
  variables:
    GIT_STRATEGY: clone
    GIT_DEPTH: 0
    FAIRWINDS_TOKEN: $FAIRWINDS_TOKEN
  services:
    - docker:dind
  before_script:
    - apk update
    - apk add bash
  script:
    - |
      wget -O insights-ci.sh ~ciUrl~
      echo "~ciSha~ insights-ci.sh" > insights-ci.sh.sha256
      sha256sum -c insights-ci.sh.sha256
      chmod +x insights-ci.sh
      bash
      image_version=~ciPluginImageVersion~ ./insights-ci.sh
\`\`\``;

const jenkins = `\`\`\`
pipeline {
  stages {
      stage ('Run Insights CI') {
          steps {
              withCredentials([usernamePassword(credentialsId: 'FAIRWINDS_INSIGHTS', passwordVariable: 'FAIRWINDS_TOKEN', usernameVariable: 'unused']) {
                  curl -L ~ciUrl~ > insights-ci.sh
                  echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
                  chmod +x insights-ci.sh
                  image_version=~ciPluginImageVersion~ ./insights-ci.sh
              }
          }
      }
  }
}
\`\`\``;

const travis = `\`\`\`
git:
	depth: false
services:
  - docker
env:
  - secure: "encrypted string for FAIRWINDS_TOKEN=<CI bot token>"
after_success: |
  curl -L ~ciUrl~ > insights-ci.sh
  echo "~ciSha~ *insights-ci.sh" | shasum -a 256 --check
  chmod +x insights-ci.sh
  image_version=~ciPluginImageVersion~ ./insights-ci.sh
\`\`\``;

export interface CISystem {
  name: string;
  instructions: string;
  filename?: string;
}

export const ciSystems = [
  {
    name: strings.register.Other,
    instructions: other,
  },
  {
    name: strings.circleCI,
    instructions: circle,
    filename: '.circleci/config.yml',
  },
  {
    name: strings.travisCI,
    instructions: travis,
  },
  {
    name: strings.githubActions,
    instructions: github,
  },
  {
    name: strings.gitlab,
    instructions: gitlab,
  },
  {
    name: strings.jenkins,
    instructions: jenkins,
  },
  {
    name: strings.azureDevOps,
    instructions: ado,
  },
] as const;
