import React, { useState, useEffect, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ChartType, Cluster, DateType } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';
import { convertFiltersToURL } from '~reactHelpers';

import '../Charts.react.scss';

type TopImpactedPackagesProps = {
  baseURL?: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  noData?: React.ReactNode;
  setFilter: (columnId: string, updater: any) => void;
  firstSeenRange: DateType | null;
};

const TopImpactedPackages = ({
  baseURL,
  cluster,
  filters,
  noData,
  setFilter,
  firstSeenRange,
}: TopImpactedPackagesProps) => {
  const [topImpactedPackages, setTopImpactedPackages] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getTopImpactedPackages();
    return () => {
      isMounted.current = false;
    };
  }, [cluster]);

  useEffect(() => {
    getTopImpactedPackages();
  }, [filters, firstSeenRange?.start, firstSeenRange?.end]);

  const getTopImpactedPackages = async () => {
    setLoaded(false);
    const params = new URLSearchParams(cluster ? `groupBy=packageName&cluster=${cluster.Name}` : 'groupBy=packageName');
    const URLParams = convertFiltersToURL(filters, params);
    let response = null;
    if (firstSeenRange?.start) {
      URLParams.append('firstSeen', firstSeenRange?.start);
    }
    if (firstSeenRange?.end) {
      URLParams.append('firstSeenEnd', firstSeenRange?.end);
    }
    try {
      response = await sendRequest('GET', `${baseURL}/vulnerabilities/top?${URLParams}`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_vuln_top_impacted_packages: ', e);
      toast.error(strings.vulnerabilities.errorTopImpactedPackages);
    }
    if (response?.length && isMounted.current) {
      const topImpactedPackages = response.slice(0, 5);
      setTopImpactedPackages(topImpactedPackages);
    } else {
      setTopImpactedPackages([]);
    }
    setLoaded(true);
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'packageName')?.value?.length) return;
    setFilter('packageName', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('packageName', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'packageName')?.value?.length}
      />
      {topImpactedPackages?.length ? (
        <div aria-label={strings.ariaLabels.topImpactedPackagesChart} className="vulnerabilities--charts">
          <BarChart
            data={topImpactedPackages}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[COLORS.CHARTS.PACKAGES[100], COLORS.CHARTS.PACKAGES[200]]}
            colorBy="indexValue"
            layout="vertical"
            tooltipLabel={(d) => `${d.data.value}`}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default TopImpactedPackages;
