import { Alert, Card, Row, Col, Badge, Form, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import timezone from 'dayjs/plugin/timezone';

import Icon from '~reactIcons/Icon.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import {
  ORG_DASHBOARD,
  AUTOMATION,
  EDITRULE,
  NEWRULE,
  AUTOMATION_TEMPLATES,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import { IRule } from './AutomationRulesList.types.react';
import { IStore, IRoute, IRouter } from '~globalTypes';

import { sendRequest } from '~utils/request';
import { getCurrentTimezone, handlePageChange } from '~reactHelpers';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import './AutomationRulesList.react.scss';

dayjs.extend(timezone);

type AutomationRulesListProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const AutomationRulesList = ({
  route,
  router,
  store,
}: AutomationRulesListProps): React.ReactNode => {
  const [rules, setRules] = useState<IRule[]>([]);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredRules, setFilteredRules] = useState<IRule[]>([]);
  const { isOrgOwner, organization } = store().getters;

  useEffect(() => {
    getRules();
  }, []);

  const formatDate = (rule: IRule) => {
    const timeZone = getCurrentTimezone();
    const { CreatedAt, LastUpdatedAt } = rule;
    if (LastUpdatedAt > CreatedAt) {
      return `Updated on ${
        timeZone
          ? dayjs(LastUpdatedAt).tz(timeZone).format('MM/DD/YY')
          : dayjs(LastUpdatedAt).format('MM/DD/YY')
      }`;
    }
    return `Created on ${
      timeZone
        ? dayjs(CreatedAt).tz(timeZone).format('MM/DD/YY')
        : dayjs(CreatedAt).format('MM/DD/YY')
    }`;
  };

  const onRuleClicked = (rule: IRule) => {
    logger.logEvent(`rule-templates: selected ${rule.Name} to edit`);
    router().push({ name: EDITRULE, query: { name: rule.Name } });
  };

  const getRules = async () => {
    setLoading(true);

    try {
      const rules = await sendRequest(
        'GET',
        `/v0/organizations/${organization.Name}/rules`,
        {},
        null,
      );

      setRules(rules);
      setLoading(false);
    } catch (e: unknown) {
      logger.logError('error_fetching_automation_rules', e);
    }
  };

  const onRulesSearched = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const filteredRules = rules?.filter((policy) =>
      policy.Name.toLowerCase().includes(target.value.toLowerCase()),
    );
    setSearch(target.value);
    setFilteredRules(filteredRules);
  };

  const createCustomRule = () => {
    router().push({ name: NEWRULE, params: { rules, organization } });
  };

  const createFromTemplate = () => {
    router().push({ name: AUTOMATION_TEMPLATES });
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: AUTOMATION,
      label: strings.navigation.Automation,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: 'All Rules',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="automation-rules--list">
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <div className="d-flex justify-content-between mb-3">
          {filteredRules.length || rules.length ? (
            <div className="automation-rules--list__header">
              <Form className="automation-rules--search">
                <Form.Group controlId="automation-rules-search">
                  <Form.Control
                    data-cy="rule-search-text-input"
                    onChange={onRulesSearched}
                    placeholder={strings.automationRule.search}
                    type="search"
                    value={search}
                  />
                </Form.Group>
              </Form>
              {isOrgOwner && (
                <div className="automation-rules--list__actions">
                  <Button
                    className="btn"
                    data-cy="create-rule-button"
                    onClick={createCustomRule}
                    type="button"
                  >
                    {strings.automationRule.createCustomRule}
                  </Button>
                  <Button
                    className="automation-rules--list__create-from-template"
                    data-cy="create-from-template-button"
                    onClick={createFromTemplate}
                    type="button"
                    variant="secondary"
                  >
                    {strings.automationRule.createFromTemplate}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {!isOrgOwner && (
          <Alert variant="warning" className="warning-alert">
            <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
            <h4 className="warning-title">
              {strings.automationRule.onlyOwner} <strong>{strings.automationRule.readOnly}</strong>
            </h4>
          </Alert>
        )}
        {!filteredRules.length && !rules.length && !loading ? (
          <div className="automation-rules--zero">
            <p>
              <strong className="align-center">
                {strings.automationRule.noRulesFound}
                <br />
              </strong>
              <a href={router().resolve({ name: NEWRULE, params: { rules, organization } }).href}>
                {strings.automationRule.createOneNow}
              </a>{' '}
              {strings.automationRule.or}{' '}
              <a
                href="https://insights.docs.fairwinds.com/configure/automation/rules/"
                target="_blank"
              >
                {strings.automationRule.readTheDocumentation}
              </a>
            </p>
          </div>
        ) : null}
        <Row xs={1} lg={1}>
          {filteredRules.length || rules.length || !loading ? (
            (filteredRules.length ? filteredRules : rules).map((rule, idx) => (
              <Col key={idx}>
                <Card
                  onClick={() => onRuleClicked(rule)}
                  role="button"
                  data-cy="rule-card"
                  className="automation-rules--list--card"
                >
                  <Card.Body>
                    <div className="automation-rules--list--card">
                      <span
                        role="heading"
                        aria-level={1}
                        className="automation-rules--list--card__title"
                        title={rule.Name}
                      >
                        {rule.Name}
                      </span>
                      <span>
                        {formatDate(rule)} by {rule.CreatedBy}
                      </span>
                    </div>

                    <div className="automation-rules--list--card--badge-container">
                      {rule.LogsEnabled && (
                        <Badge pill className="automation-rules--list--card--logging">
                          {strings.Logging}
                        </Badge>
                      )}
                      {rule.IsActive && route.name !== 'modify-opa-template' && (
                        <Badge variant="lighter-grey" pill>
                          {strings.automationRule.enabled}
                        </Badge>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <ThinCardLoader />
          )}
        </Row>
      </div>
    </LayoutReact>
  );
};

const ThinCardLoader = () => {
  return (
    <>
      {Array.from({ length: 7 }).map((_, idx) => (
        <Col key={idx}>
          <Card>
            <Card.Body>
              <div className="automation-rules--list--card">
                <span className="skeleton" style={{ width: '4rem' }} />
                <span
                  className="skeleton"
                  style={{ width: `${Math.random() * (23 - 13) + 11}rem` }}
                />
              </div>
              <span className="skeleton rounded" style={{ width: '4rem' }} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default AutomationRulesList;
