import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { Modal, Button } from 'react-bootstrap';
import { Card } from '@fairwindsops/ui-components';

import { RepositoryItem } from './SettingsRepos.types.react';

import { getTableColumns } from './SettingsRepos.config.react';

import { REMOVE_ORG_REPOSITORY } from '~store/action.types';

import { Repository, IStore } from '~globalTypes';
import { strings } from '~utils/strings';
import logger from '~logger';
import { CardTitlePrimary } from '~utils/texts.react';

import './SettingsRepos.react.scss';

type SettingsReposProps = {
  repositories: Repository[];
  isOrgOwner: boolean;
  store: () => IStore;
};

const SettingsRepos = ({ repositories, isOrgOwner, store }: SettingsReposProps) => {
  const [repositoryItems, setRepositoryItems] = useState<RepositoryItem[]>([]);
  const [isDeleteRepoModalShown, setIsDeleteModalShown] = useState<boolean>(false);

  const selectedRepository = useRef<Repository>();

  useEffect(() => {
    setRepositoryItems(() => repositories?.map((repository) => ({ repository: repository.Name })));
  }, [repositories]);

  const deleteRepository = async () => {
    if (!selectedRepository.current) return;
    try {
      await store().dispatch(REMOVE_ORG_REPOSITORY, selectedRepository.current);
      setIsDeleteModalShown(false);
    } catch (e: any) {
      logger.logEvent('event_delete_repo', { message: e.message });
    }
  };

  const onRepoSelected = (item: RepositoryItem) => {
    selectedRepository.current = repositories.find((repo) => item.repository === repo.Name);
    setIsDeleteModalShown(true);
  };

  const columns = useMemo(
    () => getTableColumns({ onRepoSelected, isOrgOwner }) as any,
    [repositoryItems],
  );

  const data = useMemo(() => repositoryItems, [repositoryItems]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  return (
    <Card className={`repo-container ${isOrgOwner ? '' : 'delete-repo'}`}>
      <Card.Title className="repo-header">
        <h2
          className={CardTitlePrimary({
            textTransform: strings.textStyling.capitalize,
            topMargin: strings.textStyling.md,
            weight: strings.textStyling.medium,
          })}
        >
          {strings.navigation.Repositories}
        </h2>
      </Card.Title>
      {repositoryItems?.length ? (
        <Card.Body className={isOrgOwner ? 'repo-container__card-body' : ''}>
          <table
            className="table-container repo-table"
            {...getTableProps()}
            aria-label="Table enabling repository deletion"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>{column.render('Header')}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="table-body">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Modal show={isDeleteRepoModalShown} onHide={() => setIsDeleteModalShown(false)}>
            <Modal.Body className="pt-0 repo-delete-modal">
              <h3
                className={CardTitlePrimary({
                  textTransform: strings.textStyling.capitalize,
                  topMargin: strings.textStyling.lg,
                  bottomMargin: strings.textStyling.smBottom,
                })}
              >
                {strings.confirmRepoDelete} {selectedRepository.current?.Name}
              </h3>
              <p>
                {strings.confirmRepoDeleteExplanation.replace(
                  'selectedRepo',
                  selectedRepository.current?.Name,
                )}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn"
                variant="primary"
                onClick={() => setIsDeleteModalShown(false)}
              >
                {strings.Cancel}
              </Button>
              <Button className="btn btn-danger" type="button" onClick={deleteRepository}>
                {strings.Delete} {selectedRepository.current?.Name}
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      ) : (
        <span className={isOrgOwner ? 'repo-container__no-repo' : ''}>{strings.noRepos}</span>
      )}
    </Card>
  );
};

export default SettingsRepos;
