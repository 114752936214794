import Case from 'case';
import { OptionType, SelectedFilters } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import {
  ActionItemAggregation,
  ActionItemAggregator,
  SEARCH_PARAMS_MAPPER,
  TEAM,
  Team,
} from './ActionItemsSummary.config.react';

export async function getAggregatedActionItems(
  selectedAggregators: OptionType[],
  selectedFilters: SelectedFilters,
  teams: Team[],
  org: string,
  cluster?: string,
  sortBy?: any,
): Promise<ActionItemAggregation[]> {
  const searchParams = new URLSearchParams();

  selectedAggregators.forEach((aggregator) => {
    searchParams.append('aggregator', aggregator.value);
  });

  if (searchParams.has('aggregator', ActionItemAggregator.eventType)) {
    searchParams.append('aggregator', ActionItemAggregator.title);
  }

  if (cluster) {
    searchParams.append('cluster', cluster);
  }

  const filterKeys = Object.keys(selectedFilters);

  if (filterKeys?.length) {
    for (const key of filterKeys) {
      const apiSearchKey = SEARCH_PARAMS_MAPPER[key];

      if (!apiSearchKey) {
        continue;
      }

      if (key === TEAM) {
        selectedFilters[key].forEach((data: any) => {
          const team = teams.find((team) => team.Name === data.value);

          if (team?.ID) {
            searchParams.append(apiSearchKey, String(team.ID));
          }
        });
        continue;
      }

      selectedFilters[key].forEach((data: any) => searchParams.append(apiSearchKey, data.value));
    }
  }

  if (sortBy?.length) {
    const sortCriteria = sortBy[0];
    searchParams.append('orderBy', sortCriteria.id);
    searchParams.append('orderByDesc', sortCriteria.desc);
  }

  const fetchedAggregatedActionItems = await sendRequest(
    'GET',
    `/v0/organizations/${org}/action-items/aggregation?${searchParams}`,
    {},
    null,
  );

  return fetchedAggregatedActionItems;
}

export function getAggregatorString(
  aggregatedActionItem: ActionItemAggregation,
  selectedAggregators: OptionType[],
) {
  return selectedAggregators
    .map(({ value }) => {
      return aggregatedActionItem[value] ?? aggregatedActionItem[`resource${Case.pascal(value)}`];
    })
    .join('-');
}
