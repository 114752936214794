import React from 'react';
import { Form } from 'react-bootstrap';
import { TableInstance } from 'react-table';

import { TableColumnsParams, TableAccessors } from './AddRepository.types.react';

import { strings } from '~utils/strings';

export const getTableColumns = ({
  onAutoScanChanged,
  onIssueCreationChanged,
}: TableColumnsParams) => [
  {
    Header: strings.general.Repository,
    accessor: TableAccessors.Repository,
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return <span>{values.Name}</span>;
    },
    minWidth: 524,
    maxWidth: 524,
    width: 524,
  },
  {
    Header: strings.repository.autoScan,
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return (
        <Form.Check
          aria-label={strings.repository.autoScanAriaLabel}
          className="add-repo-switch-btn"
          defaultChecked={values.AutoScan}
          disabled={!values.InstallationID}
          id={`auto-scan-switch-${values.ID}`}
          onChange={() => onAutoScanChanged(values)}
          type="switch"
        />
      );
    },
  },
  {
    Header: strings.repository.issueCreation,
    minWidth: 130,
    maxWidth: 130,
    width: 130,
    Cell: (data: TableInstance) => {
      const values = data.row.original;
      return (
        <Form.Check
          aria-label={strings.repository.issueCreationAriaLabel}
          className="add-repo-switch-btn"
          defaultChecked={values.Tickets}
          disabled={!values.InstallationID}
          id={`issue-creation-scan-switch-${values.ID}`}
          onChange={() => onIssueCreationChanged(values)}
          type="switch"
        />
      );
    },
  },
];

export const POLLING_REPOSITORIES_INTERVAL_TIME = 1000;
export const POLLING_REPOSITORIES_TIMEOUT = 1800000;
