// eslint-disable-next-line
import DangerZone from './DangerZone.react.tsx';

export default {
  components: {
    'danger-zone': DangerZone,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
