import React, { useState, useEffect, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { ChartType, Cluster, DateType } from '~globalTypes';
import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';

import { getChartURLSearchParams } from '../../../../ReactVulnerabilities.helpers.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

import '../Charts.react.scss';
import './TopImpactedImages.react.scss';
import { convertFiltersToURLForAllImages } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.helpers.react';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';

type TopImpactedImages = {
  baseURL?: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  isResolvedShown?: boolean;
  selectedAppGroup: AppGroup | null;
  firstSeenRange: DateType | null;
  noData?: React.ReactNode;
  setFilter: (columnId: string, updater: any) => void;
};

const TopImpactedImages = ({
  baseURL,
  cluster,
  filters,
  isResolvedShown,
  selectedAppGroup,
  firstSeenRange,
  noData,
  setFilter,
}: TopImpactedImages) => {
  const [topImpactedImages, setTopImpactedImages] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getTopImpactedImages();
    return () => {
      isMounted.current = false;
    };
  }, [cluster, isResolvedShown, firstSeenRange, selectedAppGroup]);

  useEffect(() => {
    if (filters?.length) {
      getTopImpactedImages();
    }
  }, [filters]);

  const getTopImpactedImages = async () => {
    try {
      setLoaded(false);
      const params = getChartURLSearchParams({
        groupBy: 'title',
        cluster,
        isResolvedShown,
        selectedAppGroup,
        firstSeen: firstSeenRange?.start,
        firstSeenEnd: firstSeenRange?.end,
      });
      const URLParams = convertFiltersToURLForAllImages(filters, params);
      const response = await sendRequest('GET', `${baseURL}/images/vulnerabilities/top?${URLParams}`, {}, null);
      const topImpactedImages = response.slice(0, 5).reverse();
      if (isMounted.current) {
        setTopImpactedImages(topImpactedImages);
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_vuln_top_impacted_images: ', e);
      toast.error(strings.vulnerabilities.errorTopImpactedImages);
    }
  };

  const handleChartClick = ({ indexValue }: { indexValue: string | number }) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'title')?.value?.length) return;
    setFilter('title', [{ value: indexValue, label: indexValue }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('title', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  const tooltip = (data: any, color: string) => {
    return (
      <div
        className="top-impacted-images-tooltip"
        style={{
          width: `${
            typeof data.value === 'string' ? (data.value.length + data.count.toString().length) * 0.6 : '20'
          }rem`,
        }}
      >
        <div
          className="top-impacted-images-tooltip__square"
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div>
          {data.value}: <strong>{data.count}</strong>
        </div>
      </div>
    );
  };

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'title')?.value?.length}
      />
      {topImpactedImages?.length ? (
        <div aria-label={strings.ariaLabels.topImpactedImagesChart} className="vulnerabilities--charts">
          <BarChart
            data={topImpactedImages}
            keys={['count']}
            indexBy={strings.noTranslate.value}
            colors={[
              COLORS.CHARTS.PURPLE[100],
              COLORS.CHARTS.PURPLE[200],
              COLORS.CHARTS.PURPLE[300],
              COLORS.CHARTS.PURPLE[400],
              COLORS.CHARTS.PURPLE[500],
            ]}
            layout="horizontal"
            tooltip={tooltip}
            onClick={handleChartClick}
            showLabel={true}
          />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default TopImpactedImages;
