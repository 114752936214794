import logger from '~utils/logger';
import { sendRequest } from '~utils/request';
import { LimitsType } from '../Costs.types.react';
import { useState } from 'react';

const useWorkloadsRecommendation = (org: string) => {
  const [limits, setLimits] = useState<LimitsType>();

  const fetchWorkloadsRecommendation = async (
    cluster: string,
    kind: string,
    namespace: string,
    workload: string,
    container?: string | null,
  ) => {
    let response;
    try {
      response = await sendRequest(
        'GET',
        `/v0/organizations/${org}/clusters/${cluster}/workloads/recommendation?kind=${kind}&namespace=${namespace}&workload=${workload}${container ? `&container=${container}` : ''}`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_workloads_recommendations', e);
    }
    setLimits(response);
    return;
  };

  return { limits, setLimits, fetchWorkloadsRecommendation };
};

export default useWorkloadsRecommendation;
