import React, { Children } from 'react';
import { TableInstance } from 'react-table';
import Select, { components, ValueContainerProps, MultiValue, ActionMeta } from 'react-select';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { SELECT_STYLES } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.config.react';
import { filtersMap, sortingFields } from '../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';

import { mapFilters, formatStatus, saveResizedWidth, RESIZED_WIDTH_STORAGE_KEYS } from '~reactHelpers';
import { FiltersMapType } from '~globalTypes';

import './SelectColumnFilter.react.scss';
import { COLORS } from '~utils/styling';

export const vulnerabilitiesValueToLabel = {
  '25': '<25',
  '50': '<50',
  '100': '<100',
  '101': '101+',
};

export const oneOrMoreValueToLabel = {
  true: '1 or More',
  false: 'None',
};

type OptionType = {
  value: string;
  label: string;
};

type SelectColumnFilterType = {
  tableInstance: TableInstance;
  filters: FiltersMapType;
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    return child && allowedTypes.includes(child.type.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const SelectColumnFilter = ({ tableInstance, filters, setLoaded }: SelectColumnFilterType) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = tableInstance;

  const mappedFilters = mapFilters(filters, filtersMap, sortingFields);

  let options: OptionType[];

  if (mappedFilters?.[id] && Array.isArray(mappedFilters[id])) {
    options = (mappedFilters[id] || []).map((option: unknown) => {
      if (typeof option === 'object') {
        return option;
      }
      return {
        label: id === 'status' ? formatStatus(option as string) : String(option),
        value: String(option),
      };
    });
  } else {
    options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows?.forEach((row) => {
        options.add(row.values[id]?.toString());
      });
      return Array.from(options).map((option) => ({
        label: option,
        value: option,
      }));
    }, [id, preFilteredRows]);
  }

  const handleChange = (selected: MultiValue<OptionType>) => {
    if (!selected?.length && !filterValue?.length) return;

    saveResizedWidth(RESIZED_WIDTH_STORAGE_KEYS.vulnerabilitiesAllImagesPage, tableInstance);

    setFilter(selected);
    setLoaded && setLoaded(false);
  };

  const getFilteredValues = () => {
    if (!filterValue?.length) return null;

    if (id != 'vulnerabilitiesCount') return filterValue;

    return filterValue.map((filterValue: { value: string }) => {
      const label = vulnerabilitiesValueToLabel[filterValue.value as keyof typeof vulnerabilitiesValueToLabel];
      return label ? { value: filterValue.value, label } : filterValue;
    });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={getFilteredValues()}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          ValueContainer,
        }}
        onChange={(selected) => handleChange(selected)}
      />
    </div>
  );
};

export default React.memo(SelectColumnFilter);
