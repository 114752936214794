import * as React from 'react';
import { COLORS } from '~utils/styling';

const FilterIcon = ({
  width = '1.25rem',
  height = `1.25rem`,
  darkColor = COLORS.CORE.DARK_GRAY,
  lightColor = COLORS.CORE.WHITE,
}): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width}>
    <rect y="1.68" width="18" height="1" rx=".5" fill={darkColor} />
    <circle cx="3.6" cy="1.9" r="1.3" fill={lightColor} stroke={darkColor} stroke-width=".8" />
    <rect y="16.07" width="18" height="1" rx=".5" fill={darkColor} />
    <circle cx="14.4" cy="16.3" r="1.3" fill={lightColor} stroke={darkColor} stroke-width=".8" />
    <rect y="8.87" width="18" height="1" rx=".5" fill={darkColor} />
    <circle cx="9" cy="9.1" r="1.3" fill={lightColor} stroke={darkColor} stroke-width=".8" />
  </svg>
);

export default FilterIcon;
