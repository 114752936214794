import React from 'react';
import { COLORS } from '~utils/styling';

function CopyIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.45 3.03H2.5v13.2c0 .68.6 1.24 1.31 1.24h10.36v1.29c0 .68-.59 1.24-1.31 1.24H1.46c-.73 0-1.32-.56-1.32-1.24V4.28c0-.69.6-1.25 1.31-1.25ZM4.43 0h8.46v3.07c0 .6.52 1.1 1.15 1.1h3.1v11.55c0 .69-.58 1.25-1.3 1.25H4.44c-.73 0-1.32-.56-1.32-1.25V1.24C3.12.56 3.72 0 4.43 0Zm9.16 0h.55l3 2.84v.66h-3.1a.44.44 0 0 1-.45-.43V0Z"
        fill={COLORS.CORE.WHITE}
      />
    </svg>
  );
}

export default CopyIcon;
