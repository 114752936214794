import React from 'react';

import Logo from '~public/logo-navy.png';

import { IStore } from '~globalTypes';
import { strings } from '~utils/strings';

import './TrialExpired.react.scss';

type TrialExpiredProps = {
  store: () => IStore;
};

const TrialExpired = ({ store }: TrialExpiredProps) => {
  const { organization } = store().getters;
  if (!organization || organization.Tier) return <></>;

  return (
    <div className="trial-expired">
      <img className="w-25" src={Logo} alt="Fairwinds Insights Logo" />
      <span className="trial-expired__content">
        Thank you for using Fairwinds Insights. The Fairwinds Insights Free Tier is no longer
        available. If you are interested in a subscription to Fairwinds Insights, or starting a new
        trial, please contact <a href={strings.noTranslate.mailToSales}>sales@fairwinds.com</a>
      </span>
    </div>
  );
};

export default TrialExpired;
