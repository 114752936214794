import React, { useEffect, useState } from 'react';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import PolicyEditor from '../PolicyEditor.react';
import { sendRequest } from '~utils/request';
import './NewPolicy.react.scss';
import { IPolicies, IPolicyFormData } from '../policy.types.react';
import { IStore, IRoute, IRouter } from '~globalTypes';
import { displayToast, handlePageChange } from '~reactHelpers';
import logger from '~logger';
import { strings } from '~utils/strings';
import {
  ORG_DASHBOARD,
  ALL_POLICIES,
  POLICY_WIZARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';

type NewPolicyProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  edit?: boolean;
};

const NewPolicy = ({ route, router, store }: NewPolicyProps): JSX.Element => {
  const { org } = route.params || {};

  const breadcrumbRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ALL_POLICIES,
      label: strings.navigation.Policy,
      href: `/orgs/${org}/policy`,
    },
    {
      id: 'last',
      label: 'OPA Policy Wizard',
      href: `/orgs/${org}/policy`,
      isActive: true,
    },
  ];
  const [policies, setPolicies] = useState<IPolicies[]>([]);
  const baseUrl = `/v0/organizations/${store().getters.organization.Name}/opa/customChecks`;

  useEffect(() => {
    if (route?.params?.policies) {
      setPolicies([...route.params.policies]);
    } else {
      sendRequest('GET', baseUrl, {}, null)
        .then((res: IPolicies[]) => {
          setPolicies(res);
        })
        .catch(() => {
          displayToast('Error fetching policies', true, store);
          logger.logError('error_fetching_policies');
        });
    }
  }, []);

  const handleOnSubmit = async (data: IPolicyFormData) => {
    if (policies.some((policy) => policy.Name === data.Name)) {
      displayToast(`Policy ${data.Name} already exists!`, true, store);
      return;
    }

    const policy = {
      Rego: data.Rego,
      Description: data.Description,
    };

    try {
      await sendRequest(
        'PUT',
        `${baseUrl}/${data.Name}?version=2.0`,
        { data: policy, headers: { 'content-type': 'application/json' } },
        null,
      );
      displayToast(strings.policy.policyCreated.replace('#policyName', data.Name), false, store);
      logger.logEvent('policy-wizard: new policy created');
      router().push({
        name: POLICY_WIZARD,
        query: {
          name: data?.Name,
        },
      });
    } catch (e) {
      displayToast(e.message, true, store);
      logger.logError('error_creating_policy', e);
    }
  };

  return (
    <LayoutReact>
      <Breadcrumbs
        data={breadcrumbRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <PolicyEditor
        route={route}
        router={router}
        store={store}
        edit={false}
        isTemplate={false}
        handleOnSubmit={handleOnSubmit}
      />
    </LayoutReact>
  );
};

export default NewPolicy;
