import React, { useMemo } from 'react';
import BarChart from '~reactComponents/charts/BarChart/BarChart.react';

import { ACTION_ITEMS } from '~reactComponents/NavigationReact/Navigation.config.react';

import { OrgIssue, SearchCriteria, chartColors, actionItemQueriesMap } from './TopOrgIssues.types.react';
import { OptionType, IRouter } from '~globalTypes';

import { strings } from '~utils/strings';

type TopOrgIssueChartProps = {
  selectedReport: OptionType;
  issues: OrgIssue[];
  firstSeenCriteria: string | null | undefined;
  router: () => IRouter;
};

const TopOrgIssuesChart = ({ selectedReport, issues, firstSeenCriteria, router }: TopOrgIssueChartProps) => {
  const onChartClicked = (selectedData: any) => {
    if (!selectedData) return;
    const queryCriteria = getQueryCriteria(selectedReport);
    const queryValue = selectedData.data[queryCriteria];
    if (!queryCriteria || !queryValue) return;
    router().push({
      name: ACTION_ITEMS,
      query: {
        [queryCriteria]: [queryValue],
        [SearchCriteria.FirstSeen]: firstSeenCriteria,
        Resolution: 'None',
        Fixed: 'false',
      },
    });
  };

  const getQueryCriteria = (selectedReport: OptionType) =>
    selectedReport && selectedReport.value ? actionItemQueriesMap[selectedReport.value] : actionItemQueriesMap.issues;

  const queryCriteria = getQueryCriteria(selectedReport);

  const margins = useMemo(
    () => ({
      top: 20,
      right: 0,
      bottom: 20,
      left: actionItemQueriesMap[selectedReport.value] === 'Severity' ? 100 : 180,
    }),
    [selectedReport],
  );

  const tooltip = (data: any, color: string) => {
    const currentReportItem = data[actionItemQueriesMap[selectedReport.value]];
    const length = currentReportItem.length + data.Count.toString().length;
    return (
      <div
        className="top-issues-tooltip"
        style={{
          width: `${length < 40 ? length * 0.8 : length * 0.6}rem`,
        }}
      >
        <div
          className="top-issues-tooltip__square"
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div>
          {currentReportItem}: <strong>{data.Count}</strong>
        </div>
      </div>
    );
  };

  const FormattedAxis = ({ bars }) => {
    if (bars.length > 0) {
      return bars?.map((bar) => {
        const currentReportItem = actionItemQueriesMap[selectedReport.value];
        const datum = bar.data.data[currentReportItem];
        const label = datum.length > 18 ? `${datum.slice(0, 18)}...` : datum;

        return (
          <text
            dy={bar.absY}
            dx={currentReportItem === 'Severity' ? -100 : -180}
            id={`org-overview-top-issues-bar-chart__${
              currentReportItem === 'Severity' ? 'axis-label-severity' : 'axis-label'
            }`}
          >
            {label}
          </text>
        );
      });
    }
    return <></>;
  };

  return (
    <div aria-label={strings.ariaLabels.topOrgIssuesChart} id="org-overview-top-issues-bar-chart">
      <BarChart
        data={issues}
        keys={['Count']}
        indexBy={queryCriteria}
        colors={chartColors.slice(issues.length * -1)}
        layout="horizontal"
        tooltip={tooltip}
        onClick={onChartClicked}
        showLabel={false}
        FormattedAxis={FormattedAxis}
        margin={margins}
      />
    </div>
  );
};

export default TopOrgIssuesChart;
