import React from 'react';

import ImageDetailItemsTable from './components/ImageDetailItemsTable/ImageDetailItemsTable.react';

import { IRoute, IRouter, IStore } from '~globalTypes';

import './ReactImageDetail.react.scss';

type ReactImageDetailProps = {
  store: () => IStore;
  route: IRoute;
  router: () => IRouter;
};

const ReactImageDetail = ({ store, route, router }: ReactImageDetailProps) => {
  const { organization } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const isOwner = store().getters.isOrgOwner;

  return (
    <ImageDetailItemsTable
      organization={organization}
      route={route}
      router={router}
      baseURL={baseURL}
      sha={route.params?.selectedImage}
      isOwner={isOwner}
    />
  );
};

export default ReactImageDetail;
