import React from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import { OptionType } from '~utils/global.types.react';

type WorkloadSelectorProps = {
  searchParams: URLSearchParams;
  refreshKey: number;
  onLoadOptions: (i: string, filter: string) => Promise<any>;
  onSelectChange: (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  onClearAll: () => void;
};

function WorkloadSelector({
  searchParams,
  refreshKey,
  onLoadOptions,
  onSelectChange,
  onClearAll,
}: WorkloadSelectorProps) {
  const defaultValue = (type: string) => {
    const value = searchParams.get(type);
    return value ? { label: value, value: value } : null;
  };

  const allFilters = ['clusters', 'namespaces', 'kinds', 'workloads'].filter((f) => searchParams.has(f));

  return (
    <div className="filters">
      <AsyncSelect
        key={`select0-${refreshKey}`}
        name="clusters"
        loadOptions={(i, cb) => {
          onLoadOptions(i, 'clusters').then(cb);
        }}
        cacheOptions
        defaultOptions
        placeholder="Select a cluster"
        isClearable
        onChange={onSelectChange}
        value={defaultValue('clusters')}
      />
      <AsyncSelect
        key={`select1-${refreshKey}`}
        name="namespaces"
        loadOptions={(i, cb) => {
          onLoadOptions(i, 'namespaces').then(cb);
        }}
        cacheOptions
        defaultOptions
        placeholder="Select a namespace"
        isClearable
        onChange={onSelectChange}
        value={defaultValue('namespaces')}
      />
      <AsyncSelect
        key={`select2-${refreshKey}`}
        name="kinds"
        loadOptions={(i, cb) => {
          onLoadOptions(i, 'kinds').then(cb);
        }}
        cacheOptions
        defaultOptions
        placeholder="Select a kind"
        isClearable
        onChange={onSelectChange}
        value={defaultValue('kinds')}
      />
      <AsyncSelect
        key={`select3-${refreshKey}`}
        name="workloads"
        loadOptions={(i, cb) => {
          onLoadOptions(i, 'workloads').then(cb);
        }}
        cacheOptions
        defaultOptions
        placeholder="Select a workload"
        isClearable
        onChange={onSelectChange}
        value={defaultValue('workloads')}
      />
      {allFilters.length > 0 ? (
        <div onClick={onClearAll} className="clear-changes-button">
          <CloseIcon />
          <span>Clear All</span>
        </div>
      ) : null}
    </div>
  );
}

export default WorkloadSelector;
