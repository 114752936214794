import Notifications from './Notifications.react';

export default {
  name: 'notifications',
  components: {
    'notifications-page': Notifications,
  },
  methods: {
    store() {
      return this.$store;
    },
    router() {
      return this.$router;
    },
  },
};
