import React, { useEffect, useMemo, useState } from 'react';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';

import CloseIcon from '~reactComponents/Icons/Close.icon.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import ExpandIcon from '~reactComponents/Icons/ExpandIcon.icon.react';
import Tag from '~reactComponents/Tags/Tag.react';

import { strings } from '~utils/strings';
import { APP_GROUP_SPECS, IAppGroup, IAppGroupSpec } from '~utils/global.types.react';

import './ViewAppGroupModal.react.scss';

type ExpadableSections = {
  matchResources: boolean;
  excludeResources: boolean;
};

type ViewAppGroupModalProps = {
  isModalShown?: boolean;
  onModalHidden?: (isModalShown?: boolean | undefined) => void;
  selectedAppGroup: IAppGroup | null;
};

const ViewAppGroupModal = ({
  isModalShown,
  onModalHidden,
  selectedAppGroup,
}: ViewAppGroupModalProps) => {
  const [matchResources, setMatchResources] = useState<IAppGroupSpec>({});
  const [excludeResources, setExcludeResources] = useState<IAppGroupSpec>({});

  const transformResources = (resources: IAppGroupSpec) => {
    if (!resources) {
      return resources;
    }

    const keys = Object.keys(resources);

    for (const key of keys) {
      const appGroupKey = key as keyof IAppGroupSpec;

      if (['namespaceLabels', strings.appGroups.labelsKey].includes(appGroupKey)) {
        resources[key as keyof IAppGroupSpec] = resources[key as keyof IAppGroupSpec]?.map((data) =>
          JSON.stringify(data),
        );
      }
    }

    return resources;
  };

  const transformSelectedAppGroup = () => {
    if (!selectedAppGroup) {
      return;
    }

    if (selectedAppGroup.spec) {
      if (selectedAppGroup.spec?.match?.length) {
        setMatchResources(transformResources(selectedAppGroup.spec.match[0]));
      }
      if (selectedAppGroup.spec?.exclude?.length) {
        setExcludeResources(transformResources(selectedAppGroup.spec.exclude[0]));
      }
    }
  };

  useEffect(() => {
    if (selectedAppGroup) {
      transformSelectedAppGroup();
    }
  }, [selectedAppGroup]);

  const closeModal = () => {
    onModalHidden && onModalHidden(false);
  };

  const displayTags = (data: string[], isMatch = true) =>
    data && data.length > 0
      ? data.map((name) => (
          <Tag
            hollow={false}
            fontSize={'1rem'}
            isRemovable={false}
            tagClassNames={isMatch ? '' : 'exclude-tag'}
          >
            {name}
          </Tag>
        ))
      : null;

  const appGroupSpecs = useMemo(
    () => Object.keys(APP_GROUP_SPECS).map((key) => key as keyof IAppGroupSpec),
    [],
  );

  const hasResources = (resources: IAppGroupSpec) => {
    if (!resources) {
      return;
    }

    const keys = Object.keys(resources);

    for (const key of keys) {
      if (resources[key as keyof IAppGroupSpec]?.length) {
        return true;
      }
    }

    return false;
  };

  const hasMatchResources = useMemo(() => hasResources(matchResources), [matchResources]);
  const hasExcludeResources = useMemo(() => hasResources(excludeResources), [excludeResources]);

  if (!selectedAppGroup) {
    return <></>;
  }

  return (
    <Modal show={isModalShown} onHide={closeModal} className="view-app-group-modal">
      <Modal.Body className="pt-0">
        <div className="view-app-group-modal-title-container">
          <h3 className="modal-title">{selectedAppGroup.name}</h3>
          <CloseIcon
            width="0.75rem"
            height="0.75rem"
            onClick={closeModal}
            className="view-app-group-modal-close-icon"
          />
        </div>
        {/* Match Resources */}
        {hasMatchResources ? (
          <div className="match-resources">
            <div className="match-resources-title">
              <span>{strings.appGroups.matchResources}</span>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id={`tooltip-match-resources`}>
                    <Popover.Content>{strings.appGroups.matchResourcesTooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
            {hasMatchResources ? (
              <div className="match-resources-body">
                {appGroupSpecs.map((key) => (
                  <div className="match-resources-item">
                    <div className="match-resources-item-title">
                      <span>{APP_GROUP_SPECS[key]}</span>
                    </div>
                    <div className="match-resources-item-content">
                      {displayTags(matchResources[key] || [])}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
        {/* End Match Resources */}
        {/* Exclude Resources */}
        {hasExcludeResources ? (
          <div className="exclude-resources">
            <div className="exclude-resources-title">
              <span>{strings.appGroups.excludeResources}</span>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id={`tooltip-exclude-resources`}>
                    <Popover.Content>{strings.appGroups.excludeResourcesTooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon />
              </OverlayTrigger>
            </div>
            <div className="exclude-resources-body">
              {appGroupSpecs.map((key) => (
                <div className="exclude-resources-item">
                  <div className="exclude-resources-item-title">
                    <span>{APP_GROUP_SPECS[key]}</span>
                  </div>
                  <div className="exclude-resources-item-content">
                    {displayTags(excludeResources[key] || [], false)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {/* End Exclude Resources */}
      </Modal.Body>
    </Modal>
  );
};

export default ViewAppGroupModal;
