import React from 'react';
import { strings } from '~utils/strings';

export const TABLE_COLUMNS = (display: string) => [
  {
    Header: strings.Name,
    accessor: 'name',
    defaultCanSort: true,
    Cell: ({ value }: { value: string }) => {
      return <div className="name-cell">{value}</div>;
    },
    minWidth: 190,
    width: 190,
    maxWidth: 350,
  },
  {
    Header: strings.Role,
    accessor: 'role',
    defaultCanSort: true,
    Cell: ({ value }: { value: string }) => {
      return <div className="role-cell">{value}</div>;
    },
    minWidth: 50,
    width: 100,
    maxWidth: 150,
  },
  {
    Header: strings.InstanceType,
    accessor: 'instanceType',
    defaultCanSort: true,
    Cell: ({ value }: { value: string }) => {
      return <div className="type-cell">{value}</div>;
    },
    minWidth: 50,
    width: 100,
    maxWidth: 150,
  },
  {
    Header: strings.general.Available,
    accessor: 'available',
    defaultCanSort: true,
    Cell: ({ value }: { value: number }) => {
      return <div className="available-cell">{`${value} ${display === 'cpu' ? 'CPU' : ''}`}</div>;
    },
    minWidth: 50,
    width: 100,
    maxWidth: 150,
  },
  {
    Header: strings.Requested,
    accessor: 'requests',
    defaultCanSort: true,
    Cell: ({ value }: { value: number }) => {
      return <div className="requests-cell">{`${Math.round(value)}%`}</div>;
    },
    minWidth: 50,
    width: 100,
    maxWidth: 150,
  },
  {
    Header: strings.general.Limits,
    accessor: 'limits',
    defaultCanSort: true,
    Cell: ({ value }: { value: number }) => {
      return <div className="limits-cell">{`${Math.round(value)}%`}</div>;
    },
    minWidth: 50,
    width: 50,
    maxWidth: 50,
  },
];
