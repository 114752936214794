import React, { useState, useEffect, SetStateAction } from 'react';
import clsx from 'clsx';

import { Card } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import CheckCircleIcon from '../../Icons/CheckCircle.react';
import CheckFillIcon from '../../Icons/CheckFillCircle.react';

import { Check, Standards } from '~views/organization/compliance/Compliance.types.react';
import {
  formatStatus,
  standardizedReports,
} from '~views/organization/compliance/Compliance.helpers.react';

import ComplianceBadge from '~reactComponents/ComplianceBadge/ComplianceBadge.react';
import { DataItem } from '~reactComponents/ComplianceBadge/ComplianceBadge.types.react';
import { IGNORE_STANDARDS } from './ComplianceCard.types.react';

import { Labels } from '~views/organization/compliance/Compliance.types.react';

import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { CardTitleDefault, MediumBasicText } from '~utils/texts.react';

import './ComplianceCard.react.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

type ComplianceCardProps = {
  check: Check;
  reportStandards: string[];
  setIsAutomatedModalShown: React.Dispatch<SetStateAction<boolean>>;
  setModal: React.Dispatch<SetStateAction<boolean>>;
  setSelectedCheck: React.Dispatch<SetStateAction<Check | null>>;
};

const ComplianceCard = ({
  check,
  setIsAutomatedModalShown,
  setModal,
  setSelectedCheck,
}: ComplianceCardProps): JSX.Element => {
  const [standards, setStandards] = useState<DataItem[] | null>(null);

  const timeZone = getCurrentTimezone();

  useEffect(() => {
    getStandards(check.standards);
  }, [check]);

  const getStandards = (standards: Standards[]) => {
    if (!standards?.length) {
      return;
    }
    const transformedStandards = [];
    for (const standard of standards) {
      if (IGNORE_STANDARDS.includes(standard?.name)) continue;
      transformedStandards.push({
        label: standardizedReports(standard?.name),
        value: String(standard?.codes?.length || 0),
      });
    }
    setStandards(transformedStandards);
  };

  const openAssessmentModal = () => {
    setSelectedCheck(check);
    if (check?.mode === Labels.automated) {
      setIsAutomatedModalShown(true);
    } else {
      setModal(true);
    }
  };

  const isImplemented = ([Labels.implemented, Labels.passing] as string[]).includes(check.status);

  return (
    <Card className="check-card" onClick={openAssessmentModal}>
      <Card.Body>
        <Card.Title
          className={CardTitleDefault({
            weight: strings.textStyling.regular,
            bottomMargin: strings.textStyling.xsBottom,
          })}
        >
          <div className="left-title">
            <>
              {isImplemented ? (
                <CheckFillIcon fill={COLORS.CORE.SUCCESS.DEFAULT} />
              ) : (
                <CheckCircleIcon fill={COLORS.CORE.GRAY} />
              )}
              <div className="title-container">
                <span className={isImplemented ? 'implemented-title' : ''}>{check.title}</span>
                <Card.Subtitle className={clsx({ 'implemented-subtitle': isImplemented })}>
                  {check.category}
                </Card.Subtitle>
              </div>
            </>
          </div>
          <div className="right-title">
            {check?.mode === Labels.automated && (
              <Badge pill className="compliance-automated-badge">
                {`${Labels.automated}`}
              </Badge>
            )}
            <Badge
              pill
              className={`compliance-status-badge compliance-status-badge--${check.status}`}
            >
              {formatStatus(check.status)}
            </Badge>
          </div>
        </Card.Title>
        <div className="compliance-badge">
          <ComplianceBadge data={standards} />
          {check.selfAssessedAt && check.selfAssessedBy ? (
            <div className="updated-info">
              <span className="updated-info--bold">{strings.general.lastUpdated}</span>
              <span>
                {`${
                  timeZone
                    ? dayjs(check.selfAssessedAt).utc().tz(timeZone).format('LL')
                    : dayjs(check.selfAssessedAt).utc().format('LL')
                } ${strings.general.at} 
                ${
                  timeZone
                    ? dayjs(check.selfAssessedAt).utc().tz(timeZone).format('LT')
                    : dayjs(check.selfAssessedAt).utc().format('LT')
                } ${strings.general.by} `}
                <span className="updated-info--bold">
                  {check.selfAssessedBy.firstName && check.selfAssessedBy.lastName
                    ? `${check.selfAssessedBy.firstName} ${check.selfAssessedBy.lastName}`
                    : `${check.selfAssessedBy.email}`}
                </span>
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ComplianceCard;
