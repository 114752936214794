import { ActionItemReportData, ActionItemReportDataObject } from '~utils/global.types.react';

import { convertJsonToCsv, downloadCsv } from '~utils/helpers';

type CreateCSVFileParams = {
  data: ActionItemReportData[];
  org: string;
  statuses: string[];
};

const formatActionItemReportData = (data: ActionItemReportData[], statuses: string[]) => {
  if (!data?.length || !statuses?.length) {
    return [];
  }

  const formattedActionItemReportData = [];

  for (const reportData of data) {
    if (!reportData) {
      continue;
    }
    for (const status of statuses) {
      const formattedData = {
        dates: reportData.time,
        status: status,
        events: reportData.period[status as keyof ActionItemReportDataObject],
        cumulative: reportData.cumulative[status as keyof ActionItemReportDataObject],
      };
      formattedActionItemReportData.push(formattedData);
    }
  }
  return formattedActionItemReportData;
};

const getCSVFilename = (orgName: string): string => `action_items_reports_${orgName}.csv`;

export const createCSVFile = async ({ data, org, statuses }: CreateCSVFileParams) => {
  downloadCsv(
    await convertJsonToCsv(formatActionItemReportData(data, statuses), ['dates', 'events', 'cumulative', 'status']),
    getCSVFilename(org),
  );
};
