import React, { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import toast, { Toaster } from 'react-hot-toast';
import { sendRequest } from '~utils/request';
import { IStore, IRoute, IRouter, Team, OptionType } from '~globalTypes';
import DeleteModal from '~reactComponents/DeleteModal/DeleteModal.react';
import logger from '~logger';
import Icon from '~reactIcons/Icon.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';
import { handlePageChange } from '~utils/global.helpers.react';

import { COLORS } from '~utils/styling';
import { ORG_DASHBOARD, TEAM_MANAGEMENT } from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';

import './ssoPage.react.scss';

type SSOProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

type InputData = {
  url: string;
  domain: string;
  strict: boolean;
  defaultTeamID: number;
  defaultRole: string;
};

type SAMLDomain = {
  SAMLIntegrationID: number;
  EmailDomain: string;
};

type SAMLIntegration = {
  ID: number;
  Organization: string;
  MetadataURL: string;
  RedirectURL: string | null;
  Metadata: string;
  Domains: SAMLDomain[];
  EntityID: string;
  DefaultTeamID?: number;
  DefaultRole?: string;
};

const validationSchema = Joi.object({
  url: Joi.string()
    .uri({
      scheme: [/https?/],
    })
    .required()
    .messages({
      'string.empty': 'A URL is required',
    }),
  domain: Joi.string().required().messages({
    'string.empty': 'An email domain is required',
  }),
  strict: Joi.boolean(),
  defaultTeamID: Joi.any(),
  defaultRole: Joi.any(),
});

const SsoPage = ({ route, store, router }: SSOProps) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const roles = useRef<OptionType[]>([
    { label: strings.general.admin, value: strings.general.admin },
    { label: strings.general.editor, value: strings.general.editor },
    { label: strings.general.viewer, value: strings.general.viewer },
  ]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [id, setID] = useState<number>(0);
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    reset,
    setError,
  } = useForm<InputData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      url: '',
      domain: '',
      strict: false,
    },
  });
  const { isOrgOwner } = store().getters;
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;

  useEffect(() => {
    const getSAMLData = async () => {
      try {
        const samlData = (await sendRequest('GET', `${baseURL}/saml`, {}, null)) as SAMLIntegration;
        if (!samlData) {
          return;
        }

        setID(samlData.ID);
        const strictOrgs = store().getters.strictSAMLOrgs;
        const matchingOrg = strictOrgs.find((strictOrg) => strictOrg === org);
        reset({
          url: samlData.MetadataURL || '',
          domain: samlData.Domains?.map((domain) => domain.EmailDomain).join(', ') || '',
          strict: Boolean(matchingOrg),
          defaultTeamID: samlData.DefaultTeamID || undefined,
          defaultRole: samlData.DefaultRole || undefined,
        });
      } catch (e) {
        if (e.status === 404) {
          return;
        }

        if (e.status === 403) {
          toast.error(e.message);
          logger.logError('error_permission_saml_info', e);
          return;
        }

        toast.error(e.message);
        logger.logError('error_retrieving_saml_info', e);
      }
    };

    const getTeams = async () => {
      try {
        setTeams((await sendRequest('GET', `${baseURL}/teams`, {}, null)) || []);
      } catch (e) {
        logger.logError('error_getting_teams_team-management', e);
      }
    };

    getSAMLData();
    getTeams();
  }, [reset]);

  const handleOnSubmit = async (inputs: InputData) => {
    // if strict checkbox has changed and is true
    if (dirtyFields.strict && inputs.strict && !showModal) {
      setShowModal(true);
      return;
    }
    try {
      if (dirtyFields.domain || dirtyFields.url || dirtyFields.defaultTeamID || dirtyFields.defaultRole) {
        await setSAMLChanges(inputs);
      }
      // Handle the case where they want to disable strict provisioning
      if (dirtyFields.strict && !showModal) {
        await updateStrictProvisioning(inputs.strict);
      }
      // If the user is validating strict provisioning from the modal
      if (showModal) {
        await updateStrictProvisioning(inputs.strict);
        setShowModal(false);
      }
    } catch (e) {
      toast.error(e.message);
      return;
    }
    if (isValid) {
      toast.success(<b>SAML settings were updated successfully</b>);
    }
  };

  const setSAMLChanges = async (inputs?: InputData) => {
    const transformedDomains = transformDomains(inputs?.domain);
    if (!transformedDomains?.length) {
      return;
    }
    const data = {
      MetadataURL: inputs?.url,
      Domains: transformedDomains,
      DefaultTeamID: inputs?.defaultTeamID ? parseInt(inputs.defaultTeamID.toString()) : undefined,
      DefaultRole: inputs?.defaultRole ? inputs.defaultRole : undefined,
    };
    try {
      await sendRequest('POST', `${baseURL}/saml`, { data }, null);
    } catch (e) {
      logger.logError('error_adding_sso', e);
      throw e;
    }
  };

  const transformDomains = (domain: string | undefined) => {
    if (!domain) {
      return [];
    }
    const splitDomains = domain.replaceAll(/\s/g, '').split(',');
    return hasInvalidDomain(splitDomains) ? [] : splitDomains.map((EmailDomain: string) => ({ EmailDomain }));
  };

  const hasInvalidDomain = (splitDomains: string[]) => {
    for (const domain of splitDomains) {
      if (!isValidDomain(domain)) {
        return true;
      }
    }
    return false;
  };

  const isValidDomain = (domain: string) => {
    const result = Joi.string().domain({ tlds: false }).validate(domain);
    if (result.error || !domain) {
      setError('domain', { type: 'custom', message: 'Invalid domain(s)' });
      return false;
    }
    return true;
  };

  const updateStrictProvisioning = async (strict: boolean) => {
    try {
      await sendRequest('POST', `${baseURL}/sso-strict`, { data: { Enabled: strict } }, null);
    } catch (e) {
      logger.logError('error_setting_sso_strict', e);
      throw e;
    }
    setShowModal(false);
  };

  const removeSSOIntegration = async () => {
    try {
      await sendRequest('DELETE', `${baseURL}/saml/${id}`, {}, null);
      setShowDeleteModal(false);
    } catch (e) {
      logger.logError('error_removing_sso_integration', e);
      toast.error(<b>There was an error deleting your SSO integration</b>);
      throw e;
    }
    toast.success(<b>SSO integration successfully deleted</b>);
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${org}/settings`,
    },
    {
      id: 'last',
      label: strings.settings.sso.singleSignOn,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="sso-settings">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      {!isOrgOwner ? (
        <Alert variant="warning" className="warning-alert">
          <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
          <h4 className="warning-title">
            Only owners are allowed to modify Single Sign-On settings. <strong>This page is read-only.</strong>
          </h4>
        </Alert>
      ) : null}
      <h2 className="sso-settings-details-title">SSO Details</h2>
      <span className="sso-settings-details-subtitle">
        Information needed to set up SAML. For more info read the docs.
      </span>
      <br />
      <a href="https://insights.docs.fairwinds.com/installation/sso/sso/" target="_blank">
        Learn More
      </a>
      <div className="sso-settings-details">
        <div className="sso-settings-details-content">
          <strong>ACS URL:</strong>
          <span className="sso-settings-details-content-url">{`https://insights.fairwinds.com/v0/organizations/${org}/auth/saml`}</span>
        </div>
        <div className="sso-settings-details-content">
          <strong>Entity ID:</strong>
          <span className="sso-settings-details-content-id">fairwinds-insights</span>
        </div>
      </div>
      <h2 className="sso-settings-metadata-title">Additional Metadata</h2>
      <Form className="sso-settings-form" onSubmit={handleSubmit(handleOnSubmit)}>
        <fieldset disabled={!isOrgOwner}>
          <Form.Group controlId="formURL">
            <Form.Label>Metadata URL</Form.Label>
            <Form.Control
              type="text"
              placeholder="URL"
              className={errors.url ? 'sso-settings-form-control is-invalid' : 'sso-settings-form-control'}
              {...register('url', { required: true })}
              data-cy="metadata-url-text-input"
            />
            <Form.Control.Feedback type="invalid">{errors?.url?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email Domain(s) (Comma-Separated)</Form.Label>
            <Form.Control
              type="text"
              placeholder="Email Domain(s)"
              className={errors.domain ? 'sso-settings-form-control is-invalid' : 'sso-settings-form-control'}
              {...register('domain', { required: true })}
              data-cy="email-domain-text-input"
            />
            <Form.Control.Feedback type="invalid">{errors?.domain?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formDefaultTeamID">
            <Form.Label>Default Team</Form.Label>
            <Form.Control
              as="select"
              className={'sso-settings-form-control'}
              data-cy="default-team-select-input"
              {...register('defaultTeamID', { required: false })}
            >
              <option value="">Optional Default Team</option>
              {teams.map((team) => (
                <option key={team.ID} value={team.ID}>
                  {team.Name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formDefaultRole">
            <Form.Label>Default Role</Form.Label>
            <Form.Control
              as="select"
              className={'sso-settings-form-control'}
              data-cy="default-role-select-input"
              {...register('defaultRole', { required: false })}
            >
              <option value="">Optional Default Role</option>
              {roles.current.map((role) => (
                <option key={role.value} value={role.value}>
                  {role.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <div className="sso-settings-form-strict">
            <Form.Group controlId="formStrictCheckbox">
              <Form.Check type="checkbox" label="Enable strict provisioning" {...register('strict')} />
            </Form.Group>
            <span className="sso-settings-form-strict-subtitle">
              If you have strict provisioning set, users must go through a third-party app to login.
            </span>
          </div>
          <div className="sso-settings-buttons">
            <Button variant="danger" onClick={() => setShowDeleteModal(true)} data-cy="delete-sso-button">
              Delete SSO
            </Button>
            <Button variant="primary" type="submit" data-cy="update-sso-button">
              Update SSO
            </Button>
          </div>
        </fieldset>
      </Form>
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          page="SSO Integration"
          text="Are you sure you want to remove your SSO integration?"
          deleteFunction={removeSSOIntegration}
        />
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Strict Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Confirm that your organization's SAML is working correctly or else you will be logged out of your account.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleSubmit(handleOnSubmit)()}>
            Update SSO
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </div>
  );
};

export default SsoPage;
