import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import { REACT_REPOSITORY } from '~reactComponents/NavigationReact/Navigation.config.react';

import store from '~store/index';

import { Activity, Labels } from './RepoActivities.types.react';
import { CodeScan, Organization, Repository, OptionType, IRouter } from '~globalTypes';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { SET_REPOSITORY } from '~store/action.types';
import { getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import { CardTitlePrimary, XSCardTitle, SmallCardTitle } from '~utils/texts.react';

import './RepoActivities.react.scss';

dayjs.extend(timezone);

type RepoActivitiesProps = {
  organization: Organization;
  repositories: Repository[];
  repository?: Repository;
  selectedBranch?: OptionType;
  router?: () => IRouter;
};

const RepoActivities = ({
  organization,
  repositories,
  repository,
  selectedBranch,
  router,
}: RepoActivitiesProps) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  const baseURL = `/v0/organizations/${organization.Name}`;
  const timeZone = getCurrentTimezone();

  useEffect(() => {
    initActivities();
  }, [organization, repository, selectedBranch]);

  const initActivities = async () => {
    try {
      const transformedActivities = transformActivities(await getActivities());
      setActivities(transformedActivities);
    } catch (e) {
      logger.logError('error_repo_activities', e);
    }
  };

  const transformActivities = (activities: CodeScan[]): Activity[] => {
    if (!activities?.length) return [];
    return activities.map((activity: CodeScan) => buildTransformedActivityObj(activity));
  };

  const buildTransformedActivityObj = (activity: CodeScan): Activity => ({
    title: `${activity?.Repository?.Name} ${activity?.BranchName}`,
    date: timeZone
      ? dayjs(activity?.CreatedAt).tz(timeZone).format('MM/DD/YYYY')
      : dayjs(activity?.CreatedAt).format('MM/DD/YYYY'),
    time: timeZone
      ? dayjs(activity?.CreatedAt).tz(timeZone).format('h:mm A')
      : dayjs(activity?.CreatedAt).format('h:mm A'),
    ID: activity.ID,
    commitHash: activity.CommitHash,
    repoId: activity?.Repository?.ID,
  });

  const getActivities = async () => {
    try {
      setLoaded(false);
      const activities = await sendRequest('GET', getRequestURL(), {}, null);
      setLoaded(true);
      return activities;
    } catch (e) {
      logger.logError('error_repo_activities', e);
    }
  };

  const getRequestURL = () => {
    if (repository && selectedBranch) {
      const params = new URLSearchParams(
        `repository=${repository.Name}&branch=${selectedBranch.value}`,
      );
      return `${baseURL}/ci/repositories/scan-results?${params}`;
    }
    return `${baseURL}/ci/scan-results`;
  };

  const selectCommit = (activity: Activity) => () => {
    if (!activity) {
      return;
    }
    const selectedRepository = repositories?.find(
      (repository) => repository.ID === activity.repoId,
    );
    if (selectedRepository) {
      store.commit(SET_REPOSITORY, selectedRepository);
      router &&
        router().push({
          name: REACT_REPOSITORY,
          params: {
            org: organization.Name,
            repo: selectedRepository.Name,
          },
          query: {
            commitHash: activity.commitHash,
          },
        });
    }
  };

  return (
    <Card data-cy="repo-latest-activities" className="repo-latest-activities expanded">
      <Card.Body
        style={{ padding: strings.noTranslate.repoPadding }}
        className="repo-latest-activities__card-body"
        padded
      >
        <div>
          <Card.Header>
            <h2 className={CardTitlePrimary({ weight: strings.textStyling.regular })}>
              {Labels.LatestScans}
            </h2>
          </Card.Header>
          {loaded ? (
            <div className="repo-latest-activities_body">
              <ul>
                {activities.map((activity: Activity, idx: number) => {
                  return (
                    <li className="updated" key={idx}>
                      <div className="activity">
                        <div
                          className={clsx(
                            'activity-type__bold',
                            SmallCardTitle({ color: strings.textStyling.default }),
                          )}
                        >
                          <span>{activity.title}:</span>
                          <Button
                            variant="link"
                            className="activity-commit-hash"
                            onClick={selectCommit(activity)}
                            title={activity.commitHash}
                          >
                            {activity.commitHash.slice(0, 7)}
                          </Button>
                        </div>
                        <div className={XSCardTitle()}>
                          {activity.date} at {activity.time}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <div className="repo-latest-activities__loading-container">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default RepoActivities;
