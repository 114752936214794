import React from 'react';
import { Row } from 'react-table';
import Select from 'react-select';

import UserAvatar from '~reactComponents/UserAvatar/UserAvatar.react';

import { AVATAR_COLORS } from '~utils/constants';
import { CurrentUser, OptionType, TeamMembership } from '~utils/global.types.react';
import { randomAvatarColor } from '~utils/helpers';
import { strings } from '~utils/strings';

const chooseAvatarColor = (content: string) => randomAvatarColor(content, AVATAR_COLORS);

export type TeamAccountTableData = TeamMembership & {
  ID?: string;
  Role?: string;
  isAddingNewMember?: boolean;
};

type getAccountTableColumnsParams = {
  onMembershipRemoved: (email: string, ID?: string) => void;
  onNewRoleAdded: (selectedRole: string, ID: string) => void;
  onRoleChanged: (email: string, selectedRole: string) => void;
  onEmailSelected: (email: string, ID: string) => void;
  user: CurrentUser;
  isOrgOwner: boolean;
  roles: OptionType[];
  nonMembershipEmails: OptionType[];
};

export const getAccountTableColumns = ({
  onMembershipRemoved,
  onNewRoleAdded,
  onRoleChanged,
  onEmailSelected,
  user,
  isOrgOwner,
  roles,
  nonMembershipEmails,
}: getAccountTableColumnsParams) => [
  {
    Header: strings.teamManagement.name,
    accessor: 'Name',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      if (row.original.IsAddingNewMember && !row.original.Email) {
        return (
          <Select
            onChange={(option) => {
              if (option) {
                onEmailSelected(option.value, row.original.ID);
              }
            }}
            options={nonMembershipEmails}
            isSearchable={false}
            classNamePrefix="select-team-modal__member-email"
          />
        );
      }

      const splitedName = row.original.Name;

      const firstName = splitedName[0].charAt(0);
      const lastName = splitedName[1].charAt(0);

      return (
        <div className="team-accounts-table__name-container">
          <UserAvatar
            firstName={firstName}
            lastName={lastName}
            backgroundColor={chooseAvatarColor(`${firstName} ${lastName}`)}
          />
          <span title={value}>{value}</span>
        </div>
      );
    },
    minWidth: 250,
  },
  {
    Header: strings.teamManagement.email,
    accessor: 'Email',
    Cell: ({ value }: { value: string }) => {
      return <span title={`${value}`}>{value}</span>;
    },
    minWidth: 350,
  },
  {
    Header: strings.teamManagement.role,
    accessor: 'Role',
    Cell: ({ row }: { row: Row }) => {
      return (
        <Select
          onChange={(option) => {
            if (!option) {
              return;
            }

            if (row.original.ID) {
              onNewRoleAdded(option.value, row.original.ID);
              return;
            }

            onRoleChanged(row.original.Email, option.value);
          }}
          options={roles}
          defaultValue={roles.find((role) => role.value === row.original.Role)}
          isSearchable={false}
          classNamePrefix="select-team-modal__member-role"
          menuPortalTarget={document.body} // renders the dropdown outside of the container
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // high z-index for overlaying
          }}
        />
      );
    },
    minWidth: 350,
  },
  {
    Header: strings.teamManagement.action,
    Cell: ({ row }: { row: Row }) => {
      return !isOrgOwner ? null : (
        <span
          className="team-accounts-table__remove"
          onClick={() => onMembershipRemoved(row.original.Email, row.original.ID)}
        >
          {strings.teamManagement.remove}
        </span>
      );
    },
    minWidth: 50,
  },
];
