import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import clsx from 'clsx';

import DonutChart from '~reactComponents/charts/DonutChart/DonutChart.react';

import { Summary } from '~views/organization/compliance/Compliance.types.react';
import { KeyPositions, Labels } from './ReportProgress.types.react';

import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { CardTitlePrimary, MediumBasicText } from '~utils/texts.react';

import './ReportProgress.react.scss';

type ProgressProp = {
  summary: Summary;
};

const OverallProgressCard = ({ summary }: ProgressProp): JSX.Element => {
  const ignoreKeys = [Labels.notImplemented, Labels.implemented] as string[];
  const getNameAndColor = (key: string) => {
    if (ignoreKeys.includes(key)) {
      return null;
    }
    let currentKey = '';
    let color = '';
    switch (key) {
      case Labels.passing:
      case Labels.implemented:
        currentKey = strings.checkStatus.passing;
        color = COLORS.CORE.SUCCESS.DEFAULT;
        break;
      case Labels.needsEvidence:
        currentKey = strings.checkStatus.needsEvidence;
        color = COLORS.SEVERITY.MEDIUM;
        break;
      case Labels.notStarted:
      case Labels.notImplemented:
        currentKey = strings.checkStatus.notStarted;
        color = COLORS.SEVERITY.NONE;
        break;
      case Labels.notInScope:
        currentKey = strings.checkStatus.notInScope;
        color = COLORS.SEVERITY.LOW;
        break;
      default:
        currentKey = strings.checkStatus.failing;
        color = COLORS.SEVERITY.CRITICAL;
        break;
    }
    return { label: currentKey, color };
  };

  const formatPieData = useMemo(() => {
    const summaryKeys: string[] = Object.keys(summary);
    const filteredKeys = summaryKeys.filter((key) => summary[key as keyof Summary] > 0);
    const formattedData = [];
    for (const key of filteredKeys) {
      if (ignoreKeys.includes(key)) continue;
      const nameAndColor = getNameAndColor(key);
      if (hasKey(summary, key) && nameAndColor) {
        formattedData.push({
          id: nameAndColor.label,
          label: nameAndColor.label,
          value: summary[key],
          color: nameAndColor.color,
        });
      }
    }
    return formattedData;
  }, [summary]);

  const centeredMetric = useMemo(() => {
    const total =
      (summary?.notStarted || 0) +
      (summary?.passing || 0) +
      (summary?.needsEvidence || 0) +
      (summary?.notInScope || 0) +
      (summary?.failing || 0);

    return summary.passing > 0 && total > 0
      ? `${Math.round(((summary?.passing || 0) / total) * 100)}%`
      : '0%';
  }, [summary]);

  const DisplayLegend = () => {
    const summaryKeys: string[] = summary ? sortSummaryKeys(Object.keys(summary)) : [];
    const filteredLegend = summaryKeys.map((summaryKey) => {
      if (ignoreKeys.includes(summaryKey)) return <></>;
      const nameAndColor = getNameAndColor(summaryKey);
      if (hasKey(summary, summaryKey) && nameAndColor) {
        return (
          <div
            className={clsx(
              'legend-item',
              MediumBasicText({
                textTransform: strings.textStyling.capitalize,
                weight: strings.textStyling.regular,
              }),
            )}
            key={summaryKey}
          >
            <div className="legend-count">
              <strong>{summary[summaryKey]}</strong>
            </div>
            <div className="legend-text">
              <span>{nameAndColor.label}</span>
            </div>
          </div>
        );
      }
      return <></>;
    });
    return <>{filteredLegend}</>;
  };

  const sortSummaryKeys = (summaryKeys: string[]) => {
    if (!summaryKeys?.length) return [];
    const sorted = [];
    for (const key of summaryKeys) {
      sorted[KeyPositions[key]] = key;
    }
    return sorted;
  };

  return (
    <Card className="overall-progress-card">
      <Card.Body>
        <Card.Title>
          <h6 className={CardTitlePrimary({ textTransform: strings.textStyling.capitalize })}>
            {strings.overallProgress}
          </h6>
        </Card.Title>
        <DonutChart
          data={summary ? formatPieData : []}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          label={strings.ariaLabels.reportDonutChart}
          centerInfo={centeredMetric}
        />
        <div className="legend">
          <DisplayLegend />
        </div>
      </Card.Body>
    </Card>
  );
};

export default OverallProgressCard;
