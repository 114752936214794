import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import Icon from '../../Icons/Icon.react';

import { Organization, IStore, IRouter } from '~globalTypes';
import { sendRequest, getCurrentUser } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~logger';
import { NEW_ORG } from '~reactComponents/NavigationReact/Navigation.config.react';

import { UPDATE_ORG, UPDATE_MEMBERSHIPS } from '~store/action.types';

import './DeleteOrg.react.scss';

type DeleteOrgProps = {
  organization: Organization;
  isOrgOwner: boolean;
  store: () => IStore;
  router: () => IRouter;
};

const DeleteOrg = ({ organization, isOrgOwner, store, router }: DeleteOrgProps) => {
  const [isDeleteOrgModalShown, setIsDeleteOrgModalShown] = useState<boolean>(false);

  const baseUrl = `/v0/organizations/${organization.Name}`;

  const deleteOrganization = async () => {
    try {
      await sendRequest('DELETE', baseUrl, {}, null);
      const results = await getCurrentUser();
      let routeParams = { name: NEW_ORG };
      if (results.memberships?.length) {
        const org = results.memberships[0].Organization;
        await store().dispatch(UPDATE_ORG, org);
        routeParams = { name: 'default-org', params: { org } } as any;
      }
      localStorage.removeItem(strings.localStorage.organization);
      await store().dispatch(UPDATE_MEMBERSHIPS, results);
      router().push(routeParams);
    } catch (e: any) {
      logger.logEvent('event_delete_organization', { message: e.message });
    }
  };

  return (
    <div className="delete-org">
      <h2>{`${strings.Delete} ${organization.Name} ${strings.general.Organization}`}</h2>
      <Modal show={isDeleteOrgModalShown} onHide={() => setIsDeleteOrgModalShown(false)}>
        <Modal.Body className="pt-0 cluster-delete-modal">
          <h3 className="modal-title">{`${strings.confirmOrgDeletion} ${organization.Name}`}</h3>
          <p>
            {strings.deleteOrgExplanation1.replace('orgName', organization.Name)}
            <a href="mailto:insights@fairwinds.com">insights@fairwinds.com</a>{' '}
            {strings.deleteOrgExplanation2}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn" variant="primary" onClick={() => setIsDeleteOrgModalShown(false)}>
            {strings.Cancel}
          </Button>
          <Button className="btn btn-danger" type="button" onClick={deleteOrganization}>
            {`${strings.deleteOrg} ${organization.Name}`}
          </Button>
        </Modal.Footer>
      </Modal>
      {isOrgOwner && (
        <div>
          <Button
            className="delete-btn"
            variant="danger"
            onClick={() => setIsDeleteOrgModalShown(true)}
          >
            <Icon name="trash" className="delete-icon delete-org-delete-icon" />
            <span>{strings.deleteOrg}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default DeleteOrg;
