import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { Row, TableInstance } from 'react-table';
import { ContextMenuParams, TriggerEvent } from 'react-contexify';

import PaginationComponent from '~reactComponents/pagination/pagination.react';
import ContextMenu from '../ContextMenu/ContextMenu.react';

import { ActionItem, ModalInfo, Member } from '../../ActionItems.types.react';

import { IRouter, IRoute } from '~globalTypes';
import logger from '~utils/logger';
import { strings } from '~utils/strings';

import './Table.react.scss';

type TableProps = {
  setSelectedFlatRows: React.Dispatch<React.SetStateAction<Row<ActionItem>[]>>;
  pageSizeCount: number;
  totalRowCount: number;
  fetchActionItemsDebounced: () => void;
  tableInstance: TableInstance<ActionItem>;
  members: Member[];
  baseURL: string;
  showContextMenu: (
    event: TriggerEvent,
    params?: Pick<ContextMenuParams, 'id' | 'props' | 'position'> | undefined,
  ) => void;
  route: IRoute;
  router: () => IRouter;
  setSelectedActionItem: React.Dispatch<React.SetStateAction<ActionItem | null>>;
  setShowModal: React.Dispatch<React.SetStateAction<ModalInfo>>;
  loaded?: boolean;
  caption: string;
  setShowViewMode: React.Dispatch<React.SetStateAction<boolean>>;
  isOwner: boolean;
};

const Table = ({
  setSelectedFlatRows,
  pageSizeCount,
  fetchActionItemsDebounced,
  totalRowCount,
  tableInstance,
  showContextMenu,
  members,
  baseURL,
  route,
  router,
  setSelectedActionItem,
  setShowModal,
  loaded,
  caption,
  setShowViewMode,
  isOwner,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, filters, sortBy },
  } = tableInstance;

  useEffect(() => {
    setPageSize(pageSizeCount);
  }, [pageSizeCount]);

  useEffect(() => {
    setSelectedFlatRows(selectedFlatRows);
  }, [selectedFlatRows]);

  useEffect(() => {
    fetchActionItemsDebounced();
  }, [fetchActionItemsDebounced, pageIndex, sortBy, pageSize, filters]);

  const handlePaginationChange = (page: number): void => {
    gotoPage(page);
  };

  const handleOpenTicketModal = (actionItem: ActionItem) => {
    setShowViewMode(false);
    if (actionItem.TicketLink) {
      setShowViewMode(true);
    }
    if (
      !actionItem.TicketLink &&
      (actionItem.Fixed ||
        actionItem.Resolution === strings.willNotFixResolution ||
        actionItem.Resolution === strings.workingAsAttendedResolution)
    ) {
      toast.error(strings.general.cantCreateTicketForFixed);
      return;
    }
    setShowModal({ show: true, modal: 'ticket', meta: { actionItem } });
  };

  const toggleContextMenu = (e: TriggerEvent, actionItem: ActionItem) => {
    showContextMenu({ event: e, props: { actionItem } });
    // Need to stop the propagation otherwise the row is selected
    window.addEventListener(
      'keydown',
      function (event) {
        event?.stopPropagation();
      },
      true,
    );
  };

  if (!loaded) return <></>;

  return (
    <>
      <table {...getTableProps()} className="action-items-table" aria-label={caption} data-cy="action-items-table">
        <thead role={strings.ariaRoles.rowgroup}>
          {headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()} role="row">
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} role="presentation">
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.id === strings.general.selection ? <span className="sr-only">Select all rows</span> : null}
                    <span>{column.render('Header')}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-arrow-down" />
                        ) : (
                          <i className="fas fa-arrow-up" />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                  <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                  {column.id !== strings.general.selection ? (
                    <div {...column.getResizerProps()} className="draggable-column" />
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  // If we are clicking on anything but the row, return
                  const target = e.target as HTMLElement;
                  if (target?.previousElementSibling) {
                    return;
                  }
                  logger.logEvent(`action-items-table:selected ${row?.original?.Category} category item`);
                  setSelectedActionItem(row.original);
                }}
                className="pointer"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      onContextMenu={(e) => toggleContextMenu(e, row.original)}
                      {...cell.getCellProps([
                        {
                          className: cell.column?.className,
                          style: cell.column?.style,
                        },
                      ])}
                    >
                      <div className="cell-container">{cell.render('Cell')}</div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationComponent
        currentPage={pageIndex}
        pageChange={handlePaginationChange}
        totalCount={Math.ceil(totalRowCount / pageSizeCount)}
      />
      <ContextMenu
        members={members}
        baseURL={baseURL}
        toast={toast}
        router={router}
        route={route}
        handleOpenTicketModal={handleOpenTicketModal}
        tableInstance={tableInstance}
        fetchActionItemsDebounced={fetchActionItemsDebounced}
        setShowModal={setShowModal}
        isEditable={true}
        isOwner={isOwner}
      />
    </>
  );
};

export default Table;
