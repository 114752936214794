import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ModalInfo } from '../../ActionItems.types.react';

export type ConfirmAddToListProps = {
  showModal: ModalInfo;
  closeModal: () => void;
  handleAddToManualList: (listID: number) => void;
  handleAddToQueryList: (listID: number) => void;
  query: string;
};

const ConfirmAddToList = ({
  showModal,
  closeModal,
  handleAddToManualList,
  handleAddToQueryList,
  query,
}: ConfirmAddToListProps): JSX.Element => {
  const handleSubmit = () => {
    if (showModal?.meta?.listType === 'query') {
      handleAddToQueryList(showModal.meta?.listID as number);
    } else {
      handleAddToManualList(showModal.meta?.listID as number);
    }
  };

  const BodyText = () => {
    const listName = showModal.meta?.listName as string;

    if (showModal?.meta?.listType === 'query') {
      return (
        <div>
          <span>
            Are you sure you want to override <strong>{listName}</strong> filters with the following
            filters?
          </span>
          <pre className="mt-2">{query}</pre>
        </div>
      );
    }
    return (
      <span>
        Are you sure you want to add the selected Action Items to <strong>{listName}</strong>?
      </span>
    );
  };

  return (
    <Modal show={showModal.show} onHide={closeModal} style={{ paddingTop: '8rem' }}>
      <Modal.Header closeButton>
        <Modal.Title>Add Action Items to list?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BodyText />
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()} variant="primary">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAddToList;
