import React, { useState, useMemo, SyntheticEvent } from 'react';
import { Alert, Button, Form, FormGroup } from 'react-bootstrap';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm } from 'react-hook-form';
import Joi from 'joi';

import {
  ORG_DASHBOARD,
  CLUSTER_REPORT_HUB,
  CLUSTER_HUB_INSTALL,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { Breadcrumbs } from '@fairwindsops/ui-components';

import { Cluster, IRouter, IStore } from '~globalTypes';

import { UPDATE_ORG } from '~store/action.types';

import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { fixID } from '~utils/helpers';
import { handlePageChange } from '~reactHelpers';
import { PRE_INSTALLED_REPORTS } from '~utils/global.helpers.react';

import './NewCluster.react.scss';

type FormData = { clusterId: string };

type NewClusterProps = {
  router: () => IRouter;
  store: () => IStore;
};

const validationSchema = Joi.object({
  clusterId: Joi.string().trim().replace(/\s/g, '-').required().prefs({ convert: true }),
});

const NewCluster = ({ router, store }: NewClusterProps) => {
  const CLUSTER_ID = 'clusterId';
  const organization = store().getters.organization;
  const isOrgOwner = store().getters.isOrgOwner;
  const clusters = store().getters.clusters;

  const [isCreatingCluster, setIsCreatingCluster] = useState<boolean>(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
  } = useForm<FormData>({
    resolver: joiResolver(validationSchema),
    defaultValues: {
      clusterId: '',
    },
  });

  const onClusterIdKeyUp = (e: SyntheticEvent) => {
    setValue(CLUSTER_ID, fixID((e.target as HTMLInputElement).value));
  };

  const disableButton = useMemo(
    () => !watch(CLUSTER_ID) || watch(CLUSTER_ID) === '-' || watch(CLUSTER_ID).length < 1,
    [watch(CLUSTER_ID)],
  );

  const createNewCluster = async (orgId: string, clusterId: string) => {
    if (!orgId || !clusterId) {
      return null;
    }

    try {
      const cluster = await sendRequest(
        'POST',
        `/v0/organizations/${orgId}/clusters/${clusterId}`,
        {},
        null,
      );

      if (!cluster) {
        return null;
      }

      await store().dispatch(UPDATE_ORG, orgId);

      return cluster;
    } catch (e) {
      if (e) {
        setError(CLUSTER_ID, { message: e.message });
        logger.logError('error_creating_new_cluster', e);
      }
    }
  };

  const isFreeTier = useMemo(() => !organization.Tier, [organization]);

  const installAgents = (orgId: string, cluster: Cluster) => {
    if (!orgId || !cluster || !PRE_INSTALLED_REPORTS?.length) {
      return;
    }

    PRE_INSTALLED_REPORTS.forEach(async (report) => {
      await sendRequest(
        'POST',
        `/v0/organizations/${orgId}/clusters/${cluster.Name}/reports/${report}/configs`,
        { data: {}, showSuccessAlert: false },
        null,
      );
    });
  };

  const goToPage = (orgId: string, cluster: Cluster, routeName: string) => {
    if (!orgId || !cluster || !routeName) {
      return;
    }

    router().push({
      name: routeName,
      query: { setup: true },
      params: { org: orgId, cluster: cluster.Name },
    });
  };

  const createCluster = async (data: FormData) => {
    const orgId = organization.Name;
    const { clusterId } = data;

    if (!orgId || !clusterId) {
      return;
    }

    const cluster = await createNewCluster(orgId, clusterId);

    if (!cluster) {
      return;
    }

    if (isFreeTier) {
      setIsCreatingCluster(true);
      installAgents(orgId, cluster);
      goToPage(orgId, cluster, CLUSTER_HUB_INSTALL);
      return;
    }

    goToPage(orgId, cluster, CLUSTER_REPORT_HUB);
  };

  const isLimited = useMemo(() => clusters.length >= 2, [clusters]);

  const submit = (data: FormData) => {
    if (!data?.clusterId) {
      return;
    }

    createCluster(data);
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: 'last',
      label: strings.general.newCluster,
      href: ``,
      isActive: true,
    },
  ];

  return (
    <div className="container new-cluster">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div>
        <h1>{strings.newCluster.addNewCluster}</h1>
        {isOrgOwner && isFreeTier && isLimited && !isCreatingCluster && (
          <Alert className="warning-alert">
            <h4 className="warning-title">{strings.newCluster.freeTierMessage}</h4>
          </Alert>
        )}
        {!isOrgOwner && (
          <Alert className="warning-alert">
            <span className="warning-title">
              {strings.newCluster.ownerRequired.replace('$organizationName', organization.Name)}
            </span>
          </Alert>
        )}
        {isOrgOwner && (
          <div>
            <p id="hint">{strings.newCluster.subTitle}</p>
            <div className="card-list">
              <div className="card">
                <div className="card-body">
                  <Form onSubmit={handleSubmit((data: FormData) => submit(data))}>
                    <fieldset disabled={!isOrgOwner}>
                      <div className="rule--editor--details">
                        <FormGroup controlId="Name" className="rule--editor--details__Name">
                          <Form.Label>{strings.newCluster.clusterName}</Form.Label>
                          <Form.Control
                            {...register(CLUSTER_ID)}
                            className={errors?.clusterId && 'is-invalid'}
                            onKeyUp={onClusterIdKeyUp}
                            placeholder="Name"
                            type="text"
                            data-cy="cluster-name-text-input"
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors?.clusterId?.message}
                          </Form.Control.Feedback>
                        </FormGroup>
                      </div>
                    </fieldset>
                    <Button
                      type="submit"
                      variant="primary"
                      data-cy="submit-rule-button"
                      disabled={disableButton}
                    >
                      {strings.newCluster.createCluster}
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCluster;
