import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';
import './ProgressBar.react.scss';

type ProgressBarProps = {
  name: string;
  itemTotal: number;
  total: number;
  color: string;
};

const ProgressBarComponent = ({ name, itemTotal, total, color }: ProgressBarProps): JSX.Element => {
  return (
    <div className="progress-div">
      <div className="progress-label">
        <span className={MediumBasicText()}>{name}</span>
        <span>
          <strong>{itemTotal}</strong> {strings.general.of} <strong>{total}</strong>{' '}
          {strings.checksCompleted}
        </span>
      </div>
      <ProgressBar
        variant={color}
        now={itemTotal}
        max={total}
        label={`${itemTotal}%`}
        srOnly
        key={color}
      />
    </div>
  );
};

export default ProgressBarComponent;
