import React from 'react';

import {
  labels,
  colors,
  legendLabels,
  styleById,
  usageLineColors,
} from '../../CumulativeCosts/Components/ResourceUsageChart/ResourceUsage.config.react';
import { Point, Serie } from '@nivo/line';
import { area, curveMonotoneX } from 'd3-shape';
import { Defs } from '@nivo/core';
import { COLORS } from '~utils/styling';
import dayjs, { Dayjs } from 'dayjs';
import { getCurrentTimezone, hasKey } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';
import { LineChartProps } from '~reactComponents/charts/LineChart/LineChart.react';
import { Period } from '../../CumulativeCosts/Costs.types.react';
import {
  resolveAxisBottomFormat,
  resolveAxisBottomTickValues,
  resolveXFormat,
  resolveXScaleFormat,
  resolveXScalePrecision,
} from '../RightSizing.helpers.react';

type seriesType = 'memory' | 'cpu';

function useResourcePerWorkload(
  type: seriesType,
  series: Serie[],
  highestUsage: number,
  period: Period,
  hiddenIds: string[],
  setHiddenIds: React.Dispatch<React.SetStateAction<string[]>>,
) {
  const timeZone = getCurrentTimezone();

  const AreaLayer1 = ({
    series,
    xScale,
    yScale,
    innerHeight,
  }: {
    series: Serie[];
    xScale: (x: number) => number;
    yScale: (y: number) => number;
    innerHeight: number;
  }) => {
    const limits = series.filter((serie) => serie.id === 'limits') || [];
    const requests = series.filter((serie) => serie.id === 'requests') || [];
    const areaGenerator = area()
      .x((d: any) => xScale(d.data.x))
      .y0((d: any) => {
        let bottomPoint;
        if (requests[0]) {
          requests[0].data.forEach((dataPoint: any) => {
            const updatedDatapoint = timeZone
              ? dayjs(dataPoint.data.x).tz(timeZone).valueOf()
              : dataPoint.data.x.getTime();
            const updatedD = timeZone ? dayjs(d.data.x).tz(timeZone).valueOf() : d.data.x.getTime();
            if (updatedDatapoint === updatedD) bottomPoint = dataPoint.data.y;
          });
        }
        return Math.min(innerHeight, yScale(d.data.y === bottomPoint || !bottomPoint ? d.data.y : bottomPoint));
      })
      .y1((d: any) => yScale(d.data.y))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: 'recommended-data',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: COLORS.CHARTS.USAGE_LINE.RECOMMENDED_FILL, opacity: 0.1 },
                { offset: 100, color: COLORS.CHARTS.USAGE_LINE.RECOMMENDED_FILL, opacity: 0.1 },
              ],
            },
          ]}
        />
        <path d={areaGenerator(limits[0]?.data || {})} fill="url(#recommended-data)" fillOpacity={0.6} />
      </>
    );
  };

  const AreaLayer2 = ({
    series,
    xScale,
    yScale,
    innerHeight,
  }: {
    series: Serie[];
    xScale: (x: number) => number;
    yScale: (y: number) => number;
    innerHeight: number;
  }) => {
    const limits = series.filter((serie) => serie.id === 'settings-limits') || [];
    const requests = series.filter((serie) => serie.id === 'settings-requests') || [];
    const areaGenerator = area()
      .x((d: any) => xScale(d.data.x))
      .y0((d: any) => {
        let bottomPoint;
        if (requests) {
          requests[0].data.forEach((dataPoint: any) => {
            const updatedDatapoint = timeZone
              ? dayjs(dataPoint.data.x).tz(timeZone).valueOf()
              : dataPoint.data.x.getTime();
            const updatedD = timeZone ? dayjs(d.data.x).tz(timeZone).valueOf() : d.data.x.getTime();
            if (updatedDatapoint === updatedD) bottomPoint = dataPoint.data.y;
          });
        }
        return Math.min(innerHeight, yScale(d.data.y === bottomPoint || !bottomPoint ? d.data.y : bottomPoint));
      })
      .y1((d: any) => yScale(d.data.y))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: 'actual-data',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: COLORS.CHARTS.USAGE_LINE.ACTUAL_FILL, opacity: 0.3 },
                {
                  offset: 100,
                  color: COLORS.CHARTS.USAGE_LINE.ACTUAL_FILL,
                  opacity: 0.3,
                },
              ],
            },
          ]}
        />
        <path d={areaGenerator(limits[0]?.data || {})} fill="url(#actual-data)" fillOpacity={0.6} />
      </>
    );
  };

  const DashedLine = ({
    series,
    lineGenerator,
    xScale,
    yScale,
  }: {
    series: Serie[];
    lineGenerator: (data: { x: Dayjs; y: number }[]) => any;
    xScale: (x: Dayjs) => Dayjs;
    yScale: (y: number) => number;
  }) => {
    return series.map(({ id, data }) => (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(timeZone ? dayjs(d.data.x).tz(timeZone) : dayjs(d.data.x)),
            y: yScale(d.data.y),
          })),
        )}
        fill="none"
        stroke={colors[id]}
        style={hasKey(styleById, id) ? styleById[id] : styleById.default}
      />
    ));
  };

  const tooltip = (slice: any) => {
    const avgUsage = slice.points.filter((point: any) => point.serieId === strings.noTranslate.avgUsage)[0];

    const duplicatedData: any = {};

    const originalArray = slice.points.filter((point: any) => {
      if (duplicatedData[point.data.x] && duplicatedData[point.data.x] === point.serieId) {
        return false;
      }

      duplicatedData[point.data.x] = point.serieId;

      return point.serieId !== strings.noTranslate.avgUsage;
    });

    if (!hiddenIds.includes(strings.noTranslate.avgUsage)) originalArray.splice(5, 0, avgUsage);
    return (
      <div className="resource-usage__tooltip">
        {originalArray.map((point?: Point) => {
          return (
            point && (
              <div className="resource-usage__tooltip-container">
                <div className="resource-usage__row-container">
                  <div
                    className="resource-usage__square"
                    style={{
                      backgroundColor: point.serieColor,
                    }}
                  ></div>
                  <strong>{labels[point.serieId]}:</strong>
                </div>
                {type === 'memory'
                  ? ` ${Number(point.data.y).toFixed(2)} GB`
                  : ` ${Number(point.data.y).toFixed(0)} mCPU`}
              </div>
            )
          );
        })}
      </div>
    );
  };

  const chartProps: LineChartProps = {
    data: series,
    margin: { top: 0, right: 30, bottom: 85, left: 80 },
    xScale: {
      type: 'time',
      format: resolveXScaleFormat(period),
      useUTC: false,
      precision: resolveXScalePrecision(period),
    },
    xFormat: resolveXFormat(period),
    yScale: {
      type: 'linear',
      min: 0,
      max: highestUsage * 1.15,
      stacked: false,
      reverse: false,
    },
    yFormat: '>-.2f',
    colors: ({ id }: { id: string }): string => colors[id],
    legends: [
      {
        data: Object.keys(legendLabels).map((item) => {
          return {
            color: hiddenIds.includes(item)
              ? 'rgba(1, 1, 1, .1)'
              : hasKey(usageLineColors, item)
                ? usageLineColors[item]
                : 'rgba(1, 1, 1, .1)',
            id: item,
            label: hasKey(legendLabels, item) ? legendLabels[item] : '',
          };
        }),
        onClick: (datum: any) => {
          setHiddenIds((state) =>
            state.includes(String(datum.id)) ? state.filter((item) => item !== datum.id) : [...state, String(datum.id)],
          );
        },
        anchor: 'bottom-left',
        translateY: 80,
        direction: 'row',
        itemHeight: 40,
        itemWidth: 150,
      },
    ],
    axisBottom: {
      format: (value: Date) => resolveAxisBottomFormat(value, timeZone, period),
      tickValues: resolveAxisBottomTickValues(period),
      tickRotation: -45,
    },
    axisLeft: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legendOffset: -45,
      format: (value: number) => {
        if (Number.isInteger(value) || highestUsage < 0.55 || value > highestUsage) {
          return `${value} ${type === 'memory' ? 'GB' : 'mCPU'}`;
        }
        return '';
      },
    },
    layers: [
      'grid',
      'markers',
      'areas',
      DashedLine,
      AreaLayer1,
      AreaLayer2,
      'slices',
      'axes',
      'points',
      'legends',
      'mesh',
      'crosshair',
    ],
    sliceTooltip: tooltip,
  };

  return chartProps;
}

export default useResourcePerWorkload;
