import React, { useEffect, useState } from 'react';

import { Breadcrumbs } from '@fairwindsops/ui-components';

import { APP_GROUPS_REPORTS, ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IStore, IRoute, IRouter, DateType, OptionType } from '~globalTypes';
import Option from '~views/appGroups/reports/components/Option/OptionComponent/OptionComponent.react';
import { formatDatetime, getCurrentTimezone, handlePageChange } from '~reactHelpers';
import { strings } from '~utils/strings';

import './AppGroupsReports.react.scss';
import AggregatorBarChart from './AggregatorBarChart/AggregatorBarChart.react';
import { AppGroupsAPIResponse, AppGroupsStatistics } from './AppGroupsReports.config.react';
import { sendRequest } from '~utils/request';
import Datepicker from '~reactComponents/ReactDatepicker/Datepicker.react';
import dayjs, { Dayjs } from 'dayjs';
import Select, { SingleValue } from 'react-select';
import logger from '~utils/logger';
import toast from 'react-hot-toast';
import { COLORS } from '~utils/styling';
import DownloadIcon from '~reactComponents/Icons/Download.react';
import { downloadCsv } from '~utils/helpers';
import { DateRangeLabel, getDateRangeLabel } from '~reactComponents/ReactDatepicker/Datepicker.helper.react';
import { DEFAULT_DATE_PICKER_OPTIONS } from '~reactComponents/ReactDatepicker/Datepicker.config.react';

const colors: Record<string, string> = {
  introduced: COLORS.CHARTS.ACTION_ITEMS_REPORTS.INTRODUCED,
  fixed: COLORS.CHARTS.ACTION_ITEMS_REPORTS.FIXED,
  open: COLORS.CHARTS.ACTION_ITEMS_REPORTS.OPEN,
  resolved: COLORS.CHARTS.ACTION_ITEMS_REPORTS.RESOLVED,
  deleted: COLORS.CHARTS.ACTION_ITEMS_REPORTS.DELETED,
};

type AppGroupsReportsProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  isAddAppGroupModalShown?: boolean;
};

const AppGroupsReports = ({ route, router, store, isAddAppGroupModalShown }: AppGroupsReportsProps) => {
  const organization = store().getters.organization;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const timezone = getCurrentTimezone();
  const [filteredAppGroups, setFilterAppGroups] = useState<AppGroupsStatistics[]>([]);
  const [data, setData] = useState<AppGroupsStatistics[]>([]);
  const [appGroupOptions, setAppGroupOptions] = useState<OptionType[]>([]);
  const [policyMappingOptions, setPolicyMappingOptions] = useState<OptionType[]>([]);
  const [appGroups, setAppGroups] = useState<OptionType[]>([]);
  const [policyMapping, setPolicyMapping] = useState<OptionType>();
  const [selectedStatus, setSelectedStatus] = useState<OptionType[]>([
    { label: 'introduced', value: 'introduced' },
    { label: 'open', value: 'open' },
    { label: 'fixed', value: 'fixed' },
    { label: 'resolved', value: 'resolved' },
    { label: 'deleted', value: 'deleted' },
  ]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTimeRange, setSelectedTimeRange] = useState<DateType>({
    start:
      route?.query?.startTime ||
      formatDatetime(timezone ? dayjs().tz(timezone).subtract(1, 'month') : dayjs().subtract(1, 'month')),
    end: route?.query?.endTime || formatDatetime(timezone ? dayjs().tz(timezone) : dayjs()),
  });
  const dateOption = getDateRangeLabel(dayjs(), dayjs(selectedTimeRange.start), dayjs(selectedTimeRange.end));

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `${baseURL}/dashboard`,
    },
    {
      id: APP_GROUPS_REPORTS,
      label: strings.appGroups.AppGroups,
      href: `${baseURL}/app-groups/reports`,
    },
    {
      id: 'last',
      label: strings.appGroups.summary,
      href: ``,
      isActive: true,
    },
  ];

  useEffect(() => {
    fetchData();
    (async function () {
      await getAppGroups();
      await getPolicyMapping();
    })();
  }, [policyMapping, appGroups, selectedTimeRange]);

  useEffect(() => {
    filterData(undefined);
  }, [selectedStatus]);

  const filterByDate = (start?: Dayjs | Date, end?: Date | Dayjs) => {
    if (!start || !end) {
      return;
    }

    const changedDates = {
      start: formatDatetime(timezone ? dayjs(start).tz(timezone) : dayjs(start)),
      end: formatDatetime(timezone ? dayjs(end).tz(timezone) : dayjs(end)),
    };
    setSelectedTimeRange(changedDates);
  };

  const getAppGroups = async () => {
    try {
      const appGroupRequest = await sendRequest('GET', `${baseURL}/app-groups`, {}, null);
      setAppGroupOptions(
        appGroupRequest?.length
          ? appGroupRequest.map((appGroup: any) => ({ label: appGroup.name, value: appGroup.name }))
          : [],
      );
    } catch (e) {
      logger.logError('error_retrieving_app_groups', e);
      toast.error(<b>{strings.appGroups.errorRetrievingAppGroups}</b>);
    }
  };

  const getPolicyMapping = async () => {
    try {
      const policyMappingRequest = await sendRequest('GET', `${baseURL}/policy-mappings`, {}, null);
      setPolicyMappingOptions(
        policyMappingRequest?.length
          ? policyMappingRequest.map((policyMapping: any) => ({ label: policyMapping.name, value: policyMapping.name }))
          : [],
      );
    } catch (e) {
      logger.logError('error_retrieving_policy_mapping', e);
      toast.error(<b>{strings.appGroups.errorRetrievingPolicyMappings}</b>);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const appGroupsParams = appGroups.map((appGroup) => appGroup.value).join('&appGroup=');
      let url = `${baseURL}/app-groups/action-items/statistics?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}`;
      if (appGroupsParams) {
        url += `&appGroup=${appGroupsParams}`;
      }
      if (policyMapping) {
        url += `&policyMapping=${policyMapping.value}`;
      }
      const data = sendRequest('GET', url, { showErrorAlert: false }, null);
      const appGroupsData: AppGroupsAPIResponse[] = await data;
      const result = appGroupsData.map((appGroup) => {
        return {
          appGroup: appGroup.appGroup,
          policyMapping: appGroup.policyMapping,
          open: appGroup.cumulative.open,
          introduced: appGroup.cumulative.introduced,
          fixed: appGroup.cumulative.fixed,
          resolved: appGroup.cumulative.resolved,
          deleted: appGroup.cumulative.deleted,
        };
      });
      setData(result);
      filterData(result);
      setIsLoading(false);
    } catch (e) {
      logger.logError('app_groups_reports_error: ', e);
    }
  };

  const downloadCSV = async () => {
    try {
      const appGroupsParams = appGroups.map((appGroup) => appGroup.value).join('&appGroup=');
      let url = `${baseURL}/app-groups/action-items/statistics/export?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}`;
      if (appGroupsParams) {
        url += `&appGroup=${appGroupsParams}`;
      }
      if (policyMapping) {
        url += `&policyMapping=${policyMapping.value}`;
      }
      const data = await sendRequest('GET', url, { showErrorAlert: false }, null);
      downloadCsv(data, 'app_groups_report_download');
    } catch (e) {
      logger.logError('app_groups_reports_error_download: ', e);
      toast.error('Unable to download the report. Try again.');
    }
  };

  const filterData = (p: any) => {
    let result: any;
    if (p) {
      result = JSON.parse(JSON.stringify(p));
    } else if (data) {
      result = JSON.parse(JSON.stringify(data));
    }
    const allSected = selectedStatus.map((status) => status.value);
    for (const key in colors) {
      if (!allSected.includes(key)) {
        result = result.map((appGroup: Record<string, any>) => {
          delete appGroup[key];
          return appGroup;
        });
      }
    }
    // filter out if all values are 0
    result = result.filter((appGroup: Record<string, any>) => {
      return Object.values(appGroup).some((value) => value > 0);
    });
    result = result.sort((a: Record<string, any>, b: Record<string, any>) => {
      const sumA =
        zeroIfUndefined(a.introduced) +
        zeroIfUndefined(a.open) +
        zeroIfUndefined(a.fixed) +
        zeroIfUndefined(a.resolved) +
        zeroIfUndefined(a.deleted);
      const sumB =
        zeroIfUndefined(b.introduced) +
        zeroIfUndefined(b.open) +
        zeroIfUndefined(b.fixed) +
        zeroIfUndefined(b.resolved) +
        zeroIfUndefined(b.deleted);
      return sumB - sumA;
    });
    if (!appGroups || appGroups.length === 0) {
      result = result.slice(0, 5);
    }
    setFilterAppGroups(result);
  };

  const zeroIfUndefined = (value: number | undefined) => {
    return value || 0;
  };

  return (
    <div className="app-groups-summary">
      <div className="app-groups-summary__container">
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <div className="action-items-reports__header-container">
          <div className="action-items-reports__filter-container">
            <Datepicker
              disabled={isLoading}
              isClearable={true}
              dateOptionSelected={dateOption}
              onDateOptionSelect={(dateOption) => {
                if (!dateOption) {
                  return;
                }
                const today = timezone ? dayjs().tz(timezone) : dayjs();
                const startDate = DEFAULT_DATE_PICKER_OPTIONS[dateOption as DateRangeLabel].startDateConverter(today);
                const endDate = DEFAULT_DATE_PICKER_OPTIONS[dateOption as DateRangeLabel].endDateConverter(today);
                filterByDate(startDate, endDate);
              }}
              onDateSelect={filterByDate}
              startDate={selectedTimeRange.start}
              endDate={selectedTimeRange.end}
              minDate={12}
              className="action-items-reports"
            />
            <Select
              closeMenuOnSelect={false}
              isMulti
              placeholder="Filter by App Groups"
              options={appGroupOptions}
              onChange={(newOption, { action, removedValue, option }) => {
                if (action === 'clear') {
                  setAppGroups([]);
                } else if (option) {
                  setAppGroups((prevAppGroups) => [...prevAppGroups, option]);
                } else if (removedValue) {
                  setAppGroups((prevAppGroups) =>
                    prevAppGroups.filter((appGroup) => appGroup.value !== removedValue.value),
                  );
                }
              }}
              value={appGroups}
              isDisabled={isLoading}
            />
            <Select
              closeMenuOnSelect={false}
              isMulti={false}
              isClearable={true}
              placeholder="Filter by Policy Mapping"
              options={policyMappingOptions}
              onChange={async (option: SingleValue<any>) => {
                setPolicyMapping(option);
              }}
              value={policyMapping}
              isDisabled={isLoading}
            />
            <Select
              isDisabled={isLoading}
              isMulti
              options={Object.keys(colors).map((key) => ({
                label: key,
                value: key,
              }))}
              value={selectedStatus}
              placeholder="Select at least one Status"
              isSearchable
              isClearable
              backspaceRemovesValue
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              components={{
                Option,
              }}
              onChange={setSelectedStatus}
            />
          </div>
          <div className="report-download" onClick={downloadCSV}>
            <DownloadIcon marginRight="0.5rem" />
            Download
          </div>
        </div>
      </div>
      <AggregatorBarChart data={filteredAppGroups} isLoading={isLoading} title="App Groups Reporting" colors={colors} />
    </div>
  );
};

export default AppGroupsReports;
