import { PolicyMapping } from './PolicyMapping.react';

export default {
  name: 'policyMapping',
  components: {
    'policy-mapping': PolicyMapping,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
