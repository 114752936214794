import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { NodeCostType, NodeCostsType } from './Efficiency.types.react';
import { OptionType, IRoute, IRouter } from '~globalTypes';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { sortAscending } from '~utils/helpers';
import { hasKey, getCurrentTimezone } from '~reactHelpers';
import { strings } from '~utils/strings';
import {
  REPORT_HUB,
  CLUSTER_REPORT_HUB,
} from '~reactComponents/NavigationReact/Navigation.config.react';

dayjs.extend(timezone);

export const formatDates = (date: string) => {
  const timeZone = getCurrentTimezone();
  const day = timeZone
    ? dayjs(date).tz(timeZone).format('MM/DD/YYYY')
    : dayjs(date).format('MM/DD/YYYY');
  const time = timeZone ? dayjs(date).tz(timeZone).format('h:mm A') : dayjs(date).format('h:mm A');
  return { day, time };
};

export const handleChartTypeChange = (
  selected: unknown,
  setSelectedDisplay: (value: string) => void,
) => {
  const typedSelection = selected as OptionType;
  if (selected) setSelectedDisplay(typedSelection.value);
};

export const aggregateDataByTimePeriod = (data: NodeCostsType, type: string) => {
  const valuesByTimePeriod: Record<string, NodeCostsType> = {};
  const keys = Object.keys(data);

  if (type !== 'hour' && type !== 'day') {
    throw Error;
  }

  keys.forEach((key: string) => {
    data[key].forEach((datum) => {
      let format = 'YYYY-MM-DD';
      if (type === 'hour') {
        format = 'YYYY/MM/DD HH';
      }
      const timeKey = dayjs(datum.reportTimestamp).utc().format(format);

      if (valuesByTimePeriod[key]) {
        valuesByTimePeriod[key][timeKey]
          ? valuesByTimePeriod[key][timeKey].push(datum)
          : (valuesByTimePeriod[key][timeKey] = [datum]);
      } else {
        valuesByTimePeriod[key] = { [timeKey]: [datum] };
      }
    });
  });

  return valuesByTimePeriod;
};

export const findAvgByDay = (
  valuesByTimePeriod: Record<string, NodeCostsType>,
  options?: {
    getValue?: (a: any) => number;
    chart?: string;
    sum?: boolean;
  },
) => {
  const clustersForAveraging = Object.keys(valuesByTimePeriod);
  const avgByDay: Record<string, Record<string, number>> = {};

  clustersForAveraging.forEach((cluster) => {
    Object.keys(valuesByTimePeriod[cluster]).forEach((date: string) => {
      const allValuesForDay = valuesByTimePeriod[cluster][date];
      const totalSum = allValuesForDay.reduce((sum: number, datum) => {
        return !options?.getValue ? datum.nodes.totalCost + sum : options.getValue(datum) + sum;
      }, 0);
      if (totalSum > 0) {
        const average = totalSum / allValuesForDay.length;
        if (avgByDay[cluster]) {
          avgByDay[cluster][date] = options?.sum ? totalSum : average;
        } else {
          avgByDay[cluster] = {
            [date]: options?.sum ? totalSum : average,
          };
        }
      }
    });
  });

  return avgByDay;
};

export const findTotalByDay = (valuesByTimePeriod: Record<string, NodeCostsType>) => {
  const clustersForSumming = Object.keys(valuesByTimePeriod);
  const totalByDay: Record<string, Record<string, number>> = {};

  clustersForSumming.forEach((cluster) => {
    Object.keys(valuesByTimePeriod[cluster]).forEach((date: string) => {
      const allValuesForDay = valuesByTimePeriod[cluster][date];
      const totalSum = allValuesForDay.reduce((sum: number, datum) => {
        return datum.nodes.totalCost + sum;
      }, 0);

      if (totalByDay[cluster]) {
        totalByDay[cluster][date] = totalSum;
      } else {
        totalByDay[cluster] = {
          [date]: totalSum,
        };
      }
    });
  });
};

export const getClusterData = async (
  baseURL: string,
  start: Date,
  end: Date,
  period: string,
  cluster?: string,
) => {
  const formattedData: NodeCostsType = {};
  let response: NodeCostsType;
  const updatedBaseURL = cluster ? `${baseURL}/clusters/${cluster}` : baseURL;

  try {
    response = await sendRequest(
      'GET',
      `${updatedBaseURL}/capacity?startTime=${start.toISOString()}&endTime=${end.toISOString()}&period=${period}&addNetworkAndStorage=true`,
      {},
      null,
    );
  } catch (e) {
    logger.logError('error_retrieving_cluster_capacities', e);
  } finally {
    if (response && !cluster) {
      const keys = Object.keys(response);
      keys.forEach((cluster: string) => {
        formattedData[cluster] = response[cluster].sort((a, b) =>
          sortAscending(a.reportTimestamp, b.reportTimestamp),
        );
      });
    } else if (response && cluster) {
      formattedData[cluster] = response.sort((a, b) =>
        sortAscending(a.reportTimestamp, b.reportTimestamp),
      );
    }
  }
  return formattedData;
};

export const formatData = (data: number, selectedDisplay: string): number => {
  if (selectedDisplay === 'memory' || selectedDisplay === 'memoryRaw') {
    return data / (1024 * 1024 * 1024);
  } else {
    return data / 1000;
  }
};

const findSum = (
  values: NodeCostType[],
  display: 'memory' | 'cpu' | string,
): Record<string, number> => {
  const categories: string[] = ['nodes', 'requests', 'limits', 'allocatable', 'inUse'];
  const sums: Record<string, number> = {
    costs: 0,
    limits: 0,
    requests: 0,
    allocatable: 0,
    inUse: 0,
  };
  values.forEach((datum) => {
    categories.forEach((category) => {
      if (hasKey(datum, category)) {
        if (category === 'nodes') {
          sums.costs += datum.nodes.costPerNode * datum.nodes.total;
        } else {
          if (hasKey(datum[category], display)) {
            sums[category] += datum[category][display]['raw'];
          }
        }
      }
    });
  });

  return sums;
};

type ClusterAverages = {
  cost: number[];
  requests: number[];
  inUse: number[];
  limits: number[];
  available: number[];
  date: string;
};

export const findSingleClusterAverages = (data: NodeCostType[], display: string) => {
  const dailyValues: Record<string, NodeCostType[]> = {};
  const dates: string[] = [];
  const avgByDay: ClusterAverages = {
    cost: [],
    requests: [],
    inUse: [],
    limits: [],
    available: [],
    date: '',
  };

  data.forEach((datum: NodeCostType) => {
    const timeZone = getCurrentTimezone();
    const fullDate = dayjs(datum.reportTimestamp).utc().local();
    const day = timeZone
      ? fullDate.tz(timeZone).format('YYYY/MM/DD')
      : fullDate.format('YYYY/MM/DD');
    dailyValues[day] ? dailyValues[day].push(datum) : (dailyValues[day] = [datum]);
    dates.push(fullDate.format());
  });

  avgByDay.date = dates[dates.length - 1];

  Object.keys(dailyValues).forEach((key) => {
    const allValuesForDay = dailyValues[key];
    const dailySums = findSum(allValuesForDay, display);

    if (dailySums.costs / allValuesForDay.length > 0) {
      if (Array.isArray(avgByDay.cost))
        avgByDay.cost.push((dailySums.costs / allValuesForDay.length) * 24);
    }
    if (dailySums.requests / allValuesForDay.length > 0) {
      if (Array.isArray(avgByDay.requests))
        avgByDay.requests.push((dailySums.requests / allValuesForDay.length) * 24);
    }
    if (dailySums.inUse / allValuesForDay.length > 0) {
      if (Array.isArray(avgByDay.inUse))
        avgByDay.inUse.push((dailySums.inUse / allValuesForDay.length) * 24);
    }
    if (dailySums.limits / allValuesForDay.length > 0) {
      if (Array.isArray(avgByDay.limits))
        avgByDay.limits.push((dailySums.limits / allValuesForDay.length) * 24);
    }
    if (dailySums.allocatable / allValuesForDay.length > 0) {
      if (Array.isArray(avgByDay.available))
        avgByDay.available.push(dailySums.allocatable / allValuesForDay.length);
    }
  });

  return avgByDay;
};

export const getAverage = (averages: number[]) => {
  return averages.length ? averages.reduce((sum, day) => sum + day) / averages.length : 0;
};

export const DisplaySyncData = ({
  syncData,
  route,
  router,
}: {
  syncData: { date: string; report: string; paramReport: string; name: string }[];
  route: IRoute;
  router: () => IRouter;
}): JSX.Element => {
  const mappedDates = syncData.map((data, index) => {
    const params = {
      cluster: route?.params?.cluster,
      org: route?.params?.org,
      report: data.paramReport,
    };

    if (!data.date.toLowerCase().includes('install')) {
      return (
        <div className="synced-div-section" key={`${data.report}-sync`}>
          <div className="synced-green-circle"></div>
          <strong className="synced-bold">{`Last Synced ${data.report}:`}</strong>
          {data.date}
        </div>
      );
    } else {
      let link = router().resolve({
        name: data.name,
        params,
      }).href;

      let target = '';

      if (data.report === 'AWS-Costs') {
        link =
          'https://insights.docs.fairwinds.com/technical-details/reports/aws-costs/#agent-configuration';
        target = '_blank';
      } else if (data.report === 'Cloud-Costs') {
        link = 'https://insights.docs.fairwinds.com/technical-details/reports/cloud-costs/';
        target = '_blank';
      }

      return (
        <div className="synced-div-section" key={`${data.report}-sync`}>
          <div className="synced-red-circle"></div>
          {route?.params?.cluster ? (
            <a className="synced-bold" href={link} target={target}>
              {data.date}
            </a>
          ) : (
            <span className="synced-bold">{data.date}</span>
          )}
        </div>
      );
    }
  });

  return <>{mappedDates}</>;
};
