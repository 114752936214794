import React, { useState, useEffect, useMemo } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import { IGNORE_STANDARDS } from '~reactComponents/cards/ComplianceCard/ComplianceCard.types.react';
import AccordionComponent from '~reactComponents/accordion/AccordionComponent.react';
import Tag from '~reactComponents/Tags/Tag.react';
import CloseIcon from '~reactIcons/Close.icon.react';

import { AutomatedStandard, CommonLabels, IRoute, IRouter, OptionType } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { useContextMenu } from 'react-contexify';
import { COMPLIANCE_REPORT_TABLE_CONTEXT_MENU_ID } from '~utils/global.helpers.react';

import { formatStatus, standardizedReports } from '~views/organization/compliance/Compliance.helpers.react';

import {
  AutomatedCheck,
  defaultPolicyStatusOptions,
  Labels,
  NON_RESOURCE_TYPES,
  payloadMap,
  RESOURCE_TYPES,
  statusMap,
} from './AutomatedCheckModal.types.react';

import { Check, Standards } from '../Compliance.types.react';

import { strings } from '~utils/strings';

import './AutomatedCheckModal.react.scss';
import ContextMenu from '../ContextMenu/ContextMenu.react';
import CreateTicketModal from '~reactComponents/createTicketModal/CreateTicketModal.react';

type AutomatedCheckModalProps = {
  baseURL: string;
  check: Check | null;
  clusters?: string[];
  isOwner?: boolean;
  organizationName?: string;
  reportID: string;
  show: boolean;
  addEvidence: (evidence: { evidence: string; status: string; mode: string }, id: number) => void;
  onRunAutomatedCheckTriggered: () => void;
  setModal: (showModal: boolean) => void;
  route: IRoute;
  router: () => IRouter;
};

const AutomatedCheckModal = ({
  baseURL,
  check,
  clusters,
  isOwner,
  reportID,
  show,
  addEvidence,
  onRunAutomatedCheckTriggered,
  setModal,
  route,
  router,
}: AutomatedCheckModalProps): JSX.Element => {
  if (!check) {
    return <></>;
  }

  const { show: showContextMenu } = useContextMenu({
    id: COMPLIANCE_REPORT_TABLE_CONTEXT_MENU_ID,
  });

  const [standards, setStandards] = useState<AutomatedStandard[]>([]);
  const [automatedCheck, setAutomatedCheck] = useState<AutomatedCheck | null>(null);
  const [policyStatusOptions, setPolicyStatusOptions] = useState<OptionType[]>();
  const [policyStatus, setPolicyStatus] = useState<OptionType | null>(null);
  const [evidenceText, setEvidenceText] = useState<string>(check.evidence);
  const [isCreateTicketModalShown, setIsCreateTicketModalShown] = useState<boolean>(false);

  useEffect(() => {
    if (check) {
      const filteredPolicyStatusOptions = check.canBeAutomated
        ? defaultPolicyStatusOptions
        : defaultPolicyStatusOptions.filter((option) => option.value !== Labels.Automated);
      postAutomatedCheck().then((response) => setAutomatedCheck(response));
      getStandards(check.standards);
      setPolicyStatusOptions(filteredPolicyStatusOptions);
      setPolicyStatus(findPolicyStatus(filteredPolicyStatusOptions, check));
    }
  }, [check]);

  const showCreateTicketModal = () => {
    if (!isOwner) {
      return;
    }
    setIsCreateTicketModalShown(true);
  };

  const postAutomatedCheck = async () => {
    if (!baseURL || !reportID) {
      return;
    }
    try {
      return await sendRequest(
        'POST',
        `${baseURL}/compliance/reports/${reportID}/checks/${check.id}/automated-check`,
        {},
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_automated_check', e);
    }
  };

  const getStandards = (standards: Standards[]) => {
    if (!standards?.length) {
      return;
    }
    const transformedStandards = [];
    for (const standard of standards) {
      if (IGNORE_STANDARDS.includes(standard?.name)) continue;
      transformedStandards.push({
        label: standardizedReports(standard?.name),
        codes: standard?.codes,
      });
    }
    setStandards(transformedStandards);
  };

  const findPolicyStatus = (filteredPolicyStatusOptions: OptionType[], check: Check) => {
    const criteria = getCriteria(check);
    if (!criteria) return null;
    return filteredPolicyStatusOptions.find((option: OptionType) => option.value === criteria) || null;
  };

  const getCriteria = (check: Check) => {
    if (check.mode === Labels.Automated) {
      return check.mode;
    }
    const keys = Object.keys(statusMap);
    for (const key of keys) {
      if (statusMap[key].includes(check.status)) {
        return key;
      }
    }
    return null;
  };

  const getUpdatedBy = () => {
    if (check.selfAssessedBy?.firstName && check.selfAssessedBy.lastName) {
      `${check.selfAssessedBy.firstName} ${check.selfAssessedBy.lastName}`;
    }
    return check.selfAssessedBy?.email ? check.selfAssessedBy?.email : CommonLabels.Empty;
  };

  const onPolicyStatusSelected = (selected: OptionType) => {
    setPolicyStatus(selected);
  };

  const runAutomatedCheck = async () => {
    await postAutomatedCheck();
    onRunAutomatedCheckTriggered();
    toast.success(<b>{strings.automatedCheck.successfulRefresh}</b>);
  };

  const updateReport = () => {
    if (check)
      addEvidence(
        {
          evidence: evidenceText || '',
          status: findStatus(),
          mode: policyStatus?.value === Labels.Automated ? Labels.Automated : Labels.Manual,
        },
        check?.id,
      );
  };

  const ticketCreated = async (resp: any) => {
    await postCreateTicket(resp);
  };

  const postCreateTicket = async (resp: any) => {
    if (resp) {
      if (resp.TicketLink) {
        check.ticketLink = resp.TicketLink;
      }
      if (resp.TicketProvider) {
        check.ticketProvider = resp.TicketProvider;
      }
      if (resp.TicketCreatedAt) {
        check.ticketCreatedAt = resp.TicketCreatedAt;
      }
      toast.success(strings.viewTicketModal.ticketCreatedSuccessfully);
      setIsCreateTicketModalShown(false);
    }
  };

  const findStatus = () => {
    if (policyStatus?.value === Labels.Automated) {
      return Labels.NotStarted;
    }
    const keys = Object.keys(payloadMap);
    for (const key of keys) {
      if (key === policyStatus?.value) return payloadMap[key];
    }
    return check.status;
  };

  const disableUpdate = useMemo(() => {
    if (!isOwner) {
      return true;
    } else if (check.mode !== policyStatus?.value || check.evidence !== evidenceText) {
      return false;
    }
    return true;
  }, [policyStatus, evidenceText]);

  const evidence = (node: React.FormEvent) => {
    const target = node.target as HTMLInputElement;
    if (node !== null) setEvidenceText(target.value);
  };

  return (
    <Modal show={show} onHide={() => setModal(false)} className="automated-check-modal">
      <Modal.Header>
        <Modal.Title>
          <div className="automated-check-modal__title-container">
            <div className="automated-check-modal__left-title">{check.title}</div>
            <div className="automated-check-modal__right-title">
              <div>
                {check.mode === Labels.Automated && (
                  <Badge pill className="compliance-automated-badge">
                    {`${strings.automatedCheck.automated
                      .charAt(0)
                      .toUpperCase()}${strings.automatedCheck.automated.slice(1)}`}
                  </Badge>
                )}
                {!check.reportInstalled && (
                  <Badge pill className={`compliance-status-badge compliance-status-badge--not-installed`}>
                    {strings.automatedCheck.notInstalled}
                  </Badge>
                )}
                {check.status && check.reportInstalled && (
                  <Badge pill className={`compliance-status-badge compliance-status-badge--${check.status}`}>
                    {formatStatus(check.status)}
                  </Badge>
                )}
                {isOwner &&
                  policyStatus?.value === strings.automatedCheck.automated &&
                  ((automatedCheck && automatedCheck?.failing > 0 && automatedCheck.failingActionItemsLink) ||
                    (check.ticketLink && check.ticketLink !== '')) && (
                    <div>
                      <button
                        className="additional-actions-btn"
                        onClick={(e) => showContextMenu({ event: e, props: { check } })}
                        data-cy="additional-actions-button"
                      >
                        <img src={HorizontalEllipsis} alt="ellipsis icon" />
                      </button>
                    </div>
                  )}
                <div className="custom-close-icon">
                  <CloseIcon onClick={() => setModal(false)} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="automated-check-modal__header-section">
          <div>
            <div className="automated-check-modal__last-reported">
              <div>{strings.automatedCheck.lastReported}</div>
              <div>
                {dayjs(check.selfAssessedAt).utc().format('LL')} at {dayjs(check.selfAssessedAt).utc().format('LT')}
              </div>
            </div>
            <div className="automated-check-modal__updated-by">
              <div>{strings.automatedCheck.updatedBy}</div>
              <div>{getUpdatedBy()}</div>
            </div>
          </div>
          <div className="automated-check-modal__standards">
            {standards?.length &&
              standards.map((standard: AutomatedStandard, index: number) => (
                <div key={index} className="automated-check-modal__standard-item">
                  <div>{standard.label}</div>
                  <div>
                    {standard.codes?.length &&
                      standard.codes.map((code: string, index: number) => (
                        <div key={index}>
                          <Tag tagClassNames="tag-ellipsis">{code}</Tag>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        {policyStatus?.value === strings.automatedCheck.automated && (
          <div className="automated-check-modal__action-items-container">
            {automatedCheck?.type === RESOURCE_TYPES.RESOURCES && (
              <>
                <div className="automated-check-modal__items-section">
                  <div>{strings.automatedCheck.failingResources}</div>
                  <div>
                    <span className="automated-check-modal__number-items">{automatedCheck?.failing || 0}</span>
                    <a
                      className="automated-check-modal__view-action-items"
                      href={automatedCheck?.failingActionItemsLink}
                    >
                      {strings.automatedCheck.viewActionItems}
                    </a>
                  </div>
                </div>
                <div className="automated-check-modal__items-section">
                  <div>{strings.automatedCheck.manuallyResolved}</div>
                  <div>
                    <span className="automated-check-modal__number-items">{automatedCheck?.manuallyResolved || 0}</span>
                    <a
                      className="automated-check-modal__view-action-items"
                      href={automatedCheck?.manuallyResolvedActionItemsLink}
                    >
                      {strings.automatedCheck.viewActionItems}
                    </a>
                  </div>
                </div>
                <div className="automated-check-modal__items-section">
                  <div>{strings.automatedCheck.passingResources}</div>
                  <div>
                    <span className="automated-check-modal__number-items">{automatedCheck?.passing || 0}</span>
                  </div>
                </div>
              </>
            )}
            {NON_RESOURCE_TYPES.includes(automatedCheck?.type || '') && (
              <>
                <div className="automated-check-modal__items-section">
                  <div>
                    {automatedCheck?.type === RESOURCE_TYPES.CLUSTERS
                      ? strings.automatedCheck.passingClusters
                      : strings.automatedCheck.passingRepositories}
                  </div>
                  <div>
                    <span className="automated-check-modal__number-items">{automatedCheck?.passing || 0}</span>
                  </div>
                </div>
                <div className="automated-check-modal__items-section">
                  <div>
                    {automatedCheck?.type === RESOURCE_TYPES.CLUSTERS
                      ? strings.automatedCheck.failingClusters
                      : strings.automatedCheck.failingRepositories}
                  </div>
                  <div>
                    <span className="automated-check-modal__number-items">{automatedCheck?.failing || 0}</span>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        <div className="automated-check-modal__automated-policy-status">
          <div className="automated-check-modal__policy-evaluated">
            <div>{strings.automatedCheck.policy}</div>
            <p className="automated-check-modal__check-text">{check.text}</p>
            {check.reportType ? (
              <p className="automated-check-modal__check-text">{`${strings.automatedCheck.automationDetails1.replace(
                '#report_type',
                check.reportType,
              )} ${
                check.eventTypes?.length
                  ? strings.automatedCheck.automationDetails2.replace('#event_types', check.eventTypes.join(', '))
                  : ''
              }`}</p>
            ) : null}
          </div>
          <Select
            className="automated-policy-status"
            classNamePrefix="automated-policy-status"
            isSearchable={false}
            options={policyStatusOptions}
            value={policyStatus}
            onChange={onPolicyStatusSelected}
          />
        </div>
        <div>
          <AccordionComponent
            title={strings.automatedCheck.additionalEvidence}
            containerClassNames="automated-check-modal__accordion-container"
            headerClassNames="automated-check-modal__accordion-header"
            bodyClassNames="automated-check-modal__accordion-body"
          >
            <Form.Control
              as="textarea"
              defaultValue={check?.evidence}
              placeholder={strings.automatedCheck.additionalEvidencePlaceholder}
              onChange={evidence}
              rows={4}
            />
          </AccordionComponent>
        </div>
        <div></div>
      </Modal.Body>
      <Modal.Footer>
        {policyStatus?.value === strings.automatedCheck.automated &&
          check.mode === Labels.Automated &&
          check.canBeAutomated && (
            <Button className="outline-btn" onClick={runAutomatedCheck}>
              {check.status === strings.automatedCheck.notStarted
                ? strings.automatedCheck.acceptEvidence
                : strings.automatedCheck.refreshEvidence}
            </Button>
          )}
        <Button
          type="submit"
          disabled={disableUpdate}
          onClick={updateReport}
          className="automated-check-modal__update-report"
        >
          {strings.automatedCheck.updateReport}
        </Button>
      </Modal.Footer>
      <Toaster />
      {isOwner && (
        <ContextMenu
          showCreateTicketModal={showCreateTicketModal}
          check={check}
          baseURL={baseURL}
          reportID={reportID}
        />
      )}
      {isOwner && isCreateTicketModalShown && (
        <CreateTicketModal
          router={router}
          closeModal={() => setIsCreateTicketModalShown(false)}
          route={route}
          showModal={isCreateTicketModalShown}
          reportID={reportID}
          complicanceCheckID={check.id}
          afterCreateTicketCallback={ticketCreated}
          isOwner={isOwner || false}
        />
      )}
    </Modal>
  );
};

export default AutomatedCheckModal;
