import React from 'react';
import { Column, Row } from 'react-table';
import Tag from '~reactComponents/Tags/Tag.react';
import { strings } from '~utils/strings';
import { ITableData } from './PolicyMapping.types.react';

export const TABLE_COLUMNS = (viewPolicyMapping: (row: Row<ITableData>) => void): Column<ITableData>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ row }: { row: Row<ITableData> }) => {
      return (
        <a className="policy-mapping-group-name-column" onClick={() => viewPolicyMapping(row)}>
          {row.original.name}
        </a>
      );
    },
  },
  {
    Header: 'App Groups',
    accessor: 'appGroups',
    Cell: ({ row }: { row: Row<ITableData> }) => {
      const appGroups = row.original.appGroups;
      return appGroups?.length ? (
        <div className="policy-mapping-tag-container">
          {appGroups.map((appGroup: string) => (
            <Tag
              hollow={true}
              fontSize={'0.875rem'}
              isRemovable={false}
              key={appGroup}
              tagClassNames="policy-mapping-table-tag"
            >
              {appGroup}
            </Tag>
          ))}
        </div>
      ) : null;
    },
  },
  {
    Header: 'Policies',
    accessor: 'policies',
    Cell: ({ row }: { row: Row<ITableData> }) => {
      const policies = row.original.policies;

      return policies?.length ? (
        <div className="policy-mapping-tag-container">
          {policies.map((policy: string) => (
            <Tag
              hollow={true}
              fontSize={'0.875rem'}
              isRemovable={false}
              key={policy}
              tagClassNames="policy-mapping-table-tag"
            >
              {policy}
            </Tag>
          ))}
        </div>
      ) : (
        <div className="policy-mapping-tag-container">
          <Tag hollow={true} fontSize={'0.875rem'} isRemovable={false} tagClassNames="policy-mapping-table-tag">
            {strings.policyMapping.allPolicies}
          </Tag>
        </div>
      );
    },
  },
  {
    Header: 'Contexts',
    accessor: 'contexts',
    Cell: ({ row }: { row: Row<ITableData> }) => {
      const contexts = row.original.contexts;

      return contexts?.length ? (
        <div className="policy-mapping-tag-container">
          {contexts.map((context: string) => (
            <Tag
              hollow={true}
              fontSize={'0.875rem'}
              isRemovable={false}
              key={context}
              tagClassNames="policy-mapping-table-tag"
            >
              {context}
            </Tag>
          ))}
        </div>
      ) : null;
    },
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    Cell: ({ row }: { row: Row<ITableData> }) => {
      const enabledLabel =
        row.original.enabled === undefined ? 'Enabled' : row.original.enabled ? 'Enabled' : 'Disabled';
      return (
        <div className="policy-mapping-tag-container">
          <Tag
            hollow={true}
            fontSize={'0.875rem'}
            isRemovable={false}
            key={enabledLabel}
            tagClassNames="policy-mapping-table-tag"
          >
            {enabledLabel}
          </Tag>
        </div>
      );
    },
  },
];
