import Profile from './Profile.react';

export default {
  name: 'profile',
  components: {
    'profile-page': Profile,
  },
  methods: {
    store() {
      return this.$store;
    },
    router() {
      return this.$router;
    },
  },
};
