import { Organization, IRouter, ReportStatus } from '~globalTypes';

export interface ClustersChartScore {
  favorite: boolean;
  Cluster: string;
  version?: string;
  workloads?: ReportStatus;
  admission?: ReportStatus;
  prometheus?: ReportStatus;
  passiveMode: boolean;
  nodesCount: number;
  difference?: number;
}

export interface ClusterHealthScoreSummary {
  Critical: number;
  Danger: number;
  HealthScore: number;
  High: number;
  Low: number;
  Medium: number;
  Name: string;
  None: number;
  Passing: number;
  Warning: number;
}

export interface HistoricalClusterScore {
  [option: string]: string;
}

export interface HistoricalCluster {
  Name: string;
  Scores: HistoricalClusterScore;
}

export interface TableColumnsParams {
  organization: Organization;
  loadingSummary: boolean | undefined;
  router: () => IRouter;
  changeFavorite: (item: ClustersChartScore) => void;
}

export enum TableAccessors {
  Favorite = 'favorite',
  Cluster = 'Cluster',
  Version = 'version',
  Workloads = 'workloads',
  Admission = 'admission',
  Prometheus = 'prometheus',
  Trends = 'trends',
}

export enum TimeUnits {
  Month = 'month',
  MMDD = 'MM/DD',
}

export enum ChartObjs {
  Line = 'line',
  Dot = 'dot',
}
