import { Alert, Button, Card, Form, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { toast, Toaster } from 'react-hot-toast';
import React, { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { AddButton, Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';
import Icon from '~reactComponents/Icons/Icon.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';
import { ALL_POLICIES, ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { UPDATE_ORG } from '~store/action.types';

import { COLORS } from '~utils/styling';
import { handlePageChange } from '~utils/global.helpers.react';
import { IRoute, IRouter, IStore, Membership } from '~utils/global.types.react';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import AddPolicyMappingModal from './components/AddPolicyMappingModal/AddPolicyMappingModal.react';
import UpdatePolicyMappingModal from './components/UpdatePolicyMappingModal/UpdatePolicyMappingModal.react';

import { TABLE_COLUMNS } from './PolicyMapping.config.react';

import { IPolicyMapping, ITableData } from './PolicyMapping.types.react';

import './PolicyMapping.react.scss';
import { Column, Row, useTable } from 'react-table';

interface PolicyMappingProps {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
}

export function PolicyMapping({ router, route, store }: PolicyMappingProps) {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const organization = store().getters.organization;
  const isOrgOwner = store().getters.isOrgOwner;

  const [selectedScanMode, setSelectedScanMode] = useState<string>(organization?.PolicyStrategy || '');

  const [isAddPolicyMappingModalShown, setIsAddPolicyMappingModalShown] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<string>(organization?.PolicyStrategy || '');
  const [loading, setLoading] = useState<boolean>(true);
  const [policyMappingsTableData, setPolicyMappingsTableData] = useState<ITableData[]>([]);
  const [selectedPolicyMapping, setSelectedPolicyMapping] = useState<IPolicyMapping | null>(null);
  const [isUpdatePolicyMappingModalShown, setIsUpdatePolicyMappingModalShown] = useState<boolean>(false);
  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] = useState<boolean>(false);
  const [policyMappingNameToDelete, setPolicyMappingNameToDelete] = useState<string>('');

  const transformPolicyMappings = (policyMappings: IPolicyMapping[]): ITableData[] => {
    if (!policyMappings?.length) {
      return [];
    }

    return policyMappings.map((policyMapping) => {
      return {
        id: String(policyMapping.id),
        name: policyMapping.name,
        appGroups: policyMapping?.spec?.appGroups || [],
        policies: policyMapping?.spec?.policies || [],
        contexts: policyMapping?.spec?.contexts || [],
        enabled: policyMapping?.spec?.enabled,
      };
    });
  };

  const getPolicyMappings = async () => {
    setLoading(true);

    try {
      setPolicyMappingsTableData(
        transformPolicyMappings(await sendRequest('GET', `${baseURL}/policy-mappings`, {}, null)),
      );
    } catch (e) {
      logger.logError('error_retrieving_policy_mappings', e);
      toast.error(<b>{strings.policyMapping.errorRetrievingPolicyMappings}</b>);
    }

    setLoading(false);
  };

  useEffect(() => {
    getPolicyMappings();
  }, []);

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ALL_POLICIES,
      label: strings.navigation.Policy,
      href: `/orgs/${org}/policy`,
    },
    {
      id: 'last',
      label: strings.navigation.policyMapping,
      href: ``,
      isActive: true,
    },
  ];

  const scanModeOptions = useMemo(
    () => [
      {
        label: strings.policyMapping.focusedRecommended,
        value: strings.policyMapping.appGroups,
        tooltip: strings.policyMapping.focusedTooltip,
      },
      {
        label: strings.policyMapping.everything,
        value: strings.policyMapping.scanAll,
        tooltip: strings.policyMapping.everythingTooltip,
      },
    ],
    [],
  );

  const onScanModeChanged = (e: SyntheticEvent) => {
    e.stopPropagation();

    setSelectedScanMode((e.target as HTMLInputElement).value);
  };

  const updateScanMode = async () => {
    if (!selectedScanMode) {
      toast.error(strings.policyMapping.scanModeRequired);
      return;
    }

    try {
      await sendRequest(
        'PATCH',
        `${baseURL}`,
        {
          data: {
            PolicyStrategy: selectedScanMode,
          },
        },
        null,
      );

      toast.success(strings.policyMapping.scanModeUpdated);

      store().dispatch(UPDATE_ORG, org);

      setCurrentMode(selectedScanMode);
    } catch (e) {
      logger.logError('error_updating_scan_mode', e);
      toast.error(<b>{strings.policyMapping.failedToUpdateScanMode}</b>);
    }
  };

  const data = useMemo(() => policyMappingsTableData, [policyMappingsTableData]);

  const viewPolicyMapping = async (row: Row<ITableData>) => {
    if (!row.original.name) {
      return;
    }

    const selectedPolicyMapping = await getPolicyMappingByName(row.original.name);

    if (!selectedPolicyMapping) {
      return;
    }

    setSelectedPolicyMapping(selectedPolicyMapping);
    setIsUpdatePolicyMappingModalShown(true);
  };

  const columns = useMemo<Column<ITableData>[]>(() => TABLE_COLUMNS(viewPolicyMapping), [policyMappingsTableData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const isOwner = useMemo(() => {
    const matchingOrg = store().getters.memberships.find((membership: Membership) => membership.Organization === org);

    return matchingOrg?.IsOwner || store().getters.user?.IsSuperAdmin;
  }, [org]);

  const onPolicyMappingAdded = () => {
    getPolicyMappings();
  };

  const onPolicyMappingUpdated = () => {
    getPolicyMappings();
  };

  const getPolicyMappingByName = async (policyMappingName: string) => {
    if (!policyMappingName) {
      return;
    }

    try {
      return await sendRequest('GET', `${baseURL}/policy-mappings/${policyMappingName}`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_policy_mapping_by_name', e);
      toast.error(<b>{strings.policyMapping.errorRetrievingPolicyMappingByName}</b>);
    }
  };

  const deletePolicyMapping = async () => {
    if (!policyMappingNameToDelete) {
      toast.error(<b>{strings.policyMapping.cannotDeletePolicyMapping}</b>);
      return;
    }

    try {
      await sendRequest('DELETE', `${baseURL}/policy-mappings/${policyMappingNameToDelete}`, {}, null);

      setIsDeleteConfirmModalShown(false);

      toast.success(strings.policyMapping.deletePolicyMappingSuccess);

      await getPolicyMappings();
    } catch (e) {
      logger.logError('error_deleting_policy_mapping', e);
      toast.error(<b>{strings.policyMapping.cannotDeletePolicyMapping}</b>);
    }
  };

  return (
    <LayoutReact className="policy-mapping">
      <Breadcrumbs
        data={breadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div
        className={`policy-mapping-scan-mode ${
          currentMode === strings.policyMapping.appGroups ? 'policy-mapping-scan-mode-space' : ''
        }`}
      >
        <div className="policy-mapping-scan-mode-title" data-cy="policy-mapping-scan-mode-title">
          {strings.policyMapping.scanMode}
        </div>
        <div className="policy-mapping-scan-mode-option-container">
          {scanModeOptions.map((option) => (
            <div
              key={`policy-mapping-scan-mode-option__${option.value}`}
              data-cy={`policy-mapping-scan-mode-option-${option.value}`}
            >
              <Form.Check
                inline
                key={option.value}
                id={option.value}
                type="radio"
                label={option.label}
                value={option.value}
                checked={selectedScanMode === option.value}
                defaultChecked={selectedScanMode === option.value}
                onChange={(e) => onScanModeChanged(e)}
                data-cy={`policy-mapping-scan-mode-radio-option-${option.value}`}
              />
              <OverlayTrigger
                placement="left"
                overlay={
                  <Popover id={`tooltip-${option.value}`}>
                    <Popover.Content>{option.tooltip}</Popover.Content>
                  </Popover>
                }
              >
                <InfoIcon style={{ marginBottom: '0.75rem', marginRight: '1rem' }} />
              </OverlayTrigger>
            </div>
          ))}
          <Button
            className="btn policy-mapping-scan-mode-option-update-btn"
            type="button"
            onClick={updateScanMode}
            data-cy="policy-mapping-update-scan-mode-btn"
          >
            {strings.Update}
          </Button>
        </div>
      </div>
      {currentMode === strings.policyMapping.scanAll ? (
        <Alert
          variant="warning"
          className="policy-mapping-scan-mode-warning warning-alert"
          data-cy="policy-mapping-scan-mode-warning-alert"
        >
          <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
          {strings.policyMapping.warningMessage}
        </Alert>
      ) : null}
      <Card className="policy-mapping-card">
        <Card.Body>
          {isOrgOwner || isOwner ? (
            <AddButton
              buttonText={strings.policyMapping.addPolicyMapping}
              action={() => setIsAddPolicyMappingModalShown(true)}
              disabled={!isOwner}
              testLabel="add-button"
            />
          ) : null}
          {loading && (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" className="policy-mapping-summary-spinner">
                <span className="sr-only">{strings.policyMapping.loading}</span>
              </Spinner>
            </div>
          )}
          {!loading && policyMappingsTableData.length > 0 && (
            <table
              {...getTableProps()}
              className="policy-mapping-summary-table"
              aria-label={strings.appGroups.appGroupsSummaryTable}
              data-cy="policy-mappings-table"
            >
              <thead className="policy-mapping-summary-header">
                {headerGroups.map((headerGroup) => (
                  <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="policy-mapping-table-row">
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Card.Body>
      </Card>
      <AddPolicyMappingModal
        isModalShown={isAddPolicyMappingModalShown}
        onConfirmClicked={onPolicyMappingAdded}
        onModalHidden={() => setIsAddPolicyMappingModalShown(false)}
        organizationName={org}
      />
      <UpdatePolicyMappingModal
        isModalShown={isUpdatePolicyMappingModalShown}
        onModalHidden={() => {
          setSelectedPolicyMapping(null);
          setIsUpdatePolicyMappingModalShown(false);
        }}
        selectedPolicyMapping={selectedPolicyMapping}
        onToggleEnabledClicked={onPolicyMappingUpdated}
        onConfirmClicked={onPolicyMappingUpdated}
        organizationName={org}
        onDeleteModalShown={(policyMappingName: string) => {
          setIsDeleteConfirmModalShown(true);
          setPolicyMappingNameToDelete(policyMappingName);
        }}
        isViewing={!isOrgOwner && !isOwner}
      />
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.Cancel}
        confirmButtonText={strings.Delete}
        isModalShown={isDeleteConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.policyMapping.deletePolicyMappingConfirmationContent.replace(
          '$POLICY_MAPPING_NAME',
          policyMappingNameToDelete,
        )}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.policyMapping.deletePolicyMapping}
        onConfirmClicked={deletePolicyMapping}
        onModalHidden={(isModalShown: boolean | undefined) => setIsDeleteConfirmModalShown(isModalShown ? true : false)}
        modalClassName="update-policy-mapping-confirm-modal"
        dataCyConfirmButton="delete-policy-mapping-confirm-button"
      />
      <Toaster />
    </LayoutReact>
  );
}
