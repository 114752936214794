import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Filters } from 'react-table';
import { toast } from 'react-hot-toast';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import PieChart from '~reactComponents/charts/PieChart/PieChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';

import { VulnerabilitiesActionItems } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.types.react';
import { ChartType, Cluster, DateType } from '~globalTypes';

import { getChartURLSearchParams } from '../../../../ReactVulnerabilities.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { colorScheme } from '~config/main.config';
import { strings } from '~utils/strings';

import '../Charts.react.scss';
import { convertFiltersToURLForAllImages } from '../../VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.helpers.react';
import { AppGroup } from '~views/organization/actionItems/ActionItems.types.react';

type SeverityBreakdownProps = {
  baseURL?: string;
  cluster?: Cluster;
  filters: Filters<VulnerabilitiesActionItems>;
  isResolvedShown?: boolean;
  selectedAppGroup: AppGroup | null;
  firstSeenRange: DateType;
  noData?: React.ReactNode;
  setFilter: (columnId: string, updater: any) => void;
};

const SeverityBreakdown = ({
  baseURL,
  cluster,
  filters,
  firstSeenRange,
  noData,
  isResolvedShown,
  selectedAppGroup,
  setFilter,
}: SeverityBreakdownProps) => {
  const [severities, setSeverities] = useState<ChartType[]>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const chartClicked = useRef<boolean>(false);
  const isMounted = useRef<boolean>(false);

  useEffect(() => {
    isMounted.current = true;
    getSeverities();
    return () => {
      isMounted.current = false;
    };
  }, [cluster, isResolvedShown, firstSeenRange?.start, firstSeenRange?.end, selectedAppGroup]);

  useEffect(() => {
    if (filters?.length) {
      getSeverities();
    }
  }, [filters]);

  const getSeverities = async () => {
    try {
      setLoaded(false);
      const params = getChartURLSearchParams({
        groupBy: strings.vulnerabilities.severity_lowercase,
        cluster,
        isResolvedShown,
        selectedAppGroup,
        firstSeen: firstSeenRange?.start,
        firstSeenEnd: firstSeenRange?.end,
      });
      const URLParams = convertFiltersToURLForAllImages(filters, params);
      const response = await sendRequest('GET', `${baseURL}/images/vulnerabilities/top?${URLParams}`, {}, null);
      if (isMounted.current) {
        setSeverities(transformResponse(response));
        setLoaded(true);
      }
    } catch (e) {
      logger.logError('error_retrieving_severities: ', e);
      toast.error(strings.vulnerabilities.errorVulnSeverities);
    }
  };

  const transformResponse = (response: ChartType[]) => {
    if (!response?.length) return [];
    return response.map((res) => ({ ...res, value: res.value?.toLowerCase() }));
  };

  const data = useMemo(() => {
    return severities?.map((severity) => ({
      label: severity.value.charAt(0).toUpperCase() + severity.value.slice(1),
      id: severity.value,
      color: (colorScheme as Record<string, string>)[severity.value],
      value: severity.count,
    }));
  }, [severities]);

  const handleChartClick = (data) => {
    if (chartClicked.current && filters?.find((filter) => filter.id === 'severityLabel')?.value?.length) return;
    setFilter('severityLabel', [{ value: data.id, label: data.id }]);
    chartClicked.current = true;
  };

  const clearFilter = () => {
    setFilter('severityLabel', []);
    chartClicked.current = false;
  };

  if (!loaded) return <LoadingSpinner />;

  return (
    <>
      <ClearButton
        isChartClicked={chartClicked.current}
        clearFilter={clearFilter}
        isFiltered={filters?.find((filter) => filter.id === 'severityLabel')?.value?.length}
      />
      {data?.length ? (
        <div aria-label={strings.ariaLabels.severityPieChart} className="vulnerabilities--charts">
          <PieChart data={data || []} handleChartClick={handleChartClick} />
        </div>
      ) : (
        <>{noData}</>
      )}
    </>
  );
};

export default SeverityBreakdown;
