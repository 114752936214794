import React, { useState, useEffect } from 'react';
import { Card } from '@fairwindsops/ui-components';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { OrgActivitiesProps, Activity, ResponseActivity } from './Activities.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { strings } from '~utils/strings';
import { getCurrentTimezone } from '~reactHelpers';
import { CardTitlePrimary, BasicText, XSText } from '~utils/texts.react';

import './Activities.react.scss';

dayjs.extend(timezone);

const OrgActivities = ({ organization }: OrgActivitiesProps) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [expanded, setExpanded] = useState(false);
  const timeZone = getCurrentTimezone();

  useEffect(() => {
    init();
  }, [organization]);

  const init = async () => {
    try {
      const response = await sendRequest(
        'GET',
        `/v0/organizations/${organization.Name}/activities`,
        {},
        null,
      );
      setActivities(
        response.activities.map((activity: ResponseActivity) => {
          const split = activity.activityType.split('.');
          let title;
          let action;
          if (split[1] === 'repositories') {
            title = strings.general.Repository;
          } else {
            title = strings.general[split[1].slice(0, split[1].length - 1)];
          }
          if (split.length === 4) {
            action = `${strings[split[2].slice(0, split[2].length - 1)]} ${strings[split[3]]}`;
          }
          if (split.length === 5) {
            action = `${strings.userRole} ${strings.updated}`;
          }
          const date = timeZone
            ? dayjs(activity.createdAt).tz(timeZone).format('MM/DD/YYYY')
            : dayjs(activity.createdAt).format('MM/DD/YYYY');
          const time = timeZone
            ? dayjs(activity.createdAt).tz(timeZone).format('h:mm A')
            : dayjs(activity.createdAt).format('h:mm A');
          return {
            title,
            action: action || `${title} ${split[2]}`,
            date,
            time,
          };
        }),
      );
    } catch (e) {
      logger.logError('error_org_activities', e);
    }
  };

  const chooseClass = (action: string) => {
    const foundAction = action.split(' ');
    switch (foundAction[1]) {
      case 'added':
      case 'created':
        return 'added';
      case 'deleted':
      case 'removed':
        return 'removed';
      case 'updated':
      default:
        return 'updated';
    }
  };

  const ActivitiesList = (): JSX.Element => {
    let activitiesToDisplay;
    if (expanded) {
      activitiesToDisplay = activities;
    } else {
      activitiesToDisplay = activities.length > 5 ? activities.slice(0, 5) : activities;
    }
    const list = activitiesToDisplay.map((activity: Activity, index: number) => {
      return (
        <li className={chooseClass(activity.action)} key={`${activity.title}-${index}`}>
          <div className="activity">
            <div
              className={BasicText({
                size: 'extraSpacing',
                weight: strings.textStyling.thin,
                textTransform: strings.textStyling.capitalize,
              })}
            >
              <span
                className={BasicText({
                  weight: strings.textStyling.medium,
                  textTransform: strings.textStyling.capitalize,
                })}
              >
                {activity.title}:{' '}
              </span>
              <span>{activity.action}</span>
            </div>
            <div className={XSText({ weight: strings.textStyling.thin })}>
              {`${activity.date} ${strings.general.at} ${activity.time}`}
            </div>
          </div>
        </li>
      );
    });
    return <ul className={expanded ? 'expanded' : 'not-expanded'}>{list}</ul>;
  };

  return (
    <Card data-cy="latest-activities" className="org-latest-activities not-expanded">
      <Card.Body style={{ padding: '0.5rem 1.5rem' }}>
        <div>
          <Card.Header>
            <h2 className={CardTitlePrimary()}>{strings.latestActivity}</h2>
            <span
              onClick={() => setExpanded((prevState) => !prevState)}
              className="org-latest-activities__title-right"
            >
              {strings.general.seeAll}
            </span>
          </Card.Header>
          <div className="org-latest-activities_body">
            <ActivitiesList />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrgActivities;
