import React, { useEffect, useState, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';

import { CommonLabels, IRouter, IStore, OptionType } from '~utils/global.types.react';
import { strings } from '~utils/strings';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';

import { Labels, data } from './RegionSettings.config.react';

import './RegionSettings.react.scss';
import { ORG_DASHBOARD, PROFILE } from '~reactComponents/NavigationReact/Navigation.config.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { handlePageChange } from '~utils/global.helpers.react';

type CountryType = {
  name: string;
  alpha2: string;
  alpha3: string;
};

const RegionSettings = ({ store, router }: { store: () => IStore; router: () => IRouter }) => {
  const user = store().getters.user;
  const organization = localStorage.getItem('organization') || '';
  const [timeZones, setTimeZones] = useState<OptionType[]>([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState<OptionType | null>(null);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<OptionType>({
    value: '',
    label: strings.region.selectCountry,
  });

  useEffect(() => {
    const getCountries = async () => {
      let response, user;
      const requests = [
        sendRequest('GET', '/v0/countries', {}, null),
        sendRequest('GET', '/v0/current-user', {}, null),
      ];
      try {
        [response, user] = await Promise.allSettled(requests);
      } catch (e) {
        logger.logError('error_retrieving_countries', e);
      }
      setCountries(response?.value || []);

      if (user?.value?.user?.Country)
        setSelectedCountry({
          value: user.value.user.Country.Alpha2,
          label: user.value.user.Country.Name,
        });
    };
    const savedTimeZone = localStorage.getItem(CommonLabels.selectedTimezone);
    setSelectedTimeZone(savedTimeZone ? JSON.parse(savedTimeZone) : null);
    getTimezones();
    getCountries();
  }, []);

  const countryOptions = useMemo(
    () => countries.map((country: CountryType) => ({ value: country.alpha2, label: country.name })),
    [countries],
  );

  const getTimezones = () => {
    setTimeZones(transformTimezones());
  };

  const transformTimezones = () => {
    const transformedTimezones = [] as OptionType[];
    for (const timezone of data)
      transformedTimezones.push({
        label: timezone.text,
        value: String(timezone.utc),
      });
    return transformedTimezones;
  };

  const onTimezoneChanged = (selection: OptionType) => {
    setSelectedTimeZone(selection);
  };

  const saveTimezone = () => {
    if (!selectedTimeZone) return;
    localStorage.setItem(CommonLabels.selectedTimezone, JSON.stringify(selectedTimeZone));
    toast.success(Labels.timeZoneSavedMessage);
  };

  const saveCountry = async () => {
    let response;
    const data = {
      FirstName: user.FirstName,
      LastName: user.LastName,
      JobTitle: user.JobTitle,
      CountryAlpha2: selectedCountry.value,
    };
    try {
      response = await sendRequest('PUT', '/v0/user', { data }, null);
    } catch (e) {
      logger.logError('error_adding_country_to_user', e);
    }
    if (response?.Success) toast.success(Labels.countrySaved);
  };

  const onSubmit = () => {
    saveCountry();
    saveTimezone();
  };

  const breadcrumbsRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: organization,
      href: `/orgs/${organization}/dashboard`,
    },
    {
      id: PROFILE,
      label: strings.navigation.userSettings,
      href: `/user-settings/details`,
    },
    {
      id: 'last',
      label: 'Region',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="region-container">
        <Breadcrumbs
          data={breadcrumbsRoutesList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <p className="region-container__description">{Labels.description}</p>
        <div className="region-container__dropdown-container">
          <div>
            <span className="region-container__time-zone-label">{Labels.timezone}</span>
            <Select
              className="region-container__time-zone-select"
              value={selectedTimeZone}
              isMulti={false}
              isSearchable
              onChange={onTimezoneChanged}
              options={timeZones}
            />
          </div>
          <div>
            <span className="region-container__time-zone-label">{Labels.country}</span>
            <Select
              className="region-container__country-select"
              value={selectedCountry}
              isMulti={false}
              isSearchable
              onChange={setSelectedCountry}
              options={countryOptions}
            />
          </div>
        </div>
        <Button
          className="region-container__update-btn"
          onClick={onSubmit}
          disabled={!selectedTimeZone || !selectedCountry}
          data-cy="submit-region-button"
        >
          {Labels.update}
        </Button>
        <Toaster />
      </div>
    </LayoutReact>
  );
};

export default RegionSettings;
