import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CardDeck } from 'react-bootstrap';
import {
  Column,
  FilterTypes,
  TableInstance,
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import toast, { Toaster } from 'react-hot-toast';

import { REACT_VULNERABILITIES_ALL_VULNERABILITIES } from '~reactComponents/NavigationReact/Navigation.config.react';
import { Card } from '@fairwindsops/ui-components';
import EmptyData from '~reactComponents/EmptyData/EmptyData.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import Table from '~reactComponents/Table/Table.react';
import DescriptionPanel from '~reactComponents/DescriptionPanel/DescriptionPanel.react';
import { DescriptionPanelProps } from '~reactComponents/DescriptionPanel/DescriptionPanel.types.react';

import SelectColumnFilter from '../SelectColumnFilter/SelectColumnFilter.react';
import TableCardHeader from '../TableCardHeader/TableCardHeader.react';

import { Cluster, CommonLabels, DateType, FiltersMapType, IRoute, IRouter, Organization } from '~globalTypes';
import { convertFiltersToURL, getCurrentTimezone } from '~reactHelpers';
import { sendRequest } from '~utils/request';
import logger from '~logger';

import { ChartLabels, Labels, VulnerabilitiesActionItems } from './VulnerabilitiesItemsTable.types.react';
import { COLUMNS_ORDER, CONTENT, PAGE_SIZE, TABLE_COLUMNS, TOP_ROW } from './VulnerabilitiesItemsTable.config.react';

import {
  exportActionItems,
  exportCSV,
  getExportURLSearchParams,
  getTrivyReports,
  PAGES as EXPORT_PAGES,
} from '../../../ReactVulnerabilities.helpers.react';

import './VulnerabilitiesItemsTable.react.scss';
import { downloadCsv } from '~utils/helpers';
import CvesReportModal from '../Modals/CvesReportModal.react';

dayjs.extend(relativeTime);
dayjs.extend(timezone);

type VulnerabilitiesItemsTableProps = {
  baseURL: string;
  cluster?: Cluster;
  organization: Organization;
  route: IRoute;
  savedSelectionsKey?: string;
  router: () => IRouter;
};

const VulnerabilitiesItemsTable = ({
  baseURL,
  cluster,
  organization,
  route,
  savedSelectionsKey,
  router,
}: VulnerabilitiesItemsTableProps) => {
  const [vulnActionItems, setVulnActionItems] = useState<VulnerabilitiesActionItems[]>([]);
  const [selectedVulnActionItem, setSelectedVulnActionItem] = useState<VulnerabilitiesActionItems | null>(null);
  const vulnActionItemFilters = useRef<FiltersMapType>({});
  const initialFilters = useRef<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<ModalInfo>({ show: false, modal: '' });
  const [firstSeenRange, setFirstSeenRange] = useState<DateType | null>(
    (() => {
      const searchParams = new URLSearchParams(window.location.search);
      const firstSeenStart = searchParams.get('firstSeen');
      const firstSeenEnd = searchParams.get('firstSeenEnd');
      return firstSeenStart && firstSeenEnd ? { start: firstSeenStart, end: firstSeenEnd } : null;
    })(),
  );
  const [descriptionItem, setDescriptionItem] = useState<DescriptionPanelProps>({} as DescriptionPanelProps);
  const [syncedDate, setSyncedDate] = useState('');
  const skipTableResetRef = useRef<boolean>();
  const sortBy = useRef<{ orderBy: string; desc: boolean }>({ orderBy: '', desc: false });
  const isMounted = useRef<boolean>(false);
  // store the number of total items. It will be used to export all/filtered items.
  const totalExportItems = useRef<number>(0);
  const totalExportFilteredItems = useRef<number>(0);

  const isFirstLoaded = useRef<boolean>(true);

  const timeZone = getCurrentTimezone();

  const closeModal = () => setShowModal({ show: false, modal: '' });

  useEffect(() => {
    isMounted.current = true;
    initQueries();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getTrivyReports(baseURL, cluster!, (lastSyncedDate: string) => {
      setSyncedDate(lastSyncedDate);
    });
  }, [cluster]);

  useEffect(() => {
    fetchVulnActionItemsDebounced();
  }, [globalSearch, firstSeenRange?.start, firstSeenRange?.end, cluster]);

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipTableResetRef.current = false;
  });

  useEffect(() => {
    if (selectedVulnActionItem) {
      buildDescriptionItem();
    } else {
      setDescriptionItem({} as DescriptionPanelProps);
    }
  }, [selectedVulnActionItem]);

  const getAffectedImagesInfo = async (cveCode: string) => {
    if (!cveCode) {
      return;
    }
    try {
      return await sendRequest('GET', `${baseURL}/vulnerabilities/${cveCode}/images`, { showErrorAlert: false }, null);
    } catch (e) {
      console.error('ERROR: ', e);
      logger.logError('error_loading_affected_images_information_all_vulnerabilities_page: ', e);
    }
  };

  const buildDescriptionItem = async () => {
    let affectedImagesInfo = [];
    if (selectedVulnActionItem?.cveCode) {
      affectedImagesInfo = await getAffectedImagesInfo(selectedVulnActionItem?.cveCode);
    }
    const severityLabel = selectedVulnActionItem?.cveSeverity;
    setDescriptionItem({
      selectedItem: {
        metadata: TOP_ROW(selectedVulnActionItem!),
        content: CONTENT(
          selectedVulnActionItem!,
          selectedVulnActionItem?.imageNames,
          affectedImagesInfo,
          organization.Name,
          router,
        ),
        created: null,
        updated: null,
        dateReported: selectedVulnActionItem!.createdAt,
        title: selectedVulnActionItem!.cveTitle ? selectedVulnActionItem!.cveTitle : selectedVulnActionItem!.cveCode,
        severity: severityLabel
          ? `${severityLabel.charAt(0).toUpperCase()}${severityLabel.slice(1).toLowerCase()}`
          : null,
        category: null,
      },
      setSelectedItem: setSelectedVulnActionItem,
      page: '',
      openContextMenu: null,
    });
  };

  // Restores user selection for hidden/ordered columns
  const tableColumnsStorage: Record<string, string[]> = useMemo(
    () => (savedSelectionsKey ? JSON.parse(localStorage.getItem(savedSelectionsKey) || '{}') : {}),
    [],
  );

  const tableHiddenColumns = useMemo(() => {
    return tableColumnsStorage?.hiddenColumns || [];
  }, []);

  const data = React.useMemo(() => {
    return vulnActionItems;
  }, [vulnActionItems]);

  const ColumnFiltering = (tableInstance: TableInstance) => (
    <SelectColumnFilter tableInstance={tableInstance} filters={vulnActionItemFilters.current} setLoaded={setLoaded} />
  );

  const columns = React.useMemo<Column<VulnerabilitiesActionItems>[]>(
    () => TABLE_COLUMNS(ColumnFiltering),
    [vulnActionItems],
  );

  const filterTypes = React.useMemo<FilterTypes<VulnerabilitiesActionItems>>(
    () => ({
      text: (rows, ids, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[ids[0]];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState: {
        pageSize: PAGE_SIZE,
        filters: initialFilters.current,
        columnOrder: tableColumnsStorage?.columnOrder || COLUMNS_ORDER,
        hiddenColumns: tableHiddenColumns,
      },
      manualFilters: true,
      manualPagination: true,
      autoResetHiddenColumns: false,
      manualSortBy: true,
      autoResetFilters: !skipTableResetRef.current,
      autoResetSortBy: !skipTableResetRef.current,
      autoResetPage: !skipTableResetRef.current,
      autoResetExpanded: !skipTableResetRef.current,
      autoResetGroupBy: !skipTableResetRef.current,
      autoResetRowState: !skipTableResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useColumnOrder,
  );

  useEffect(() => {
    if (savedSelectionsKey) {
      localStorage.setItem(
        savedSelectionsKey,
        JSON.stringify({
          hiddenColumns: tableInstance.state.hiddenColumns,
          columnOrder: tableInstance.state.columnOrder,
        }),
      );
    }
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    if (initialFilters?.current.length > 0 && loaded) {
      tableInstance.setAllFilters(initialFilters.current);
    }
  }, [initialFilters.current, loaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filters = await sendRequest(
          'GET',
          `${baseURL}/vulnerabilities/summaries-filters`,
          { showErrorAlert: false },
          null,
        );
        vulnActionItemFilters.current = {
          ...vulnActionItemFilters.current,
          ...transformFilters(filters),
        };
      } catch (e) {
        console.error('ERROR: ', e);
        logger.logError('error_loading_action_items_all_vulnerabilities_page: ', e);
        toast.error('Error loading page. Please try again!');
      }
    };
    fetchData();
  }, [organization]);

  const transformFilters = (filters: FiltersMapType) => {
    if (!filters) return;
    for (const key in filters) {
      if (key === Labels.cveSeverities) {
        filters[key] = (filters[key] as string[])?.map((filterValue) => filterValue.toLowerCase());
      }
    }
    return filters;
  };

  const initQueries = () => {
    const navigation = window.performance.getEntriesByType('navigation') as any;
    if ((navigation[0].type === 'reload' || navigation[0].type === 'navigate') && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('redirect', 'true');
      router()
        .replace({ path: `${route.path}?${searchParams.toString()}` })
        .catch(() => {});
    }
  };

  const hydrateColumnFilters = (searchParams: URLSearchParams) => {
    const columnFilters: any[] = [];
    for (const item of searchParams.entries()) {
      if (tableInstance.allColumns.some((col) => col.id === item[0])) {
        const filterIdx = columnFilters.findIndex((filter) => filter.id === item[0]);
        if (filterIdx >= 0) {
          columnFilters[filterIdx].value.push({
            value: item[1],
            label: item[1],
          });
        } else {
          columnFilters.push({
            id: item[0],
            value: [
              {
                value: item[1],
                label: item[1],
              },
            ],
          });
        }
      } else {
        hydrateSecondaryFilters(item);
      }
    }
    initialFilters.current = columnFilters;
  };

  const hydrateSecondaryFilters = (item: [string, string]) => {
    switch (item[0]) {
      case 'search':
        setGlobalSearch(item[1]);
        break;
      case 'pageSize':
        tableInstance.state.pageSize = Number(item[1]) || PAGE_SIZE;
        break;
      case 'page': {
        tableInstance.state.pageIndex = Number(item[1]) || 0;
        tableInstance.gotoPage(Number(item[1]) || 0);
        break;
      }
      case 'orderBy': {
        sortBy.current = {
          ...sortBy.current,
          orderBy: item[0] === 'orderBy' ? item[1] : '',
        };
        break;
      }
      case 'desc': {
        sortBy.current = {
          ...sortBy.current,
          desc: item[1] === 'true',
        };
        if (sortBy.current.orderBy) {
          tableInstance.setSortBy([
            {
              id: sortBy.current.orderBy,
              desc: sortBy.current.desc,
            },
          ]);
        }
        break;
      }
      default:
        return;
    }
  };

  const fetchVulnActionItems = useCallback(async () => {
    const { pageIndex, pageSize, sortBy, filters } = tableInstance.state;
    // When data gets updated with this function, set a flag
    // to disable all of the auto resetting
    skipTableResetRef.current = true;

    let searchParams: URLSearchParams;

    if (!window.location.search.includes('redirect')) {
      searchParams = new URLSearchParams();
      searchParams.set('page', pageIndex.toString());
      searchParams.set('desc', 'true');
    } else {
      searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('redirect');
      searchParams.set('page', searchParams.get('page') ? (searchParams.get('page') as string) : pageIndex.toString());
    }

    if (!searchParams.has('pageSize')) {
      searchParams.set('pageSize', pageSize.toString());
    }

    if (firstSeenRange?.start) {
      searchParams.set(
        'firstSeen',
        `${timeZone ? dayjs(firstSeenRange.start).tz(timeZone).toISOString() : dayjs(firstSeenRange.start).toISOString()}`,
      );
    }
    if (firstSeenRange?.end) {
      searchParams.set(
        'firstSeenEnd',
        `${timeZone ? dayjs(firstSeenRange.end).tz(timeZone).toISOString() : dayjs(firstSeenRange.end).toISOString()}`,
      );
    }

    if (!loaded && isMounted.current) {
      hydrateColumnFilters(searchParams);
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    } else {
      searchParams.delete('search');
    }

    searchParams = convertFiltersToURL(filters, searchParams);

    if (sortBy[0]) {
      searchParams.set('orderBy', `${sortBy[0].id === Labels.HasFixedVersion ? Labels.FixedVersion : sortBy[0].id}`);
      searchParams.set('desc', `${sortBy[0].desc}`);
    } else {
      searchParams.delete('orderBy');
      searchParams.delete('desc');
    }

    if (globalSearch) {
      searchParams.set('search', globalSearch);
    }

    if (cluster) {
      searchParams.set('cluster', cluster.Name);
    }

    try {
      const response = await sendRequest(
        'GET',
        `${baseURL}/vulnerabilities/summaries?${searchParams.toString()}`,
        { returnHeaders: true },
        null,
      );
      const data = response.data;
      if (isMounted.current) {
        setVulnActionItems(data?.summaries);
        setTotalRowCount(data.total);
        if (isFirstLoaded.current) {
          totalExportItems.current = data.total;
          isFirstLoaded.current = false;
        }
        totalExportFilteredItems.current = data.total;
        tableInstance.pageCount = data.total;
        setLoaded(true);
        const routeName = router().currentRoute?.name;
        if (routeName && routeName.includes(REACT_VULNERABILITIES_ALL_VULNERABILITIES)) {
          router()
            .replace({ path: `${window.location.pathname}?${searchParams.toString()}` })
            .catch(() => {});
        }
      }
    } catch (e) {
      console.error(e);
      logger.logError('error_fetching_vuln_action_items: ', e);
      toast.error('Error fetching vuln Action Items');
    }
  }, [loaded, globalSearch, firstSeenRange?.start, firstSeenRange?.end, cluster]);

  const fetchVulnActionItemsDebounced = useAsyncDebounce(fetchVulnActionItems, 250);

  const totalPage = useMemo(
    () => Math.ceil(totalRowCount / tableInstance.state.pageSize),
    [totalRowCount, tableInstance.state.pageSize],
  );

  const handlePaginationChange = (page: number): void => {
    if (page < totalPage && tableInstance.state.pageIndex !== page) {
      tableInstance.gotoPage(page);
      setLoaded(false);
    }
  };

  const onVulnActionItemSelected = (selectedVulnActionItem: VulnerabilitiesActionItems) => {
    if (!selectedVulnActionItem) return;
    setSelectedVulnActionItem(selectedVulnActionItem);
    logger.logEvent(`react-vulnerabilities-all-vulnerabilites:select-item`, selectedVulnActionItem);
  };

  const handleExport = async (action: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (action === 'Export CVEs by App Group to CSV') {
      setShowModal({ show: true, modal: 'cvesReport' });
      return;
    }
    exportCSV({
      action,
      exportAllCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.ALL_VULNERABILITIES,
          apiEndpoint: `${baseURL}/vulnerabilities/summaries?${getExportURLSearchParams(
            cluster!,
            totalExportItems.current,
          ).toString()}`,
          orgName: organization.Name,
        }),
      exportFilteredCallback: () =>
        exportActionItems({
          from: EXPORT_PAGES.ALL_VULNERABILITIES,
          apiEndpoint: `${baseURL}/vulnerabilities/summaries?${searchParams.toString()}`,
          orgName: organization.Name,
        }),
      exportCvesByAppGroupCallback: async () => {},
      totalExportFilteredItems: totalExportFilteredItems.current,
      searchParams,
      logEventKey: 'react-vulnerabilities-all-vulnerabilites',
      cluster: cluster?.Name,
    });
  };

  const handleExportCvesByAppGroup = async (selectedTimeRange: DateType, statuses: string[], severities: string[]) => {
    try {
      let url = `${baseURL}/app-groups/cves-statistics/export?startTime=${selectedTimeRange.start}&endTime=${selectedTimeRange.end}`;
      if (statuses.length) {
        url += statuses.map((status) => `&status=${status}`).join('');
      }
      if (severities.length) {
        url += severities.map((severity) => `&severity=${severity}`).join('');
      }
      const data = await sendRequest('GET', url, { showErrorAlert: false }, null);
      downloadCsv(data, 'cve_app_groups_report_download');
      closeModal();
    } catch (e) {
      logger.logError('cve_app_groups_reports_error_download: ', e);
      toast.error('Unable to download the CVEs report. Try again.');
    }
  };

  return (
    <>
      <Toaster />
      <div>
        <div className="vulnerabilities--charts-container" aria-hidden="true">
          <CardDeck>
            {(Object.keys(ChartLabels) as Array<keyof typeof ChartLabels>).map((label) => {
              const ChartComponent = ChartLabels[label].component;
              return (
                <Card>
                  <Card.Header className="vulnerabilities__card-header">
                    <h2>{ChartLabels[label].title}</h2>
                  </Card.Header>
                  <Card.Body className="vulnerabilities__chart-container" padded>
                    <ChartComponent
                      noData={<EmptyData label={CommonLabels.NoData} />}
                      baseURL={baseURL}
                      filters={tableInstance.state.filters}
                      setFilter={tableInstance.setFilter}
                      cluster={cluster}
                      firstSeenRange={firstSeenRange}
                    />
                  </Card.Body>
                </Card>
              );
            })}
          </CardDeck>
          <div className="all-vulnerabilities-action-items--container">
            <Card className="all-vulnerabilities-action-items">
              <TableCardHeader
                firstSeenRange={firstSeenRange}
                globalSearch={globalSearch}
                handleExport={handleExport}
                isHidingColsSupported
                selectedVulnActionItem={selectedVulnActionItem}
                setFirstSeenRange={setFirstSeenRange}
                setGlobalSearch={setGlobalSearch}
                setLoaded={setLoaded}
                syncedDate={syncedDate}
                tableInstance={tableInstance}
                totalRowCount={totalRowCount}
              />
              <Table
                caption={Labels.AllVulnerabilitiesVulnerabilitiesItems}
                tableInstance={tableInstance}
                pagination={
                  <Pagination
                    currentPage={tableInstance.state.pageIndex}
                    pageChange={handlePaginationChange}
                    totalCount={totalPage}
                  />
                }
                onDataFetched={fetchVulnActionItemsDebounced}
                onItemSelected={onVulnActionItemSelected}
                loaded={loaded}
              />
            </Card>
            <DescriptionPanel
              selectedItem={descriptionItem?.selectedItem}
              setSelectedItem={setSelectedVulnActionItem}
              page={descriptionItem?.page}
              openContextMenu={descriptionItem?.openContextMenu}
            />
          </div>
        </div>
      </div>
      <CvesReportModal showModal={showModal} closeModal={closeModal} exportCves={handleExportCvesByAppGroup} />
    </>
  );
};

export default VulnerabilitiesItemsTable;

export type CvesReportModalProps = {
  showModal: ModalInfo;
  closeModal: () => void;
  exportCves: (selectedTimeRange: DateType, selectedStatus: string[], selectedSeverity: string[]) => void;
};

export type ModalInfo = {
  show: boolean;
  modal: string;
  meta?: Record<string, unknown>;
};
