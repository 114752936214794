import React, { useState, useEffect } from 'react';
import { Alert, Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';

import { LayoutReact, Breadcrumbs } from '@fairwindsops/ui-components';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';

import { INTEGRATIONS, ORG_DASHBOARD, TEAM_MANAGEMENT } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IRouter, IStore } from '~utils/global.types.react';

import { handlePageChange } from '~utils/global.helpers.react';
import { formatCost } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import logger from '~utils/logger';

import './Datadog.react.scss';

const Datadog = ({ store, router }: { store: () => IStore; router: () => IRouter }) => {
  const { organization, organizationTierAccess, isOrgOwner } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;
  const datadogURL = `${baseURL}/datadog`;
  const [costEstimates, setCostEstimates] = useState<Record<string, number>>();
  const [costEstimatesError, setCostEstimatesError] = useState<boolean>(false);
  const [datadogSettings, setDatadogSettings] = useState<Record<string, number>>();
  const [costMetrics, setCostMetrics] = useState<boolean>(false);
  const [datadogAPIKey, setDatadogAPIKey] = useState<string>('');
  const [showUpgradePrompt, setShowUpgradePrompt] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  useEffect(() => {
    if (isOrgOwner) {
      refreshDatadogTokenSet();
      getDatadogCostEstimate();

      if (loadingError) {
        toast.error('There was an issue retrieving your data. Refresh the page to try again.');
        setLoadingError(false);
      }
    }
  }, []);

  const refreshDatadogTokenSet = async () => {
    let response;
    try {
      response = await sendRequest('GET', datadogURL, {}, null);
    } catch (e) {
      setLoadingError(true);
      logger.logError('error_retrieving_datadog_token_set', e);
    }
    setCostMetrics(response.SendDatadogCostMetrics);
    setDatadogSettings(response);
  };

  const getDatadogCostEstimate = async () => {
    let response;
    try {
      response = await sendRequest('GET', `${datadogURL}/event-estimates`, {}, null);
    } catch (e) {
      setLoadingError(true);
      setCostEstimatesError(true);
      logger.logError('error_getting_datadog_cost_estimate', e);
    }
    setCostEstimates(response);
  };

  const connectDatadog = async () => {
    setShowUpgradePrompt(false);
    if (organizationTierAccess?.DatadogIntegration) {
      try {
        await sendRequest(
          'POST',
          datadogURL,
          {
            data: datadogAPIKey,
            headers: { 'Content-Type': 'text/plain' },
          },
          null,
        );
        await refreshDatadogTokenSet();
        toast.success(strings.Success);
      } catch (e) {
        toast.error(e.message);
        logger.logError('error_connecting_datadog', e);
      }
    } else {
      setShowUpgradePrompt(true);
    }
  };

  const disconnectDatadog = async () => {
    try {
      await sendRequest('DELETE', datadogURL, {}, null);
      await refreshDatadogTokenSet();
      toast.success(strings.Success);
    } catch (e) {
      toast.error(e.message);
      logger.logError('error_disconnecting_datadog', e);
    }
  };

  const updateDatadogCostMetricsCheck = async () => {
    const data = {
      DatadogCostMetrics: !datadogSettings?.SendDatadogCostMetrics,
    };

    try {
      await sendRequest('PATCH', `${datadogURL}/send-cost-metrics`, { data }, null);
      setCostMetrics((prevState) => !prevState);
      toast.success(strings.Success);
    } catch (e) {
      toast.error(e.message);
      logger.logError('error_updating_datadog_cost_metrics', e);
    }
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: TEAM_MANAGEMENT,
      label: strings.navigation.Settings,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: INTEGRATIONS,
      label: strings.navigation.Integrations,
      href: `/orgs/${organization.Name}/settings`,
    },
    {
      id: 'last',
      label: 'Datadog Integration',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="datadog-integration">
      <div>
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        {showUpgradePrompt ||
          (!organizationTierAccess.DatadogIntegration && (
            <Alert variant={strings.noTranslate.info}>
              Datadog is available in our commercial tier. Please{' '}
              <a href="https://www.fairwinds.com/insights-getstarted" target="_blank">
                visit our website
              </a>{' '}
              to learn about upgrade options, or contact{' '}
              <a href={strings.noTranslate.mailToSales}>sales@fairwinds.com</a>
            </Alert>
          ))}
        <p>{strings.datadog.integrationText}</p>
        {costEstimates?.ActionItemEvents && (
          <p>
            <i>
              Connecting Insights to Datadog will add approximately {formatCost(costEstimates?.ActionItemEvents / 100)}{' '}
              to your Datadog bill in custom metrics charges
            </i>
          </p>
        )}
        {datadogSettings?.IsDatadogTokenSet ? (
          <Form>
            <Form.Check
              type="checkbox"
              label="Include cost metrics"
              id="datadogCostMetrics"
              className="datadog-integration__form"
              checked={costMetrics}
              onChange={updateDatadogCostMetricsCheck}
            />
            {costEstimates?.CostEvents && (
              <p>
                <i>
                  Sending cost metrics Datadog will add approximately {formatCost(costEstimates?.CostEvents / 100)} to
                  your Datadog bill in custom metrics charges
                </i>
              </p>
            )}
            {costEstimatesError && (
              <p className="datadog-integration__error">
                <i>{strings.datadog.estimatesError}</i>
              </p>
            )}
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                disconnectDatadog();
              }}
            >
              {strings.datadog.disconnect}
            </Button>
          </Form>
        ) : (
          <>
            <h2 className="datadog-integration__subtitle">{strings.datadog.subtitle}</h2>
            {organizationTierAccess?.DatadogIntegration && (
              <Form>
                <Form.Group>
                  <div className="datadog-integration__tooltip">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Popover id={`tooltip-datadog-api`}>
                          <Popover.Content>{strings.datadog.tooltipText}</Popover.Content>
                        </Popover>
                      }
                    >
                      <InfoIcon className="datadog-integration__tooltip-icon" />
                    </OverlayTrigger>
                    <Form.Label>{strings.datadog.apiKey}</Form.Label>
                  </div>
                  <Form.Control type="text" onChange={(e) => setDatadogAPIKey(e.target.value)} />{' '}
                </Form.Group>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    connectDatadog();
                  }}
                  data-cy="connect-to-datadog-button"
                >
                  {strings.datadog.connect}
                </Button>
              </Form>
            )}
          </>
        )}
      </div>
      <Toaster />
    </LayoutReact>
  );
};

export default Datadog;
