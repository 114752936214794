import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { strings } from '~utils/strings';

type DeleteModalProps = {
  page: string;
  name?: string;
  text: string;
  deleteFunction: () => any;
  showModal: boolean;
  setShowModal: (value: boolean) => any;
};

const DeleteModal = ({
  page,
  name,
  text,
  deleteFunction,
  showModal,
  setShowModal,
}: DeleteModalProps) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} style={{ paddingTop: '0' }}>
      <Modal.Header closeButton>
        <Modal.Title>{`${strings.Remove} ${page}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          {strings.Cancel}
        </Button>
        <Button variant="danger" onClick={deleteFunction}>
          {`${strings.Remove} ${name || page}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
