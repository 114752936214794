import { Alert, Card, Row, Col, Form, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import React, { useEffect, useMemo, useState } from 'react';
import timezone from 'dayjs/plugin/timezone';

import Icon from '~reactIcons/Icon.react';
import {
  ORG_DASHBOARD,
  AUTOMATION,
  NEWRULE,
  AUTOMATION_TEMPLATES,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import { ITemplate } from './AutomationTemplatesList.types.react';
import { IRouter, Organization } from '~globalTypes';

import automationTemplateConfigs from '~config/automationRules.config';
import logger from '~logger';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { handlePageChange } from '~utils/global.helpers.react';

import './AutomationTemplatesList.react.scss';

dayjs.extend(timezone);

type AutomationTemplatesListProps = {
  isOrgOwner: boolean;
  organization: Organization;
  router: () => IRouter;
};

const AutomationTemplatesList = ({
  isOrgOwner,
  organization,
  router,
}: AutomationTemplatesListProps): React.ReactNode => {
  const [templates, setTemplates] = useState<ITemplate[]>(automationTemplateConfigs);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredTemplates, setFilteredTemplates] = useState<ITemplate[]>([]);

  const searchInstance = useMemo(() => {
    return new Fuse(
      templates as ITemplate[],
      {
        keys: ['Title'],
      } as Fuse.IFuseOptions<ITemplate>,
    );
  }, [templates]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const onTemplateClicked = (rule: ITemplate) => {
    logger.logEvent(`rule-templates: selected ${rule.Title} to edit`);
    router().push({ name: 'edit-rule-template', params: { rule } });
  };

  const onTemplatesSearched = (event: React.FormEvent) => {
    const target = event.target as HTMLInputElement;
    const rules = searchInstance.search(target.value).map((res) => res.item);
    setSearch(target.value);
    setFilteredTemplates(rules);
  };

  const createCustomRule = () => {
    router().push({ name: NEWRULE, params: { rules: templates, organization } });
  };

  const createFromTemplate = () => {
    router().push({ name: AUTOMATION_TEMPLATES });
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: AUTOMATION,
      label: strings.navigation.Automation,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: AUTOMATION,
      label: 'All Rules',
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: strings.automationRule.templates,
      href: `${window.location.pathname}`,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="automation-templates--list">
        <Breadcrumbs
          data={breadcrumbsList}
          onClick={(route: string) => {
            handlePageChange(router, route);
          }}
        />
        <div className="d-flex justify-content-between mb-3">
          {filteredTemplates.length || templates.length ? (
            <div className="automation-rules--list__header">
              <Form className="automation-rules--search">
                <Form.Group controlId="automation-rules-search">
                  <Form.Control
                    onChange={onTemplatesSearched}
                    placeholder={strings.automationRule.search}
                    type="search"
                    value={search}
                  />
                </Form.Group>
              </Form>
              {isOrgOwner && (
                <div className="automation-rules--list__actions">
                  <Button className="btn" type="button" onClick={createCustomRule}>
                    {strings.automationRule.createCustomRule}
                  </Button>
                  <Button
                    className="automation-rules--list__create-from-template"
                    data-cy="create-from-template-button"
                    onClick={createFromTemplate}
                    type="button"
                    variant="secondary"
                  >
                    {strings.automationRule.createFromTemplate}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
        {!isOrgOwner && (
          <Alert variant="warning" className="warning-alert">
            <Icon name="exclamation-triangle" width="1.5rem" fill={COLORS.CORE.WARNING} />
            <h4 className="warning-title">
              {strings.automationRule.onlyOwner} <strong>{strings.automationRule.readOnly}</strong>
            </h4>
          </Alert>
        )}
        {!filteredTemplates.length && !templates.length && !loading ? (
          <div className="automation-rules--zero">
            <p>
              <strong className="align-center">
                {strings.automationRule.noRulesFound}
                <br />
              </strong>
              <a
                href={
                  router().resolve({ name: NEWRULE, params: { rules: templates, organization } })
                    .href
                }
              >
                {strings.automationRule.createOneNow}
              </a>{' '}
              {strings.automationRule.or}{' '}
              <a
                href="https://insights.docs.fairwinds.com/configure/automation/rules/"
                target="_blank"
              >
                {strings.automationRule.readTheDocumentation}
              </a>
            </p>
          </div>
        ) : null}
        <Row xs={1} lg={1}>
          {filteredTemplates.length || templates.length || !loading ? (
            (filteredTemplates.length ? filteredTemplates : templates).map((rule, idx) => (
              <Col key={idx}>
                <Card
                  onClick={() => onTemplateClicked(rule)}
                  role="button"
                  data-cy="automation-templates--list--card"
                  className="automation-card automation-templates--list--card"
                >
                  <Card.Body>
                    <div className="automation-templates--list--card">
                      <span
                        role="heading"
                        aria-level={1}
                        className="automation-templates--list--card__title"
                        title={rule.Title}
                      >
                        {rule.Title}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <ThinCardLoader />
          )}
        </Row>
      </div>
    </LayoutReact>
  );
};

const ThinCardLoader = () => {
  return (
    <>
      {Array.from({ length: 7 }).map((_, idx) => (
        <Col key={idx}>
          <Card>
            <Card.Body>
              <div className="automation-templates--list--card">
                <span className="skeleton skeleton__first" />
                <span
                  className="skeleton"
                  style={{ width: `${Math.random() * (23 - 13) + 11}rem` }}
                />
              </div>
              <span className="skeleton rounded skeleton__first" />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default AutomationTemplatesList;
