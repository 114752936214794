import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SelectedReport } from '../Compliance.types.react';

type ModalProps = {
  show: string;
  closeModal: () => void;
  selectedReport: SelectedReport | null;
  isOwner: boolean;
  deleteReport: () => void;
};

const DeleteModal = ({
  show,
  closeModal,
  selectedReport,
  isOwner,
  deleteReport,
}: ModalProps): JSX.Element => {
  return (
    <Modal show={show === 'delete'} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          {selectedReport ? `Delete ${selectedReport.name} report?` : 'No report to delete'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedReport
          ? `Are you sure you want to delete ${selectedReport.name} report?`
          : 'Return to list and try again'}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Cancel</Button>
        <Button variant="danger" onClick={deleteReport} disabled={!isOwner}>
          Delete Report
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
