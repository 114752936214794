import React from 'react';
import { Form } from 'react-bootstrap';
import './SearchFilterBar.react.scss';

type SearchFilterProps = {
  searchFunction?: (event: unknown) => void;
  className?: string;
  children?: any;
};

const SearchFilterBar = ({ searchFunction, className, children }: SearchFilterProps) => {
  return (
    <div className={`action-bar mb-4 ${className}`} role="toolbar">
      {searchFunction && (
        <Form className="search-bar" onSubmit={(e) => e.preventDefault()}>
          <Form.Group>
            <Form.Control
              type="search"
              placeholder="Search"
              onChange={searchFunction}
              data-cy="search-text-input"
            />
          </Form.Group>
        </Form>
      )}
      {children && (
        <div className={`search-bar-buttons ${searchFunction ? 'search' : 'no-search'}`}>
          {children}
        </div>
      )}
    </div>
  );
};

export default SearchFilterBar;
