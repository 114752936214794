import React from 'react';
import { OptionProps } from 'react-select';
import { OptionType } from '~globalTypes';

import { COLORS } from '~utils/styling';

const colors: Record<string, string> = {
  introduced: COLORS.CHARTS.ACTION_ITEMS_REPORTS.INTRODUCED,
  fixed: COLORS.CHARTS.ACTION_ITEMS_REPORTS.FIXED,
  open: COLORS.CHARTS.ACTION_ITEMS_REPORTS.OPEN,
  resolved: COLORS.CHARTS.ACTION_ITEMS_REPORTS.RESOLVED,
  deleted: COLORS.CHARTS.ACTION_ITEMS_REPORTS.DELETED,
};

const Option = (props: OptionProps<OptionType, true>) => {
  const { data, label, isSelected, selectOption } = props;

  return (
    <div
      title={label}
      className="plain-select-checkbox"
      onClick={(event) => {
        event.preventDefault();
        selectOption(data);
      }}
    >
      <input
        type="checkbox"
        id={`${label}-checkbox`}
        checked={isSelected}
        style={{ pointerEvents: 'none' }}
        onClick={(event) => event.preventDefault()}
        onChange={() => {}}
      />
      <span>
        <svg width="15" height="15">
          <rect fill={colors[label]} width="15" height="15" />
        </svg>
      </span>
      <span className="select-checkbox__label">{label}</span>
    </div>
  );
};

export default Option;
