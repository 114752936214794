import { COLORS } from '~utils/styling';

export const labelsForChart = {
  idleNodesCost: 'Idle Cost',
  filteredResourcesCost: 'Billed Cost Shown',
  otherResourcesCost: 'Billed Cost Not Shown',
  totalFee: 'Fees',
};

export const colors = {
  idleNodesCost: COLORS.CHARTS.COSTS_DONUT.IDLE,
  filteredResourcesCost: COLORS.CHARTS.COSTS_DONUT.FILTERED,
  otherResourcesCost: COLORS.CHARTS.COSTS_DONUT.OTHER,
  totalFee: COLORS.CHARTS.COSTS_DONUT.FEE,
};
