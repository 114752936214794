import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

import { CreateModalProps } from '../../ActionItems.types.react';

import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import './CreateList.react.scss';

const CreateListModal = ({
  showModal,
  closeModal,
  listNames,
  listType,
  ImportListOptions,
  setQuery,
  query,
  submitNewList,
  error,
}: CreateModalProps) => {
  const [createdQuery, setCreatedQuery] = useState<string>('');
  const [importedValue, setImportedValue] = useState<string>('');
  const [listNameValue, setListNameValue] = useState<string>('');

  const handleCreateQuery = () => {
    setQuery(createdQuery);
  };

  const handleSubmit = () => {
    submitNewList(listNameValue, importedValue);
  };
  return (
    <Modal show={showModal.show} onHide={closeModal} className="create-list-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{strings.actionItemsList.newList}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="list-form">
          <Form.Group>
            <Form.Label>{strings.actionItemsList.listName}</Form.Label>
            <Form.Control
              type="text"
              placeholder={strings.Name}
              onChange={(event) => setListNameValue(event.target.value)}
              isInvalid={listNames?.includes(listNameValue || '')}
              required
              data-cy="list-name-text-input"
            />
            <Form.Control.Feedback type="invalid">{strings.actionItemsList.nameExists}</Form.Control.Feedback>
          </Form.Group>
          {listType === 'manual' && (
            <Form.Group>
              <Form.Control.Feedback type="invalid">{strings.actionItemsList.nameExists}</Form.Control.Feedback>
              <Form.Control
                as="select"
                onBlur={(event: React.SyntheticEvent) => {
                  const target = event.target as HTMLInputElement;
                  if (target.value === 'List') return;
                  setImportedValue(target.value);
                }}
              >
                <option>{strings.List}</option>
                <ImportListOptions />
              </Form.Control>
            </Form.Group>
          )}
          {listType === 'query' && (
            <Form.Group>
              <div className="query-header">
                <Form.Label>{strings.actionItemsList.actionItemQuery}</Form.Label>
              </div>
              <Form.Control
                style={{ backgroundColor: COLORS.CORE.DARKEST_GRAY, color: COLORS.CORE.WHITE }}
                className="query-input"
                as="textarea"
                rows={3}
                defaultValue={query || undefined}
                placeholder="ReportType=polaris AND Severity=critical,high"
                onBlur={handleCreateQuery}
                onChange={(event) => setCreatedQuery(event?.target.value)}
                data-cy="query-text-input"
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="create-list-modal__footer">
        <div className="create-list-modal__actions">
          <Button variant="secondary" onClick={closeModal}>
            {strings.general.Cancel}
          </Button>
          <div>
            <Button
              onClick={() => {
                if (listType === 'manual') {
                  submitNewList(listNameValue, importedValue);
                } else {
                  handleSubmit();
                }
              }}
              disabled={!listNameValue}
              data-cy="submit-create-list-button"
            >
              {strings.actionItemsList.createList}
            </Button>
          </div>
        </div>
        {error && <span className="error create-list-modal__error">{error}</span>}
      </Modal.Footer>
    </Modal>
  );
};

export default CreateListModal;
