import InviteAccount from './InviteAccount.react';

export default {
  name: 'invite-account',
  components: {
    'invite-account-page': InviteAccount,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
