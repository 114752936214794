import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { TableInstance } from 'react-table';

import Icon from '~reactIcons/Icon.react';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import RepoSeverities from '../RepoSeverities/RepoSeverities.react';
import LineChart from '~reactComponents/charts/LineChart/LineChart.react';

import { TableColumnsParams, TableAccessors, Labels } from './RepoChart.types.react';
import { COLORS } from '~utils/styling';
import { strings } from '~utils/strings';

export const TABLE_COLUMNS = ({
  loadingTrends,
  onFavoriteChanged,
  onRepositorySelected,
}: TableColumnsParams) => {
  return [
    {
      Header: (
        <span className="repositories-chart-card__star-icon-container">
          <Icon name="star-fill" width="16" height="16" fill={COLORS.CORE.LINK} />
        </span>
      ),
      accessor: TableAccessors.Favorite,
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      defaultCanSort: true,
      sortType: 'basic',
      disableResizing: true,
      Cell: (data: TableInstance) => {
        const item = data.row.original;
        return (
          <div
            className="repositories-chart-card__star-icon"
            onClick={() => onFavoriteChanged(item)}
          >
            <Icon
              name={item.Favorite ? 'star-fill' : 'star'}
              width="16"
              height="16"
              fill={item.Favorite ? COLORS.CORE.LINK : ''}
            />
          </div>
        );
      },
    },
    {
      Header: strings.columnTitles.Name,
      accessor: TableAccessors.Name,
      width: 225,
      minWidth: 225,
      defaultCanSort: true,
      Cell: (data: TableInstance) => {
        const item = data.row.original;

        if (!item?.Name) return <>{Labels.NoNameAvailable}</>;

        const renderTooltip = (name: string) => (
          <Popover id={`repo-severities-tooltip-${name}`}>
            <Popover.Content>{name}</Popover.Content>
          </Popover>
        );

        return (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(item.Name)}
          >
            <span
              className="cell-container repositories-chart-card__name"
              onClick={() => onRepositorySelected(item)}
            >
              {item.Name}
            </span>
          </OverlayTrigger>
        );
      },
    },
    {
      Header: strings.columnTitles.Severities,
      accessor: TableAccessors.Severities,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      disableSortBy: true,
      Cell: (data: TableInstance) => {
        const item = data.row.original;
        if (loadingTrends) {
          return (
            <LoadingSpinner
              containerClassNames="repositories-chart-card__loading-container"
              spinnerClassNames="repositories-chart-card__loading"
            />
          );
        }
        return <RepoSeverities id={item.ID} repoSeverity={item.Severities} isFromRepo />;
      },
    },
    {
      Header: strings.columnTitles.Branches,
      accessor: TableAccessors.Branches,
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      defaultCanSort: true,
      Cell: ({ value }: { value: number }) => {
        if (loadingTrends) {
          return (
            <LoadingSpinner
              containerClassNames="repositories-chart-card__loading-container"
              spinnerClassNames="repositories-chart-card__loading"
            />
          );
        }
        return <span>{value}</span>;
      },
    },
    {
      Header: strings.columnTitles.actionItems,
      accessor: TableAccessors.ActionItems,
      width: 100,
      minWidth: 100,
      maxWidth: 150,
      defaultCanSort: true,
      Cell: ({ value }: { value: number }) => {
        if (loadingTrends) {
          return (
            <LoadingSpinner
              containerClassNames="repositories-chart-card__loading-container"
              spinnerClassNames="repositories-chart-card__loading"
            />
          );
        }
        return <span>{value}</span>;
      },
    },
    {
      Header: strings.columnTitles.EligibleAutoFixCount,
      accessor: TableAccessors.EligibleAutoFixCount,
      width: 100,
      minWidth: 100,
      maxWidth: 150,
      defaultCanSort: true,
      Cell: ({ value }: { value: number }) => {
        if (loadingTrends) {
          return (
            <LoadingSpinner
              containerClassNames="repositories-chart-card__loading-container"
              spinnerClassNames="repositories-chart-card__loading"
            />
          );
        }
        return <span>{value}</span>;
      },
    },
    {
      Header: strings.columnTitles.Trends,
      accessor: TableAccessors.Trends,
      width: 125,
      minWidth: 125,
      maxWidth: 200,
      disableSortBy: true,
      Cell: (data: TableInstance) => {
        const { Name: name, Trends: trends } = data.row.original;
        const chartData = trends[0]?.data ? trends[0].data : [];

        const chartNotShow = () => {
          let count = 0;
          for (const data of chartData) {
            if (data && data.y) {
              count++;
            }
          }
          return count < 4;
        };

        if (loadingTrends)
          return (
            <LoadingSpinner
              containerClassNames="repositories-chart-card__loading-container repositories-chart-card__trends-loading-container"
              spinnerClassNames="repositories-chart-card__loading"
            />
          );

        if (chartNotShow()) {
          return (
            <div className="repositories-chart-card__trend-chart-message">
              {strings.general.notEnoughData}
            </div>
          );
        }

        const tooltip = (tooltipData: any) => {
          const pointData = tooltipData?.point?.data;
          if (!pointData || !pointData.xFormatted || !pointData.yFormatted) return <></>;
          return (
            <div className="repositories-chart-card__tooltip">
              {`${strings.general.dataOn} ${pointData?.xFormatted}: ${pointData?.yFormatted}`}
            </div>
          );
        };

        const props = {
          colors: [COLORS.CHARTS.BLUE[100]],
          data: trends,
          margin: { top: 10, right: 10, bottom: 10, left: 10 },
          dotColor: COLORS.CHARTS.BLUE[100],
          pointSize: 5,
          tooltip: tooltip,
          xScale: { type: 'point' },
          yScale: {
            max: 'auto',
            min: 'auto',
            reverse: false,
            stacked: true,
            type: 'linear',
          },
        };

        return (
          <div
            id={`line-chart-${name}`}
            className="repositories-chart-card__line-chart"
            aria-label={strings.ariaLabels.repoTrendLineChart}
            role="img"
          >
            <LineChart {...props} />
          </div>
        );
      },
    },
  ];
};

export const CALCULATED_ACTION_ITEMS = [
  'CriticalActionItems',
  'HighActionItems',
  'MediumActionItems',
  'LowActionItems',
  'NoneActionItems',
];

export const CHART_COLORS = {
  GREEN: {
    LINE: COLORS.CHARTS.LINE.GREAT,
    DOT: COLORS.CHARTS.DOTS.GREAT,
  },
  RED: {
    LINE: COLORS.CHARTS.LINE.DANGER,
    DOT: COLORS.CHARTS.DOTS.DANGER,
  },
};

export const HIDDEN_SORT_ICONS = ['Severities', 'Trends'];

export const PAGE_SIZE = 5;
