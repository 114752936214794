import Dashboard from './Dashboard.react';

export default {
  name: 'org-dashboard',
  components: {
    dashboard: Dashboard,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
