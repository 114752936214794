import React from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

import './SplitButton.react.scss';
import ArrowDownIcon from '~reactIcons/ArrowDown.icon.react';
import { List } from '../../ActionItems.types.react';
import Icon from '~reactIcons/Icon.react';

interface SplitButtonProps {
  toggleTitle: string;
  buttonTitle: string;
  type: 'manual' | 'query';
  createAction: (type: 'query' | 'manual', list?: List) => void;
  lists: List[];
  disabled: boolean;
}

interface SplitButtonRef {
  children?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const SplitButton = ({
  toggleTitle,
  buttonTitle,
  lists,
  type,
  createAction,
  disabled,
}: SplitButtonProps): JSX.Element => {
  const CustomToggle = React.forwardRef<HTMLButtonElement, SplitButtonRef>(
    ({ children, onClick }, ref) => (
      <Button
        variant="primary"
        className="custom--split-button__toggle"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        disabled={disabled}
        data-cy="split-button-dropdown"
      >
        {children}
        <ArrowDownIcon height="8px" width="10px" className="ml-1" />
      </Button>
    )
  );

  const CustomButton = React.forwardRef<HTMLDivElement, SplitButtonRef>(({ children }, ref) => (
    <div className="custom--split-button__button" ref={ref}>
      {children}
    </div>
  ));

  const filteredLists = lists.filter((list) => list.Type === type);

  return (
    <Dropdown as={ButtonGroup}>
      <Button as={CustomButton}>{buttonTitle}</Button>

      <Dropdown.Toggle as={CustomToggle} split variant="primary">
        {toggleTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {filteredLists.map((list) => (
          <Dropdown.Item onSelect={() => createAction(type, list)} key={list.ID}>
            {list.Name}
          </Dropdown.Item>
        ))}
        {filteredLists.length > 0 ? <Dropdown.Divider /> : ''}
        <Dropdown.Item onSelect={() => createAction(type)} className="d-flex align-items-center">
          <span>Create List</span>
          <Icon name="plus-circle" height="16px" width="16px" className="ml-2" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SplitButton;
