import React from 'react';
import Tag from '../Tags/Tag.react';
import { OptionType } from '~globalTypes';
import './DisplayTags.react.scss';

type DisplayProps = {
  options: OptionType[];
  admin?: boolean;
  removeTag?: (option: OptionType) => void;
  hollow?: boolean;
  isGray?: boolean;
  justified?: string;
  tagClassNames?: string;
  fontSize?: string;
};

const DisplayTags = ({
  options,
  admin,
  removeTag,
  hollow,
  isGray,
  justified,
  tagClassNames,
  fontSize,
}: DisplayProps): JSX.Element => {
  let fixedOptions: OptionType[] = [...options];
  if (options.length >= 7) {
    fixedOptions = options.slice(0, 6);
    fixedOptions.push({ value: 'ellipsis', label: '...' });
  }

  return (
    <div className="tags-div" style={{ justifyContent: justified ? justified : 'flex-start' }}>
      {fixedOptions.map((option) => {
        return (
          <Tag
            isGray={isGray}
            hollow={hollow}
            fontSize={fontSize || '1rem'}
            isRemovable={admin}
            removeTag={removeTag}
            key={option.value}
            option={option}
            tagClassNames={tagClassNames}
          >
            {option.label}
          </Tag>
        );
      })}
    </div>
  );
};

export default DisplayTags;
