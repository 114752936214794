import { vexillaShould } from '~utils/feature-flags';
import { strings } from '~utils/strings';
import { NotificationPreference } from '../notifications/Notifications.types.react';

export type StatusType = 'available' | 'installed';

export interface Status {
  slack: StatusType;
  datadog: StatusType;
  github: StatusType;
  jira: StatusType;
  pagerduty: StatusType;
  azure: StatusType;
  msteams: StatusType;
}

export interface SlackIntegration {
  IsSlackAccessTokenSet: boolean;
  RedirectURL: string;
  SlackClientID: string;
  SlackState: string;
}

export interface MSTeamsIntegration {
  AuthenticationType: string;
  ClientID: string;
  IsAccessTokenSet: boolean;
  RedirectURL: string;
  State: string;
  URL: string;
}

export interface MSTeamsIntegrationPreferences {
  Teams: MSTeamsTeam[];
  NotificationPreferences: NotificationPreference[];
}

export interface MSTeamsTeam {
  id: string;
  name: string;
  channels: MSTeamsChannel[];
}

export interface MSTeamsChannel {
  id: string;
  name: string;
}

export interface HashMap {
  [option: string]: any;
}

export enum IntegrationsChannels {
  Slack = 'slack',
  Jira = 'jira',
  Pagerduty = 'pagerduty',
  Azure = 'azure',
  Datadog = 'datadog',
  Github = 'github',
  MSTeams = 'msteams',
}

export const integrationsTitles = vexillaShould(strings.featureFlags.msTeamsIntegration)
  ? ['slack', 'datadog', 'github', 'jira', 'pagerduty', 'azure', 'msteams']
  : ['slack', 'datadog', 'github', 'jira', 'pagerduty', 'azure'];
