import { useEffect, useState } from 'react';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import RulesEditor from '../components/rulesEditor/RulesEditor.react';

import { IRoute, IRouter, IRule, IRulesFormData, IStore } from '~utils/global.types.react';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { handlePageChange } from '~utils/global.helpers.react';

import { strings } from '~utils/strings';
import {
  AUTOMATION,
  ORG_DASHBOARD,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import './NewAutomation.react.scss';

type NewAutomationProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const sampleAction = `if (ActionItem.Severity <= 0.25) {
  ActionItem.Description = 'Low Risk';
}`;

const NewAutomation = ({ route, router, store }: NewAutomationProps): JSX.Element => {
  const [rules, setRules] = useState<IRule[]>([]);

  const organization = store().getters.organization;
  const baseURL = `/v0/organizations/${organization.Name}`;

  const getRules = async () => {
    try {
      return await sendRequest('GET', `${baseURL}/rules`, {}, null);
    } catch (e: unknown) {
      logger.logError('error_fetching_automation_rules', e);
      return [];
    }
  };

  useEffect(() => {
    (async () => {
      setRules(route?.params?.rules || (await getRules()));
    })();
  }, [route]);

  const addNewRule = async (data: IRulesFormData) => {
    try {
      await sendRequest('POST', `${baseURL}/rules/create`, { data }, null);
      router().push({ name: AUTOMATION });
    } catch (e: unknown) {
      toast.error(e.message);
      logger.logError('error_creating_new_automation_rule', e);
    }
  };

  const handleOnSubmit = async (data: IRulesFormData) => {
    if (!data) {
      return;
    }

    await addNewRule(data);
  };

  const breadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: organization.Name,
      href: `/orgs/${organization.Name}/dashboard`,
    },
    {
      id: AUTOMATION,
      label: strings.navigation.Automation,
      href: `/orgs/${organization.Name}/automation`,
    },
    {
      id: 'last',
      label: strings.automationRule.rulesWizard,
      href: `${window.location.pathname}`,
      isActive: true,
    },
  ];

  return (
    <LayoutReact>
      <div className="new-automation__container">
        <div className="new-automation__breadcrumb">
          <Breadcrumbs
            data={breadcrumbsList}
            onClick={(route: string) => {
              handlePageChange(router, route);
            }}
          />
        </div>
        <RulesEditor
          handleOnSubmit={handleOnSubmit}
          route={route}
          rule={{ Action: sampleAction }}
          rules={rules}
          store={store}
        />
        <Toaster />
      </div>
    </LayoutReact>
  );
};

export default NewAutomation;
