import React from 'react';
import { MultiValue } from 'react-select';

import Tag from '~reactComponents/Tags/Tag.react';
import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';

import { AggregatorBarData } from '../../Costs.types.react';
import { OptionType } from '~globalTypes';

import { aggregatorOptions } from '../../Costs.config.react';
import { formatCost } from '../../Costs.helpers.react';
import { strings } from '~utils/strings';

type BarChartProps = {
  costsData: Pick<AggregatorBarData, 'aggregator' | 'totalCost' | 'height'>[];
  maxCost: number;
  handleChartClick: ({ data }: { data: AggregatorBarData }) => void;
  aggregators: MultiValue<OptionType>;
};

function BarChart({ costsData, maxCost, handleChartClick, aggregators }: BarChartProps) {
  return (
    <LargeBarChart
      data={costsData}
      keys={[
        strings.noTranslate.cpuCost,
        strings.noTranslate.memoryCost,
        strings.noTranslate.networkReceiveCost,
        strings.noTranslate.networkTransmitCost,
        strings.noTranslate.storageCapacityCost,
      ]}
      indexBy="aggregator"
      margin={{ top: 20, right: 0, bottom: 30, left: 85 }}
      maxValue={maxCost + maxCost * 0.1}
      // eslint-disable-next-line
      // @ts-ignore
      colors={({ id, data }) => String(data[`${id}Color`])}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: strings.efficiency.totalCostsTimePeriod,
        legendPosition: 'middle',
        legendOffset: -70,
        format: (value: number) => {
          return `$${new Intl.NumberFormat('en').format(value.toFixed(2))}`;
        },
      }}
      onClick={(data: any) => handleChartClick(data)}
      tooltip={(data, label) => {
        let sectionTitle = label?.split('Cost')[0].trim();
        const section = label?.split(' - ')[0].trim();

        const tooltipAggregators = Object.keys(data).filter((key) =>
          (aggregators.length > 0
            ? aggregators
            : aggregatorOptions.concat([{ value: 'pod', label: strings.efficiency.Pod }])
          )
            .map((aggregator) =>
              aggregator.section
                ? `${aggregator.section}${strings.general.Labels}`
                : aggregator.value,
            )
            .includes(key),
        );

        if (sectionTitle === 'networkReceive') {
          sectionTitle = 'network receive';
        }
        if (sectionTitle === 'networkTransmit') {
          sectionTitle = 'network transmit';
        }
        if (sectionTitle === 'cpu') {
          sectionTitle = 'CPU';
        }

        return (
          <div className="costs-aggregator-bar-chart__tooltip">
            {tooltipAggregators.length ? (
              tooltipAggregators.map((aggregator) => (
                <p>
                  <strong>{`${aggregator.replace('Labels', ' Labels')}:`}</strong>
                  <span>
                    {Array.isArray(data[aggregator])
                      ? data[aggregator].map((label) => (
                          <span className="aggregator-labels-spacing">
                            <Tag isGray={true}>{`${label.name}=${label.value}`}</Tag>
                            &nbsp;
                          </span>
                        ))
                      : data[aggregator] || ''}
                  </span>
                </p>
              ))
            ) : (
              <></>
            )}
            <div>
              <strong>{`${sectionTitle}${strings.punctuation.colon}`}</strong>
              <span>{data[section] > 0 ? formatCost(data[section]) : strings.zero$}</span>
            </div>
            <div>
              <strong>{`${strings.general.Cost}${strings.punctuation.colon}`}</strong>
              <span>{data.totalCost > 0 ? formatCost(data.totalCost) : strings.zero$}</span>
            </div>
          </div>
        );
      }}
    />
  );
}

export default BarChart;
