import React from 'react';
import ReactMarkdown from 'react-markdown';

import PillBadge from '../PillBadge/PillBadge.react';
import CloseIcon from '../Icons/Close.icon.react';
import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import { dateFromToday } from '~utils/helpers';
import { Card } from '@fairwindsops/ui-components';

import { DescriptionPanelProps } from './DescriptionPanel.types.react';

import { escapeEvent } from '~reactHelpers';
import { useWindowEvents } from '~hooks/useWindowEvents.react';

import { labels } from './DescriptionPanel.config.react';
import { strings } from '~utils/strings';
import { XLBasicText, XSText, MediumBasicText, BasicText } from '~utils/texts.react';

import './DescriptionPanel.react.scss';

const DescriptionPanel = ({
  selectedItem,
  setSelectedItem,
  page,
  openContextMenu,
}: DescriptionPanelProps) => {
  useWindowEvents([
    escapeEvent(() => {
      setSelectedItem(null);
    }),
  ]);

  const Metadata = () => {
    if (selectedItem) {
      return (
        <>
          {selectedItem?.metadata.map((headerItem, key) => (
            <div key={key} className="metadata-item" title={headerItem.content || 'N/A'}>
              <h2
                className={MediumBasicText({
                  topMargin: strings.textStyling.md,
                  bottomMargin: strings.textStyling.xsBottom,
                })}
              >
                {headerItem.title}
              </h2>
              <span className={BasicText()}>{headerItem.content || 'N/A'}</span>
            </div>
          ))}
        </>
      );
    }
    return <></>;
  };

  const Description = () => {
    if (selectedItem) {
      return (
        <>
          {selectedItem.content.map((contentItem, key) => {
            if (contentItem.content) {
              return (
                <div key={key} className="description-panel--content">
                  {contentItem.title && <h2 className={MediumBasicText()}>{contentItem.title}</h2>}
                  <div className="markdown">
                    {contentItem.isJSX ? (
                      contentItem.content
                    ) : (
                      <ReactMarkdown>{contentItem.content}</ReactMarkdown>
                    )}
                  </div>
                </div>
              );
            }
            return '';
          })}
        </>
      );
    }
    return <></>;
  };

  return (
    <Card
      className={`${page}--description description-panel ${
        selectedItem ? 'custom-card' : `${page}--description__hidden description-panel__hidden`
      }`}
    >
      {selectedItem ? (
        <>
          <div className="description-panel--header">
            <div className="description-panel--header__close">
              <CloseIcon
                onClick={() => setSelectedItem(null)}
                className="description-panel__close"
              />
            </div>
            <div className="description-panel--header--container">
              <h2 className={XLBasicText()}>{selectedItem?.title}</h2>
              <div className="badges">
                {typeof selectedItem.severity === 'number' ? (
                  <PillBadge severity={selectedItem?.severity} />
                ) : (
                  <PillBadge text={selectedItem?.severity ? selectedItem.severity : ''} />
                )}
                {selectedItem?.category && <PillBadge color="low" text={selectedItem?.category} />}
                {openContextMenu && (
                  <button
                    className="additional-actions-btn"
                    onClick={(e) => openContextMenu(e)}
                    data-cy="additional-actions-button"
                  >
                    <img src={HorizontalEllipsis} alt="ellipsis icon" />
                  </button>
                )}
              </div>
            </div>
            {selectedItem.dateReported && (
              <div className="lower-title">
                <div className="first-seen">
                  <h2
                    className={XSText({
                      weight: strings.textStyling.medium,
                      bottomMargin: strings.textStyling.xsBottom,
                      topMargin: strings.textStyling.md,
                    })}
                  >
                    {strings.dateReported}
                  </h2>
                  <span
                    className={XSText({
                      bottomMargin: strings.textStyling.xsBottom,
                      topMargin: strings.textStyling.md,
                    })}
                  >
                    {dateFromToday(selectedItem?.dateReported)}
                  </span>
                </div>
              </div>
            )}
            {selectedItem.id && (
              <div>
                <div className="id-container">
                  <span className={MediumBasicText()}>ID</span>
                  <span>{selectedItem.id}</span>
                </div>
              </div>
            )}
            {(selectedItem.created || selectedItem.updated) && (
              <div className="lower-title">
                {selectedItem.created && (
                  <div className="first-seen">
                    <h2
                      className={XSText({
                        weight: strings.textStyling.medium,
                        bottomMargin: strings.textStyling.xsBottom,
                        topMargin: strings.textStyling.md,
                      })}
                    >
                      {page === labels.actionItems ? strings.firstSeen : strings.createdAt}
                    </h2>
                    <span
                      className={XSText({
                        bottomMargin: strings.textStyling.xsBottom,
                        topMargin: strings.textStyling.md,
                      })}
                    >
                      {dateFromToday(selectedItem?.created)}
                    </span>
                  </div>
                )}
                {selectedItem.updated && (
                  <div className="last-seen">
                    <h2
                      className={XSText({
                        weight: strings.textStyling.medium,
                        bottomMargin: strings.textStyling.xsBottom,
                        topMargin: strings.textStyling.md,
                      })}
                    >
                      {page === labels.actionItems ? strings.lastReported : strings.updatedAt}
                    </h2>
                    <span
                      className={XSText({
                        bottomMargin: strings.textStyling.xsBottom,
                        topMargin: strings.textStyling.md,
                      })}
                    >
                      {dateFromToday(selectedItem?.updated)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="body-content">
            <div className="body-row">
              <Metadata />
            </div>
            <Description />
          </div>
        </>
      ) : null}
    </Card>
  );
};

export default DescriptionPanel;
