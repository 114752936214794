import React from 'react';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Labels } from './Information.types.react';

import { ImageSummary } from '~globalTypes';

import './Information.react.scss';

type InformationProps = {
  imageSummary: ImageSummary | undefined;
  loaded: boolean;
};

const Information = ({ imageSummary, loaded }: InformationProps) => {
  return (
    <Card>
      <Card.Body padded>
        <div className="vulnerabilities-right-card__information-container">
          <h2 className="vulnerabilities-right-card__title">{Labels.Information}</h2>
          {loaded ? (
            <div className="vulnerabilities-right-card__information-body">
              <div>
                <h3
                  title={imageSummary?.sha ? imageSummary.sha : Labels.NA}
                  className="overflow-content"
                >
                  {imageSummary?.sha ? imageSummary.sha : Labels.NA}
                </h3>
                <h4>{Labels.SHA}</h4>
              </div>
              <div></div>
              <div>
                <h3
                  title={imageSummary?.operatingSystem ? imageSummary.operatingSystem : Labels.NA}
                  className="overflow-content"
                >
                  {imageSummary?.operatingSystem ? imageSummary.operatingSystem : Labels.NA}
                </h3>
                <h4>{Labels.OS}</h4>
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Information;
