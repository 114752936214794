import React, { useState, useMemo, useReducer, PropsWithoutRef } from 'react';
import { MultiValue } from 'react-select';
import dayjs, { Dayjs } from 'dayjs';

import LastSyncedDate from '~reactComponents/LastSyncedDate/LastSyncedDate.react';

import {
  DateType,
  Filters,
  SelectedFilters,
  WorkloadOption,
  TotalCosts,
  CostsOptions,
  ResourcesAction,
  LabelsAction,
  TableAction,
  SyncedDatesAction,
  CurrentAction,
  SavingsAvailable,
  Period,
} from '../Costs.types.react';
import { IRoute, OptionType } from '~utils/global.types.react';

import {
  getSelectedTimeRange,
  getSelectedAggregators,
  buildStartOfQuery,
  buildFilterParams,
  buildAggregatorsQuery,
} from '../Costs.helpers.react';

import {
  usageChartRequired,
  resourcesPerPodRequired,
  quickViews,
  genericReducer,
  filterReducer,
} from '../Costs.config.react';

import { getCurrentTimezone, formatDatetime, hasKey } from '~utils/global.helpers.react';
import { strings } from '~utils/strings';

const useCostsData = (cluster: string, route: IRoute) => {
  const timeZone = getCurrentTimezone();
  const currentLocation = new URLSearchParams(window.location.search);
  const [selectedTimeRangeChanged, setSelectedTimeRangeChanged] = useState<boolean>(false);

  // reducers
  const [{ resources, summaries, totals }, setResources] = useReducer(genericReducer<ResourcesAction>, {
    resources: [],
    summaries: [],
    totals: { costRecommendationTotal: 0, numberOfRows: 0, totalCost: 0 },
  });
  const [{ workloadLabels, namespaceLabels, podLabels }, setLabels] = useReducer(genericReducer<LabelsAction>, {
    workloadLabels: [],
    namespaceLabels: [],
    podLabels: [],
  });
  const [
    {
      syncedAWSDate,
      syncedCloudCostsDate,
      syncedPrometheusDate,
      syncedAWSDateCircleColor,
      syncedCloudCostsDateCircleColor,
    },
    setSyncedDates,
  ] = useReducer(genericReducer<SyncedDatesAction>, {
    syncedAWSDate: `${strings.general.Loading}${strings.punctuation.ellipsis}`,
    syncedCloudCostsDate: `${strings.general.Loading}${strings.punctuation.ellipsis}`,
    syncedPrometheusDate: `${strings.general.Loading}${strings.punctuation.ellipsis}`,
    syncedAWSDateCircleColor: '',
    syncedCloudCostsDateCircleColor: '',
  });
  const [selectedFilters, setSelectedFilters] = useReducer(filterReducer<SelectedFilters>, {});
  const [{ pageSize, orderBy, currentPage, hiddenColumnsChanged }, setTableState] = useReducer(
    genericReducer<TableAction>,
    {
      pageSize: parseInt(currentLocation.get('pageSize') || '10'),
      orderBy: {
        orderBy: currentLocation.get('orderBy') || 'totalCost',
        orderByDesc: currentLocation.get('orderByDesc') || 'true',
      },
      currentPage: parseInt(currentLocation.get('currentPage') || '0'),
      hiddenColumnsChanged: false,
    },
  );

  // state
  const [selectedTimeRange, setSelectedTimeRange] = useState<DateType>(() =>
    getSelectedTimeRange(timeZone, new URLSearchParams(window.location.search)),
  );
  const [dateRangeOption, setDateRangeOption] = useState<string | undefined>(() => {
    return new URLSearchParams(window.location.search).get('dateRangeOption') || 'Last 30 days';
  });
  const [selectedAggregators, setSelectedAggregators] = useState<(OptionType | CostsOptions)[]>(() => {
    return getSelectedAggregators(new URLSearchParams(window.location.search));
  });
  const [filters, setFilters] = useState<Filters | null>(null);
  const [topWorkloads, setTopWorkloads] = useState<WorkloadOption[]>([]);
  const [totalCosts, setTotalCosts] = useState<TotalCosts>();
  const [savingsAvailable, setSavingsAvailable] = useState<SavingsAvailable>();
  const [currentAction, setCurrentAction] = useState<CurrentAction>('mounting');
  const [savedViews, setSavedViews] = useState([]);
  const [selectedSavedView, setSelectedSavedView] = useState<WorkloadOption | null>(null);

  // useMemo's
  const period: Period = useMemo(() => {
    const endDate = dayjs(selectedTimeRange.end);
    const startDate = dayjs(selectedTimeRange.start);
    const diffsInDays = endDate.diff(startDate, 'day');
    const diffsInMonths = endDate.diff(startDate, 'month');

    if (diffsInMonths >= 2 && diffsInMonths <= 9) {
      return 'weekly';
    }

    if (diffsInMonths > 9) {
      return 'monthly';
    }

    return diffsInDays < 4 ? 'hourly' : 'daily';
  }, [resources, selectedAggregators, route.fullPath, selectedTimeRange]);

  const showUsageChart = useMemo(() => {
    let allFiltersSelected;
    const allAggregatorsSelected = selectedAggregators.filter((aggregator) =>
      usageChartRequired.includes(aggregator.value),
    );

    if (Object.keys(selectedFilters).length) {
      allFiltersSelected =
        Object.keys(selectedFilters).filter(
          (filter) => selectedFilters[filter].length && resourcesPerPodRequired.includes(filter),
        ).length === 4;
    }

    // need to have cluster, kind, namespace, and workload keys also
    // selectedSavedView.value.aggregators;

    /* 
      when getting filters from saved views, words are singular; everywhere else for filters, they're plural. do we need this?
      we don't, update all filters to be singular
    */
    const chartRequirements = ['clusters', 'kinds', 'namespaces', strings.workloads];

    const checkAggregators = chartRequirements.filter(
      (aggregator) => !selectedSavedView?.value?.aggregators?.includes(aggregator),
    );

    const checkFilters = chartRequirements.filter(
      (aggregator) => selectedSavedView && !Object.keys(selectedSavedView?.value).includes(aggregator),
    );

    if (selectedSavedView && !checkAggregators && !checkFilters && allAggregatorsSelected) {
      return true;
    }

    return allAggregatorsSelected && allFiltersSelected;
  }, [selectedFilters, selectedAggregators, resources, selectedSavedView]);

  const currentFilters = useMemo(() => {
    if (!selectedFilters) {
      return 0;
    }

    let count = 0;
    if (selectedFilters) {
      const filterKeys = Object.keys(selectedFilters);
      if (filterKeys) {
        filterKeys.forEach((key) => (count += key === 'dateRangeOption' ? 0 : selectedFilters[key].length));
      }
    }
    return count;
  }, [selectedFilters, currentAction]);

  // functions
  const filterByDate = (start?: Dayjs | Date, end?: Date | Dayjs, dateRangeOption?: string) => {
    if (!start || !end) {
      return;
    }

    const changedDates = {
      start: formatDatetime(timeZone ? dayjs(start).tz(timeZone) : dayjs(start)),
      end: formatDatetime(timeZone ? dayjs(end).tz(timeZone) : dayjs(end)),
    };

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('startDate', changedDates.start);
    searchParams.set('endDate', changedDates.end);
    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);

    setDateRangeOption(dateRangeOption);
    setSelectedTimeRange(changedDates);
    setSelectedTimeRangeChanged(true);
    setCurrentAction('updating');
  };

  // sending a request cuts the s off the request

  const selectFilters = (wasClicked: boolean, option?: SelectedFilters, removedValue?: boolean) => {
    if (!option && !removedValue) return;

    if (option) {
      setSelectedFilters({ type: null, payload: option });
    }

    if (wasClicked) {
      setCurrentAction('clicked');
    } else {
      setCurrentAction(currentAction !== 'mounting' ? 'updating' : 'mounting');
    }
  };

  const selectAggregators = (option: (OptionType | CostsOptions)[]) => {
    setCurrentAction('updating');
    setTableState({ currentPage: 0 });
    setSelectedAggregators(option || []);
  };

  const updateSelectedFilters = (cluster: string, type?: string, formattedFilters?: SelectedFilters) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    if (formattedFilters) {
      if (cluster) {
        setSelectedFilters({
          type: type || null,
          payload: {
            ...formattedFilters,
            clusters: [{ section: strings.noTranslate.clusters, value: cluster, label: cluster }],
          },
        });
      } else {
        setSelectedFilters({ type: type || null, payload: { ...formattedFilters } });
      }
    } else if ((cluster && !selectedFilters.clusters) || (cluster && selectedSavedView)) {
      setSelectedFilters({
        type: type || null,
        payload: {
          clusters: [{ section: strings.noTranslate.clusters, value: cluster, label: cluster }],
        },
      });
    } else if (cluster && !selectedFilters.clusters.find((indCluster) => indCluster.value === cluster)) {
      newSearchParams.delete('cluster');
      if (newSearchParams.get('Cluster') !== cluster || newSearchParams.get('cluster') !== cluster) {
        newSearchParams.set('Cluster', cluster);
      }
      setSelectedFilters({
        type: type || null,
        payload: {
          clusters: [{ section: strings.noTranslate.clusters, value: cluster, label: cluster }],
        },
      });
    } else if (!cluster && newSearchParams.has('redirect')) {
      setCurrentAction(currentAction === 'mounted' || currentAction === 'updated' ? 'updating' : 'mounting');
      newSearchParams.delete('cluster');
      const updateFilters = () => {
        const copiedFilters = { ...selectedFilters };
        delete copiedFilters?.clusters;
        return copiedFilters;
      };
      setSelectedFilters({ type: type || null, payload: updateFilters() });
    }
  };

  const updateSelectedTopWorkload = (option: WorkloadOption, actionMeta: Record<string, any>, cluster: string) => {
    const filters: Record<string, CostsOptions[]> = {};
    // not losing selected saved view on filter/aggregator change
    if (option) {
      const specialFieldsMapper = {
        namespaceLabelsFilters: 'namespaceLabels',
        workloadLabelsFilters: 'workloadLabels',
        podLabelsFilters: 'podLabels',
        ResourceTypes: 'resourceTypes',
      };

      setSelectedAggregators([]);
      // { key: [{ label, value, section }]}
      updateSelectedFilters(cluster);

      const optionKeys = Object.keys(option.value);

      if (optionKeys.length) {
        const aggregatorKeysArr = [
          'aggregators',
          'namespaceLabelsAggregators',
          'podLabelsAggregators',
          'workloadLabelsAggregators',
        ];

        const aggregatorKeys = optionKeys.filter((key) => aggregatorKeysArr.includes(key));

        let aggregatorValues: any[] = [];

        for (const aggregatorKey of aggregatorKeys) {
          if (hasKey(option.value, aggregatorKey)) {
            const aggregatorKeyString = aggregatorKey as string;

            if (aggregatorKeyString === 'aggregators') {
              aggregatorValues = [
                ...aggregatorValues,
                ...option.value.aggregators.map((aggregator) => ({
                  value: aggregator,
                  label: aggregator,
                })),
              ];
            } else if (aggregatorKeyString === 'namespaceLabelsAggregators') {
              aggregatorValues = [
                ...aggregatorValues,
                ...option.value.namespaceLabelsAggregators.map((aggregator) => ({
                  value: aggregator,
                  label: aggregator,
                  section: 'namespace',
                })),
              ];
            } else if (aggregatorKeyString === 'podLabelsAggregators') {
              aggregatorValues = [
                ...aggregatorValues,
                ...option.value.podLabelsAggregators.map((aggregator) => ({
                  value: aggregator,
                  label: aggregator,
                  section: 'pod',
                })),
              ];
            } else if (aggregatorKeyString === 'workloadLabelsAggregators') {
              aggregatorValues = [
                ...aggregatorValues,
                ...option.value.workloadLabelsAggregators.map((aggregator) => ({
                  value: aggregator,
                  label: aggregator,
                  section: 'workload',
                })),
              ];
            }
          }
        }

        selectAggregators(aggregatorValues);

        const filterKeys = optionKeys.filter((key) => key !== 'name' && !aggregatorKeys.includes(key));

        filterKeys.forEach((section) => {
          if (hasKey(option.value, section)) {
            const optionValue = option.value[section] as any;
            const isOptionValueArray = Array.isArray(optionValue) && optionValue?.length > 0;
            const filterSection =
              section in specialFieldsMapper
                ? specialFieldsMapper[section as keyof typeof specialFieldsMapper]
                : section;

            filters[filterSection] = isOptionValueArray
              ? optionValue.map((value: any) => ({
                  value: value,
                  label: value,
                  section,
                }))
              : [
                  {
                    value: optionValue,
                    label: optionValue,
                    section,
                  },
                ];
          }
        });
      }
    }

    if (
      actionMeta.action === 'clear' ||
      (actionMeta.action === 'select-option' && selectedSavedView?.label === option?.label)
    ) {
      selectFilters(
        true,
        {
          clusters: [],
          kinds: [],
          namespaces: [],
          workloads: [],
          containers: [],
          namespaceLabels: [],
          workloadLabels: [],
          podLabels: [],
          resourceTypes: [],
          billedCostGreaterThan: [],
          networkingLessThanInMB: [],
        },
        true,
      );
      setSelectedAggregators(quickViews.clear.aggregators);
      setSelectedSavedView(null);
    } else {
      updateSelectedFilters(cluster, 'pop', filters);
      setSelectedSavedView(option);
    }
  };

  /* */

  const SyncedDates = ({ loading }: PropsWithoutRef<{ loading: boolean }>) => {
    let prometheusDate = null;

    if (loading) {
      prometheusDate = (
        <LastSyncedDate loading={true} noneSyncedDateTitle="Loading..." className="costs-synced-dates-div__left" />
      );
    } else if (syncedPrometheusDate && syncedPrometheusDate.includes(strings.general.at)) {
      prometheusDate = (
        <LastSyncedDate
          syncedDate={syncedPrometheusDate}
          syncedDateTitle={strings.efficiency.lastSyncedPrometheus}
          className="costs-synced-dates-div__left"
        />
      );
    } else {
      prometheusDate = (
        <LastSyncedDate
          noneSyncedDateTitle={
            !syncedPrometheusDate
              ? strings.efficiency.prometheusNotInstalled
              : `${strings.efficiency.prometheusNotReported} ${syncedPrometheusDate}`
          }
          className="costs-synced-dates-div__left"
        />
      );
    }

    let awsDate = null;
    let cloudCostsDate = null;

    if (syncedAWSDate && syncedAWSDate.includes(strings.general.at)) {
      awsDate = (
        <LastSyncedDate
          syncedDate={syncedAWSDate}
          syncedDateTitle={strings.efficiency.lastSyncedWithAWS}
          className="costs-synced-dates-div"
          givenCircleColor={syncedAWSDateCircleColor}
        />
      );
    } else {
      if (loading) {
        cloudCostsDate = (
          <LastSyncedDate loading={true} noneSyncedDateTitle="Loading..." className="costs-synced-dates-div__left" />
        );
      } else if (syncedCloudCostsDate && syncedCloudCostsDate.includes(strings.general.at)) {
        cloudCostsDate = (
          <LastSyncedDate
            syncedDate={syncedCloudCostsDate}
            syncedDateTitle={strings.efficiency.lastSyncedWithCloudCosts}
            className="costs-synced-dates-div"
            givenCircleColor={syncedCloudCostsDateCircleColor}
          />
        );
      } else {
        cloudCostsDate = (
          <LastSyncedDate
            noneSyncedDateTitle={strings.efficiency.cloudCostsNotInstalled}
            className="costs-synced-dates-div"
          />
        );
      }
    }

    return (
      <div className="costs__synced-dates">
        {prometheusDate}
        {awsDate}
        {cloudCostsDate}
      </div>
    );
  };

  const createBaseSearchParams = (
    aggregators: MultiValue<OptionType>,
    passedFilters: SelectedFilters,
    wasFilterRemoved: boolean,
    isRouteUpdated = false,
    updatedDates?: DateType,
    dateRangeOption?: string | undefined,
  ) => {
    const flattenedPassedFilters = Object.values(passedFilters).flat();
    const flattenedSelectedFilters = Object.values(selectedFilters).flat();
    const currentLocation = window.location.search;
    let searchParams = currentLocation.length > 0 ? new URLSearchParams(currentLocation) : new URLSearchParams();

    searchParams = buildStartOfQuery(
      searchParams,
      flattenedPassedFilters,
      aggregators,
      updatedDates ? updatedDates : selectedTimeRange,
      route,
    );

    searchParams = buildFilterParams(
      searchParams,
      cluster,
      flattenedPassedFilters,
      flattenedSelectedFilters,
      wasFilterRemoved,
      filters,
      currentAction,
      isRouteUpdated,
    );

    searchParams.set('orderBy', !orderBy?.orderBy ? 'totalCost' : orderBy.orderBy);
    searchParams.set('orderByDesc', orderBy?.orderByDesc);

    searchParams.delete('column');

    searchParams.delete('dateRangeOption');
    if (dateRangeOption) {
      searchParams.set('dateRangeOption', dateRangeOption);
    }
    searchParams.set(strings.noTranslate.addNetworkAndStorage, 'true');

    return searchParams;
  };

  const paramsWithAggregators = (
    aggregators: (OptionType | CostsOptions)[],
    filters: SelectedFilters,
    dates: DateType,
    dateRangeOption?: string | undefined,
  ) => {
    let searchParams = createBaseSearchParams(aggregators || [], filters || {}, false, true, dates, dateRangeOption);

    searchParams = buildAggregatorsQuery(searchParams, aggregators || [], selectedAggregators);

    searchParams.set('pageSize', pageSize?.toString() || '10');
    searchParams.set('page', currentPage?.toString() || '0');

    return searchParams;
  };

  return {
    selectedTimeRange,
    setSelectedTimeRange,
    selectedTimeRangeChanged,
    setSelectedTimeRangeChanged,
    selectedAggregators,
    setSelectedAggregators,
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
    topWorkloads,
    setTopWorkloads,
    savedViews,
    setSavedViews,
    syncedPrometheusDate,
    syncedAWSDate,
    syncedCloudCostsDate,
    setSyncedDates,
    pageSize,
    orderBy,
    currentPage,
    hiddenColumnsChanged,
    setTableState,
    summaries,
    totals,
    resources,
    totalCosts,
    setTotalCosts,
    savingsAvailable,
    setSavingsAvailable,
    selectedSavedView,
    setSelectedSavedView,
    workloadLabels,
    namespaceLabels,
    podLabels,
    currentAction,
    setCurrentAction,
    period,
    showUsageChart,
    currentFilters,
    filterByDate,
    selectFilters,
    selectAggregators,
    updateSelectedFilters,
    updateSelectedTopWorkload,
    SyncedDates,
    createBaseSearchParams,
    paramsWithAggregators,
    setResources,
    setLabels,
    dateRangeOption,
    setDateRangeOption,
  };
};

export default useCostsData;
