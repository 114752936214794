import React from 'react';

import { Item, Menu } from 'react-contexify';

import Print from '~assetIcons/print.svg';
import DownloadIcon from '~reactComponents/Icons/Download.react';

import { ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID } from '../../ActionItemsReports.config.react';

import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';

type ContextMenuProps = {
  handleExport: (action: string) => Promise<void>;
  handlePrint: () => void;
};

const ContextMenu = ({ handleExport, handlePrint }: ContextMenuProps) => {
  return (
    <Menu id={ACTION_ITEMS_REPORTS_CONTEXT_MENU_ID} className="context--menu" animation={false}>
      <Item className="context--menu__title context--menu__no-hover" disabled>
        <span>{strings.general.additionalActions}</span>
      </Item>
      <Item onClick={handlePrint}>
        <img className="context--menu__print-icon" src={Print} alt={strings.general.printIcon} />
        {strings.general.print}
      </Item>
      <Item onClick={handleExport}>
        <DownloadIcon marginRight="1rem" fill={COLORS.CORE.PRIMARY} width="15" height="15" />
        {strings.general.downloadCSV}
      </Item>
    </Menu>
  );
};

export default ContextMenu;
