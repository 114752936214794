import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useFlexLayout } from 'react-table';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { ImpactedWorkloads, Labels } from './ImpactedWorkloadsTable.types.react';
import { getTableColumns } from './ImpactedWorkloadsTable.config.react';

import { ImageSummary } from '~globalTypes';

import './ImpactedWorkloadsTable.react.scss';

type ImpactedWorkloadsProps = {
  orgName: string;
  imageSummary: ImageSummary | undefined;
  loaded: boolean;
};

const ImpactedWorkloadsTable = ({ orgName, imageSummary, loaded }: ImpactedWorkloadsProps) => {
  const [impactedWorkloads, setImpactedWorkloads] = useState<ImpactedWorkloads[]>([]);

  useEffect(() => {
    if (imageSummary?.impactedWorkloads?.length) {
      setImpactedWorkloads(imageSummary.impactedWorkloads);
    }
  }, [imageSummary]);

  const columns = useMemo(() => getTableColumns(), []);

  const data = useMemo(() => impactedWorkloads, [impactedWorkloads]);

  // imageSummary.name has format "imageName:imageTag"
  const [imageName, imageTag, imageSHA] = [imageSummary?.name.split(':')[0], imageSummary?.tag, imageSummary?.sha];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout,
  );

  return (
    <Card className="impacted-workloads" data-cy="impacted-workloads">
      <Card.Title>
        <div className="impacted-workloads__title">
          <div className="impacted-workloads__left-title">
            {data?.length ? (
              <a
                className="impacted-workloads__workloads-title"
                href={`/orgs/${orgName}/action-items?page=0&orderBy=Severity.desc&pageSize=25&ImageName=${imageName}&ImageTag=${imageTag}&ImageSHA=${imageSHA}`}
              >
                {Labels.ImpactedWorkloads}
              </a>
            ) : (
              <span className="impacted-workloads__workloads-title">{Labels.ImpactedWorkloads}</span>
            )}
          </div>
        </div>
      </Card.Title>
      <Card.Body className={`impacted-workloads__body ${loaded ? '' : 'impacted-workloads__scroll--hidden'}`}>
        {loaded ? (
          <div>
            {data?.length ? (
              <table className="impacted-workloads__table" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          <div>{column.render('Header')}</div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="impacted-workloads__table-body">
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="impacted-workloads__empty-body">
                <span>{Labels.NoImpactedWorkloads}</span>
              </div>
            )}
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </Card.Body>
    </Card>
  );
};

export default ImpactedWorkloadsTable;
