import React from 'react';

import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';

import VulnerabilitiesItemsTable from './components/VulnerabilitiesItemsTable/VulnerabilitiesItemsTable.react';

import { ORG_DASHBOARD } from '~reactComponents/NavigationReact/Navigation.config.react';

import { IRoute, IRouter, IStore } from '~globalTypes';
import { Labels } from './ReactAllVulnerabilities.types.react';

import { strings } from '~utils/strings';
import { handlePageChange } from '~utils/global.helpers.react';

import './ReactAllVulnerabilities.react.scss';

type ReactAllVulnerabilitiesProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
};

const ReactAllVulnerabilities = ({ route, router, store }: ReactAllVulnerabilitiesProps) => {
  const { organization, cluster } = store().getters;
  const baseURL = `/v0/organizations/${organization.Name}`;

  const org = organization.Name;

  const allVulnerabilitiesRoutesList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: 'react-vulnerabilities-all-images',
      label: strings.navigation.Vulnerabilities,
      href: `/orgs/${org}/vulnerabilities/all-images`,
    },
    {
      id: 'react-vulnerabilities-all-vulnerabilities',
      label: 'All Vulnerabilities',
      href: `/orgs/${org}/vulnerabilities/all-vulnerabilities`,
      isActive: cluster ? undefined : true,
    },
  ];

  if (cluster) {
    allVulnerabilitiesRoutesList.push({
      id: 'last',
      label: cluster.Name,
      href: `/orgs/${org}/vulnerabilities/image/${cluster.Name}`,
      isActive: true,
    });
  }

  return (
    <LayoutReact>
      <Breadcrumbs
        data={allVulnerabilitiesRoutesList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <VulnerabilitiesItemsTable
        baseURL={baseURL}
        cluster={cluster}
        organization={organization}
        route={route}
        router={router}
        savedSelectionsKey={Labels.SaveSelectionsKey}
      />
    </LayoutReact>
  );
};

export default ReactAllVulnerabilities;
