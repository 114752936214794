import Joi from 'joi';
import {
  CLUSTER_OVERVIEW,
  ORG_DASHBOARD,
  ORG_EFFICIENCY,
} from '~reactComponents/NavigationReact/Navigation.config.react';
import { strings } from '~utils/strings';

export const orgBreadcrumbsList = (org: string, cluster?: string) => {
  const breadcrumbs = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
      isActive: false,
    },
    {
      id: ORG_EFFICIENCY,
      label: strings.navigation.Efficiency,
      href: `/orgs/${org}/efficiency`,
      isActive: false,
    },
  ];

  if (cluster) {
    breadcrumbs.push({
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}/overview`,
      isActive: false,
    });
  }

  breadcrumbs.push({
    id: 'last',
    label: strings.navigation.RightSizing,
    href: `/orgs/${org}/efficiency/right-sizing`,
    isActive: true,
  });

  return breadcrumbs;
};

export const validationSchema = Joi.object({
  QoS: Joi.string().valid('critical', 'limited', 'guaranteed', 'burstable', 'burstable_plus').required(),
});
