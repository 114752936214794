import React, { useState, useEffect, useMemo } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Column, Row, useTable } from 'react-table';
import toast, { Toaster } from 'react-hot-toast';

import { AddButton } from '@fairwindsops/ui-components';

import { sendRequest } from '~utils/request';
import logger from '~logger';
import { Membership, IStore, IRoute, IRouter, IAppGroup, IAppGroupSummary } from '~globalTypes';

import { TABLE_COLUMNS } from './AppGroupsSummaryTable.config.react';
import { ITableData } from './AppGroupsSummaryTable.types.react';

import ViewAppGroupModal from '../ViewAppGroupModal/ViewAppGroupModal.react';
import AddAppGroupModal from '../AddAppGroupModal/AddAppGroupModal.react';

import { TOGGLE_ADD_APP_GROUP_MODAL } from '~store/action.types';

import { strings } from '~utils/strings';

import ConfirmationDialog from '~reactComponents/ConfirmationDialog/ConfirmationDialog.react';

import UpdateAppGroupModal from '../UpdateAppGroupModal/UpdateAppGroupModal.react';

import './AppGroupsSummaryTable.react.scss';

type AppGroupSummaryTableProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  isAddAppGroupModalShown?: boolean;
};

const AppGroupSummaryTable = ({
  route,
  router,
  store,
  isAddAppGroupModalShown,
}: AppGroupSummaryTableProps): JSX.Element => {
  const org = route?.params?.org;
  const baseURL = `/v0/organizations/${org}`;
  const isOrgOwner = store().getters.isOrgOwner;

  const [loading, setLoading] = useState<boolean>(true);
  const [appGroupsTableData, setAppGroupsTableData] = useState<ITableData[]>([]);
  const [selectedAppGroup, setSelectedAppGroup] = useState<IAppGroup | null>(null);
  const [isViewAppGroupModalShown, setIsViewAppGroupModalShown] = useState<boolean>(false);
  const [isUpdateAppGroupModalShown, setIsUpdateAppGroupModalShown] = useState<boolean>(false);
  const [isDeleteConfirmModalShown, setIsDeleteConfirmModalShown] = useState<boolean>(false);
  const [appGroupNameToDelete, setAppGroupNameToDelete] = useState<string>('');
  const [isOverrideConfirmModalShown, setIsOverrideConfirmModalShown] = useState<boolean>(false);
  const [appGroupNameToOverride, setAppGroupNameToOverride] = useState<string>('');
  const [createAppGroupPayload, setCreateAppGroupPayload] = useState<Record<string, any>>({});

  const buildAppGroupTableData = (
    appGroup: IAppGroup,
    appGroupSummary: IAppGroupSummary | undefined,
  ) =>
    appGroupSummary
      ? {
          name: appGroup.name,
          policyMappings: appGroupSummary.policyMappings?.length
            ? appGroupSummary.policyMappings
                .filter((policyMapping) => !!policyMapping.name)
                .map((policyMapping) => policyMapping.name)
            : [],
          open: appGroupSummary.openActionItemsCount || 0,
          fixed: appGroupSummary.fixedActionItemsCount || 0,
          openLink: appGroupSummary.openActionItemsUrl || '',
          fixedLink: appGroupSummary.fixedActionItemsUrl || '',
          monthlyBilledCost:
            appGroupSummary.monthlyBilledCost !== null &&
            appGroupSummary.monthlyBilledCost !== undefined
              ? `$${appGroupSummary.monthlyBilledCost.toFixed(2)}`
              : '',
        }
      : { name: appGroup.name };

  const transformAppGroupsTableData = (
    appGroups: IAppGroup[],
    appGroupsSummary: IAppGroupSummary[],
  ) => {
    return appGroups?.length
      ? appGroups.map((appGroup) =>
          buildAppGroupTableData(
            appGroup,
            appGroupsSummary.find((summary) => summary.id === appGroup.id),
          ),
        )
      : [];
  };

  const getAppGroups = async () => {
    setLoading(true);
    try {
      const appGroupRequest = sendRequest('GET', `${baseURL}/app-groups`, {}, null);
      const appGroupSummaryRequest = sendRequest('GET', `${baseURL}/app-groups/summary`, {}, null);

      setAppGroupsTableData(
        transformAppGroupsTableData(await appGroupRequest, await appGroupSummaryRequest),
      );
    } catch (e) {
      logger.logError('error_retrieving_app_groups', e);
      toast.error(<b>{strings.appGroups.errorRetrievingAppGroups}</b>);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAppGroups();
  }, []);

  const isOwner = useMemo(() => {
    const matchingOrg = store().getters.memberships.find(
      (membership: Membership) => membership.Organization === org,
    );

    return matchingOrg?.IsOwner || store().getters.user?.IsSuperAdmin;
  }, [org]);

  const data = useMemo(() => appGroupsTableData, [appGroupsTableData]);

  const getAppGroupByName = async (appGroupName: string) => {
    if (!appGroupName) {
      return;
    }

    try {
      return await sendRequest('GET', `${baseURL}/app-groups/${appGroupName}`, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_app_group_by_name', e);
      toast.error(<b>{strings.appGroups.errorRetrievingAppGroupByName}</b>);
    }
  };

  const viewAppGroup = () => async (row: Row) => {
    if (!row.original.name) {
      return;
    }

    const selectedAppGroup = await getAppGroupByName(row.original.name);

    if (selectedAppGroup) {
      setSelectedAppGroup(selectedAppGroup);

      if (isOrgOwner || isOwner) {
        setIsUpdateAppGroupModalShown(true);
      } else {
        setIsViewAppGroupModalShown(true);
      }
    }
  };

  const columns = useMemo<Column[]>(
    () => TABLE_COLUMNS(viewAppGroup(), isOrgOwner || isOwner, router),
    [appGroupsTableData],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const onAppGroupAdded = () => {
    getAppGroups();
  };

  const onAppGroupUpdated = () => {
    getAppGroups();
  };

  const deleteAppGroup = async () => {
    if (!appGroupNameToDelete) {
      return;
    }

    try {
      await sendRequest('DELETE', `${baseURL}/app-groups/${appGroupNameToDelete}`, {}, null);
      setIsDeleteConfirmModalShown(false);
      getAppGroups();
      toast.success(strings.appGroups.deleteAppGroupsSuccess);
    } catch (e: any) {
      logger.logError('error_deleting_an_app_group', e);
      toast.error(<b>{e.message ? e.message : strings.appGroups.cannotDeleteAppGroup}</b>);
    }
  };

  const overrideAppGroup = async () => {
    try {
      await sendRequest('POST', `${baseURL}/app-groups`, { data: createAppGroupPayload }, null);

      toast.success(
        strings.appGroups.createAppGroupSuccess.replace(
          '$APP_GROUP_NAME',
          createAppGroupPayload.name,
        ),
      );

      onAppGroupAdded();
      setIsOverrideConfirmModalShown(false);
      setAppGroupNameToOverride('');
    } catch (e) {
      logger.logError('error_creating_an_app_group', e);
      toast.error(
        <b>
          {strings.appGroups.cannotCreateTheAppGroup.replace(
            '$APP_GROUP_NAME',
            createAppGroupPayload.name,
          )}
        </b>,
      );
    }
  };

  return (
    <div>
      <Card className="app-groups-summary-card">
        <Card.Body>
          <div className="app-groups-summary-card-header">
            <AddButton
              buttonText={strings.appGroups.addAppGroup}
              action={() => store().commit(TOGGLE_ADD_APP_GROUP_MODAL, true)}
              disabled={!isOwner}
              testLabel="add-button"
            />
          </div>
          {loading && (
            <div className="h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status" className="app-groups-summary-spinner">
                <span className="sr-only">{strings.appGroups.loading}</span>
              </Spinner>
            </div>
          )}
          {!loading && appGroupsTableData.length > 0 && (
            <table
              {...getTableProps()}
              className="app-groups-summary-table"
              data-cy="app-groups-summary-table"
              aria-label={strings.appGroups.appGroupsSummaryTable}
            >
              <thead className="app-groups-summary-header">
                {headerGroups.map((headerGroup) => (
                  <tr className="header-row" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className="app-groups-summary-table-row">
                      {row.cells.map((cell) => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {!loading && !appGroupsTableData.length && (
            <div className="error-message">
              <h6>{strings.appGroups.appGroupsEmptyMessage}</h6>
            </div>
          )}
        </Card.Body>
      </Card>
      <AddAppGroupModal
        isModalShown={isAddAppGroupModalShown}
        onConfirmClicked={onAppGroupAdded}
        onModalHidden={() => store().commit(TOGGLE_ADD_APP_GROUP_MODAL, false)}
        organizationName={org}
        appGroupNames={
          appGroupsTableData?.length
            ? (appGroupsTableData.map((data) => data.name) as string[])
            : []
        }
        onOverrideModalShown={(payload: Record<string, any>) => {
          setAppGroupNameToOverride(payload.name);
          setCreateAppGroupPayload(payload);
          setIsOverrideConfirmModalShown(true);
        }}
        store={store}
      />
      {!isOrgOwner && !isOwner ? (
        <ViewAppGroupModal
          isModalShown={isViewAppGroupModalShown}
          onModalHidden={() => {
            setSelectedAppGroup(null);
            setIsViewAppGroupModalShown(false);
          }}
          selectedAppGroup={selectedAppGroup}
        />
      ) : (
        <UpdateAppGroupModal
          isModalShown={isUpdateAppGroupModalShown}
          onModalHidden={() => {
            setSelectedAppGroup(null);
            setIsUpdateAppGroupModalShown(false);
          }}
          selectedAppGroup={selectedAppGroup}
          onConfirmClicked={onAppGroupUpdated}
          organizationName={org}
          onDeleteModalShown={(appGroupName: string) => {
            setAppGroupNameToDelete(appGroupName);
            setIsDeleteConfirmModalShown(true);
          }}
          store={store}
        />
      )}
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.Cancel}
        confirmButtonText={strings.Delete}
        isModalShown={isDeleteConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.appGroups.deleteAppGroupConfirmationContent.replace(
          '$APP_GROUP_NAME',
          appGroupNameToDelete,
        )}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.appGroups.deleteAppGroup}
        onConfirmClicked={deleteAppGroup}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsDeleteConfirmModalShown(isModalShown ? true : false);
          setAppGroupNameToDelete('');
        }}
        modalClassName="update-app-group-confirm-modal"
        dataCyConfirmButton="delete-app-group-confirm-button"
      />
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.general.No}
        confirmButtonText={strings.general.Yes}
        isModalShown={isOverrideConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.appGroups.overrideAppGroupConfirmationContent.replace(
          '$APP_GROUP_NAME',
          appGroupNameToOverride,
        )}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.appGroups.overrideAppGroup}
        onConfirmClicked={overrideAppGroup}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsOverrideConfirmModalShown(isModalShown ? true : false);
          setAppGroupNameToOverride('');
        }}
        modalClassName="update-app-group-confirm-modal"
      />
      <Toaster />
    </div>
  );
};

export default AppGroupSummaryTable;
