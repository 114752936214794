export default `package fairwinds

noDefaultNamespace[actionItem] {
  input.metadata.namespace == "evil"
  actionItem := {
    "title": "Kubernetes shall be used for Good, not Evil",
    "description": "Resources are not allowed to be deployed to the evil namespace",
    "remediation": "Set a different namespace.",
    "category": "Security",
    "severity": 0.99,
  }
}`;
