import React from 'react';
import { DropdownIndicatorProps, GroupBase, components } from 'react-select';
import AccordionDropdown from '~reactComponents/Icons/AccordionDropdown.react';
import { OptionType } from '~utils/global.types.react';
import { COLORS } from '~utils/styling';

export const DropdownIndicator = (({ children, ...props }: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <div className="accordion-arrow">
      <AccordionDropdown />
    </div>
    {children}
  </components.DropdownIndicator>
)) as React.ComponentType<DropdownIndicatorProps<OptionType, true, GroupBase<OptionType>>> | null | undefined;
