import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Select, {
  MultiValueGenericProps,
  StylesConfig,
  components,
  createFilter,
} from 'react-select';
import { Virtuoso } from 'react-virtuoso';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';
import FilterIcon from '~reactComponents/Icons/Filter.react';

import { CustomMenuType, MenuListType, OptionType } from '~utils/global.types.react';

import { strings } from '~utils/strings';
import { MediumBasicText } from '~utils/texts.react';

import './AdvancedFilters.react.scss';

const MenuList = ({ children, maxHeight }: MenuListType) => (
  <Virtuoso
    style={{ height: maxHeight }}
    totalCount={children.length}
    itemContent={(index) => <div>{children[index]}</div>}
  />
);

const MultiValueContainer = (props: MultiValueGenericProps<any>) => {
  if (props.data.value === strings.punctuation.asterisk) return <></>;
  return <components.MultiValueContainer {...props} />;
};

type CustomMenuProps = {
  advancedFilterTitle?: string;
  customFilterStyles?: StylesConfig<OptionType, true>;
  customMenuType: CustomMenuType;
};

const CustomMenu = ({
  advancedFilterTitle,
  customFilterStyles,
  customMenuType,
}: CustomMenuProps): JSX.Element => {
  const { filters, selectedFilters, selectFilters } = customMenuType;

  const menu = Object.keys(filters).map((filterSection) => {
    const allFilters = filters[filterSection].map((filter) => ({
      label: filter,
      value: filter,
      section: filterSection,
    }));

    return (
      <div className="filter-section__section" key={filterSection}>
        <h1
          className={MediumBasicText({
            textTransform: strings.textStyling.capitalize,
            bottomMargin: strings.textStyling.xsBottom,
            weight: strings.textStyling.regular,
          })}
        >
          {filterSection}
        </h1>
        <Select
          className={`custom--select_filtering ${filterSection}`}
          classNamePrefix="custom--select_filtering"
          closeMenuOnSelect={false}
          components={{ Option, MenuList, MultiValueContainer }}
          controlShouldRenderValue={true}
          filterOption={createFilter({ ignoreAccents: false })}
          hideSelectedOptions={false}
          id={filterSection}
          isClearable={false}
          isMulti
          key={filterSection}
          onChange={(newOption, { action, removedValue, option }) => {
            selectFilters(option, removedValue, action);
          }}
          options={allFilters}
          placeholder={strings.general.Search}
          styles={customFilterStyles}
          isDisabled={
            !!(
              selectedFilters[filterSection]?.length && selectedFilters[filterSection][0].isDisabled
            )
          }
          value={selectedFilters[filterSection]}
        />
      </div>
    );
  });

  return (
    <>
      <div className="filters-title">{advancedFilterTitle || strings.general.advancedFilters}</div>
      <Dropdown.Divider />
      <div className="filter-section">{menu}</div>
    </>
  );
};

type AdvancedFiltersProps = {
  advancedFilterTitle?: string;
  customFilterStyles?: StylesConfig<OptionType, true>;
  customMenuType: CustomMenuType;
  dropdownButtionLabel?: string;
  filterIcon?: React.ReactNode;
  isLoading?: boolean;
  nCurrentFilters?: number;
};

const AdvancedFilters = ({
  advancedFilterTitle,
  customFilterStyles,
  customMenuType,
  dropdownButtionLabel,
  filterIcon,
  isLoading,
  nCurrentFilters = 0,
}: AdvancedFiltersProps) => {
  return (
    <DropdownButton
      disabled={isLoading}
      title={
        <span className="filter-title">
          {filterIcon || <FilterIcon />}
          {dropdownButtionLabel || strings.general.filtering}
          {nCurrentFilters > 0 && (
            <div className="advanced-filters__filter-number">
              {nCurrentFilters > 99 ? '99+' : nCurrentFilters}
            </div>
          )}
        </span>
      }
      id="filter-options"
      data-cy="filter-button"
    >
      <CustomMenu
        advancedFilterTitle={advancedFilterTitle}
        customFilterStyles={customFilterStyles}
        customMenuType={customMenuType}
      />
    </DropdownButton>
  );
};

export default AdvancedFilters;
