import React, { useMemo } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import dayjs from 'dayjs';
import humanFormat from 'human-format';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';
import DonutChart from '~reactComponents/charts/DonutChart/DonutChart.react';
import InfoIcon from '~reactComponents/Icons/InfoIcon.react';

import { labelsForChart, colors } from './CostsDonut.react.config';

import { CostsDonutProps } from '../../Costs.types.react';
import { DonutChartTooltip } from '~utils/global.types.react';

import { formatCost } from '../../Costs.helpers.react';
import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';
import { ThinTitle, SmallCardTitle } from '~utils/texts.react';

import './CostsDonut.react.scss';

const CostsDonut = ({ data, dates, isLoading }: CostsDonutProps) => {
  const formattedData = useMemo(() => {
    if (data && Object.keys(data).length) {
      const result: any[] = [];
      Object.keys(labelsForChart).forEach((costsLabel) => {
        if (hasKey(labelsForChart, costsLabel) && data[costsLabel] !== undefined) {
          result.push({
            id: labelsForChart[costsLabel],
            label: labelsForChart[costsLabel],
            value: data[costsLabel],
            color: colors[costsLabel],
          });
        }
      });
      return result;
    }
    return [];
  }, [data]);

  const createTooltip = (datum: DonutChartTooltip) => {
    return (
      <div className="costs-donut-chart__tooltip">
        <div className="label">{datum.label}:</div>
        <div>{` ${formatCost(datum.value)}`}</div>
      </div>
    );
  };

  const calculateDayDifference = useMemo(() => dayjs(dates.end).diff(dayjs(dates.start), 'day') || 1, [data, dates]);

  return (
    <Card className="costs-donut-chart">
      <Card.Body padded>
        {!isLoading && (
          <Card.Header className="costs-donut-chart__header">
            {`${strings.efficiency.totalCostOver} ${calculateDayDifference} ${
              calculateDayDifference > 1 ? strings.general.Days : strings.general.Day
            }`}
            <OverlayTrigger
              placement="left"
              overlay={
                <Popover id={`tooltip-costs-donut`}>
                  <Popover.Content>{strings.efficiency.donutTooltip}</Popover.Content>
                </Popover>
              }
            >
              <InfoIcon className="costs-donut-chart__info-icon" />
            </OverlayTrigger>
          </Card.Header>
        )}
        {isLoading && <LoadingSpinner containerClassNames="costs-donut-chart__spinner" />}
        {!isLoading && formattedData.length > 0 && formattedData[0] !== null && (
          <DonutChart
            data={formattedData}
            centerInfoNode={
              <div className="costs-donut-chart__center-info">
                <span
                  className={ThinTitle({
                    weight: strings.textStyling.bold,
                    color: strings.textStyling.primary,
                  })}
                >{`${
                  data.totalNodesCost === 0
                    ? 0
                    : ((data.filteredResourcesCost / data.totalNodesCost) * 100).toPrecision(2)
                }%`}</span>
                <span
                  className={SmallCardTitle({ weight: strings.textStyling.regular })}
                >{`of $${humanFormat(data.totalNodesCost || 0, { maxDecimals: 2 })}`}</span>
              </div>
            }
            label={`${strings.ariaLabels.costsDonutChart.replace(
              '$dates',
              `${dayjs(dates.start).format('MM/DD/YY')} - ${dayjs(dates.end).format('MM/DD/YY')}`,
            )} ${formatCost(data.totalNodesCost || 0)}`}
            tooltip={createTooltip}
          />
        )}
        {!isLoading && formattedData.length > 0 && !formattedData.find((datum) => datum !== null) && (
          <span>{strings.general.noData}</span>
        )}
      </Card.Body>
    </Card>
  );
};

export default CostsDonut;
