import React from 'react';
import PillBadge from '~reactComponents/PillBadge/PillBadge.react';
import Tag from '~reactComponents/Tags/Tag.react';
import { StylesConfig } from 'react-select';
import { OptionType } from '~globalTypes';
import { FormattedPolicies } from '../AllPolicies.types.react';
import { Renderer, FilterProps, Row } from 'react-table';
import DOMPurify from 'dompurify';

import { strings } from '~utils/strings';

export const FILTERS_DEFAULT = {
  severity: [
    strings.severities.critical,
    strings.severities.high,
    strings.severities.medium,
    strings.severities.low,
    strings.severities.none,
  ],
  customized: [strings.policies.Customized, strings.policies.Default],
  ci: [strings.policies.Warn, strings.policies.Block, strings.policies.Unknown, strings.general.na],
  admission: [strings.policies.Warn, strings.policies.Block, strings.policies.Unknown, strings.general.na],
};

export const CONTEXT_MENU_ID = 'POLICY_TABLE_CONTEXT_MENU';

export const SELECT_STYLES: StylesConfig<OptionType, true> = {
  menu: (provided) => ({
    ...provided,
    width: '150%',
    maxWidth: '20rem',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (base) => ({
    ...base,
    borderRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  multiValueRemove: (base) => ({ ...base, display: 'none' }),
};

const warn = strings.noTranslate.warn;
const medium = strings.noTranslate.medium;
const critical = strings.noTranslate.critical;

export const TABLE_COLUMNS = (Filter: Renderer<FilterProps<FormattedPolicies>>) => [
  {
    Header: strings.columnTitles.Title,
    accessor: 'title',
    Filter,
    filter: 'text',
    defaultCanSort: true,
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 200,
    width: 300,
    maxWidth: 600,
  },
  {
    Header: strings.columnTitles.reportType,
    accessor: 'type',
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) =>
      value === strings.policies.Unknown ? (
        <span title={value}>{value}</span>
      ) : (
        <Tag variant={value} children={value} />
      ),
    minWidth: 125,
    width: 135,
    maxWidth: 250,
  },
  {
    Header: strings.columnTitles.Category,
    accessor: 'category',
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) =>
      value === strings.policies.Unknown ? <span title={value}>{value}</span> : <PillBadge color="low" text={value} />,
    minWidth: 75,
    width: 115,
    maxWidth: 150,
  },
  {
    Header: strings.columnTitles.Severity,
    accessor: strings.vulnerabilities.severity_lowercase,
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) => {
      return <PillBadge text={value} />;
    },
    minWidth: 50,
    width: 100,
    maxWidth: 150,
    sortType: sortSeverity,
  },
  {
    Header: strings.columnTitles.Configuration,
    accessor: 'customized',
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) => <span title={value}>{value}</span>,
    minWidth: 135,
    width: 135,
    maxWidth: 250,
  },
  {
    Header: strings.columnTitles.CI,
    accessor: 'ci',
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) =>
      value === strings.policies.Unknown || value === strings.general.na ? (
        <span title={value}>{value}</span>
      ) : (
        <PillBadge text={value?.toLowerCase()} color={value?.toLowerCase() === warn ? medium : critical} />
      ),
    minWidth: 125,
    width: 125,
    maxWidth: 250,
  },
  {
    Header: strings.columnTitles.Admission,
    accessor: strings.admission,
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) =>
      value === strings.policies.Unknown || value === strings.general.na ? (
        <span title={value}>{value}</span>
      ) : (
        <PillBadge text={value?.toLowerCase()} color={value?.toLowerCase() === warn ? medium : critical} />
      ),
    minWidth: 125,
    width: 125,
    maxWidth: 250,
  },
  {
    Header: strings.columnTitles.Agent,
    accessor: 'agent',
    defaultCanSort: true,
    Filter,
    filter: 'text',
    Cell: ({ value }: { value: string }) => {
      return value.toLowerCase() === warn ? (
        <PillBadge text={strings.policies.Warn} color="medium" />
      ) : (
        <span title="none">{strings.general.none}</span>
      );
    },
    minWidth: 150,
    width: 150,
    maxWidth: 250,
  },
];

const sortSeverity = (rowA: Row, rowB: Row) => {
  const dataRowA = rowA.original as FormattedPolicies;
  const dataRowB = rowB.original as FormattedPolicies;
  const severityValueA = dataRowA.severityValue || 0;
  const severityValueB = dataRowB.severityValue || 0;
  if (severityValueA > severityValueB) {
    return 1;
  }
  return severityValueA < severityValueB ? -1 : 0;
};

export const TOP_ROW = (policy: FormattedPolicies) => {
  const content = [
    {
      title: strings.policies.eventType,
      content: policy.eventType,
    },
    { title: strings.columnTitles.Configuration, content: policy.customized },
    {
      title: strings.policies.Report,
      content:
        policy.type === strings.policies.Unknown ? (
          <span title={policy.type}>{policy.type}</span>
        ) : (
          <Tag variant={policy.type} children={policy.type} />
        ),
    },
    {
      title: strings.columnTitles.CI,
      content:
        policy.ci === strings.policies.Unknown || policy.ci === strings.general.na ? (
          <span title={policy.ci}>{policy.ci}</span>
        ) : (
          <PillBadge text={policy?.ci?.toLowerCase()} color={policy?.ci?.toLowerCase() === warn ? medium : critical} />
        ),
    },
    {
      title: strings.columnTitles.Admission,
      content:
        policy.admission === strings.policies.Unknown || policy.admission === strings.general.na ? (
          <span title={policy.admission}>{policy.admission}</span>
        ) : (
          <PillBadge text={policy.admission} color={policy.admission.toLowerCase() === warn ? medium : critical} />
        ),
    },
    {
      title: strings.columnTitles.Agent,
      content: policy.agent.includes(strings.policies.Warn) ? (
        <PillBadge text={strings.policies.Warn} color="medium" />
      ) : (
        <span title="none">{strings.general.None}</span>
      ),
    },
  ];

  if (!policy.isLibrary) {
    // add to the beginning of the array
    content.unshift({
      title: strings.policies.Enabled,
      content:
        policy.type !== 'opa' ? strings.general.True : policy.enabled ? strings.general.True : strings.general.False,
    });
  }

  return content;
};

export const CONTENT = (policy: FormattedPolicies) => [
  {
    title: strings.policies.policyDescription,
    content: policy.description.length
      ? DOMPurify.sanitize(policy.description)
      : policy.type === 'OPA'
        ? strings.policies.noDescriptionOPA
        : '',
  },
  {
    title: strings.policies.Remediation,
    content: policy.remediation.length
      ? DOMPurify.sanitize(policy.remediation)
      : policy.type === 'OPA'
        ? strings.policies.noRemediationOPA
        : '',
  },
];
