import { Dayjs } from 'dayjs';
import { DateRangeLabel } from './Datepicker.helper.react';

export const DEFAULT_DATE_PICKER_OPTIONS: {
  [key in DateRangeLabel]: { startDateConverter: (date: Dayjs) => Dayjs; endDateConverter: (date: Dayjs) => Dayjs };
} = {
  'Last 7 days': {
    startDateConverter: (date: Dayjs) => date.subtract(1, 'week').startOf('day'),
    endDateConverter: (date) => date.endOf('day'),
  },
  'Last 30 days': {
    startDateConverter: (date: Dayjs) => date.subtract(1, 'month').startOf('day'),
    endDateConverter: (date) => date.endOf('day'),
  },
  'Last 365 days': {
    startDateConverter: (date: Dayjs) => date.subtract(1, 'year').startOf('day'),
    endDateConverter: (date) => date.endOf('day'),
  },
  'Last month': {
    startDateConverter: (date: Dayjs) => date.subtract(1, 'month').startOf('month'),
    endDateConverter: (date: Dayjs) => date.subtract(1, 'month').endOf('month'),
  },
  'Month to date': {
    startDateConverter: (date: Dayjs) => date.startOf('month'),
    endDateConverter: (date) => date.endOf('day'),
  },
  'Last quarter': {
    startDateConverter: (date: Dayjs) => {
      const lasQuarter = date.subtract(3, 'month');
      const month = lasQuarter.month();
      const quarterMod = month % 3;
      return lasQuarter.subtract(quarterMod, 'month').startOf('month');
    },
    endDateConverter: (date: Dayjs) => {
      const lasQuarter = date.subtract(3, 'month');
      const month = lasQuarter.month();
      const quarterMod = month % 3;
      return lasQuarter.subtract(quarterMod - 2, 'month').endOf('month');
    },
  },
  'Quarter to date': {
    startDateConverter: (date: Dayjs) => {
      const month = date.month();
      const quarterMod = month % 3;
      return date.subtract(quarterMod, 'month').startOf('month');
    },
    endDateConverter: (date) => date.endOf('day'),
  },
  'Last year': {
    startDateConverter: (date: Dayjs) => date.subtract(1, 'year').startOf('year'),
    endDateConverter: (date) => date.subtract(1, 'year').endOf('year'),
  },
  'Year to date': {
    startDateConverter: (date: Dayjs) => date.startOf('year'),
    endDateConverter: (date) => date.endOf('day'),
  },
};
