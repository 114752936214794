import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableInstance } from 'react-table';
import { TriggerEvent } from 'react-contexify';

import HorizontalEllipsis from '~assetIcons/horizontalEllipsis.svg';
import ResultsDropdown from '~reactComponents/ResultsDropdown/ResultsDropdown.react';

import ToggleColumnsVisibility from '../ToggleColumnsVisibility/ToggleColumnsVisibility.react';

import { IRoute } from '~globalTypes';
import { ResourceSummary } from '../../../../Costs.types.react';

import './TableCardHeader.react.scss';
import { Button } from 'react-bootstrap';
import { strings } from '~utils/strings';

type TableCardHeaderProps = {
  aggregators: string[];
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  route: IRoute;
  totalResults: number;
  pageIndex: number;
  tableInstance: TableInstance<ResourceSummary>;
  exportWorkloads: () => void;
};

const TableCardHeader = ({
  pageSize,
  pageIndex,
  totalResults,
  route,
  aggregators,
  setPageSize,
  tableInstance,
  exportWorkloads,
}: TableCardHeaderProps) => {
  return (
    <div className="costs-table-card-header">
      <div className="costs-table-card-header__section">
        <span className="costs-table-card-header__total-results">
          {`Showing ${Math.max(pageSize * pageIndex + 1)} -
                ${
                  pageSize * pageIndex + pageSize > totalResults ? totalResults : pageSize * pageIndex + pageSize
                } of ${new Intl.NumberFormat('en').format(totalResults)} results`}
        </span>
        <Button
          variant="primary"
          onClick={exportWorkloads}
          size="sm"
          data-cy="export-button"
          className="apply-changes-button"
        >
          {strings.general.Export}
        </Button>
      </div>
      <div className="costs-table-card-header__section">
        <DndProvider backend={HTML5Backend} debugMode={true}>
          <ToggleColumnsVisibility
            tableInstance={tableInstance}
            selectedCluster={route?.params?.cluster || ''}
            currentAggregators={aggregators}
          />
        </DndProvider>
        <ResultsDropdown pageSize={pageSize} changePageSize={setPageSize} />
      </div>
    </div>
  );
};

export default TableCardHeader;
