import React, { SetStateAction } from 'react';
import { MultiValue } from 'react-select';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toast } from 'react-hot-toast';

import { aggregatorOptions, costsRoutes, defaultHiddenColumns } from './Costs.config.react';

import {
  COLUMNS_ORDER,
  neverHideColumns,
  sortByOptions,
} from './Components/AggregatorTable/AggregatorTable.config.react';

import {
  ResourceSummary,
  CostsOptions,
  FormattedFilters,
  SelectedFilters,
  Interval,
  DateType,
  Filters,
  SyncedDatesAction,
  UpdatedReportsStatus,
} from './Costs.types.react';
import { Report, ReportsStatus, OptionType, IRoute, IRouter } from '~globalTypes';

import { CUMULATIVE_COSTS, CUMULATIVE_COSTS_CLUSTER } from '~reactComponents/NavigationReact/Navigation.config.react';

import { sortDescending } from '~utils/helpers';
import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import { COLORS } from '~utils/styling';
import { convertFiltersToURL, setURL, hasKey, formatDatetime, getCurrentTimezone } from '~reactHelpers';
import logger from '~logger';

dayjs.extend(utc);
dayjs.extend(timezone);

export const baseRequest = async (endpoint: string, org: string, searchParams: URLSearchParams) => {
  let data;

  const currentEndDate = searchParams.get('endDate');
  const currentStartDate = searchParams.get('startDate');

  const newStartDate = dayjs.utc(currentStartDate);
  // const newEndDate = dayjs.utc(currentEndDate).add(1, 'day');

  const newEndDate = dayjs.utc(currentEndDate).set('hour', 23).set('minute', 59).set('second', 59).toISOString();

  // const formattedEndDate = formatDatetime(newEndDate);
  const formattedStartDate = formatDatetime(newStartDate);
  searchParams.set('startDate', formattedStartDate);

  searchParams.set('endDate', newEndDate);

  if (searchParams.get('Cluster')) {
    searchParams.set('clusters', searchParams.get('Cluster') || '');
    searchParams.delete('Cluster');
  }

  try {
    data = await sendRequest('GET', `/v0/organizations/${org}/${endpoint}?${searchParams.toString()}`, {}, null);
  } catch (e: any) {
    toast.error(<b>{e.message}</b>);
    logger.logError(`error_retrieving_data_resources${endpoint}_endpoint`, e);
  }

  return data;
};

export const getSyncDate = async (
  cluster: string,
  org: string,
  setSyncedDates: React.Dispatch<Partial<SyncedDatesAction>> | React.Dispatch<SetStateAction<Record<string, string>>>,
) => {
  let reports: Record<string, ReportsStatus[]>;
  let clusterReports: ReportsStatus[];
  let enabledPrometheusReports: ReportsStatus[] = [];
  let enabledAWSReports: ReportsStatus[] = [];
  let enableCloudCostReports: ReportsStatus[] = [];
  const syncDateEndpoint = cluster
    ? `/v0/organizations/${org}/clusters/${cluster}/reports`
    : `/v0/organizations/${org}/reports`;

  try {
    if (cluster) {
      clusterReports = await sendRequest('GET', syncDateEndpoint, {}, null);
    } else {
      reports = await sendRequest('GET', syncDateEndpoint, {}, null);
    }
  } catch (e) {
    logger.logError('error_retrieving_reports', e);
  }

  if (reports) {
    [enabledPrometheusReports, enabledAWSReports, enableCloudCostReports] = getReportsForOrg(reports);
  }

  if (clusterReports) {
    [enabledPrometheusReports, enabledAWSReports, enableCloudCostReports] = getReportsForCluster(
      clusterReports,
      cluster,
    );
  }

  if (setSyncedDates) {
    if (enabledPrometheusReports.length > 0) {
      getAndSetSyncDate(cluster, enabledPrometheusReports, (newDate, isMoreThanOneDay) =>
        setSyncedDates({ syncedPrometheusDate: newDate }),
      );
    } else {
      setSyncedDates({ syncedPrometheusDate: '' });
    }

    if (enabledAWSReports.length > 0) {
      getAndSetSyncDate(cluster, enabledAWSReports, (newDate, isMoreThanOneDay) => {
        setSyncedDates({
          syncedAWSDate: newDate,
          syncedAWSDateCircleColor: isMoreThanOneDay ? 'yellow' : '',
        });
      });
    } else if (enableCloudCostReports.length > 0) {
      getAndSetSyncDate(cluster, enableCloudCostReports, (newDate, isMoreThanOneDay) =>
        setSyncedDates({
          syncedCloudCostsDate: newDate,
          syncedCloudCostsDateCircleColor: isMoreThanOneDay ? 'yellow' : '',
        }),
      );
    } else {
      setSyncedDates({ syncedCloudCostsDate: '' });
    }
  }
};

const getReportsForOrg = (reports: Record<string, ReportsStatus[]>) => {
  const enabledPrometheusReports: UpdatedReportsStatus[] = [];
  const enabledAWSReports: UpdatedReportsStatus[] = [];
  const enableCloudCostReports: UpdatedReportsStatus[] = [];

  Object.keys(reports).forEach((cluster) => {
    if (hasKey(reports, cluster)) {
      reports[cluster]?.forEach((report: ReportsStatus) => {
        if (report.Name === 'prometheus-metrics' && report.Enabled) {
          enabledPrometheusReports.push({ ...report, cluster });
        }
        if (report.Name === 'awscosts' && report.Enabled) {
          enabledAWSReports.push({ ...report, cluster });
        }
        if (report.Name === 'cloudcosts' && report.Enabled) {
          enableCloudCostReports.push({ ...report, cluster });
        }
      });
    }
  });
  return [enabledPrometheusReports, enabledAWSReports, enableCloudCostReports];
};

const getReportsForCluster = (reports: ReportsStatus[], cluster: string) => {
  const enabledPrometheusReports: UpdatedReportsStatus[] = [];
  const enabledAWSReports: UpdatedReportsStatus[] = [];
  const enableCloudCostReports: UpdatedReportsStatus[] = [];
  reports.forEach((report: UpdatedReportsStatus) => {
    if (report.Name === 'prometheus-metrics' && report.Enabled) {
      enabledPrometheusReports.push({ ...report, cluster });
    }
    if (report.Name === 'awscosts' && report.Enabled) {
      enabledAWSReports.push({ ...report, cluster });
    }
    if (report.Name === 'cloudcosts' && report.Enabled) {
      enableCloudCostReports.push({ ...report, cluster });
    }
  });
  return [enabledPrometheusReports, enabledAWSReports, enableCloudCostReports];
};

const getAndSetSyncDate = (
  cluster: string,
  enabledReports: UpdatedReportsStatus[],
  setSyncedDate: (newDate: string, isMoreThanOneDay: boolean) => void,
) => {
  const timeZone = getCurrentTimezone();
  if (enabledReports.length) {
    const clusterEnabledReports = cluster ? enabledReports.filter((report) => report.cluster === cluster) : [];
    if (!cluster || (cluster && clusterEnabledReports.length)) {
      const filteredDates = enabledReports
        .filter((report) => report.LastSeen)
        .map((filteredDate: Report | ReportsStatus) =>
          timeZone
            ? dayjs((filteredDate as ReportsStatus).LastSeen)
                .utc()
                .local()
                .tz(timeZone)
                .format()
            : dayjs((filteredDate as ReportsStatus).LastSeen)
                .utc()
                .local()
                .format(),
        )
        .sort((a: string, b: string) => sortDescending(a, b));

      const isMoreThanOneDay =
        (timeZone && dayjs().tz(timeZone).diff(filteredDates[0], 'day') > 1) ||
        dayjs().diff(filteredDates[0], 'day') > 1;

      if (isMoreThanOneDay) {
        const diff = timeZone
          ? dayjs().tz(timeZone).diff(filteredDates[0], 'day')
          : dayjs().diff(filteredDates[0], 'day');
        setSyncedDate(`${diff} ${diff > 1 ? strings.general.days : strings.general.day}`, isMoreThanOneDay);
      } else if (filteredDates[0]) {
        const day = timeZone
          ? dayjs(filteredDates[0]).tz(timeZone).format('MM/DD/YYYY')
          : dayjs(filteredDates[0]).format('MM/DD/YYYY');
        const time = timeZone
          ? dayjs(filteredDates[0]).tz(timeZone).format('h:mm A')
          : dayjs(filteredDates[0]).format('h:mm A');
        setSyncedDate(
          `${day} ${strings.general.at} ${time}`,
          (timeZone && dayjs().tz(timeZone).diff(filteredDates[0], 'day') > 1) ||
            dayjs().diff(filteredDates[0], 'day') > 1,
        );
      } else {
        setSyncedDate('', false);
      }
    } else {
      setSyncedDate('', false);
    }
  }
};

export const getFiltersFromQuery = (filterSections: string[], searchParams: URLSearchParams) => {
  const foundFilters: Record<string, string[]> = {};
  filterSections.forEach((section) => {
    const matchedFilter = searchParams.getAll(section);
    if (matchedFilter.length) {
      foundFilters[section] = matchedFilter;
    }
  });
  return foundFilters;
};

export const formatFiltersForURL = (
  filters: MultiValue<CostsOptions>,
  searchParams: URLSearchParams,
  isSelectedParams?: boolean,
) => {
  const formattedFilters: FormattedFilters[] = [];
  filters.forEach((filter: CostsOptions) => {
    const foundFilter = formattedFilters.find((formattedFilter) => formattedFilter.id === filter.section);

    if (!foundFilter) {
      formattedFilters.push({
        id: filter.section,
        value: [{ value: filter.value, label: filter.value }],
      });
    } else {
      foundFilter.value.push({
        value: filter.value,
        label: filter.value,
      });
    }
  });

  if (isSelectedParams) {
    const foundWorkloadLabel = formattedFilters.find((formattedFilter) => formattedFilter.id === workloadLabels);
    const foundSearchWorkloadLabel = searchParams.get(workloadLabels);
    if (!foundWorkloadLabel && !foundSearchWorkloadLabel) {
      formattedFilters.push({
        id: workloadLabels,
        value: [],
      });
    }
    const foundNamespaceLabel = formattedFilters.find((formattedFilter) => formattedFilter.id === namespaceLabels);
    const foundSearchNamespaceLabel = searchParams.get(namespaceLabels);
    if (!foundNamespaceLabel && !foundSearchNamespaceLabel) {
      formattedFilters.push({
        id: namespaceLabels,
        value: [],
      });
    }
    const foundPodLabel = formattedFilters.find((formattedFilter) => formattedFilter.id === podLabels);
    const foundSearchPodLabel = searchParams.get(podLabels);
    if (!foundPodLabel && !foundSearchPodLabel) {
      formattedFilters.push({
        id: podLabels,
        value: [],
      });
    }
  }

  return convertFiltersToURL(formattedFilters, searchParams);
};

export const formatFiltersForState = (filters: Record<string, string[]>): SelectedFilters => {
  const typedFilters = filters as unknown as SelectedFilters;
  Object.keys(filters).forEach((key) => {
    const formatted = filters[key].map((value) => ({
      value: value,
      label: value,
      section: key,
    }));
    typedFilters[key] = formatted;
  });

  return typedFilters;
};

export const findSum = (data: any, label: string): number => {
  return data.reduce((sum: number, datum: any) => {
    sum += datum[label];
    return sum;
  }, 0);
};

export const formatCost = (cost: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cost);

export const formatPercentage = (value: number) =>
  `${new Intl.NumberFormat('en', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100)}`;

export const updatePageURL = async (searchParams: URLSearchParams, route: IRoute, router: () => IRouter) => {
  let updatedParams = route.params;

  if (!route?.params?.cluster && route.fullPath?.includes('Clusters=')) {
    updatedParams = { ...route.params, cluster: searchParams.get('clusters') };
  }
  if (route?.params?.cluster && searchParams.toString().length && !searchParams.has('clusters')) {
    searchParams.delete('clusters');
    try {
      await router().push({
        ...route,
        name: CUMULATIVE_COSTS,
        params: route.params,
      });
    } catch (e: any) {
      logger.logError('error_updating_costs_url', { message: e.message });
    }
    setURL({
      route,
      nameToMatch: CUMULATIVE_COSTS,
      searchParams: searchParams?.toString(),
      router,
    });
  } else if (costsRoutes.includes(route?.name || '')) {
    try {
      await router().push({
        ...route,
        name: updatedParams?.cluster ? CUMULATIVE_COSTS_CLUSTER : CUMULATIVE_COSTS,
        params: updatedParams,
      });
    } catch (e: any) {
      logger.logError('error_updating_costs_url', { message: e.message });
    }
    setURL({
      route,
      nameToMatch: updatedParams?.cluster ? CUMULATIVE_COSTS_CLUSTER : CUMULATIVE_COSTS,
      searchParams: searchParams?.toString(),
      router,
    });
  }
};

export const formatChangedDates = (retrievedInterval: Interval) => {
  const timeZone = getCurrentTimezone();

  const end = formatDatetime(
    dayjs.utc(
      retrievedInterval.endDateTime === '0001-01-01T00:00:00Z'
        ? dayjs().subtract(1, 'day')
        : retrievedInterval.endDateTime,
    ),
  );
  return {
    start: formatDatetime(
      dayjs(
        retrievedInterval.initialDateTime === '0001-01-01T00:00:00Z'
          ? dayjs().subtract(1, 'month')
          : timeZone
            ? dayjs(retrievedInterval.initialDateTime).tz(timeZone)
            : retrievedInterval.initialDateTime,
      ),
    ),
    end,
  };
};

export const buildStartOfQuery = (
  searchParams: URLSearchParams,
  flattenedPassedFilters: CostsOptions[] = [],
  aggregators: MultiValue<OptionType>,
  dates: DateType,
  route: IRoute,
) => {
  if (searchParams.toString().length && flattenedPassedFilters.length && !aggregators.length) {
    aggregatorOptions
      .map((aggregator) => aggregator.value)
      .forEach((filter) => {
        if (filter && searchParams.has(filter)) searchParams.delete(filter);
      });
  }

  if (searchParams.has('redirect')) searchParams.delete('redirect');

  searchParams.set('endDate', dates.end);
  searchParams.set('startDate', dates.start);

  if (searchParams.has('Clusters')) searchParams.delete('Clusters');

  if (route?.params?.cluster) {
    searchParams.set('clusters', route.params.clusters);
  }

  return searchParams;
};

export const buildFilterParams = (
  searchParams: URLSearchParams,
  cluster: string,
  flattenedPassedFilters: CostsOptions[] = [],
  flattenedSelectedFilters: CostsOptions[] = [],
  wasFilterRemoved: boolean,
  filters: Filters | null,
  currentAction: string,
  isRouteUpdated: boolean,
) => {
  const filteredSelectedFilters = flattenedSelectedFilters.filter(
    (filter) => filter.value !== strings.punctuation.asterisk,
  );
  if (!cluster && !flattenedPassedFilters.length && filteredSelectedFilters.length) {
    searchParams = formatFiltersForURL(filteredSelectedFilters, searchParams);
  }

  if (flattenedPassedFilters.length) {
    searchParams = formatFiltersForURL(flattenedPassedFilters, searchParams);
  }

  if (filteredSelectedFilters.length) {
    searchParams = formatFiltersForURL(filteredSelectedFilters, searchParams, true);
  }

  if (currentAction !== 'mounting') {
    const currentFilters = filteredSelectedFilters.map((filter) => filter.section);
    if (filters) {
      Object.keys(filters)
        .filter((filter) => !currentFilters.includes(filter))
        .forEach((unusedFilter) => searchParams.delete(unusedFilter));
    }
    // billedCostGreaterThan is not returned on filters
    if (!currentFilters.includes('billedCostGreaterThan')) {
      searchParams.delete('billedCostGreaterThan');
    }
    if (!currentFilters.includes('networkingLessThanInMB')) {
      searchParams.delete('networkingLessThanInMB');
    }
    if (!currentFilters.includes('dateRangeOption')) {
      searchParams.delete('dateRangeOption');
    }
  }

  if (currentAction === 'mounting') {
    const retrievedURLFilters = getFiltersFromQuery(Object.keys(filters || {}), searchParams);
    const formattedQueryFilters = formatFiltersForState(retrievedURLFilters);
    const flattenedQueryFilters = Object.values(formattedQueryFilters).flat();

    if (wasFilterRemoved) {
      Object.keys(filters || {}).forEach((filter) => {
        if (searchParams.has(filter)) {
          searchParams.delete(filter);
        }
      });
    } else if (flattenedQueryFilters.length || isRouteUpdated) {
      searchParams = formatFiltersForURL(flattenedQueryFilters, searchParams);
    }
  }

  return searchParams;
};

export const buildColumnsQuery = (searchParams: URLSearchParams) => {
  if (!localStorage.getItem('costs-table-reset-03-2024')) {
    localStorage.removeItem('costs-table');
    localStorage.setItem('costs-table-reset-03-2024', 'true');
  }
  const hiddenColumns = localStorage.getItem('costs-table')
    ? JSON.parse(localStorage.getItem('costs-table')).hiddenColumns
    : defaultHiddenColumns;

  const visibleColumns = COLUMNS_ORDER.filter((column) => !hiddenColumns.includes(column)).filter(
    (filteredColumn) => !neverHideColumns.includes(filteredColumn),
  );

  visibleColumns.forEach((column) => {
    const formattedKey = column.replaceAll('.', '_');
    if (hasKey(sortByOptions, formattedKey)) {
      if (!searchParams.getAll('column').includes(sortByOptions[formattedKey])) {
        searchParams.append('column', sortByOptions[formattedKey]);
      }
    }
  });

  return searchParams;
};

export const buildAggregatorsQuery = (
  searchParams: URLSearchParams,
  aggregators: (OptionType | CostsOptions)[],
  selectedAggregators: (OptionType | CostsOptions)[],
) => {
  const aggregatorsToIterateOver = aggregators.length > 0 ? aggregators : selectedAggregators;
  searchParams.delete('aggregators');
  searchParams.delete('aggregationLabels');

  if (aggregatorsToIterateOver) {
    aggregatorsToIterateOver.forEach((aggregator) => {
      const hasAggregatorParam = searchParams.has('aggregators');
      const hasLabelParam = searchParams.has('aggregationLabels');

      if (
        aggregator?.section &&
        hasLabelParam &&
        !searchParams.getAll('aggregationLabels').includes(`${aggregator.section}:${aggregator.label}`) &&
        (aggregators.length > 1 || selectedAggregators.length > 1)
      ) {
        searchParams.append('aggregationLabels', `${aggregator.section}:${aggregator.label}`);
      } else if (aggregator?.section?.length && !hasLabelParam) {
        searchParams.set('aggregationLabels', `${aggregator.section}:${aggregator.label}`);
      } else if (
        !aggregator.section &&
        hasAggregatorParam &&
        !searchParams.getAll('aggregators').includes(aggregator.value) &&
        (aggregators.length > 1 || selectedAggregators.length > 1)
      ) {
        searchParams.append('aggregators', aggregator.value);
      } else if (!aggregator.section && !hasAggregatorParam) {
        searchParams.set('aggregators', aggregator.value);
      }
    });
  }
  return searchParams;
};

export const findLabel = (section: string): string => {
  let labelAggregator = '';
  if (section) {
    switch (section) {
      case 'workload':
        labelAggregator = 'workloadLabels';
        break;
      case 'namespace':
        labelAggregator = 'namespaceLabels';
        break;
      case 'pod':
        labelAggregator = 'podLabels';
        break;
      default:
        labelAggregator = '';
        break;
    }
  }
  return labelAggregator;
};

export function getSelectedAggregators(searchParams: URLSearchParams) {
  const allAggregators: (OptionType | CostsOptions)[] = [];
  if (searchParams.getAll('aggregators').length || searchParams.getAll('aggregationLabels').length) {
    searchParams.getAll('aggregators').forEach((aggregator) =>
      allAggregators.push({
        value: aggregator,
        label: aggregator,
      }),
    );
    searchParams.getAll('aggregationLabels').forEach((label) => {
      const splitLabel = label.split(':');
      allAggregators.push({
        value: `${splitLabel[1]}-${splitLabel[0]}`,
        label: splitLabel[1],
        section: splitLabel[0],
      });
    });
    return allAggregators;
  }
  return [{ value: 'cluster', label: strings.general.cluster }];
}

export const getSelectedTimeRange = (timeZone: string | null, searchParams: URLSearchParams) => {
  return {
    start: searchParams.get('startDate') || formatDatetime(dayjs().subtract(1, 'month')),
    end: searchParams.get('endDate') || formatDatetime(dayjs()),
  };
};

export const getResourceTypeOptions = (aggregatorData: ResourceSummary) => ({
  cpuCost: {
    cpuCost: aggregatorData.costs.actual.cpu || 0,
    cpuCostColor: COLORS.CHARTS.PACKAGES[100],
  },
  memoryCost: {
    memoryCost: aggregatorData.costs.actual.memory || 0,
    memoryCostColor: COLORS.CHARTS.BULLET.RANGE_1,
  },
  networkReceiveCost: {
    networkReceiveCost: aggregatorData.costs.actual.networkReceive || 0,
    networkReceiveCostColor: COLORS.CHARTS.WORKLOADS[300],
  },
  networkTransmitCost: {
    networkTransmitCost: aggregatorData.costs.actual.networkTransmit || 0,
    networkTransmitCostColor: COLORS.CHARTS.CAPACITY[270],
  },
  storageCapacityCost: {
    storageCapacityCost: aggregatorData.costs.actual.storageCapacity || 0,
    storageCapacityCostColor: COLORS.CHARTS.CAPACITY[150],
  },
});

export const workloadLabels = 'workloadLabels';
export const namespaceLabels = 'namespaceLabels';
export const podLabels = 'podLabels';
