import React, { SyntheticEvent, useRef, useState } from 'react';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useRootClose from 'react-overlays/useRootClose';
import { TableInstance } from 'react-table';

import MoveIcon from '~reactIcons/Move.icon.react';
import Icon from '~reactIcons/Icon.react';

import { ResourceSummary } from '../../../../Costs.types.react';
import { neverHideColumns } from '../../AggregatorTable.config.react';

import { COLORS } from '~utils/styling';

import './ToggleColumnsVisibility.react.scss';

type ToggleColumnsVisibilityProps = {
  tableInstance: TableInstance<ResourceSummary>;
  selectedCluster?: string | undefined;
  currentAggregators: string[];
};

const ToggleColumnsVisibility = ({
  tableInstance,
  selectedCluster,
  currentAggregators,
}: ToggleColumnsVisibilityProps): JSX.Element => {
  const { allColumns, state } = tableInstance;
  const ref: React.Ref<HTMLDivElement & { 'aria-labelledby': string }> = useRef<
    HTMLDivElement & { 'aria-labelledby': string }
  >(null);
  const [show, setShow] = useState(false);

  const handleRootClose = () => setShow(false);

  useRootClose(ref, handleRootClose, {
    disabled: !show,
  });

  const CustomToggle = React.forwardRef<
    HTMLInputElement,
    { onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }
  >(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // e.stopPropagation();
        onClick(e);
      }}
      className="costs-table-hidden-columns__custom-toggle"
    >
      {children}
    </div>
  ));

  const CustomMenu = React.forwardRef<
    HTMLDivElement,
    { className: string; 'aria-labelledby': string }
  >(({ children, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div ref={ref} className={`list-unstyled ${className}`} aria-labelledby={labeledBy}>
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <Droppable droppableId="costs-columns">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {React.Children.toArray(children).filter((child) => {
                return (
                  !value ||
                  (child as JSX.Element)?.props.draggableId.toLowerCase().startsWith(value)
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    );
  });

  const handleDropdownToggle = (
    isOpen: boolean,
    event: SyntheticEvent<Dropdown>,
    metadata: {
      source: 'select' | 'click' | 'rootClose' | 'keydown';
    },
  ) => {
    if (metadata.source === 'click') {
      setShow(!show);
    } else {
      setShow(true);
    }
  };

  const Toggle = React.memo(({ hiddenColumnsCounts }: { hiddenColumnsCounts: number }) => {
    return (
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Icon name="eye-slash" height="1rem" width="1rem" fill={COLORS.CORE.WHITE} />
        <span>{hiddenColumnsCounts} hidden columns</span>
      </Dropdown.Toggle>
    );
  });

  // need for cluster level, but not org level, will need to find new logic
  const hiddenColumnsCount = allColumns.filter((col) => {
    if (col.id === 'Cluster' && selectedCluster) {
      return false;
    }
    return !col.isVisible;
  }).length;

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const columns = Array.from(allColumns);
    const [reorderedItem] = columns.splice(result.source.index, 1);
    columns.splice(result.destination.index, 0, reorderedItem);
    tableInstance.setColumnOrder(columns.map((item) => item.id));
    return columns;
  };

  const disabledSwitch = (column: string) => {
    if (currentAggregators.includes('workload') && column === 'qos') {
      return false;
    }
    return (
      neverHideColumns.includes(column) &&
      currentAggregators.length &&
      !currentAggregators.includes(column)
    );
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Dropdown
        focusFirstItemOnShow
        show={show}
        onToggle={handleDropdownToggle}
        className="costs-table-hidden-columns"
      >
        <Toggle hiddenColumnsCounts={hiddenColumnsCount} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Dropdown.Menu as={CustomMenu} ref={ref}>
          {allColumns.map((column, index) => {
            if (column.id === 'Cluster' && selectedCluster) return <></>;
            return (
              <Draggable key={column.id} draggableId={column.Header as string} index={index}>
                {(provided) => (
                  <Dropdown.Item
                    as="li"
                    eventKey={column.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        {
                          <Form>
                            <Form.Check
                              id={column.id}
                              type="switch"
                              {...column.getToggleHiddenProps()}
                              disabled={disabledSwitch(column.id)}
                            />
                          </Form>
                        }
                        <span>{column.Header}</span>
                      </div>
                      <MoveIcon />
                    </div>
                  </Dropdown.Item>
                )}
              </Draggable>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </DragDropContext>
  );
};

export default ToggleColumnsVisibility;
