import React from 'react';
import clsx from 'clsx';
import { Card } from 'react-bootstrap';
import ProgressBarComponent from '../ProgressBar/ProgressBar.react';
import { sortDescending } from '~utils/helpers';
import { standardizedReports } from '~views/organization/compliance/Compliance.helpers.react';
import { StandardsSummary } from '~views/organization/compliance/Compliance.types.react';
import { strings } from '~utils/strings';
import { MediumBasicText, CardTitlePrimary } from '~utils/texts.react';
import './CheckTypes.react.scss';

type CheckTypesProps = {
  totalTypes: StandardsSummary[];
};

const CheckTypesCard = ({ totalTypes }: CheckTypesProps): JSX.Element => {
  const CheckForTypes = (): JSX.Element => {
    const finalTypes = totalTypes
      .sort((a, b) => sortDescending(a.standard, b.standard))
      .map((type: StandardsSummary, index: number) => {
        const name = standardizedReports(type.standard);
        const standardTotal =
          (type.summary?.passing || 0) +
          (type.summary?.notStarted || 0) +
          (type.summary?.needsEvidence || 0) +
          (type.summary?.notInScope || 0) +
          (type.summary?.failing || 0);
        return (
          <ProgressBarComponent
            key={index}
            name={name}
            itemTotal={type.summary.passing}
            total={standardTotal}
            color={`bar-${index}`}
          />
        );
      });
    return <>{finalTypes}</>;
  };

  return (
    <Card className="check-type-card">
      <Card.Body>
        <Card.Title
          className={clsx(
            'mb-3',
            CardTitlePrimary({ textTransform: strings.textStyling.capitalize }),
          )}
        >
          {strings.checkType}
        </Card.Title>
        {totalTypes.length === 0 && (
          <p
            className={clsx(
              'error-message',
              MediumBasicText({ weight: strings.textStyling.regular }),
            )}
          >{`${strings.sorry}, ${strings.noCheckTypes}`}</p>
        )}
        {totalTypes.length > 0 && <CheckForTypes />}
      </Card.Body>
    </Card>
  );
};

export default CheckTypesCard;
