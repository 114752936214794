import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Filters } from 'react-table';

import PieChart from '~reactComponents/charts/PieChart/PieChart.react';
import ClearButton from '~reactComponents/ClearButton/ClearButton.react';
import { LoadingSpinner } from '@fairwindsops/ui-components';

import { ActionItem, AppGroup, Team, TopSeverities } from '../../../ActionItems.types.react';

import { colorScheme } from '~config/main.config';
import { sendRequest } from '~utils/request';
import { hasKey, convertFiltersToURL } from '~reactHelpers';
import logger from '~logger';
import { strings } from '~utils/strings';

import '../Charts.react.scss';
import { useDebouncedCallback } from 'use-debounce';
import { additionalQueryParams } from '../Charts.react';

type SeverityChartProps = {
  baseURL: string;
  filters: Filters<ActionItem>;
  noData?: React.ReactNode;
  selectedTeam?: Team | null;
  selectedAppGroup?: AppGroup | null;
  setFilter: (columnId: string, updater: any) => void;
};

const SeverityChartReact = ({
  baseURL,
  filters,
  noData,
  selectedTeam,
  selectedAppGroup,
  setFilter,
}: SeverityChartProps) => {
  const [topSeverities, setTopSeverities] = useState<TopSeverities[]>();
  const chartClicked = useRef<boolean>(false);
  const [loading, setLoading] = useState(true);

  const debounceGetTopSeverities = useDebouncedCallback(async () => {
    const params = buildURLSearchParams();
    const URLParams = convertFiltersToURL(filters, params);
    try {
      const data = await sendRequest('GET', `${baseURL}/action-items/top?${URLParams}`, {}, null);
      setTopSeverities(data);
    } catch (e) {
      console.error('Error retrieving top severities: ', e);
      logger.logError('error_retrieving_top_severities: ', e);
      toast.error(strings.actionItemsPage.errorRetrievingTopSeverities);
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    setLoading(true);
    debounceGetTopSeverities();
  }, [filters, selectedTeam, selectedAppGroup]);

  const clearFilter = () => {
    setFilter('Severity', []);
    chartClicked.current = false;
  };

  const buildURLSearchParams = () => {
    const currentURLSearchParams = new URLSearchParams(window.location.search);

    let url = 'groupBy=severity&page=0&pageSize=6';

    if (selectedTeam?.ID) {
      url = `${url}&TeamID=${selectedTeam.ID}`;
    }

    if (selectedAppGroup?.name) {
      url = `${url}&AppGroup=${selectedAppGroup.name}`;
    }

    additionalQueryParams.forEach((param) => {
      if (currentURLSearchParams.get(param)) {
        url = `${url}&${param}=${currentURLSearchParams.get(param)}`;
      }
    });

    return new URLSearchParams(url);
  };

  const data = useMemo(() => {
    if (!filters.length) {
      return [];
    }
    return topSeverities?.map((severity) => ({
      label: severity.Severity.charAt(0).toUpperCase() + severity.Severity.slice(1),
      id: severity.Severity,
      color: hasKey(colorScheme, severity.Severity) ? colorScheme[severity.Severity] : '',
      value: severity.Count,
    }));
  }, [topSeverities]);

  const handleChartClick = (data: any) => {
    setFilter('Severity', [{ value: data.id, label: data.id }]);
    chartClicked.current = true;
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <ClearButton
            isChartClicked={chartClicked.current}
            clearFilter={clearFilter}
            isFiltered={filters?.find((filter) => filter.id === 'Severity')?.value?.length}
          />
          {data?.length ? (
            <div aria-label={strings.ariaLabels.severityPieChart} className="action-item--charts">
              <PieChart data={data || []} handleChartClick={handleChartClick} />
            </div>
          ) : (
            <>{noData}</>
          )}
        </>
      )}
    </>
  );
};

export default SeverityChartReact;
