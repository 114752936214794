import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Column } from 'react-table';

import DisplayTags from '~reactComponents/DisplayTags/DisplayTags.react';
import DownloadIcon from '~reactIcons/Download.react';

import { Summary, ReportsSummary } from '../Compliance.types.react';
import { OptionType, IRouter } from '~globalTypes';

import { standardizedReports } from '../Compliance.helpers.react';
import { strings } from '~utils/strings';

export const TABLE_COLUMNS = (
  handleEditModal: (report: ReportsSummary) => void,
  router: () => IRouter,
): Column[] => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ data, value }: { value: string; data: ReportsSummary[] }) => {
      const matchingReport = data.find((report) => report.name === value);
      return (
        <div
          className="name-div"
          onClick={() =>
            router().push({
              name: 'report',
              query: { reportID: matchingReport?.id },
            })
          }
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: 'Clusters',
    accessor: 'clusters',
    Cell: ({ value }: { value: string[] }) => {
      const reformattedClusters = value.map((cluster) => ({ value: cluster, label: cluster }));
      return (
        <div className="table-tags-div">
          <DisplayTags
            options={reformattedClusters}
            admin={false}
            isGray
            tagClassNames="ellipsis-tag"
          />
        </div>
      );
    },
  },
  {
    Header: 'Types',
    accessor: 'standards',
    Cell: ({ value }: { value: string[] }) => {
      const reformattedTypes = value.map((type) => {
        const formattedType = standardizedReports(type);
        return { value: type, label: formattedType };
      });
      return (
        <div className="table-tags-div">
          <DisplayTags
            options={reformattedTypes}
            admin={false}
            isGray
            tagClassNames="ellipsis-tag"
          />
        </div>
      );
    },
  },
  {
    Header: 'Progress',
    accessor: strings.noTranslate.summary,
    Cell: ({ value }: { value: Summary }) => {
      // TODO: swap srOnly to visuallyHidden when bootstrap & react-bootstrap upgraded
      const total = value.notImplemented + value.needsEvidence + value.implemented;
      return (
        <div className="progress-div">
          <ProgressBar className="bar-parent">
            <ProgressBar
              className="bar-child"
              variant="success"
              now={value.implemented}
              max={total}
              label={`${value.implemented}%`}
              srOnly
              key={1}
            />
            <ProgressBar
              className="bar-child"
              variant="medium"
              now={value.needsEvidence}
              max={total}
              label={`${value.needsEvidence}%`}
              srOnly
              key={2}
            />
            <ProgressBar
              className="bar-child"
              variant="critical"
              now={value.notImplemented}
              max={total}
              label={`${value.notImplemented}%`}
              srOnly
              key={3}
            />
          </ProgressBar>
        </div>
      );
    },
  },
  {
    Header: 'Update',
    id: 'update',
    accessor: 'id',
    Cell: (value) => {
      return (
        <div className="edit-div" onClick={() => handleEditModal(value.data[value.cell.row.index])}>
          Edit
        </div>
      );
    },
  },
  {
    Header: 'Download',
    id: 'download',
    Cell: ({ data, row }: { data: ReportsSummary[]; row: any }) => {
      const route = router().resolve({
        name: 'download-report',
        query: { reportID: data[row.id]?.id },
      });

      return (
        <div className="download-div" onClick={() => window.open(route.href, '_blank')}>
          <DownloadIcon marginRight="0.5rem" />
          Download
        </div>
      );
    },
  },
];

export const TYPES_OPTIONS: OptionType[] = [
  { value: 'hipaa', label: 'HIPAA' },
  { value: 'soc_2', label: 'SOC 2' },
  { value: 'iso', label: 'ISO 27001' },
  { value: 'nsa', label: 'NSA Hardening Guidance' },
];
