import React, { Children } from 'react';
import Select, { components, ValueContainerProps } from 'react-select';
import { TableInstance, Row } from 'react-table';

import Option from '~reactComponents/OptionComponent/OptionComponent.react';

import { ActionItemFilters, List } from '../../ActionItems.types.react';
import { OptionType } from '~globalTypes';

import { SELECT_STYLES, TRANSFORM_OPTIONS } from '../../ActionItems.config.react';

import { RESIZED_WIDTH_STORAGE_KEYS, saveResizedWidth } from '~reactHelpers';
import { COLORS } from '~utils/styling';

import './SelectColumnFilter.react.scss';

type SelectColumnFilterType = {
  tableInstance: TableInstance;
  filters: ActionItemFilters;
  setSelectedSavedList?: React.Dispatch<React.SetStateAction<List | null>>;
  selectedSavedList?: List | null;
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  const allowedTypes = ['MultiValue'];
  const childrenWithoutPlaceholder = Children.map(children, (child) => {
    const typedChild = child as Record<string, any>;
    return child && allowedTypes.includes(typedChild?.type?.name) ? child : null;
  });

  const length = React.Children.count(childrenWithoutPlaceholder) - 1;

  if (length >= 1) {
    return (
      <components.ValueContainer {...props}>
        {children} (+{length} others)
      </components.ValueContainer>
    );
  }
  return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
};

const MenuList = ({ children, ...props }: any) => (
  <components.MenuList {...props}>
    {
      Array.isArray(children) && props.selectProps?.maxOptions
        ? children.slice(0, props.selectProps?.maxOptions) /* Options */
        : children /* NoOptionsLabel */
    }
  </components.MenuList>
);

const SelectColumnFilter = ({
  tableInstance,
  filters,
  setSelectedSavedList,
  selectedSavedList,
}: SelectColumnFilterType) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  } = tableInstance;

  let options: OptionType[];

  if (filters?.[id] && Array.isArray(filters[id])) {
    options = (filters[id] || []).map((option: any) => {
      if (typeof option === 'object') {
        return option;
      }
      return {
        label: TRANSFORM_OPTIONS[id] ? TRANSFORM_OPTIONS[id](option) : option,
        value: TRANSFORM_OPTIONS[id] ? TRANSFORM_OPTIONS[id](option) : option,
      };
    });
  } else {
    options = React.useMemo(() => {
      const options = new Set();
      preFilteredRows?.forEach((row: Row) => {
        options.add(row.values[id]?.toString());
      });
      return Array.from(options).map((option: any) => ({
        label: option,
        value: option,
      }));
    }, [id, preFilteredRows]);
  }

  const handleChange = (selected: any) => {
    // If the user had a list selected, we unselect it now that a filter has been changed
    if (selectedSavedList && setSelectedSavedList) {
      setSelectedSavedList(null);
    }

    saveResizedWidth(RESIZED_WIDTH_STORAGE_KEYS.actionItemsPage, tableInstance);

    setFilter(selected);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Select
        className="custom--select"
        classNamePrefix="custom--select"
        aria-label={Header}
        styles={SELECT_STYLES}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
            primary25: COLORS.CHARTS.SELECT_FILTER_PRIMARY,
          },
        })}
        isMulti
        value={filterValue}
        options={options}
        placeholder="Filter"
        isSearchable
        isClearable
        isLoading={!filters[id]}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          MenuList,
          // eslint-disable-next-line
          // @ts-ignore
          ValueContainer,
        }}
        onChange={(selected) => handleChange(selected)}
        maxMenuHeight={1000}
      />
    </div>
  );
};

export default React.memo(SelectColumnFilter);
