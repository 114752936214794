import React, { useEffect, useState } from 'react';

import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { Card } from '@fairwindsops/ui-components';
import { AppGroupsStatistics } from '../AppGroupsReports.config.react';

import LargeBarChart from '~reactComponents/charts/LargeBarChart/LargeBarChart.react';

import './AggregatorBarChart.react.scss';
import Case from 'case';

type BarChartProps = {
  data: AppGroupsStatistics[];
  isLoading: boolean | undefined;
  title: string;
  colors: Record<string, string>;
};

const AggregatorBarChart = ({ data, isLoading, title, colors }: BarChartProps): JSX.Element => {
  const getKeys = (): string[] => {
    const allKeys: string[] = [];
    for (let i = 0; i < data.length; i++) {
      const keys = Object.keys(data[i]).filter((key) => key !== 'appGroup' && key !== 'policyMapping');
      allKeys.push(...keys);
    }
    return Array.from(new Set(allKeys));
  };

  return (
    <div>
      <Card className="action-item-aggregator-summary-bar-chart">
        <Card.Title className="chart-title">
          <span className="aggregators">{title}</span>
        </Card.Title>
        <Card.Body>
          {isLoading && <LoadingSpinner containerClassNames="spinner" />}
          {!isLoading && data.length > 0 && (
            <div className="app-groups-chart">
              <LargeBarChart
                data={data}
                enableLabel={false}
                keys={getKeys()}
                indexBy={'appGroup'}
                margin={{ top: 10, right: 10, bottom: 255, left: 85 }}
                colors={({ id }) => {
                  return colors[id];
                }}
                groupMode="grouped"
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 2,
                  tickRotation: 0,
                }}
                axisBottom={{
                  tickSize: 3,
                  tickPadding: 2,
                  tickRotation: -45,
                  renderTick: (props: any) => {
                    return (
                      <g transform={`translate(${props.x},${props.y})`}>
                        <text x={-5} y={0} dy={0} textAnchor="end" transform={`rotate(${props.rotate})`} fill="black">
                          {Case.capital(props.value)}
                        </text>
                      </g>
                    );
                  },
                }}
                tooltip={(d: any, label?: string) => (
                  <div className="action-items-reports-bar-chart__tooltip">
                    <div>
                      {' '}
                      {d.appGroup}
                      {d.policyMapping && (
                        <div>
                          <span>{d.policyMapping}</span>
                        </div>
                      )}
                    </div>
                    <span>
                      {label?.substring(0, label?.indexOf(' - ')) +
                        ': ' +
                        (label ? d[label?.substring(0, label?.indexOf(' - '))] : '')}
                    </span>
                  </div>
                )}
              />
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default AggregatorBarChart;
