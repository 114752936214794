import React from 'react';
import { Row } from 'react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import Tag from '~reactComponents/Tags/Tag.react';
import UserAvatar from '~reactComponents/UserAvatar/UserAvatar.react';
import Icon from '~reactComponents/Icons/Icon.react';

import { AVATAR_COLORS } from '~utils/constants';
import { CurrentUser, OptionType, Team, TeamMembership } from '~utils/global.types.react';
import { randomAvatarColor } from '~utils/helpers';
import { strings } from '~utils/strings';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { COLORS } from '~utils/styling';

dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(timezone);

const chooseAvatarColor = (content: string) => randomAvatarColor(content, AVATAR_COLORS);

type getAccountTableColumnsParams = {
  onMembershipRemoved: (email: string) => void;
  onMembershipOwnerChanged: (membership: TeamMembership) => void;
  onAccountTeamRemoved: (email: string, teamId: string) => void;
  onAccountTeamAdded: (team: Team, email: string) => void;
  user: CurrentUser;
  isOrgOwner: boolean;
  teams: Team[];
};

export const getAccountTableColumns = ({
  onMembershipRemoved,
  onMembershipOwnerChanged,
  onAccountTeamRemoved,
  onAccountTeamAdded,
  user,
  isOrgOwner,
  teams,
}: getAccountTableColumnsParams) => [
  {
    Header: strings.teamManagement.name,
    accessor: 'Name',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: string; row: Row }) => {
      const splitedName = row.original.Name;

      const firstName = splitedName[0].charAt(0);
      const lastName = splitedName[1].charAt(0);

      return (
        <div className="accounts-table__name-container">
          <UserAvatar
            firstName={firstName}
            lastName={lastName}
            backgroundColor={chooseAvatarColor(`${firstName} ${lastName}`)}
          />
          <span title={value}>{value}</span>
        </div>
      );
    },
    minWidth: 250,
  },
  {
    Header: strings.teamManagement.email,
    accessor: 'Email',
    className: 'no-overflow',
    Cell: ({ value }: { value: number }) => {
      return <span title={`${value}`}>{value}</span>;
    },
    minWidth: 250,
  },
  {
    Header: strings.teamManagement.ownerAccess,
    accessor: 'IsOwner',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      return (
        <input
          type="checkbox"
          onChange={() => onMembershipOwnerChanged(row.original as TeamMembership)}
          checked={row.original.IsOwner}
          disabled={row.original.Email === user.Email || !isOrgOwner}
        />
      );
    },
    minWidth: 50,
    maxWidth: 150,
  },
  {
    Header: strings.teamManagement.teams,
    accessor: 'Teams',
    className: 'no-overflow',
    Cell: ({ value, row }: { value: OptionType[]; row: Row }) => {
      const notAddedTeams = teams.filter(
        (team: Team) => !row.original.Teams.find((t) => +t.value === team.ID),
      );

      return (
        <div className="accounts-table__tag-container">
          {notAddedTeams?.length && isOrgOwner ? (
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={
                <Popover id={`add-team-for-${row.original.Email}`}>
                  <Popover.Content>
                    {notAddedTeams.map((team) => (
                      <div
                        onClick={() => onAccountTeamAdded(team, row.original.Email)}
                        className="accounts-table__add-team-option"
                      >
                        {team.Name}
                      </div>
                    ))}
                  </Popover.Content>
                </Popover>
              }
              rootClose
            >
              <Icon name="plus-circle" fill={COLORS.CORE.LINK} width="1rem" height="1rem" />
            </OverlayTrigger>
          ) : null}
          {value?.length
            ? value.map((team) => (
                <Tag
                  hollow={true}
                  fontSize={'0.675rem'}
                  isRemovable={isOrgOwner}
                  removeTag={() => onAccountTeamRemoved(row.original.Email, team.value)}
                  key={team.value}
                  option={team}
                  tagClassNames="accounts-table__tag"
                >
                  {team.label}
                </Tag>
              ))
            : null}
        </div>
      );
    },
    minWidth: 300,
  },
  {
    Header: strings.teamManagement.lastLogin,
    accessor: 'LastLogin',
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      return (
        <span>
          {row.original.LastLogin
            ? dayjs(row.original.LastLogin).format('YYYY-MM-DD HH:mm:ss')
            : null}
        </span>
      );
    },
    minWidth: 150,
  },
  {
    Header: strings.teamManagement.action,
    className: 'no-overflow',
    Cell: ({ row }: { row: Row }) => {
      return user.Email === row.original.Email || !isOrgOwner ? null : (
        <span
          className="accounts-table__remove"
          onClick={() => onMembershipRemoved(row.original.Email)}
        >
          {strings.teamManagement.remove}
        </span>
      );
    },
    minWidth: 50,
  },
];
