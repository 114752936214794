import React, { useState, useEffect } from 'react';
import { Card } from '@fairwindsops/ui-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ClusterBarProps, ClusterData } from '../../../Efficiency.types.react';
import { formatData } from '../../../Efficiency.helpers.react';
import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';
import './ClusterBar.react.scss';

dayjs.extend(utc);

const ClusterBar = ({
  data,
  display,
  timeperiod,
}: {
  data: ClusterData;
  display: string;
  timeperiod: string;
}): JSX.Element => {
  const [clusterBarData, setClusterBarData] = useState<ClusterBarProps>({
    available: '',
    requested: '',
    'in use': '',
    limits: '',
    cost: 0,
  });

  useEffect(() => {
    if (data?.available > 0) {
      const multiplier = timeperiod === 'average' ? 30 : 1;
      const availableData = data?.available * multiplier;

      setClusterBarData({
        available: formatData(data?.available, display).toFixed(),
        requested: data.requests > 0 ? ((data.requests / availableData) * 100).toFixed() : '0',
        'in use': data.inUse > 0 ? ((data.inUse / availableData) * 100).toFixed() : '0',
        limits: data.limits > 0 ? ((data.limits / availableData) * 100).toFixed() : '0',
        cost: data.costs > 0 ? data.costs : 0,
      });
    }
  }, [data, display]);

  const Bar = () => {
    const keys: string[] = Object.keys(clusterBarData);
    const allDivs = keys.map((key) => {
      if (hasKey(clusterBarData, key)) {
        if (key === 'cost') {
          return (
            <div className="cluster-bar-div" key={`${key}-section`}>
              <span className="cluster-bar-title">{`$${clusterBarData[key].toFixed(2)}`}</span>
              <span className="cluster-bar-subtitle">
                {timeperiod === 'average'
                  ? strings.efficiency.monthlyClusterCost
                  : strings.efficiency.dailyClusterCost}
              </span>
            </div>
          );
        }
        return (
          <div className="cluster-bar-div" key={`${key}-section`}>
            <span className="cluster-bar-title">
              {key !== 'available'
                ? `${clusterBarData[key]}%`
                : `${clusterBarData[key]} ${
                    display === 'cpu' ? strings.general.CPU : strings.general.GB
                  }`}
            </span>
            <span className="cluster-bar-subtitle">{`${strings.efficiency.totalResources} ${key}`}</span>
          </div>
        );
      }
    });
    return <>{allDivs}</>;
  };

  return (
    <Card>
      <Card.Body
        style={{
          padding: '0',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Bar />
      </Card.Body>
    </Card>
  );
};

export default ClusterBar;
