export const Labels = {
  ImpactedRepositories: 'Impacted Repositories',
  NoImpactedRepositories: 'There are no impacted repositories',
} as const;

export interface ImpactedRepository {
  repository: string;
  namespace: string;
  name: string;
  container: string;
  kind: string;
}

export enum TableColumnNames {
  Repositories = 'Repositories',
  Namespace = 'Namespace',
  Name = 'Name',
  Container = 'Container',
  Kind = 'Kind',
}

export enum TableAccessors {
  Repositories = 'repository',
  Namespace = 'namespace',
  Name = 'name',
  Container = 'container',
  Kind = 'kind',
}
