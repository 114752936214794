import RegionSettings from './RegionSettings.react';

export default {
  metaInfo() {
    return { title: 'Fairwinds Insights | Region Settings' };
  },
  components: {
    RegionSettings,
  },
  methods: {
    router() {
      return this.$router;
    },
    store() {
      return this.$store;
    },
  },
};
