import Fuse from 'fuse.js';

import { Check } from '../Compliance.types.react';
import { CalcParams, Filter } from './Report.types.react';

export class ReportFilter {
  calc({ checks, status, type, controlId, search }: CalcParams) {
    if (!checks?.length) return;

    const statusFilter = new StatusFilter(status);
    const typeFilter = new TypeFilter(type);
    const controlIdFilter = new ControlIDFilter(controlId);
    const searchFilter = new SearchFilter(search);

    statusFilter.setNext(typeFilter);
    typeFilter.setNext(controlIdFilter);
    controlIdFilter.setNext(searchFilter);

    return statusFilter.exec(checks);
  }
}

class StatusFilter implements Filter {
  private next: Filter | null;
  private status: string;

  constructor(status: string, next = null) {
    this.status = status;
    this.next = next;
  }

  setNext(next: Filter | null) {
    this.next = next;
  }

  exec(checks: Check[]) {
    if (checks?.length && this.status) {
      if (this.status.includes('All') || this.status.includes('Check Status'))
        return this.next ? this.next.exec(checks) : checks;
      checks = checks.filter((check: Check) => check.status === this.status);
      return this.next ? this.next.exec(checks) : checks;
    }
    return checks;
  }
}

class TypeFilter implements Filter {
  private type: string;
  private next: Filter | null;

  constructor(type: string, next = null) {
    this.type = type;
    this.next = next;
  }

  setNext(next: Filter | null) {
    this.next = next;
  }

  exec(checks: Check[]) {
    if (checks?.length && this.type) {
      if (this.type.includes('All') || this.type.includes('Check Type'))
        return this.next ? this.next.exec(checks) : checks;
      checks = this.filterByType(checks);
      return this.next ? this.next.exec(checks) : checks;
    }
    return checks;
  }

  filterByType(checks: Check[]) {
    if (!checks?.length) return checks;
    const filtered = [];
    for (const check of checks) if (this.hasType(check)) filtered.push(check);
    return filtered;
  }

  hasType(check: Check) {
    if (!check || !check?.standards.length) return false;
    for (const standard of check.standards) if (standard?.name === this.type) return true;
    return false;
  }
}

class ControlIDFilter implements Filter {
  private controlId: string;
  private next: Filter | null;

  constructor(controlId: string, next = null) {
    this.controlId = controlId;
    this.next = next;
  }

  setNext(next: Filter | null) {
    this.next = next;
  }

  exec(checks: Check[]) {
    if (!this.controlId || this.controlId.includes('All') || this.controlId.includes('Control ID'))
      return this.next ? this.next.exec(checks) : checks;
    checks = this.filterByControlId(checks);
    return this.next ? this.next.exec(checks) : checks;
  }

  filterByControlId(checks: Check[]) {
    if (!checks?.length) return checks;
    const filtered = [];
    for (const check of checks) if (this.hasControlId(check)) filtered.push(check);
    return filtered;
  }

  hasControlId(check: Check) {
    if (!check || !check?.standards.length) return false;
    for (const standard of check.standards)
      if (standard?.codes?.includes(this.controlId)) return true;
    return false;
  }
}

class SearchFilter implements Filter {
  private search: string;
  private next: Filter | null;

  constructor(search: string, next = null) {
    this.search = search;
    this.next = next;
  }

  setNext(next: Filter | null) {
    this.next = next;
  }

  exec(checks: Check[]) {
    if (!this.search) return this.next ? this.next.exec(checks) : checks;
    const fuse = new Fuse(checks, {
      findAllMatches: true,
      threshold: 0.2,
      keys: ['title', 'mode'],
    });
    checks = fuse.search(this.search).map((res) => res.item);
    return this.next ? this.next.exec(checks) : checks;
  }
}
