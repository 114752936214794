import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { ActionItem, ActionItemFilters, List } from '../ActionItems.types.react';
import { Dropdown, DropdownButton, Form, Spinner } from 'react-bootstrap';
import Fuse from 'fuse.js';
import toast, { Toaster } from 'react-hot-toast';

import ListsTableComponent from '../components/ListsTable/ListsTable.react';
import CreateListModal from './modals/CreateList.react';
import DeleteModal from './modals/DeleteList.react';
import EditModal from './modals/EditList.react';
import PencilIcon from '~assetIcons/pencil.svg';
import DeleteIcon from '~assetIcons/delete.svg';
import useListsReact from '../hooks/useLists.react';

import { IStore, IRoute, IRouter } from '~globalTypes';
import { findMatchingListByName } from '../ActionItems.helpers.react';
import { sendRequest } from '~utils/request';
import logger from '~logger';
import { Breadcrumbs, LayoutReact } from '@fairwindsops/ui-components';
import { handlePageChange } from '~utils/global.helpers.react';
import {
  CLUSTER_OVERVIEW,
  ORG_DASHBOARD,
  ACTION_ITEMS,
  CLUSTERS,
} from '~reactComponents/NavigationReact/Navigation.config.react';

import './ActionItemsList.react.scss';

import { strings } from '~utils/strings';

export type ActionItemsProps = {
  route: IRoute;
  router: () => IRouter;
  store: () => IStore;
  selectedCluster?: string;
};

const ActionItemsList = ({ route, store, router, selectedCluster }: ActionItemsProps): JSX.Element => {
  const org = route?.params?.org;
  const cluster = route?.params?.cluster;
  const baseURL = `/v0/organizations/${org}`;
  const isOwner = store().getters.isOrgOwner;

  const [createdByOptions, setCreatedByOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<List[]>([]);
  const [selectedSavedList, setSelectedSavedList] = useState<string>('Saved Lists');
  const [selectedAuthor, setSelectedAuthor] = useState<string>('Created By');

  const getInitialLists = async (): Promise<List[] | undefined> => {
    let _lists;
    setLoading(true);
    try {
      _lists = await sendRequest('GET', `${baseURL}/lists`, { showSuccessAlert: false, showErrorAlert: false }, null);
    } catch (e) {
      logger.logError('error_retrieving_lists', e);
      toast.error(<b>Problem retrieving your lists. Please try again.</b>);
    }

    const createdByList: string[] = [];
    const updatedLists: List[] = [];
    setListNames([]);

    _lists.forEach((list: List) => {
      if (!createdByList.includes(list.CreatedBy) && list.CreatedBy !== '') {
        createdByList.push(list.CreatedBy);
      } else if (!createdByList.includes(list.CreatedBy) && list.CreatedBy === '') {
        createdByList.push('Insights');
      }
      updatedLists.push({
        ...list,
        isOwner: isOwner || list.CreatedBy === store()?.getters?.user?.Email,
      });
      setListNames((prevNames) => [...prevNames, list.Name]);
    });

    setLists(updatedLists);
    setLoading(false);
    setCreatedByOptions(createdByList);
    return _lists;
  };

  const loadActionItems = async (list: List) => {
    if (!list?.ActionItems?.length) {
      list.isOwner = isOwner;
      list.ActionItems = [];

      if (list.Type === 'query' && Object.keys(list?.Filters).length === 0) {
        list = { ...list, ActionItems: [] };
      }

      const actionItems = await retrieveActionItems(list);
      list.ActionItems = actionItems;
    }
  };

  const processListFilters = (filters: ActionItemFilters) => {
    const searchParams = new URLSearchParams();
    const searchParamsObject = Object.fromEntries(new URLSearchParams(filters));

    for (const key in searchParamsObject) {
      if (searchParamsObject[key]) {
        searchParamsObject[key]?.split(',')?.forEach((param) => searchParams.append(key, param));
      }
    }
    return searchParams;
  };

  const findActionItems = async (filters: ActionItemFilters) => {
    const query = processListFilters(filters);
    let actionItems;
    try {
      actionItems = await sendRequest(
        'GET',
        `${baseURL}/action-items?${query}&minimalFields=true`,
        { showErrorAlert: false },
        null,
      );
    } catch (e) {
      logger.logError('error_retrieving_query_action_items', e);
      toast.error(strings.actionItemsList.errorRetrievingAI);
    }
    return !selectedCluster || selectedCluster === 'All Clusters'
      ? actionItems
      : actionItems.filter((item: ActionItem) => item.Cluster === selectedCluster);
  };

  const retrieveActionItems = async (list: List | Record<string, any>) => {
    if (!list) return;

    const listWithActionItems = list;

    if (list.Type === 'manual') {
      let manualList;
      try {
        manualList = await sendRequest(
          'GET',
          `${baseURL}/lists/${list.ID}?minimalFields=true`,
          { showSuccessAlert: false },
          null,
        );
      } catch (e) {
        logger.logError('error_retrieving_manual_action_items_list');
      }
      return manualList.ActionItems;
    }

    if (list.Type === 'query' && Object.keys(list?.Filters).length) {
      const actionItems = await findActionItems(list.Filters);
      return actionItems;
    }

    if (list.Type === 'query' && !Object.keys(list?.Filters).length) {
      return [];
    }

    if (selectedCluster && selectedCluster !== 'All Clusters' && list?.ActionItems?.length) {
      const actionItems = list.ActionItems.filter((item: ActionItem) => item.Cluster === selectedCluster);
      return actionItems;
    }
  };

  const {
    editListModal,
    deleteListModal,
    editList,
    deleteActionItemModal,
    deleteList,
    deleteActionItem,
    closeModal,
    importedList,
    listType,
    showModal,
    selectedList,
    listName,
    listNames,
    modalError,
    lists,
    query,
    setQuery,
    setLists,
    setListType,
    setListName,
    setShowModal,
    setListNames,
    setImportedList,
    submitNewList,
    checkListName,
  } = useListsReact(baseURL, getInitialLists, undefined, loadActionItems);

  const searchInstance = useMemo(() => {
    return new Fuse(lists as List[], {
      findAllMatches: true,
      threshold: 0.2,
      ignoreLocation: true,
      keys: ['Name', 'ActionItems.Title'],
    });
  }, [lists]);

  useEffect(() => {
    getInitialLists();
  }, [selectedCluster]);

  useEffect(() => {
    if (route?.query?.name) {
      const filteredLists = findMatchingListByName(route.query.name, lists);
      filteredLists ? setSearchResults([filteredLists]) : setSearchResults([]);
      filteringByDropdown(route.query.name, 'list');
    }
  }, [lists]);

  useEffect(() => {
    if (lists.length > 0) {
      if (selectedCluster && selectedCluster !== 'All Clusters') {
        const filtered = lists.filter((list) => (list.ActionItems ? list.ActionItems.length > 0 : undefined));
        setLists(filtered);
      }
    }
  }, [lists, selectedList, showModal]);

  // import list for create new list/edit list modals
  const ImportListOptions = ({ name }: { name?: string }) => {
    return (
      <>
        {lists
          .filter((list) => list.Type === 'manual' && list.Name !== name)
          .map((filteredList) => {
            return <option key={filteredList.Name}>{filteredList.Name}</option>;
          })}
      </>
    );
  };

  const DropdownListOptions = ({ options, type }: { options: string[]; type: string }): JSX.Element => {
    const fullList: string[] = [type === 'lists' ? 'Saved Lists' : 'Created By'];
    options.forEach((option) => {
      if (!fullList.includes(option)) {
        fullList.push(option);
      }
    });
    if (type === 'lists') {
      return (
        <>
          {fullList.map((listName, index) => {
            const matchingList = findMatchingListByName(listName, lists);
            if (listName !== 'Saved Lists' && !matchingList) {
              return (
                <div className="dropdown-item-container" key={`missing-${index}`}>
                  <Dropdown.Item eventKey={`missing-${index}`}>List not found</Dropdown.Item>
                </div>
              );
            } else {
              return (
                <div className="dropdown-item-container" key={listName}>
                  <Dropdown.Item eventKey={listName}>{listName}</Dropdown.Item>
                  {matchingList?.isOwner && (
                    <div className="actions-container">
                      <img
                        alt="pencil icon"
                        className="pencil-icon"
                        src={PencilIcon}
                        aria-haspopup
                        onClick={() => editListModal(matchingList)}
                      />
                      {matchingList?.isOwner && (
                        <img
                          alt="delete icon"
                          className="delete-icon"
                          src={DeleteIcon}
                          aria-haspopup
                          onClick={() => deleteListModal(matchingList.ID)}
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            }
          })}
        </>
      );
    } else {
      return (
        <>
          {fullList.map((list) => (
            <Dropdown.Item eventKey={list} key={list}>
              {list}
            </Dropdown.Item>
          ))}
        </>
      );
    }
  };

  const DisplayLists: () => JSX.Element = useCallback(() => {
    if (searchResults.length === 0 && searchValue) {
      return <h2>Sorry, nothing matches your search. Please try again.</h2>;
    }
    if (searchResults.length) {
      const filteredSearchResults = searchResults.map((result) => result.ID);
      const updatedSearchResults = lists.filter((list) => filteredSearchResults.includes(list.ID));
      return (
        <>
          {updatedSearchResults.map((list, index) => {
            list.index = index;

            return (
              <ListsTableComponent
                key={index}
                list={list}
                deleteActionItem={deleteActionItemModal}
                route={route}
                router={router}
                deleteList={deleteListModal}
                editList={editListModal}
                name={list.Name}
                selectedCluster={selectedCluster}
                setLists={setLists}
                isOwner={isOwner || list.isOwner || false}
                totalResults={searchResults.length}
                loadActionItems={loadActionItems}
              />
            );
          })}
        </>
      );
    }
    if (listNames?.length && lists?.length) {
      return (
        <>
          {listNames.map((name) => {
            const foundList = lists.find((list) => list.Name === name);
            return (
              <ListsTableComponent
                key={name}
                list={foundList || ({} as List)}
                deleteActionItem={deleteActionItemModal}
                route={route}
                router={router}
                deleteList={deleteListModal}
                editList={editListModal}
                name={name}
                selectedCluster={selectedCluster}
                setLists={setLists}
                isOwner={isOwner || foundList?.isOwner || false}
                totalResults={lists.length}
                loadActionItems={loadActionItems}
              />
            );
          })}
        </>
      );
    }
    return <></>;
  }, [listNames, lists, searchResults]);

  const searchForItems = (event: React.BaseSyntheticEvent): void => {
    const searchValue = event.target as HTMLInputElement;
    const results = searchInstance.search(searchValue.value).map((res) => res.item);
    setSearchValue(searchValue.value);
    setSearchResults(results);
  };

  const filteringByDropdown = (selection: string | null, dropdown: string): void => {
    if (selection === 'All Lists' || selection === 'All Authors') {
      setSearchResults([]);
      return;
    }

    if (dropdown === 'list' && selectedAuthor !== 'Created By' && selection) {
      setSelectedSavedList(selection);
      if (selection === 'Saved Lists') {
        setSelectedAuthor('Created By');
      }
      const filteredLists = searchResults.find((list) => list?.Name.toLowerCase() === selection.toLowerCase());
      filteredLists ? setSearchResults([filteredLists]) : setSearchResults([]);
    }

    if (dropdown === 'list' && selectedAuthor === 'Created By' && selection) {
      setSelectedSavedList(selection);
      const filteredLists = findMatchingListByName(selection, lists);
      filteredLists ? setSearchResults([filteredLists]) : setSearchResults([]);
    }

    if (dropdown === 'author' && selectedSavedList === 'Saved Lists' && selection) {
      setSelectedAuthor(selection);
      const filteredLists = lists.filter((list) => list.CreatedBy.toLowerCase() === selection.toLowerCase());
      setSearchResults(filteredLists);
    }
    if (dropdown === 'author' && selection === 'Insights') {
      setSelectedAuthor(selection);
      const filteredLists = lists.filter((list) => list.CreatedBy === '');
      setSearchResults(filteredLists);
    }
    if (dropdown === 'author' && selectedSavedList !== 'Saved Lists' && selection) {
      setSelectedAuthor(selection);
      const filteredLists = lists.filter((list) => list.CreatedBy.toLowerCase() === selection.toLowerCase());
      setSearchResults(filteredLists);
    }
  };

  const orgBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: ACTION_ITEMS,
      label: strings.navigation.ActionItems,
      href: `/orgs/${org}/action-items`,
    },
    {
      id: 'last',
      label: 'Lists',
      href: ``,
      isActive: true,
    },
  ];

  const clusterBreadcrumbsList = [
    {
      id: ORG_DASHBOARD,
      label: org,
      href: `/orgs/${org}/dashboard`,
    },
    {
      id: CLUSTERS,
      label: strings.navigation.allClusters,
      href: `/orgs/${org}/clusters`,
    },
    {
      id: CLUSTER_OVERVIEW,
      label: cluster,
      href: `/orgs/${org}/clusters/${cluster}`,
    },
    {
      id: 'last',
      label: 'Lists',
      href: ``,
      isActive: true,
    },
  ];

  return (
    <LayoutReact className="action-items-lists">
      <Breadcrumbs
        data={cluster ? clusterBreadcrumbsList : orgBreadcrumbsList}
        onClick={(route: string) => {
          handlePageChange(router, route);
        }}
      />
      <div className="lists-bar">
        <div className="bottom-bar">
          <div className="left-side">
            <Form className="search-bar">
              <Form.Group>
                <Form.Control type="text" placeholder="Search" aria-label="search field" onChange={searchForItems} />
              </Form.Group>
            </Form>
            <DropdownButton
              className="dropdown-btn saved-lists-dropdown mb-4"
              title={selectedSavedList}
              aria-haspopup
              onSelect={(selection) => filteringByDropdown(selection, 'list')}
              data-cy="saved-lists-dropdown"
            >
              <DropdownListOptions options={listNames} type="lists" />
            </DropdownButton>
            {createdByOptions.length > 0 && selectedSavedList === 'Saved Lists' && (
              <DropdownButton
                className="dropdown-btn created-by-dropdown mb-4"
                title={selectedAuthor}
                aria-haspopup
                onSelect={(selection) => filteringByDropdown(selection, 'author')}
                disabled={selectedSavedList !== 'Saved Lists'}
                data-cy="created-by-dropdown"
              >
                <DropdownListOptions options={createdByOptions} type="authors" />
              </DropdownButton>
            )}
          </div>
          <div className="right-side">
            <DropdownButton title="Create List" aria-haspopup data-cy="create-list-dropdown">
              <Dropdown.Item
                aria-haspopup
                onClick={() => {
                  setListType('manual');
                  setShowModal((prev) => ({ ...prev, show: true, modal: 'create' }));
                }}
              >
                Manual List
              </Dropdown.Item>
              <Dropdown.Item
                aria-haspopup
                onClick={() => {
                  setListType('query');
                  setShowModal((prev) => ({ ...prev, show: true, modal: 'create' }));
                }}
              >
                Query List
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
      <div>
        {loading && (
          <div className="h-100 d-flex justify-content-center align-items-center spinner-container">
            <Spinner animation="border" role="status" className="list-spinner">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {!loading && lists?.length > 0 && (
          <div className="saved-lists-view card-columns">
            <DisplayLists />
          </div>
        )}
      </div>
      {showModal.modal === 'delete' && (
        <DeleteModal
          show={showModal.show}
          setShowModal={setShowModal}
          item={selectedList}
          deleteList={deleteList}
          deleteActionItem={deleteActionItem}
        />
      )}
      {showModal.modal === 'edit' && (
        <EditModal
          show={showModal.show}
          item={selectedList}
          importedList={importedList}
          setImportedList={setImportedList}
          listName={listName}
          setListName={setListName}
          listNames={listNames}
          ImportListOptions={ImportListOptions}
          query={query}
          setQuery={setQuery}
          deleteListModal={deleteListModal}
          editList={editList}
          router={router}
          org={org}
          getLists={getInitialLists}
          closeModal={closeModal}
          loadActionItems={loadActionItems}
        />
      )}
      {showModal.modal === 'create' && (
        <CreateListModal
          showModal={showModal}
          closeModal={closeModal}
          listNames={listNames}
          listType={listType}
          ImportListOptions={ImportListOptions}
          query={query}
          setQuery={setQuery}
          submitNewList={submitNewList}
          error={modalError}
        />
      )}
      <Toaster />
    </LayoutReact>
  );
};

export default ActionItemsList;
