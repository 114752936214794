import React, { useEffect, useMemo } from 'react';
import {
  useColumnOrder,
  useFlexLayout,
  useResizeColumns,
  useSortBy,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { MultiValue } from 'react-select';
import { Toaster } from 'react-hot-toast';

import Table from '~reactComponents/Table/Table.react';
import Pagination from '~reactComponents/pagination/pagination.react';
import { Card } from '@fairwindsops/ui-components';
import TableCardHeader from './components/TableCardHeader/TableCardHeader.react';

import { COLUMNS_ORDER, TABLE_COLUMNS, sortByOptions, neverHideColumns } from './AggregatorTable.config.react';
import { CostsOptions, ResourceSummary, SelectedFilters, TableAction } from '../../Costs.types.react';
import { aggregatorOptions } from '../../Costs.config.react';

import { hasKey } from '~utils/global.helpers.react';
import { IRoute, IRouter, OptionType } from '~globalTypes';
import { strings } from '~utils/strings';

import './AggregatorTable.react.scss';
import { sendRequest } from '~utils/request';
import logger from '~utils/logger';
import { downloadCsv } from '~utils/helpers';

type AggregatorTableProps = {
  route: IRoute;
  router: () => IRouter;
  aggregators: MultiValue<OptionType>;
  summaries: ResourceSummary[];
  pageSize: number;
  setFilters: (wasClicked: boolean, option?: SelectedFilters, removedValue?: boolean) => void;
  totalResults: number;
  pageIndex: number;
  isLoading: boolean;
  setTableState: React.Dispatch<TableAction>;
  onItemSelected: (row: ResourceSummary) => void;
};

const AggregatorTable = ({
  aggregators,
  summaries,
  pageSize,
  setFilters,
  route,
  totalResults,
  pageIndex,
  isLoading,
  setTableState,
  onItemSelected,
}: AggregatorTableProps) => {
  const org = route?.params?.org;

  const columns = useMemo(() => {
    return aggregators.length
      ? TABLE_COLUMNS((aggregators || []).map((option) => (option?.section ? `${option.section}Labels` : option.value)))
      : TABLE_COLUMNS([]);
  }, [summaries, aggregators, pageSize]);

  const storedTableData = useMemo(() => {
    const retrievedTableData = localStorage.getItem('costs-table');

    return retrievedTableData
      ? JSON.parse(retrievedTableData)
      : {
          hiddenColumns: columns.filter((column) => !column.isVisible).map((option) => option.accessor),
          columnOrder: COLUMNS_ORDER,
        };
  }, [columns]);

  const data = useMemo(() => summaries || [], [summaries, aggregators, route.name, columns, pageSize]);

  const tableInstance = useTable(
    {
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageSize,
            pageIndex,
          }),
          [state, pageSize, pageIndex],
        );
      },
      columns,
      data,
      initialState: {
        pageIndex,
        pageSize,
        columnOrder: storedTableData.columnOrder,
        hiddenColumns: storedTableData.hiddenColumns,
      },
      autoResetHiddenColumns: false,
      manualPagination: true,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    useColumnOrder,
  );

  const hiddenColumns = useMemo(() => {
    let currentAggregators = (aggregators || []).map((option) =>
      option?.section ? `${option.section}Labels` : option.value,
    );
    let hiddenAggregators = aggregatorOptions
      .filter((option) => !currentAggregators.includes(!option.value ? option.section : option.value))
      .map((item) => (!item.value ? item.section : item.value));

    // if included in hiddenColumns but in currentAggregators, keep in hidden
    if (!currentAggregators.includes('pod')) hiddenAggregators.push('pod');
    if (!currentAggregators.length) {
      currentAggregators = hiddenAggregators;
      currentAggregators.push('pod');
      hiddenAggregators = [];
    }

    let updatedHidden = tableInstance.state.hiddenColumns || [];

    (hiddenAggregators || []).forEach((aggregator) => {
      if (!tableInstance.state.hiddenColumns?.includes(aggregator)) {
        updatedHidden.push(aggregator);
      }
    });

    currentAggregators.forEach((aggregator) => {
      if (updatedHidden.includes(aggregator)) {
        updatedHidden = updatedHidden.filter((hiddenItem) => hiddenItem !== aggregator);
      }
    });

    return updatedHidden;
  }, [aggregators, summaries, route.fullPath, tableInstance.state.hiddenColumns, columns]);

  useEffect(() => {
    const orderByInfo = { orderBy: 'totalCost', orderByDesc: 'true' };
    const currentAggregators = aggregators.length > 0 ? aggregators.map((aggregator) => aggregator.value) : [];
    if (tableInstance.state.sortBy.length) {
      const formattedKey = tableInstance.state.sortBy[0].id.replaceAll('.', '_');
      if (hasKey(sortByOptions, formattedKey)) {
        if (neverHideColumns.includes(formattedKey) && !currentAggregators.includes(formattedKey)) {
          orderByInfo.orderBy = currentAggregators.length > 0 ? 'totalCost' : 'pod';
          orderByInfo.orderByDesc = 'true';
        } else {
          orderByInfo.orderBy = sortByOptions[formattedKey];
          orderByInfo.orderByDesc = (!!tableInstance.state.sortBy[0].desc).toString() || 'true';
        }
      }
    } else {
      if (
        orderByInfo.orderBy &&
        neverHideColumns.includes(orderByInfo.orderBy) &&
        !currentAggregators.includes(orderByInfo.orderBy)
      ) {
        orderByInfo.orderBy = !currentAggregators.length ? 'pod' : 'totalCost';
      }
    }

    setTableState({ orderBy: orderByInfo });
  }, [tableInstance.state.sortBy, aggregators]);

  useEffect(() => {
    localStorage.setItem(
      'costs-table',
      JSON.stringify({
        hiddenColumns,
        columnOrder: tableInstance.state.columnOrder,
      }),
    );
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder]);

  useEffect(() => {
    setTableState({ hiddenColumnsChanged: true });
  }, [tableInstance.state.hiddenColumns]);

  const handlePaginationChange = (page: number) => {
    page > -1 && page < Math.ceil(totalResults / pageSize) ? setTableState({ currentPage: page }) : null;
  };

  const totalPage = useMemo(
    () => Math.ceil(totalResults / tableInstance.state.pageSize),
    [totalResults, tableInstance.state.pageIndex, pageSize],
  );

  const isLoaded = useMemo(() => isLoading, [isLoading]);

  const exportWorkloads = async () => {
    const currentURL = new URLSearchParams(window.location.search);
    let response;
    currentURL.delete('pageSize');
    currentURL.delete('page');
    try {
      let url = `/v0/organizations/${org}/resources-summary/export?${currentURL.toString()}&format=csv`;

      url += strings.noTranslate.addNetworkAndStorageTrue;

      response = await sendRequest('GET', url, {}, null);
    } catch (e) {
      logger.logError('error_retrieving_resource_summary_json', e);
    }

    downloadCsv(response, `costs data for ${org}`);
  };

  const changePageSize = (pageSize: number) => {
    handlePaginationChange(0);
    setTableState({ pageSize });
  };

  return (
    <Card className="costs-table" data-cy="costs-card">
      <TableCardHeader
        pageSize={pageSize}
        pageIndex={pageIndex}
        totalResults={totalResults}
        route={route}
        aggregators={aggregators.map((option) => (option?.section ? `${option.section}Labels` : option.value))}
        setPageSize={changePageSize}
        tableInstance={tableInstance}
        exportWorkloads={exportWorkloads}
      />
      <div className="costs-table__table-container">
        <Table
          loaded={!isLoaded}
          tableInstance={tableInstance}
          pagination={<Pagination currentPage={pageIndex} pageChange={handlePaginationChange} totalCount={totalPage} />}
          onDataFetched={setFilters}
          caption={strings.ariaLabels.costsTable}
          onItemSelected={onItemSelected}
          hiddenColumns={hiddenColumns}
          className="aggregator-table"
        />
      </div>
      <Toaster />
    </Card>
  );
};

export default AggregatorTable;
