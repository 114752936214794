import { OptionType } from '~globalTypes';

export type Summary = {
  failing: number;
  implemented: number;
  needsEvidence: number;
  notImplemented: number;
  notInScope: number;
  notStarted: number;
  passing: number;
};

export type Standards = {
  codes: string[];
  name: string;
};

export type Check = {
  category: string;
  eventType?: string;
  evidence: string;
  id: number;
  selfAssessedAt?: string;
  selfAssessedBy?: {
    company: string;
    createdOn: string;
    email: string;
    firstName: string;
    id: 143;
    jobTitle: string;
    lastName: string;
  };
  standards: Standards[];
  status: string;
  tested: boolean | null;
  text: string;
  title: string;
  type: string;
  mode?: string;
  reportInstalled?: boolean;
  canBeAutomated?: boolean;
  eventTypes?: string[];
  reportType?: string;
  ticketLink?: string;
  ticketProvider?: string;
  ticketCreatedAt?: string;
};

export type StandardsSummary = {
  standard: string;
  summary: Summary;
};

export type ReportsSummary = {
  clusters: string[];
  id: number;
  name: string;
  standards: string[];
  standardsSummaries: StandardsSummary[];
  summary: Summary;
  checks?: Check[];
};

export type SelectedReport = {
  name: string;
  id: number;
  clusters: OptionType[];
  standards: OptionType[];
};

export type ReportType = {
  name: string;
  clusters: OptionType[];
  standards: OptionType[];
};

export const Labels = {
  automated: 'automated',
  notImplemented: 'not_implemented',
  implemented: 'implemented',
  passing: 'passing',
  needsEvidence: 'needs_evidence',
  notStarted: 'not_started',
  notInScope: 'not_in_scope',
  failing: 'failing',
  soc2: 'soc_2',
  iso27001: 'iso',
} as const;
