import React from 'react';

import { Card } from '@fairwindsops/ui-components';
import LoadingSpinner from '~reactComponents/LoadingSpinner/LoadingSpinner.react';

import { BottomBarData } from '../../Costs.types.react';

import { formatCost } from '../../Costs.helpers.react';

import { hasKey } from '~reactHelpers';
import { strings } from '~utils/strings';

import './AggregatorBarData.react.scss';

const AggregatorBar = ({ data, isLoading }: { data: BottomBarData | null; isLoading: boolean }) => {
  const totalRecCosts = 'totalRecCosts';

  const barTitles = {
    totalBilled: strings.efficiency.totalBilled,
    totalRecCosts: strings.efficiency.totalSavingsAvailable,
    totalWithSavings: strings.efficiency.totalWithSavings,
    avgBilled: strings.efficiency.avgBilled,
  };

  const Bar = () => {
    if (data) {
      const keys = Object.keys(data);
      return (
        <>
          {keys.map((key) => (
            <div className={'aggregator-bar-div'} key={key}>
              {(isLoading && hasKey(data, key) && (data[key] === 0 || !data[key])) ||
              (key === totalRecCosts && data.totalRecCosts === '$0.00 (0%)' && isLoading) ? (
                <LoadingSpinner spinnerClassNames="aggregator-bar-div__spinner" />
              ) : (
                <>
                  <div className={key === totalRecCosts ? 'aggregator-bar-div__savings' : 'aggregator-bar-div__title'}>
                    {hasKey(data, key) ? (key !== totalRecCosts ? formatCost(data[key]) : data[key]) : 'No data'}
                  </div>
                  <div className="aggregator-bar-div__subtitle">{hasKey(barTitles, key) ? barTitles[key] : ''}</div>
                </>
              )}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <>
      {data && (
        <Card>
          <Card.Body
            style={{
              padding: '0',
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Bar />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default AggregatorBar;
