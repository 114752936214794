import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DeleteModalProps } from '../../ActionItems.types.react';

const DeleteModal = ({
  show,
  setShowModal,
  item,
  deleteList,
  deleteActionItem,
}: DeleteModalProps): JSX.Element => {
  let checkForDeleteActionItem;

  if (!item) {
    checkForDeleteActionItem = false;
  } else if (item?.ActionItemIndex !== undefined && item?.ActionItemIndex >= 0) {
    checkForDeleteActionItem = true;
  }

  return (
    <Modal
      show={show}
      onHide={() => setShowModal({ show: false, modal: '' })}
      style={{ paddingTop: '8rem' }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {checkForDeleteActionItem ? 'Remove Action Item' : 'Delete List'}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {checkForDeleteActionItem && (
          <p>{`Are you sure you want to remove ${
            item?.ActionItems && item.ActionItemIndex && item?.ActionItems?.length > 0
              ? item?.ActionItems[item?.ActionItemIndex]?.Title
              : ''
          } Action Item from this list?`}</p>
        )}
        {item && !checkForDeleteActionItem && (
          <p>{`Are you sure you want to delete the  ${item?.Name} list?`}</p>
        )}
      </Modal.Body>
      <Modal.Footer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
        {item && (
          <>
            <Button onClick={() => setShowModal({ show: false, modal: '' })}>Cancel</Button>
            {item?.isOwner && checkForDeleteActionItem && (
              <Button onClick={() => deleteActionItem()} variant="danger">{`Remove ${
                item?.ActionItems && item.ActionItemIndex
                  ? item?.ActionItems[item?.ActionItemIndex]?.Title
                  : ''
              }`}</Button>
            )}
            {item?.isOwner && !checkForDeleteActionItem && (
              <Button onClick={() => deleteList()} variant="danger">{`Delete ${item.Name}`}</Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
