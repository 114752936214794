import React, { useMemo, useState } from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

import CloseIcon from '../../../../../../reactComponents/Icons/Close.icon.react';
import Tag from '~reactComponents/Tags/Tag.react';

import { sendRequest } from '~utils/request';
import { strings } from '~utils/strings';
import { OptionType } from '~utils/global.types.react';
import logger from '~logger';

import { CostsOptions, SelectedFilters } from '../../Costs.types.react';

import './NewSavedViewDialog.react.scss';

type NewSavedViewDialogProps = {
  isModalShown?: boolean;
  onModalHidden?: () => void;
  onSaveViewCreated?: () => void;
  organizationName: string;
  selectedAggregators: (OptionType | CostsOptions)[];
  selectedFilters: SelectedFilters;
  dateRangeOption: string | undefined;
};

interface CustomFilterToValueMapper {
  [key: string]: (values: OptionType[]) => any;
}

// most of the values has the same value of the filter but some of them are different and requires a custom implementation
const customFilterToValueMappers: CustomFilterToValueMapper = {
  billedCostGreaterThan: (values: OptionType[]) => {
    return values[0]?.value ? parseFloat(values[0].value) : null;
  },
  networkingLessThanInMB: (values: OptionType[]) => {
    return values[0]?.value ? parseInt(values[0].value, 10) : null;
  },
  dateRangeOption: (values: OptionType[]) => {
    return values[0]?.value;
  },
};

const NewSavedViewDialog = ({
  isModalShown,
  onModalHidden,
  onSaveViewCreated,
  organizationName,
  selectedAggregators,
  selectedFilters,
  dateRangeOption,
}: NewSavedViewDialogProps) => {
  const [savedViewName, setSavedViewName] = useState<string>('');

  const closeModal = () => {
    setSavedViewName('');
    onModalHidden && onModalHidden();
  };

  const getAggregatorBySection = (section: string | undefined) => {
    return selectedAggregators
      .filter((aggregator: any) => aggregator.section === section)
      .map((aggregator) => aggregator.value);
  };

  const buildSaveViewPayload = () => {
    const specialFieldsMapper = {
      namespaceLabels: 'namespaceLabelsFilters',
      workloadLabels: 'workloadLabelsFilters',
      podLabels: 'podLabelsFilters',
      resourceTypes: 'resourceTypes',
    };
    if (!savedViewName) {
      return null;
    }

    const payload: Record<string, any> = {
      name: savedViewName,
    };

    if (selectedAggregators?.length) {
      const aggregators = getAggregatorBySection(undefined);
      const workloadLabelsAggregators = getAggregatorBySection('workload');
      const namespaceLabelsAggregators = getAggregatorBySection('namespace');
      const podLabelsAggregators = getAggregatorBySection('pod');
      if (aggregators?.length) {
        payload.aggregators = aggregators;
      }

      const removeSuffix = (aggregator: string, suffix: string) => {
        const last = aggregator.lastIndexOf(suffix);
        return last !== -1 ? aggregator.slice(0, last) : aggregator;
      };

      if (workloadLabelsAggregators?.length) {
        payload.workloadLabelsAggregators = workloadLabelsAggregators.map((aggregator) => {
          return removeSuffix(aggregator, '-workload');
        });
      }

      if (namespaceLabelsAggregators?.length) {
        payload.namespaceLabelsAggregators = namespaceLabelsAggregators.map((aggregator) => {
          return removeSuffix(aggregator, '-namespace');
        });
      }

      if (podLabelsAggregators?.length) {
        payload.podLabelsAggregators = podLabelsAggregators.map((aggregator) => {
          return removeSuffix(aggregator, '-pod');
        });
      }
    }

    if (selectedFilters) {
      const filterKeys = Object.keys(selectedFilters);
      for (const key of filterKeys) {
        if (customFilterToValueMappers && key in customFilterToValueMappers) {
          payload[key] = customFilterToValueMappers[key](selectedFilters[key]);
          continue;
        }
        payload[specialFieldsMapper[key as keyof typeof specialFieldsMapper] || `${key}`] = selectedFilters[key]?.length
          ? selectedFilters[key].filter((filter) => filter.value !== '*').map((filter) => filter.value)
          : [];
      }
    }
    if (dateRangeOption) {
      payload.dateRangeOption = dateRangeOption;
    }

    return payload;
  };

  const saveView = async () => {
    if (!savedViewName) {
      toast.error(strings.efficiency.savedViewNameRequired);
      return false;
    }

    const payload = buildSaveViewPayload();
    if (!payload) {
      return;
    }

    try {
      await sendRequest(
        'POST',
        `/v0/organizations/${organizationName}/resources/saved-views`,
        {
          data: payload,
          headers: {
            'content-type': 'application/json',
          },
        },
        null,
      );

      toast.success(strings.efficiency.saveViewAdded);

      closeModal();
      onSaveViewCreated && onSaveViewCreated();
    } catch (e) {
      toast.error(e.message);
      logger.logError('error_add_saved_view', e);
    }
  };

  const filters = useMemo(() => Object.entries(selectedFilters), [selectedFilters]);

  const hasFilters = useMemo(() => {
    if (!selectedFilters) {
      return false;
    }

    const filterKeys = Object.keys(selectedFilters);
    for (const key of filterKeys) {
      if (selectedFilters[key]?.length) {
        return true;
      }
    }

    return false;
  }, [selectedFilters, dateRangeOption]);

  return (
    <Modal show={isModalShown} onHide={closeModal}>
      <Modal.Body className="pt-0 pb-0">
        <div className="save-view-title-container">
          <h3 className="modal-title">{strings.efficiency.newSaveView}</h3>
          <CloseIcon width="0.75rem" height="0.75rem" onClick={closeModal} className="save-view-close-icon" />
        </div>
        <div>
          {selectedAggregators?.length ? (
            <>
              <div className="saved-view-label">{strings.efficiency.selectedAggregators}</div>
              <div className="save-view-aggregators">
                {selectedAggregators?.length
                  ? selectedAggregators.map((aggregator) => (
                      <Tag hollow={false} tagClassNames="selected-aggregator-tag">
                        {aggregator.label}
                      </Tag>
                    ))
                  : null}
              </div>
            </>
          ) : null}
          {hasFilters || dateRangeOption ? (
            <>
              <div className="selected-filters-title saved-view-label">{strings.efficiency.selectedFilters}</div>
              <div>
                {dateRangeOption ? (
                  <div className="selected-filter-container">
                    <Tag hollow={false} tagClassNames="selected-filter-tag">
                      {'dateRangeOption'}
                    </Tag>
                    <span className="selected-filter-value">{dateRangeOption}</span>
                  </div>
                ) : null}
                {filters.map(([key, value]) =>
                  value?.length && key !== 'dateRangeOption' ? (
                    <div className="selected-filter-container">
                      <Tag hollow={false} tagClassNames="selected-filter-tag">
                        {key}
                      </Tag>
                      <span className="selected-filter-value">
                        {value?.length
                          ? value
                              .filter((element) => element.value !== '*')
                              .map((value) => value.label)
                              .join(', ')
                          : null}{' '}
                        {}
                      </span>
                    </div>
                  ) : null,
                )}
              </div>
            </>
          ) : null}
          <div className="saved-view-name-title saved-view-label">{strings.efficiency.savedViewName}</div>
          <FormControl
            autoFocus
            placeholder={strings.general.name}
            onChange={(e) => setSavedViewName(e.target.value)}
            value={savedViewName}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn" type="button" onClick={saveView}>
          {strings.efficiency.saveView}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewSavedViewDialog;
