import React, { useState } from 'react';

import { Item, ItemParams, Menu, Submenu } from 'react-contexify';

import {
  COMPLIANCE_REPORT_TABLE_CONTEXT_MENU_ID,
  REPO_ACTION_ITEMS_TABLE_CONTEXT_MENU_ID,
  safeClickHandler,
} from '~reactHelpers';

import ExportIcon from '~assetIcons/export.svg';

import { strings } from '~utils/strings';

import 'react-contexify/dist/ReactContexify.css';
import './ContextMenu.react.scss';
import { Check } from '../Compliance.types.react';
import { ConfirmationDialog } from '@fairwindsops/ui-components';
import { sendRequest } from '~utils/request';
import toast from 'react-hot-toast';
import logger from '~utils/logger';

type ContextMenuProps = {
  check: Check;
  showCreateTicketModal: () => void;
  baseURL: string;
  reportID: string;
};

const ContextMenu = ({ showCreateTicketModal, check, baseURL, reportID }: ContextMenuProps) => {
  const [isUnlinkConfirmModalShown, setIsUnlinkConfirmModalShown] = useState<boolean>(false);
  const handleCreateTicketClick = () => {
    showCreateTicketModal();
  };

  const unlinkTicket = () => {
    setIsUnlinkConfirmModalShown(true);
  };

  const executeUnlinkTicket = async () => {
    if (check && check.ticketLink) {
      try {
        await sendRequest(
          'DELETE',
          `${baseURL}/compliance/reports/${reportID}/checks/${check.id}/ticket`,
          { showSuccessAlert: true, cache: true },
          null,
        );
        check.ticketLink = undefined;
        check.ticketProvider = undefined;
        check.ticketCreatedAt = undefined;
        toast.success(strings.viewTicketModal.ticketUnlinked);
        setIsUnlinkConfirmModalShown(false);
      } catch (error) {
        logger.logError('Error unlinking list ticket', error);
        toast.error(strings.viewTicketModal.errorUnlinkingTicket);
      }
    } else {
      toast.error(strings.viewTicketModal.errorUnlinkingTicket);
    }
  };

  const openTicketLink = () => {
    if (!check.ticketLink) {
      return;
    }
    window.open(check.ticketLink, '_blank');
  };

  return (
    <>
      <Menu id={COMPLIANCE_REPORT_TABLE_CONTEXT_MENU_ID} className="context--menu" animation={false}>
        {!check.ticketLink || check.ticketLink === '' ? (
          <Item onClick={handleCreateTicketClick}>
            <img
              className="ticket-icon"
              src={ExportIcon}
              style={{ paddingRight: '1rem' }}
              alt={strings.actionItemsPage.contextMenu.ticketIcon}
            />
            {strings.general.createTicket}
          </Item>
        ) : (
          <>
            <Item onClick={openTicketLink}>
              <img
                className="ticket-icon"
                src={ExportIcon}
                style={{ paddingRight: '1rem' }}
                alt={strings.actionItemsPage.contextMenu.ticketIcon}
              />
              {strings.general.ViewTicket}
            </Item>
            <Item onClick={unlinkTicket}>
              <img
                className="ticket-icon"
                src={ExportIcon}
                style={{ paddingRight: '1rem' }}
                alt={strings.actionItemsPage.contextMenu.ticketIcon}
              />
              {strings.general.UnlinkTicket}
            </Item>
          </>
        )}
      </Menu>
      <ConfirmationDialog
        cancelButtonClasses="custom-cancel-button"
        cancelButtonText={strings.Cancel}
        confirmButtonText={strings.Unlink}
        isModalShown={isUnlinkConfirmModalShown}
        modalBodyClasses="custom-confirm-modal-body"
        modalContent={strings.general.UnlinkTicketConfirmation}
        modalContentClasses="custom-confirm-modal-content"
        modalTitle={strings.general.UnlinkTicket}
        onConfirmClicked={executeUnlinkTicket}
        onModalHidden={(isModalShown: boolean | undefined) => {
          setIsUnlinkConfirmModalShown(isModalShown ? true : false);
        }}
        modalClassName="update-app-group-confirm-modal"
        dataCyConfirmButton="delete-app-group-confirm-button"
      />
    </>
  );
};

export default ContextMenu;
